import dayjs from 'dayjs';
import { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { startCase } from 'lodash';

import { ActionLogRecord } from 'types/application';
import { applicationsConfigs } from 'constants/globalConstants';
import 'assets/scss/ApplicationEdit.scss';

function dateFormatter(cell: string) {
  return dayjs(cell).format('MMM DD YYYY  - hh:mm A');
}

function textFormatter(cell: string) {
  return cell ?? 'Empty';
}

function isCamelCased(str: string) {
  // Check if the string starts with a lowercase letter and contains no underscores or spaces
  return /^[a-z][a-zA-Z0-9]*$/.test(str);
}

function textUserNameFormatter(cell: string, row: ActionLogRecord) {
  if (!row.user?.userName) row.user = { userName: row?.application?.newState?.assigneeName || '' };

  return (cell || row.user.userName) ?? '-';
}

function getFieldName(element: string) {
  let field = element;

  if (isCamelCased(element)) {
    field = element === 'submissionStatus' ? 'Notes / Status' : startCase(element);
  }

  return field;
}

function columnFormatter(cell: string[]) {
  if (!cell) return 'Created';

  const data = cell?.map(getFieldName);

  return data?.map((item) => <li key={item}>{item}</li>);
}

function Column({ type = 'Empty', value = 'Empty' }) {
  let field: string | string[] = '';

  if (type === 'customFields')
    field =
      Array.isArray(value) && value?.length >= 1
        ? value?.map(
            (column) => `Field: ${column?.name || 'Empty'} Value: ${column?.value || 'Empty'} `
          )
        : 'Field: Empty Value: Empty';

  if (type.includes('endsAt') && value?.includes('T0')) value = dayjs(value).format('MM/DD/YYYY');

  if (type.includes('category')) {
    field = applicationsConfigs.alphabetSortedCategories[+value];
  } else if (type.includes('source')) {
    field = applicationsConfigs.sources[+value];
  } else if (type.includes('status')) {
    field = applicationsConfigs.statuses.concat(applicationsConfigs.additionalCases)[+value];
  }

  return <span>{field || value || 'Empty'}</span>;
}

const columns = [
  {
    dataField: 'user.userName',
    text: 'Name',
    formatter: textUserNameFormatter,
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: textFormatter,
  },
  {
    dataField: 'changedColumns',
    text: 'Columns',
    classes: 'action-column-changes',
    formatter: columnFormatter,
  },
  {
    dataField: 'date',
    text: 'Date',
    formatter: dateFormatter,
  },
];

const expandRow = {
  renderer: (row: ActionLogRecord) => {
    return row.status === 'Application Created' ? (
      <div>Application Created</div>
    ) : (
      <div>
        <ul className="logs-changes-row">
          {row?.changedColumns?.length &&
            row?.changedColumns?.map((column) => {
              const field = getFieldName(column);

              return (
                <li key={column}>
                  <span className="changed-field">{field}:</span>
                  <Column type={column} value={row?.application?.oldState[column]} />

                  <i className="arrow column-arrow" />
                  <Column
                    type={column}
                    value={(row?.application?.newState || row?.application?.oldState || [])[column]}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    );
  },
};

export default function HistoryBar({ state }: { state?: ActionLogRecord[] | string }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prevState) => !prevState);

  const data = typeof state === 'string' ? JSON.parse(state) : state || [];

  // if (Array.isArray(data)) {
  //   console.log('data is an array');
  // } else if (typeof data === 'object' && data !== null) {
  //   console.log('data is an object');
  // }

  return (
    <Row className="history-bar">
      <div className="mt-4 custom-fields-wrapper">
        <div
          className="hide-show-wrapper"
          onClick={toggle}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggle();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <h5 className="heading-gb mb-4">Action History</h5>
          <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
            {isOpen ? (
              <>
                <KeyboardArrowUp className="ms-2" style={{ color: '#2C4474', fontSize: '18px' }} />
                <span className="show-hide-text">Hide Section</span>
              </>
            ) : (
              <>
                <KeyboardArrowDown
                  className="ms-2"
                  style={{ color: '#2C4474', fontSize: '18px' }}
                />{' '}
                <span className="show-hide-text">Show Section</span>
              </>
            )}
          </span>
        </div>
        {isOpen && (
          <h5 className="history-tagline">
            Here you can see all the history done by you or others.
          </h5>
        )}
        {isOpen && (
          <BootstrapTable
            bootstrap4
            columns={columns}
            data={data}
            expandRow={expandRow}
            keyField="date"
            noDataIndication={() => 'No Logs Found.'}
            striped
            wrapperClasses="table-responsive table-borderless"
          />
        )}
      </div>
    </Row>
  );
}
