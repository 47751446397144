/* eslint-disable no-restricted-globals */
/* eslint-disable no-else-return */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  Paper,
  Stack,
  Typography,
  IconButton,
  LinearProgress,
  FormGroup,
  FormControlLabel,
  Link,
  Grid,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  TextField,
} from '@mui/material';
import { ArrowBack, ArrowDropDown, ArrowRight, ArrowLeft } from '@mui/icons-material';
import { AwardBudgetEntry } from 'types/awards';
import {
  getAwardBudget,
  bulkUpdateBudgetEntries,
  bulkCreateBudgetEntries,
  deleteBudgetEntries,
  fetchVersions,
  createBudgetVersion,
  getVersionData,
} from 'services/awards/awardBudgetService';

import useUserSession from 'hooks/useUserSession';
import useCurrentAward from 'hooks/useCurrentAward';
import useLocalStorage from 'hooks/useLocalStorage';
import useUnsavedChangesWarning from 'hooks/useUnsavedChangesGuard';
import { tossError, tossSuccess } from 'utils/toastTosser';

import SaveButton from 'components/awards/AwardsDetails/AwardsDetailsView/Budget/SaveButton';
import BudgetTable from 'components/awards/AwardsDetails/AwardsDetailsView/Budget/BudgetTable';
import MuiSwitch from 'shared/muiComponents/Switch';
import StandardButton from 'shared/muiComponents/StandardButton';
import CurrencyInput from 'shared/muiComponents/CurrencyInputField';
import { nanoid } from 'nanoid';
import StatsBubbles from './StatsBubbles';
import prepareBudgetEntries, { Totals } from './prepareBudgetEntries';

export interface BudgetFormEntry {
  id: string;
  awardId: number;
  parentId: number | null;
  entryId: number | null;
  itemOrder: number | null;
  name: string;
  matchType: 'cash' | 'existingGrant' | 'inKind' | 'other';
  matchSource: string;
  matchDescription: string;
  matchAwardId?: number | null;
  awardAmount: number;
  awardExpended: number;
  awardBalance: number;
  matchAmount: number;
  matchExpended: number;
  matchBalance: number;
  enabled?: boolean;
  hasError?: boolean;
  hasChildren?: boolean;
  isUpdated?: boolean;
  editable?: boolean;
}
interface CsvData {
  [key: string]: string;
}

interface BudgetData {
  id: number | string;
  parentId: number | null;
  name: string;
  awardAmount: number | null;
  awardExpended: number | null;
  awardBalance: number | null;
  matchAmount: number | null;
  matchExpended: number | null;
  matchBalance: number | null;
  matchType: string | null;
  matchSource: string | null;
  matchDescription: string | null;
  isUpdated: boolean;
  enabled: boolean;
  awardId: number;
}

export default function Budget() {
  const { awardData, refetchAwardAndTimeline, updateTotals } = useCurrentAward();
  const award = awardData.data;

  const [budget, setBudget] = useState<AwardBudgetEntry[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [csvData, setCsvData] = useState<CsvData[] | null>(null);
  const [isCsvData, setIsCsvData] = useState(false);

  const [totals, setTotals] = useState<Totals>({
    awardAmount: award?.awardAmount || 0,
    matchAmount: award?.matchAmount || 0,
  } as Totals);

  const { userType } = useUserSession();
  const { awardId } = useParams();
  const navigate = useNavigate();

  const readOnly = userType === 'millenniumAnalyst' || userType === 'millenniumResearcher';

  const [showWithMatch, setShowWithMatch] = useLocalStorage<boolean>('showWithMatch', 'v1.0');

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isVersionData, setIsversionData] = useState(false);

  const canEditAward = userType !== 'millenniumAnalyst' && userType !== 'millenniumResearcher';

  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState('');
  const [isSaveVersionModalOpen, setIsSaveVersionModalOpen] = useState(false);
  const [versionName, setVersionName] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [openDialog, setOpenDialog] = useState(false);
  const [isVersionSelected, setIsVersionSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  useUnsavedChangesWarning(unsavedChanges);

  const BUDGET_TABLE_IDS = {
    Personnel: 1,
    'Fringe Benefits': 2,
    Travel: 3,
    Equipment: 4,
    Supplies: 5,
    Contractual: 6,
    Construction: 7,
    Other: 8,
    'Total Direct Charges': 9,
    'Indirect Charges': 10,
  };
  const updateBudgetDataFromCsv = (
    csvDataa: Record<string, string>[],
    budgetData: BudgetData[],
    BUDGET_TABLE: Record<string, number>
  ): BudgetData[] => {
    const parentEntriesInCsv = csvDataa.filter((row) => row.csvID && !isNaN(Number(row.csvID)));
    if (parentEntriesInCsv.length !== Object.keys(BUDGET_TABLE).length) {
      tossError(
        `Invalid number of parent entries. Expected ${
          Object.keys(BUDGET_TABLE).length
        } entries, but found ${parentEntriesInCsv.length}.`
      );
    }
    const budgetMap: Record<number, BudgetData> = {};
    budgetData.forEach((entry) => {
      budgetMap[entry.id] = { ...entry };
    });

    const idToNameMap: Record<number, string> = {};
    for (const [name, id] of Object.entries(BUDGET_TABLE)) {
      idToNameMap[id] = name;
    }

    const updatedBudgetData: BudgetData[] = [];

    // Helper function to create a new budget entry
    const createBudgetEntry = (parentId: number, row: Record<string, string>): BudgetData => {
      const parseValue = (value: string) => (value === '-' ? 0 : Number(value));

      return {
        id: `_new_${nanoid()}`,
        parentId,
        awardId: awardId ? Number(awardId) : undefined,
        name: row.Name || '',
        awardAmount: parseValue(row['Total Project Budget']) || 0,
        awardBalance: parseValue(row['Award Balance']) || 0,
        awardExpended: parseValue(row['Award Expended']) || 0,
        matchAmount: parseValue(row['Match Amount']) || 0,
        matchExpended: parseValue(row['Match Expended']) || 0,
        matchBalance: parseValue(row['Match Balance']) || 0,
        matchDescription: row['Match Description'] === '-' ? null : row['Match Description'],
        matchAwardId: row['Match Award ID'] === '-' ? null : row['Match Award ID'],
        matchSource: row['Match Source'] === '-' ? null : row['Match Source'],
        matchType: row['Match Type'] === '-' ? null : row['Match Type'],
        isUpdated: true,
        enabled: true,
      };
    };
    csvDataa.forEach((row) => {
      const csvID = row.csvID && !isNaN(Number(row.csvID)) ? Number(row.csvID) : null;
      const parentID =
        row['Parent ID'] && !isNaN(Number(row['Parent ID'])) ? Number(row['Parent ID']) : null;
      const name = row.Name;

      if (csvID !== null && idToNameMap[csvID]) {
        const parentName = idToNameMap[csvID];

        const budgetEntry = Object.values(budgetMap).find(
          (entry) => entry.name === parentName && entry.parentId === null
        );

        if (budgetEntry) {
          budgetEntry.awardAmount =
            row['Total Project Budget'] === '-' ? 0 : Number(row['Total Project Budget']);
          budgetEntry.awardExpended =
            row['Award Expended'] === '-' ? 0 : Number(row['Award Expended']);
          budgetEntry.awardBalance =
            row['Award Balance'] === '-' ? 0 : Number(row['Award Balance']);
          budgetEntry.matchAmount = row['Match Amount'] === '-' ? 0 : Number(row['Match Amount']);
          budgetEntry.matchExpended =
            row['Match Expended'] === '-' ? 0 : Number(row['Match Expended']);
          budgetEntry.matchBalance =
            row['Match Balance'] === '-' ? 0 : Number(row['Match Balance']);
          budgetEntry.isUpdated = true;
          budgetEntry.enabled = true;

          updatedBudgetData.push(budgetEntry);
        } else {
          const newEntry = createBudgetEntry(null, row);
          budgetMap[newEntry.id] = newEntry;
          updatedBudgetData.push(newEntry);
        }
      } else if (parentID !== null && idToNameMap[parentID]) {
        const parentName = idToNameMap[parentID];
        const parentBudgetEntry = Object.values(budgetMap).find(
          (entry) => entry.name === parentName && entry.parentId === null
        );
        if (parentBudgetEntry) {
          const subItemsInBudget = Object.values(budgetMap).filter(
            (entry) => entry.parentId === parentBudgetEntry.id
          );
          const subItemBudgetEntry = subItemsInBudget.find((entry) => !entry.isUpdated);

          if (subItemBudgetEntry) {
            subItemBudgetEntry.awardAmount =
              row['Total Project Budget'] === '-' ? 0 : Number(row['Total Project Budget']);
            subItemBudgetEntry.awardExpended =
              row['Award Expended'] === '-' ? 0 : Number(row['Award Expended']);
            subItemBudgetEntry.awardBalance =
              row['Award Balance'] === '-' ? 0 : Number(row['Award Balance']);
            subItemBudgetEntry.matchAmount =
              row['Match Amount'] === '-' ? 0 : Number(row['Match Amount']);
            subItemBudgetEntry.matchExpended =
              row['Match Expended'] === '-' ? 0 : Number(row['Match Expended']);
            subItemBudgetEntry.matchBalance =
              row['Match Balance'] === '-' ? 0 : Number(row['Match Balance']);
            subItemBudgetEntry.isUpdated = true;
            subItemBudgetEntry.enabled = true;

            updatedBudgetData.push(subItemBudgetEntry);
          } else {
            const newEntry = createBudgetEntry(parentBudgetEntry.id, row);
            budgetMap[newEntry.id] = newEntry;
            updatedBudgetData.push(newEntry);
          }
        } else {
          console.log(`Parent entry not found for sub-item: ${name}`);
        }
      } else {
        console.log(`Row skipped: No valid csvID or parentID found`);
      }
    });
    return updatedBudgetData;
  };

  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const parseCsvManually = (file: File): Promise<CsvData[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const text = event.target?.result as string;
        if (!text) {
          reject(new Error('File is empty or cannot be read'));
          return;
        }

        const rows = text
          .split('\n')
          .map((row) => row.trim())
          .filter((row) => row);
        const headers = rows[0].split(',').map((header) => header.trim());

        const jsonData: CsvData[] = rows.slice(1).map((row) => {
          const values = row.split(',').map((value) => value.trim());
          const obj: CsvData = {};

          headers.forEach((header, index) => {
            obj[header] = values[index] || '';
          });

          return obj;
        });

        resolve(jsonData);
      };

      reader.onerror = () => reject(new Error('Error reading the file'));
      reader.readAsText(file);
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const parsedData = await parseCsvManually(file);
        setCsvData(parsedData);
        const importedData = updateBudgetDataFromCsv(parsedData, budget, BUDGET_TABLE_IDS);
        setBudget(importedData);
        setIsCsvData(true);
      } catch (err) {
        console.error('Error parsing CSV:', err);
      }
    }
  };

  const goToAwardDetails = () => {
    const proceedToNavigate = unsavedChanges
      ? confirm('Please save or discard your changes before leaving')
      : true;

    if (proceedToNavigate) {
      navigate(`/dashboard/award/${awardId}/details`, { state: { from: 'Budget' } });
    }
  };

  const fetchBudget = useCallback(async () => {
    setLoading(true);
    const result = await getAwardBudget(awardId!);
    if (isMounted.current) {
      if (result) {
        setBudget(result);
        setError(false);
      } else setError(true);
      setLoading(false);
    }
  }, [awardId]);

  const loadVersions = async (currentPage: number, currentPageSize: number) => {
    const data = await fetchVersions(awardId!, currentPage, currentPageSize);
    if (data) {
      setVersions(data);
    }
  };

  const getDefaultVersionName = () => {
    if (versions.length === 0) return 'V0.1';
    const lastVersion = versions[versions.length - 1];
    const lastVersionName = lastVersion.versionName;
    if (!lastVersionName || !lastVersionName.startsWith('V')) {
      return 'V0.1';
    }
    const versionNumber = parseFloat(lastVersionName.slice(1));
    if (isNaN(versionNumber)) {
      return 'V0.1';
    }
    const nextVersionNumber = versionNumber + 0.1;
    return `V${nextVersionNumber.toFixed(1)}`;
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
    setOpenDialog(true);
    handleMenuClose();
  };

  const normalizeEntries = (entries) => {
    return entries.map((entry) => ({
      id: String(entry.entryId),
      parentId: entry.parentId,
      entryId: entry.entryId,
      awardId: awardId || undefined,
      matchAwardId: entry.matchAwardId || null,
      itemOrder: entry.itemOrder,
      name: entry.name,
      awardAmount: entry.awardAmount,
      awardExpended: entry.awardExpended,
      awardBalance: entry.awardBalance,
      matchType: entry.matchType,
      matchSource: entry.matchSource,
      matchDescription: entry.matchDescription,
      matchAmount: entry.matchAmount,
      matchExpended: entry.matchExpended,
      matchBalance: entry.matchBalance,
      enabled: entry.enabled,
      hasChildren: entry.hasChildren,
      hasError: entry.hasError,
      isUpdated: false,
    }));
  };

  const handleSaveVersionClick = () => {
    const defaultVersionName = getDefaultVersionName();
    setVersionName(defaultVersionName);
    setIsSaveVersionModalOpen(true);
    handleMenuClose();
  };

  const handleSaveVersionConfirm = async (budgetEntries) => {
    const transformedEntries = budgetEntries?.map((entry) => ({
      parentId: entry.parentId,
      entryId: entry.id,
      matchAwardId: entry.matchAwardId,
      itemOrder: entry.itemOrder,
      name: entry.name,
      awardAmount: entry.awardAmount,
      awardExpended: entry.awardExpended,
      awardBalance: entry.awardBalance,
      matchType: entry.matchType,
      matchSource: entry.matchSource,
      matchDescription: entry.matchDescription,
      matchAmount: entry.matchAmount,
      matchExpended: parseFloat(entry.matchExpended),
      matchBalance: parseFloat(entry.matchBalance),
      enabled: entry.enabled,
    }));

    const payload = {
      versionName: versionName || `V0.${versions.length + 1}`,
      entries: transformedEntries,
    };

    const newVersion = await createBudgetVersion(awardId, payload);
    if (newVersion) {
      tossSuccess('Version saved successfully');
      loadVersions(page, pageSize);
    } else {
      tossError('Failed to save version');
    }

    setIsSaveVersionModalOpen(false);
  };

  useEffect(() => {
    isMounted.current = true;
    if (awardId) {
      fetchBudget();
      loadVersions(page, pageSize);
    }

    return () => {
      isMounted.current = false;
    };
  }, [awardId, page]);

  const validationSchema = Yup.object({
    budgetEntries: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Please enter name'),
        awardAmount: Yup.number().nullable(),
        awardExpended: Yup.number().nullable(),
        awardBalance: Yup.number().nullable(),
        matchAmount: Yup.number().nullable(),
        matchExpended: Yup.number().nullable(),
        matchBalance: Yup.number().nullable(),
      })
    ),
  });

  const portalLink = award?.awardPortalLink;

  const { sortedItems } = useMemo(
    () =>
      prepareBudgetEntries(
        budget?.map((entry) => ({
          ...entry,
          id: String(entry.id),
          isUpdated: false,
        })),
        isVersionData
      ),
    [budget, isVersionData]
  );

  const downloadCSV = async () => {
    const csvHeaders = [
      'csvID',
      'Parent ID',
      'Name',
      'Total Project Budget',
      'Award Expended',
      'Award Balance',
      'Match Amount',
      'Match Expended',
      'Match Balance',
      'Match Type',
      'Match Source',
      'Match Description',
    ].join(',');
    const idToCsvIdMap = new Map();
    sortedItems.forEach((entry) => {
      if (BUDGET_TABLE_IDS[entry.name]) {
        idToCsvIdMap.set(Number(entry.id), BUDGET_TABLE_IDS[entry.name]);
      }
    });
    sortedItems.forEach((entry) => {
      if (!BUDGET_TABLE_IDS[entry.name] && entry.parentId) {
        const parentCsvID = idToCsvIdMap.get(Number(entry.parentId));
        if (parentCsvID) {
          idToCsvIdMap.set(Number(entry.id), '-');
        }
      }
    });
    const csvRows = sortedItems.map((entry) => {
      const {
        id,
        parentId,
        name,
        awardAmount,
        awardExpended,
        awardBalance,
        matchAmount,
        matchExpended,
        matchBalance,
        matchType,
        matchSource,
        matchDescription,
      } = entry;

      const csvID = idToCsvIdMap.get(Number(id)) || '-';

      const parentCsvID = parentId ? idToCsvIdMap.get(Number(parentId)) || '-' : '-';

      return [
        csvID,
        parentCsvID,
        name || '-',
        awardAmount || '-',
        awardExpended || '-',
        awardBalance || '-',
        matchAmount || '-',
        matchExpended || '-',
        matchBalance || '-',
        matchType || '-',
        matchSource || '-',
        matchDescription || '-',
      ].join(',');
    });

    const csvContent = `${csvHeaders}\n${csvRows.join('\n')}`;

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Grantrack Award Budget #${awardId}.csv`;
    a.click();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        budgetEntries: budget?.map((entry) => ({
          ...entry,
          id: String(entry.id),
          isUpdated: entry.isUpdated || false,
        })),
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoading(true);

        const currentBudgetEntries =
          isVersionData || isCsvData ? await getAwardBudget(awardId!) : [];

        if (isVersionData || isCsvData) {
          const entriesToDelete = currentBudgetEntries.filter(
            (dbEntry) =>
              !values.budgetEntries.some((entry) => String(entry.id) === String(dbEntry.id))
          );
          if (entriesToDelete.length > 0) {
            const deleteResult = await deleteBudgetEntries(
              awardId!,
              entriesToDelete.map((entry) => ({ id: Number(entry.id) }))
            );

            if (deleteResult) {
              tossSuccess(`${entriesToDelete.length} row(s) deleted`);
            } else {
              tossError(`Error deleting entries`);
            }
          }
        }
        let createPayload;
        let updatePayload;

        if (isVersionData) {
          createPayload = values.budgetEntries.filter(
            (entry) =>
              !currentBudgetEntries.some((dbEntry) => String(dbEntry.id) === String(entry.id))
          );

          updatePayload = values.budgetEntries.filter((entry) =>
            currentBudgetEntries.some((dbEntry) => String(dbEntry.id) === String(entry.id))
          );

          updatePayload = updatePayload.map((entry) => ({
            ...entry,
            isUpdated: true,
          }));
        } else {
          createPayload = values.budgetEntries.filter((entry) => entry.id.startsWith('_new_'));
          updatePayload = values.budgetEntries.filter(
            (entry) => !entry.id.startsWith('_new_') && entry.isUpdated
          );
        }
        if (createPayload.length) {
          const result = await bulkCreateBudgetEntries(
            awardId!,
            createPayload.map((entry) => ({
              ...entry,
              matchAwardId: entry.matchAwardId || null,
              id: undefined,
            }))
          );

          if (result) {
            tossSuccess(`${createPayload.length} row(s) created`);
          } else {
            tossError(`Error creating new entries`);
          }
        }
        if (updatePayload.length) {
          const result = await bulkUpdateBudgetEntries(
            awardId!,
            updatePayload.map((entry) => ({
              ...entry,
              matchAwardId: entry.matchAwardId || null,
              id: Number(entry.id),
            }))
          );

          if (result) {
            tossSuccess(`${updatePayload.length} row(s) updated`);
          } else {
            tossError(`Error updating budget entries`);
          }
        }

        if (award?.status === 'initialAgreementRequired') {
          refetchAwardAndTimeline();
        }
        setSubmitting(false);
        await fetchBudget();
        setIsversionData(false);
      }}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => (
        <Stack alignItems="center" sx={{ width: '100%' }}>
          <Helmet>
            <title>Budget</title>
          </Helmet>

          <Stack
            direction="row"
            justifyContent="space-between"
            m={2}
            maxWidth="1400px"
            sx={{ width: '100%' }}
          >
            <Stack alignItems="center" direction="row" spacing={2}>
              <IconButton onClick={() => goToAwardDetails()}>
                <ArrowBack htmlColor="black" />
              </IconButton>

              <Typography variant="h2">
                Award {award?.stringId || ''} Budget
                {portalLink && (
                  <>
                    <br />
                    <Typography variant="caption">
                      Navigate to your portal{' '}
                      <Link onClick={() => window.open(portalLink)}>here</Link>.
                    </Typography>
                  </>
                )}
              </Typography>
            </Stack>

            <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
              <Button
                aria-controls="version-menu"
                aria-haspopup="true"
                endIcon={<ArrowDropDown />}
                onClick={handleMenuOpen}
                sx={{ textTransform: 'none' }}
              >
                Versions
              </Button>
              <Menu
                anchorEl={anchorEl}
                id="version-menu"
                onClose={handleMenuClose}
                open={isMenuOpen}
              >
                <MenuItem onClick={handleSaveVersionClick}>Save Version</MenuItem>
                <MenuItem disabled>Select Version</MenuItem>
                {versions?.map((version) => (
                  <MenuItem key={version.id} onClick={handleVersionChange} value={version.id}>
                    {version.versionName}
                  </MenuItem>
                ))}
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  mt={2}
                  spacing={2}
                >
                  <IconButton
                    disabled={page === 1}
                    onClick={() => {
                      setPage((prev) => Math.max(prev - 1, 1));
                      loadVersions(page - 1, pageSize);
                    }}
                  >
                    <ArrowLeft />
                  </IconButton>
                  <Typography variant="body1">Page {page}</Typography>
                  <IconButton
                    disabled={versions.length < pageSize}
                    onClick={() => {
                      setPage((prev) => prev + 1);
                      loadVersions(page + 1, pageSize);
                    }}
                  >
                    <ArrowRight />
                  </IconButton>
                </Stack>
              </Menu>

              <StandardButton onClick={downloadCSV}>Download CSV</StandardButton>
              <StandardButton onClick={handleImportClick}>Import CSV</StandardButton>
              <input
                ref={fileInputRef}
                accept=".csv, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                type="file"
              />
              <SaveButton setUnsavedChanges={setUnsavedChanges} />
            </Stack>
          </Stack>

          <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
            <DialogTitle>Confirm Change to Version</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to load this version? All current changes will be replaced.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
              <Button
                color="primary"
                onClick={async () => {
                  if (!isVersionData && !isVersionSelected) {
                    const defaultVersionName = getDefaultVersionName();
                    setVersionName(defaultVersionName);
                    await handleSaveVersionConfirm(values.budgetEntries);
                  }
                  setOpenDialog(false);
                  setIsversionData(true);
                  setIsVersionSelected(true);
                  const versionData = await getVersionData(selectedVersion);
                  if (versionData) {
                    const transformedData = normalizeEntries(versionData.budget_version_entries);
                    setBudget(transformedData);
                    setFieldValue('budgetEntries', transformedData);
                    tossSuccess(`Version ${versionData.versionName} loaded successfully`);
                  } else {
                    tossError('Failed to load version!');
                  }
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog onClose={() => setIsSaveVersionModalOpen(false)} open={isSaveVersionModalOpen}>
            <DialogTitle>Save New Version</DialogTitle>
            <DialogContent>
              <DialogContentText>Enter a name for the new version</DialogContentText>
              <TextField
                autoFocus
                fullWidth
                label="Version Name"
                margin="dense"
                onChange={(e) => setVersionName(e.target.value)}
                type="text"
                value={versionName}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsSaveVersionModalOpen(false)}>Cancel</Button>
              <Button
                color="primary"
                onClick={() => handleSaveVersionConfirm(values.budgetEntries)}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Stack
            direction="row"
            justifyContent="space-between"
            m={2}
            maxWidth="1400px"
            sx={{ width: '100%' }}
          >
            {award && <StatsBubbles award={award} totals={totals} />}
          </Stack>

          <Paper
            elevation={2}
            sx={{
              m: 2,
              p: 2,
              borderRadius: 4,
              maxWidth: '1400px',
              width: '100%',
              boxShadow: '0 0 15px 10px #f7f7f7',
            }}
          >
            <Stack direction="row" justifyContent="space-between" mb={2}>
              <Stack direction="column">
                <Typography variant="h2">Budget Details</Typography>

                <Stack direction="row" gap={1} mt={1}>
                  <Grid item md={6} xs={12}>
                    <CurrencyInput
                      id="awardAmount"
                      label="Award Amount"
                      onSave={(value: number) => {
                        const t = { ...totals, awardAmount: value };
                        totals.awardAmount = value;
                        updateTotals(
                          value,
                          (totals.matchAmount || Number(award?.matchAmount)) ?? 0
                        );
                        setTotals(t);
                        tossSuccess('Award amount updated');
                      }}
                      readonly={!canEditAward}
                      required
                      value={award?.awardAmount}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CurrencyInput
                      id="matchAmount"
                      label="Match"
                      onSave={(value: number) => {
                        const t = { ...totals, matchAmount: value };
                        totals.matchAmount = value;
                        updateTotals((totals.awardAmount || award?.awardAmount) ?? 0, value);
                        setTotals(t);
                        tossSuccess('Match amount updated');
                      }}
                      readonly={!canEditAward}
                      value={award?.matchAmount ?? 0}
                    />
                  </Grid>
                </Stack>
              </Stack>

              <Stack direction="row">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        checked={!!showWithMatch}
                        onChange={() => setShowWithMatch(!showWithMatch)}
                        size="medium"
                        sx={{ mr: 2 }}
                      />
                    }
                    label="Show with Match"
                    sx={{
                      m: 0,
                      '& .MuiFormControlLabel-label	': {
                        color: '#50535B',
                        fontWeight: 600,
                      },
                    }}
                  />
                </FormGroup>
              </Stack>
            </Stack>

            {loading && <LinearProgress />}
            {error && 'Error loading budget'}

            <BudgetTable
              budget={budget}
              isVersionData={isVersionData}
              loading={loading}
              readOnly={readOnly}
              setTotals={setTotals}
              setUnsavedChanges={setUnsavedChanges}
              showWithMatch={!!showWithMatch}
              totals={totals}
            />
          </Paper>
        </Stack>
      )}
    </Formik>
  );
}
