import { Box, Stack, Typography } from '@mui/material';
import { toArray } from 'utils/utilFunctions';
import useSearchParams from 'hooks/useSearchParams';
import { applicationsConfigs } from 'constants/globalConstants';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';

const allowedProgramFilters = {
  f: 'Funder',
  gp: 'Program',
  sd: 'Start Date',
  ed: 'End Date',
  ct: 'Category',
} as const;

const getDefaultFilters = (isUserDashboard: boolean) => {
  const today = dayjs();
  const fourteenDaysTD = today.startOf('D').subtract(14, 'd').toString();
  const endFourteenDaysTD = today.endOf('D').add(14, 'd').toString();

  return isUserDashboard
    ? {
        programNames: [],
        funders: [],
        category: 'all',
        startDate: fourteenDaysTD,
        endDate: endFourteenDaysTD,
        sortBy: 'startDate',
        sortOrder: 'desc',
      }
    : {
        programNames: [],
        funders: [],
        category: 'all',
        startDate: null,
        endDate: null,
        sortBy: 'startDate',
        sortOrder: 'desc',
      };
};

export default function UsedProgramFiltersDescriptionBlock(): JSX.Element {
  const { searchParams } = useSearchParams();
  const location = useLocation();
  const isUserDashboard = location?.pathname?.includes('user-dashboard');
  const defaultFilters = getDefaultFilters(isUserDashboard);

  const usedFilters: { key: keyof typeof allowedProgramFilters; values: string }[] = [];

  Object.entries(allowedProgramFilters).forEach(([key, label]) => {
    const filterValues = toArray(searchParams[key]);

    if (filterValues.length > 0) {
      const isDefaultValue =
        (key === 'f' && filterValues.join(', ') === defaultFilters.funders.join(', ')) ||
        (key === 'gp' && filterValues.join(', ') === defaultFilters.programNames.join(', ')) ||
        (key === 'ct' && filterValues.join(', ') === defaultFilters.category) ||
        (key === 'sd' && filterValues.join(', ') === defaultFilters.startDate) ||
        (key === 'ed' && filterValues.join(', ') === defaultFilters.endDate);

      if (!isDefaultValue) {
        let displayValue = filterValues.join(', ');

        if (key === 'ct') {
          const categoryValue = filterValues[0];
          const categoryIndex = parseInt(categoryValue, 10);
          if (
            !Number.isNaN(categoryIndex) &&
            applicationsConfigs.categories[categoryIndex] !== undefined
          ) {
            displayValue = applicationsConfigs.categories[categoryIndex];
          } else {
            displayValue = categoryValue;
          }
        }

        usedFilters.push({ key: key as keyof typeof allowedProgramFilters, values: displayValue });
      }
    }
  });

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" gap={3} mb={2} mt={2}>
      {usedFilters.map((filter) => (
        <Stack key={filter.key} alignItems="center" direction="row" gap={2} m={0}>
          <Typography variant="body1">{`${allowedProgramFilters[filter.key]}:`}</Typography>
          <Typography sx={{ color: 'grey' }} variant="body1">
            {filter.values}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
}
