import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Box, Stack, Typography } from '@mui/material';

import SearchFilter from 'shared/muiComponents/SearchFilter';
import { searchFilters } from 'services/filterService';
import { SavedFilter } from 'types/filter';

import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';

// TODO: can be refactored together with API to use names directly

const moduleIds = {
  summaryView: 3,
  financeView: 4,
  projectView: 5,
  userView: 6,
} as const;

type ViewKey = keyof typeof moduleIds;

interface LoadFiltersSetModalProps {
  view: string;
  onClose: () => void;
  onApply: (filterSet: SavedFilter) => void;
}

export default function LoadFiltersSetModal({ view, onClose, onApply }: LoadFiltersSetModalProps) {
  const filtersSearch = useCallback(
    async (input: string) => searchFilters(moduleIds[view as ViewKey], input, 'title'),
    []
  );
  const navigate = useNavigate();

  const [selectedFilterSet, setSelectedFilterSet] = useState<SavedFilter | null>(null);

  return (
    <Modal centered className="awards-modal" onHide={onClose} show>
      <Modal.Body>
        <Stack direction="column" px={2} py={2} spacing={4}>
          <Typography m={2} variant="h2">
            Load saved Filter Set
          </Typography>

          <Box>
            <SearchFilter<SavedFilter, false>
              getLabel={(filter: SavedFilter) => filter.title}
              label="Filter Set"
              multiple={false}
              onChange={setSelectedFilterSet}
              searchFunction={filtersSearch}
              selected={selectedFilterSet}
            />
          </Box>

          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
            <StandardButton
              disabled={selectedFilterSet === null}
              onClick={() => {
                onApply(selectedFilterSet!);
                navigate(String(selectedFilterSet!.filterData));
                onClose();
              }}
            >
              Apply
            </StandardButton>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
