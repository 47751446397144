/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IconButton, Stack } from '@mui/material';
import { DeleteForeverOutlined } from '@mui/icons-material';

import { MandatoryRole } from 'types/awards';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import { searchUsers } from 'services/userService';
import { camelCaseToWords } from 'utils/utilFunctions';

import { deleteAwardRole } from 'services/awards/awardRolesService';

export interface MandatoryRoleRow {
  id?: number;
  roleId?: number;
  role: MandatoryRole;
  userId?: number;
  updatedUserId?: number;
  userName?: string;
  position?: string;
  email?: string;
}

export interface CustomRoleRow {
  id: number;
  role: string;
  userId: number;
  userName: string;
  position: string;
  email: string;
}

export const getMandatoryRolesColumns = (
  awardId: string,
  updateRole: (updatedRole: MandatoryRoleRow) => void,
  setUnsavedChanges: (value: boolean) => void,
  readOnly = false
): ColumnDescription<MandatoryRoleRow>[] => [
  {
    dataField: 'role',
    text: 'Role',
    headerStyle: { width: '300px' },
    formatter: (roleName) => camelCaseToWords(roleName),
  },
  {
    dataField: 'userName',
    text: 'Name',
    headerStyle: { width: '25%' },
    formatter: (cell, role) =>
      !readOnly ? (
        <SearchFilter<MandatoryRoleRow, false>
          getLabel={(user) => user.userName || ''}
          label={role.userName || readOnly ? '' : 'Search for User'}
          multiple={false}
          onChange={(user) => {
            setUnsavedChanges(true);
            if (user) updateRole(user);
            else
              updateRole({
                id: role.id,
                role: role.role,
                roleId: role.roleId,
                userId: role.userId,
              });
          }}
          readOnly={readOnly}
          searchFunction={async (input: string) =>
            (await searchUsers(input, 'name')).map((foundUser) => ({
              ...role,
              updatedUserId: foundUser.id,
              userName: foundUser.name,
              position: foundUser.position || '',
              email: foundUser.email,
            }))
          }
          selected={role.userName ? role : null}
          width="250px"
        />
      ) : (
        cell
      ),
  },
  {
    dataField: 'email',
    text: 'E-Mail',
    headerStyle: { width: '25%' },
  },
  {
    dataField: 'position',
    text: 'Position',
    headerStyle: { width: '25%' },
  },
];

export const getCustomRolesColumns = (
  awardId: string,
  fetchRoles: () => void,
  readOnly: boolean
): ColumnDescription<CustomRoleRow>[] => [
  { dataField: 'role', text: 'Role', headerStyle: { width: '25%' } },
  { dataField: 'userName', text: 'Name', headerStyle: { width: '25%' } },
  { dataField: 'email', text: 'E-Mail', headerStyle: { width: '25%' } },
  { dataField: 'position', text: 'Position', headerStyle: { width: '25%' } },
  {
    dataField: 'actions',
    text: '',
    isDummyField: true,
    formatter: (_cell, role) =>
      readOnly ? null : (
        <Stack direction="row" justifyContent="flex-start">
          <IconButton
            aria-label="delete"
            disabled={readOnly}
            onClick={async (event) => {
              event.stopPropagation();

              if (!confirm('Are you sure you want to delete this user role?')) return;

              if (role.id) {
                await deleteAwardRole(awardId, role.id);
                fetchRoles();
              }
            }}
            size="small"
            sx={{
              fontSize: '1rem',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '100px',
              borderRadius: 2,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <DeleteForeverOutlined />
            Remove
          </IconButton>
        </Stack>
      ),
  },
];
