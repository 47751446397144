import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';

interface FilterSelectProps {
  label: string;
  onChange: (value?: string) => void;
  selected?: string;
  options: (string | null | undefined)[];
  getValueLabel: (value: string | undefined) => string;
  width?: string;
}

export default function FilterSelect({
  label,
  onChange,
  selected,
  options,
  getValueLabel,
  width = '100%',
}: FilterSelectProps) {
  return (
    <FormControl
      sx={{
        width,
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: (theme) =>
            !selected
              ? `1px solid ${theme.palette.secondary.dark}`
              : `2px solid ${theme.palette.primary.light}`,
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: (theme) => `2px solid ${theme.palette.secondary.main}`,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: (theme) => `2px solid ${theme.palette.primary.main}`,
        },
        '& .MuiInputLabel-outlined': {
          fontSize: '0.8rem',
          p: '2px',
          fontWeight: 500,
          lineHeight: '0.4rem',
        },
        '& .MuiInputLabel-shrink, .MuiOutlinedInput-notchedOutline': {
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1.1rem',
        },
      }}
    >
      <InputLabel id={`filter-select-${label}`}>{label}</InputLabel>
      <Select
        IconComponent={ExpandMoreRounded}
        id={`filter-select-${label}`}
        label={label}
        labelId={`filter-select-${label}`}
        onChange={(event) => onChange(event.target.value)}
        renderValue={getValueLabel}
        size="small"
        sx={{
          height: '40px',
          '.MuiSelect-select': {
            p: '6px 2px 0px 10px',
          },
          fieldset: { borderRadius: 0 },
          '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-root': {
              minHeight: '40px',
            },
          },
        }}
        value={selected}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {getValueLabel(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
