import SearchFilter from 'shared/muiComponents/SearchFilter';
import { toArray } from 'utils/utilFunctions';
import useSearchParams from 'hooks/useSearchParams';
import { searchUsers } from 'services/userService';

export default function AssigneeFilter({ width = '100%' }) {
  const { searchParams, setSearchParams } = useSearchParams();

  return (
    <SearchFilter<string>
      getLabel={(f) => f}
      label="Assignee"
      onChange={(values) => setSearchParams({ page: 1, assignees: values })}
      searchFunction={async (input: string) =>
        (await searchUsers(input, 'name')).map((u) => u.name)
      }
      selected={toArray(searchParams.assignees)}
      variant="filter"
      width={width}
    />
  );
}
