import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useSearchQuery() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Function to get all current search parameters as an object
  const getCurrentSearchParams = useCallback(() => {
    const params: { [key: string]: string | string[] } = {};

    searchParams.forEach((encodedValue, key) => {
      const value = decodeURIComponent(encodedValue);
      if (value.includes(',') && value.split(',').length > 1) {
        params[key] = value.split(',');
      } else {
        params[key] = value;
      }
    });
    return params;
  }, [searchParams]);

  // Function to update one or more search parameters
  const updateSearchParams = useCallback(
    (updates: { [key: string]: string | string[] | number | null }) => {
      const newSearchParams = new URLSearchParams(searchParams);
      Object.entries(updates).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          // If the value is an array, set the search parameter as comma-separated values
          if (value.length > 0) newSearchParams.set(key, value.join(','));
          else newSearchParams.delete(key);
        } else if (value) newSearchParams.set(key, String(value));
        else newSearchParams.delete(key);
      });
      setSearchParams(newSearchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  return {
    searchParams: getCurrentSearchParams(),
    setSearchParams: updateSearchParams,
  };
}
