import { Dispatch, SetStateAction } from 'react';
import {
  StateFilter,
  DateType,
  DateRange,
  FunderFilter,
  UserFilter,
  ClientFilter,
  FilterActions,
} from 'shared/filters';
import dayjs from 'dayjs';
import useUserSession from 'hooks/useUserSession';
import { ReportFilters, Entity } from 'pages/Reports';
import { Stack } from '@mui/material';
import UsedReportsFiltersDescriptionBlock from './UsedReportsFilterBlock';

export type Filters = Partial<ReportFilters> & { programNames?: string[] };

export default function ReportsFiltersUI({
  filters,
  setFilters,
  onCopy,
  onDownloadDOCX,
  onExport,
  onReset,
}: {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
}) {
  const updateUsers = (users: Entity[]) =>
    setFilters((prevState: Filters) => ({ ...prevState, users }));
  const updateClients = (clients: Entity[]) =>
    setFilters((prevState) => ({ ...prevState, clients }));
  const updateFunders = (funders: { name: string }[]) =>
    setFilters((prevState) => ({ ...prevState, funders }));
  const updateState = (state: string | null) =>
    setFilters((prevState) => ({ ...prevState, state }));
  const updateDateType = (dateType: string | null) =>
    setFilters((prevState) => ({ ...prevState, endDateType: dateType }));
  const updateDateRange = (start: string | null, end: string | null) =>
    setFilters((prevState) => ({ ...prevState, startDate: start, endDate: end }));

  const currentUser = useUserSession();
  const todayDate = dayjs().format('YYYY-MM-DD');
  const startOfYearDate = dayjs().startOf('y').format('YYYY-MM-DD');
  const {
    users: usersCriteria,
    // clients: clientsCriteria,
    funders: fundersCriteria,
    state: stateCriteria,
    startDate: startDateCriteria,
    endDate: endDateCriteria,
    endDateType: endDateTypeCriteria,
  } = filters || {};

  const clientsCriteria = currentUser.isMillenniumUser ? filters.clients : null;

  const defaultFilters = {
    startDate: currentUser.isMillenniumUser ? startOfYearDate : null,
    endDate: currentUser.isMillenniumUser ? todayDate : null,
    endDateType: 'Due Date',
  };
  return (
    <Stack direction="column" m={2} sx={{ width: '100%' }}>
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ width: '100%' }}
      >
        <ClientFilter key="Client" filters={clientsCriteria} onChange={updateClients} />
        <FunderFilter key="Funder" filters={fundersCriteria} onChange={updateFunders} />
        <DateRange
          key="Date Range"
          endDate={endDateCriteria}
          onChange={updateDateRange}
          startDate={startDateCriteria}
        />
        {currentUser.isMillenniumUser && (
          <UserFilter
            key="Assigned To"
            filters={usersCriteria}
            onChange={updateUsers}
            title="Assigned To"
          />
        )}
        <StateFilter key="State" onChange={updateState} state={stateCriteria || 'all'} />
        <DateType key="Show Results By" dateType={endDateTypeCriteria} onChange={updateDateType} />
        <div className="filter-action-row filter-action-row--reports mb-3">
          <FilterActions
            defaultResetFilters={defaultFilters}
            filters={filters}
            onCopy={onCopy}
            onDownloadDOCX={onDownloadDOCX}
            onExport={onExport}
            onReset={onReset}
          />
        </div>
      </Stack>
      <UsedReportsFiltersDescriptionBlock />
    </Stack>
  );
}
