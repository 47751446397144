import { useEffect, useState } from 'react';
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Chip,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
} from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';

import RenderTextWithHighlight from 'utils/helpers/RenderTextWithHighlight';

function CustomPopperComponent(props: PopperProps) {
  return (
    <Popper
      {...props}
      sx={{
        '& .MuiAutocomplete-listbox, .MuiAutocomplete-loading, .MuiAutocomplete-noOptions': {
          fontSize: '0.8rem',
        },
      }}
    />
  );
}

function getCustomPaperComponent<T>(options: readonly T[], inputValue: string) {
  return function CustomPaperComponent(props: PaperProps) {
    if (options.length === 0 && !inputValue) return null;
    return (
      <Paper {...props} sx={{ fontSize: '0.8rem', color: (theme) => theme.palette.primary.main }} />
    );
  };
}

interface SearchFilterProps<T, Multiple extends boolean = true> {
  label?: string;
  onChange: (value: Multiple extends true ? T[] | null : T | null) => void;
  selected: Multiple extends true ? T[] : T | null;
  searchFunction?: (input: string) => Promise<T[]>;
  onSearch?: (input: string) => Promise<T[]>;
  getLabel: (entity: T) => string;
  multiple?: Multiple;
  width?: string;
  variant?: 'regular' | 'filter';
  readOnly?: boolean;
}

export default function SearchFilter<T, Multiple extends boolean = true>({
  label,
  onChange,
  selected,
  searchFunction,
  onSearch,
  getLabel,
  multiple = true as Multiple,
  width = '100%',
  variant = 'regular',
  readOnly = false,
}: SearchFilterProps<T, Multiple>) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly T[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;

    const fetchData = async () => {
      if (!searchFunction && !onSearch) return;

      if (inputValue === '') {
        setOptions([]);
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const search = onSearch || searchFunction;
        const results = await search?.(inputValue);

        if (active) {
          setOptions(results || []);
        }
      } catch (error) {
        console.error('Error during search:', error);
        if (active) setOptions([]);
      } finally {
        if (active) setLoading(false);
      }
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [inputValue, searchFunction, onSearch]);

  const empty = Array.isArray(selected) ? selected.length === 0 : !selected;

  return (
    <Autocomplete<T, Multiple, false, false>
      ChipProps={{
        sx: { maxWidth: '120px' },
      }}
      disabled={readOnly}
      getOptionLabel={(option) => getLabel(option) || ''}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => getLabel(option) === getLabel(value)}
      limitTags={1}
      loading={loading}
      multiple={multiple}
      noOptionsText="No matches"
      onChange={(_event, value) => onChange(value)}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      PaperComponent={getCustomPaperComponent<T>(options, inputValue)}
      PopperComponent={CustomPopperComponent}
      popupIcon={<ExpandMoreRounded />}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          placeholder={empty ? 'Start typing to search...' : '...'}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: variant === 'filter' ? 0 : 1,
            },
            '& .MuiInputLabel-outlined': {
              fontSize: '0.8rem',
              p: '2px',
              fontWeight: 500,
              lineHeight: '1.1rem',
            },
            '& .MuiInputLabel-shrink, .MuiOutlinedInput-notchedOutline': {
              fontSize: '1rem',
              fontWeight: 500,
              lineHeight: '1.1rem',
            },
            ...(readOnly && {
              '& .MuiInputBase-root': {
                minHeight: '40px',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                color: '#2c4474',
              },
            }),
            ...(!readOnly && {
              '& .MuiInputBase-root': {
                minHeight: '40px',
              },
            }),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <RenderTextWithHighlight highlight={inputValue} text={getLabel(option)} />
          </li>
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={getLabel(option)}
            variant="outlined"
            {...getTagProps({ index })}
            sx={{
              maxWidth: 'calc(max(100% - 60px, 50px)) !important',
              fontSize: '0.8rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: '24px',
            }}
          />
        ))
      }
      size="small"
      sx={{
        width,
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: (theme) =>
            !empty && variant === 'filter'
              ? `2px solid ${theme.palette.primary.light}`
              : `1px solid ${theme.palette.secondary.dark}`,
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: (theme) =>
            `2px solid ${
              variant === 'filter' ? theme.palette.secondary.main : theme.palette.primary.light
            }`,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: (theme) =>
            `2px solid ${
              variant === 'filter' ? theme.palette.primary.main : theme.palette.primary.light
            }`,
        },
      }}
      value={selected}
    />
  );
}
