import BootstrapTable from 'react-bootstrap-table-next';
import { format } from 'date-fns';
import { useLocation, useNavigate } from 'react-router';
import { Award, Notifications } from 'types/awards';
import { formatCurrencyValue } from 'utils/utilFunctions';
import Notes from './Notes';

function NotesBox({
  data,
  notes,
  awardId,
}: {
  data: Award[];
  notes: Notifications[];
  awardId: number;
}) {
  const columns = [
    {
      dataField: 'stringId',
      text: 'Award ID',
    },
    {
      dataField: 'funder',
      text: 'Funder',
    },
    {
      dataField: 'grantProgramName',
      text: 'Grant Program',
    },
    {
      dataField: 'awardAmount',
      text: 'Total Award Amount',
      formatter: (amount: number | null) => (amount ? formatCurrencyValue(amount) : '-'),
    },
    {
      dataField: 'awardExpended',
      text: 'Expended',
      formatter: (amount: number | null) => (amount ? formatCurrencyValue(amount) : '-'),
      headerStyle: { width: '10%' },
    },
    {
      dataField: 'awardBalance',
      text: 'Balance',
      formatter: (amount: number | null) => (amount ? formatCurrencyValue(amount) : '-'),
      headerStyle: { width: '10%' },
    },
    {
      dataField: 'clientName',
      text: 'Client',
      formatter: (clientName?: string) => clientName || 'Millennium Managed',
    },
    {
      dataField: 'department',
      text: 'Department',
    },
    {
      dataField: 'nextReportDueDate',
      text: 'Next Report Due',
      formatter: (date: string | null) =>
        date ? format(new Date(date), 'eeee, MMMM do yyyy') : '-',
    },
  ];

  if (!data || data.length === 0) return <p>This user is not assigned to any awards yet.</p>;
  const navigate = useNavigate();
  const location = useLocation();
  const handleRowClick = () => {
    navigate(`/dashboard/award/${awardId}/details`, { state: { from: location.pathname } });
  };
  return (
    <>
      {notes?.length && <Notes handleClick={handleRowClick} notes={notes} />}
      <BootstrapTable
        bootstrap4
        bordered={false}
        columns={columns}
        data={data}
        keyField="stringId"
        remote={{
          filter: true,
        }}
        rowEvents={{
          onClick: () => handleRowClick(),
        }}
        // rowClasses={(row: Award) => (row.awardBalance > row.awardAmount ? 'danger' : '')}
        wrapperClasses="table-responsive table-borderless"
      />
    </>
  );
}

export default NotesBox;
