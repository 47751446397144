import { useCallback, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

import {
  Box,
  Typography,
  Paper,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';

import RequiredActionChip from 'components/awards/AwardsDetails/AwardsDetailsView/Timeline/RequiredActionChip';
import { AwardTimelineStep } from 'types/awards';
import TimelineCollapsed from 'components/awards/AwardsDetails/AwardsDetailsView/Timeline/TimelineCollapsed';

import useCurrentAward from 'hooks/useCurrentAward';
import AwardStatusChip from 'components/awards/AwardStatusChip';
import LeftLine from './LeftLine';
import RightLine from './RightLine';

export interface StepWithProps extends AwardTimelineStep {
  serial: number;
  stepColor: string;
  nextStepColor: string;
}

export interface StepWithCoordinates extends StepWithProps {
  serial: number;
  rowDirection: string;
  firstInTotal: boolean;
  lastInTotal: boolean;
  firstInRow: boolean;
  lastInRow: boolean;
}
export interface NextAction {
  text: string;
  color: string;
}

const getStepColor = (step?: AwardTimelineStep) => {
  if (!step) return '#5F77A5';

  const { timeline, complete, reportId } = step;

  let color = complete ? '#5F77A5' : '#84b9c6';

  if (timeline === 'Active') color = complete ? '#af8fad' : '#e5cad7';
  if (timeline === 'Reports' || reportId) color = complete ? '#ccb55c' : '#F7F2D4';
  if (timeline === 'Compliance Required') color = complete ? '#d58a57' : '#FFE0CC';
  if (timeline === 'Closed') color = complete ? '#99b4af' : '#E0EBE3';

  return color;
};

const getNextAction = (steps: StepWithProps[]): NextAction => {
  // Searches for the first element in the reversed array that has a complete property set to true.
  const lastCompleteStep =
    steps
      .slice()
      .reverse()
      .find((step) => step.complete) || steps[0];

  return {
    text:
      (lastCompleteStep &&
        (lastCompleteStep?.requiredAction || lastCompleteStep?.defaultAction || '')) ||
      '',
    color: lastCompleteStep?.stepColor || '#3C5D9C',
  };
};

export default function StatusTimeline() {
  const { timelineData, awardData } = useCurrentAward();
  const { data, loading, error } = timelineData;
  const [timelineSteps, setTimelineSteps] = useState<Array<StepWithProps>>([]);

  const award = awardData.data;

  useEffect(() => {
    if (data) {
      setTimelineSteps(
        data.map((step, serial, array) => ({
          ...step,
          serial,
          stepColor: getStepColor(step),
          nextStepColor: getStepColor(array[serial + 1]),
        }))
      );
    }
  }, [data]);

  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down('lg'));
  const isWideScreen = useMediaQuery(currentTheme.breakpoints.up('xl'));
  const [expanded, setExpanded] = useState(false);
  let stepsPerRow = 5;

  if (isSmallScreen) {
    stepsPerRow = 4;
  }
  if (isWideScreen) {
    stepsPerRow = 6;
  }

  const getExpandedRows = useCallback(() => {
    const rows = [];
    for (let i = 0; i < timelineSteps.length; i += stepsPerRow) {
      rows.push(timelineSteps.slice(i, i + stepsPerRow));
    }
    return rows;
  }, [timelineSteps, stepsPerRow]);

  const getCollapsedRow = useCallback(() => {
    let lastCompleteIndex = 0;

    for (let i = timelineSteps.length - 1; i >= 0; i--) {
      if (timelineSteps[i].complete) {
        lastCompleteIndex = i;
        break;
      }
    }

    const collapsedRowStart = Math.max(0, lastCompleteIndex - stepsPerRow + 2);
    const collapsedRowEnd = Math.min(timelineSteps.length, collapsedRowStart + stepsPerRow);

    return timelineSteps.slice(collapsedRowStart, collapsedRowEnd);
  }, [timelineSteps, stepsPerRow]);

  const nextAction = getNextAction(timelineSteps);

  // console.log('nextAction', nextAction, timelineSteps);

  return (
    <Paper
      elevation={1}
      sx={{ width: '100%', p: 2, borderRadius: 3, my: 2, boxShadow: '0 0 15px 10px #f7f7f7' }}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row" justifyContent="flex-start">
          <Typography color="primary.main" m={2} variant="h2">
            Timeline
            <br />
            <Typography color="primary.main" variant="caption">
              Current Status: {award?.status && <AwardStatusChip status={award.status} />}
              <br />
              {nextAction?.text && 'Next action required: '}
              {timelineSteps.length > 0 && nextAction?.text && (
                <RequiredActionChip nextAction={nextAction} />
              )}
            </Typography>
          </Typography>
        </Stack>

        <IconButton
          aria-label="expand"
          disabled={error}
          disableRipple
          onClick={() => setExpanded(!expanded)}
          size="small"
          sx={{ borderRadius: 2, color: (theme) => theme.palette.primary.main }}
        >
          <ExpandMoreRounded transform={expanded ? 'rotate(180)' : ''} />
          <Typography sx={{ fontWeight: 600 }} variant="caption">
            {expanded ? 'Collapse Timeline' : 'Expand Timeline'}
          </Typography>
        </IconButton>
      </Stack>

      {!expanded && (
        <Box m={2} ml={4} textAlign="center">
          {error && 'Error loading timeline'}
          {loading && <LinearProgress />}
          {timelineSteps.length > 0 && !loading && (
            <TimelineCollapsed
              firstRow={getCollapsedRow().map((step, stepIndex) => ({
                ...step,
                rowDirection: 'left-to-right',
                firstInTotal: step.serial === 0,
                lastInTotal: step.serial + 1 === timelineSteps.length,
                firstInRow: stepIndex === 0,
                lastInRow: stepIndex + 1 === stepsPerRow,
              }))}
            />
          )}
        </Box>
      )}

      {expanded && timelineSteps.length > 0 && !loading && (
        <Box m={2} ml={4}>
          <Grid container spacing={2}>
            {getExpandedRows().map((row, rowIndex) => (
              <Grid
                key={nanoid()}
                container
                direction={rowIndex % 2 === 1 ? 'row-reverse' : 'row'}
                justifyContent="flex-start"
                spacing={2}
              >
                {row.map((step, stepIndex) => {
                  const rowDirection = rowIndex % 2 === 0 ? 'left-to-right' : 'right-to-left';
                  const firstInTotal = step.serial === 0;
                  const lastInTotal = step.serial + 1 === timelineSteps.length;
                  const firstInRow = stepIndex === 0;
                  const lastInRow = stepIndex + 1 === stepsPerRow;

                  return (
                    <Grid
                      key={nanoid()}
                      item
                      sx={{ p: '0!important', mt: '-5px' }}
                      xs={12 / stepsPerRow}
                    >
                      <Stack
                        direction="row"
                        sx={{
                          height: '100px',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                        }}
                        textAlign="center"
                        zIndex={1}
                      >
                        <LeftLine
                          step={{
                            ...step,
                            rowDirection,
                            firstInTotal,
                            lastInTotal,
                            firstInRow,
                            lastInRow,
                          }}
                        />
                        <Box
                          sx={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: step.complete ? getStepColor(step) : 'white',
                            borderRadius: '50%',
                            zIndex: '2',
                            border: `3px solid ${getStepColor(step)}`,
                            position: 'relative',
                          }}
                        >
                          <Stack
                            direction="column"
                            left={-42}
                            position="absolute"
                            top={24}
                            width="100px"
                          >
                            <Typography
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                                textTransform: 'uppercase',
                                fontSize: '0.7rem',
                                fontWeight: '600',
                                lineHeight: '1',
                              }}
                            >
                              {step.timeline}
                            </Typography>
                          </Stack>
                        </Box>

                        <RightLine
                          step={{
                            ...step,
                            rowDirection,
                            firstInTotal,
                            lastInTotal,
                            firstInRow,
                            lastInRow,
                          }}
                        />
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Paper>
  );
}
