import { Modal } from 'react-bootstrap';
import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { Award, Project } from 'types/awards';
import { searchAwards, updateProject } from 'services/awards/awardsService';
import SearchFilter from 'shared/muiComponents/SearchFilter';

interface AddAwardsModalProps {
  toggle: () => void;
  fetchProject: (id: number) => void;
  data: Project;
}

export default function AddAwardsModal(props: AddAwardsModalProps) {
  const { data, toggle, fetchProject } = props;

  const [selectedAwards, setSelectedAwards] = useState<Award[]>([]);

  const onSubmit = async () => {
    const result = await updateProject({
      id: data.id,
      // TODO: why next 2 fields are required API side for any update
      name: data.name,
      category: data.category,
      assignedAwards: [...(data.awards || []), ...selectedAwards].map((i) => i.id),
    });
    if (result) {
      tossSuccess('The Award was successfully added.');
      fetchProject(data.id);
      toggle();
    } else tossError('Error adding a new Award.');
  };

  return (
    <Modal centered className="awards-modal" onHide={toggle} show>
      <Modal.Body>
        <Stack direction="column" px={2} py={2} spacing={4}>
          <Stack direction="column" spacing={4}>
            <Typography m={2} variant="h2">
              Add Awards
            </Typography>

            <Box>
              <SearchFilter<Award>
                getLabel={(f) => `${f.grantProgramName} - ${f.funder}`}
                label="Select awards"
                onChange={(values) => setSelectedAwards(values || [])}
                searchFunction={async (input: string) => searchAwards('grantProgramName', input)}
                selected={selectedAwards}
              />
            </Box>
          </Stack>
          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
            <SecondaryButton onClick={toggle}>Cancel</SecondaryButton>
            <StandardButton disabled={!selectedAwards.length} onClick={onSubmit}>
              Add Awards
            </StandardButton>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
