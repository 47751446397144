import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import BootstrapTable, {
  BootstrapTableProps,
  ExpandColumnRendererProps,
  ExpandHeaderColumnRenderer,
  ExpandRowProps,
  PaginationOptions,
} from 'react-bootstrap-table-next';
import { Stack, Typography } from '@mui/material';
import { Card } from 'react-bootstrap';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import useSearchParams from 'hooks/useSearchParams';
import { UsersAwardsList } from 'services/awards/awardsService';
import { UsersAwards } from 'types/awards';
import useLocalStorage from 'hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import NoUsersEmptyScreen from './NoUsersEmptyScreen';
import NotesBox from './NotesBox';
import getUsersAwardsColumns from './getUsersAwardsColumns';

interface UsersTableProps {
  data?: UsersAwardsList;
  loading: boolean;
  error: boolean;
}

const expandRow: ExpandRowProps<UsersAwards, number> = {
  renderer: (row: UsersAwards) => {
    const awardId = row?.awards?.length > 0 ? row?.awards[0]?.id : null;
    return (
      <Stack sx={{ mt: 2, mb: 2, ml: 5, mr: 5 }}>
        <NotesBox awardId={awardId} data={row?.awards} notes={row?.awardNotifications} />
      </Stack>
    );
  },

  className: (isExpand: boolean) => {
    return isExpand ? 'expand-row' : '';
  },

  showExpandColumn: true,
  expandColumnPosition: 'left',
  expandColumnRenderer: ({ expanded }: ExpandColumnRendererProps) =>
    expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />,
  expandHeaderColumnRenderer: ({ isAnyExpands }: ExpandHeaderColumnRenderer) =>
    isAnyExpands ? <KeyboardArrowUp /> : <KeyboardArrowDown />,
};

const defaultFiltersSet = {
  sortBy: 'name',
  sortOrder: 'desc',
  page: '1',
  perPage: '20',
};

export default function UsersTable({ data, loading, error }: UsersTableProps) {
  const { searchParams, setSearchParams } = useSearchParams();
  const [storedFilter, setStoredFilter] = useLocalStorage<string>('awardsUsersFilter', 'v1.2');
  const [goToPage, setGoToPage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const currentPage = Number(searchParams.page);
  const perPage = Number(searchParams.perPage || 20);
  const totalRecords = data?.count || 0;
  const pageCount = Math.ceil(totalRecords / perPage);
  const startItem = (currentPage - 1) * perPage + 1;

  useEffect(() => {
    if (storedFilter) {
      navigate(storedFilter, { replace: true });
    } else {
      setSearchParams(defaultFiltersSet);
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      setStoredFilter(location.pathname + location.search);
    }
  }, [location]);

  const handleSortChange = (sortField: string, sortOrder: string) => {
    setSearchParams({ page: 1, sortBy: sortField, sortOrder });
  };

  const handleGoToPage = (e: React.FormEvent) => {
    e.preventDefault();
    const pageNum = parseInt(goToPage, 10);
    if (pageNum >= 1 && pageNum <= pageCount) {
      setSearchParams({ page: pageNum });
      window.scrollTo({ top: 250 });
    }
    setGoToPage('');
  };

  const displayValue = goToPage || (goToPage === '' ? '#' : currentPage.toString());

  const contentTable = ({
    paginationProps,
    paginationTableProps,
  }: {
    paginationProps: PaginationOptions;
    paginationTableProps: BootstrapTableProps;
  }) => (
    <>
      <BootstrapTable
        {...paginationTableProps}
        bodyClasses={loading ? 'loading' : ''}
        bootstrap4
        bordered={false}
        columns={getUsersAwardsColumns()}
        data={[...new Set(data?.rows || [])]}
        expandRow={expandRow}
        keyField="id"
        noDataIndication="No Users were found to display for selected filters."
        onTableChange={(type, newState) => {
          if (type === 'sort') {
            handleSortChange(newState.sortField, newState.sortOrder);
          }
        }}
        remote={{
          sort: true,
          pagination: true,
        }}
        sort={{ dataField: searchParams.sortBy, order: searchParams.sortOrder as 'asc' | 'desc' }}
        wrapperClasses="table-responsive table-borderless hover"
      />

      {data?.count >= 1 && (
        <div className="my-3 pagination-wrapper">
          <div className="records-showing-text">
            Showing {startItem} of {totalRecords}
          </div>
          <div className="pagination-controls-wrapper">
            <form className="pagination-form-field" onSubmit={handleGoToPage}>
              <span className="me-2 label-text">Go to page</span>
              <input
                aria-label="Page number"
                className="form-control form-control-sm goToPage-input"
                max={pageCount}
                min="1"
                onBlur={() => {
                  if (goToPage === '') {
                    setGoToPage('#');
                  }
                }}
                onChange={(e) => setGoToPage(e.target.value)}
                onFocus={() => {
                  if (goToPage === '' && currentPage) {
                    setGoToPage(currentPage.toString());
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleGoToPage(e);
                  }
                }}
                type="number"
                value={displayValue || '#'}
              />
            </form>

            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </>
  );

  if (!loading && data?.rows?.length === 0) return <NoUsersEmptyScreen />;

  if (!data?.rows) return <NoUsersEmptyScreen />;

  if (error)
    return (
      <>
        <p>Error fetching Users.</p>
        <p>Try resetting the filters.</p>
      </>
    );

  return (
    <Card className="w-100">
      <Card.Header>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h2">User View</Typography>
        </Stack>
      </Card.Header>

      <Card.Body>
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            hideSizePerPage: true,
            withFirstAndLast: pageCount > 5,
            alwaysShowAllBtns: true,
            page: currentPage,
            totalSize: totalRecords,
            sizePerPage: perPage,
            firstPageText: '«',
            nextPageText: '›',
            prePageText: '‹',
            lastPageText: '»',
            onPageChange: (newPage) => {
              setSearchParams({ page: newPage });
              window.scrollTo({ top: 250 });
            },
          })}
        >
          {contentTable}
        </PaginationProvider>
      </Card.Body>
    </Card>
  );
}
