import {
  FormControlLabel,
  FormGroup,
  InputLabel,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';

import { tossError, tossSuccess } from 'utils/toastTosser';

import IconButton from 'shared/muiComponents/IconButton';
import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';
import MuiSwitch from 'shared/muiComponents/Switch';
import useUserSession from 'hooks/useUserSession';
import { getPreferences, updatePreferences } from 'services/awards/awardsService';
import { PreferenceObject } from 'types/awards';
import FilterSelect from 'shared/muiComponents/FilterSelect';
import dayjs from 'dayjs';
import useLocalStorage from 'hooks/useLocalStorage';
import useCurrentAward from 'hooks/useCurrentAward';

const frequencyValueLabels = {
  day: 'Day (9:00 AM)',
  week: 'Week (9:00 AM)',
  biWeekly: '2 Weeks (9:00 AM)',
  month: 'Month (1st) (9:00 AM)',
  endOfMonth: `Month (${dayjs().endOf('month').format('DD')}st) (9:00 AM)`,
};

type FrequencyOption = keyof typeof frequencyValueLabels;

function AwardsPreferences() {
  const navigate = useNavigate();
  const currentUser = useUserSession();
  const award = useCurrentAward();
  const clientId =
    currentUser.clientCreatorId || currentUser.primaryClientId || award?.awardData?.data?.clientId;

  const [data, setData] = useState<PreferenceObject>({
    preferences: [],
    awardNotificationsEnabled: true,
    clientId: null,
  } as PreferenceObject);

  const [awardNotificationsEnabled, enableAwardNotifications] = useLocalStorage<boolean>(
    'enableNotifications',
    'v1.0'
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    // Disable and enable all preference items when awardNotificationsEnabled changes
    if (data.preferences?.length) {
      const preferences = data.preferences.map((item) => ({
        ...item,
        settings: { ...item.settings, enabled: awardNotificationsEnabled },
      }));

      setData({ ...data, preferences });
    }
  }, [awardNotificationsEnabled]);

  const refetchPreferences = async () => {
    setLoading(true);

    if (!clientId) return;

    const result = await getPreferences(clientId);

    if (isMounted.current) {
      if (result) {
        setData({
          preferences: result.preferences,
          awardNotificationsEnabled: result.awardNotificationsEnabled,
          clientId: result.clientId,
        });

        enableAwardNotifications(result.awardNotificationsEnabled);

        setError(false);
      } else setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    refetchPreferences();
  }, []);

  // Error
  if (error) {
    console.log('AwardsPreferences error', error);
  }

  const handleChangeFrequency = (value: string | null, key: string[]) => {
    const preferences = data.preferences.map((item) => {
      if (key.includes(item.preference))
        return {
          ...item,
          frequency: value === 'none' ? null : value || null,
          settings: { time: '0900' },
        };
      return item;
    });

    // Uncomment to see the preferences object for debugging
    // console.log('handleChangeFrequency', preferences);

    setData({ ...data, preferences });
  };

  const onSubmit = async () => {
    if (!clientId) return;

    const result = await updatePreferences(clientId, data);

    if (result) {
      tossSuccess('Notification preferences saved successfully!');
    } else tossError('Error saving notification preferences.');
  };

  if (!clientId) return null;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" m={2}>
        <Stack alignItems="center" direction="row" spacing={2}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack htmlColor="black" />
          </IconButton>

          <Typography variant="h2">Preferences</Typography>
        </Stack>

        <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
          <SecondaryButton onClick={refetchPreferences}>Discard</SecondaryButton>
          <StandardButton onClick={onSubmit}>Save Changes</StandardButton>
        </Stack>
      </Stack>

      <Stack m={2}>
        <Typography variant="caption">Customize preferences for all awards.</Typography>
      </Stack>

      <Paper elevation={2} sx={{ m: 2, p: 3, borderRadius: 4, boxShadow: '0 0 15px 10px #f7f7f7' }}>
        <Stack direction="column" mb={2} mt={2} spacing={4}>
          <Typography variant="h1">Notifications</Typography>
          {loading && <LinearProgress />}

          {!data.preferences?.length && (
            <Typography variant="body1">No preferences found for your client.</Typography>
          )}

          {data.preferences?.length > 1 && (
            <>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                width="350px"
              >
                <Typography fontWeight="500" variant="h2">
                  Allow E-Mail Notifications
                </Typography>

                <Stack direction="row">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          checked={awardNotificationsEnabled}
                          onChange={() => enableAwardNotifications(!awardNotificationsEnabled)}
                          size="medium"
                          sx={{ mr: 2 }}
                        />
                      }
                      label=""
                      sx={{
                        m: 0,
                        '& .MuiFormControlLabel-label	': {
                          color: '#50535B',
                          fontWeight: 600,
                        },
                      }}
                    />
                  </FormGroup>
                </Stack>
              </Stack>

              <Stack width="350px">
                <InputLabel htmlFor="notificationOption1" required>
                  Send batch emails in advance of events every...
                </InputLabel>

                <FilterSelect
                  getValueLabel={(value) =>
                    value ? frequencyValueLabels[value as FrequencyOption] : 'Default'
                  }
                  label=""
                  onChange={(value) => {
                    handleChangeFrequency(value || null, [
                      'awardBatchEmails',
                      'awardEmailNotification',
                    ]);
                  }}
                  options={[null, ...Object.keys(frequencyValueLabels)]}
                  selected={
                    data.preferences.find((item) => item.preference === 'awardBatchEmails')
                      ?.frequency || ''
                  }
                />
              </Stack>

              <Stack width="350px">
                <InputLabel htmlFor="notificationOption2" required>
                  Send reminders every....
                </InputLabel>

                <FilterSelect
                  getValueLabel={(value) =>
                    value ? frequencyValueLabels[value as FrequencyOption] : 'Default'
                  }
                  label=""
                  onChange={(value) => handleChangeFrequency(value || null, ['awardReminders'])}
                  options={[null, ...Object.keys(frequencyValueLabels)]}
                  selected={
                    data.preferences.find((item) => item.preference === 'awardReminders')
                      ?.frequency || ''
                  }
                />
              </Stack>
            </>
          )}
        </Stack>
      </Paper>
    </>
  );
}

export default AwardsPreferences;
