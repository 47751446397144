import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import AuthWrapper from 'components/auth/authWrapper';
import ResetPasswordForm from 'components/auth/resetPasswordForm';
import authImage from 'assets/images/authwrapperImg.png';

function ResetPassword() {
  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Reset Password - Grantrack</title>
      </Helmet>
      <AuthWrapper imageSrc={authImage}>
        <ResetPasswordForm />
      </AuthWrapper>
    </>
  );
}

export default ResetPassword;
