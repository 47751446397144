/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// import { Stack, Typography, IconButton as IconB } from '@mui/material';
import {
  Stack,
  Typography,
  IconButton as IconB,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { ArrowBack, CheckCircleOutlineRounded, HighlightOffRounded } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import IconButton from 'shared/muiComponents/IconButton';
import Documents from 'components/awards/AwardsDetails/AwardsDetailsView/Documents';
import StandardButton from 'shared/muiComponents/StandardButton';
import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StatusTimeline from 'components/awards/AwardsDetails/AwardsDetailsView/Timeline';
import Details from 'components/awards/AwardsDetails/AwardsDetailsView/Details';
import Payments from 'components/awards/AwardsDetails/AwardsDetailsView/Payments';

import ActionHistory from 'components/awards/AwardsDetails/AwardsDetailsView/ActionHistory';
import { Award } from 'types/awards';
import { createAward, updateAward } from 'services/awards/awardsService';
import { tossError, tossSuccess } from 'utils/toastTosser';
import useUserSession from 'hooks/useUserSession';
import useCurrentAward from 'hooks/useCurrentAward';

import {
  approveAward,
  rejectAward,
  requestApproval,
  setCompliance,
} from 'services/awards/awardStatusActions';

dayjs.extend(isSameOrBefore);
export default function AwardsDetailsView() {
  const { userType, id: currentUserId, clientCreatorId } = useUserSession();
  const navigate = useNavigate();
  const { awardId } = useParams();

  const { currentAwardId, awardData, timelineData, fetchAward, refetchAwardAndTimeline } =
    useCurrentAward();

  const { data } = awardData;
  const showCloseOut = data?.status === 'closeout';
  const isTimelineStatusComplete = timelineData?.data?.some(
    (item) => item?.complete === true && item?.order === 13
  );
  const [unsavedChanges, setUnsavedChanges] = useState(true);
  const [isCloseoutDialogOpen, setIsCloseoutDialogOpen] = useState(false);

  // Re-fetch data if navigation took place from a subpage
  useEffect(() => {
    if (awardId !== 'new' && awardId && currentAwardId && window.history.state.usr?.from) {
      refetchAwardAndTimeline();
    }
  }, [awardId, currentAwardId, fetchAward]);
  // run refetchAwardAndTimeline(); every minute
  useEffect(() => {
    const interval = setInterval(refetchAwardAndTimeline, 60 * 1000);

    return () => clearInterval(interval);
  }, [refetchAwardAndTimeline]);

  const isNewAwardCreation = awardId === 'new';
  let newAwardInitialData: Partial<Award> | null = null;

  if (isNewAwardCreation)
    newAwardInitialData = {
      source: 0,
      category: 0,

      startsOn: dayjs().format('YYYY-MM-DD'),
      endsOn: dayjs().add(6, 'months').format('YYYY-MM-DD'),

      awardAmount: 0,
      awardExpended: 0,
      awardBalance: 0,
      matchAmount: 0,
      matchExpended: 0,
      matchBalance: 0,
      department: '',
    };

  const awardEditForm = useFormik<Partial<Award>>({
    initialValues: newAwardInitialData || data || {},
    enableReinitialize: true,

    validationSchema: Yup.object({
      awardAmount: Yup.number().required('Please enter a amount'),
      // assigneeId: Yup.number().required('Please select an assignee'),
      clientId: Yup.number().required('Please select a client'),
      // department: Yup.string().required('Please enter a department'),
      grantProgramName: Yup.string().required('Please enter a grant program name'),
      funder: Yup.string().required('Please enter a funder'),
      // grantPurpose: Yup.string().required('Please enter a grant purpose'),
      startsOn: Yup.date()
        .max(Yup.ref('endsOn'), `Start Date must come before End Date`)
        .nullable()
        .required('Start Date is a required field'),
      endsOn: Yup.date()
        .min(Yup.ref('endsOn'), `Start Date must come before End Date`)
        .nullable()
        .required('End Date is a required field'),
    }),

    onSubmit: async (values: Partial<Award>) => {
      // console.log('onSubmit', values);

      setUnsavedChanges(false);

      if (!values.source) values.source = 0;

      if (isNewAwardCreation) {
        const newAward = await createAward(values);

        if (newAward && newAward.id) {
          tossSuccess('Award created');
          navigate(`/dashboard/award/${newAward.id}/details`);
        } else tossError('Error creating award');

        return;
      }

      const updatedFields: Partial<Award> = {};

      (Object.keys(values) as Array<keyof Award>).forEach((key) => {
        if (values[key] !== awardEditForm.initialValues[key]) {
          // @ts-expect-error TODO: check how to do it more explicitly
          updatedFields[key] = values[key];
        }
      });

      const result = await updateAward({ ...updatedFields, id: data!.id });

      if (result) {
        tossSuccess('Award updated');
        fetchAward();
      } else tossError('Error updating award');
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCloseout = async () => {
    setIsCloseoutDialogOpen(false);
    const status = 'closed';
    const result = await setCompliance(currentAwardId, status);
    if (result) {
      tossSuccess('Award moved to Clesed');
      fetchAward();
    } else {
      tossError('Error moving award to Closed');
    }
  };

  const documentPreSave = async () => {
    // console.log('documentPreSave');

    return awardEditForm.submitForm();
  };

  const goBack = () => {
    const proceedToNavigate = unsavedChanges ? alert('Please save changes before leaving') : true;

    if (proceedToNavigate) {
      navigate(`/dashboard/awards`, { state: { from: 'Details' } });
    }
  };

  const canSubmitForApproval = currentUserId === data?.assigneeId && data?.status === 'encumbered';
  const submittedForApproval = currentUserId === data?.assigneeId && data?.status === 'approval';

  const canApprove = userType.match('Admin') !== null && data?.status === 'approval';
  const canMarkCompliance =
    userType.match('Admin') !== null && data?.status === 'complianceRequired';

  const canSeeActionHistory =
    userType !== 'millenniumAnalyst' && userType !== 'millenniumResearcher';

  if (isNewAwardCreation && userType === 'userAdmin' && !awardEditForm.values.clientId)
    awardEditForm.setFieldValue('clientId', clientCreatorId);

  return (
    <>
      <Helmet>
        <title>Awards - {isNewAwardCreation ? 'Create' : 'Details'}</title>
      </Helmet>

      <Stack
        alignItems="center"
        sx={{ m: 'auto', maxWidth: isNewAwardCreation ? '860px' : '1600px' }}
      >
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          mb={3}
          sx={{ width: '100%' }}
        >
          <Stack alignItems="flex-start" direction="row" spacing={2}>
            <IconButton onClick={() => goBack()}>
              <ArrowBack htmlColor="black" />
            </IconButton>

            <Typography variant="h2">
              {(isNewAwardCreation && 'Create Award') || (
                <>
                  Award {data?.stringId}
                  <br />
                  <Typography variant="subtitle2">{data?.funder}</Typography>
                  <Typography variant="subtitle2">{data?.grantProgramName}</Typography>
                </>
              )}
            </Typography>
          </Stack>

          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
            {(canSubmitForApproval || submittedForApproval) && (
              <IconB
                disabled={!canSubmitForApproval}
                disableRipple
                onClick={async () => {
                  const result = await requestApproval(currentAwardId);
                  if (result) {
                    tossSuccess('Submitted for Approval');
                    refetchAwardAndTimeline();
                  } else tossError('Error Submitting for Approval');
                }}
                size="small"
                sx={{ borderRadius: 2, color: (theme) => theme.palette.primary.main }}
              >
                <CheckCircleOutlineRounded sx={{ mr: '4px' }} />

                <Typography sx={{ fontWeight: 600 }} variant="caption">
                  {submittedForApproval ? 'Submitted' : 'Submit'} for Approval
                </Typography>
              </IconB>
            )}

            {canApprove && !isNewAwardCreation && (
              <>
                <IconB
                  disableRipple
                  onClick={async () => {
                    const result = await approveAward(currentAwardId);
                    if (result) {
                      tossSuccess('Approved');
                      refetchAwardAndTimeline();
                    } else tossError('Error Approving Award');
                  }}
                  size="small"
                  sx={{ borderRadius: 2, color: (theme) => theme.palette.primary.main }}
                >
                  <CheckCircleOutlineRounded sx={{ mr: '4px' }} />

                  <Typography sx={{ fontWeight: 600 }} variant="caption">
                    Approve
                  </Typography>
                </IconB>

                <IconB
                  disableRipple
                  onClick={async () => {
                    const result = await rejectAward(currentAwardId);
                    if (result) {
                      tossSuccess('Rejected');
                      refetchAwardAndTimeline();
                    } else tossError('Error Rejecting Award');
                  }}
                  size="small"
                  sx={{ borderRadius: 2, color: (theme) => theme.palette.primary.main }}
                >
                  <HighlightOffRounded sx={{ mr: '4px' }} />
                  <Typography sx={{ fontWeight: 600 }} variant="caption">
                    Reject
                  </Typography>
                </IconB>
              </>
            )}

            {canMarkCompliance && (
              <IconB
                disableRipple
                onClick={async () => {
                  const result = await setCompliance(currentAwardId);
                  if (result) {
                    tossSuccess('Mark as In Compliance');
                    refetchAwardAndTimeline();
                  } else tossError('Error');
                }}
                size="small"
                sx={{ borderRadius: 2, color: (theme) => theme.palette.primary.main }}
              >
                <CheckCircleOutlineRounded sx={{ mr: '4px' }} />

                <Typography sx={{ fontWeight: 600 }} variant="caption">
                  Mark as In Compliance
                </Typography>
              </IconB>
            )}

            {/* {!isNewAwardCreation && (
              <SecondaryButton onClick={awardEditForm.resetForm}>Discard</SecondaryButton>
            )} */}

            {!isNewAwardCreation && (
              <Stack direction="row" spacing={2}>
                {showCloseOut && isTimelineStatusComplete && (
                  <StandardButton
                    onClick={() => setIsCloseoutDialogOpen(true)}
                    sx={{ backgroundColor: '#ff6b6b', color: '#fff' }}
                  >
                    Close-out
                  </StandardButton>
                )}
                <SecondaryButton onClick={() => navigate(`/dashboard/award/${awardId}/budget`)}>
                  Input / Update Budget
                </SecondaryButton>
                <SecondaryButton onClick={() => navigate(`/dashboard/award/${awardId}/roles`)}>
                  Assign Roles
                </SecondaryButton>
                <SecondaryButton onClick={() => navigate(`/dashboard/award/${awardId}/reporting`)}>
                  Manage Reports
                </SecondaryButton>
              </Stack>
            )}

            <StandardButton
              // disabled={awardEditForm.isValid}
              onClick={
                awardEditForm.isValid
                  ? awardEditForm.submitForm
                  : () => tossError('Please fill in all required fields')
              }
            >
              {isNewAwardCreation ? 'Create' : 'Save'}
            </StandardButton>
          </Stack>
        </Stack>
        {!isNewAwardCreation && <StatusTimeline />}

        <Row>
          <Col
            className="d-flex flex-column align-items-stretch"
            lg={isNewAwardCreation ? 12 : 6}
            sm={12}
            xl={isNewAwardCreation ? 12 : 8}
            xs={12}
          >
            <Details
              awardEditForm={awardEditForm}
              data={data}
              isNewAwardCreation={isNewAwardCreation}
              setUnsavedChanges={setUnsavedChanges}
            />
          </Col>
          {!isNewAwardCreation && (
            <Col className="d-flex align-items-stretch" lg={6} sm={12} xl={4} xs={12}>
              <Documents preSave={documentPreSave} />
            </Col>
          )}
        </Row>

        {!isNewAwardCreation && <Payments />}
        {!isNewAwardCreation && canSeeActionHistory && <ActionHistory />}
        {/* Close-out Confirmation Dialog */}
        <Dialog
          aria-labelledby="closeout-dialog-title"
          onClose={() => setIsCloseoutDialogOpen(false)}
          open={isCloseoutDialogOpen}
        >
          <DialogTitle id="closeout-dialog-title">Confirm Close-out</DialogTitle>
          <DialogContent>
            <Typography>
              The end date for this award has passed. Before clicking to ‘Close-out’ this award,
              please first make sure that all reports are filed, all payments are made, and the
              budget table is clean.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setIsCloseoutDialogOpen(false)}>
              Cancel
            </Button>
            <Button autoFocus color="primary" onClick={handleCloseout}>
              Confirm Close-out
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
}
