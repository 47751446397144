import { AxiosResponse } from 'axios';
import { ClientFile } from 'types/client';

import api, { outputError } from './apiService';

export const getClientFiles = (id: number): Promise<ClientFile[]> =>
  api
    .get(`/clients?page=1&perPage=10000&id=${id}&target=file`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data.rows;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });

export const downloadClientFile = (path: string) =>
  api
    .get(`/file/${path}`, { responseType: 'blob' })
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {};
    })
    .catch((error) => {
      outputError(error);
      return {};
    });

export const deleteClientFile = (id: number): Promise<boolean> =>
  api
    .delete('/clients', { data: { id, target: 'file' } })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
