import { Modal } from 'react-bootstrap';
import { InputLabel, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { tossWarning } from 'utils/toastTosser';

import InputField from 'shared/muiComponents/InputField';
import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';
import SelectField from 'shared/muiComponents/SelectField';
// import OutlinedInputField from 'shared/muiComponents/OutlinedInputField';
import CurrencyInputField from 'shared/muiComponents/CurrencyInputField';
import SearchFilter from 'shared/muiComponents/SearchFilter';

import { searchAwards } from 'services/awards/awardsService';
import { Award } from 'types/awards';
import { BudgetFormEntry } from '.';

interface MatchModalProps {
  toggle: () => void;
  data: BudgetFormEntry;
  onSave: (newValues: BudgetFormEntry) => void;
  awardMatchAmount: number;
  totalsMatchAmount: number;
}

function parseValue(value: string | number): number {
  // eslint-disable-next-line no-useless-escape
  return parseFloat(value.toString().replace(/[^0-9.\-]/g, '')) || 0;
}

export const matchTypes = ['cash', 'existingGrant', 'inKind', 'other'];

export default function MatchModal(props: MatchModalProps) {
  const { toggle, data, onSave, awardMatchAmount, totalsMatchAmount } = props;

  const amountRef = useRef<HTMLInputElement>(null);
  const balanceRef = useRef<HTMLInputElement>(null);

  const returnOn1stRender = useRef({
    amount: true,
    balance: true,
  });

  const [description, setDescription] = useState<string>(data.matchDescription);
  const [amount, setAmount] = useState(data.matchAmount || 0);
  const [balance, setBalance] = useState(data.matchBalance || 0);
  const [expended, setExpended] = useState(data.matchExpended || 0);
  const [matchType, setMatchType] = useState<BudgetFormEntry['matchType']>(data.matchType || '');
  const [selectedAward, setSelectedAward] = useState<Award>();

  // Prepopulate the award if the match source is awards
  useEffect(() => {
    if (data.matchSource === 'awards' && data?.matchAwardId) {
      searchAwards('id', data.matchAwardId.toString()).then((awards) => {
        if (awards.length) setSelectedAward(awards[0]);
      });
    }
  }, [setSelectedAward]);

  useEffect(() => {
    if (returnOn1stRender.current?.amount) {
      returnOn1stRender.current.amount = false;
      return;
    }

    // if (balance > 0)
    setBalance(parseValue(balance) - parseValue(expended));
    if (balanceRef.current) balanceRef.current.value = balance.toString();

    setAmount(parseValue(balance) + parseValue(expended));
    if (amountRef.current) amountRef.current.value = amount.toString();
  }, [expended]);

  useEffect(() => {
    if (returnOn1stRender.current?.balance) {
      returnOn1stRender.current.balance = false;
      return;
    }

    setAmount(parseValue(amount) + parseValue(balance));
    if (amountRef.current) amountRef.current.value = amount.toString();
  }, [balance]);

  const update = () => {
    const saveAttributes = {
      ...data,
      matchAmount: amount,
      matchExpended: expended,
      matchBalance: balance,
      matchDescription: description,
      matchType,
      ...(selectedAward?.id && { matchSource: 'awards', matchAwardId: selectedAward?.id }),
    };

    console.log('saveAttributes', saveAttributes);

    onSave(saveAttributes);

    if (awardMatchAmount > 0 && totalsMatchAmount > 0 && awardMatchAmount < totalsMatchAmount) {
      tossWarning('Match amount is higher than the total match amount');
    }

    toggle();
  };

  return (
    <Modal centered className="awards-modal" onHide={toggle} show>
      <Modal.Body>
        <Stack direction="column" px={2} py={2} spacing={4}>
          <Stack direction="column" spacing={4}>
            <Typography m={2} variant="h2">
              Match
            </Typography>

            <CurrencyInputField
              id="matchExpended"
              label="Expended"
              onSave={(v) => setExpended(parseValue(v))}
              required
              value={expended}
            />

            <CurrencyInputField
              ref={balanceRef}
              id="matchBalance"
              label="Balance"
              onSave={(v) => setBalance(parseValue(v))}
              required
              value={balance}
            />

            <CurrencyInputField
              ref={amountRef}
              id="matchAmount"
              label="Budget (Calculated)"
              onSave={() => {}}
              readonly
              value={parseValue(balance) + parseValue(expended)}
            />

            <SelectField
              handleChange={(value: string) => setMatchType(value as BudgetFormEntry['matchType'])}
              id="matchType"
              label="Type of Match"
              options={matchTypes}
              required
              value={matchType}
            />

            <InputField
              id="description"
              label={matchType === 'other' ? 'Other (Explanation)' : 'Description (Optional)'}
              onChange={setDescription}
              value={description}
            />

            {matchType === 'existingGrant' && (
              <Stack spacing={1}>
                <InputLabel>Award Source</InputLabel>
                <SearchFilter<Partial<Award>, false>
                  getLabel={(award) => award.grantProgramName || ''}
                  // No label for the search filter
                  // -
                  // label="Award Source"
                  multiple={false}
                  onChange={(award) => {
                    if (award) setSelectedAward(award as Award);
                  }}
                  // readOnly={readOnly}
                  searchFunction={async (input: string) =>
                    (await searchAwards('grantProgramName', input)).map((record) => ({
                      ...selectedAward,
                      id: record.id,
                      grantProgramName: record.grantProgramName,
                    }))
                  }
                  selected={selectedAward?.grantProgramName ? selectedAward : null}
                  width="100%"
                />
              </Stack>
            )}
          </Stack>
          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
            <SecondaryButton onClick={toggle}>Cancel</SecondaryButton>
            <StandardButton disabled={(!amount && amount !== 0) || !matchType} onClick={update}>
              Close
            </StandardButton>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
