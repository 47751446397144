import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { FileMinus, FileText } from 'react-feather';
import { Helmet } from 'react-helmet';
import { BootstrapTableRef } from 'react-bootstrap-table-next';
import dayjs from 'dayjs';

import useUserSession from 'hooks/useUserSession';

import filtersConfigs from 'constants/filtersConfigs';
import checkFilterValues from 'utils/helpers/checkFilterValues';
import splitAndReplaceDoubleCommas from 'utils/helpers/splitAndReplaceDoubleCommas';

import { FilterContext } from 'pages/Applications';

import ProgramsList from 'components/programs/ProgramsList';
import ApplicationFilters from 'components/applications/ApplicationFilters';
import ApplicationsList from 'components/applications/ApplicationsList';
import ProgramFilters from 'components/programs/ProgramFilters';
import DashboardStats from 'components/dashboard/DashboardStats';

import useQueryString from 'hooks/useQueryString';
import useWindowSize from 'hooks/useWindowSize';

import { getProgramsReadList, markProgramsAsRead, ReadProgram } from 'services/programService';
import { Program } from 'types/program';

interface Name {
  name: string;
}

export interface ProgramFilter {
  programNames?: Name[];
  category?: string;
  funders?: Name[];
  startDate?: string | null;
  endDate?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
}

interface ApplicationUser {
  id: string;
  name: string;
}

export interface ApplicationClient {
  id: string;
  name: string;
}

export interface ApplicationFilter {
  programNames?: Name[];
  users?: ApplicationUser[];
  clients?: ApplicationClient[];
  funders?: Name[];
  status?: string;
  startDate?: string | null;
  endDate?: string | null;
  endDateType?: string | null;
  sortBy?: string;
  sortOrder?: string;
}

export default function Dashboard() {
  const queryFilters = useQueryString();
  const location = useLocation();
  const currentUser = useUserSession();

  const pageName = location?.pathname?.split('/').at(-1);
  const storage = window.localStorage.getItem('filters');
  const storageFilter = storage && JSON.parse(storage);

  const windowSize = useWindowSize();
  const isMobile = !!(windowSize.width && windowSize.width < 768);

  // const APPLICATION_FILTERS_DEFAULT = { programNames: [], users: [], clients: [], funders: [], status: 'all', startDate: null, endDate: null },
  // 			PROGRAM_FILTERS_DEFAULT			= { programNames: [], category: '', funders: [], startDate: null, endDate: null };

  const defaultTab = queryFilters.get('t');
  const [activeTab, setActiveTab] = useState(defaultTab ? parseInt(defaultTab, 10) : 1);
  const [applicationFilters, setApplicationFilters] = useState(
    (pageName && storageFilter?.[pageName]?.applicationFilters) || {
      status: filtersConfigs.dashboard.applications.default.status,
      startDate: filtersConfigs.dashboard.applications.default.startDate,
      endDate: filtersConfigs.dashboard.applications.default.endDate,
      users: [{ id: currentUser.id, name: currentUser.name }],
    }
  );
  const [programFilters, setProgramFilters] = useState(
    (pageName && storageFilter?.[pageName]?.programFilters) || {
      startDate: filtersConfigs.dashboard.programs.default.startDate,
      ct: '',
      endDate: filtersConfigs.dashboard.programs.default.endDate,
      users: [{ id: currentUser.id, name: currentUser.name }],
      sortOrder: 'desc',
    }
  );
  const [applicationsPage, setApplicationsPage] = useState(1);
  const [programsPage, setProgramsPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [selected, setSelected] = useState<Program[]>([]);
  const [readPrograms, setReadPrograms] = useState<ReadProgram[]>([]);

  const [, setSearchParams] = useSearchParams();

  const programTableRef = useRef<BootstrapTableRef>(null);
  const appTableRef = useRef<BootstrapTableRef>(null);

  const switchTab = () => setActiveTab(activeTab === 0 ? 1 : 0);

  const syncProgramFilters = useCallback(() => {
    const filter: ProgramFilter | undefined = checkFilterValues(programFilters)
      ? (programFilters as ProgramFilter)
      : pageName && storageFilter?.[pageName]?.programFilters;
    const { programNames, category, funders, startDate, endDate, sortBy, sortOrder } = filter || {};

    const historyObject: { [key: string]: string } = { t: '0', p: programsPage.toString() };

    /*
			MIL-283:
			As the list of program names is comma-separated in the URL, if any of the names happens to
			contain commas, these commas will be treated as separators too, causing unintended behavior.
			To prevent that, I'm going to add escape mechanics for commas in names (and funders too, just
			in case): single commas will be replaced with double commas when passed to the URL.
		*/
    if (programNames && programNames.length > 0)
      historyObject.gp = programNames.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    if (funders && funders.length > 0)
      historyObject.f = funders.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    if ((category === '0' || category) && category !== 'all') historyObject.ct = category;
    if (startDate && dayjs(startDate).isValid())
      historyObject.sd = dayjs(startDate).format('YYYY-MM-DD');
    if (endDate && dayjs(endDate).isValid()) historyObject.ed = dayjs(endDate).format('YYYY-MM-DD');
    if (sortBy) historyObject.sb = sortBy;
    if (sortOrder) historyObject.so = sortOrder;

    setSearchParams(historyObject);
  }, [programFilters, programsPage]);

  const syncApplicationFilters = useCallback(() => {
    const filter: ApplicationFilter | undefined = checkFilterValues(applicationFilters)
      ? (applicationFilters as ApplicationFilter)
      : pageName && storageFilter?.[pageName]?.applicationFilters;

    const { programNames, users, clients, funders, status, startDate, endDate, sortBy, sortOrder } =
      filter || {};

    const historyObject: { [key: string]: string } = { t: '1', p: applicationsPage.toString() };

    /*
			MIL-283:
			As the list of program names is comma-separated in the URL, if any of the names happens to
			contain commas, these commas will be treated as separators too, causing unintended behavior.
			To prevent that, I'm going to add escape mechanics for commas in names (and funders too, just
			in case): single commas will be replaced with double commas when passed to the URL.
		*/

    if (programNames && programNames.length > 0) {
      historyObject.gp = programNames.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    }
    if (funders && funders.length > 0) {
      historyObject.f = funders.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    }
    if (users && users.length > 0) {
      historyObject.a = users.map((e) => `${e.id}|${e.name}`).join(',');
    }
    if (clients && clients.length > 0) {
      historyObject.c = clients.map((e) => `${e.id}|${e.name}`).join(',');
    }
    if (status) historyObject.s = status;
    if (startDate && dayjs(startDate).isValid()) {
      historyObject.sd = dayjs(startDate).format('YYYY-MM-DD');
    }
    if (endDate && dayjs(endDate).isValid()) {
      historyObject.ed = dayjs(endDate).format('YYYY-MM-DD');
    }
    if (sortBy) historyObject.sb = sortBy;
    if (sortOrder) historyObject.so = sortOrder;

    setSearchParams(historyObject);
  }, [applicationFilters, applicationsPage]);

  useEffect(() => {
    // To prevent URL flickering.
    if (!programFilters) return;

    syncProgramFilters();
  }, [programFilters, syncProgramFilters]);

  useEffect(() => {
    // To prevent URL flickering.
    if (!applicationFilters) return;

    syncApplicationFilters();
  }, [applicationFilters, syncApplicationFilters]);

  useEffect(() => {
    if (activeTab === 0) {
      syncProgramFilters();
    } else if (activeTab === 1) {
      syncApplicationFilters();
    }
  }, [activeTab, syncApplicationFilters, syncProgramFilters]);

  const readProgramFiltersFromURL = (query: URLSearchParams) => {
    const page = query.get('p');
    const grantPrograms = query.get('gp');
    const category = query.get('ct');
    const funders = query.get('f');
    const startDate = query.get('sd');
    const endDate = query.get('ed');
    const sortBy = query.get('sb');
    const sortOrder = query.get('so');

    setProgramsPage(Number.isNaN(page) || !page ? 1 : parseInt(page, 10));

    // Safe to not address exceptions for startDate and endDate because if they
    // don't exist among params, they will be equal to null according to js docs.

    return {
      programNames: grantPrograms ? splitAndReplaceDoubleCommas(grantPrograms) : [],
      funders: funders ? splitAndReplaceDoubleCommas(funders) : [],
      category: category ?? 'all',
      startDate,
      endDate,
      sortBy,
      sortOrder,
    };
  };

  const resetApplicationsPage = () => {
    setApplicationsPage(1);
  };

  const resetProgramsPage = () => {
    setProgramsPage(1);
  };

  const readApplicationFiltersFromURL = (query: URLSearchParams) => {
    const page = query.get('p');
    const grantPrograms = query.get('gp');
    const users = query.get('a');
    const clients = query.get('c');
    const status = query.get('s');
    const funders = query.get('f');
    const startDate = query.get('sd');
    const endDate = query.get('ed');
    const sortBy = query.get('sb');
    const sortOrder = query.get('so');

    setApplicationsPage(Number.isNaN(page) || !page ? 1 : parseInt(page, 10));

    // Safe to not address exceptions for startDate and endDate because if they
    // don't exist among params, they will be equal to null according to js docs.

    return {
      programNames: grantPrograms ? splitAndReplaceDoubleCommas(grantPrograms) : [],
      users: users
        ? users.split(',').map((e) => {
            const arrUser = e.split('|');
            return { id: parseInt(arrUser[0], 10), name: arrUser[1] };
          })
        : [],
      clients: clients
        ? clients.split(',').map((e) => {
            const arrClient = e.split('|');
            return { id: parseInt(arrClient[0], 10), name: arrClient[1] };
          })
        : [],
      funders: funders ? splitAndReplaceDoubleCommas(funders) : [],
      status,
      startDate,
      endDate,
      sortBy,
      sortOrder,
    };
  };

  useEffect(() => {
    // Parse the query string once per page load. Don't do that on each tab switch.
    // This solution is stable, even though eslint doesn't quite like it.
    const tab = parseInt(queryFilters.get('t') || '', 10);

    if (tab === 0) {
      const filtersFromUrl = readProgramFiltersFromURL(queryFilters);
      const filter = checkFilterValues(filtersFromUrl)
        ? filtersFromUrl
        : pageName && storageFilter?.[pageName]?.programFilters;

      setActiveTab(0);
      setProgramFilters(filter);
    } else if (tab === 1) {
      const filtersFromUrl = readApplicationFiltersFromURL(queryFilters);
      const filter = checkFilterValues(filtersFromUrl)
        ? filtersFromUrl
        : pageName && storageFilter?.[pageName]?.applicationFilters;

      setActiveTab(1);
      setApplicationFilters(filter);
    }
  }, []);

  const readList = async (userId: number) => {
    const list = await getProgramsReadList(String(userId));
    setReadPrograms(list);
  };

  useEffect(() => {
    if (location?.pathname?.includes('user-dashboard')) {
      readList(currentUser.id);
    }
  }, [location]);

  const startMultipleEdit = () => {
    setIsEditing((prevState) => !prevState);
  };

  const cancelMultipleEdit = () => {
    setIsEditing(false);
    if (appTableRef?.current?.selectionContext?.selected?.length) {
      appTableRef.current.selectionContext.selected = [];
    }
    setSelected([]);
  };

  const setAsRead = async () => {
    const result = await markProgramsAsRead({ selected, currentUser });
    if (result) {
      cancelMultipleEdit();
      await readList(currentUser.id);
    }
  };

  return (
    <>
      <Helmet>
        <title>{activeTab === 1 ? 'Grant Applications' : 'Grant Programs'} - Grantrack</title>
      </Helmet>
      <FilterContext.Provider
        value={{
          activeTab,
          appFilterFound: null,
          progFilterFound: null,
          applicationFilters,
          programFilters,
          setApplicationFilters,
          setProgramFilters,
          applicationsPage,
          setApplicationsPage,
          programsPage,
          setProgramsPage,
        }}
      >
        <Container className="filter-header p-0" fluid>
          <Row className="d-flex justify-content-start align-items-end">
            <Col className="d-none d-md-flex justify-content-start align-items-start " xs={12}>
              <Col className="d-none d-md-flex justify-content-end align-items-end" xs={12} />
            </Col>
            <Col>
              {!isMobile && (
                <div className="filter-body">
                  {activeTab === 0 && (
                    <ProgramFilters
                      cancelMultipleEdit={cancelMultipleEdit}
                      onManualChange={resetProgramsPage}
                      selected={selected}
                      setAsRead={setAsRead}
                      startMultipleEdit={startMultipleEdit}
                      switchTab={switchTab}
                    />
                  )}
                  {activeTab === 1 && (
                    <ApplicationFilters
                      onManualChange={resetApplicationsPage}
                      switchTab={switchTab}
                    />
                  )}
                </div>
              )}
            </Col>

            <Col className="pt-3 d-md-none" xl={3}>
              {isMobile && (
                <div className="application-sections mx-md-3">
                  <input
                    defaultChecked={activeTab === 0}
                    id="section-progs"
                    name="application-section"
                    onChange={switchTab}
                    type="radio"
                    value="0"
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    className="d-flex justify-content-center align-items-center"
                    htmlFor="section-progs"
                  >
                    <FileMinus
                      size={16}
                      style={{
                        minWidth: '16px',
                        maxWidth: '16px',
                        minHeight: '16px',
                        maxHeight: '16px',
                      }}
                    />
                    &nbsp;&nbsp;Programs
                  </label>
                  <input
                    defaultChecked={activeTab === 1}
                    id="section-apps"
                    name="application-section"
                    onChange={switchTab}
                    type="radio"
                    value="1"
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    className="d-flex justify-content-center align-items-center"
                    htmlFor="section-apps"
                  >
                    <FileText
                      size={16}
                      style={{
                        minWidth: '16px',
                        maxWidth: '16px',
                        minHeight: '16px',
                        maxHeight: '16px',
                      }}
                    />
                    &nbsp;&nbsp;Applications
                  </label>
                </div>
              )}
              {activeTab === 0 && (
                <ProgramFilters
                  cancelMultipleEdit={cancelMultipleEdit}
                  isMobile={isMobile}
                  onManualChange={resetProgramsPage}
                  selected={selected}
                  setAsRead={setAsRead}
                  startMultipleEdit={startMultipleEdit}
                  switchTab={switchTab}
                />
              )}
              {activeTab === 1 && (
                <ApplicationFilters
                  isMobile={isMobile}
                  onManualChange={resetApplicationsPage}
                  switchTab={switchTab}
                />
              )}
            </Col>
          </Row>
        </Container>

        <div className="mt-4" />

        {activeTab === 1 ? (
          <>
            <DashboardStats userId={currentUser.id} />
            <ApplicationsList appTableRef={appTableRef} checkFilterValues={checkFilterValues} />
          </>
        ) : (
          <ProgramsList
            checkFilterValues={checkFilterValues}
            isEditing={isEditing}
            programTableRef={programTableRef}
            readList={readList}
            readPrograms={readPrograms}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </FilterContext.Provider>
    </>
  );
}
