import isEqual from 'lodash/isEqual';
import { capitalize } from 'lodash';

export function updateIfChanged<T>(setter: (fn: (previousValue: T) => T) => void, value: T) {
  if (!setter) return;
  setter((previousValue: T) => (isEqual(previousValue, value) ? previousValue : value));
}

export function formatCurrencyValue(value?: number | string | null) {
  if (!value) return '$0.00';

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    typeof value === 'number' ? value : parseFloat(value || '0')
  );
}

export function scrollToBottom() {
  document.body.scrollTop = document.body.scrollHeight;
  document.documentElement.scrollTop = document.body.scrollHeight;
  window.scrollTo(0, document.body.scrollHeight);
}

export function camelCaseToWords(camelCase: string) {
  if (!camelCase) return '';

  // Split camel case string into words
  const words = camelCase.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');

  // Capitalize each word and join them with a space
  return capitalize(words[0]) + words.map((w, i) => (i === 0 ? '' : capitalize(w))).join(' ');
}

export function toArray(values?: string | string[]) {
  if (Array.isArray(values)) return values;
  if (typeof values === 'string') return [values];
  return [];
}
