import { createContext, useState, useEffect, useCallback, ReactNode, useMemo } from 'react';
import { Award, AwardDocument, AwardTimelineStep, Notifications } from 'types/awards';
import {
  getAwardById,
  getAwardTimeline,
  NotificationsList,
  updateAward,
} from 'services/awards/awardsService';
import { useParams, Outlet } from 'react-router-dom';
import { getDocumentsByAwardId } from 'services/awards/awardDocumentsService';

interface Data<T> {
  data: T | null;
  loading: boolean;
  error: boolean;
}

export type DocumentCategory = 'initial' | 'contract' | 'report' | 'related';

export interface DocumentsFilters {
  search: string;
  sortBy: 'name' | 'dateAdded' | 'fileType' | 'default';
  sortOrder: 'asc' | 'desc';
  includedCategories: DocumentCategory[];
}

export const defaultDocumentsFilters: DocumentsFilters = {
  search: '',
  sortBy: 'default',
  sortOrder: 'asc',
  includedCategories: ['initial', 'contract', 'report', 'related'],
};

interface AwardContext {
  currentAwardId: string;
  isNewAwardCreation: boolean;
  setCurrentAwardId: (awardId: string) => void;
  awardData: Data<Award>;
  fetchAward: () => void;
  updateTotals: (awardAmount: number, matchAmount: number) => void;
  timelineData: Data<AwardTimelineStep[]>;
  fetchTimeline: () => void;
  documentsData: Data<AwardDocument[]>;
  fetchDocuments: () => void;
  documentsFilters: DocumentsFilters;
  updateDocumentsFilters: (update: Partial<DocumentsFilters>) => void;
  notesData: Data<NotificationsList[]>;
  refetchAwardAndTimeline: () => void;
}

const AwardContext = createContext<AwardContext | null>(null);

function AwardContextProvider({ children }: { children: ReactNode }): JSX.Element {
  const params = useParams();

  const [currentAwardId, setCurrentAwardId] = useState('');
  const [isNewAwardCreation, setIsNewAwardCreation] = useState(false);

  const [awardData, setAwardData] = useState<Data<Award>>({
    data: null,
    loading: false,
    error: false,
  });

  const [timelineData, setTimelineData] = useState<Data<AwardTimelineStep[]>>({
    data: null,
    loading: false,
    error: false,
  });

  const [documentsData, setDocumentsData] = useState<Data<AwardDocument[]>>({
    data: null,
    loading: false,
    error: false,
  });

  const [notesData, setNotesData] = useState<Data<NotificationsList[]>>({
    data: null,
    loading: false,
    error: false,
  });

  const [documentsFilters, setDocumentsFilters] = useState(defaultDocumentsFilters);

  const fetchAward = useCallback(async () => {
    if (currentAwardId && currentAwardId !== 'new') {
      setAwardData((prevState) => ({ ...prevState, loading: true }));
      setNotesData((prevState) => ({ ...prevState, loading: true }));

      const result = await getAwardById(currentAwardId);

      if (result) {
        setAwardData((prevState) => ({ ...prevState, data: result, loading: false }));
        setNotesData((prevState) => ({
          ...prevState,
          data: result.notifications,
          loading: false,
        }));
      } else setAwardData((prevState) => ({ ...prevState, error: true, loading: false }));
    }
  }, [currentAwardId]);

  const updateTotals = useCallback(
    async (awardAmount: number, matchAmount: number) => {
      const awardExpended = awardData.data?.awardExpended || 0;
      const matchExpended = awardData.data?.matchExpended || 0;
      const UpdatedState = {
        awardAmount,
        matchAmount,
        awardExpended,
        matchExpended,
        awardBalance: awardExpended === 0 ? awardAmount : awardData.data?.awardBalance || 0,
        matchBalance: matchExpended === 0 ? matchAmount : awardData.data?.matchBalance || 0,
      };

      setAwardData((prevState) => ({
        ...prevState,
        data: {
          ...(awardData.data as Award),
          ...UpdatedState,
        },
      }));

      await updateAward({
        // ...(awardData.data as Award),
        id: Number(currentAwardId),
        ...UpdatedState,
      });

      fetchAward();
    },
    [currentAwardId]
  );

  const fetchTimeline = useCallback(async () => {
    if (currentAwardId) {
      setTimelineData((prevState) => ({ ...prevState, loading: true }));

      const result = await getAwardTimeline(currentAwardId);

      if (result) {
        setTimelineData((prevState) => ({ ...prevState, data: result, loading: false }));
      } else setTimelineData((prevState) => ({ ...prevState, error: true, loading: false }));
    }
  }, [currentAwardId]);

  const fetchDocuments = useCallback(async () => {
    if (currentAwardId) {
      setDocumentsData((prevState) => ({ ...prevState, loading: true }));

      const result = await getDocumentsByAwardId(currentAwardId);

      if (result) {
        setDocumentsData((prevState) => ({ ...prevState, data: result, loading: false }));
      } else setDocumentsData((prevState) => ({ ...prevState, error: true, loading: false }));
    }
  }, [currentAwardId]);

  const updateDocumentsFilters = useCallback(
    (update: Partial<DocumentsFilters>) => {
      setDocumentsFilters({ ...documentsFilters, ...update });
    },
    [documentsFilters, setDocumentsFilters]
  );

  const fetchAllDataForAward = async () => {
    await fetchAward();
    await fetchTimeline();
    await fetchDocuments();
  };

  const refetchAwardAndTimeline = () => {
    // to show progress for user immediately when they return to award page
    // small delay to make sure API had some time to handle status sync
    setTimeout(() => {
      fetchAward();
      fetchTimeline();
    }, 800);
  };

  useEffect(() => {
    fetchAllDataForAward();
  }, [currentAwardId]);

  useEffect(() => {
    const { awardId } = params;

    if (awardId === 'new') setIsNewAwardCreation(true);
    else if (awardId && Number.isInteger(Number(awardId))) {
      setCurrentAwardId(awardId);
      setIsNewAwardCreation(false);
    }
  }, [params]);

  const contextValue = useMemo(
    () => ({
      currentAwardId,
      isNewAwardCreation,
      setCurrentAwardId,
      awardData,
      fetchAward,
      updateTotals,
      timelineData,
      fetchTimeline,
      documentsData,
      fetchDocuments,
      documentsFilters,
      updateDocumentsFilters,
      notesData,
      refetchAwardAndTimeline,
    }),
    [
      currentAwardId,
      isNewAwardCreation,
      awardData,
      timelineData,
      documentsData,
      fetchAward,
      updateTotals,
      fetchTimeline,
      fetchDocuments,
      documentsFilters,
      updateDocumentsFilters,
    ]
  );

  return <AwardContext.Provider value={contextValue}>{children}</AwardContext.Provider>;
}

function AwardContextWrapper() {
  return (
    <AwardContextProvider>
      <Outlet />
    </AwardContextProvider>
  );
}

export { AwardContextWrapper, AwardContext };
