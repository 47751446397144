import { Badge } from '@mui/material';

export default function UserStatsBadge({
  cell,
  warning = false,
}: {
  cell: string;
  warning?: boolean;
}) {
  return (
    <Badge
      badgeContent={cell}
      color="primary"
      sx={{
        '& .MuiBadge-badge': {
          fontWeight: 700,
          color: '#3C5D9C',
          backgroundColor: !warning ? '#E9F3EB' : '#FBE9E9',
        },
      }}
    />
  );
}
