import { SyntheticEvent } from 'react';
import dayjs from 'dayjs';
import { ColumnDescription } from 'react-bootstrap-table-next';
// import { format } from 'date-fns';
import { Stack, IconButton } from '@mui/material';

import ContentCopy from '@mui/icons-material/ContentCopy';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';

import { AwardPayments, AwardReport } from 'types/awards';
import { camelCaseToWords, formatCurrencyValue } from 'utils/utilFunctions';
import ReportChip from './ReportChip';
import AwardDocumentDropzone from '../Documents/AwardDocumentDropzone';

const getReportsColumns = (
  setHoveredCell: (event: HTMLElement | null) => void,
  setSelectedReport: (data: AwardReport) => void,
  refetchReports: () => void,
  readOnly: boolean,
  remove: (id: number) => void,
  duplicate: (data: AwardReport) => void
): ColumnDescription<AwardReport>[] => {
  const eventsHandlers = {
    onMouseEnter: (event: SyntheticEvent) => {
      setHoveredCell(event.target instanceof HTMLElement ? event.target : null);
    },
  };

  const columns: ColumnDescription<AwardReport>[] = [
    {
      dataField: 'name',
      text: 'Report',
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Report Type',
      sort: false,
      formatter: (type) => camelCaseToWords(type),
    },
    {
      dataField: 'startsOn',
      text: 'Start Date',
      formatter: (date: string | null) => (date ? dayjs(date).utc().format('MM/DD/YYYY') : '-'),
      sort: true,
    },
    {
      dataField: 'endsOn',
      text: 'End Date',
      formatter: (date: string | null) => (date ? dayjs(date).utc().format('MM/DD/YYYY') : '-'),
      sort: true,
    },
    {
      dataField: 'dueDate',
      text: 'Due Date',
      formatter: (date: string | null) => (date ? dayjs(date).utc().format('MM/DD/YYYY') : '-'),
      sort: true,
    },
    {
      dataField: 'payments',
      text: 'Payment',
      formatter: (payments: AwardPayments[]) =>
        payments.length > 0 ? (
          <ReportChip key={payments[0].id} paymentStatus={payments[0].status} />
        ) : null,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (_cell, row) =>
        row.payments[0]?.amount ? formatCurrencyValue(row.payments[0].amount) : '-',
    },
    {
      dataField: '',
      text: 'Upload Documents',
      formatter: (cell, row) => (
        <AwardDocumentDropzone callback={refetchReports} category="report" reportId={row.id} />
      ),
    },
    ...(readOnly
      ? []
      : [
          {
            dataField: 'actions',
            text: '',
            isDummyField: true,
            formatter: (_cell: string, row: AwardReport) => {
              return (
                <Stack direction="row" justifyContent="center" spacing={4}>
                  <IconButton
                    aria-label="Edit"
                    className="table-hover-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedReport(row);
                    }}
                    sx={{
                      visibility: 'hidden',
                      borderRadius: 'initial',
                      backgroundColor: '#EFF1F6',
                    }}
                  >
                    <BorderColorIcon />
                  </IconButton>

                  <IconButton
                    aria-label="Duplicate"
                    className="table-hover-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      duplicate(row);
                    }}
                    sx={{
                      visibility: 'hidden',
                      borderRadius: 'initial',
                      backgroundColor: '#EFF1F6',
                    }}
                  >
                    <ContentCopy />
                  </IconButton>

                  <IconButton
                    aria-label="Delete"
                    className="table-hover-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      remove(row.id);
                    }}
                    sx={{
                      visibility: 'hidden',
                      borderRadius: 'initial',
                      backgroundColor: '#EFF1F6',
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              );
            },
          },
        ]),
  ];
  return columns.map((column) => ({ ...column, events: eventsHandlers }));
};

export default getReportsColumns;
