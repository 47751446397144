import { useEffect, useState } from 'react';
import FilterSelect from 'shared/muiComponents/FilterSelect';
import { applicationsConfigs } from '../../constants/globalConstants';

interface StatusFilterProps {
  status: string;
  onChange: (newValue: string) => void;
  isDashboard: boolean;
  width?: string;
}

export default function StatusFilter({ status, onChange, isDashboard, width }: StatusFilterProps) {
  const [currentStatus, setCurrentStatus] = useState(status || '');

  useEffect(() => {
    setCurrentStatus(status || '');
  }, [status]);

  const handleChange = (value?: string) => {
    const newValue = value || '';
    setCurrentStatus(newValue);
    onChange(newValue);
  };

  const getValueLabel = (value?: string) => {
    if (!value) return 'Select Status';
    const filterStatusesList = isDashboard
      ? applicationsConfigs.filterStatuses
      : applicationsConfigs.filterStatusesGrants;
    return (
      Object.entries(filterStatusesList).find(([, filterKey]) => filterKey === value)?.[0] || ''
    );
  };

  const options = Object.values(
    isDashboard ? applicationsConfigs.filterStatuses : applicationsConfigs.filterStatusesGrants
  );

  return (
    <FilterSelect
      getValueLabel={getValueLabel}
      label="Status"
      onChange={handleChange}
      options={options}
      selected={currentStatus}
      width={width}
    />
  );
}
