import { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

interface UserOption {
  id: number;
  name: string;
}

interface UserSelectProps {
  readOnly?: boolean;
  options?: UserOption[];
  defaultValue?: UserOption;
  setSelectedId: (userId?: number) => void;
}

export default function UserSelect({
  readOnly,
  options,
  defaultValue,
  setSelectedId,
}: UserSelectProps) {
  const [selected, setSelected] = useState<UserOption | undefined>();

  useEffect(() => {
    setSelected(defaultValue);
  }, []);

  useEffect(() => {
    setSelectedId(selected?.id);
  }, [selected]);

  useEffect(() => {
    if (options && !options.some((option) => option.id === selected?.id)) setSelected(undefined);
  }, [options]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="w-100 text-left form-control"
        disabled={readOnly || options?.length === 0}
        variant="outline-select"
      >
        {selected?.name || (readOnly ? 'No permissions for editing this list' : 'Select User')}
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100">
        {options?.map((user) => (
          <Dropdown.Item key={user.id} onClick={() => setSelected(user)}>
            {user.name}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => setSelected(undefined)}>None</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
