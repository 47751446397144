import { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { Button, Container, Row, Col, Modal } from 'react-bootstrap';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { saveAs } from 'file-saver';
import { Download } from 'react-feather';
import dayjs from 'dayjs';

import useKeyPress from 'hooks/useKeyPress';
import useUserSession from 'hooks/useUserSession';

import { Client } from 'types/client';
import { getClientMemoData } from 'services/clientService';
import { angularParser, loadFile, DocxMimeType } from 'services/fileService';
import 'assets/scss/ClientList.scss';

interface ClientMemoGenProps {
  client: Client;
}

export default function ClientMemoGen({ client }: ClientMemoGenProps) {
  const currentUser = useUserSession();

  const escPress = useKeyPress('Esc');

  const [open, setOpen] = useState(false);

  const [includeDN, setIncludeDN] = useState(false);
  const [includeMR, setIncludeMR] = useState(false);
  const [includePP, setIncludePP] = useState(false);
  const [orientation, setOrientation] = useState('pt');

  const toggle = () => setOpen((prevState) => !prevState);

  const generateDocument = (docData: unknown) => {
    loadFile(
      `${process.env.REACT_APP_API_URL}/templates/client-memo-${orientation}.docx`,
      (fileError, content) => {
        if (fileError) throw fileError;

        const dateToday = dayjs().format('YYYY-MM-DD');
        const clientName = client.name ?? 'Unnamed';

        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { parser: angularParser });
        doc.setData(docData);

        doc.render();

        const out = doc.getZip().generate({
          type: 'blob',
          mimeType: DocxMimeType,
          compression: 'DEFLATE',
        });

        saveAs(out, `[${dateToday}] ${clientName} - Memorandum.docx`);
      }
    );
  };

  const generateOutput = async () => {
    const docData = await getClientMemoData({
      clientId: client.id,
      employeeId: client.primaryUserId || currentUser.id,
      contractStartedAt: client.contractStartedAt,
      includeDepartmentNotified: includeDN,
      includeMatchRequirements: includeMR,
      includePerformancePeriod: includePP,
    });

    generateDocument(docData);
  };

  useEffect(() => {
    if (!escPress && open) toggle();
  }, [escPress]);

  const readOnlyAccess =
    currentUser.userType !== 'millenniumAnalyst' &&
    currentUser.userType !== 'millenniumAdmin' &&
    !client.applicationUsers?.some((e) => e.id === currentUser?.id);

  return (
    <>
      <Button
        className="d-flex justify-content-center align-items-center generate-memo-btn"
        disabled={readOnlyAccess}
        onClick={toggle}
        style={{ width: 'max-content' }}
        variant="light"
      >
        <Download size="16" style={{ marginBottom: '2px' }} />
        &nbsp; Generate Memo
      </Button>

      <Modal centered className="custom-modal" onHide={toggle} show={open} size="md">
        <Modal.Header style={{ borderBottom: 'none', paddingBottom: '0' }}>
          <Modal.Title className="gen-memo-heading">Generate Client Monthly Memo</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: '0', overflowY: 'auto', maxHeight: '70vh' }}>
          <Form>
            <Container className="generate-memo-form" fluid>
              <Row>
                <p className="memo-modal-tagline">
                  Include any extra information in the memo that you need to present.
                </p>
                <h5 className="memo-subheading">Include in the Memo</h5>
                <hr className="w-100" style={{ borderTop: '1px solid #e9ecef' }} />
              </Row>
              <Row>
                <Col className="col-md-8">
                  <FormGroup>
                    <CustomInput
                      checked={includeDN}
                      className="custom-client-toggle"
                      id="includeDN"
                      label="Department Notified"
                      labelStyle={{ fontSize: '14px', color: '#252933', fontWeight: '600' }}
                      name="includeDNSwitch"
                      onChange={(e) => setIncludeDN(e.target.checked)}
                      style={{ marginBottom: '1rem' }}
                      type="switch"
                    />
                  </FormGroup>

                  <FormGroup>
                    <CustomInput
                      checked={includeMR}
                      className="custom-client-toggle"
                      id="includeMR"
                      label="Match Requirements"
                      labelStyle={{ fontSize: '14px', color: '#252933', fontWeight: '600' }}
                      name="includeMRSwitch"
                      onChange={(e) => setIncludeMR(e.target.checked)}
                      style={{ marginBottom: '1rem' }}
                      type="switch"
                    />
                  </FormGroup>

                  <FormGroup>
                    <CustomInput
                      checked={includePP}
                      className="custom-client-toggle"
                      id="includePP"
                      label="Period of Performance"
                      labelStyle={{ fontSize: '14px', color: '#252933', fontWeight: '600' }}
                      name="includePPSwitch"
                      onChange={(e) => setIncludePP(e.target.checked)}
                      style={{ marginBottom: '1rem' }}
                      type="switch"
                    />
                  </FormGroup>

                  <div className="mt-3" />

                  <FormGroup>
                    <p className="memo-subheading">Orientation</p>
                    <FormGroup check>
                      <Label
                        check
                        style={{ fontSize: '14px', color: '#252933', fontWeight: '600' }}
                      >
                        <Input
                          defaultChecked={orientation === 'pt'}
                          name="memo_orientation"
                          onChange={() => setOrientation('pt')}
                          style={{
                            width: '18px',
                            height: '18px',
                            marginRight: '0.5rem',
                            accentColor: '#5F77A5',
                            border: '2px solid #5F77A5',
                            transform: 'translateY(-2px)',
                          }}
                          type="radio"
                        />{' '}
                        Portrait
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label
                        check
                        style={{ fontSize: '14px', color: '#252933', fontWeight: '600' }}
                      >
                        <Input
                          defaultChecked={orientation === 'ls'}
                          name="memo_orientation"
                          onChange={() => setOrientation('ls')}
                          style={{
                            width: '18px',
                            height: '18px',
                            marginRight: '0.5rem',
                            marginBottom: '0.5rem',
                            accentColor: '#5F77A5',
                            border: '2px solid #5F77A5',
                            transform: 'translateY(-2px)',
                          }}
                          type="radio"
                        />{' '}
                        Landscape
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: 'none', paddingTop: '0' }}>
          <Button
            onClick={toggle}
            style={{ fontSize: '0.875rem', fontWeight: '500' }}
            variant="light"
          >
            Cancel
          </Button>
          <Button
            className="d-flex justify-content-center align-items-center"
            onClick={generateOutput}
            variant="primary"
          >
            <Download size="16" style={{ marginBottom: '2px', marginRight: '0.5rem' }} /> Download
            Memo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
