/* eslint-disable no-nested-ternary */
import { datadogLogs } from '@datadog/browser-logs';

const { host } = window.location;
const env = host.includes('localhost')
  ? 'dev'
  : host.includes('staging')
    ? 'staging'
    : host.includes('mock')
      ? 'mock'
      : 'live';

datadogLogs.init({
  // TODO: move to ENV
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || 'pubbbf682cc81aaa35ed8c00d85bba4d0f3', // || '229dc25cfa96c261ed7bc6b6e0c77b84ec934474',
  site: 'us5.datadoghq.com',
  env,
  sessionSampleRate: 100,
  forwardErrorsToLogs: true,
  beforeSend: () => env !== 'dev',
});

const loggerName =
  env === 'dev' || env === 'staging'
    ? `Grantrack-Staging`
    : env === 'mock'
      ? `Grantrack-Mock`
      : `Grantrack-Live`;

const logger = datadogLogs.createLogger(loggerName);

// logger.info('Logger initialized');

export default logger;
