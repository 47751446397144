import Chip from '@mui/material/Chip';
import { camelCaseToWords } from 'utils/utilFunctions';

const statusColors = {
  reimbursed: '#31816C',
  paid: '#2C4474',
};

export default function ReportChip({ paymentStatus }: { paymentStatus: string | null }) {
  return (
    <Chip
      label={paymentStatus ? camelCaseToWords(paymentStatus) : 'Not Paid'}
      size="small"
      sx={{
        color: 'white',
        border: 'none',
        fontSize: '0.75rem',
        fontWeight: 800,
        borderRadius: '3px',
        backgroundColor: paymentStatus
          ? statusColors[paymentStatus as keyof typeof statusColors]
          : '#5F77A5',
      }}
      variant="outlined"
    />
  );
}
