import React from 'react';

interface CustomTooltipProps {
  hoveredCell: HTMLElement | null;
  tooltipText: string;
}

function CustomTooltip({ hoveredCell, tooltipText }: CustomTooltipProps) {
  if (!hoveredCell) return null;

  const rect = hoveredCell.getBoundingClientRect();

  return (
    <div
      style={{
        position: 'absolute',
        top: rect.top - 70 + window.scrollY,
        left: rect.left - 70 + window.scrollX,
        color: 'white',
        fontFamily: 'Raleway',
        fontWeight: 600,
        padding: '5px 9px',
        borderRadius: '4px',
        fontSize: '12px',
        zIndex: 1000,
        backgroundColor: '#2C4474',
      }}
    >
      {tooltipText}
    </div>
  );
}

export default CustomTooltip;
