import { AppBar, Toolbar, Link } from '@mui/material';

import millenniumLogo from 'assets/images/logo.png';
import UserMenu from 'components/layout/UserMenu';
import HeaderNavBar from './HeaderNavBar';

export default function Header() {
  return (
    <AppBar
      sx={{
        width: '100%',
        backgroundColor: (theme) => theme.palette.primary.main,
        position: 'fixed',
        zIndex: 1100,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Link
          href="/"
          sx={{
            mixBlendMode: 'screen',
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
          }}
        >
          <img alt="logo" className="normallogo" height="40" src={millenniumLogo} />
        </Link>

        <HeaderNavBar />

        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}
