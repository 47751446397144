import { useContext } from 'react';
import { AwardContext } from './AwardContext';

const useCurrentAward = () => {
  const context = useContext(AwardContext);

  if (!context) {
    throw new Error('Trying to use Award Context outside of Provider');
  }

  return context;
};

export default useCurrentAward;
