/* eslint-disable no-use-before-define */

import { useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Card } from 'react-bootstrap';
import { formatCurrencyValue } from 'utils/utilFunctions';

import { AwardPayments } from 'types/awards';

import { AwardPaymentsList, getAwardPayments } from 'services/awards/awardPaymentsService';
import useCurrentAward from 'hooks/useCurrentAward';
import useUserSession from 'hooks/useUserSession';

import StandardButton from 'shared/muiComponents/StandardButton';
import PaymentsAwardTable from './PaymentsAwardTable';
import NewPaymentModal from './NewPaymentModal';

const defaultFiltersSet = { sortBy: 'id', sortOrder: 'desc' };

export default function Payments() {
  const [searchParams, setSearchParams] = useState<Record<string, string>>(defaultFiltersSet);
  const { userType } = useUserSession();
  const { currentAwardId, awardData, setCurrentAwardId } = useCurrentAward();
  const award = awardData.data;

  useEffect(() => {
    setCurrentAwardId(currentAwardId);
    if (currentAwardId !== '') refetchPayments();
  }, [currentAwardId, setCurrentAwardId]);

  const [data, setData] = useState<AwardPaymentsList>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const refetchPayments = useCallback(async () => {
    if (currentAwardId && isMounted.current) {
      setLoading(true);

      const result = await getAwardPayments(
        currentAwardId,
        `?${new URLSearchParams(searchParams).toString()}`
      );

      if (result) {
        setData(result);
        setError(false);
      } else setError(true);

      setLoading(false);
    }
  }, [searchParams, currentAwardId, setCurrentAwardId]);

  useEffect(() => {
    refetchPayments();
  }, [searchParams]);

  const readOnly = userType === 'millenniumAnalyst' || userType === 'millenniumResearcher';

  return (
    <Card className="w-100">
      <Card.Header>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h2">
            Payments
            <br />
            <Typography variant="caption">
              Payments Received vs. Requested{' '}
              {formatCurrencyValue(
                data?.rows.reduce((acc, v: AwardPayments) => (v.status ? acc + v.amount : 0), 0)
              )}{' '}
              /{' '}
              {formatCurrencyValue(data?.rows.reduce((acc, v: AwardPayments) => acc + v.amount, 0))}{' '}
            </Typography>
          </Typography>

          {!readOnly && (
            <StandardButton onClick={() => setIsCreateOpen(true)} startIcon>
              Add Payment
            </StandardButton>
          )}
        </Stack>

        {/* <Typography variant="caption">
          Here you can see all the award payments recorded by you or others.
        </Typography> */}
      </Card.Header>

      <Card.Body>
        <PaymentsAwardTable
          data={data}
          error={error}
          loading={loading}
          refetchPayments={refetchPayments}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </Card.Body>

      {isCreateOpen && (
        <NewPaymentModal refetchPayments={refetchPayments} toggle={() => setIsCreateOpen(false)} />
      )}
    </Card>
  );
}
