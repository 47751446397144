import Card from 'react-bootstrap/Card';
import BootstrapTable from 'react-bootstrap-table-next';
import { IconButton, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useContext, ReactElement } from 'react';

import { formatCurrencyValue } from 'utils/utilFunctions';
import { UserSessionContext } from 'index';
import { tossError, tossSuccess } from 'utils/toastTosser';

import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import TooltipWithAnchor from 'shared/muiComponents/TooltipWithAnchor';

import { Project, ProjectSources } from 'types/awards';
import { updateProject } from 'services/awards/awardsService';
import AddFundingModal from '../modals/AddFundingModal';

interface SourcesTableProps {
  data: Project;
  fetchProject: (id: number) => void;
}

function FundingSources({ data, fetchProject }: SourcesTableProps) {
  const [isAddFundingOpen, setIsAddFundingOpen] = useState(false);

  const [hoveredToolTipIcon, setHoveredToolTipIcon] = useState<HTMLElement | null>(null);

  const currentUser = useContext(UserSessionContext);

  const canEdit =
    !currentUser?.isMillenniumAnalyst && currentUser?.userType !== 'millenniumResearcher';

  const toggleFunding = () => setIsAddFundingOpen((prevState) => !prevState);

  const remove = async (row: ProjectSources) => {
    const filteredSources = data.sources.filter(
      (source) => JSON.stringify(source) !== JSON.stringify(row)
    );

    const result = await updateProject({
      id: data.id,
      name: data.name,
      category: data.category,
      sources: filteredSources,
    });

    if (result) {
      tossSuccess('The Source was successfully deleted.');
      fetchProject(data.id);
    } else tossError('Error deleting the Source.');
  };

  function awardsValueFormatter(cell: number) {
    return formatCurrencyValue(cell);
  }

  // Delete btn
  function actionsFormatter(_cell: string, row: ProjectSources) {
    return (
      <Stack direction="row">
        <IconButton
          aria-label="delete"
          className="table-hover-btn"
          onClick={(e) => {
            e.stopPropagation();
            remove(row);
          }}
          onMouseOut={() => setHoveredToolTipIcon(null)}
          onMouseOver={(event) => setHoveredToolTipIcon(event.currentTarget)}
          sx={{ visibility: 'hidden', borderRadius: 'initial', backgroundColor: '#EFF1F6' }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Description',
    },
    {
      dataField: 'type',
      text: 'Source',
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: awardsValueFormatter,
    },
    {
      dataField: 'actions',
      text: '',
      isDummyField: true,
      formatter: actionsFormatter,
    },
  ];

  return (
    <>
      <Card className="w-100 py-3">
        <Card.Header className="details-card-header">
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography color="primary.main" variant="h2">
              Other Funding Sources
            </Typography>

            {canEdit && (
              <SecondaryButton onClick={toggleFunding} startIcon>
                Add Funding Source
              </SecondaryButton>
            )}
          </Stack>
        </Card.Header>

        <Card.Body>
          <BootstrapTable
            bootstrap4
            bordered={false}
            columns={columns}
            data={data.sources || []}
            keyField="name"
            remote={{
              filter: true,
            }}
            wrapperClasses="table-responsive table-borderless"
          />
        </Card.Body>
      </Card>

      {isAddFundingOpen && (
        <AddFundingModal data={data} fetchProject={fetchProject} toggle={toggleFunding} />
      )}

      <TooltipWithAnchor
        anchorElement={hoveredToolTipIcon}
        isOpen={Boolean(hoveredToolTipIcon)}
        text="Remove this source from the project"
      />
    </>
  );
}

export default FundingSources;
