/*
    MIL-283:
    If any program name or program funder has double commas, convert them to single commas before parsing.
    For ones not familiar with regular expressions well enough:
      the regexp /(?<!,),(?!,)/g means 'a comma that is neither preceded nor followed by another comma'.
      (?>![symbol]) is NEGATIVE LOOKBEHIND. It means 'previous symbol is not [symbol]'.
      (?![symbol]) is NEGATIVE LOOKAHEAD. It means 'next symbol is not [symbol]'.
      /g is a flag that instructs to look up whole string for matches.

      the regexp /,,/g means 'all double commas'.
  */

/*
	Changed realization because Safari doesn't support LOOKBEHIND
*/
const COMMA = ',';
const REGEXP_COMMA_DOUBLE = /,,/gm;
const COMMA_SURROGATE = '!?!';

const splitAndReplaceDoubleCommas = (string: string) =>
  string
    .replace(REGEXP_COMMA_DOUBLE, COMMA_SURROGATE)
    .split(COMMA)
    .map((e) => ({ name: e.replace(COMMA_SURROGATE, COMMA) }));

export default splitAndReplaceDoubleCommas;
