import { Route, Outlet, Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Stack, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import IconButton from 'shared/muiComponents/IconButton';

import useUserSession from 'hooks/useUserSession';

import { Client } from 'types/client';

import SummaryView from 'components/awards/SummaryView';
import FinanceView from 'components/awards/FinanceView';
import AwardViewsNavBar from 'components/awards/AwardViewsNavBar';
import ProjectView from 'components/awards/ProjectView';
import ProjectDetails from 'components/awards/ProjectView/ProjectDetails';
import UserView from 'components/awards/UserView';

function AwardsPage() {
  const navigate = useNavigate();

  const { userType, clientCreator } = useUserSession();

  const canEditPreferences =
    userType === 'millenniumAdmin' || userType === 'millenniumManager' || userType === 'userAdmin';

  const client = ((clientCreator as unknown as Client[]) || [])[0];
  const awardsEnabled = !clientCreator || client?.awardsEnabled;
  const isPrivatelyManaged = client?.privateAwardsManagement;

  // Block access if awards are not enabled or if privately managed and user is not millennium admin
  if (!awardsEnabled || (isPrivatelyManaged && userType !== 'millenniumAdmin')) {
    return (
      <>
        <Helmet>
          <title>Awards - Grantrack</title>
        </Helmet>

        <Typography align="center" ml={2} variant="h1">
          This feature is not available at this time.
          <br />
          <Typography ml={2} variant="caption">
            Please contact your Millennium representative for more information.
            {/* {!awardsEnabled
              ? 'Please contact your Millennium representative for more information.'
              : 'This award is being privately managed by Millennium Strategies.'} */}
          </Typography>
        </Typography>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Awards - Grantrack</title>
      </Helmet>

      <Typography ml={2} variant="h1">
        Awards
      </Typography>

      <Stack alignItems="center" direction="row" justifyContent="space-between" m={2}>
        <AwardViewsNavBar />

        {canEditPreferences && (
          <IconButton
            onClick={() => navigate(`/dashboard/awards-preferences`)}
            tooltipText='Click to view "Preferences"'
          >
            <TuneIcon htmlColor="black" />
          </IconButton>
        )}
      </Stack>

      <Outlet />
    </>
  );
}

export default function awardsRoutes() {
  return (
    <Route element={<AwardsPage />} path="awards">
      <Route element={<Navigate to="summary" />} index />
      <Route element={<SummaryView />} path="summary" />
      <Route element={<FinanceView />} path="finance" />

      <Route path="projects">
        <Route element={<ProjectView />} index />
        <Route element={<ProjectDetails />} path="details/:projectId" />
      </Route>

      <Route element={<UserView />} path="userView" />
    </Route>
  );
}
