import { FileDownloadOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import Card from 'react-bootstrap/Card';

import { tossError, tossSuccess } from 'utils/toastTosser';
import { UserSessionContext } from 'index';

import IconButton from 'shared/muiComponents/IconButton';
import AwardDocumentDropzone from 'components/awards/AwardsDetails/AwardsDetailsView/Documents/AwardDocumentDropzone';

import { updateAward } from 'services/awards/awardsService';
import useCurrentAward from 'hooks/useCurrentAward';
import DownloadDocumentsModal from 'components/awards/AwardsDetails/AwardsDetailsView/Documents/DownloadDocumentsModal';
import DocumentFilters from './DocumentFilters';

export default function Documents({ preSave }: { preSave: () => Promise<boolean> }) {
  const { awardData, currentAwardId, documentsFilters, refetchAwardAndTimeline } =
    useCurrentAward();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    resolutionRequired,
    applicationRequired,
    awardLetterRequired,
    contractMaterialRequired,
    initialAgreementRequired,
  } = awardData.data ?? {};

  const currentUser = useContext(UserSessionContext);

  const readOnly =
    currentUser?.userType === 'millenniumAnalyst' ||
    currentUser?.userType === 'millenniumResearcher';

  return (
    <Card className="w-100">
      <Card.Body>
        <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
          <Typography variant="h1">Documents</Typography>
          <Box height="38px" width="38px">
            <IconButton onClick={() => setIsModalOpen(true)} tooltipText="Download as ZIP">
              <FileDownloadOutlined htmlColor="black" />
            </IconButton>
          </Box>
        </Stack>

        {isModalOpen && <DownloadDocumentsModal onClose={() => setIsModalOpen(false)} />}
        <DocumentFilters />

        <Stack direction="column" spacing={2} sx={{ wordBreak: 'break-word' }}>
          {documentsFilters.includedCategories.includes('initial') && (
            <>
              <Typography variant="h2">Required Documents</Typography>
              <Typography variant="body1">
                Upload documents in the appropriate section below. Checked boxes denote required
                uploads. Uncheck the box to make an upload optional.
              </Typography>

              <Stack direction="column" gap="2px">
                <AwardDocumentDropzone
                  category="initialApplication"
                  checked={applicationRequired}
                  disabled={readOnly}
                  label="Application"
                  onCheck={async () => {
                    const result = await updateAward({
                      id: Number(currentAwardId),
                      applicationRequired: !applicationRequired,
                    });

                    if (result) {
                      tossSuccess(
                        `Application set as ${applicationRequired ? 'optional' : 'required'}`
                      );
                      refetchAwardAndTimeline();
                    } else tossError('Error updating award');
                  }}
                  preSaveCheck={preSave}
                />

                <AwardDocumentDropzone
                  category="initialAwardLetter"
                  checked={awardLetterRequired}
                  disabled={readOnly}
                  label="Award Letter"
                  onCheck={async () => {
                    const result = await updateAward({
                      id: Number(currentAwardId),
                      awardLetterRequired: !awardLetterRequired,
                    });

                    if (result) {
                      tossSuccess(
                        `Award Letter set as ${awardLetterRequired ? 'optional' : 'required'}`
                      );
                      refetchAwardAndTimeline();
                    } else tossError('Error updating award');
                  }}
                  preSaveCheck={preSave}
                />

                <AwardDocumentDropzone
                  category="initialResolution"
                  checked={resolutionRequired}
                  disabled={readOnly}
                  label="Resolution"
                  onCheck={async () => {
                    const result = await updateAward({
                      id: Number(currentAwardId),
                      resolutionRequired: !resolutionRequired,
                    });

                    if (result) {
                      tossSuccess(
                        `Resolution set as ${resolutionRequired ? 'optional' : 'required'}`
                      );
                      refetchAwardAndTimeline();
                    } else tossError('Error updating award');
                  }}
                  preSaveCheck={preSave}
                />

                <AwardDocumentDropzone
                  category="initialAgreement"
                  checked={initialAgreementRequired}
                  disabled={readOnly}
                  label="Agreement"
                  onCheck={async () => {
                    const result = await updateAward({
                      id: Number(currentAwardId),
                      initialAgreementRequired: !initialAgreementRequired,
                    });

                    if (result) {
                      tossSuccess(
                        `Agreement set as ${initialAgreementRequired ? 'optional' : 'required'}`
                      );
                      refetchAwardAndTimeline();
                    } else tossError('Error updating award');
                  }}
                  preSaveCheck={preSave}
                />

                {documentsFilters.includedCategories.includes('contract') && (
                  <AwardDocumentDropzone
                    category="requiredContractMaterial"
                    checked={contractMaterialRequired}
                    disabled={readOnly}
                    label="Required contract material"
                    onCheck={async () => {
                      const result = await updateAward({
                        id: Number(currentAwardId),
                        contractMaterialRequired: !contractMaterialRequired,
                      });

                      if (result) {
                        tossSuccess(
                          `Contract material set as ${
                            contractMaterialRequired ? 'optional' : 'required'
                          }`
                        );
                        refetchAwardAndTimeline();
                      } else tossError('Error updating award');
                    }}
                    preSaveCheck={preSave}
                  />
                )}
              </Stack>
            </>
          )}

          {documentsFilters.includedCategories.includes('related') && (
            <>
              <Typography variant="h2">Additional Documents (optional)</Typography>

              <AwardDocumentDropzone
                allowMultiple
                category="other"
                disabled={readOnly}
                // label=""
                preSaveCheck={preSave}
              />
            </>
          )}

          {documentsFilters.includedCategories.includes('report') && (
            <>
              <Typography variant="h2">Reports</Typography>
              <Typography variant="body1">
                All reports uploaded for the grant will show here.
              </Typography>

              <AwardDocumentDropzone category="report" disabled />
            </>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
}
