import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ProjectsList, getProjects } from 'services/awards/awardsService';
import useUserSession from 'hooks/useUserSession';
import { LinearProgress } from '@mui/material';
import ProjectsTable from './sections/ProjectsTable';
import ProjectFilters from './ProjectFilters';

export default function ProjectView() {
  const isMounted = useRef(true);
  const location = useLocation();

  const currentUser = useUserSession();

  const [data, setData] = useState<ProjectsList>();
  const [loading, setLoading] = useState(false);

  const refetchProjects = useCallback(async () => {
    setLoading(true);

    const result = await getProjects(location.search);

    if (isMounted.current) {
      setData(result);
      setLoading(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (location.search) {
      refetchProjects();
    }
  }, [location.search]);

  return (
    <>
      {currentUser?.isMillenniumUser && <ProjectFilters />}

      <Container fluid>
        {loading && <LinearProgress />}
        <ProjectsTable data={data} loading={loading} refetchProjects={refetchProjects} />
      </Container>
    </>
  );
}
