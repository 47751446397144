import { Box, Stack, Typography } from '@mui/material';
import { toArray } from 'utils/utilFunctions';
import useSearchParams from 'hooks/useSearchParams';
import { applicationsConfigs } from 'constants/globalConstants';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';

const allowedApplicationFilters = {
  f: 'Funder',
  gp: 'Program',
  c: 'Client',
  a: 'Assigned To',
  s: 'Status',
  sd: 'Start Date',
  ed: 'End Date',
  edt: 'Date Type',
} as const;

const getDefaultFilters = (isUserDashboard: boolean) => {
  const today = dayjs();
  const fourteenDaysTD = today.startOf('D').subtract(14, 'd').toString();
  const endFourteenDaysTD = today.endOf('D').add(14, 'd').toString();

  return isUserDashboard
    ? {
        startDate: fourteenDaysTD,
        endDate: endFourteenDaysTD,
        status: 'all',
        sortBy: 'startDate',
        sortOrder: 'desc',
        users: [],
      }
    : {
        programNames: [],
        users: [],
        clients: [],
        funders: [],
        status: 'all',
        startDate: null,
        endDate: null,
        endDateType: 'endsAt',
        sortBy: 'startDate',
        sortOrder: 'desc',
      };
};

export default function UsedApplicationFiltersDescriptionBlock(): JSX.Element {
  const { searchParams } = useSearchParams();
  const location = useLocation();
  const isUserDashboard = location?.pathname?.includes('user-dashboard');
  const defaultFilters = getDefaultFilters(isUserDashboard);

  const usedFilters: { key: keyof typeof allowedApplicationFilters; values: string }[] = [];

  Object.entries(allowedApplicationFilters).forEach(([key, label]) => {
    if (isUserDashboard && key === 'a') return;

    const filterValues = toArray(searchParams[key]);

    if (filterValues.length > 0) {
      const isDefaultValue =
        (key === 'f' && filterValues.join(', ') === defaultFilters.funders?.join(', ')) ||
        (key === 'gp' && filterValues.join(', ') === defaultFilters.programNames?.join(', ')) ||
        (key === 'c' && filterValues.join(', ') === defaultFilters.clients?.join(', ')) ||
        (key === 'a' && filterValues.join(', ') === defaultFilters.users?.join(', ')) ||
        (key === 's' && filterValues.join(', ') === defaultFilters.status) ||
        (key === 'sd' && filterValues.join(', ') === defaultFilters.startDate) ||
        (key === 'ed' && filterValues.join(', ') === defaultFilters.endDate) ||
        (key === 'edt' && filterValues.join(', ') === defaultFilters.endDateType);

      if (!isDefaultValue) {
        let displayValue = filterValues.join(', ');

        if (key === 'a') {
          const splitValues = filterValues.flatMap((value) => value.split('|'));
          const uniqueValues = [...new Set(splitValues)];
          displayValue = uniqueValues.join(', ');
        }

        if (key === 's') {
          const statusValue = filterValues[0];
          const statusConfig = applicationsConfigs.filterStatuses;
          if (statusConfig[statusValue]) {
            displayValue = statusConfig[statusValue];
          }
        }

        usedFilters.push({
          key: key as keyof typeof allowedApplicationFilters,
          values: displayValue,
        });
      }
    }
  });

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" gap={3} mt={0}>
      {usedFilters.map((filter) => (
        <Stack key={filter.key} alignItems="center" direction="row" gap={2}>
          <Typography variant="body1">{`${allowedApplicationFilters[filter.key]}:`}</Typography>
          <Typography sx={{ color: 'grey' }} variant="body1">
            {filter.values}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
}
