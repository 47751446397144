import { User } from 'types/user';
import { Client } from './client';
import { Application } from './application';

export type ActionLogs = import('./actionLogs').ActionLogs;

export interface Notifications {
  id: number;
  body: string;
  subject: string;
  createdAt: string;
}

export const awardDocumentCategories = [
  'initialApplication',
  'initialAwardLetter',
  'initialResolution',
  'initialAgreement',
  'requiredContractMaterial',
  'optionalContractMaterial',
  'report',
  'other',
];

export type AwardDocumentCategory = (typeof awardDocumentCategories)[number];

export interface AwardDocument {
  id: number;
  awardId: number | null;
  reportId: number | null;
  category: AwardDocumentCategory;
  type: string;
  name: string;
  size: number;
  url: string;
  createdAt: string;
  updatedAt: string;
}
export interface AwardPayments {
  id: number;
  type: string;
  amount: number;
  requestedDate: string;
  paidAt: string;
  status: 'paid' | 'reimbursed' | null;
  userId: number;
  reportId: number | null;
  report: {
    string_id: string;
  };
  user: {
    id: number;
    name: string;
  };
}

export interface AwardReport {
  id: number;
  name: string;
  stringId: string;
  type: 'financial' | 'programmatic' | 'combined';
  startsOn: string;
  endsOn: string;
  dueDate: string;
  amount: number;
  paymentId: number | null;
  payments: AwardPayments[];
  files: AwardDocument[];
}

export const AWARD_STATUSES = [
  'applicationRequired',
  'awardLetterRequired',
  'resolutionRequired',
  'initialAgreementRequired',
  'budgetRequired',
  'reportScheduleRequired',
  'contractMaterialRequired',
  'encumbered',
  'approval',
  'active',
  'reportsRequired',
  'complianceRequired',
  'closeout',
  'closed',
] as const;

export interface Award {
  id: number;
  stringId: string;
  // ----------~~~-------------
  assigneeId?: number;
  clientId: number;
  // ----------~~~-------------
  category: number;
  department: string;
  funder: string;
  grantPurpose: string;
  funderAwardId: string;
  grantProgramName: string;
  // ----------~~~-------------
  status: (typeof AWARD_STATUSES)[number];
  source: number;
  nextReportDueDate: string;
  // ----------~~~-------------
  awardAmount: number;
  awardExpended: number;
  awardBalance: number;
  matchAmount: number | string;
  matchExpended: number;
  matchBalance: number;
  // ----------~~~-------------
  notes: string;
  // ----------~~~-------------
  startsOn: string;
  endsOn: string;
  // ----------~~~-------------
  assignee: User | null;
  client: Client | null;
  application: Application;
  // ----------~~~-------------
  paymentsReceived: number;
  paymentsRequested: number;
  // ----------~~~-------------
  applicationRequired: boolean;
  awardLetterRequired: boolean;
  resolutionRequired: boolean;
  initialAgreementRequired: boolean;
  contractMaterialRequired: boolean;
  //-----------------------
  actionLogs: ActionLogs;
  notifications: Notifications[];
  reports?: AwardReport[];
  awardPortalLink: string;
  departmentNotified: string;
}

// Project View & Project Details
export interface Creator {
  id: number;
  name: string;
}

export interface ProjectAwards {
  id: number;
  stringId: string;
  funder: string;
  department: string;
  grantProgramName: string;
  awardAmount: number;
}

export interface ProjectSources {
  type: string;
  name: string;
  amount: number;
}

export interface Project {
  id: number;
  name: string;
  category: string;
  createdBy: Creator;
  createdAt: string;
  sources: ProjectSources[];
  awards: ProjectAwards[];
  budget: number;
  clientId: number;
}

export interface UsersAwards {
  id: number;
  name: string;
  closedAwardsCount: number;
  openAwardsCount: number;
  totalReportsOnTime: number;
  lateReportsCount: number;
  awardNotifications: Notifications[];
  awards: Award[];
}

export const mandatoryRoles = [
  'primaryOrganizationalContact',
  'projectDirector',
  'departmentDirector',
  'financeContact',
  'resolutionOwner',
  'purchasing',
] as const;

export type MandatoryRole = (typeof mandatoryRoles)[number];

export interface AwardUserRole {
  id: number;
  awardId: number;
  userId: number;
  user: {
    id: number;
    position: string;
    email: string;
    name: string;
  };
  role: MandatoryRole | null;
  customUserRole: string | null;
}

export interface AwardTimelineStep {
  timeline: string;
  complete: boolean;
  reportId?: number;
  requiredAction?: string;
  defaultAction?: string;
}
export interface AwardBudgetEntry {
  id: number;
  awardId: number;
  parentId: number | null;
  entryId: number | null;
  itemOrder: number | null;
  name: string;
  matchType: 'inKind' | 'existingGrant' | 'other' | 'cash';
  matchSource: string;
  matchDescription: string;
  matchAwardId?: number | null;
  awardAmount: number;
  awardExpended: number;
  awardBalance: number;
  matchAmount: number;
  matchExpended: number;
  matchBalance: number;
}

export interface Preferences {
  id: number;
  frequency: null | string;
  preference: string;
  settings: {
    enabled?: boolean;
    time?: string;
  };
}

export interface PreferenceObject {
  preferences: Preferences[];
  awardNotificationsEnabled: boolean;
  clientId: number | null;
}
