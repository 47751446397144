import SearchFilter from 'shared/muiComponents/SearchFilter';
import { toArray } from 'utils/utilFunctions';
import useSearchParams from 'hooks/useSearchParams';
import { searchAwards } from 'services/awards/awardsService';

export default function ProgramFilter({ width = '100%' }) {
  const { searchParams, setSearchParams } = useSearchParams();

  return (
    <SearchFilter<string>
      getLabel={(f) => f}
      label="Program"
      onChange={(values) => setSearchParams({ page: 1, programs: values })}
      searchFunction={async (input: string) =>
        (await searchAwards('grantProgramName', input)).map((a) => a.grantProgramName)
      }
      selected={toArray(searchParams.programs)}
      variant="filter"
      width={width}
    />
  );
}
