import { FocusEventHandler, useEffect, useState } from 'react';
import { InputLabel, Stack } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';

const parseValue = (value: string | number) =>
  // eslint-disable-next-line no-useless-escape
  parseFloat(value.toString().replace(/[^0-9.\-]/g, ''));

interface OutlinedInputProps {
  value?: number | string;
  onSave?: (value: number) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  label?: string;
  required?: boolean;
  id?: string;
  readonly?: boolean;
  errorText?: string;
  ref?: React.Ref<HTMLInputElement>;
}

export default function CurrencyInputField({
  errorText,
  id = '',
  label = '',
  onBlur,
  onSave,
  readonly = false,
  required = false,
  value,
  ref,
}: OutlinedInputProps) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (value !== currentValue && (currentValue === 0 || currentValue)) {
      timeout = setTimeout(() => {
        if (onSave) onSave(Number(parseValue(currentValue)));
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [currentValue, value]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <Stack spacing={1}>
      {label && (
        <InputLabel htmlFor={id} required={required}>
          {label}
        </InputLabel>
      )}

      {value !== undefined && (
        <CurrencyInput
          ref={ref}
          allowDecimals
          className="mui-input"
          decimalSeparator="."
          decimalsLimit={2}
          defaultValue={value}
          disableAbbreviations
          placeholder="0"
          prefix="$"
          step={10}
          value={currentValue !== undefined ? currentValue : value}
          // onBlur={(e) => {
          //   if (onBlur) onBlur(e);
          //   setCurrentValue(parseValue(e.target.value));
          // }}
          disabled={readonly}
          // onKeyUp={(e) => setCurrentValue(parseValue((e.target as HTMLInputElement).value || '0'))}
          onValueChange={(_value, _name, values) => setCurrentValue(parseValue(_value || '0'))}
          groupSeparator=","
          // Delete everything when you delete one character using onKeyDown={(e) => {}}
          onKeyDown={(e) => {
            if (e.key === 'Backspace') {
              setCurrentValue(0);
            }
          }}
        />
      )}
    </Stack>
  );
}
