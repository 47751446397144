import { createRef, forwardRef, useLayoutEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { searchFilters } from 'services/filterService';
import { Filter } from 'types/filter';

interface SearchModalProps {
  open: boolean;
  moduleId: number;
  onClose: () => void;
  onSelect: (filter: Filter) => void;
  setSearchOpen: (isOpen: boolean) => void;
}

export default forwardRef(
  ({ open = false, moduleId, onClose, onSelect, setSearchOpen }: SearchModalProps, ref) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<Filter[]>([]);
    const [selected] = useState([]);
    const filterRef = createRef<AsyncTypeahead<Filter>>();

    const onChange = (newValue: Filter[]) => {
      const chosenValue = newValue.pop();
      onSelect(chosenValue!);
      onClose();
    };

    const onSearch = async (query: string) => {
      setLoading(true);

      const filters = await searchFilters(moduleId, query, 'title');

      const filtersOptions = filters.map(({ id, title: name, filterData }) => ({
        id,
        name,
        filterData,
      }));

      setOptions(filtersOptions);
      setLoading(false);
    };

    useLayoutEffect(() => {
      if (filterRef?.current) filterRef.current.focus();
    }, [filterRef]);

    return (
      <Modal
        ref={ref}
        centered
        className="bg-filter-set-search-modal"
        onHide={onClose}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.code === 'Enter') {
            e.preventDefault();
            setSearchOpen(false);
          }
        }}
        show={open}
      >
        <Modal.Header closeButton>
          <Modal.Title>Search Filter Sets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AsyncTypeahead
            ref={filterRef}
            autoFocus
            id="filterSetSearcher"
            isLoading={loading}
            labelKey="name"
            minLength={1}
            onChange={onChange}
            onSearch={onSearch}
            options={options}
            placeholder="Start typing filter set title here"
            positionFixed
            renderMenuItemChildren={({ name }, { text }) => (
              <Highlighter search={text!}>{name}</Highlighter>
            )}
            selected={selected}
            useCache={false}
          />
        </Modal.Body>
      </Modal>
    );
  }
);
