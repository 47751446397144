/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import BootstrapTable from 'react-bootstrap-table-next';
import isEqual from 'lodash/isEqual';

import useCurrentAward from 'hooks/useCurrentAward';
import { AwardPaymentsList, deletePayment } from 'services/awards/awardPaymentsService';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { useState } from 'react';

import { AwardPayments } from 'types/awards';
import useUserSession from 'hooks/useUserSession';
import getPaymentsColumns from './getPaymentsColumns';
import NoPaymentsEmptyScreen from './NoPaymentsEmptyScreen';
import EditPaymentModal from './EditPaymentModal';

interface PaymentsAwardTableProps {
  data?: AwardPaymentsList;
  loading: boolean;
  error: boolean;
  searchParams: Record<string, string>;
  setSearchParams: (values: Record<string, string>) => void;
  refetchPayments: () => void;
}

const defaultFiltersSet = { sortBy: 'id', sortOrder: 'desc' };

export default function PaymentsAwardTable({
  data,
  loading,
  error,
  refetchPayments,
  searchParams,
  setSearchParams,
}: PaymentsAwardTableProps) {
  const { currentAwardId } = useCurrentAward();
  const { userType } = useUserSession();
  const [selectedPayment, setSelectedPayment] = useState<AwardPayments>({} as AwardPayments);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const toggleEdit = () => setIsEditOpen((prevState) => !prevState);

  const isFiltered = !isEqual(searchParams, defaultFiltersSet);

  const handleSortChange = (sortField: string, sortOrder: string) => {
    setSearchParams({ sortBy: sortField, sortOrder });
  };

  const remove = async (paymentId: number) => {
    if (!confirm('Are you sure you want to delete this payment?')) return;

    const result = await deletePayment(currentAwardId, paymentId);

    if (result) {
      tossSuccess('The payment was successfully deleted.');
      refetchPayments();
    } else tossError('Error deleting the payment.');
  };

  const readOnly = userType === 'millenniumAnalyst' || userType === 'millenniumResearcher';

  if (error) return <p>Error fetching payments.</p>;
  if (!isFiltered && !loading && data?.rows?.length === 0) return <NoPaymentsEmptyScreen />;
  if (!data?.rows) return <div />;

  return (
    <>
      <BootstrapTable
        bodyClasses={loading ? 'loading' : ''}
        bootstrap4
        bordered={false}
        columns={getPaymentsColumns(
          refetchPayments,
          remove,
          setSelectedPayment,
          toggleEdit,
          currentAwardId,
          readOnly
        )}
        data={data?.rows || []}
        defaultSorted={[
          { dataField: searchParams.sortBy, order: searchParams.sortOrder as 'asc' | 'desc' },
        ]}
        keyField="id"
        noDataIndication="No Payments found"
        onTableChange={(type, newState) => {
          if (type === 'sort') {
            handleSortChange(newState.sortField, newState.sortOrder);
          }
        }}
        remote={{
          sort: true,
        }}
        sort={{ dataField: searchParams.sortBy, order: searchParams.sortOrder as 'asc' | 'desc' }}
        wrapperClasses="table-responsive table-borderless"
      />
      {isEditOpen && !readOnly && (
        <EditPaymentModal
          data={selectedPayment}
          refetchPayments={refetchPayments}
          toggle={toggleEdit}
        />
      )}
    </>
  );
}
