import {
  useEffect,
  useState,
  useContext,
  useCallback,
  LegacyRef,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
} from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import BootstrapTable, { ColumnDescription, RowSelectionType } from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { Stack, Box } from '@mui/material';
// @ts-expect-error no types support planned for react-bootstrap-table2
import overlayFactory from 'react-bootstrap-table2-overlay';
import { useLocation, useNavigate } from 'react-router';
import { Edit as EditIcon, Plus } from 'react-feather';
import { Button, Modal } from 'react-bootstrap';
import { FilterContext } from 'pages/Applications';
import useUserSession from 'hooks/useUserSession';
import reduceTZ from 'utils/dateUtils';

import useLocalStorage from 'hooks/useLocalStorage';
import { ProgramFilter } from 'pages/Dashboard';

import { Program } from 'types/program';
import { useProgramContext } from 'hooks/ProgramContext';
import { getPrograms, ReadProgram } from 'services/programService';
import CreateApplications from './CreateApplications';
import CustomTooltip from '../../shared/CustomTooltip';
import 'assets/scss/programList.scss';

dayjs.extend(advancedFormat);

interface ProgramsTableRow {
  id: number;
  funder?: string;
  category?: number;
  programName?: string;
  startDate?: string | Date | null;
  dueDate?: string | Date | null;
  program: Program;
  actions: string;
}

interface ProgramsListProps {
  isEditing?: boolean;
  selected?: Program[];
  setSelected?: Dispatch<SetStateAction<Program[]>>;
  programTableRef: unknown;
  readPrograms?: ReadProgram[];
  readList?: (userId: number) => Promise<void>;
  checkFilterValues: (filter: ProgramFilter) => boolean;
}

export default function ProgramsList(props: ProgramsListProps) {
  const {
    isEditing,
    selected = [],
    setSelected,
    programTableRef,
    readPrograms,
    readList,
    checkFilterValues,
  } = props;

  const {
    programFilters,
    programsPage: page,
    setProgramsPage: setPage,
    setProgramFilters,
  } = useContext(FilterContext);

  const [storedFilters, setStoredFilters, removeStoredFilters] = useLocalStorage('filters', 'v1.0');
  const [storedFilterSet] = useLocalStorage('setNameApp', 'v1.0');
  const [, storeProgramDetails] = useLocalStorage('programDetailsProps', 'v1');

  const { REACT_APP_PROGRAMS_PER_PAGE: PROGRAMS_PER_PAGE } = process.env;

  const currentUser = useUserSession();
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = location?.pathname?.split('/').at(-1);
  const readOnly = !currentUser.isMillenniumUser;
  const [data, setData] = useState<ProgramsTableRow[]>([]);

  const [isError, setIsError] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const { setCreateProgramProps, setProgramDetailsProps, programDetailsProps } =
    useProgramContext();

  const [hoveredRow, setHoveredRow] = useState<ProgramsTableRow | null>(null);
  const [hoveredCell, setHoveredCell] = useState<HTMLElement | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<ColumnDescription<Program> | null>(null);

  // State to manage modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  let tooltipText = 'Click Edit button to view Program details';

  if (hoveredRow && hoveredColumn) {
    if (hoveredColumn.dataField === 'startDate' && !hoveredRow.startDate)
      tooltipText = 'Start date is not set';
    if (hoveredColumn.dataField === 'dueDate' && !hoveredRow.dueDate)
      tooltipText = 'Due date is not set';
    if (hoveredColumn.dataField === 'startDate' && new Date(hoveredRow.startDate) < new Date())
      tooltipText = 'Start date is in the past';
    if (hoveredColumn.dataField === 'dueDate' && new Date(hoveredRow.dueDate) < new Date())
      tooltipText = 'Due date is in the past';
  }

  const refetchList = useCallback(async () => {
    // To avoid redundant render on non-initialized table, skip fetching on that condition.
    const filter = checkFilterValues(programFilters)
      ? programFilters
      : storedFilters?.[pageName]?.programFilters;

    const queryFilters: {
      names?: string[];
      funders?: string[];
      category?: string;
      dateFrom?: string;
      dateTo?: string;
      sortBy?: string;
      sortOrder?: string;
    } = {};

    const {
      programNames: filterProgramNames,
      funders: filterFunders,
      category: filterCategory,
      startDate: filterStartDate,
      endDate: filterEndDate,
      sortBy: filterSortBy,
      sortOrder: filterSortOrder,
    } = filter;

    if (filterProgramNames && filterProgramNames.length > 0)
      queryFilters.names = filterProgramNames.map(({ name }: { name: string }) =>
        name.replace(/,/gm, ',,')
      );
    if (filterFunders && filterFunders.length > 0)
      queryFilters.funders = filterFunders.map(({ name }: { name: string }) =>
        name.replace(/,/gm, ',,')
      );
    if (filterCategory && filterCategory !== 'all') queryFilters.category = filterCategory;
    if (filterStartDate) queryFilters.dateFrom = filterStartDate;
    if (filterEndDate) queryFilters.dateTo = filterEndDate;
    if (filterSortBy) queryFilters.sortBy = filterSortBy;
    if (filterSortOrder) queryFilters.sortOrder = filterSortOrder;

    const searchParams = new URLSearchParams({
      page: page.toString() || '1',
      perPage: PROGRAMS_PER_PAGE || '20',
    });

    Object.entries(queryFilters).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value) && value.length > 0) {
          searchParams.append(key, value.join(','));
        } else {
          searchParams.append(key, value.toString());
        }
      }
    });

    return getPrograms(searchParams.toString());
  }, [programFilters, page, readPrograms]);

  const updateList = () => {
    const startDate = new Date();
    const stop = startDate.getTime() + 300;

    const performListUpdate = (retrievedList: ProgramsList) => {
      const { rows, pages, error } = retrievedList;

      const preparedRows = [];

      if (error) {
        setIsError(true);
        return;
      }

      for (const currentRow of rows) {
        const { id, funder, name, category, startsAt, endsAt } = currentRow;

        preparedRows.push({
          id,
          funder,
          category,
          programName: name,
          startDate: startsAt,
          dueDate: endsAt,
          program: { ...currentRow }, // should get rid of this approach in the near future.
          actions: 'edit',
        });
      }
      const calculatedTotalRecords = pages * Number(PROGRAMS_PER_PAGE);

      setTotalPages(pages);
      setTotalRecords(calculatedTotalRecords);
      setData(preparedRows);
    };

    const performFetch = async () => {
      const retrievedList = await refetchList();
      const endDate = new Date();
      const timeRemaining = stop - endDate.getTime();

      if (endDate.getTime() > stop) {
        performListUpdate(retrievedList);
      } else {
        setTimeout(() => {
          performListUpdate(retrievedList);
        }, timeRemaining);
      }
    };

    performFetch();
  };

  useEffect(updateList, [programFilters, page, refetchList]);

  const handleEditProgram = (program: Program) => {
    const updatedProps = {
      program,
      onSave: updateList,
      readList: readList!,
      readOnly: !currentUser.isMillenniumUser,
      disabled: false,
    };
    setProgramDetailsProps({ ...updatedProps });
    storeProgramDetails(updatedProps);
    navigate(`/dashboard/program-details/${program.id}`);
  };

  const onTableChange = (type: string, newState: { sortField: string; sortOrder: string }) => {
    if (type === 'sort') {
      const { sortField, sortOrder } = newState;
      if (programTableRef.current) {
        programTableRef.current.paginationContext.currPage = 1;
        setPage(1);
      }
      setProgramFilters({ ...programFilters, sortBy: sortField, sortOrder });
    }
  };

  function dateFormatter(cell: string) {
    return reduceTZ(cell);
  }

  function funderFormatter(cell: string) {
    return cell ?? 'None Yet';
  }

  function checkIsRead(id: number) {
    return readPrograms?.find((program: ReadProgram) => program?.programId === id);
  }

  function actionsFormatter(cell: string, row: ProgramsTableRow) {
    return (
      <>
        {location?.pathname.includes('user-dashboard') && !checkIsRead(row?.id) && (
          <span className="align-middle d-flex text-nowrap justify-content-flex-startn unread-program" />
        )}
        <div className="align-middle d-flex text-nowrap justify-content-center ">
          <Button
            className="d-flex justify-content-center align-items-center application-detail-edit"
            onClick={() => handleEditProgram(row?.program)}
            variant="primary-blue"
          >
            {!readOnly && <EditIcon size={16} style={{ marginBottom: '2px' }} />}
            &nbsp;{readOnly ? 'View' : 'Edit'}
          </Button>
          &nbsp;&nbsp;
          {currentUser.isMillenniumUser && <CreateApplications program={row?.program} />}
        </div>
      </>
    );
  }
  const eventsHandlers = {
    onMouseEnter: (event: SyntheticEvent, column: ColumnDescription<Program>) => {
      event.preventDefault();
      const cell = event.currentTarget as HTMLElement;
      setHoveredCell(cell);
      setHoveredColumn(column);
    },
    onMouseLeave: () => {
      setHoveredCell(null);
    },
  };

  const columns = [
    {
      dataField: 'funder',
      text: 'Funder',
      formatter: funderFormatter,
      sort: true,
      headerStyle: () => ({ width: '23%' }),
      events: eventsHandlers,
    },
    {
      dataField: 'programName',
      text: 'Grant Program',
      sort: true,
      headerStyle: () => ({ width: '23%' }),
      events: eventsHandlers,
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      formatter: dateFormatter,
      sort: true,
      headerStyle: () => ({ width: '17%' }),
      events: eventsHandlers,
    },
    {
      dataField: 'dueDate',
      text: 'Due Date',
      formatter: dateFormatter,
      sort: true,
      headerStyle: () => ({ width: '17%' }),
      events: eventsHandlers,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: actionsFormatter,
      headerStyle: () => ({ width: '20%' }),
    },
  ];

  const selectRow = {
    mode: 'checkbox' as RowSelectionType,
    clickToSelect: !!isEditing,
    style: { backgroundColor: '#C9D4E9' },
    hideSelectAll: true,
    selected: selected.map((s) => s.id),
    onSelect: (row: ProgramsTableRow, isSelect: boolean) => {
      if (isSelect) {
        setSelected(selected.length ? [...selected, row] : [row]);
      } else {
        setSelected(selected?.filter((item) => item.id !== row.id));
      }
    },
  };

  useEffect(() => {
    if (storedFilters?.id && storedFilters?.id !== currentUser.id) removeStoredFilters();
    else if (checkFilterValues(programFilters) && !storedFilterSet) {
      const filter = storedFilters
        ? {
            ...storedFilters,
            [pageName]: { ...storedFilters[pageName], programFilters },
            id: currentUser.id,
          }
        : { [pageName]: { programFilters, id: currentUser.id } };
      setStoredFilters(filter);
    }
  }, [programFilters, storedFilterSet]);

  const handleCreateProgram = () => {
    setCreateProgramProps({
      disabled: !(
        currentUser.userType === 'millenniumAdmin' ||
        currentUser.userType === 'millenniumResearcher'
      ),
      onCreation: updateList,
    });
    navigate('/dashboard/create-program');
  };

  const handleViewOlder = () => {
    setIsModalOpen(true);
    setProgramFilters({ ...programFilters, endDate: null, startDate: null });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const startItem = (page - 1) * Number(PROGRAMS_PER_PAGE) + 1;
  const handleGoToPage = (e) => {
    e.preventDefault();
    const pageNum = parseInt(goToPage, 10);
    if (pageNum >= 1 && pageNum <= totalPages) {
      setPage(pageNum);
      window.scrollTo({ top: 100 });
    }
    setGoToPage('');
  };
  const displayValue = goToPage || (goToPage === '' ? '#' : page.toString());

  // @ts-expect-error no types support planned for react-bootstrap-table2
  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <CustomTooltip hoveredCell={hoveredCell} tooltipText={tooltipText} />
      <div>
        <div
          className={
            location?.pathname?.includes('user-dashboard')
              ? `application-content__list ${
                  isEditing
                    ? 'application-content__list--active'
                    : 'application-content__list--dissabled'
                }`
              : 'application-content__list'
          }
        >
          <BootstrapTable
            ref={programTableRef as unknown as LegacyRef<BootstrapTable<object, number>>}
            bootstrap4
            defaultSorted={[
              {
                dataField: programFilters?.sortBy || 'startDate',
                order: (programFilters?.sortOrder || 'asc') as 'asc' | 'desc',
              },
            ]}
            noDataIndication={() =>
              isError ? 'Error loading Programs. Reset filters or try again' : 'No Programs Found.'
            }
            onTableChange={onTableChange}
            overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
            remote={{
              filter: true,
              pagination: true,
              sort: true,
            }}
            sort={{
              dataField: programFilters?.sortBy || 'startDate',
              order: (programFilters?.sortOrder || 'asc') as 'asc' | 'desc',
            }}
            striped
            wrapperClasses="table-responsive table-borderless"
            {...(location?.pathname?.includes('user-dashboard') && { selectRow })}
            {...paginationTableProps}
            columns={columns}
            data={data}
            keyField="id"
            rowEvents={{
              onMouseEnter: (_e: SyntheticEvent, row: ProgramsTableRow) => {
                setHoveredRow(row);
              },
              onMouseLeave: () => {
                setHoveredRow(null);
              },
            }}
          />
        </div>
      </div>
      <div className="my-3 text-center">
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          mt={4}
          spacing={2}
          sx={{ width: '100%', position: 'relative' }}
        >
          {totalPages > 1 && (
            <div className="my-3 pagination-wrapper">
              <div className="records-showing-text">
                Showing {startItem} of {totalRecords}
              </div>
              <div className="pagination-controls-wrapper">
                <form className="pagination-form-field" onSubmit={handleGoToPage}>
                  <span className="me-2 label-text">Go to page</span>
                  <input
                    aria-label="Page number"
                    className="form-control form-control-sm goToPage-input"
                    max={totalPages}
                    min="1"
                    onBlur={() => {
                      if (goToPage === '') {
                        setGoToPage('#');
                      }
                    }}
                    onChange={(e) => setGoToPage(e.target.value)}
                    onFocus={() => {
                      if (goToPage === '' && page) {
                        setGoToPage(page.toString());
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleGoToPage(e);
                      }
                    }}
                    type="number"
                    value={displayValue || '#'}
                  />
                </form>

                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
          )}
          {location?.pathname?.includes('user-dashboard') && (
            <Box
              onClick={handleViewOlder}
              sx={{
                backgroundColor: '#FFFFFF',
                color: '#2C4474',
                fontWeight: 600,
                borderRadius: '4px',
                padding: '8px 16px',
                cursor: 'pointer',
                position: 'absolute',
                right: 0,
                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: '#2C4474',
                },
              }}
            >
              Show Old Programs
            </Box>
          )}
        </Stack>
      </div>
    </div>
  );

  return (
    <Stack className="program-listing-wrapper" direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <h3 className="program-heading">Programs</h3>
        {!location?.pathname?.includes('user-dashboard') && currentUser.isMillenniumUser && (
          <Button
            className="btn btn-primary d-flex justify-content-center align-items-center"
            disabled={
              !(
                currentUser.userType === 'millenniumAdmin' ||
                currentUser.userType === 'millenniumResearcher'
              )
            }
            onClick={handleCreateProgram}
          >
            <Plus size={16} style={{ marginBottom: '2px' }} />
            &nbsp; Create New Program
          </Button>
        )}
      </Stack>
      <h5 className="program-tagline">
        List of pre-filtered programs based on your interests and locations. Unread programs are
        highlighted with a red dot.{' '}
      </h5>
      <div id="content-table">
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            hideSizePerPage: true,
            withFirstAndLast: totalPages > 5,
            alwaysShowAllBtns: true,
            totalSize: totalPages * 20,
            page,
            sizePerPage: Number(PROGRAMS_PER_PAGE),
            firstPageText: '«',
            nextPageText: '›',
            prePageText: '‹',
            lastPageText: '»',
            onPageChange: (currentPage) => {
              setPage(currentPage);
              window.scrollTo({ top: 250 });
            },
          })}
        >
          {contentTable}
        </PaginationProvider>
      </div>

      <Modal
        dialogClassName="modal-90w"
        onHide={handleCloseModal}
        show={isModalOpen}
        size="xl"
        style={{ marginTop: '50px' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Old Programs</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'auto' }}>
          <BootstrapTable
            bootstrap4
            columns={columns}
            data={data}
            keyField="id"
            noDataIndication="No old programs found."
            striped
            wrapperClasses="table-responsive table-borderless"
          />
        </Modal.Body>
      </Modal>
    </Stack>
  );
}
