import { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import TooltipWrapper from 'shared/muiComponents/TooltipWrapper';
import { Box } from '@mui/material';

interface TextInputProps {
  controlId: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  type?: string;
  errors?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  text?: string;
  children?: (string | JSX.Element)[] | JSX.Element;
  maxLength?: number;
  minLength?: number;
  onBlur?: () => void;
  className?: string;
  textClass?: string;
}

export default function TextInput(props: TextInputProps) {
  const {
    controlId,
    required = false,
    label,
    placeholder,
    defaultValue,
    type = 'text',
    errors,
    disabled = false,
    onChange,
    text,
    children,
    maxLength,
    minLength,
    onBlur,
    className,
    textClass,
  } = props;

  const [value, setValue] = useState<string>(defaultValue || '');
  const [isTruncated, setIsTruncated] = useState(false);
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (spanRef.current) {
      setIsTruncated(spanRef.current.scrollWidth > spanRef.current.clientWidth);
    }
  }, [defaultValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <Form.Group className={className} controlId={controlId}>
      {label && (
        <Form.Label>
          {label}
          {required ? ' *' : ''}
        </Form.Label>
      )}
      <TooltipWrapper text={isTruncated && disabled ? defaultValue || '' : ''}>
        <Box sx={{ width: '100%' }}>
          <InputGroup className={className}>
            <Form.Control
              className={className}
              disabled={disabled}
              isInvalid={!!errors}
              maxLength={maxLength}
              minLength={minLength}
              onBlur={onBlur}
              onChange={handleChange}
              placeholder={placeholder}
              required={required}
              type={type}
              value={value}
            />

            <InputGroup.Append>{children}</InputGroup.Append>
            {Boolean(errors) && typeof errors === 'string' ? (
              <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
            ) : (
              <Form.Text className={textClass}>{text}</Form.Text>
            )}
          </InputGroup>
          <span
            ref={spanRef}
            style={{
              position: 'absolute',
              visibility: 'hidden',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            {defaultValue}
          </span>
        </Box>
      </TooltipWrapper>
    </Form.Group>
  );
}
