/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Box, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { Award } from 'types/awards';

import useUserSession from 'hooks/useUserSession';
import { formatCurrencyValue } from 'utils/utilFunctions';
import Notes from './Notes';
import Summary from './Summary';
// import FinancialDetails from './FinancialDetails';

export default function Details({
  awardEditForm,
  isNewAwardCreation,
  setUnsavedChanges,
}: {
  awardEditForm: FormikProps<Partial<Award>>;
  isNewAwardCreation: boolean;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}) {
  const { userType } = useUserSession();
  const { awardId } = useParams();
  const navigate = useNavigate();

  const canEditPreferences =
    userType === 'millenniumAdmin' || userType === 'millenniumManager' || userType === 'userAdmin';

  // useEffect(() => {
  //   // This effect will run whenever the `awardId` prop changes
  //   // You can add any additional logic here if needed
  // }, [awardId, awardEditForm.values]);

  return (
    <>
      {!isNewAwardCreation && <Notes />}

      <Card className="w-100 h-100">
        <Card.Body>
          {!isNewAwardCreation && (
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              {/* <Typography variant="h1">Details</Typography> */}
            </Stack>
          )}

          {!isNewAwardCreation && (
            <Box my={4}>
              <Typography color="primary.main" mb={2} variant="h2">
                Financial Summary
              </Typography>

              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <label>
                    Award Amount: <b>{formatCurrencyValue(awardEditForm.values.awardAmount)}</b>
                  </label>
                </Grid>
                <Grid item md={6} xs={12}>
                  <label>
                    Match: <b>{formatCurrencyValue(awardEditForm.values.matchAmount)}</b>
                  </label>
                </Grid>

                <Grid item md={6} xs={12}>
                  <label>
                    Award Balance: <b>{formatCurrencyValue(awardEditForm.values.awardBalance)}</b>
                  </label>
                </Grid>
                <Grid item md={6} xs={12}>
                  <label>
                    Award Expended: <b>{formatCurrencyValue(awardEditForm.values.awardExpended)}</b>
                  </label>
                </Grid>

                <Grid item md={6} xs={12}>
                  <label>
                    Match Balance: <b>{formatCurrencyValue(awardEditForm.values.matchBalance)}</b>
                  </label>
                </Grid>
                <Grid item md={6} xs={12}>
                  <label>
                    Match Expended: <b>{formatCurrencyValue(awardEditForm.values.matchExpended)}</b>
                  </label>
                </Grid>
              </Grid>
            </Box>
          )}

          <Summary
            awardEditForm={awardEditForm}
            isNewAwardCreation={isNewAwardCreation}
            setUnsavedChanges={setUnsavedChanges}
          />

          {/* Not used anymore */}
          {/* <FinancialDetails awardEditForm={awardEditForm} setUnsavedChanges={setUnsavedChanges} /> */}

          <InputLabel htmlFor="notes">Notes</InputLabel>
          <TextField
            disabled={!canEditPreferences}
            fullWidth
            id="notes"
            maxRows={10}
            multiline
            onChange={(event) => awardEditForm.setFieldValue('notes', event.target.value)}
            sx={{
              '&MuiInputBase-root': {
                lineHeight: '150%',
              },
            }}
            value={awardEditForm.values.notes || ''}
          />
        </Card.Body>
      </Card>
    </>
  );
}
