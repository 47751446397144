import { IconButton, Badge } from '@mui/material';
import { ReactNode, SyntheticEvent } from 'react';

import TooltipWrapper from 'shared/muiComponents/TooltipWrapper';

interface CustomIconButtonProps {
  children: ReactNode;
  tooltipText?: string;
  onClick: (event: SyntheticEvent) => void;
  showBadge?: boolean;
}

export default function CustomIconButton({
  children,
  tooltipText,
  onClick,
  showBadge = false,
}: CustomIconButtonProps) {
  return (
    <TooltipWrapper text={tooltipText || ''}>
      <Badge color="primary" invisible={!showBadge} variant="dot">
        <IconButton
          disableRipple
          onClick={onClick}
          sx={{
            p: 'auto',
            background: (theme) => theme.palette.secondary.light,
            '&:hover': {
              background: (theme) => theme.palette.primary.light,
            },
            borderRadius: '10%',
          }}
        >
          {children}
        </IconButton>
      </Badge>
    </TooltipWrapper>
  );
}
