/* eslint-disable no-alert */
import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Dropdown } from 'react-bootstrap';
import { Check, LogOut } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Box, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { ArrowBack } from '@mui/icons-material';
import { locations } from 'constants/globalConstants';

import useKeyPress from 'hooks/useKeyPress';
import { createUser, updateUser } from 'services/userService';
import useUserSession from 'hooks/useUserSession';
import useLocalStorage from 'hooks/useLocalStorage';
import { useUserDetails } from 'hooks/UserDetailsContext';

import { camelCaseToWords } from 'utils/utilFunctions';
import { tossError, tossSuccess } from 'utils/toastTosser';

import { defaultMandatoryRoles, User, UserType, userTypes, UserUpdatePayload } from 'types/user';
import { Client } from 'types/client';

import { searchClients } from 'services/clientService';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import AssignedToPicker from './AssignedToPicker';
import AssignedToManageAwardsPicker from './AssignedToManageAwardsPicker';
import 'assets/scss/editUser.scss';

interface Errors {
  name: string;
  email: string;
  phone: string;
  position: string;
  password: string;
}

export interface ClientOption {
  id: number;
  name: string;
  primary?: boolean;
}

interface EditUserForm {
  clientCreatorId?: number | null;
  name?: string;
  email?: string;
  phone?: string;
  userType?: UserType;
  location?: string;
  position?: string;
  defaultRole?: string | null;
  primaryClient?: number;
  client?: Client;
  assignedClients?: ClientOption[];
  awardClients?: ClientOption[];
  password?: string;
  passwordConfirmation?: string;
}

export default function EditUserPage() {
  const { user, onSave, isFromClientUsersList } = useUserDetails();

  const client = user?.clientCreator;

  const [form, setForm] = useState<EditUserForm>({} as EditUserForm);
  const [errors, setErrors] = useState<Errors>({} as Errors);

  const [, , removeStoredUser] = useLocalStorage('user', 'v1.0');
  const [, , removeStoredFilters] = useLocalStorage('filters', 'v1.0');

  const [selectedClient, setSelectedClient] = useState<Client | null>(client ?? null);

  const enterPress = useKeyPress('Enter');

  const currentUser = useUserSession();

  const navigate = useNavigate();
  const isMillenniumAdmin = currentUser.userType === 'millenniumAdmin';
  const { isMillenniumUser } = currentUser;
  const isClientAdmin = currentUser.userType === 'userAdmin';
  const isClientUser =
    currentUser.userType === 'userAdmin' || currentUser.userType === 'userAnalyst';

  const [isAuthUser] = useState(user?.id === currentUser.id);

  const isNew = !user?.id;

  const handleLogout = () => {
    removeStoredUser();
    removeStoredFilters();
    window.location.reload();
  };

  const setField = <K extends keyof EditUserForm>(field: K, value: EditUserForm[K]) =>
    setForm((prevState) => ({ ...prevState, [field]: value }));

  // Errors
  useEffect(() => {
    const { name, email, phone, password, passwordConfirmation } = form;

    const errorList = {} as Errors;

    if (typeof name !== 'undefined') {
      if (name === '') errorList.name = 'Name is required.';
      else if (name.length < 2)
        errorList.name = 'Names with less than 2 characters are not allowed.';
    }

    if (email) {
      if (
        email.match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
        )?.[0] !== email
      )
        errorList.email = 'Invalid email format.';
    }

    if (phone) {
      if (
        phone.match(
          /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g
        )?.[0] !== phone
      )
        errorList.phone = 'Invalid phone format.';
    }

    if (password) {
      if (passwordConfirmation !== password) errorList.password = 'Passwords do not match';
      if (password.length < 7) errorList.password = 'Password should be at least 7 characters long';
    }

    setErrors(errorList);
  }, [form]);

  useEffect(() => {
    return setField('clientCreatorId', selectedClient?.id || null);
  }, [selectedClient]);

  const save = async () => {
    const {
      clientCreatorId,
      name,
      email,
      phone,
      userType,
      defaultRole,
      location,
      position,
      password,
      passwordConfirmation,
    } = form;

    const payload: UserUpdatePayload = {
      ...(user?.id && { id: user.id }),
      clientCreatorId: currentUser.clientCreatorId ? currentUser.clientCreatorId : clientCreatorId,
      name,
      email,
      phone,
      userType,
      defaultRole,
      location,
      position,
      password,
      passwordConfirmation,
    };

    if (form.assignedClients) {
      payload.assignedClients = form.assignedClients.map((c) => c.id);
      payload.primaryClient = form.assignedClients
        .filter((c) => c.primary)
        .map((c) => c.id)
        .shift();
    }

    if (form.awardClients) {
      payload.awardClients = form.awardClients.map((c) => c.id);
    }

    if (isNew) {
      const newUser = await createUser(payload);

      if (newUser) {
        tossSuccess('new user created');
        onSave?.();
      } else tossError('Error creating a new user. Please make sure required fields are filled.');

      return;
    }

    // Do not send an empty client creator ID to the server by accident, it will clear the user's client.
    if (form.clientCreatorId !== undefined && !form.clientCreatorId) {
      delete payload.clientCreatorId;
    }

    if (window.confirm('Are you sure you want to edit this user?')) {
      const result = await updateUser(payload);

      if (result) {
        tossSuccess(`The user ${user?.name} was updated successfully.`);
        onSave?.();
      } else tossError(`Error updating the user ${name}.`);
    }
  };

  useEffect(() => {
    if (enterPress) {
      if (Object.keys(errors).length === 0) save();
    }
  }, [enterPress]);

  return (
    <>
      <Helmet>
        <title>Users - Grantrack</title>
      </Helmet>
      <div className="edit-user-page p-0">
        <Stack alignItems="space-between" direction="column" mb={4} spacing={1}>
          <Box mb={2}>
            {!(isAuthUser || isNew) && (
              <Stack alignItems="center" direction="row" spacing={1}>
                <span
                  className="breadcrumb-list"
                  onClick={() => navigate(-1)}
                  onKeyDown={(e) => e.key === 'Enter' && navigate(-1)}
                  role="button"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  tabIndex={0}
                >
                  Users
                </span>
                <span>{'>'}</span>
                <span className="breadcrumb-list" style={{ fontWeight: '700' }}>
                  User Details
                </span>
              </Stack>
            )}
          </Box>
          <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
            <Stack alignItems="center" direction="row" spacing={1}>
              {!(!isAuthUser || isNew) && (
                <IconButton
                  className="back-button"
                  onClick={() => navigate(-1)}
                  sx={{
                    width: '34px',
                    height: '34px',
                    backgroundColor: '#eff1f6',
                    borderRadius: '6px',
                    padding: '8px 16px',
                    '&:hover': {
                      backgroundColor: '#e0e3eb',
                    },
                  }}
                >
                  <ArrowBack />
                </IconButton>
              )}
              <h5 className="user-header-heading">
                {' '}
                {isAuthUser
                  ? `User Profile - ${user?.name}`
                  : `${isNew ? 'Add New User' : `User Details - ${user?.name}`}`}
              </h5>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button
                className="discard-btn"
                onClick={() => {
                  navigate(-1);
                }}
                style={{ height: '46px' }}
                variant="light"
              >
                Discard
              </Button>
              {isAuthUser && (
                <>
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    onClick={handleLogout}
                    variant="danger"
                  >
                    <LogOut size={16} />
                    &nbsp;Logout
                  </Button>
                  &nbsp;&nbsp;
                </>
              )}
              <Button
                className="d-flex justify-content-center align-items-center"
                disabled={Object.keys(errors).length > 0}
                onClick={save}
              >
                <Check size={16} />
                &nbsp;Save
              </Button>
            </Stack>
          </Stack>
          {!isNew && (
            <h6 className="user-sub-heading">
              {' '}
              {isAuthUser ? 'Edit your user information and notification preferences' : 'Edit User'}
            </h6>
          )}{' '}
        </Stack>

        <Form>
          <div className="user-general-fields">
            <Stack direction="column" spacing={3}>
              <h2 className="user-fields-heading mb-4">
                {isAuthUser ? 'Edit Profile' : `${isNew ? 'Add' : 'Edit'} User`}
              </h2>
              <Row>
                <Col md={4} xs={12}>
                  <Form.Group className="user-common-input" controlId="editUser.Name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      defaultValue={user?.name}
                      isInvalid={!!errors.name}
                      onChange={(e) => setField('name', e.target.value)}
                      placeholder="John Doe"
                      readOnly={!(isMillenniumAdmin || isMillenniumUser || isClientAdmin)}
                      required
                      type="text"
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} xs={12}>
                  <Form.Group className="user-common-input" controlId="editUser.Email">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      defaultValue={user?.email}
                      isInvalid={!!errors.email}
                      onChange={(e) => setField('email', e.target.value)}
                      placeholder="example@email.com"
                      readOnly={!(isMillenniumAdmin || isMillenniumUser || isClientAdmin)}
                      required
                      type="email"
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} xs={12}>
                  <Form.Group className="user-common-input" controlId="editUser.Phone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      defaultValue={user?.phone}
                      isInvalid={!!errors.phone}
                      onChange={(e) => setField('phone', e.target.value)}
                      placeholder="(000) 123-1100"
                      readOnly={!(isMillenniumAdmin || isMillenniumUser || isClientAdmin)}
                      required
                      type="phone"
                    />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {/* Second row with 3 fields */}
              <Row>
                <Col md={4} xs={12}>
                  <Form.Group className="user-common-input" controlId="editUser.Type">
                    <Form.Label>Type *</Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="w-100 text-left form-control"
                        disabled={!(isMillenniumAdmin || isMillenniumUser || isClientAdmin)}
                        variant="outline-select"
                      >
                        {camelCaseToWords(form.userType || user?.userType || 'Unspecified')}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-100">
                        {userTypes
                          .filter(
                            (type) =>
                              (currentUser.isMillenniumUser && !user?.clientCreatorId) ||
                              ['userAdmin', 'userAnalyst'].includes(type)
                          )
                          .map((type) => (
                            <Dropdown.Item key={type} onClick={() => setField('userType', type)}>
                              {camelCaseToWords(type)}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>

                {!isClientUser && (
                  <Col md={4} xs={12}>
                    <Form.Group className="user-common-input" controlId="editUser.Client">
                      <Form.Label>Client</Form.Label>
                      <SearchFilter<Client, false>
                        getLabel={(f: Client | unknown) => (f[0] || f).name}
                        label=""
                        multiple={false}
                        onChange={setSelectedClient}
                        readOnly={!isMillenniumAdmin}
                        searchFunction={async (input: string) => searchClients(input, 'name')}
                        selected={selectedClient}
                      />
                    </Form.Group>
                  </Col>
                )}

                {(isClientAdmin || isMillenniumAdmin) &&
                  (form?.clientCreatorId || user?.clientCreatorId) && (
                    <Col md={4} xs={12}>
                      <Form.Group className="user-common-input" controlId="editUser.DefaultRole">
                        <Form.Label>Default User Role</Form.Label>
                        <Dropdown>
                          <Dropdown.Toggle className="w-100 text-left" variant="outline-select">
                            {camelCaseToWords(
                              form?.defaultRole || user?.defaultRole || 'Select a Default Role'
                            )}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-100 user-common-input">
                            <Dropdown.Item onClick={() => setField('defaultRole', null)}>
                              None
                            </Dropdown.Item>

                            {defaultMandatoryRoles?.map((role) => (
                              <Dropdown.Item
                                key={role}
                                onClick={() => setField('defaultRole', role)}
                              >
                                {camelCaseToWords(role)}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Form.Group>
                    </Col>
                  )}
              </Row>

              {(isMillenniumAdmin || isMillenniumUser) && !isFromClientUsersList && (
                <Row>
                  <Col md={4} xs={12}>
                    <Form.Group className="user-common-input" controlId="editUser.Location">
                      <Form.Label>Location *</Form.Label>
                      <Dropdown>
                        <Dropdown.Toggle className="w-100 text-left" variant="outline-select">
                          {form?.location || user?.location || 'Select Location'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100 user-common-input">
                          {locations?.map((city) => (
                            <Dropdown.Item key={city} onClick={() => setField('location', city)}>
                              {city}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </Col>

                  <Col md={4} xs={12}>
                    <Form.Group className="user-common-input" controlId="editUser.Position">
                      <Form.Label>Position *</Form.Label>
                      <Form.Control
                        defaultValue={user?.position}
                        isInvalid={!!errors.position}
                        onChange={(e) => setField('position', e.target.value)}
                        placeholder="Invitee"
                        readOnly={!(isMillenniumAdmin || isMillenniumUser)}
                        required
                        type="text"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.position}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {(isMillenniumAdmin || isMillenniumUser) && !isFromClientUsersList && (
                <>
                  <Row>
                    <Col xs={12}>
                      <Form.Group
                        className="user-common-input"
                        controlId="editUser.assignedToPicker"
                      >
                        <Form.Label>Assigned to (manage Applications)</Form.Label>
                        <AssignedToPicker
                          onChange={(clients) => setField('assignedClients', clients)}
                          readOnly={!isMillenniumAdmin}
                          user={user}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Form.Group
                        className="user-common-input"
                        controlId="editUser.assignedToManageAwardsPicker"
                      >
                        <Form.Label>Assigned to (manage Awards)</Form.Label>
                        <AssignedToManageAwardsPicker
                          defaultValues={user?.awardClients || []}
                          onChangeSelected={(clients) => setField('awardClients', clients)}
                          options={form.assignedClients}
                          readOnly={!isMillenniumAdmin}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
            </Stack>
          </div>
          <div className="user-general-fields mt-4">
            <h2 className="user-fields-heading mb-4">Password & Security</h2>
            {(isClientAdmin || isMillenniumAdmin) && (
              <Row>
                <Col md={6} xs={12}>
                  <Form.Group className="user-common-input" controlId="editUser.password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.password}
                      onChange={(e) => setField('password', e.target.value)}
                      placeholder="Enter new Password"
                      type="password"
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Group
                    className="user-common-input"
                    controlId="editUser.passwordConfirmation"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.password}
                      onChange={(e) => setField('passwordConfirmation', e.target.value)}
                      placeholder="Password Confirmation"
                      type="password"
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </div>
        </Form>
      </div>
    </>
  );
}
