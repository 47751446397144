import { useState, useEffect, useContext } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Bar } from 'react-chartjs-2';

import { SelectInput } from 'shared/inputs';
import { StringsContext } from 'index';
import { ReportEntry } from 'services/reportService';
import { applicationsConfigs } from '../../../constants/globalConstants';
import 'assets/scss/ApplicationList.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function StatsCharts({
  sourceData,
  categoryData,
}: {
  sourceData: ReportEntry[];
  categoryData: ReportEntry[];
}) {
  const [barShowBy, setBarShowBy] = useState(0);
  const [barShow, setBarShow] = useState(0);

  const {
    applications: { sourceNames, categoryNames },
  } = useContext(StringsContext);
  const location = useLocation();

  const [barChartData, setBarChartData] = useState({
    labels: ['test1', 'test2', 'test3'],
    datasets: [
      {
        label: 'Values',
        data: [0, 1, 2],
      },
    ],
  });

  const update = () => {
    if (!(sourceData && categoryData)) return;

    const barNumbers = [];

    if (barShow === 0) {
      if (barShowBy === 0) {
        // Grant Applications Submitted by Source
        for (const currentSource of sourceData) barNumbers.push(currentSource.submitted);
      } else if (barShowBy === 1) {
        // Grant Applications Submitted by Category
        for (const currentCategory of categoryData) barNumbers.push(currentCategory.submitted);
      }
    } else if (barShow === 1) {
      if (barShowBy === 0) {
        // Grant Applications Awarded by Source
        for (const currentSource of sourceData) barNumbers.push(currentSource.awarded);
      } else if (barShowBy === 1) {
        // Grant Applications Awarded by Category
        for (const currentCategory of categoryData) barNumbers.push(currentCategory.awarded);
      }
    } else if (barShow === 2) {
      if (barShowBy === 0) {
        // Submissions Value by Source
        for (const currentSource of sourceData) barNumbers.push(currentSource.submissionsValue);
      } else if (barShowBy === 1) {
        // Submissions Value by Category
        for (const currentCategory of categoryData)
          barNumbers.push(currentCategory.submissionsValue);
      }
    } else if (barShow === 3) {
      if (barShowBy === 0) {
        // Awards Value by Source
        for (const currentSource of sourceData) barNumbers.push(currentSource.awardsValue);
      } else if (barShowBy === 1) {
        // Awards Value by Category
        for (const currentCategory of categoryData) barNumbers.push(currentCategory.awardsValue);
      }
    }

    const barData = {
      labels: barShowBy === 0 ? sourceNames : categoryNames,
      datasets: [
        {
          label: 'Values',
          data: barNumbers,
          backgroundColor: 'rgba(95,119,165, 1)',
          borderColor: 'rgba(89, 120, 169, 0.6)',
          borderWidth: 1,
        },
      ],
    };

    setBarChartData((prevState) => ({ ...prevState, ...barData }));
  };

  useEffect(update, [sourceData, categoryData, barShow, barShowBy]);

  const handleToggle = () => {
    setBarShowBy((prev) => (prev === 0 ? 1 : 0));
  };

  return (
    <Card className="w-100 stats-overview-container">
      <Card.Body className="p-0">
        <Container className="text-center report-stat-data" fluid>
          <Row className="stats-heading-selector">
            <h5 className="stats-overview-heading">
              {location?.pathname.includes('user-dashboard')
                ? 'Application Statistics'
                : 'Statistics'}
            </h5>
            <SelectInput
              choices={applicationsConfigs.reportsBarShow}
              className="common-input"
              controlId="stats.Show"
              onChange={setBarShow}
            />
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <h5 className="stats-description">
                  {applicationsConfigs.reportsBarShow[barShow]} by{' '}
                  {barShowBy === 0 ? 'Source' : 'Category'}
                </h5>
              </div>
              <div className="mt-4">
                <Bar
                  key={Math.random()}
                  data={barChartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    animation: {
                      duration: 1000,
                      easing: 'easeInOutQuint',
                    },
                    scales: {
                      x: {
                        ticks: {
                          color: '#2C4474',
                          font: {
                            size: 12,
                            weight: 'bold',
                          },
                        },
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        beginAtZero: true,
                        ticks: {
                          color: '#2C4474',
                          font: {
                            size: 12,
                            weight: 'bold',
                          },
                        },
                        grid: {
                          color: '#CFD6E4',
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="toggle-selector-wrapper">
            <span className="toggle-label me-3">By Source</span>
            <Form.Check
              checked={barShowBy === 1}
              className="custom-toggle"
              id="toggle-source-category"
              onChange={handleToggle}
              type="switch"
            />
            <span className="toggle-label"> By Category</span>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default StatsCharts;
