import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index';
import { assign } from 'lodash';
import expressions from 'angular-expressions';

// define your filter functions here, for example, to be able to write {clientname | lower}
expressions.filters.lower = (input) => {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};

export const loadFile = (
  path: string,
  callback: (fileError: Error, content: PizZip.LoadData) => void
) => {
  PizZipUtils.getBinaryContent(path, callback);
};

export const angularParser = (tag: string) => {
  if (tag === '.') {
    return {
      get(s) {
        return s;
      },
    };
  }

  const expr = expressions.compile(tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"'));

  return {
    get(scope, context) {
      let obj = {};
      const { scopeList } = context;
      const { num } = context;

      for (let i = 0, len = num + 1; i < len; i++) obj = assign(obj, scopeList[i]);

      return expr(scope, obj);
    },
  };
};

export const DocxMimeType =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
