/* eslint-disable react/no-unescaped-entities, no-nested-ternary */
import { useState } from 'react';
import { FormGroup, Input, Label, Button } from 'reactstrap';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { requestPasswordReset } from 'services/sessionService';
import 'assets/scss/signIn-form.scss';

export default function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [cooldown, setCooldown] = useState(false);

  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const requestReset = async () => {
    if (!email.match(emailRegex) || email.match(emailRegex)?.[0] !== email) {
      tossError('Please, enter a valid Email.');
      return;
    }

    setLoading(true);

    try {
      const result = await requestPasswordReset({ email });

      if (result) {
        tossSuccess('Email sent successfully! Please check your inbox for instructions.');
        setStep(2);
        setCooldown(true);
        setTimeout(() => setCooldown(false), 30000);
      } else {
        tossError('Error sending an email for password restoration.');
      }
    } catch (error) {
      tossError('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="custom-auth-form">
      <div className="custom-header">
        {step === 1 ? (
          <>
            <h4 className="custom-title">Forgot Password?</h4>
            <h6 className="custom-subtitle">
              Enter your credentials and we will send you a <br /> link for resetting your password.
            </h6>
          </>
        ) : (
          <>
            <h4 className="custom-title">Email Sent to "{email}"!</h4>
            <h6 className="custom-subtitle">Check your e-mail inbox to find a reset link.</h6>
            <p className="custom-subtitle">
              Didn’t get the e-mail? Please wait a moment before <br /> resending the reset link.
            </p>
          </>
        )}
      </div>

      {step === 1 && (
        <FormGroup className="custom-form-group">
          <Label className="custom-label">Your E-mail Address</Label>
          <Input
            className="form-control custom-input"
            value={email}
            onChange={handleEmailChange}
            required
            type="email"
            placeholder="Enter your E-mail Address"
          />
        </FormGroup>
      )}

      <FormGroup className="custom-button-group">
        <Button
          className="custom-login-button"
          color="primary"
          disabled={loading || !email || (step === 2 && cooldown)}
          onClick={requestReset}
        >
          {loading ? 'Processing...' : step === 1 ? 'Send Reset Link' : 'Resend the Link'}
        </Button>
      </FormGroup>
    </div>
  );
}
