import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AwardsPreferences from 'components/awards/AwardsPreferences';

function PreferencesPage() {
  return (
    <>
      <Helmet>
        <title>Awards - Preferences</title>
      </Helmet>

      <AwardsPreferences />

      <Outlet />
    </>
  );
}

export default PreferencesPage;
