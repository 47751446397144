import { Box, Stack, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import { Notifications } from 'types/awards';

export default function Notes({
  notes,
  handleClick,
}: {
  notes: Notifications[];
  handleClick: () => void;
}) {
  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        borderRadius: '12px',
        border: '1px solid #9FADC9',
        textDecoration: 'underline',
        backgroundColor: '#eff1f6',
        fontStyle: 'italic',
        p: 2,
        mt: 1,
        mb: 4,
      }}
    >
      {notes?.map((i) => (
        <Box
          key={nanoid()}
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Typography variant="h3">{i.subject}</Typography>
          <Typography fontStyle="italic" variant="caption">
            {i.body}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
}
