import { ApplicationFilter, ProgramFilter } from 'pages/Dashboard';

const checkFilterValues = (
  filterObj: ProgramFilter | ApplicationFilter | { [s: string]: unknown } | ArrayLike<unknown>
): boolean => {
  if (typeof filterObj !== 'object') return false;

  return !!Object.values(filterObj).filter((prop) => !!prop && prop?.length).length;
};

export default checkFilterValues;
