import { useEffect, useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';

interface SelectInputProps {
  controlId?: string;
  text?: string;
  defaultValue?: number | string;
  label: string;
  disabled?: boolean;
  required: boolean;
  choices: string[];
  onChange: (newValue: string | number) => void;
  tooltipData?: unknown[];
  errors?: string;
  className?: string;
  textClass?: string;
}

export default function SelectInput(props: SelectInputProps) {
  const {
    controlId,
    text,
    defaultValue = 0,
    label,
    disabled = false,
    required = false,
    choices = [],
    onChange = null,
    tooltipData,
    errors,
    className,
    textClass,
  } = props;

  const [selected, setSelected] = useState<number | string>(defaultValue);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(selected);
  }, [selected]);

  const defaultPlaceholder = () => {
    if (tooltipData && tooltipData?.length >= 1) {
      setSelected('Multiple');
    } else if (tooltipData?.length === 1) {
      setSelected(tooltipData[0] as string);
    } else {
      setSelected('None');
    }
  };

  useEffect(() => {
    if (tooltipData?.length) defaultPlaceholder();
  }, [tooltipData]);

  return (
    <Form.Group className={className} controlId={controlId}>
      <Form.Label>
        {label}
        {required ? ' *' : ''}
      </Form.Label>
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix={disabled ? 'dropdown-disabled' : 'dropdown-toggle'}
          className="w-100 text-left form-control"
          disabled={disabled}
          variant="outline-select"
        >
          {choices[selected] || selected}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
          {choices.map((currentChoice, choiceIndex) => (
            <Dropdown.Item key={currentChoice} onClick={() => setSelected(choiceIndex)}>
              {currentChoice}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {Boolean(errors) && typeof errors === 'string' && (
        <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
      )}
      {text && <Form.Text className={textClass}>{text}</Form.Text>}
    </Form.Group>
  );
}
