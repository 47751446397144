import { ReactNode } from 'react';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Button from '@mui/material/Button';

export default function StatusButton({
  onClick,
  children,
  active = false,
  startIcon = false,
}: {
  onClick: () => void;
  children: ReactNode;
  active?: boolean;
  startIcon?: boolean;
}) {
  return (
    <Button
      disableRipple
      onClick={onClick}
      startIcon={startIcon ? <CheckCircleOutline /> : ''}
      sx={{
        background: active ? 'none' : (theme) => theme.palette.secondary.light,
        color: active ? 'green' : (theme) => theme.palette.primary.light,
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '0.9rem',
        lineHeight: 1,
        minHeight: 32,
        '&:hover': {
          background: active ? 'none' : (theme) => theme.palette.secondary.main,
        },
      }}
      variant={active ? 'text' : 'contained'}
    >
      {children}
    </Button>
  );
}
