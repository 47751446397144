export { default as ApplicationEdit } from '../components/applications/ApplicationEdit';
export { default as awardsRoutes } from './Awards';
export { default as Applications } from './Applications';
export { default as Reports } from './Reports';
export { default as Dashboard } from './Dashboard';
export { default as ClientEdit } from '../components/clients/ClientEdit';
export { default as ClientList } from './ClientList';
export { default as ForgotPassword } from './ForgotPassword';
export { default as Register } from './Register';
export { default as ResetPassword } from './ResetPassword';
export { default as SignIn } from './SignIn';
export { default as UserList } from './UserList';
export { default as awardsDetailsRoutes } from './AwardsDetails';
export { default as Preferences } from './Preferences';
