import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const defaultResponse = Promise.reject(new Error('Request failed'));

class ApiServiceSingleton {
  // eslint-disable-next-line no-use-before-define
  private static onlyInstance: ApiServiceSingleton;

  private lastRequest: {
    request: Promise<AxiosResponse> | null;
    url: string;
    data?: unknown;
    config?: AxiosRequestConfig<unknown> | null;
  } = {
    request: null,
    url: '',
    data: null,
    config: null,
  };

  client: AxiosInstance;

  private constructor() {
    if (!ApiServiceSingleton.onlyInstance) ApiServiceSingleton.onlyInstance = this;

    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  set token(token: string) {
    this.client.defaults.headers.common.Authorization = token;
  }

  public static get api() {
    return this.onlyInstance || new this();
  }

  private checkLastRequest(
    url: string | null,
    data: unknown | null,
    config?: AxiosRequestConfig<unknown> | null
  ) {
    const requestIsTheSameAsTheLast = this.lastRequest.request && this.lastRequest.url === url;
    // this.lastRequest.data === data &&
    // this.lastRequest.config === config;

    // check key value pairs for data and config if they are objects
    const dataKeyObjectPairsAreEqual =
      JSON.stringify(data) === JSON.stringify(this.lastRequest.data);

    const configKeyObjectPairsAreEqual =
      JSON.stringify(config) === JSON.stringify(this.lastRequest.config);

    // Uncomment the following line to see the logs for debugging.
    // console.log(
    //   'checkLastRequest -> checked',
    //   { url: this.lastRequest.url, data: this.lastRequest.data, config: this.lastRequest.config },
    //   'New Request',
    //   { url, data: JSON.stringify(data), config: JSON.stringify(config) },
    //   'Results',
    //   {
    //     dataKeyObjectPairsAreEqual,
    //     requestIsTheSameAsTheLast,
    //     configKeyObjectPairsAreEqual,
    //   }
    // );

    return requestIsTheSameAsTheLast && dataKeyObjectPairsAreEqual && configKeyObjectPairsAreEqual;
  }

  async get(url: string, config?: AxiosRequestConfig<unknown>): Promise<AxiosResponse> {
    // If the same request is already in progress, return the same promise
    // if (this.checkLastRequest(url, null, config))
    //   return this.lastRequest.request || defaultResponse;

    this.lastRequest = {
      request: this.client.get(url, config),
      url,
      config,
    };

    return this.lastRequest.request || defaultResponse;
  }

  async post(url: string, data: unknown, config?: AxiosRequestConfig<unknown>) {
    // If the same request is already in progress, return the same promise
    // if (this.checkLastRequest(url, data, config))
    //   return this.lastRequest.request || defaultResponse;

    this.lastRequest = {
      request: this.client.post(url, data, config),
      url,
      data,
      config,
    };

    return this.lastRequest.request || defaultResponse;
  }

  async put(url: string, data: unknown): Promise<AxiosResponse> {
    // If the same request is already in progress, return the same promise
    // if (this.checkLastRequest(url, data)) return this.lastRequest.request || defaultResponse;

    this.lastRequest = {
      request: this.client.put(url, data),
      url,
      data,
    };

    return this.lastRequest.request || defaultResponse;
  }

  async patch(url: string, data: unknown): Promise<AxiosResponse> {
    // If the same request is already in progress, return the same promise
    // if (this.checkLastRequest(url, data)) return this.lastRequest.request || defaultResponse;

    this.lastRequest = {
      request: this.client.patch(url, data),
      url,
      data,
    };

    return this.lastRequest.request || defaultResponse;
  }

  async delete(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<AxiosResponse> {
    // If the same request is already in progress, return the same promise
    if (this.checkLastRequest(url, data, config))
      return this.lastRequest.request || defaultResponse;

    this.lastRequest = {
      request: this.client.delete(url, { ...config, data }),
      url,
      data,
      config,
    };

    return this.lastRequest.request || defaultResponse;
  }
}

export const outputError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    console.error(error.message);
  } else console.error(error);
};

const { api } = ApiServiceSingleton;
export default api;
