import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';

import { tossError } from 'utils/toastTosser';
import { updatePayment } from 'services/awards/awardPaymentsService';
import useCurrentAward from 'hooks/useCurrentAward';

import { AwardPayments } from 'types/awards';

import StatusButton from './StatusButton';

export default function ActionField({
  itemStatus,
  itemType,
  id,
  callback,
}: {
  itemStatus: string | null;
  itemType: string;
  id: number;
  callback?: () => void;
}) {
  const { currentAwardId } = useCurrentAward();

  const [status, setStatus] = useState(itemStatus);

  const update = async (newStatus: AwardPayments['status']) => {
    const result = await updatePayment(currentAwardId, {
      status: newStatus,
      id,
    });

    if (!result) {
      tossError('Error updating payment.');
      setStatus(itemStatus);
    } else if (callback) {
      callback();
    }
  };

  const paymentStatus: string = useMemo(() => {
    if (status === 'reimbursed') return 'Reimbursed';
    if (status === 'paid') return 'Paid';
    if (itemType === 'reimbursement' && !status) return 'Mark as Reimbursed';
    if (itemType === 'advance' && !status) return 'Mark as Paid';
    return '';
  }, [itemType, status]);

  const handleChangeStatus = () => {
    if (status) return;
    if (!status && itemType === 'reimbursement') {
      setStatus('reimbursed');
      update('reimbursed');
    }
    if (!status && itemType === 'advance') {
      setStatus('paid');
      update('paid');
    }
  };

  return (
    <Stack alignItems="center" direction="row">
      <StatusButton active={!!status} onClick={handleChangeStatus} startIcon>
        {paymentStatus}
      </StatusButton>
    </Stack>
  );
}
