import { useState } from 'react';
import { Stack, Typography, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { camelCaseToWords } from 'utils/utilFunctions';
import { EmptyAvatarIcon } from 'shared/muiComponents/customIcons';
import useUserSession from 'hooks/useUserSession';
import { useUserDetails } from 'hooks/UserDetailsContext';
import { User } from 'types/user';

export default function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();
  const currentUser = useUserSession();
  const { setUser, setOnSave, setIsFromClientUsersList } = useUserDetails();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const updateUserData = () => {
    // Refresh the page to change the display of any page to the new permissions.
    window.location.reload();
  };

  const handleViewProfile = (userData: User | null, isFromClientList = false) => {
    handleMenuClose();
    setUser(userData);
    setOnSave(() => {});
    setIsFromClientUsersList(isFromClientList);
    navigate('/dashboard/users/user-details');
  };
  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        ml={2}
        onClick={handleMenuOpen}
        onKeyDown={handleMenuOpen}
        role="button"
        sx={{ cursor: 'pointer' }}
        tabIndex={0}
      >
        <EmptyAvatarIcon />
        <Stack ml={1}>
          <Typography color="white" fontSize="0.8rem" fontWeight={600}>
            {currentUser.name}
          </Typography>
          <Typography
            color={(theme) => theme.palette.secondary.main}
            fontSize="0.7rem"
            fontWeight={600}
          >
            {camelCaseToWords(currentUser.userType)}
          </Typography>
        </Stack>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleMenuClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleViewProfile(currentUser, false)}>View Profile</MenuItem>
      </Menu>
    </>
  );
}
