import { ReactNode } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import useSearchParams from 'hooks/useSearchParams';

function Tab({ type, children }: { type: string; children: ReactNode }) {
  const { searchParams, setSearchParams } = useSearchParams();
  const active = searchParams.type ? searchParams.type === type : type === '';

  return (
    <Button
      onClick={() => setSearchParams({ type })}
      sx={{
        p: '10px 24px',
        border: (theme) =>
          `2px solid ${active ? theme.palette.primary.main : theme.palette.secondary.main}`,
        fontWeight: 600,
        fontSize: '0.8rem',
        lineHeight: 1,
        textTransform: 'none',
        color: (theme) => (active ? 'white' : theme.palette.primary.main),
        backgroundColor: (theme) => (!active ? 'white' : theme.palette.primary.main),
        '&:hover': {
          color: (theme) => (active ? 'white' : theme.palette.primary.main),
          backgroundColor: (theme) =>
            !active ? theme.palette.secondary.main : theme.palette.primary.main,
          border: (theme) => `2px solid ${theme.palette.secondary.main} !important`,
        },
      }}
    >
      {children}
    </Button>
  );
}

export default function ReportingNavBar() {
  return (
    <ButtonGroup component="nav">
      <Tab type="">All reports</Tab>
      <Tab type="financial">Financial</Tab>
      <Tab type="programmatic">Programmatic</Tab>
      <Tab type="combined">Combined</Tab>
    </ButtonGroup>
  );
}
