import { Container } from 'react-bootstrap';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { UserSessionContext } from 'index';

import { UsersAwardsList, getUsersAwards } from 'services/awards/awardsService';
import UsersTable from './UsersTable';
import UserFilters from './UserFilters';

export default function UserView() {
  const [data, setData] = useState<UsersAwardsList>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  const currentUser = useContext(UserSessionContext);

  const location = useLocation();

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const refetchUsers = useCallback(async () => {
    setLoading(true);

    const result = await getUsersAwards(location.search);

    if (isMounted.current) {
      if (result) {
        setData(result);
        setError(false);
      } else setError(true);

      setLoading(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (location.search) {
      refetchUsers();
    }
  }, [location.search]);

  const canFilter = currentUser?.isMillenniumUser || currentUser?.userType === 'userAdmin';

  return (
    <>
      <UserFilters canFilter={canFilter} />

      <Container fluid>
        {loading && <LinearProgress />}
        <UsersTable data={data} error={error} loading={loading} />
      </Container>
    </>
  );
}
