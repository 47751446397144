import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { searchFunders } from 'services/funderService';

interface FunderInputProps {
  controlId: string;
  disabled: boolean;
  errors?: string;
  text?: string;
  defaultValue?: string;
  placeholder: string;
  onChange: (selectedName: string | null) => void;
  tooltipData?: string;
  className?: string;
}

export default function FunderInput(props: FunderInputProps) {
  const {
    controlId,
    disabled = false,
    errors,
    text,
    defaultValue,
    placeholder,
    onChange,
    tooltipData,
    className,
  } = props;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<{ name: string }[]>(
    defaultValue ? [{ name: defaultValue }] : []
  );
  const [options, setOptions] = useState<{ name: string }[]>([]);

  const search = async (query: string) => {
    setLoading(true);
    const params = new URLSearchParams({ query, field: 'name' });
    const foundFunders = await searchFunders(params.toString());
    const mappedFunders = foundFunders.map((e) => ({
      name: e.funder,
    }));

    setOptions(mappedFunders);
    setLoading(false);
  };

  const onInputChange = (newValue: { name: string }[]) => {
    setSelected(newValue.length > 0 ? [newValue.pop()!] : []);
  };

  const defaultPlaceholder = () => {
    if (tooltipData && tooltipData.length > 1) {
      setSelected([{ name: 'Multiple' }]);
    } else if (tooltipData?.length === 1) {
      setSelected([{ name: tooltipData[0] }]);
    } else {
      setSelected([{ name: 'None' }]);
    }
  };

  useEffect(() => {
    // If we have a selected funder, their name is passed, else nothing is passed.
    onChange?.(selected.length > 0 ? selected[0].name : null);
  }, [selected]);

  useEffect(() => {
    if (tooltipData?.length) defaultPlaceholder();
  }, [tooltipData]);

  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>Funder {!disabled && '*'}</Form.Label>
      <AsyncTypeahead
        allowNew
        disabled={disabled}
        id={controlId}
        isInvalid={!!errors}
        isLoading={loading}
        labelKey="name"
        minLength={1}
        onChange={onInputChange}
        onSearch={search}
        options={options}
        placeholder={placeholder}
        renderMenuItemChildren={(option) => <span>{option.name}</span>}
        selected={selected}
      />
      {errors ? (
        <div className="validation-errors">{errors}</div>
      ) : (
        <Form.Text muted>{text}</Form.Text>
      )}
    </Form.Group>
  );
}
