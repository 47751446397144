import { useCallback, useEffect, useState } from 'react';
import Dropzone, { IFileWithMeta, IUploadParams, StatusValue } from 'react-dropzone-uploader';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { Client, ClientFile } from 'types/client';
import { getClientFiles } from 'services/clientFilesService';
import api from 'services/apiService';
import FileCard from 'shared/FileCard';
import 'assets/scss/ClientFiles.scss';
import { Stack, Typography } from '@mui/material';

interface ClientFilesProps {
  client?: Client;
  readOnly?: boolean;
}
export default function ClientFiles({ client, readOnly = false }: ClientFilesProps) {
  const [files, setFiles] = useState<ClientFile[]>([]);
  const [isclientFilesVisible, setIsclientFilesVisible] = useState(true);

  const refetch = useCallback(() => {
    if (client)
      getClientFiles(client?.id).then((fileList) => {
        const actualFiles: ClientFile[] = [];

        for (const file of fileList) {
          let convertedSize = +file.size;
          if (convertedSize < 1024) {
            file.size = `${convertedSize.toFixed(2)} B`;
          } else {
            convertedSize /= 1024;

            if (convertedSize < 1024) {
              file.size = `${convertedSize.toFixed(2)} KB`;
            } else {
              convertedSize /= 1024;

              if (convertedSize < 1024) {
                file.size = `${convertedSize.toFixed(2)} MB`;
              } else {
                convertedSize /= 1024;

                file.size = `${convertedSize.toFixed(2)} GB`;
              }
            }
          }

          actualFiles.push(file);
        }

        setFiles(actualFiles);
      });
  }, [client]);

  useEffect(refetch, [client, refetch]);

  const getUploadParams = (fileWithMeta: IFileWithMeta) => {
    const body = new FormData();
    body.append('clientId', client?.id.toString() || '');
    body.append('target', 'file');
    body.append('mId', fileWithMeta.meta.id);
    body.append('file', fileWithMeta.file);
    return {
      url: `${process.env.REACT_APP_API_URL}/clients`,
      body,
      headers: {
        Authorization: api.client?.defaults.headers.common.Authorization,
      },
    } as IUploadParams;
  };

  const handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      fileWithMeta.remove();
      refetch();
    }
  };

  return (
    <div className="client-files-wrapper">
      <div
        className="hide-show-wrapper"
        onClick={() => setIsclientFilesVisible(!isclientFilesVisible)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsclientFilesVisible(!isclientFilesVisible);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <h4 className="client-file-heading">
          Files
          <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
            {isclientFilesVisible ? (
              <>
                <KeyboardArrowUp className="ms-2" style={{ color: '#2C4474', fontSize: '18px' }} />
                <span className="show-hide-text">Hide Section</span>
              </>
            ) : (
              <>
                <KeyboardArrowDown
                  className="ms-2"
                  style={{ color: '#2C4474', fontSize: '18px' }}
                />{' '}
                <span className="show-hide-text">Show Section</span>
              </>
            )}
          </span>
        </h4>
      </div>

      {isclientFilesVisible && (
        <Form>
          <Container fluid>
            {!readOnly && (
              <Row>
                <Col className="p-0">
                  <div className="dz-message tall needsclick custom-dropzone">
                    <Dropzone
                      disabled={readOnly}
                      getUploadParams={getUploadParams}
                      inputContent={
                        <div className="dropzone-content">
                          <UploadOutlinedIcon className="upload-icon" />
                          <span>Browse or drop a file here</span>
                        </div>
                      }
                      onChangeStatus={handleChangeStatus}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Stack gap="10px" mt={2}>
              {files.length === 0 ? (
                <Typography color="textSecondary" variant="body2">
                  This client has no associated files.{' '}
                  {!readOnly &&
                    'Use the area above to upload new files by either dragging and dropping them or clicking the area for a file selection window.'}
                </Typography>
              ) : (
                files.map((file: ClientFile) => (
                  <FileCard
                    key={file.id}
                    afterRemove={refetch}
                    file={file}
                    readOnly={readOnly}
                    removeappfile={false}
                  />
                ))
              )}
            </Stack>
          </Container>
        </Form>
      )}
    </div>
  );
}
