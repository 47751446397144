import SearchFilter from 'shared/muiComponents/SearchFilter';
import { searchAwards } from 'services/awards/awardsService';
import useSearchParams from 'hooks/useSearchParams';
import { toArray } from 'utils/utilFunctions';

export default function DepartmentFilter({ width = '100%' }) {
  const { searchParams, setSearchParams } = useSearchParams();

  return (
    <SearchFilter<string>
      getLabel={(a) => a}
      label="Department"
      onChange={(values) => setSearchParams({ page: 1, departments: values })}
      searchFunction={async (input: string) =>
        (await searchAwards('department', input)).map((a) => a.department)
      }
      selected={toArray(searchParams.departments)}
      variant="filter"
      width={width}
    />
  );
}
