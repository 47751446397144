import { AxiosResponse } from 'axios';
import { ClientContact, CreateClientContactPayload } from 'types/client';

import api, { outputError } from './apiService';

export const getClientContacts = (clientId: number): Promise<ClientContact[]> =>
  api
    .get(`/clients?page=1&perPage=10000&id=${clientId}&target=contact`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data.rows;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });

export const searchClientContacts = (query: string, field: string): Promise<ClientContact[]> =>
  api
    .get(`/clients/search?query=${query}&field=${field}&target=contact`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });

export const createClientContact = (data: Partial<CreateClientContactPayload>): Promise<boolean> =>
  api
    .post(`/clients`, { ...data, target: 'contact' })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const updateClientContact = (
  data: Partial<CreateClientContactPayload> & { id: number }
): Promise<boolean> =>
  api
    .put(`/clients`, { ...data, target: 'contact' })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteClientContact = (id: number): Promise<boolean> =>
  api
    .delete(`/clients`, { data: { id, target: 'contact' } })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
