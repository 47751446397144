import { useContext, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import isNil from 'lodash.isnil';
import sum from 'lodash.sum';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { StringsContext } from 'index';

import { ISubmissionData } from 'pages/Reports';
import 'assets/scss/Reports.scss';

function Submissions({ data }: { data: ISubmissionData[] }) {
  const {
    applications: { sourceNames, categoryNames },
  } = useContext(StringsContext);

  const [showSection, setShowSection] = useState(true);

  function categoryFormatter(cell: number) {
    return categoryNames[cell] ?? cell;
  }

  function submissionShareFormatter(cell: number) {
    return isNil(cell) ? '—' : `${cell.toFixed(1)}%`;
  }

  function successRateFormatter(cell: number) {
    return isNil(cell) ? '—' : `${cell.toFixed(1)}%`;
  }

  const columns = [
    {
      dataField: 'category',
      text: 'Category',
      formatter: categoryFormatter,
      classes: 'custom-first-column',
      footer: 'Totals',
    },
    {
      dataField: 'total',
      text: 'Total',
      footer: (columnData: number[]) => String(sum(columnData)),
    },
    ...sourceNames.map((sourceName, sourceIndex) => {
      return {
        dataField: `source${sourceIndex}`,
        text: sourceName,
        footer: (columnData: number[]) => String(sum(columnData)),
      };
    }),
    {
      dataField: 'submissionShare',
      text: 'Submission Share',
      formatter: submissionShareFormatter,
    },
    {
      dataField: 'successRate',
      text: 'Success Rate',
      formatter: successRateFormatter,
    },
  ];

  return (
    <div className="w-100 section-wrapper">
      <div
        className="hide-show-wrapper"
        onClick={() => setShowSection(!showSection)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setShowSection(!showSection);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <h5 className="section-heading">
          Total Applications Submitted by Category
          <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
            {showSection ? (
              <>
                <KeyboardArrowUp className="ms-2" style={{ color: '#2C4474', fontSize: '18px' }} />
                <span className="show-hide-text">Hide Section</span>
              </>
            ) : (
              <>
                <KeyboardArrowDown
                  className="ms-2"
                  style={{ color: '#2C4474', fontSize: '18px' }}
                />{' '}
                <span className="show-hide-text">Show Section</span>
              </>
            )}
          </span>
        </h5>
      </div>
      {showSection && (
        <div style={{ marginTop: '32px' }}>
          <BootstrapTable
            bootstrap4
            columns={columns}
            data={data}
            keyField="id"
            remote={{
              filter: true,
            }}
            striped
            wrapperClasses="table-responsive table-borderless"
          />
        </div>
      )}
    </div>
  );
}

export default Submissions;
