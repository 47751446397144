import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Modal, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Trash as DeleteIcon, Check } from 'react-feather';

import { tossError, tossSuccess } from 'utils/toastTosser';
import useKeyPress from 'hooks/useKeyPress';

import { deleteApplication, deleteMultipleApplications } from 'services/applicationService';

import { Application } from 'types/application';

interface ModalProps {
  status: boolean;
  pending: boolean;
  title: string;
  message: string;
}

interface DeleteApplicationProps {
  applicationId: string;
  disabled: boolean;
  eligibleApps: {
    apps: Application[];
    status: string;
  };
  setIsModalOpen: Dispatch<SetStateAction<ModalProps>>;
}

export default function DeleteApplication({
  applicationId,
  disabled,
  eligibleApps,
  setIsModalOpen,
}: DeleteApplicationProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const escPress = useKeyPress('Esc');

  const toggle = () => setOpen((prevState) => !prevState);

  const deleteOne = async () => {
    const result = await deleteApplication(applicationId);

    if (result) {
      tossSuccess('The application had been deleted successfully.');
      toggle();
      navigate(-1);
    } else tossError('Error deleting this application.');
  };

  const deleteMultiple = async () => {
    setOpen(false);

    setIsModalOpen({
      status: true,
      pending: true,
      title: 'Bulk Deleting',
      message: 'Processing Changes...',
    });

    const response = await deleteMultipleApplications(eligibleApps.apps);

    if (response) {
      setIsModalOpen((prevState) => ({
        ...prevState,
        pending: false,
        title: 'Bulk Editing Completed',
        message: 'Please proceed to the applications screen to view your changes',
      }));
    } else tossError('Problem duplicating applications');
  };

  useEffect(() => {
    if (!escPress && open) {
      toggle();
    }
  }, [escPress]);

  const deleteButtonClassNme = 'd-flex justify-content-center align-items-center';

  return applicationId || eligibleApps ? (
    <>
      <Button
        className={
          eligibleApps?.apps?.length
            ? `w-115 ${deleteButtonClassNme}`
            : `w-100 ${deleteButtonClassNme}`
        }
        disabled={disabled}
        onClick={toggle}
        style={{ height: '46px' }}
        variant="danger"
      >
        <DeleteIcon size={16} />
        {!eligibleApps?.apps?.length && ' '}
        {eligibleApps?.apps?.length ? 'Delete All' : `Delete`}
      </Button>

      <Modal centered onHide={toggle} show={open}>
        <Modal.Header closeButton>Delete Application</Modal.Header>

        <Modal.Body>
          <Form>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <Alert variant="light">
                    This action is irreversible! The application will be deleted permanently. Do you
                    want to proceed?
                    {eligibleApps?.status && (
                      <p className="eligibility-status">{eligibleApps.status}</p>
                    )}
                  </Alert>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="d-flex justify-content-center align-items-center"
            onClick={eligibleApps?.apps?.length ? deleteMultiple : deleteOne}
            variant="save"
          >
            <Check size={16} />
            &nbsp;{eligibleApps?.apps?.length ? 'Delete All' : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div className="text-center">
      No application provided for deletion. Please reload the page or clear your cache if you see
      this text.
    </div>
  );
}
