import { SyntheticEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Box, Menu, MenuItem, Stack } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import isEqual from 'lodash/isEqual';
import {
  AddRounded,
  FileDownloadOutlined,
  SaveRounded,
  SearchRounded,
  SettingsBackupRestoreRounded,
  ShareOutlined,
  UndoRounded,
} from '@mui/icons-material';

import useSearchParams from 'hooks/useSearchParams';
import { tossError, tossSuccess } from 'utils/toastTosser';
import IconButton from 'shared/muiComponents/IconButton';
import { CreateFiltersSetModal, LoadFiltersSetModal } from 'components/awards/filters';
import { updateFilter } from 'services/filterService';
import { downloadAwardsCSV, downloadAwardsXLSX } from 'services/awards/awardsService';
import useUserSession from 'hooks/useUserSession';

interface LoadedFiltersSet {
  id: number;
  name: string;
  filters: string;
}

const defaultFiltersSet = { sortBy: 'endsOn', sortOrder: 'desc', page: '1', perPage: '20' };

export default function FiltersButtonsBlock({ view }: { view: string }) {
  const { userType } = useUserSession();
  const { searchParams } = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [loadedFiltersSet, setLoadedFiltersSet] = useState<LoadedFiltersSet | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [openedModal, setOpenedModal] = useState<'create' | 'load' | null>(null);

  const isLoadedSetUpdated =
    loadedFiltersSet !== null && location.pathname + location.search !== loadedFiltersSet?.filters;

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget instanceof HTMLElement ? event.currentTarget : null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportCSV = async () => {
    const data = await downloadAwardsCSV(location.search);
    if (data) saveAs(new Blob([data]), 'awards.zip');
    else tossError('Error exporting file');
  };

  const exportXLSX = async () => {
    const data = await downloadAwardsXLSX(location.search);
    if (data) saveAs(new Blob([data]), 'awards.xlsx');
    else tossError('Error exporting file');
  };

  const canExportAwards = userType !== 'millenniumAnalyst' && userType !== 'userAnalyst';

  return (
    <Stack direction="row" justifyContent="space-between" width="280px">
      <Box height="38px" ml={1} width="38px">
        <CopyToClipboard
          onCopy={() => tossSuccess('Link copied to clipboard')}
          text={window.location.href}
        >
          <IconButton onClick={() => {}} tooltipText="Copy link with filters set">
            <ShareOutlined htmlColor="black" />
          </IconButton>
        </CopyToClipboard>
      </Box>

      {view !== 'projectView' && canExportAwards && (
        <Box height="38px" ml={1} width="38px">
          {canExportAwards && (
            <IconButton onClick={(event) => handleClick(event)} tooltipText="Export List">
              <FileDownloadOutlined htmlColor="black" />
            </IconButton>
          )}
          <Menu
            anchorEl={anchorEl}
            id="download-menu"
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl)}
            sx={{
              '& .MuiMenu-paper': {
                mt: 1,
                borderRadius: 3,
                border: (theme) => `2px solid ${theme.palette.secondary.light}`,
                '.MuiMenu-list': {
                  p: 0,
                  '.MuiMenuItem-root': {
                    fontSize: '0.8rem',
                    '&:first-of-type': {
                      borderBottom: (theme) => `1px solid ${theme.palette.secondary.light}`,
                    },
                  },
                },
              },
            }}
          >
            <MenuItem onClick={() => exportCSV()}>Download as CSV</MenuItem>
            <MenuItem onClick={() => exportXLSX()}>Download as .xlsx</MenuItem>
          </Menu>
        </Box>
      )}

      <Box height="38px" ml={1} width="38px">
        <IconButton
          onClick={() => {
            setLoadedFiltersSet(null);
            navigate(`${location.pathname}?sortBy=endsOn&sortOrder=desc&page=1&perPage=20`);
          }}
          showBadge={!isEqual(searchParams, defaultFiltersSet)}
          tooltipText="Reset All Filters"
        >
          <UndoRounded htmlColor="black" />
        </IconButton>
      </Box>
      <Box height="38px" ml={1} width="38px">
        <IconButton
          onClick={() =>
            navigate(
              loadedFiltersSet?.filters ||
                `${location.pathname}?sortBy=endsOn&sortOrder=desc&page=1&perPage=20`
            )
          }
          showBadge={isLoadedSetUpdated}
          tooltipText="Revert to last loaded Filters set"
        >
          <SettingsBackupRestoreRounded htmlColor="black" />
        </IconButton>
      </Box>
      <Box height="38px" ml={1} width="38px">
        <IconButton
          onClick={async () => {
            if (!isLoadedSetUpdated) return setOpenedModal('create');

            const result = await updateFilter({
              id: loadedFiltersSet!.id,
              filters: location.pathname + location.search,
            });

            setLoadedFiltersSet({
              ...loadedFiltersSet!,
              filters: location.pathname + location.search,
            });

            return result
              ? tossSuccess(`${loadedFiltersSet!.name} updated.`)
              : tossError('Error updating a Filters Set.');
          }}
          tooltipText={
            isLoadedSetUpdated ? `Save ${loadedFiltersSet!.name}` : 'Create New Filters Set'
          }
        >
          {isLoadedSetUpdated ? (
            <SaveRounded htmlColor="black" />
          ) : (
            <AddRounded htmlColor="black" />
          )}
        </IconButton>
      </Box>
      <Box height="38px" ml={1} width="38px">
        <IconButton
          onClick={() => setOpenedModal('load')}
          tooltipText="Search for Saved Filters Sets"
        >
          <SearchRounded htmlColor="black" />
        </IconButton>
      </Box>

      {openedModal === 'load' && (
        <LoadFiltersSetModal
          onApply={(loadedSet) =>
            setLoadedFiltersSet({
              id: loadedSet.id,
              name: loadedSet.title,
              filters: String(loadedSet.filterData),
            })
          }
          onClose={() => setOpenedModal(null)}
          view={view}
        />
      )}

      {openedModal === 'create' && (
        <CreateFiltersSetModal
          onClose={() => setOpenedModal(null)}
          onCreate={(createdSet) =>
            setLoadedFiltersSet({
              id: createdSet.id,
              name: createdSet.title,
              filters: String(createdSet.filterData),
            })
          }
          view={view}
        />
      )}
    </Stack>
  );
}
