import Card from 'react-bootstrap/Card';
import { PieChart } from '@mui/x-charts/PieChart';
import { LinearProgress, Stack, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';

import MuiSwitch from 'shared/muiComponents/Switch';
import { Project } from 'types/awards';
import { formatCurrency } from 'components/awards/AwardsDetails/AwardsDetailsView/ActionHistory';

export interface SummaryDataProps {
  id: number;
  label: string;
  longLabel: string;
  value: number;
  color: string;
  type?: string;
}

const pieParams = { height: 220, paddingRight: 15 };
const chartPalette = ['#5F77A5', '#6163A1', '#6A8DA4', '#70A2A5', '#7161A1'];

const truncateText = (text: string, maxLength: number = 80) => {
  if (text.length > maxLength) return `${text.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis

  return text;
};

function FundingSummary({ data }: { data: Project }) {
  const [chartData, setChartData] = useState<SummaryDataProps[] | []>([]);

  // Step 1: Get award stats
  const awards = data?.awards?.map((award) => {
    // limit the size of the name to 100 characters
    // eslint-disable-next-line no-nested-ternary
    return {
      label: award.grantProgramName,
      amount: award.awardAmount || 0,
      type: 'awards',
    };
  });

  // Step 2: Get sources stats
  const sources = data?.sources?.map((source) => ({
    label: source.name || 'Name Missing',
    amount: source.amount || 0,
    type: 'sources',
  }));

  // Step 3: Combine the arrays
  const combinedArray = [...(awards || []), ...(sources || [])];

  // Step 4: Calculate the total amount
  const totalAmount = combinedArray?.reduce((sum, item) => sum + item.amount, 0);

  // Step 5: Get full stats with the desired fields
  const fullStats = combinedArray?.map((item, index) => {
    const percentage = (item.amount / totalAmount) * 100;

    return {
      id: index + 1,
      label: truncateText(item.label, 30),
      longLabel: truncateText(item.label, 70),
      value: Number(percentage.toFixed(2)),
      color: chartPalette[index % chartPalette.length],
      type: item.type,
    };
  });

  // Step 6: Filter items by type and sum their values
  const totalAwardsValue = fullStats
    ?.filter((item) => item.type === 'awards')
    ?.reduce((sum, item) => sum + item.value, 0);
  const totalSourcesValue = fullStats
    ?.filter((item) => item.type === 'sources')
    ?.reduce((sum, item) => sum + item.value, 0);

  const totalValue = totalAwardsValue + totalSourcesValue;

  // Step 7: Calculate the percentage of each type's total value
  const awardsPercentage = (totalAwardsValue / totalValue) * 100;
  const sourcesPercentage = (totalSourcesValue / totalValue) * 100;

  // Step 8: Get short stats with the desired fields
  const shortStats = [
    {
      id: 1,
      label: 'Millennium Awards',
      longLabel: 'Millennium Awards',
      value: Number(awardsPercentage.toFixed(2)),
      color: '#5F77A5',
    },
    {
      id: 2,
      label: 'Other Funding Sources',
      longLabel: 'Other Funding Sources',
      value: Number(sourcesPercentage.toFixed(2)),
      color: '#6163A1',
    },
  ];

  useEffect(() => {
    // if (fullStats?.length)
    setChartData(fullStats);
  }, [data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setChartData(shortStats);
    else setChartData(fullStats);
  };

  return (
    <Card className="w-100 py-3">
      <Card.Header className="details-card-header">
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography color="primary.main" variant="h2">
            Funding Summary
          </Typography>
          <FormGroup sx={{ flexDirection: 'row', columnGap: '15px', alignItems: 'center' }}>
            <Typography fontWeight="500" variant="body1">
              Source
            </Typography>
            <FormControlLabel
              control={<MuiSwitch onChange={handleChange} size="medium" sx={{ mr: 2 }} />}
              label="Category"
              sx={{
                m: 0,
                '& .MuiFormControlLabel-label	': {
                  fontFamily: 'Raleway',
                  color: '#50535B',
                  fontWeight: 600,
                },
              }}
            />
          </FormGroup>
        </Stack>
      </Card.Header>

      <Card.Body>
        <PieChart
          // Legend is deprecated in the latest version of MUI Charts
          // legend={{
          //   direction: 'row',
          //   position: { vertical: 'middle', horizontal: 'right' },
          // }}
          series={[
            {
              data: chartData,
              cx: 110,
            },
          ]}
          sx={{
            mb: 3,
            '& .MuiChartsLegend-series text': {
              fontSize: '16px !important',
              fontFamily: 'Raleway',
            },
            '& .MuiChartsLegend-mark': {
              rx: '100%',
              ry: '100%',
              width: 12,
              height: 12,
              y: -5,
            },
            // '& .MuiChartsLegend-root': {
            //   overflow: 'hidden',
            //   clipPath: 'inset(0 0 0 500px)', // Clips everything outside 200px width
            // },
          }}
          {...pieParams}
        />
        {data?.budget > 0 && (
          <Stack direction="row" justifyContent="space-between" mb={2}>
            <Typography fontWeight="600" variant="h3">
              Total Project Budget
            </Typography>

            <Typography fontWeight="600" variant="h3">{`$${formatCurrency(
              data.budget
            )}       `}</Typography>
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <Typography fontWeight="600" variant="h3">
            Total Funding
          </Typography>

          <Typography fontWeight="600" variant="h3">{`$${formatCurrency(totalAmount)} (${
            data.budget ? ((totalAmount / data.budget) * 100).toFixed(2) : 100
          }%)`}</Typography>
        </Stack>

        <div>
          {chartData?.map((item) => (
            <div key={item.id}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="500" variant="body1">
                  {item.longLabel}
                </Typography>
                <Typography fontWeight="500" variant="body1">
                  {item.value}%
                </Typography>
              </Stack>
              <LinearProgress
                style={{ backgroundColor: '#f0f0f0' }}
                sx={{
                  mb: 3,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: item.color,
                  },
                }}
                value={item.value}
                variant="determinate"
              />
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
}

export default FundingSummary;
