import { useCallback, useState } from 'react';
import { Stack, Typography } from '@mui/material';

import { CustomRoleRow } from 'components/awards/AwardsDetails/AwardsDetailsView/AssignRoles/getRolesColumns';

import InputField from 'shared/muiComponents/InputField';
import { searchUsers } from 'services/userService';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import StandardButton from 'shared/muiComponents/StandardButton';
import { createAwardRole } from 'services/awards/awardRolesService';
import { tossError } from 'utils/toastTosser';

interface AddCustomRoleBlockProps {
  awardId: string;
  fetchRoles: () => void;
  setUnsavedChanges: (value: boolean) => void;
}

export default function AddCustomRoleBlock({
  awardId,
  fetchRoles,
  setUnsavedChanges,
}: AddCustomRoleBlockProps) {
  const [customRole, setCustomRole] = useState<Partial<CustomRoleRow>>({});

  const submitRole = useCallback(async () => {
    const result = await createAwardRole(awardId, {
      awardId: Number(awardId),
      userId: customRole.userId,
      customUserRole: customRole.role,
    });

    if (result) {
      setCustomRole({});
      fetchRoles();
    } else tossError('Error creating Custom Role');

    setUnsavedChanges(false);
  }, [customRole]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="flex-start"
      mb={5}
      mt={2}
      spacing={2}
    >
      <InputField
        id="role"
        onChange={(value) => {
          setUnsavedChanges(true);
          setCustomRole({ ...customRole, role: value });
        }}
        placeholder="Role"
        required
        value={customRole.role || ''}
      />

      <SearchFilter<Partial<CustomRoleRow>, false>
        getLabel={(role) => role.userName || ''}
        label={customRole?.userName ? '' : 'Search for User'}
        multiple={false}
        onChange={(user) => {
          setUnsavedChanges(true);
          if (user) setCustomRole(user);
          else setCustomRole({} as CustomRoleRow);
        }}
        searchFunction={async (input: string) =>
          (await searchUsers(input, 'name')).map((foundUser) => ({
            ...customRole,
            userId: foundUser.id,
            userName: foundUser.name,
            email: foundUser.email,
            position: foundUser.position || '',
          }))
        }
        selected={customRole?.userName ? customRole : null}
        width="250px"
      />

      <Typography variant="caption" width="250px">
        {customRole.email || 'E-mail'}
      </Typography>

      <Typography variant="caption" width="250px">
        {customRole.position || 'Position'}
      </Typography>

      <StandardButton
        disabled={!customRole.role || !customRole.userId}
        onClick={submitRole}
        startIcon
      >
        Add
      </StandardButton>
    </Stack>
  );
}
