import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { capitalize } from 'lodash';

import useSearchParams from 'hooks/useSearchParams';
import useLocalStorage from 'hooks/useLocalStorage';
import { ClientFilter } from 'components/awards/filters';

import { toArray } from 'utils/utilFunctions';
import FiltersButtonsBlockProjects from './FiltersButtonsBlockProjects';

const defaultFiltersSet = { sortBy: 'createdAt', sortOrder: 'desc', page: '1', perPage: '20' };

export default function FinanceFilters() {
  const { searchParams, setSearchParams } = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [storedFiltersSet, setStoredFiltersSet] = useLocalStorage<string>(
    'projectsViewFilters',
    'v1.2'
  );

  useEffect(() => {
    if (storedFiltersSet) {
      navigate(storedFiltersSet, { replace: true });
    } else {
      setSearchParams(defaultFiltersSet);
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      setStoredFiltersSet(location.pathname + location.search);
    }
  }, [location]);

  const allowedFilters = ['clients'] as const;

  const usedFilters: { key: (typeof allowedFilters)[number]; values: string }[] = [];

  allowedFilters.forEach((key) => {
    if (toArray(searchParams[key]).length > 0)
      usedFilters.push({ key, values: toArray(searchParams[key]).join(', ') });
  });

  return (
    <>
      {/* <Typography m={2} variant="h2">
        Filters
      </Typography> */}
      <Stack direction="row" justifyContent="space-between" m={2}>
        <Stack direction="row" flexGrow={1} justifyContent="space-between">
          <ClientFilter width="100%" />
        </Stack>

        <FiltersButtonsBlockProjects view="projectView" customDefaultFilter={defaultFiltersSet} />
      </Stack>

      <Box alignItems="center" display="flex" flexWrap="wrap">
        {usedFilters.map((filter) => (
          <Stack key={filter.key} alignItems="center" direction="row">
            <Typography m={2} variant="body1">{`${capitalize(filter.key)}:`}</Typography>
            <Typography sx={{ color: 'grey' }} variant="body1">
              {filter.values}
            </Typography>
          </Stack>
        ))}
      </Box>
    </>
  );
}
