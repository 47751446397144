import { useEffect, useState } from 'react';
import FilterSelect from 'shared/muiComponents/FilterSelect';
import { applicationsConfigs } from '../../constants/globalConstants';

interface CategoryFilterProps {
  category: string;
  onChange: (newValue: string) => void;
  width?: string;
}
export default function CategoryFilter({ category, onChange, width }: CategoryFilterProps) {
  const [currentCategory, setCurrentCategory] = useState(category || 'all');
  useEffect(() => {
    setCurrentCategory(category || 'all');
  }, [category]);

  const handleChange = (value?: string) => {
    const newValue = value || 'all';
    setCurrentCategory(newValue);
    onChange(newValue);
  };

  const getValueLabel = (value?: string) => {
    if (!value || value === 'all') return 'All Categories';
    return applicationsConfigs.categories[parseInt(value, 10)];
  };

  const options = [
    'all',
    ...applicationsConfigs.alphabetSortedCategories.map((value) =>
      String(applicationsConfigs.categories.indexOf(value))
    ),
  ];

  return (
    <FilterSelect
      getValueLabel={getValueLabel}
      label="Category"
      onChange={handleChange}
      options={options}
      selected={currentCategory}
      width={width}
    />
  );
}
