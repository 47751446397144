import { useContext, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { Box, Stack } from '@mui/material';

import { tossSuccess } from 'utils/toastTosser';
import { FilterContext } from 'pages/Applications';
import useLocalStorage from 'hooks/useLocalStorage';

import { Program } from 'types/program';
import CategoryFilter from 'shared/filters/CategoryFilter';
import ProgramNamesFilter from 'shared/filters/ProgramNames';
import FunderFilter from 'shared/filters/FunderFilter';
import FilterSetControls from 'shared/filters/FilterSet';
import FilterActions from 'shared/filters/FilterActions';
import DateRangeFilter from 'shared/filters/DateRange';
import UsedProgramFiltersDescriptionBlock from './UsedProgramFiltersblock';

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

interface ProgramFiltersProps {
  onManualChange: () => void;
  switchTab: () => void;
  isMobile?: boolean;
  selected?: Program[];
  startMultipleEdit?: () => void;
  cancelMultipleEdit?: () => void;
  setAsRead?: () => void;
}

export default function ProgramFilters({
  onManualChange,
  switchTab,
  isMobile,
  selected,
  startMultipleEdit,
  cancelMultipleEdit,
  setAsRead,
}: ProgramFiltersProps) {
  const { programFilters: filters, setProgramFilters: setFilters } = useContext(FilterContext);
  const [storedFilters, setStoredFilters] = useLocalStorage('filters', 'v1.0');

  const location = useLocation();
  const pageName = location?.pathname?.split('/').at(-1);
  const today = dayjs();
  const fourteenDaysTD = today.startOf('D').subtract(14, 'd').toString();
  const endFourteenDaysTD = today.endOf('D').add(14, 'd').toString();

  const resetFilters = () => {
    const newFilter = storedFilters;
    delete newFilter[pageName].programFilters;
    setStoredFilters(newFilter);

    setFilters(
      location?.pathname?.includes('user-dashboard')
        ? {
            programNames: [],
            funders: [],
            category: 'all',
            startDate: fourteenDaysTD,
            endDate: endFourteenDaysTD,
            sortBy: 'startDate',
            sortOrder: 'desc',
          }
        : {
            programNames: [],
            funders: [],
            category: 'all',
            startDate: null,
            endDate: null,
            sortBy: 'startDate',
            sortOrder: 'desc',
          }
    );
  };

  const onFiltersCopy = () => {
    tossSuccess('Link copied to clipboard!');
  };

  const revertFilters = () => {
    const { currentSet } = filters ?? {};

    if (!currentSet) return;

    const { filterData } = currentSet;

    setFilters({ ...filterData, currentSet });
  };

  const updateSet = (newSet) => {
    const { filterData } = newSet ?? {};

    setFilters({ ...filterData, currentSet: newSet });
  };

  const updateFunders = (funders: string) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, funders }));
  };

  const updateProgramNames = (programNames: string) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, programNames }));
  };

  const updateDateRange = (start: string, end: string) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, startDate: start, endDate: end }));
  };

  const updateCategory = (category: string) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, category }));
  };

  const {
    funders: fundersCriteria,
    programNames: programNamesCriteria,
    category: categoryCriteria,
    startDate: startDateCriteria,
    endDate: endDateCriteria,
    currentSet,
  } = filters || {};

  const getDefaultResetFilters = (isUserDashboard: boolean) => {
    return isUserDashboard
      ? {
          programNames: [],
          funders: [],
          category: 'all',
          startDate: fourteenDaysTD,
          endDate: endFourteenDaysTD,
          sortBy: 'startDate',
          sortOrder: 'desc',
        }
      : {
          programNames: [],
          funders: [],
          category: 'all',
          startDate: null,
          endDate: null,
          sortBy: 'startDate',
          sortOrder: 'desc',
        };
  };
  const isUserDashboard = location?.pathname?.includes('user-dashboard');
  const defaultResetFilters = getDefaultResetFilters(isUserDashboard);
  return (
    <Stack direction="column" m={2}>
      <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
        <Col className="mb-3 col-xxl-4 filter-top-row p-0" lg={8} md={6}>
          <div className="filter-section-buttons">
            {!isMobile && (
              <div className="application-sections mb-3 mt-1">
                <div className="checked">Programs</div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div onClick={() => switchTab()}>Applications</div>
              </div>
            )}
          </div>
        </Col>
        {location?.pathname?.includes('user-dashboard') && (
          <Stack direction="row" justifyContent="flex-end" mt={0} spacing={2}>
            {!selected?.length ? (
              <Box
                onClick={startMultipleEdit}
                sx={{
                  backgroundColor: '#EFF1F6',
                  borderRadius: '4px',
                  padding: '8px 16px',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#5F77A5',
                    color: 'white',
                  },
                }}
              >
                Select Programs
              </Box>
            ) : (
              <>
                <Box
                  onClick={cancelMultipleEdit}
                  sx={{
                    backgroundColor: '#EFF1F6',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#5F77A5',
                      color: 'white',
                    },
                  }}
                >
                  Cancel Selection
                </Box>
                <Box
                  onClick={setAsRead}
                  sx={{
                    backgroundColor: '#EFF1F6',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#5F77A5',
                      color: 'white',
                    },
                  }}
                >
                  Mark {selected?.length} As Read
                </Box>
              </>
            )}
          </Stack>
        )}
      </Stack>
      <Stack
        alignItems="flex-start"
        direction="row"
        flexGrow={1}
        justifyContent="space-between"
        spacing={1}
      >
        <FunderFilter
          key="Funder"
          filters={fundersCriteria}
          onChange={updateFunders}
          sourceOfSearch="programs"
          width="50%"
        />
        <ProgramNamesFilter
          key="Grant Program"
          filters={programNamesCriteria}
          moduleId={3}
          onChange={updateProgramNames}
          width="50%"
        />
        <DateRangeFilter
          key="Date Range"
          endDate={endDateCriteria}
          onChange={updateDateRange}
          startDate={startDateCriteria}
        />
        <CategoryFilter
          key="Category"
          category={categoryCriteria}
          onChange={updateCategory}
          width="50%"
        />
        <FilterActions
          {...(!location?.pathname?.includes('user-dashboard') && {
            onCopy: onFiltersCopy,
            onRevert: revertFilters,
          })}
          defaultResetFilters={defaultResetFilters}
          filters={filters}
          onReset={resetFilters}
        />
        {!location?.pathname?.includes('user-dashboard') && (
          <FilterSetControls
            activeTab={0}
            dataType="App"
            defaultSet={currentSet}
            filters={filters}
            onRevert={revertFilters}
            onUpdate={updateSet}
          />
        )}
      </Stack>
      <UsedProgramFiltersDescriptionBlock />
    </Stack>
  );
}
