import { useCallback, useEffect, useState } from 'react';

export default function useLocalStorage<T>(
  key: string,
  storageVersion: string
): [T | undefined, (newValue: T) => void, () => void] {
  // Function to remove outdated versions of the storage data
  const removeOldValueVersions = useCallback(() => {
    Object.keys(localStorage).forEach((property) => {
      if (property.startsWith(key) && !property.endsWith(`__${storageVersion}`))
        localStorage.removeItem(property);
    });
  }, [key, storageVersion]);

  // Function to get the current value from localStorage
  const getValue = useCallback((): T | undefined => {
    try {
      const item = localStorage.getItem(`${key}__${storageVersion}`);
      return item ? (JSON.parse(item) as T) : undefined;
    } catch (error) {
      console.error('Error reading localStorage key:', key, error);
      return undefined;
    }
  }, [key, storageVersion]);

  const [value, setValue] = useState<T | undefined>(getValue);

  // Function to remove the current value from localStorage
  const removeValue = useCallback(() => {
    localStorage.removeItem(`${key}__${storageVersion}`);
    setValue(undefined);
  }, [key, storageVersion]);

  // Function to store a new value in localStorage
  const storeValue = useCallback(
    (newValue: T) => {
      try {
        localStorage.setItem(`${key}__${storageVersion}`, JSON.stringify(newValue));
        setValue(newValue);
      } catch (error) {
        console.error('Error saving to localStorage:', error);
      }
    },
    [key, storageVersion]
  );

  // Effect to perform cleanup on mount, ensuring old versions are cleared
  useEffect(() => {
    removeOldValueVersions();
  }, []);

  return [value, storeValue, removeValue];
}
