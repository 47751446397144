import { useState, useEffect, useCallback } from 'react';
import { AsyncTypeahead, Highlighter, Token } from 'react-bootstrap-typeahead';

import { searchUsers } from 'services/userService';

import { Client } from 'types/client';
import TooltipWrapper from 'shared/muiComponents/TooltipWrapper';

interface AssignedUserOption {
  id: number;
  name: string;
  isClientLead?: boolean;
  isClientDirector?: boolean;
}

interface ClientUsersPickerProps {
  controlId: string;
  readOnly?: boolean;
  client?: Client;
  onChange: (users: AssignedUserOption[]) => void;
  clientLeadId?: number;
  clientDirectorId?: number;
}

export default function ClientApplicationUsersPicker({
  controlId,
  readOnly,
  client,
  onChange,
  clientLeadId,
  clientDirectorId,
}: ClientUsersPickerProps) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<AssignedUserOption[]>([]);
  const [selected, setSelected] = useState<AssignedUserOption[]>([]);

  const isOptionSelected = useCallback(
    (option: AssignedUserOption) =>
      selected.some((selectedOption) => selectedOption.id === option.id),
    [selected]
  );

  useEffect(() => {
    if (client?.applicationUsers && client.applicationUsers.length > 0)
      setSelected(
        client.applicationUsers.map((user) => {
          const { id, name } = user;
          return {
            id,
            name,
            isClientLead: id === client.primaryUserId,
            isClientDirector: id === client.primaryUserId,
          };
        })
      );
  }, [client]);

  useEffect(() => {
    setSelected((currentSelected) =>
      currentSelected.map((user) => {
        const { id, name } = user;
        return {
          id,
          name,
          isClientLead: id === clientLeadId,
          isClientDirector: id === clientDirectorId,
        };
      })
    );
  }, [clientLeadId, clientDirectorId]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const search = async (query: string) => {
    setLoading(true);

    const foundUsers = (await searchUsers(query, 'name', false, false)).map((user) => ({
      id: user.id,
      name: user.name,
    }));

    setOptions(foundUsers.filter((option) => !isOptionSelected(option)));

    setLoading(false);
  };

  return (
    <>
      <AsyncTypeahead<AssignedUserOption>
        defaultSelected={selected}
        disabled={readOnly}
        id={controlId}
        isLoading={loading}
        labelKey="name"
        minLength={1}
        multiple
        onChange={setSelected}
        onSearch={search}
        options={options}
        placeholder={readOnly ? 'No permissions for editing this list.' : 'Search for a user...'}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
          <input
            {...inputProps}
            ref={(input) => {
              if (input) {
                referenceElementRef(input);
                inputRef(input);
              }
            }}
            className="form-control"
            style={{ border: '1px solid #ced4da', borderRadius: '4px' }}
          />
        )}
        renderMenuItemChildren={(option, props) => (
          <Highlighter search={props.text!}>{option.name}</Highlighter>
        )}
        renderToken={() => null}
        selected={selected}
      />
      <div style={{ marginTop: '8px' }}>
        {selected.map((user: AssignedUserOption) => (
          <TooltipWrapper
            key={user.id}
            text={`${user.isClientLead ? 'Client Lead' : ''}${
              user.isClientDirector ? ' Client Director' : ''
            }`}
          >
            <span>
              <Token
                className={`disable-select ${user.isClientLead ? 'client-lead' : ''} ${
                  user.isClientDirector ? 'client-director' : ''
                }`}
                disabled={readOnly}
                onRemove={() => setSelected(selected.filter((option) => option.id !== user.id))}
                option={user}
                readOnly={readOnly}
              >
                {user.name}
              </Token>
            </span>
          </TooltipWrapper>
        ))}
      </div>
    </>
  );
}
