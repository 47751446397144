import { ColumnDescription } from 'react-bootstrap-table-next';

import { UsersAwards } from 'types/awards';
import UserStatsBadge from './UserStatsBadge';

const getUsersAwardsColumns = (): ColumnDescription<UsersAwards>[] => [
  {
    dataField: 'name',
    text: 'User Name',
    sort: true,
  },
  {
    dataField: 'closedAwardsCount',
    text: 'Awards Closed',
    sort: true,
    formatter: (cell: string) => <UserStatsBadge cell={cell} />,
  },
  {
    dataField: 'openAwardsCount',
    text: 'Awards Open',
    sort: true,
    formatter: (cell: string) => <UserStatsBadge cell={cell} />,
  },
  {
    dataField: 'totalReportsOnTime',
    text: 'Reports on Time',
    sort: true,
    formatter: (cell: string) => <UserStatsBadge cell={cell} />,
  },
  {
    dataField: 'lateReportsCount',
    text: 'Late Reports',
    sort: true,
    formatter: (cell: string) => <UserStatsBadge cell={cell} warning />,
  },
];

export default getUsersAwardsColumns;
