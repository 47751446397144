import { Box } from '@mui/material';

import { StepWithCoordinates } from 'components/awards/AwardsDetails/AwardsDetailsView/Timeline/index';

export default function LeftLine({ step }: { step: StepWithCoordinates }) {
  const {
    nextStepColor,
    stepColor,
    rowDirection,
    lastInTotal,
    lastInRow,
    firstInTotal,
    firstInRow,
  } = step;

  const lineColor = rowDirection === 'left-to-right' ? stepColor : nextStepColor;

  const line = {
    position: 'absolute',
    height: '4px',
    width: '50%',
  };

  if (firstInTotal)
    return (
      <Box
        sx={{
          ...line,
          borderTop: '4px solid #3C5D9C',
          left: '20%',
        }}
      />
    );

  if (lastInTotal && rowDirection === 'right-to-left')
    return (
      <Box
        sx={{
          ...line,
          borderTop: '4px solid transparent',
          left: 0,
        }}
      />
    );

  if (firstInRow && rowDirection === 'left-to-right')
    return (
      <Box
        sx={{
          ...line,
          width: 'calc(50% - 20px)',
          height: 'calc(50% - 4px)',
          borderBottom: `4px solid ${lineColor}`,
          borderBottomLeftRadius: '40px',
          borderLeft: `4px solid ${lineColor}`,
          left: '20px',
          bottom: 'calc(50% - 2px)',
        }}
      />
    );

  if (lastInRow && rowDirection === 'right-to-left')
    return (
      <Box
        sx={{
          ...line,
          width: 'calc(50% - 20px)',
          height: 'calc(50% - 4px)',
          borderTop: `4px solid ${lineColor}`,
          borderTopLeftRadius: '40px',
          borderLeft: `4px solid ${lineColor}`,
          left: '20px',
          top: 'calc(50% - 2px)',
        }}
      />
    );

  return (
    <Box
      sx={{
        ...line,
        borderTop: `4px solid ${lineColor}`,
        left: 0,
      }}
    />
  );
}
