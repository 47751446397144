import { AxiosResponse } from 'axios';
import { Strings } from 'types/strings';

import api, { outputError } from './apiService';

const getStrings = (): Promise<Strings> =>
  api
    .get(`/strings`)
    .then((response: AxiosResponse) => {
      if (response.status < 302) return response.data;
      return {} as Strings;
    })
    .catch((error) => {
      outputError(error);
      return {} as Strings;
    });

export default getStrings;
