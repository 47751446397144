/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Check } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Box, IconButton, Stack } from '@mui/material';
import { ArrowBack, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { tossError, tossSuccess } from 'utils/toastTosser';
import useKeyPress from 'hooks/useKeyPress';
import StatePicker from 'shared/StatePicker';
import { SelectInput, TextInput, DateInput, MultiSelectInput } from 'shared/inputs';
import CustomFields, { CustomField } from 'shared/CustomFields';
import { useProgramContext } from 'hooks/ProgramContext';

import { createClient } from 'services/clientService';
import { CreateClientPayload } from 'types/client';
import UserSelect from 'components/clients/UserSelect';
import { getCountiesForStates, states, types } from 'constants/globalConstants';
import ClientApplicationUsersPicker from './ClientApplicationUsersPicker';
import ClientAwardUsersPicker from './ClientAwardUsersPicker';
import 'assets/scss/clientDetails.scss';

interface AssignedUserOption {
  id: number;
  name: string;
  isClientLead?: boolean;
  isClientDirector?: boolean;
}

type ClientCreateForm = Partial<{
  type: string;
  name: string;
  population: string;
  state: string;
  taxId: string;
  duns: string;
  contractDate: string;
  contractEndsAt: string;
  customFields: CustomField;
  assignedApplicationUsers: AssignedUserOption[];
  assignedAwardsUserIds: number[];
  clientLeadId: number;
  clientDirectorId: number;
  states: string[];
  counties: string[];
  billingType: string;
}>;

dayjs.extend(advancedFormat);

export default function CreateClient() {
  const [form, setForm] = useState<ClientCreateForm>({});
  const { clientCreationProps } = useProgramContext();
  const { onCreation } = clientCreationProps;
  const [errors, setErrors] = useState<
    Partial<Record<keyof ClientCreateForm, string>> & { required?: string }
  >({});
  const [iscustomfielsVisible, setIsCustomfieldsVisible] = useState(true);
  const [isgeneralfieldsVisible, setIsgeneralfieldsVisible] = useState(true);

  const navigate = useNavigate();

  const setField = (field: string, value: unknown) =>
    setForm((prevState) => ({ ...prevState, [field]: value }));

  const save = async (goToEdit = false) => {
    const {
      name,
      type,
      population,
      state,
      taxId,
      duns,
      contractDate,
      contractEndsAt,
      assignedApplicationUsers,
      assignedAwardsUserIds,
      clientLeadId,
      clientDirectorId,
      customFields,
      states: selectedStates,
      counties: selectedCounties,
      billingType,
    } = form;

    const submittedFields: CreateClientPayload = {
      type,
      name,
      population,
      state,
      taxId,
      duns,
      contractDate,
      contractEndsAt,
      customFields: JSON.stringify(customFields ?? []),
      assignedUsers: assignedApplicationUsers?.map((user) => user.id),
      awardUsers: assignedAwardsUserIds,
      primaryUser: clientLeadId,
      clientDirectorId,
      states: selectedStates,
      counties: selectedCounties,
      billingType,
    };

    console.log({ submittedFields });

    const newClient = await createClient(submittedFields);

    if (newClient.id) {
      tossSuccess('The client was successfully created.');
      if (goToEdit) {
        navigate(`${process.env.PUBLIC_URL}/dashboard/clients/edit/${newClient.id}`);
        window.scrollTo(0, 0);
      } else if (onCreation) {
        onCreation();
        navigate(-1);
      }
    } else tossError('Error creating the client.');
  };

  const cancelHandler = () => {
    setField('type', 0);
    setField('name', '');
    setField('population', '');
    setField('taxId', '');
    setField('duns', '');
    setField('contractDate', '');
    setField('contractEndsAt', '');
    setField('customFields', []);
    setField('assignedApplicationUsers', []);
    setField('assignedAwardsUserIds', []);
    setField('clientLeadId', 0);
    setField('clientDirectorId', 0);

    setErrors({});
  };

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const { name, type, population, states, taxId, duns } = form;

    const errorList: Partial<Record<keyof ClientCreateForm, string>> & { required?: string } = {};

    if (typeof name !== 'undefined') {
      if (!name || name === '') errorList.name = 'Name is required.';
      else if (name.length < 2)
        errorList.name = 'Names with less than 2 characters are not allowed.';
    }

    if (typeof states !== 'undefined') {
      if (!states) errorList.state = 'State is required.';
    }

    if (population) {
      if (Number(population) < 0)
        errorList.population = 'If specified, population must be 0 or greater.';
      else if (Number.isNaN(population)) errorList.population = 'Invalid input.';
    }

    if (taxId) {
      if (
        taxId.match(/^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/g)?.[0] !==
        taxId
      )
        errorList.taxId = 'Invalid format of a tax ID.';
    }

    if (duns) {
      if (!duns?.match(/^[A-Z0-9]+$/g)) {
        errorList.duns = 'Invalid format of a UEI number. (letters must be uppercase)';
      }
    }

    if ([typeof name, typeof type, typeof states].includes('undefined'))
      errorList.required = 'Some required fields are not filled out.';

    setErrors(errorList);
  }, [form]);

  return (
    <div style={{ maxWidth: '90%', margin: 'auto' }}>
      <Stack alignItems="space-between" direction="column" mb={4} spacing={1}>
        <Box mb={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <span
              className="breadcrumb-list"
              onClick={() => navigate(-1)}
              onKeyDown={(e) => e.key === 'Enter' && navigate(-1)}
              role="button"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              tabIndex={0}
            >
              Clients
            </span>
            <span>{'>'}</span>
            <span className="breadcrumb-list" style={{ fontWeight: '700' }}>
              Add New Client
            </span>
          </Stack>
        </Box>
        <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <IconButton
              className="back-button"
              onClick={() => navigate(-1)}
              sx={{
                width: '34px',
                height: '34px',
                backgroundColor: '#eff1f6',
                borderRadius: '6px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: '#e0e3eb',
                },
              }}
            >
              <ArrowBack />
            </IconButton>
            <h5 className="heading">Add New Client</h5>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button className="discard-btn" onClick={cancelHandler} variant="light">
              Cancel
            </Button>
            <Button
              className="d-flex justify-content-center align-items-center"
              disabled={Object.keys(errors).length > 0}
              onClick={() => save(false)}
            >
              <Check size={16} />
              &nbsp;Create
            </Button>
          </Stack>
        </Stack>
        <h6 className="sub-heading">Use this form to create new client</h6>
      </Stack>

      <Form>
        <Container className="p-0" fluid>
          <div className="client-general-fields">
            <div
              className="hide-show-wrapper"
              onClick={() => setIsgeneralfieldsVisible(!isgeneralfieldsVisible)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsgeneralfieldsVisible(!isgeneralfieldsVisible);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <h4 className="main-client-heading">
                Client Details
                <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
                  {isgeneralfieldsVisible ? (
                    <>
                      <KeyboardArrowUp
                        className="ms-2"
                        style={{ color: '#2C4474', fontSize: '18px' }}
                      />
                      <span className="show-hide-text">Hide Section</span>
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDown
                        className="ms-2"
                        style={{ color: '#2C4474', fontSize: '18px' }}
                      />{' '}
                      <span className="show-hide-text">Show Section</span>
                    </>
                  )}
                </span>
              </h4>
            </div>
            <div className="general-fields-w">
              {isgeneralfieldsVisible && (
                <>
                  <Row>
                    <Col lg={6} xs={12}>
                      <TextInput
                        className="common-input"
                        controlId="createClient.Name"
                        defaultValue={form.name || ''}
                        errors={errors.name}
                        label="Organisation Name"
                        onChange={(newValue) => setField('name', newValue)}
                        placeholder="John Doe"
                        required
                      />
                    </Col>
                    <Col lg={6} xs={12}>
                      <SelectInput
                        choices={types.map((e) => e.name)}
                        className="common-input"
                        controlId="createClient.Type"
                        defaultValue={form.type || 0}
                        errors={errors.type}
                        label="Org Type"
                        onChange={(newValue) => setField('type', newValue)}
                        required
                      />
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col lg={6} xs={12}>
                      <StatePicker
                        className="common-input"
                        controlId="createClient.StatePicker"
                        error={errors.state}
                        label="State"
                        onChange={(newValue) => setField('state', newValue)}
                        required
                        defaultValue={form.state || ''}
                      />
                    </Col> */}
                    <Col lg={6} xs={12}>
                      <SelectInput
                        choices={['Flex', 'Full']}
                        className="common-input"
                        controlId="createClient.BillingType"
                        defaultValue={form.billingType || 'Full'}
                        label="Billing Type"
                        onChange={(newValue) => {
                          const billingType =
                            typeof newValue === 'number'
                              ? newValue === 0
                                ? 'Flex'
                                : 'Full'
                              : newValue;
                          setField('billingType', billingType);
                        }}
                        required={false}
                      />
                    </Col>
                    <Col lg={6} xs={12}>
                      <TextInput
                        className="common-input"
                        controlId="createClient.Population"
                        defaultValue={form.population || ''}
                        errors={errors.population}
                        label="Population"
                        onChange={(newValue) => setField('population', newValue)}
                        placeholder="0"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} xs={12}>
                      <TextInput
                        className="common-input"
                        controlId="createClient.TaxID"
                        defaultValue={form.taxId || ''}
                        errors={errors.taxId}
                        label="Tax ID"
                        onChange={(newValue) => setField('taxId', newValue)}
                        placeholder="9XX7XXXXX"
                      />
                    </Col>
                    <Col lg={6} xs={12}>
                      <TextInput
                        className="common-input"
                        controlId="createClient.DUNS"
                        defaultValue={form.duns || ''}
                        errors={errors.duns}
                        label="UEI Number"
                        maxLength={12}
                        minLength={10}
                        onChange={(newValue) => setField('duns', newValue)}
                        placeholder="XXXXXXXXXXXX"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} xs={12}>
                      <DateInput
                        className="common-input"
                        controlId="createClient.ContractStartedAt"
                        defaultValue={form.contractDate || ''}
                        errors={errors.contractDate}
                        label="Contract Start Date"
                        onChange={(newValue) => setField('contractDate', newValue)}
                        placeholder={dayjs().format('dddd MMMM Do YYYY')}
                        text="If empty, memos base on calendar years."
                        textClass="currency-text"
                      />
                    </Col>
                    <Col lg={6} xs={12}>
                      <DateInput
                        className="common-input"
                        controlId="createClient.contractEndsAt"
                        defaultValue={form.contractDate || ''}
                        errors={errors.contractEndsAt}
                        label="Contract End Date"
                        onChange={(newValue) => setField('contractEndsAt', newValue)}
                        placeholder={dayjs().format('dddd MMMM Do YYYY')}
                        text="If empty, set to 12 months from the start date in memos."
                        textClass="currency-text"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} xs={12}>
                      <Form.Group className="common-input" controlId="assignedApplicationUsers">
                        <Form.Label>Assigned Application Users</Form.Label>
                        <ClientApplicationUsersPicker
                          clientDirectorId={form.clientDirectorId}
                          clientLeadId={form.clientLeadId}
                          controlId="assignedApplicationUsers"
                          onChange={(users) => setField('assignedApplicationUsers', users)}
                        />
                        <Form.Text className="currency-text">
                          Start typing the user&apos;s name in the box.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col lg={6} xs={12}>
                      <Form.Group className="common-input" controlId="clientLead">
                        <Form.Label>Client Lead</Form.Label>
                        <UserSelect
                          options={form.assignedApplicationUsers || []}
                          setSelectedId={(id) => setField('clientLeadId', id)}
                        />
                        <Form.Text className="currency-text">
                          Can be selected from Assigned Application Users.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} xs={12}>
                      <Form.Group className="common-input" controlId="clientDirector">
                        <Form.Label>Client Director</Form.Label>
                        <UserSelect
                          options={form.assignedApplicationUsers || []}
                          setSelectedId={(id) => setField('clientDirectorId', id)}
                        />
                        <Form.Text className="currency-text">
                          Can be selected from Assigned Application Users.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col lg={6} xs={12}>
                      <Form.Group className="common-input" controlId="assignedAwardUsers">
                        <Form.Label>Assigned Award Users</Form.Label>
                        <ClientAwardUsersPicker
                          defaultValues={[]}
                          onChange={(ids) => setField('assignedAwardsUserIds', ids)}
                          options={form.assignedApplicationUsers || []}
                        />
                        <Form.Text className="currency-text">
                          Can be selected from Assigned Application Users.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xs={12}>
                      <MultiSelectInput
                        choices={states.map((state) => state.name)}
                        className="common-input"
                        controlId="createClient.States"
                        defaultValue={form.states || []}
                        errors={errors.states}
                        label="State"
                        onChange={(selectedIndices) => {
                          const selectedStates = selectedIndices.map((index) => states[index].name);
                          setField('states', selectedStates);
                          setField('counties', []);
                        }}
                      />
                    </Col>
                    <Col lg={6} xs={12}>
                      <MultiSelectInput
                        choices={getCountiesForStates(
                          form.states
                            ?.map((stateName) => states.find((s) => s.name === stateName)?.abbr)
                            .filter(Boolean) || []
                        )}
                        className="common-input"
                        controlId="createClient.Counties"
                        defaultValue={form.counties || []}
                        disabled={!form.states?.length}
                        label="County"
                        onChange={(selectedIndices) => {
                          const availableCounties = getCountiesForStates(
                            form.states
                              ?.map((stateName) => states.find((s) => s.name === stateName)?.abbr)
                              .filter(Boolean) || []
                          );
                          const selectedCounties = selectedIndices.map(
                            (index) => availableCounties[index]
                          );
                          setField('counties', selectedCounties);
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>

          <div className="mt-4 custom-fields-wrapper">
            <div
              className="hide-show-wrapper"
              onClick={() => setIsCustomfieldsVisible(!iscustomfielsVisible)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsCustomfieldsVisible(!iscustomfielsVisible);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <h5 className="heading-gb mb-4">Custom Fields</h5>
              <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
                {iscustomfielsVisible ? (
                  <>
                    <KeyboardArrowUp
                      className="ms-2"
                      style={{ color: '#2C4474', fontSize: '18px' }}
                    />
                    <span className="show-hide-text">Hide Section</span>
                  </>
                ) : (
                  <>
                    <KeyboardArrowDown
                      className="ms-2"
                      style={{ color: '#2C4474', fontSize: '18px' }}
                    />{' '}
                    <span className="show-hide-text">Show Section</span>
                  </>
                )}
              </span>
            </div>
            {iscustomfielsVisible && (
              <CustomFields onChange={(newFields) => setField('customFields', newFields)} />
            )}
          </div>
        </Container>
      </Form>
    </div>
  );
}
