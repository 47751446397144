import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BootstrapTable, {
  PaginationOptions,
  BootstrapTableProps,
  ColumnDescription,
} from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import useUserSession from 'hooks/useUserSession';
import useSearchParams from 'hooks/useSearchParams';
import { AwardsList } from 'services/awards/awardsService';

import NoAwardsEmptyScreen from 'components/awards/NoAwardsEmptyScreen';
import { Award } from 'types/awards';
import TooltipWithAnchor from 'shared/muiComponents/TooltipWithAnchor';
import getSummaryAwardsColumns from 'components/awards/SummaryView/getSummaryAwardsColumns';
import isEqual from 'lodash/isEqual';

interface SummaryAwardsTableProps {
  data?: AwardsList;
  loading: boolean;
  error: boolean;
}

const defaultFiltersSet = { sortBy: 'endsOn', sortOrder: 'desc', page: '1', perPage: '20' };

export default function SummaryAwardsTable({ data, loading, error }: SummaryAwardsTableProps) {
  const currentUser = useUserSession();
  const { searchParams, setSearchParams } = useSearchParams();
  const navigate = useNavigate();

  const [hoveredRow, setHoveredRow] = useState<Award | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<ColumnDescription<Award> | null>(null);
  const [hoveredCell, setHoveredCell] = useState<HTMLElement | null>(null);
  const [goToPage, setGoToPage] = useState('');

  const isFiltered = !isEqual(searchParams, defaultFiltersSet);

  let tooltipText = 'Click to view Award details';

  if (hoveredRow && hoveredColumn && hoveredRow.status !== 'closed') {
    if (hoveredRow.awardExpended >= hoveredRow.awardAmount * 0.8)
      tooltipText = 'You are close to spending your award amount';
    if (hoveredRow.awardBalance < 0)
      tooltipText = 'Negative balance: Spending has exceeded award amount';
    if (
      !hoveredRow.endsOn ||
      (new Date(hoveredRow.endsOn) <= new Date() && hoveredRow.status !== 'closed')
    )
      tooltipText = 'End date is not set or in the past';
    if (
      hoveredRow.nextReportDueDate &&
      hoveredRow.reports?.some(
        (report) =>
          new Date(report.endsOn) <= new Date(hoveredRow.nextReportDueDate || '') &&
          new Date(hoveredRow.nextReportDueDate || '') < new Date()
      )
    )
      tooltipText = 'A report is overdue';
  }

  const handleSortChange = (sortField: string, sortOrder: string) => {
    setSearchParams({ page: 1, sortBy: sortField, sortOrder });
  };

  const currentPage = Number(searchParams.page);
  const perPage = Number(searchParams.perPage);
  const totalRecords = data?.count || 0;
  const pageCount = Math.ceil(totalRecords / perPage);
  const startItem = (currentPage - 1) * perPage + 1;

  const handleGoToPage = (e: React.FormEvent) => {
    e.preventDefault();
    const pageNum = parseInt(goToPage, 10);
    if (pageNum >= 1 && pageNum <= pageCount) {
      setSearchParams({ page: pageNum });
      window.scrollTo({ top: 250 });
    }
    setGoToPage('');
  };

  const displayValue = goToPage || (goToPage === '' ? '#' : currentPage.toString());

  const contentTable = ({
    paginationProps,
    paginationTableProps,
  }: {
    paginationProps: PaginationOptions;
    paginationTableProps: BootstrapTableProps;
  }) => (
    <>
      <TooltipWithAnchor
        anchorElement={hoveredCell}
        isOpen={Boolean(hoveredRow)}
        text={tooltipText}
      />

      <BootstrapTable<Award>
        {...paginationTableProps}
        bodyClasses={loading ? 'loading' : ''}
        bootstrap4
        bordered={false}
        columns={getSummaryAwardsColumns(
          currentUser.isMillenniumUser!,
          setHoveredCell,
          setHoveredColumn
        )}
        data={data?.rows || []}
        keyField="id"
        noDataIndication="No Awards found for selected filters"
        onTableChange={(type, newState) => {
          if (type === 'sort') {
            handleSortChange(newState.sortField, newState.sortOrder);
          }
        }}
        remote={{
          sort: true,
          pagination: true,
        }}
        rowClasses={(row: Award) => {
          let containsError = false;

          if (row.status !== 'closed') {
            const expendedError = row.awardExpended >= row.awardAmount;
            const balanceError = row.awardBalance < 0;
            const dateError = !row.endsOn || new Date(row.endsOn) <= new Date();
            const reportError =
              row.nextReportDueDate &&
              row.reports?.some((report) => {
                return (
                  new Date(report.endsOn) <= new Date(row.nextReportDueDate || '') &&
                  new Date(row.nextReportDueDate || '') < new Date()
                );
              });
            containsError = Boolean(expendedError || balanceError || dateError || reportError);
          }

          return containsError ? 'danger' : '';
        }}
        rowEvents={{
          onClick: (_e: SyntheticEvent, row: Award) => {
            // scroll to the top of the page
            window.scrollTo(0, 0);
            navigate(`/dashboard/award/${row.id}/details`);
          },
          onMouseEnter: (_event: SyntheticEvent, row: Award) => setHoveredRow(row),
          onMouseLeave: () => setHoveredRow(null),
        }}
        sort={{ dataField: searchParams.sortBy, order: searchParams.sortOrder as 'asc' | 'desc' }}
        wrapperClasses="table-responsive table-borderless hover"
      />

      {data?.count >= 1 && (
        <div className="my-3 pagination-wrapper">
          <div className="records-showing-text">
            Showing {startItem} of {totalRecords}
          </div>
          <div className="pagination-controls-wrapper">
            <form className="pagination-form-field" onSubmit={handleGoToPage}>
              <span className="me-2 label-text">Go to page</span>
              <input
                aria-label="Page number"
                className="form-control form-control-sm goToPage-input"
                max={pageCount}
                min="1"
                onBlur={() => {
                  if (goToPage === '') {
                    setGoToPage('#');
                  }
                }}
                onChange={(e) => setGoToPage(e.target.value)}
                onFocus={() => {
                  if (goToPage === '' && currentPage) {
                    setGoToPage(currentPage.toString());
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleGoToPage(e);
                  }
                }}
                type="number"
                value={displayValue || '#'}
              />
            </form>

            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </>
  );

  if (!isFiltered && !loading && data?.rows?.length === 0) return <NoAwardsEmptyScreen />;

  if (!data?.rows) return <div />;

  if (error)
    return (
      <>
        <p>Error fetching awards.</p>
        <p>Try to reset filters.</p>
      </>
    );

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        hideSizePerPage: true,
        withFirstAndLast: pageCount > 5,
        alwaysShowAllBtns: true,
        page: currentPage,
        totalSize: data?.count,
        sizePerPage: perPage,
        firstPageText: '«',
        nextPageText: '›',
        prePageText: '‹',
        lastPageText: '»',
        onPageChange: (newPage) => {
          setSearchParams({ page: newPage });
          window.scrollTo({ top: 250 });
        },
      })}
    >
      {contentTable}
    </PaginationProvider>
  );
}
