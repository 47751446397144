import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Token } from 'react-bootstrap-typeahead';

import Form from 'react-bootstrap/Form';
import { ClientOption } from './EditUserModal';

export default function AssignedToManageAwardsPicker({
  readOnly,
  options,
  defaultValues,
  onChangeSelected,
}: {
  readOnly: boolean;
  options?: ClientOption[];
  defaultValues: ClientOption[];
  onChangeSelected: (selectedUsers: ClientOption[]) => void;
}) {
  const [selected, setSelected] = useState<ClientOption[]>(defaultValues);

  useEffect(() => {
    if (options)
      setSelected(selected.filter((client) => Boolean(options.find((c) => c.id === client.id))));
  }, [options]);

  useEffect(() => {
    onChangeSelected(selected);
  }, [selected]);

  const availableOptions = options
    ? options.filter((client) => !selected.find((c) => c.id === client.id))
    : [];

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="w-100 text-left form-control"
        disabled={readOnly || availableOptions.length === 0}
        variant="outline-select"
      >
        Add Clients
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100">
        {availableOptions.map((client) => (
          <Dropdown.Item key={client.id} onClick={() => setSelected([...selected, client])}>
            {client.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>

      <div style={{ marginTop: '10px' }}>
        {selected.map((client) => (
          <Token
            key={client.id}
            onRemove={() => setSelected(selected.filter((c) => c.id !== client.id))}
            option={client}
            readOnly={readOnly}
          >
            {client.name}
          </Token>
        ))}
      </div>

      <Form.Text className="currency-text">
        Can only be selected from the Assigned To list above
      </Form.Text>
    </Dropdown>
  );
}
