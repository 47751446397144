import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export default function CategoryNameField({
  value,
  onSave,
  ml = 0,
  disabled = false,
}: {
  value: string;
  onSave: (newValue: string) => void;
  ml?: number;
  disabled?: boolean;
}) {
  const [currentValue, setCurrentValue] = useState<string>(value);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (value !== currentValue) {
      timeout = setTimeout(() => {
        onSave(currentValue);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [currentValue, onSave]);

  return (
    <TextField
      disabled={disabled}
      fullWidth
      onBlur={() => onSave(currentValue)}
      onChange={(event) => {
        const newValue = event.target.value;
        setCurrentValue(newValue);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') onSave(currentValue);
      }}
      size="small"
      sx={{
        ml,
        '&.Mui-disabled': {
          '& fieldset': {
            borderColor: 'transparent',
          },
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-disabled': {
            '& fieldset': {
              border: 'transparent',
            },
          },
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: (theme) => theme.palette.primary.light,
          },
          '&.Mui-focused fieldset': {
            borderColor: (theme) => theme.palette.primary.main,
          },
        },
      }}
      value={currentValue}
    />
  );
}
