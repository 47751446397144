import { isEmpty } from 'lodash';
import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-expect-error no types support planned for react-bootstrap-table2
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { Edit, Eye, Plus } from 'react-feather';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Button, CustomInput } from 'reactstrap';
import { Stack } from '@mui/material';
import { User } from 'types/user';
import { Client, ClientsList } from 'types/client';

import { getClientsList, updateClient } from 'services/clientService';
import useUserSession from 'hooks/useUserSession';

import reduceTZ from 'utils/dateUtils';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { scrollToBottom } from 'utils/utilFunctions';

import Breadcrumbs from 'components/layout/Breadcrumbs';
import ClientMemoGen from 'components/clients/ClientMemoGen';
import ClientFilters from 'components/clients/ClientFilters';
import { clientTypes } from 'constants/globalConstants';
import { useProgramContext } from 'hooks/ProgramContext';
import 'assets/scss/ClientList.scss';

export interface ClientFilters {
  names: { name: string }[];
  state: string | null;
  taxIds: { name: string }[];
  duns: { name: string }[];
  contactNames: { name: string }[];
  contactEmails: { name: string }[];
  contactPhones: { name: string }[];
  contactTitles: { name: string }[];
  page: number;
  myClients: boolean;
}

interface ClientFiltersContext {
  filters: ClientFilters;
  setFilters: Dispatch<SetStateAction<ClientFilters>>;
}

export const FilterContext = createContext<ClientFiltersContext>({} as ClientFiltersContext);

export default function ClientList() {
  const { REACT_APP_CLIENTS_PER_PAGE: CLIENTS_PER_PAGE } = process.env;

  const currentUser = useUserSession();

  const [filters, setFilters] = useState<ClientFilters>({} as ClientFilters);

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<Client[]>([]);
  const [dataSize, setDataSize] = useState(0);
  const [fetching, setFetching] = useState(true);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const tableRef = useRef(null);
  const { setClientCreationProps } = useProgramContext();

  const refetchList = useCallback(async () => {
    const {
      names,
      state,
      taxIds,
      duns,
      contactNames,
      contactEmails,
      contactPhones,
      contactTitles,
      page: selectedPage,
      myClients,
    } = filters;

    const searchParams: URLSearchParams = new URLSearchParams({
      page: selectedPage.toString() || '1',
      perPage: CLIENTS_PER_PAGE || '20',
    });

    if (!isEmpty(filters)) {
      if (names.length) searchParams.append('names', names.map((e) => e.name).toString());
      if (state) searchParams.append('state', state);
      if (taxIds.length) searchParams.append('taxIds', taxIds.map((e) => e.name).toString());
      if (duns.length) searchParams.append('duns', duns.map((e) => e.name).toString());
      if (contactNames.length)
        searchParams.append('contactNames', contactNames.map((e) => e.name).toString());
      if (contactEmails.length)
        searchParams.append('contactEmails', contactEmails.map((e) => e.name).toString());
      if (contactPhones.length)
        searchParams.append('contactPhones', contactPhones.map((e) => e.name).toString());
      if (contactTitles.length)
        searchParams.append('contactTitles', contactTitles.map((e) => e.name).toString());
      if (myClients) searchParams.append('myClients', '1');
    }

    return getClientsList(searchParams.toString());
  }, [filters]);

  const updateClientList = useCallback(() => {
    if (!Object.values(filters).length) return;
    setFetching(true);
    const startDate = new Date();
    const stop = startDate.getTime() + 300;

    const performListUpdate = ({ pages, rows, entries }: ClientsList) => {
      // TODO handle it properly, probably API side
      for (const client of rows) {
        if (client.applicationUsers === null) client.applicationUsers = [];
      }
      const calculatedTotalRecords = pages * Number(CLIENTS_PER_PAGE);
      setData(rows);
      setDataSize(entries);
      setFetching(false);
      setPageCount(pages);
      setTotalRecords(calculatedTotalRecords);
      if (!isInitialLoad) {
        scrollToBottom();
      }
    };

    const performFetch = async () => {
      const retrievedList = await refetchList();
      const endDate = new Date();
      const timeRemaining = stop - endDate.getTime();

      if (endDate.getTime() > stop) {
        performListUpdate(retrievedList);
      } else {
        setTimeout(() => {
          performListUpdate(retrievedList);
        }, timeRemaining);
      }
    };

    performFetch();
  }, [refetchList]);

  useEffect(() => {
    updateClientList();
  }, [updateClientList]);

  useEffect(() => setFilters((prevState) => (page ? { ...prevState, page } : prevState)), [page]);

  const goToEditPage = (clientId: string) =>
    navigate(`${process.env.PUBLIC_URL}/dashboard/clients/edit/${clientId}`);

  const toggleClientActive = async (
    e: ChangeEvent<HTMLInputElement>,
    row: { id: number; name: string }
  ) => {
    const targetBox = e.target;
    const clientName = row.name;
    const newCheckedState = targetBox.checked;

    targetBox.disabled = true;

    const submittedFields = {
      id: row.id,
      enabled: newCheckedState,
    };

    const result = await updateClient(submittedFields);
    if (result) {
      targetBox.disabled = false;
      targetBox.checked = newCheckedState;
      tossSuccess(
        `The client ${clientName} is now ${newCheckedState ? 'activated' : 'deactivated'}.`
      );
    } else {
      tossError(`Error ${newCheckedState ? 'enabling' : 'disabling'} the client ${clientName}.`);
      targetBox.disabled = false;
    }
  };

  function dateAddedFormatter(cell: string) {
    return reduceTZ(cell, 'Unknown');
  }

  function clientTypeFormatter(cell: number) {
    return clientTypes[cell] ?? '-';
  }

  function clientPopulationFormatter(cell: number) {
    return cell ?? '-';
  }

  function assignedUsersFormatter(cell: { name: string }[]) {
    return (
      <div className="align-middle">
        {cell.length === 0
          ? 'None'
          : cell[0].name + (cell.length > 1 ? ` (+${cell.length - 1})` : '')}
      </div>
    );
  }

  function enabledFormatter(cell: boolean, row: { id: number; name: string }) {
    return (
      <div className="align-middle">
        <CustomInput
          checked={cell}
          className="custom-client-toggle"
          id={`enableClient${row.id}`}
          name={`enableClientSwitch${row.id}`}
          onChange={(e) => toggleClientActive(e, row)}
          type="switch"
        />
      </div>
    );
  }

  function actionsFormatter(cell: ReactElement, row: Client) {
    return (
      <div className="align-middle d-flex">
        <ClientMemoGen client={row} />
        &nbsp;&nbsp;
        <Button
          className="d-flex justify-content-center align-items-center"
          color="primary"
          onClick={() => goToEditPage(row.id.toString())}
        >
          {currentUser.userType === 'millenniumAnalyst' ||
          (currentUser.userType !== 'millenniumAdmin' &&
            currentUser.userType !== 'millenniumManager' &&
            !row.applicationUsers?.some((e: User) => e.id === currentUser.id)) ? (
            <>
              <Eye size={16} style={{ marginBottom: '1px' }} />
              &nbsp;View
            </>
          ) : (
            <>
              <Edit size={16} style={{ marginBottom: '2px' }} />
              &nbsp;Edit
            </>
          )}
        </Button>
      </div>
    );
  }

  const columns = [
    {
      dataField: 'createdAt',
      text: 'Date Added',
      formatter: dateAddedFormatter,
    },
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'clientType',
      text: 'Type',
      formatter: clientTypeFormatter,
    },
    {
      dataField: 'billingType',
      text: 'Billing Type',
    },
    {
      dataField: 'population',
      text: 'Population',
      formatter: clientPopulationFormatter,
    },
    {
      dataField: 'applicationUsers',
      text: 'Assigned To',
      formatter: assignedUsersFormatter,
    },
    {
      dataField: 'enabled',
      text: 'Enabled',
      formatter: enabledFormatter,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      formatter: actionsFormatter,
    },
  ];

  const startItem = (page - 1) * Number(CLIENTS_PER_PAGE) + 1;
  const handleGoToPage = (e) => {
    e.preventDefault();
    const pageNum = parseInt(goToPage, 10);
    if (pageNum >= 1 && pageNum <= pageCount) {
      setPage(pageNum);
      window.scrollTo({ top: 100 });
    }
    setGoToPage('');
  };
  const displayValue = goToPage || (goToPage === '' ? '#' : page.toString());
  // @ts-expect-error no types support planned for react-bootstrap-table2
  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <div>
        <div>
          <BootstrapTable
            ref={tableRef}
            bootstrap4
            columns={columns}
            data={data}
            keyField="id"
            loading={fetching}
            noDataIndication={() => 'No Clients Found.'}
            onTableChange={() => {}}
            overlay={overlayFactory({
              spinner: true,
              background: 'rgba(192,192,192,0.3)',
            })}
            remote={{
              filter: true,
              pagination: true,
              sort: true,
            }}
            striped
            wrapperClasses="table-responsive table-borderless"
            {...paginationTableProps}
          />
        </div>
      </div>
      {pageCount > 1 && (
        <div className="my-3 pagination-wrapper">
          <div className="records-showing-text">
            Showing {startItem} of {totalRecords}
          </div>
          <div className="pagination-controls-wrapper">
            <form className="pagination-form-field" onSubmit={handleGoToPage}>
              <span className="me-2 label-text">Go to page</span>
              <input
                aria-label="Page number"
                className="form-control form-control-sm goToPage-input"
                max={pageCount}
                min="1"
                onBlur={() => {
                  if (goToPage === '') {
                    setGoToPage('#');
                  }
                }}
                onChange={(e) => setGoToPage(e.target.value)}
                onFocus={() => {
                  if (goToPage === '' && page) {
                    setGoToPage(page.toString());
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleGoToPage(e);
                  }
                }}
                type="number"
                value={displayValue || '#'}
              />
            </form>

            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </div>
  );

  const contextValue = useMemo(
    () => ({
      filters,
      setFilters,
    }),
    [filters, setFilters]
  );

  const handleAddNewClient = () => {
    setClientCreationProps({
      onCreation: updateClientList,
    });

    navigate(`${process.env.PUBLIC_URL}/dashboard/clients/create-client`);
  };
  return (
    <>
      <Helmet>
        <title>Clients - Grantrack</title>
      </Helmet>
      <FilterContext.Provider value={contextValue}>
        <Breadcrumbs styles={{ padding: '0px' }} title="Clients" />

        <ClientFilters />
        <Stack className="client-listing-wrapper" direction="column" spacing={2}>
          <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2}>
            <h3 className="client-listing-heading">Clients</h3>
            {currentUser.userType === 'millenniumAdmin' && (
              <div className="my-4">
                <Button
                  className="d-flex justify-content-center align-items-center"
                  color="primary"
                  onClick={handleAddNewClient}
                >
                  <Plus size={16} style={{ marginBottom: '2px' }} />
                  &nbsp;Add New Client
                </Button>
              </div>
            )}
          </Stack>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              hideSizePerPage: true,
              withFirstAndLast: pageCount > 5,
              alwaysShowAllBtns: true,
              page,
              totalSize: dataSize,
              sizePerPage: Number(CLIENTS_PER_PAGE || '20'),
              firstPageText: '«',
              nextPageText: '›',
              prePageText: '‹',
              lastPageText: '»',
              onPageChange: (currentPage) => {
                setIsInitialLoad(false);
                setPage(currentPage);
              },
            })}
          >
            {contentTable}
          </PaginationProvider>
        </Stack>
      </FilterContext.Provider>
    </>
  );
}
