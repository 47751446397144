import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { LinearProgress, Stack, Box } from '@mui/material';
import { Form } from 'react-bootstrap';
import { CustomInput } from 'reactstrap';
import { AwardsList, getAwards } from 'services/awards/awardsService';
import StandardButton from 'shared/muiComponents/StandardButton';
import SummaryFilters from 'components/awards/SummaryView/SummaryFilters';
import logger from 'services/logger';
import useUserSession from 'hooks/useUserSession';
import SummaryAwardsTable from './SummaryAwardsTable';

export default function SummaryView() {
  const { userType } = useUserSession();
  const [data, setData] = useState<AwardsList>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onlyMe, setOnlyMe] = useState(false); // Default to false
  const isMounted = useRef(true);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    isMounted.current = true;
    const searchParams = new URLSearchParams(location.search);
    setOnlyMe(searchParams.get('target') === 'om'); // Set to true only if `target=om` is present

    return () => {
      isMounted.current = false;
    };
  }, [location.search]);

  const refetchAwards = useCallback(async () => {
    setLoading(true);

    const result = await getAwards(location.search);

    if (isMounted.current) {
      if (result) {
        setData(result);
        setError(false);
      } else setError(true);
      setLoading(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (location.search) {
      refetchAwards();
    }
  }, [location.search, refetchAwards]);

  const handleOnlyMeToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setOnlyMe(isChecked);

    const searchParams = new URLSearchParams(location.search);
    if (isChecked) {
      searchParams.set('target', 'om');
    } else {
      searchParams.delete('target');
    }
    navigate(`?${searchParams.toString()}`);
  };

  const canCreateAward =
    userType === 'millenniumAdmin' || userType === 'millenniumManager' || userType === 'userAdmin';

  return (
    <>
      <SummaryFilters />
      <Box ml={2}>
        <Form.Group>
          <CustomInput
            checked={onlyMe}
            id="target-om"
            label="Only Me"
            name="target"
            onChange={handleOnlyMeToggle}
            type="switch"
          />
        </Form.Group>
      </Box>
      <Card className="w-100 ml-3">
        <Card.Header>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            {canCreateAward && (
              <StandardButton
                onClick={() => {
                  logger.info('Clicked Create New Award Button');
                  navigate(`/dashboard/award/new/details`);
                }}
                startIcon
              >
                Create New Award
              </StandardButton>
            )}
          </Stack>
        </Card.Header>

        <Card.Body>
          {loading && <LinearProgress />}
          <SummaryAwardsTable data={data} error={error} loading={loading} />
        </Card.Body>
      </Card>
    </>
  );
}
