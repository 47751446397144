import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { X } from 'react-feather';

interface CustomFieldInputProps {
  controlId: string;
  label: string;
  defaultValue?: string | number | null;
  disabled?: boolean;
  onChange?: (val: string | number) => void;
  onDelete?: () => void;
}

export default function CustomFieldInput({
  controlId,
  label,
  defaultValue,
  disabled = false,
  onChange,
  onDelete,
}: CustomFieldInputProps) {
  const deleteField = () => {
    if (onDelete) onDelete();
  };

  return (
    <Form.Group controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <Form.Control
          defaultValue={defaultValue || ''}
          disabled={disabled}
          onChange={(e) => onChange?.(e.target.value)}
          type="text"
        />
        {!disabled && (
          <InputGroup.Append>
            <Button
              onClick={deleteField}
              style={{ margin: '0 0 0 0', padding: '5px 10px 0 10px' }}
              variant="danger"
            >
              <X size={16} />
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
    </Form.Group>
  );
}
