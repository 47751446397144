import { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Plus, Check, X as CloseIcon } from 'react-feather';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import Dropzone, { IFileWithMeta, IUploadParams, StatusValue } from 'react-dropzone-uploader';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { KeyboardArrowDown, KeyboardArrowUp, ArrowBack } from '@mui/icons-material';
import { TextInput, FunderInput, MoneyInputNew, MultiSelectInput } from 'shared/inputs';
import CustomFields from 'shared/CustomFields';
import useUserSession from 'hooks/useUserSession';
import DateField from 'components/applications/EditForm/DateField';
import { createProgram } from 'services/programService';
import api from 'services/apiService';
import { nanoid } from 'nanoid';
import './programs.css';
import 'assets/scss/programDetailsnew.scss';
import { useProgramContext } from 'hooks/ProgramContext';
import { Box, Stack, IconButton } from '@mui/material';
import {
  applicationsConfigs,
  getCountiesForStates,
  OrgTypes,
  states,
} from '../../constants/globalConstants';

export default function CreateProgramNew() {
  const [form, setForm] = useState({
    showForFlexClient: true,
    orgTypes: [],
    states: [],
    counties: [],
    source: [],
    category: [],
  });
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const tempProgram = parseInt(nanoid(6).replace(/\D/g, ''), 10);
  const setField = (field, value) => setForm((prevState) => ({ ...prevState, [field]: value }));
  const [tempProgramId, setTempProgramId] = useState(tempProgram || 0);
  const isFromPrograms = true;
  const navigate = useNavigate();
  const { createProgramProps } = useProgramContext();
  const { readOnly, disabled, onCreation } = createProgramProps;
  const currentUser = useUserSession();

  const save = async () => {
    setIsLoading(true);
    try {
      const {
        name,
        funder,
        fundingAmount,
        varyingFundingAmount,
        amountVaries,
        source,
        category,
        startsAt,
        endsAt,
        matchRequirements,
        performancePeriod,
        estimatedResponse,
        customFields,
        states: selectedStates,
        counties: selectedCounties,
        orgTypes,
        showForFlexClient,
      } = form;

      const submittedFields = {
        name,
        funder,
        fundingAmount: fundingAmount ?? 0.0,
        varyingFundingAmount,
        amountVaries: amountVaries ?? false,
        source,
        startsAt,
        endsAt,
        matchRequirements,
        performancePeriod,
        estimatedResponse,
        customFields: JSON.stringify(customFields ?? []),
        category: category.map((cat) => applicationsConfigs.categories.indexOf(cat)),
        states: selectedStates,
        counties: selectedCounties,
        orgTypes,
        showForFlexClient: showForFlexClient ?? true,
      };

      const newProgram = await createProgram(submittedFields);
      if (newProgram) {
        if (files.length > 0) {
          await api.patch('/programs/updateFileProgramId', {
            tempProgramId,
            programId: newProgram.id,
          });
        }

        tossSuccess('The program was successfully created.');
        setForm({
          showForFlexClient: true,
          orgTypes: [],
          states: [],
          counties: [],
          source: [],
          category: [],
        });
        setFiles([]);
        if (onCreation) onCreation();
        navigate(-1);
      } else {
        tossError('Error creating the program.');
      }
    } catch (error) {
      tossError('Error creating the program.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDiscard = () => {
    setForm({
      showForFlexClient: true,
      orgTypes: [],
      states: [],
      counties: [],
      source: [],
      category: [],
    });
    setFiles([]);
    setErrors({});
    setTempProgramId(tempProgram);
  };

  const toggleSectionVisibility = () => {
    setIsSectionVisible((prev) => !prev);
  };

  const getUploadParams = (fileWithMeta: IFileWithMeta) => {
    const body = new FormData();
    body.append('programId', tempProgramId.toString());
    body.append('file', fileWithMeta.file);
    body.append('size', fileWithMeta.meta.size.toString());
    body.append('name', fileWithMeta.meta.name);
    body.append('fileUrl', fileWithMeta.meta.previewUrl || '');
    body.append('type', fileWithMeta.meta.type);

    return {
      url: `${process.env.REACT_APP_API_URL}/programs/programfile`,
      body,
      headers: {
        Authorization: api.client?.defaults.headers.common.Authorization,
      },
    } as IUploadParams;
  };

  const handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      fileWithMeta.remove();
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          id: fileWithMeta.meta.id,
          name: fileWithMeta.meta.name,
          size: fileWithMeta.meta.size,
          type: fileWithMeta.meta.type,
        },
      ]);
    } else if (status === 'error_upload_params') {
      tossError('Error uploading file. Please try again.');
    }
  };

  const handleDeleteFile = async (fileId: number) => {
    setFiles([]);
  };

  useEffect(() => {
    const { name, funder, fundingAmount, amountVaries, source, category, startsAt, endsAt } = form;

    const errorList = {};

    if (typeof name !== 'undefined') {
      if (!name) errorList.name = 'This field is required.';
      else if (name.length < 3)
        errorList.name = 'Grant program names must have 3 or more characters.';
    }

    if (!endsAt) errorList.endsAt = 'This field is required.';

    if (typeof startsAt !== 'undefined' && typeof endsAt !== 'undefined') {
      if (startsAt && endsAt) {
        if (dayjs(endsAt).isBefore(dayjs(startsAt))) {
          errorList.startsAt = 'Start Date must be earlier than Due Date.';
          errorList.endsAt = 'Due Date must be later than Start Date.';
        }
      }
    }

    if (funder === null) errorList.funder = 'This field is required.';

    if (Number.isNaN(fundingAmount) && !amountVaries)
      errorList.fundingAmount = 'Enter a valid funding amount.';

    if (
      [
        typeof name,
        typeof funder,
        typeof fundingAmount,
        typeof source,
        typeof category,
        typeof endsAt,
      ].includes('undefined')
    )
      errorList.undefineds = 'One or more required fields are undefined.';

    setErrors(errorList);
  }, [form]);

  return (
    <Container className="create-program-page" fluid>
      <Stack alignItems="space-between" direction="column" mb={4} spacing={1}>
        <Box mb={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <span
              className="breadcrumb-list"
              onClick={() => navigate(-1)}
              onKeyDown={(e) => e.key === 'Enter' && navigate(-1)}
              role="button"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              tabIndex={0}
            >
              Programs
            </span>
            <span>{'>'}</span>
            <span className="breadcrumb-list" style={{ fontWeight: '700' }}>
              New Program Details
            </span>
          </Stack>
        </Box>
        <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <IconButton
              className="back-button"
              onClick={() => navigate(-1)}
              sx={{
                width: '34px',
                height: '34px',
                backgroundColor: '#eff1f6',
                borderRadius: '6px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: '#e0e3eb',
                },
              }}
            >
              <ArrowBack />
            </IconButton>

            <h5 className="heading">Create New Program</h5>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button className="discard-btn" onClick={handleDiscard} variant="light">
              Discard
            </Button>
            <Button
              className="d-flex justify-content-center align-items-center"
              disabled={Object.keys(errors).length > 0 || isLoading}
              onClick={save}
            >
              <Check size={16} />
              &nbsp;Create
            </Button>
          </Stack>
        </Stack>

        <h6 className="sub-heading">Create new Program.</h6>
      </Stack>
      <Form>
        <div className="general-fields-wrapper">
          <Row>
            <Col lg={6} xs={12}>
              <h5 className="heading-gb mb-4">Grant Summary File</h5>
              <div className="file-uploader">
                {files?.length > 0 ? (
                  <div className="file-display-container">
                    <a
                      className="file-display-link"
                      download={files[0].name}
                      href={files[0].fileUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {files[0].name}
                    </a>
                    <CloseIcon
                      className="file-display-close"
                      onClick={() => handleDeleteFile(files[0].id)}
                      size={24}
                    />
                  </div>
                ) : (
                  <div className="dz-message tall needsclick">
                    <Dropzone
                      disabled={readOnly}
                      getUploadParams={getUploadParams}
                      inputContent="Drag Files Here"
                      onChangeStatus={handleChangeStatus}
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <h5 className="heading-gb mb-4">General Fields</h5>
              <FunderInput
                className="common-input"
                controlId="createProgram.Funder"
                errors={errors.funder}
                onChange={(newValue) => setField('funder', newValue)}
                placeholder="Start typing a name..."
              />

              <Row>
                <Col lg={6} xs={12}>
                  <TextInput
                    className="common-input"
                    controlId="createProgram.Name"
                    errors={errors.name}
                    label="Grant Program Name"
                    onChange={(newValue) => setField('name', newValue)}
                    placeholder="My Grant Program"
                    required
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <MultiSelectInput
                    choices={OrgTypes}
                    className="common-input"
                    controlId="createProgram.OrgTypes"
                    defaultValue={[]}
                    disabled={readOnly}
                    isIndexBased={false}
                    label="Org Type"
                    onChange={(selectedIndices) => {
                      const selectedOrgTypes = selectedIndices.map((index) => OrgTypes[index]);
                      setField('orgTypes', selectedOrgTypes);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12}>
                  <DateField
                    className="common-input"
                    controlId="createProgram.StartDate"
                    errors={errors.startsAt}
                    label="Start Date"
                    onChange={(newValue) => setField('startsAt', newValue)}
                    value={form.startsAt}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <DateField
                    className="common-input"
                    controlId="createProgram.DueDate"
                    errors={errors.endsAt}
                    label="Due Date"
                    onChange={(newValue) => setField('endsAt', newValue)}
                    required
                    value={form.endsAt}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12}>
                  <MultiSelectInput
                    choices={applicationsConfigs.alphabetSortedCategories}
                    className="common-input"
                    controlId="createProgram.Category"
                    defaultValue={[]}
                    disabled={readOnly}
                    isIndexBased={false}
                    label="Category"
                    onChange={useCallback((selectedIndices: number[]) => {
                      const selectedCategories = selectedIndices.map(
                        (index) => applicationsConfigs.alphabetSortedCategories[index]
                      );
                      setField('category', selectedCategories);
                    }, [])}
                    required
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <MultiSelectInput
                    choices={applicationsConfigs.sources}
                    className="common-input"
                    controlId="createProgram.Source"
                    defaultValue={[]}
                    disabled={readOnly}
                    label="Source"
                    onChange={useCallback((selectedIndices: number[]) => {
                      setField('source', selectedIndices);
                    }, [])}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12}>
                  <MultiSelectInput
                    choices={states.map((state) => state.name)}
                    className="common-input"
                    controlId="createProgram.States"
                    defaultValue={[]}
                    disabled={readOnly}
                    isIndexBased={false}
                    label="State"
                    onChange={(selectedIndices) => {
                      const selectedStates = selectedIndices.map((index) => states[index].name);
                      setField('states', selectedStates);
                      setField('counties', []);
                    }}
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <MultiSelectInput
                    choices={getCountiesForStates(
                      form.states
                        ?.map((stateName) => states.find((s) => s.name === stateName)?.abbr)
                        .filter(Boolean) || []
                    )}
                    className="common-input"
                    controlId="createProgram.Counties"
                    defaultValue={[]}
                    disabled={readOnly || !form.states?.length}
                    isIndexBased={false}
                    label="County"
                    onChange={(selectedIndices) => {
                      const availableCounties = getCountiesForStates(
                        form.states
                          ?.map((stateName) => states.find((s) => s.name === stateName)?.abbr)
                          .filter(Boolean) || []
                      );
                      const selectedCounties = selectedIndices.map(
                        (index) => availableCounties[index]
                      );
                      setField('counties', selectedCounties);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12}>
                  <MoneyInputNew
                    canVary
                    className="common-input"
                    controlId="createProgram.FundingAmount"
                    defaultAmount={form.fundingAmount}
                    defaultStringAmount={form.varyingFundingAmount}
                    defaultVaries={false}
                    errors={errors.fundingAmount}
                    isFromPrograms={isFromPrograms}
                    label="Funding Amount"
                    onAmountChange={(newAmount) => setField('fundingAmount', newAmount)}
                    onVaryChange={(newVaries) => setField('amountVaries', newVaries)}
                    onVaryingAmountChange={(newAmount) =>
                      setField('varyingFundingAmount', newAmount)
                    }
                    required
                    text="May be either fixed or varying."
                    textClass="currency-text"
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <TextInput
                    className="common-input"
                    controlId="createProgram.MatchRequirements"
                    label="Match Requirements"
                    onChange={(newValue) => setField('matchRequirements', newValue)}
                    placeholder="25% Match"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12}>
                  <TextInput
                    className="common-input"
                    controlId="createProgram.PeriodOfPerformance"
                    label="Period of Performance"
                    onChange={(newValue) => setField('performancePeriod', newValue)}
                    placeholder="Enter Period of Performance"
                  />
                </Col>
                <Col lg={6} xs={12}>
                  <TextInput
                    className="common-input"
                    controlId="createProgram.EstimatedResponse"
                    label="Estimated Response"
                    onChange={(newValue) => setField('estimatedResponse', newValue)}
                    placeholder="Enter Estimated Response"
                  />
                </Col>
              </Row>
              <Row>
                {currentUser.isMillenniumUser && (
                  <Col lg={6} xs={12}>
                    <div className="program-common-input">
                      <div className="d-flex flex-column mb-2">
                        <span className="show-for-clients-text small">Show for flex Clients</span>
                        <Form.Check
                          checked={form.showForFlexClient ?? true}
                          className="show-flexclient-toggle"
                          id="showForFlexClient-toggle"
                          label=""
                          onChange={(e) => setField('showForFlexClient', e.target.checked)}
                          type="switch"
                        />
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
        {!readOnly && (
          <div className="mt-4 custom-fields-wrapper">
            <div
              className="hide-show-wrapper"
              onClick={toggleSectionVisibility}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleSectionVisibility();
                }
              }}
              role="button"
              tabIndex={0}
            >
              <h5 className="heading-gb mb-4">Custom Fields</h5>
              <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
                {isSectionVisible ? (
                  <>
                    <KeyboardArrowUp
                      className="ms-2"
                      style={{ color: '#2C4474', fontSize: '18px' }}
                    />
                    <span className="show-hide-text">Hide Section</span>
                  </>
                ) : (
                  <>
                    <KeyboardArrowDown
                      className="ms-2"
                      style={{ color: '#2C4474', fontSize: '18px' }}
                    />{' '}
                    <span className="show-hide-text">Show Section</span>
                  </>
                )}
              </span>
            </div>
            {isSectionVisible && (
              <>
                <hr />
                <CustomFields onChange={(newFields) => setField('customFields', newFields)} />
              </>
            )}
          </div>
        )}
      </Form>
    </Container>
  );
}
