import { SyntheticEvent } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { format } from 'date-fns';
import dayjs from 'dayjs';

import AwardStatusChip from 'components/awards/AwardStatusChip';
import { Award } from 'types/awards';
import { User } from 'types/user';
import { Client } from 'types/client';

const getSummaryAwardsColumns = (
  isMillenniumUser: boolean,
  setHoveredCell: (event: HTMLElement | null) => void,
  setHoveredColumn: (column: ColumnDescription<Award>) => void
): ColumnDescription<Award>[] => {
  const eventsHandlers = {
    onMouseEnter: (event: SyntheticEvent, column: ColumnDescription<Award>) => {
      setHoveredCell(event.target instanceof HTMLElement ? event.target : null);
      setHoveredColumn(column);
    },
  };

  const columns: ColumnDescription<Award>[] = [
    {
      dataField: 'stringId',
      text: 'Award ID',
    },
    {
      dataField: 'assignee',
      text: 'Assigned To',
      formatter: (user: User) => user?.name || '-',
    },
    {
      dataField: 'client',
      text: 'Client',
      formatter: (client: Client) => client?.name || '-',
      hidden: !isMillenniumUser,
    },
    {
      dataField: 'department',
      text: 'Department',
      hidden: isMillenniumUser,
    },
    {
      dataField: 'funder',
      text: 'Funder',
    },
    {
      dataField: 'grantProgramName',
      text: 'Grant Program',
    },
    {
      dataField: 'startsOn',
      text: 'Start Date',
      formatter: (date: string | null) =>
        date ? format(dayjs(date).utc().toDate(), 'eeee, MMMM do yyyy') : '-',
    },
    {
      dataField: 'endsOn',
      text: 'End Date',
      formatter: (date: string | null) =>
        date ? format(dayjs(date).utc().add(1, 'day').toDate(), 'eeee, MMMM do yyyy') : '-',
    },
    {
      dataField: 'nextReportDueDate',
      text: 'Next Report Due',
      formatter: (date: string | null) =>
        date ? format(dayjs(date).utc().toDate(), 'eeee, MMMM do yyyy') : '-',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (status: string) => <AwardStatusChip status={status} />,
      headerStyle: { width: '10%' },
    },
  ];

  return columns.map((column) => ({
    ...column,
    sort: column.dataField !== 'status',
    events: eventsHandlers,
  }));
};

export default getSummaryAwardsColumns;
