import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import AuthWrapper from 'components/auth/authWrapper';
import SignInForm from 'components/auth/signInForm';
import authImage from 'assets/images/authwrapperImg.png';

export default function SignIn() {
  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Sign In - Grantrack</title>
      </Helmet>
      <AuthWrapper imageSrc={authImage}>
        <SignInForm />
      </AuthWrapper>
    </>
  );
}
