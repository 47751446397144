/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import BootstrapTable, { PaginationOptions, BootstrapTableProps } from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Stack, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';

import useSearchParams from 'hooks/useSearchParams';

import { ReportsList, deleteReport } from 'services/awards/awardReportsService';
import { AwardReport } from 'types/awards';
import StandardButton from 'shared/muiComponents/StandardButton';
import TooltipWithAnchor from 'shared/muiComponents/TooltipWithAnchor';
import useUserSession from 'hooks/useUserSession';
import useCurrentAward from 'hooks/useCurrentAward';

import { tossError, tossSuccess } from 'utils/toastTosser';
import NoReportsEmptyScreen from './NoReportsEmptyScreen';
import getReportsColumns from './getReportsColumns';
import NewReportModal from './NewReportModal';
import EditReportModal from './EditReportModal';

interface ReportsTableProps {
  reportId?: string | null;
  data?: ReportsList;
  loading: boolean;
  error: boolean;
  refetchReports: () => void;
}

const defaultFiltersSet = { sortBy: 'id', sortOrder: 'desc', page: '1', perPage: '20' };

const getReportStatus = (row: AwardReport): { color: string; tooltip: string } => {
  if (row.files.length > 0)
    return { color: 'green', tooltip: 'This report has been successfully filed' };

  if (dayjs().isBetween(dayjs(row.dueDate), dayjs(row.dueDate).subtract(7, 'days')))
    return { color: 'yellow', tooltip: 'This report is scheduled to be submitted soon' };

  if (dayjs().isAfter(dayjs(row.dueDate)))
    return { color: 'red', tooltip: 'This report is overdue' };

  return { color: '', tooltip: 'Click to edit' };
};

export default function ReportsTable({
  reportId,
  data,
  loading,
  error,
  refetchReports,
}: ReportsTableProps) {
  const { currentAwardId } = useCurrentAward();
  const { searchParams, setSearchParams } = useSearchParams();
  const { userType } = useUserSession();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [duplicatedReport, setDuplicatedReport] = useState<AwardReport | null>(null);
  const [reportOpened, setReportOpened] = useState<AwardReport | null>(null);

  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const [hoveredRow, setHoveredRow] = useState<AwardReport | null>(null);
  const [hoveredCell, setHoveredCell] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (reportId && !reportOpened) {
      const matchingRecord = data?.rows.find((report) => report.stringId.match(reportId) !== null);

      setReportOpened(matchingRecord || null);
    }
  }, [reportId, data]);

  const isFiltered = !isEqual(searchParams, defaultFiltersSet);

  const toggleCreate = () => {
    setDuplicatedReport(null);
    setIsCreateOpen((prevState) => !prevState);
  };

  const handleSortChange = (sortField: string, sortOrder: string) => {
    setSearchParams({ page: 1, sortBy: sortField, sortOrder });
  };

  const remove = async (paymentId: number) => {
    if (!confirm('Are you sure you want to delete this report?')) return;

    const result = await deleteReport(currentAwardId, paymentId);

    if (result) {
      tossSuccess('The report was successfully deleted.');
      refetchReports();
    } else tossError('Error deleting the report.');
  };

  const duplicate = async (report: AwardReport) => {
    setDuplicatedReport(report);
    setIsCreateOpen(true);
  };

  const canEditReports = userType !== 'millenniumAnalyst' && userType !== 'millenniumResearcher';

  const contentTable = ({
    paginationProps,
    paginationTableProps,
  }: {
    paginationProps: PaginationOptions;
    paginationTableProps: BootstrapTableProps;
  }) => (
    <>
      <div
        onBlur={(_event: SyntheticEvent) => setHoveredButton(null)}
        onFocus={(_event: SyntheticEvent) => {}}
        onMouseLeave={() => setHoveredButton(null)}
        onMouseOut={() => setHoveredButton(null)}
        onMouseOver={(_event: SyntheticEvent) => {
          const target = _event.target as HTMLElement;
          const button = target.closest('button');

          console.log('button', _event, target, button);

          if (button) {
            setHoveredButton(button.getAttribute('aria-label') || '');
            setHoveredRow(null);
          }
        }}
      >
        <BootstrapTable<AwardReport>
          {...paginationTableProps}
          bodyClasses={loading ? 'loading' : ''}
          bootstrap4
          bordered={false}
          columns={getReportsColumns(
            setHoveredCell,
            setReportOpened,
            refetchReports,
            !canEditReports,
            remove,
            duplicate
          )}
          data={data?.rows || []}
          keyField="id"
          noDataIndication="No Reports found"
          onTableChange={(type, newState) => {
            if (type === 'sort') {
              handleSortChange(newState.sortField, newState.sortOrder);
            }
          }}
          remote={{
            sort: true,
            pagination: true,
          }}
          rowClasses={(row: AwardReport) => getReportStatus(row).color}
          rowEvents={{
            onClick: (event: SyntheticEvent, row: AwardReport) => {
              // Check if the click originated from an IconButton
              if ((event.target as HTMLElement).closest('.MuiBox-root')) {
                return;
              }
              if (canEditReports) {
                setReportOpened(row);
              }
            },
            onMouseEnter: (_event: SyntheticEvent, row: AwardReport) => setHoveredRow(row),
            onMouseLeave: () => setHoveredRow(null),
          }}
          sort={{ dataField: searchParams.sortBy, order: searchParams.sortOrder as 'asc' | 'desc' }}
          wrapperClasses="table-responsive table-borderless hover rows-centered"
        />
      </div>

      <div className="my-3 text-center">
        <PaginationListStandalone {...paginationProps} />
      </div>

      <TooltipWithAnchor
        anchorElement={hoveredCell}
        isOpen={Boolean(hoveredRow) || Boolean(hoveredButton)}
        text={hoveredButton || (hoveredRow ? getReportStatus(hoveredRow).tooltip : '')}
      />
    </>
  );

  if (!isFiltered && !loading && data?.rows?.length === 0)
    return (
      <>
        <NoReportsEmptyScreen canEdit={canEditReports} onCreate={toggleCreate} />
        {isCreateOpen && <NewReportModal refetchReports={refetchReports} toggle={toggleCreate} />}
      </>
    );

  if (!data?.rows) return <div />;

  if (error) return <p>Error fetching Reports.</p>;

  return (
    <>
      <Card className="w-100">
        <Card.Header>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography variant="h2">Reports</Typography>

            {canEditReports && (
              <StandardButton onClick={toggleCreate} startIcon>
                Add New Report
              </StandardButton>
            )}
          </Stack>
        </Card.Header>

        <Card.Body>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              hideSizePerPage: true,
              alwaysShowAllBtns: true,
              totalSize: data?.count,
              sizePerPage: 20,
              firstPageText: '«',
              nextPageText: '›',
              prePageText: '‹',
              lastPageText: '»',
              page: Number(searchParams.page),
              onPageChange: (currentPage) => {
                setSearchParams({ page: currentPage, perPage: 20 });
                window.scrollTo({ top: 250 });
              },
            })}
          >
            {contentTable}
          </PaginationProvider>
        </Card.Body>
      </Card>

      {isCreateOpen && (
        <NewReportModal
          initialData={duplicatedReport}
          refetchReports={() => {
            setDuplicatedReport(null);
            refetchReports();
          }}
          toggle={toggleCreate}
        />
      )}

      {reportOpened && (
        <EditReportModal
          refetchReports={refetchReports}
          report={reportOpened}
          toggle={() => setReportOpened(null)}
        />
      )}
    </>
  );
}
