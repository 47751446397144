import { Tooltip, Grow } from '@mui/material';
import { useEffect, useState } from 'react';

interface TooltipWithAnchorProps {
  text: string;
  isOpen: boolean;
  anchorElement: HTMLElement | null;
}

export default function TooltipWithAnchor({ text, isOpen, anchorElement }: TooltipWithAnchorProps) {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    setShown(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setShown(false);

    setTimeout(() => {
      setShown(true);
    }, 600);
  }, [anchorElement]);

  return shown ? (
    <Tooltip
      open={isOpen}
      PopperProps={{
        anchorEl: anchorElement,
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-20, -5],
              },
            },
          ],
        },
        tooltip: {
          sx: {
            backgroundColor: (theme) => theme.palette.primary.main,
            fontSize: '0.7rem',
            fontWeight: '600',
          },
        },
      }}
      title={text}
      TransitionComponent={Grow}
    >
      <div />
    </Tooltip>
  ) : null;
}
