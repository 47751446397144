import { ChevronsLeft, ChevronsRight } from 'react-feather';
import { Input } from 'reactstrap';
import { getMonth, getYear } from 'date-fns';

import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { years, months } from '../constants/globalConstants';

export default function DateHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '0.4rem',
        marginBottom: '0',
      }}
    >
      <button
        aria-label="Previous Month"
        className="border-0 bg-transparent mx-1"
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
        style={{ marginTop: '7px' }}
        type="button"
      >
        <ChevronsLeft />
      </button>

      <Input
        name="yrSelect"
        onChange={({ target: { value } }) => changeYear(Number(value))}
        style={{ marginRight: '3px' }}
        type="select"
        value={getYear(date)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Input>

      <Input
        name="mnSelect"
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        style={{ marginLeft: '3px' }}
        type="select"
        value={months[getMonth(date)]}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Input>

      <button
        aria-label="Next Month"
        className="border-0 bg-transparent mx-1"
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
        style={{ marginTop: '7px' }}
        type="button"
      >
        <ChevronsRight />
      </button>
    </div>
  );
}
