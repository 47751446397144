import { InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';

import MatchModal from './MatchModal';
import { BudgetFormEntry } from '.';

export default function MatchField({
  value,
  onSave,
  data,
  awardMatchAmount,
  totalsMatchAmount,
  disabled = false,
}: {
  value: string;
  data: BudgetFormEntry;
  awardMatchAmount: number;
  totalsMatchAmount: number;
  onSave: (newValues: BudgetFormEntry) => void;
  disabled?: boolean;
}) {
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const toggleOpen = () => setIsNotesOpen((prevState) => !prevState);

  return (
    <>
      <TextField
        disabled={disabled}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: <InputAdornment position="end">$</InputAdornment>,
        }}
        onClick={toggleOpen}
        size="small"
        sx={{
          '& .MuiInputBase-input': {
            textAlign: 'right',
            cursor: disabled ? 'default' : 'pointer',
            letterSpacing: '0.8px',
          },
          '&.Mui-disabled': {
            '& input': {
              WebkitTextFillColor: 'black',
            },
            '& fieldset': {
              borderColor: 'transparent',
            },
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-disabled': {
              '& fieldset': {
                border: 'transparent',
              },
            },
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: (theme) => theme.palette.primary.light,
            },
            '&.Mui-focused fieldset': {
              borderColor: (theme) => theme.palette.primary.main,
            },
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'none',
            },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        }}
        value={value}
      />
      {isNotesOpen && (
        <MatchModal
          awardMatchAmount={awardMatchAmount}
          data={data}
          onSave={onSave}
          toggle={toggleOpen}
          totalsMatchAmount={totalsMatchAmount}
        />
      )}
    </>
  );
}
