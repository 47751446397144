import { ReactNode } from 'react';
import { Modal, Paper } from '@mui/material';

export default function MountedModal({
  onClose,
  children,
  maxWidth = '1200px',
}: {
  onClose: () => void;
  children: ReactNode;
  maxWidth?: string;
}) {
  return (
    <Modal onClose={onClose} open>
      <Paper
        elevation={2}
        sx={{
          m: 2,
          p: 3,
          borderRadius: 4,
          maxWidth,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {children}
      </Paper>
    </Modal>
  );
}
