import { FilterListRounded, SwapVert } from '@mui/icons-material';
import { Box, Checkbox, Menu, MenuItem, Stack } from '@mui/material';
import { SyntheticEvent, useState } from 'react';

import IconButton from 'shared/muiComponents/IconButton';

import SearchField from 'shared/muiComponents/SearchField';
import useCurrentAward from 'hooks/useCurrentAward';

import { DocumentCategory, DocumentsFilters } from 'hooks/AwardContext';

export default function DocumentFilters() {
  const { documentsFilters, updateDocumentsFilters } = useCurrentAward();

  const [sortAnchorEl, setSortAnchorEl] = useState<HTMLElement | null>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(null);

  const handleSortMenuItemClick = (value: Partial<DocumentsFilters>) => {
    updateDocumentsFilters(value);
    setSortAnchorEl(null);
  };

  const handleFilterMenuItemClick = (value: string) => {
    let includedCategories: DocumentCategory[] = ['initial'];

    switch (value) {
      case 'all':
        if (documentsFilters.includedCategories.length === 4) includedCategories = [];
        else includedCategories = ['initial', 'contract', 'report', 'related'];
        break;
      case 'initial':
        if (documentsFilters.includedCategories.includes('initial'))
          includedCategories = documentsFilters.includedCategories.filter(
            (category) => category !== 'initial'
          );
        else includedCategories = [...documentsFilters.includedCategories, 'initial'];
        break;
      case 'contract':
        if (documentsFilters.includedCategories.includes('contract'))
          includedCategories = documentsFilters.includedCategories.filter(
            (category) => category !== 'contract'
          );
        else includedCategories = [...documentsFilters.includedCategories, 'contract'];
        break;
      case 'report':
        if (documentsFilters.includedCategories.includes('report'))
          includedCategories = documentsFilters.includedCategories.filter(
            (category) => category !== 'report'
          );
        else includedCategories = [...documentsFilters.includedCategories, 'report'];
        break;
      case 'related':
        if (documentsFilters.includedCategories.includes('related'))
          includedCategories = documentsFilters.includedCategories.filter(
            (category) => category !== 'related'
          );
        else includedCategories = [...documentsFilters.includedCategories, 'related'];
        break;
      default:
        includedCategories = ['initial'];
        break;
    }

    updateDocumentsFilters({ includedCategories });
  };

  return (
    <Stack direction="row" justifyContent="space-between" mb={4}>
      <Box width="100%">
        <SearchField
          onChange={(value) => updateDocumentsFilters({ search: value.toLowerCase() })}
          placeholder="Search for Documents..."
          value={documentsFilters.search}
        />
      </Box>
      <Box height="38px" ml={1} width="38px">
        <IconButton
          onClick={(event: SyntheticEvent) => {
            setSortAnchorEl(
              event.currentTarget instanceof HTMLElement ? event.currentTarget : null
            );
          }}
          tooltipText="Sort"
        >
          <SwapVert htmlColor="black" />
        </IconButton>
        <Menu
          anchorEl={sortAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id="document-sort-download-menu"
          onClose={() => setSortAnchorEl(null)}
          open={Boolean(sortAnchorEl)}
          sx={{
            '& .MuiMenu-paper': {
              mt: 1,
              borderRadius: '5px',
              border: (theme) => `2px solid ${theme.palette.secondary.light}`,
              '.MuiMenu-list': {
                p: 2,
                '.MuiMenuItem-root': {
                  fontSize: '1rem',
                },
              },
            },
          }}
        >
          <MenuItem
            divider
            onClick={() => handleSortMenuItemClick({ sortBy: 'default' })}
            selected={documentsFilters.sortBy === 'default'}
          >
            Default
          </MenuItem>
          <MenuItem
            onClick={() => handleSortMenuItemClick({ sortBy: 'name', sortOrder: 'asc' })}
            selected={documentsFilters.sortBy === 'name' && documentsFilters.sortOrder === 'asc'}
          >
            Name (Ascending)
          </MenuItem>
          <MenuItem
            onClick={() => handleSortMenuItemClick({ sortBy: 'name', sortOrder: 'desc' })}
            selected={documentsFilters.sortBy === 'name' && documentsFilters.sortOrder === 'desc'}
          >
            Name (Descending)
          </MenuItem>
          <MenuItem
            onClick={() => handleSortMenuItemClick({ sortBy: 'dateAdded', sortOrder: 'asc' })}
            selected={
              documentsFilters.sortBy === 'dateAdded' && documentsFilters.sortOrder === 'asc'
            }
          >
            Date Added (Ascending)
          </MenuItem>
          <MenuItem
            onClick={() => handleSortMenuItemClick({ sortBy: 'dateAdded', sortOrder: 'desc' })}
            selected={
              documentsFilters.sortBy === 'dateAdded' && documentsFilters.sortOrder === 'desc'
            }
          >
            Date Added (Descending)
          </MenuItem>
          <MenuItem
            onClick={() => handleSortMenuItemClick({ sortBy: 'fileType', sortOrder: 'asc' })}
            selected={
              documentsFilters.sortBy === 'fileType' && documentsFilters.sortOrder === 'asc'
            }
          >
            File Type (Ascending)
          </MenuItem>
          <MenuItem
            onClick={() => handleSortMenuItemClick({ sortBy: 'fileType', sortOrder: 'desc' })}
            selected={
              documentsFilters.sortBy === 'fileType' && documentsFilters.sortOrder === 'desc'
            }
          >
            File Type (Descending)
          </MenuItem>
        </Menu>
      </Box>

      <Box height="38px" ml={1} width="38px">
        <IconButton
          onClick={(event: SyntheticEvent) => {
            setFilterAnchorEl(
              event.currentTarget instanceof HTMLElement ? event.currentTarget : null
            );
          }}
          tooltipText="Filter"
        >
          <FilterListRounded htmlColor="black" />
        </IconButton>

        <Menu
          anchorEl={filterAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id="document-filter-dropdown-menu"
          onClose={() => setFilterAnchorEl(null)}
          open={Boolean(filterAnchorEl)}
          sx={{
            '& .MuiMenu-paper': {
              mt: 1,
              borderRadius: '5px',
              border: (theme) => `2px solid ${theme.palette.secondary.light}`,
              '.MuiMenu-list': {
                p: 2,
                '.MuiMenuItem-root': {
                  fontSize: '1rem',
                },
              },
            },
          }}
        >
          <MenuItem
            divider
            onClick={() => handleFilterMenuItemClick('all')}
            selected={documentsFilters.includedCategories.length === 4}
          >
            <Checkbox
              checked={documentsFilters.includedCategories.length === 4}
              sx={{ p: 0, marginRight: 1 }}
            />
            Show All
          </MenuItem>
          <MenuItem
            onClick={() => handleFilterMenuItemClick('initial')}
            selected={documentsFilters.includedCategories.includes('initial')}
          >
            <Checkbox
              checked={documentsFilters.includedCategories.includes('initial')}
              sx={{ p: 0, marginRight: 1 }}
            />
            Initial Documents
          </MenuItem>
          <MenuItem
            onClick={() => handleFilterMenuItemClick('contract')}
            selected={documentsFilters.includedCategories.includes('contract')}
          >
            <Checkbox
              checked={documentsFilters.includedCategories.includes('contract')}
              sx={{ p: 0, marginRight: 1 }}
            />
            Contract Materials
          </MenuItem>
          <MenuItem
            onClick={() => handleFilterMenuItemClick('report')}
            selected={documentsFilters.includedCategories.includes('report')}
          >
            <Checkbox
              checked={documentsFilters.includedCategories.includes('report')}
              sx={{ p: 0, marginRight: 1 }}
            />
            Reports
          </MenuItem>
          <MenuItem
            onClick={() => handleFilterMenuItemClick('related')}
            selected={documentsFilters.includedCategories.includes('related')}
          >
            <Checkbox
              checked={documentsFilters.includedCategories.includes('related')}
              sx={{ p: 0, marginRight: 1 }}
            />
            Related Documents
          </MenuItem>
        </Menu>
      </Box>
    </Stack>
  );
}
