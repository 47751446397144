import { Modal } from 'react-bootstrap';
import { Grid, InputLabel, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import { tossError, tossSuccess } from 'utils/toastTosser';
import useCurrentAward from 'hooks/useCurrentAward';

import { getAwardReports } from 'services/awards/awardReportsService';
import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';
import DateField from 'components/applications/EditForm/DateField';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import { searchUsers } from 'services/userService';
import OutlinedInputField from 'shared/muiComponents/OutlinedInputField';
import { AwardPayments, AwardReport } from 'types/awards';
import FilterSelect from 'shared/muiComponents/FilterSelect';
import useUserSession from 'hooks/useUserSession';
import { createPayment } from 'services/awards/awardPaymentsService';
import StatusButton from './StatusButton';
import ReportSelect from './ReportSelect';

interface NewReportModalProps {
  toggle: () => void;
  refetchPayments: () => void;
}
export interface CustomUserData {
  userId?: number;
  name?: string;
}

const paymentTypeValues = {
  advance: 'Advance Payment',
  reimbursement: 'Reimbursement',
};

type TypesOption = keyof typeof paymentTypeValues;

export default function NewPaymentModal(props: NewReportModalProps) {
  const { toggle, refetchPayments } = props;
  const currentUser = useUserSession();
  const { currentAwardId } = useCurrentAward();

  const [defaultUser, setDefaultUser] = useState<Partial<CustomUserData>>({});
  const [reports, setReports] = useState<AwardReport[]>([]);

  const refetchReports = useCallback(async () => {
    if (currentAwardId) {
      const result = await getAwardReports(currentAwardId, '?page=1&perPage=100');
      const filteredReports = result?.rows?.filter(
        (item) => item.type !== 'programmatic' && !item.payments.length
      );
      if (filteredReports.length) setReports(filteredReports);
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      setDefaultUser({ userId: currentUser.id, name: currentUser.name });
    }
  }, [currentUser]);

  useEffect(() => {
    refetchReports();
  }, []);

  const formik = useFormik<Partial<AwardPayments>>({
    initialValues: {
      requestedDate: dayjs().format('YYYY-MM-DD'),
      paidAt: dayjs().format('YYYY-MM-DD'),
      type: 'reimbursement',
    },

    validationSchema: Yup.object({
      requestedDate: Yup.date().required('Requested Date is required'),
      type: Yup.string().required('Please enter Payment type'),
      amount: Yup.number().optional().positive('Please enter award amount'), // .required('Please enter award amount'),
    }),

    onSubmit: async (values) => {
      const result = await createPayment(currentAwardId, {
        ...values,
        userId: defaultUser.userId,
        amount: Number(values.amount),
      });

      if (result) {
        refetchPayments();
        tossSuccess('Payment successfully added for Award');
        toggle();
      } else tossError('Error adding new payment');
    },
  });

  const { values, errors, touched, handleBlur, setFieldValue, handleSubmit } = formik;

  const paymentStatus: string = useMemo(() => {
    if (values.status === 'reimbursed') return 'Reimbursed';
    if (values.status === 'paid') return 'Paid';
    if (values.type === 'reimbursement' && !values.status) return 'Mark as Reimbursed';
    if (values.type === 'advance' && !values.status) return 'Mark as Paid';
    return '';
  }, [values.type, values.status]);

  const handleChangeStatus = () => {
    if (values.status) return;
    if (!values.status && values.type === 'reimbursement') setFieldValue('status', 'reimbursed');
    if (!values.status && values.type === 'advance') setFieldValue('status', 'paid');
  };

  return (
    <Modal centered className="award-payment-modal" onHide={toggle} show>
      <Modal.Body>
        <Typography m={2} ml={0} variant="h2">
          Add New Payment
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <DateField
              controlId="requestedDate"
              errors={touched.requestedDate ? errors.requestedDate : ''}
              inputClass="mui-datepicker"
              label="Day requested"
              labelClass="mui-datepicker-label"
              onChange={(newValue) => setFieldValue('requestedDate', newValue)}
              value={values.requestedDate}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="assignee" required>
                Requested by
              </InputLabel>
              <SearchFilter<CustomUserData, false>
                getLabel={(user) => user.name || ''}
                label=""
                multiple={false}
                onChange={(user) => {
                  if (user) setDefaultUser(user);
                  else setDefaultUser({} as CustomUserData);
                }}
                searchFunction={async (input: string) =>
                  (await searchUsers(input, 'name')).map((item) => ({
                    userId: item.id,
                    name: item.name,
                  }))
                }
                selected={defaultUser.name ? defaultUser : null}
              />
            </Stack>
          </Grid>
          <Grid item md={6} xs={12}>
            <DateField
              controlId="paidAt"
              errors={touched.paidAt ? errors.paidAt : ''}
              inputClass="mui-datepicker"
              label="Payment Date"
              labelClass="mui-datepicker-label"
              onChange={(newValue) => setFieldValue('paidAt', newValue)}
              value={values.paidAt}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputLabel htmlFor="assignee" required>
              Payment Type
            </InputLabel>
            <FilterSelect
              getValueLabel={(value) => (value ? paymentTypeValues[value as TypesOption] : '')}
              label=""
              onChange={(newValue) => {
                setFieldValue('type', newValue);
                setFieldValue('status', null);
              }}
              options={[undefined, ...Object.keys(paymentTypeValues)]}
              selected={values.type}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <OutlinedInputField
              errorText={touched.amount ? errors.amount : ''}
              id="amount"
              label="Amount"
              onBlur={handleBlur}
              onChange={(value) => setFieldValue('amount', value)}
              required
              value={values.amount}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="assignee">Link Payment to Report</InputLabel>

              <ReportSelect
                label=""
                onChange={(newValue) => setFieldValue('reportId', Number(newValue))}
                options={reports}
                selected={values.reportId ? String(values.reportId) : ''}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack alignItems="center" direction="row" justifyContent="space-between" mb={1} mt={3}>
          <StatusButton active={!!values.status} onClick={handleChangeStatus} startIcon>
            {paymentStatus}
          </StatusButton>
          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
            <SecondaryButton onClick={toggle}>Cancel</SecondaryButton>
            <StandardButton onClick={handleSubmit}>Add New Payment</StandardButton>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
