import { AxiosResponse } from 'axios';

import api, { outputError } from './apiService';

// eslint-disable-next-line import/prefer-default-export
export const searchFunders = (searchParams: string): Promise<{ funder: string }[]> =>
  api
    .get(`/funders/search?${searchParams}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });
