import useSearchParams from 'hooks/useSearchParams';
import { Box, Stack, Typography } from '@mui/material';

import { capitalize } from 'lodash';
import { toArray } from 'utils/utilFunctions';

const allowedFilters: string[] = [
  'funders',
  'programs',
  'assignees',
  'clients',
  'department',
  'status',
] as const;

const statusFilterValues = {
  initialAgreementRequired: 'Awarded',
  awardLetterRequired: 'Award Letter',
  resolutionRequired: 'Resolution',
  applicationRequired: 'Application',
  appropriated: 'Appropriated',
  encumbered: 'Encumbered',
  approval: 'Approval Required',
  active: 'Active',
  reportsRequired: 'Report Due',
  complianceRequired: 'Compliance',
  closeout: 'Close Out',
  closed: 'Completed',
};

type StatusFilterOption = keyof typeof statusFilterValues;

export default function UsedFiltersDescriptionBlock(): JSX.Element {
  const { searchParams } = useSearchParams();

  const usedFilters: { key: (typeof allowedFilters)[number]; values: string }[] = [];

  allowedFilters.forEach((key) => {
    if (toArray(searchParams[key]).length > 0)
      usedFilters.push({ key, values: toArray(searchParams[key]).join(', ') });
  });

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap">
      {usedFilters.map((filter) => (
        <Stack key={filter.key} alignItems="center" direction="row">
          <Typography m={2} variant="body1">{`${capitalize(filter.key)}:`}</Typography>
          <Typography sx={{ color: 'grey' }} variant="body1">
            {filter.key === 'status' && statusFilterValues[filter.values as StatusFilterOption]}
            {filter.key !== 'status' && filter.values}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
}
