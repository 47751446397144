import { useContext, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { CustomInput } from 'reactstrap';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';

import { FilterContext } from 'pages/Applications';
import useUserSession from 'hooks/useUserSession';
import filtersConfigs, {
  getDefaultApplicationsSortByFilter,
  getDefaultApplicationsStatusFilter,
} from 'constants/filtersConfigs';
import useLocalStorage from 'hooks/useLocalStorage';
import { tossSuccess } from 'utils/toastTosser';
import {
  DateType,
  StatusFilter,
  ProgramNames,
  FunderFilter,
  FilterSet,
  FilterActions,
  DateRange,
  ClientFilter,
  UserFilter,
} from 'shared/filters';

import { downloadApplicationsCSV, downloadApplicationsXLSX } from 'services/applicationService';
import { Application } from 'types/application';
import { Box, Stack } from '@mui/material';
import { applicationDates } from '../../constants/globalConstants';
import UsedApplicationFiltersDescriptionBlock from './UsedApplicationFiltersBlock';

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

interface ApplicationFiltersProps {
  onManualChange: () => void;
  switchTab: () => void;
  isMobile?: boolean;
  selected?: Application[];
  startMultipleEdit?: () => void;
  cancelMultipleEdit?: () => void;
  onOnlyMeToggle?: (isChecked: boolean) => void;
}

export default function ApplicationFilters(props: ApplicationFiltersProps) {
  const {
    onManualChange,
    switchTab,
    isMobile,
    selected,
    startMultipleEdit,
    cancelMultipleEdit,
    onOnlyMeToggle,
  } = props;

  const { applicationFilters: filters, setApplicationFilters: setFilters } =
    useContext(FilterContext);
  const [storedFilters, setStoredFilters] = useLocalStorage('filters', 'v1.0');
  const [onlyMe, setOnlyMe] = useState(false);

  function checkFilterValues(filterObj: unknown) {
    if (typeof filterObj !== 'object') return false;

    return !!Object.values(filterObj).filter((prop) => !!prop && prop?.length).length;
  }

  const currentUser = useUserSession();

  const location = useLocation();

  const pageName = location?.pathname?.split('/').at(-1);

  const handleOnlyMeToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setOnlyMe(isChecked);
    onOnlyMeToggle?.(isChecked); // Notify parent component (if needed)
    setFilters((prevFilters) => ({ ...prevFilters, onlyMe: isChecked })); // Update filters
  };
  const buildFilterList = () => {
    const filter = checkFilterValues(filters)
      ? filters
      : storedFilters?.[pageName]?.applicationFilters;
    const queryFilters = {};

    const {
      programNames: filterProgramNames,
      users: filterUsers,
      clients: filterClients,
      funders: filterFunders,
      status: filterStatus,
      startDate: filterStartDate,
      endDate: filterEndDate,
      sortBy: filterSortBy,
      sortOrder: filterSortOrder,
      endDateType: filterEndDateType,
    } = filter || {};

    if (filterProgramNames && filterProgramNames.length > 0)
      queryFilters.names = filterProgramNames
        .map(({ name }) => name.replace(/,/gm, ',,'))
        .join(',');
    if (filterClients && filterClients.length > 0)
      queryFilters.clients = filterClients.map(({ id }) => id);
    if (filterUsers && filterUsers.length > 0)
      queryFilters.assignees = filterUsers.map(({ id }) => id);
    if (filterFunders && filterFunders.length > 0)
      queryFilters.funders = filterFunders.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    if (filterStatus) queryFilters.status = filterStatus;
    if (filterStartDate) queryFilters.dateFrom = filterStartDate;
    if (filterEndDate) queryFilters.dateTo = filterEndDate;

    if (filterSortBy) queryFilters.sortBy = filterSortBy;
    if (filterSortOrder) queryFilters.sortOrder = filterSortOrder;

    queryFilters.endDateType = filterEndDateType
      ? applicationDates[applicationDates.findIndex(({ name }) => name === filterEndDateType)]
          .serverValue
      : 'endsAt';

    const url = new URL(`${process.env.REACT_APP_API_URL}/applications`);
    url.search = new URLSearchParams(queryFilters).toString();

    return url.search;
  };

  const resetFilters = () => {
    const newFilter = storedFilters;
    if (newFilter) delete newFilter[pageName]?.applicationFilters;
    setStoredFilters(newFilter);
    setOnlyMe(false);
    setFilters(
      location?.pathname?.includes('user-dashboard')
        ? {
            startDate: filtersConfigs.dashboard.applications.default.startDate,
            endDate: filtersConfigs.dashboard.applications.default.endDate,
            status: filtersConfigs.dashboard.applications.default.status,
            sortBy: filtersConfigs.dashboard.applications.default.sortBy,
            sortOrder: getDefaultApplicationsSortByFilter(currentUser.isMillenniumUser),
            users: [{ id: String(currentUser.id), name: currentUser.name }],
            onlyMe: false,
          }
        : {
            programNames: [],
            users: [],
            clients: [],
            funders: [],
            status: getDefaultApplicationsStatusFilter(currentUser.isMillenniumUser),
            startDate: filtersConfigs.grants.applications.default.startDate,
            endDate: filtersConfigs.grants.applications.default.endDate,
            endDateType: filtersConfigs.grants.applications.default.dateType,
            sortBy: filtersConfigs.grants.applications.default.sortBy,
            sortOrder: getDefaultApplicationsSortByFilter(currentUser.isMillenniumUser),
            onlyMe: false,
          }
    );
  };

  const onFiltersCopy = () => {
    tossSuccess('Link copied to clipboard!');
  };

  const exportCSV = async () => {
    const queryString = buildFilterList();

    try {
      const data = await downloadApplicationsCSV(queryString);

      saveAs(new Blob([data]), 'applications.zip');
    } catch (err) {
      console.error(err);
    }
  };

  const exportXLSX = async () => {
    const queryString = buildFilterList();

    try {
      const data = await downloadApplicationsXLSX(queryString);

      saveAs(new Blob([data]), 'applications.xlsx');
    } catch (err) {
      console.error(err);
    }
  };

  const revertFilters = () => {
    const { currentSet } = filters ?? {};

    if (!currentSet) return;

    const { filterData } = currentSet;

    setFilters({ ...filterData, currentSet });
  };

  const updateSet = (newSet) => {
    const { filterData } = newSet ?? {};

    setFilters({ ...filterData, currentSet: newSet });
  };

  const updateUsers = (users) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, users }));
  };

  const updateClients = (clients) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, clients }));
  };

  const updateFunders = (funders) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, funders }));
  };

  const updateProgramNames = (programNames) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, programNames }));
  };

  const updateDateRange = (start, end) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, startDate: start, endDate: end }));
  };

  const updateStatus = (status) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, status }));
  };

  const updateDateType = (dateType) => {
    onManualChange();
    setFilters((prevState) => ({ ...prevState, endDateType: dateType }));
  };

  const {
    users: usersCriteria,
    clients: clientsCriteria,
    funders: fundersCriteria,
    programNames: programNamesCriteria,
    status: statusCriteria,
    startDate: startDateCriteria,
    endDate: endDateCriteria,
    currentSet,
    endDateType: endDateTypeCriteria,
  } = filters || {};

  const getDefaultResetFilters = (isUserDashboard: boolean) => {
    return isUserDashboard
      ? {
          startDate: filtersConfigs.dashboard.applications.default.startDate,
          endDate: filtersConfigs.dashboard.applications.default.endDate,
          status: filtersConfigs.dashboard.applications.default.status,
          sortBy: filtersConfigs.dashboard.applications.default.sortBy,
          sortOrder: getDefaultApplicationsSortByFilter(currentUser.isMillenniumUser),
          users: [{ id: String(currentUser.id), name: currentUser.name }],
        }
      : {
          programNames: [],
          users: [],
          clients: [],
          funders: [],
          status: getDefaultApplicationsStatusFilter(currentUser.isMillenniumUser),
          startDate: filtersConfigs.grants.applications.default.startDate,
          endDate: filtersConfigs.grants.applications.default.endDate,
          endDateType: filtersConfigs.grants.applications.default.dateType,
          sortBy: filtersConfigs.grants.applications.default.sortBy,
          sortOrder: getDefaultApplicationsSortByFilter(currentUser.isMillenniumUser),
        };
  };
  const isUserDashboard = location?.pathname?.includes('user-dashboard');
  const defaultResetFilters = getDefaultResetFilters(isUserDashboard);
  return (
    <Stack direction="column" m={2}>
      <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
        <Col className="filter-top-row mb-3 p-0">
          <div className="filter-section-buttons">
            {!isMobile && (
              <div className="application-sections mb-3 mt-0">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div onClick={() => switchTab()}>Programs</div>
                <div className="checked">Applications</div>
              </div>
            )}
          </div>
        </Col>
        {!location?.pathname?.includes('user-dashboard') && currentUser.isMillenniumUser && (
          <Stack direction="row" justifyContent="flex-end" mt={0} spacing={2}>
            {!selected?.length ? (
              <Box
                onClick={startMultipleEdit}
                sx={{
                  backgroundColor: '#EFF1F6',
                  borderRadius: '4px',
                  padding: '8px 16px',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#5F77A5',
                    color: 'white',
                  },
                }}
              >
                Bulk Edit
              </Box>
            ) : (
              <>
                <Box
                  onClick={cancelMultipleEdit}
                  sx={{
                    backgroundColor: '#EFF1F6',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#5F77A5',
                      color: 'white',
                    },
                  }}
                >
                  Cancel Editing
                </Box>
                <Link state={{ data: selected }} to="/dashboard/applications/edit/multiple">
                  <Box
                    sx={{
                      backgroundColor: '#EFF1F6',
                      borderRadius: '4px',
                      padding: '8px 16px',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#5F77A5',
                        color: 'white',
                      },
                    }}
                  >
                    Edit {selected?.length} Applications
                  </Box>
                </Link>
              </>
            )}
          </Stack>
        )}
      </Stack>
      <Stack
        alignItems="flex-start"
        direction="row"
        flexGrow={1}
        justifyContent="space-between"
        spacing={1}
      >
        <FunderFilter
          key="Funder"
          cFilters={clientsCriteria}
          filters={fundersCriteria}
          onChange={updateFunders}
          sourceOfSearch="applications"
        />
        <ProgramNames
          key="Grant Program"
          filters={programNamesCriteria}
          moduleId={2}
          onChange={updateProgramNames}
        />
        {currentUser.isMillenniumUser && (
          <ClientFilter key="Client" filters={clientsCriteria} onChange={updateClients} />
        )}
        <DateRange
          key="Date Range"
          endDate={endDateCriteria}
          onChange={updateDateRange}
          startDate={startDateCriteria}
        />
        {currentUser.isMillenniumUser && !location?.pathname?.includes('user-dashboard') && (
          <UserFilter key="Assigned To" filters={usersCriteria} onChange={updateUsers} />
        )}
        <StatusFilter
          key="Status"
          isDashboard={location?.pathname?.includes('user-dashboard')}
          onChange={updateStatus}
          status={statusCriteria!}
        />
        {!location?.pathname?.includes('user-dashboard') && (
          <DateType
            key="Show Results By"
            dateType={endDateTypeCriteria}
            onChange={updateDateType}
          />
        )}
        <div className="filter-action-row mb-3">
          <FilterActions
            {...(!location?.pathname?.includes('user-dashboard') && {
              onExport: true,
              onCopy: onFiltersCopy,
              onDownloadCSV: exportCSV,
              onDownloadCSV: exportCSV,
              onDownloadXLSX: exportXLSX,
              onRevert: revertFilters,
            })}
            defaultResetFilters={defaultResetFilters}
            filters={filters}
            onReset={resetFilters}
          />
          {!location?.pathname?.includes('user-dashboard') && (
            <FilterSet
              activeTab={1}
              dataType="App"
              defaultSet={currentSet}
              filters={filters}
              onRevert={revertFilters}
              onUpdate={updateSet}
            />
          )}
        </div>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        mt={1}
        spacing={2}
        sx={{
          '@media (max-width: 800px)': {
            maxWidth: '70%',
            rowGap: '20px',
          },
        }}
      >
        <UsedApplicationFiltersDescriptionBlock />
        <Box ml={2}>
          <Form.Group>
            <CustomInput
              checked={onlyMe}
              id="target-om"
              label="Only Me"
              name="target"
              onChange={handleOnlyMeToggle}
              type="switch"
            />
          </Form.Group>
        </Box>
      </Stack>
    </Stack>
  );
}
