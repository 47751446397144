/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import BootstrapTable from 'react-bootstrap-table-next';
import { nanoid } from 'nanoid';

import { AwardBudgetEntry } from 'types/awards';
import budgetColumns from 'components/awards/AwardsDetails/AwardsDetailsView/Budget/getBudgetColumns';
import prepareBudgetEntries, {
  Totals,
} from 'components/awards/AwardsDetails/AwardsDetailsView/Budget/prepareBudgetEntries';

import {
  BudgetFormEntry,
  BudgetForm,
} from 'components/awards/AwardsDetails/AwardsDetailsView/Budget/index';
import { tossError } from 'utils/toastTosser';

export function isIndirectTotal(entry: BudgetFormEntry): boolean {
  return entry.name === 'Total Direct Charges';
}

function parseValue(value: string | number): number {
  return parseFloat(value.toString().replace(/[^0-9.]/g, '')) || 0;
}

export default function BudgetTable({
  showWithMatch,
  readOnly,
  isVersionData,
  loading,
  setTotals,
  setUnsavedChanges,
  totals: initialTotals,
  budget,
}: {
  showWithMatch: boolean;
  readOnly: boolean;
  loading: boolean;
  isVersionData: boolean;
  setTotals: (value: Totals) => void;
  setUnsavedChanges: (value: boolean) => void;
  totals: Totals;
  budget: AwardBudgetEntry[];
}) {
  const { awardAmount, matchAmount } = initialTotals;

  const { values, setFieldValue } = useFormikContext<BudgetForm>();
  const [entries, setEntries] = useState<BudgetFormEntry[]>(
    values.budgetEntries.map((_e: BudgetFormEntry) => ({ ..._e, editable: !isIndirectTotal(_e) }))
  );
  const isUpdateFromFormik = useRef(false);
  const isUpdateFromEntries = useRef(false);
  useEffect(() => {
    if (isVersionData && JSON.stringify(budget) !== JSON.stringify(entries)) {
      setEntries(budget);
    }
  }, [budget, isVersionData]);

  useEffect(() => {
    if (isUpdateFromEntries.current) {
      isUpdateFromEntries.current = false;
      return;
    }

    if (JSON.stringify(entries) === JSON.stringify(values.budgetEntries)) return;
    isUpdateFromFormik.current = true;
    setEntries(values.budgetEntries);
  }, [values.budgetEntries]);

  useEffect(() => {
    if (isUpdateFromFormik.current) {
      isUpdateFromFormik.current = false;
      return;
    }

    if (JSON.stringify(entries) === JSON.stringify(values.budgetEntries)) return;
    isUpdateFromEntries.current = true;
    setFieldValue('budgetEntries', entries);
  }, [entries]);

  const updateItem = useCallback(
    (updatedItem: BudgetFormEntry) => {
      setEntries((prevEntries: BudgetFormEntry[]) => {
        setUnsavedChanges(true);

        const previousItem = prevEntries.filter((e) => e.id === updatedItem.id)[0];

        const awardExpended = parseValue(updatedItem.awardExpended);
        let awardBalance = parseValue(updatedItem.awardBalance);
        if (awardExpended > previousItem.awardExpended) awardBalance -= awardExpended;

        const matchExpended = parseValue(updatedItem.matchExpended);
        let matchBalance = parseValue(updatedItem.matchBalance);
        if (matchExpended > previousItem.matchExpended) matchBalance -= matchExpended;

        const updatedTotals = {
          awardAmount: awardExpended + awardBalance + matchExpended + matchBalance,
          matchAmount: matchBalance + matchExpended,
          awardBalance,
          awardExpended,
          matchBalance,
          matchExpended,
        };

        const updatedItems = prevEntries.map((item) =>
          item.id === updatedItem.id
            ? {
                ...updatedItem,
                ...updatedTotals,
                isUpdated: true,
              }
            : item
        );
        setFieldValue('budgetEntries', updatedItems);
        if (
          updatedItems.reduce((acc, item) => acc + Number(item.awardBalance), 0) < 0 ||
          updatedItems.reduce((acc, item) => acc + Number(item.matchBalance), 0) < 0
        ) {
          tossError('The award balance or match balance is in deficit.');
        }

        if (
          awardAmount &&
          updatedItems.reduce((acc, item) => acc + Number(item.awardExpended), 0) > awardAmount
        ) {
          tossError('Award budget is expended.');
        }

        if (
          awardAmount &&
          updatedItems.reduce((acc, item) => acc + Number(item.awardBalance), 0) > awardAmount
        ) {
          tossError('Balance is greater than the award amount.');
        }

        if (
          awardAmount &&
          updatedItems.reduce((acc, item) => acc + Number(item.matchBalance), 0) > awardAmount
        ) {
          tossError('Match Balance is greater than the match amount.');
        }

        return updatedItems;
      });
    },
    [setEntries, setUnsavedChanges, setFieldValue]
  );
  const addChild = (parent: BudgetFormEntry) => {
    setEntries((previousEntries) => {
      const newEntry = {
        name: `${parent.name} sub-item`,
        parentId: Number(parent.id),
        awardId: parent.awardId,
        awardAmount: !parent.hasChildren
          ? parseValue(parent.awardBalance) +
            parseValue(parent.awardExpended) +
            parseValue(parent.matchExpended) +
            parseValue(parent.matchBalance)
          : 0,
        awardBalance: !parent.hasChildren ? parent.awardBalance : 0,
        awardExpended: !parent.hasChildren ? parent.awardExpended : 0,
        matchAmount: !parent.hasChildren ? parent.matchAmount : 0,
        matchExpended: !parent.hasChildren ? parent.matchExpended : 0,
        matchBalance: !parent.hasChildren ? parent.matchBalance : 0,
        matchDescription: !parent.hasChildren ? parent.matchDescription : '',
        matchAwardId: !parent.hasChildren ? parent.matchAwardId : null,
        matchSource: !parent.hasChildren ? parent.matchSource : null,
        matchType: !parent.hasChildren ? parent.matchType : null,
        id: `_new_${nanoid()}`,
      } as BudgetFormEntry;

      setUnsavedChanges(true);

      return [...previousEntries, newEntry];
    });
  };

  const removeChild = (child: BudgetFormEntry) => {
    setUnsavedChanges(true);

    const updatedEntry = entries.filter((e) => e.id === child.id)[0];

    if (!updatedEntry) return;

    updatedEntry.enabled = false;
    updateItem(updatedEntry);
  };

  const { sortedItems, totals } = useMemo(
    () => prepareBudgetEntries(entries, isVersionData),
    [entries, isVersionData]
  );

  useEffect(() => {
    setTotals(totals);
  }, [totals]);

  return (
    <BootstrapTable<AwardBudgetEntry>
      bodyClasses={loading ? 'loading no-row-cell-padding' : 'no-row-cell-padding'}
      bootstrap4
      bordered={false}
      columns={budgetColumns(
        showWithMatch,
        readOnly,
        addChild,
        removeChild,
        updateItem,
        totals,
        matchAmount
      )}
      data={sortedItems}
      keyField="id"
      noDataIndication="No budget entry data to display for this award."
      wrapperClasses="table-responsive table-borderless rows-centered"
    />
  );
}
