import { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';

import api from '../services/apiService';

export default function FileUploader({
  filesUploaded: defaultFiles,
  disabled = false,
  maxFiles,
  uploadURL,
  onDownload,
  whenError,
  whenStarted,
  whenDone,
  whenRemoved,
}) {
  const [filesUploaded, setFilesUploaded] = useState([]);

  const getUploadParams = (fileWithMeta) => {
    const body = new FormData();
    body.append('mId', fileWithMeta.meta.id);
    body.append('file', fileWithMeta.file);
    return {
      url: uploadURL,
      body,
      headers: { Authorization: api.client?.defaults.headers.common.Authorization },
    };
  };

  useEffect(() => {
    if (defaultFiles) setFilesUploaded([defaultFiles]);
  }, []);

  const handleChangeStatus = (fileWithMeta, status) => {
    if (status === 'error_upload') {
      if (whenError) whenError();
    } else if (status === 'headers_received' || status === 'started' || status === 'preparing') {
      if (whenStarted) whenStarted();
    } else if (status === 'done') {
      if (whenDone) whenDone({ name: `${fileWithMeta.meta.id}_${fileWithMeta.file.name}` });
      setFilesUploaded([fileWithMeta]);
      // fileWithMeta.remove();
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function FileCard({ name, handleDownload }) {
    return (
      <div className="dz-message needsclick">
        <div className="dzu-dropzone">
          <div className="dzu-previewContainer">
            <span className="dzu-previewFileName">
              {handleDownload ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownload();
                  }}
                  rel="noreferrer nofollow"
                >
                  Download {name}
                </a>
              ) : (
                name
              )}
            </span>
            <div className="dzu-previewStatusContainer">
              {!disabled && (
                <span
                  className="dzu-previewButton"
                  onClick={() => {
                    setFilesUploaded([]);
                    if (whenRemoved) whenRemoved();
                  }}
                  onKeyDown={() => {
                    setFilesUploaded([]);
                    if (whenRemoved) whenRemoved();
                  }}
                  role="button"
                  style={{
                    background:
                      'url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUuMCwgMC4wKSIgZmlsbD0iIzMzMzMzMyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNC4wLCAwLjApIj48cG9seWdvbiBwb2ludHM9IjcuNzE5IDQuOTY0IDEyLjY5MiAwLjAxNyAxNC4zODkgMS43MTUgOS40MTIgNi42NjYgMTQuMzU0IDExLjYzNCAxMi42NTcgMTMuMzMxIDYuMDE3IDYuNjU3IDcuNzE1IDQuOTYwIj48L3BvbHlnb24+PHBvbHlnb24gcG9pbnRzPSI3LjYxMiA0Ljk2NCA3LjYxNiA0Ljk2MCA5LjMxMyA2LjY1NyAyLjY3NCAxMy4zMzEgMC45NzcgMTEuNjM0IDUuOTE5IDYuNjY2IDAuOTQyIDEuNzE1IDIuNjM5IDAuMDE3Ij48L3BvbHlnb24+PC9nPjwvZz48L3N2Zz4K")',
                  }}
                  tabIndex={0}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line no-nested-ternary
  return filesUploaded.length > 0 ? (
    <div>
      <FileCard
        handleDownload={onDownload}
        id={filesUploaded[0].meta.id}
        name={filesUploaded[0].meta.name}
      />
    </div>
  ) : disabled ? (
    <div>
      <FileCard id={0} name="No File Attached" />
    </div>
  ) : (
    <div className="dz-message needsclick">
      <Dropzone
        getUploadParams={getUploadParams}
        inputContent="Drag Files Here"
        maxFiles={maxFiles}
        onChangeStatus={handleChangeStatus}
      />
    </div>
  );
}
