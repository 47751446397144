/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Box, IconButton, Stack } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, ArrowBack } from '@mui/icons-material';
import { Check } from 'react-feather';
import dayjs from 'dayjs';

// Types and Constants
import { Client, UpdateClientPayload } from 'types/client';
import { clientTypes, getCountiesForStates, states, types } from 'constants/globalConstants';

// Hooks
import useKeyPress from 'hooks/useKeyPress';
import useUserSession from 'hooks/useUserSession';

// Services & Utils
import { updateClient } from 'services/clientService';
import { tossError, tossSuccess } from 'utils/toastTosser';

// Components
import CustomFields, { CustomField } from 'shared/CustomFields';
import { DateInput, MultiSelectInput, SelectInput, TextInput, TextInputNew } from 'shared/inputs';
import StatePicker from 'shared/StatePicker';
import UserSelect from 'components/clients/UserSelect';
import ClientApplicationUsersPicker from './ClientApplicationUsersPicker';
import ClientAwardUsersPicker from './ClientAwardUsersPicker';
import 'assets/scss/clientDetails.scss';

interface ClientDetailsProps {
  client?: Client;
  readOnly?: boolean;
}

interface AssignedUserOption {
  id: number;
  name: string;
  isClientLead?: boolean;
  isClientDirector?: boolean;
}

type ClientDetailsFormFields = Partial<{
  type: string | number;
  name: string;
  population: string;
  state: string;
  taxId: string;
  duns: string;
  contractStartDate: Date | string | null;
  contractEndDate: Date | string | null;
  assignedApplicationUsers: AssignedUserOption[];
  assignedAwardsUserIds: number[];
  clientLeadId: number;
  clientDirectorId: number;
  memoTo: string;
  memoCc: string;
  customFields: CustomField[];
  awardsEnabled?: boolean;
  canCreateAward?: boolean;
  privateAwardsManagement?: boolean;
  states?: string[];
  counties?: string[];
  billingType?: string;
}>;

export default function ClientDetails({ client, readOnly = false }: ClientDetailsProps) {
  const [form, setForm] = useState<ClientDetailsFormFields>({});
  const [errors, setErrors] = useState<Partial<Record<keyof ClientDetailsFormFields, string>>>();
  const [showCustomFields, setShowCustomFields] = useState(true);
  const [showGeneralFields, setShowGeneralFields] = useState(true);
  const [clientCustomFields, setClientCustomFields] = useState<CustomField[]>([]);

  const clientDetailsForm = useRef<HTMLFormElement>(null);
  const enterPress = useKeyPress('Enter');
  const currentUser = useUserSession();
  const navigate = useNavigate();

  const setField = <K extends keyof ClientDetailsFormFields>(
    field: K,
    value: ClientDetailsFormFields[K]
  ) => setForm((prevState) => ({ ...prevState, [field]: value }));

  function formatClients(arr: string[]) {
    if (!arr) return null;

    const result: string[] = [];

    for (const item of arr) if (!result.includes(item?.trim())) result.push(item?.trim());

    return result?.join(', ');
  }

  useEffect(() => {
    if (client) {
      try {
        const parsedCustomFields = JSON.parse(client.customFields);
        setClientCustomFields(parsedCustomFields);
        const clientStates = Array.isArray(client.states)
          ? client.states
          : client.states
            ? [client.states]
            : [];
        const clientCounties = Array.isArray(client.counties)
          ? client.counties
          : client.counties
            ? [client.counties]
            : [];

        setForm((prev) => ({
          ...prev,
          states: clientStates,
          counties: clientCounties,
          billingType: client.billingType,
        }));
      } catch (error) {
        console.error('Error parsing custom fields', error);
      }
    }
  }, [client]);

  const submitForm = async () => {
    if (window.confirm('Are you sure you want to edit this client?')) {
      const {
        type,
        name,
        population,
        state,
        taxId,
        duns,
        contractStartDate,
        contractEndDate,
        assignedApplicationUsers,
        assignedAwardsUserIds,
        clientLeadId,
        clientDirectorId,
        memoTo,
        memoCc,
        customFields,
        awardsEnabled,
        canCreateAward,
        privateAwardsManagement,
        states: selectedStates,
        counties: selectedCounties,
        billingType,
      } = form;

      const contractDate = contractStartDate
        ? new Date(contractStartDate).toLocaleString?.('en-US', { timeZone: 'GMT' })
        : contractStartDate;

      const contractEndsAt = contractEndDate
        ? new Date(contractEndDate).toLocaleString?.('en-US', { timeZone: 'GMT' })
        : contractEndDate;

      const submittedFields: UpdateClientPayload = {
        id: client!.id,
        type: String(type),
        name,
        population,
        state,
        taxId,
        duns,
        contractDate,
        contractEndsAt,
        assignedUsers: assignedApplicationUsers?.map((user) => user.id),
        awardUsers: assignedAwardsUserIds,
        primaryUser: clientLeadId,
        clientDirectorId,
        memoTo: formatClients(memoTo?.split(',') || []) || undefined,
        memoCc: formatClients(memoCc?.split(',') || []) || undefined,
        customFields: JSON.stringify(customFields ?? []),
        awardsEnabled,
        canCreateAward,
        privateAwardsManagement,
        states: selectedStates,
        counties: selectedCounties,
        billingType,
      };
      const result = await updateClient(submittedFields);

      if (result) {
        tossSuccess('The client data was updated successfully.');
        if (clientDetailsForm.current?.contains(document.activeElement))
          (document.activeElement as HTMLElement)?.blur();
      } else tossError('An error has occurred.');
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const { name, population, taxId, duns, states } = form;

    const errorList: Partial<Record<keyof ClientDetailsFormFields, string>> = {};

    if (name === '') errorList.name = 'Name is required.';
    else if (name && name?.length < 2)
      errorList.name = 'Names with less than 2 characters are not allowed.';

    if (typeof states !== 'undefined') {
      if (!states) errorList.state = 'State is required.';
    }

    if (population) {
      if (Number(population) < 0)
        errorList.population = 'If specified, population must be 0 or greater.';
      else if (Number.isNaN(population)) errorList.population = 'Invalid input.';
    }

    if (taxId) {
      if (
        taxId.match(/^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/g)?.[0] !==
        taxId
      )
        errorList.taxId = 'Invalid format of a tax ID.';
    }

    if (duns) {
      if (!duns?.match(/^[A-Z0-9]+$/g)) {
        errorList.duns = 'Invalid format of a UEI number. (letters must be uppercase)';
      }
    }

    setErrors(errorList);
  }, [form, client]);

  useEffect(() => {
    if (!enterPress && clientDetailsForm.current) {
      if (
        clientDetailsForm.current.contains(document.activeElement) &&
        !readOnly &&
        Object.keys(errors || {}).length === 0
      )
        submitForm();
    }
  }, [enterPress]);

  useEffect(() => {
    if (client) {
      console.log('Client data:', clientCustomFields);
      const formData = {
        ...form,
        type: client.clientType,
        population: client.population,
        taxId: client.taxId,
        duns: client.dunsNumber,
        state: client.state,
        name: client.name,
        memoTo: client.memoTo,
        memoCc: client.memoCc,
        awardsEnabled: Boolean(client.awardsEnabled),
        canCreateAward: Boolean(client.canCreateAward),
        privateAwardsManagement: Boolean(client.privateAwardsManagement),
        customFields: clientCustomFields,
        states: Array.isArray(client.states) ? client.states : client.states ? [client.states] : [],
        counties: Array.isArray(client.counties)
          ? client.counties
          : client.counties
            ? [client.counties]
            : [],
        billingType: client.billingType,
      };
      // console.log('Setting form data:', formData.awardsEnabled);
      setForm(formData);
    }
  }, [client, clientCustomFields]);

  const discardHandler = () => {
    if (client) {
      try {
        const parsedCustomFields = JSON.parse(client.customFields);
        setClientCustomFields(parsedCustomFields);
      } catch (error) {
        console.error('Error parsing custom fields', error);
      }
      const clientStates = Array.isArray(client.states)
        ? client.states
        : client.states
          ? [client.states]
          : [];
      const clientCounties = Array.isArray(client.counties)
        ? client.counties
        : client.counties
          ? [client.counties]
          : [];
      setField('type', client.clientType);
      setField('name', client.name);
      setField('population', client.population);
      setField('taxId', client.taxId);
      setField('duns', client.dunsNumber);
      setField(
        'contractStartDate',
        client.contractStartedAt
          ? new Date(client.contractStartedAt).toLocaleString?.('en-US', { timeZone: 'GMT' })
          : null
      );
      setField(
        'contractEndDate',
        client.contractEndsAt
          ? new Date(client.contractEndsAt).toLocaleString?.('en-US', { timeZone: 'GMT' })
          : null
      );
      setField('assignedApplicationUsers', client.applicationUsers || []);
      setField('assignedAwardsUserIds', client.awardUsers?.map((user) => user.id) || []);
      setField('clientLeadId', client.primaryUserId);
      setField('clientDirectorId', client.clientDirectorId);
      setField('memoTo', client.memoTo);
      setField('memoCc', client.memoCc);
      setField('customFields', clientCustomFields);
      setField('awardsEnabled', Boolean(client.awardsEnabled));
      setField('canCreateAward', Boolean(client.canCreateAward));
      setField('privateAwardsManagement', Boolean(client.privateAwardsManagement));
      setField('states', clientStates);
      setField('counties', clientCounties);
      setField('billingType', client.billingType);
    }
  };
  return client ? (
    <div>
      <Stack alignItems="space-between" direction="column" mb={4} spacing={1}>
        {!readOnly ? (
          <>
            <Box mb={2}>
              <Stack alignItems="center" direction="row" spacing={1}>
                <span
                  className="breadcrumb-list"
                  onClick={() => navigate(-1)}
                  onKeyDown={(e) => e.key === 'Enter' && navigate(-1)}
                  role="button"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  tabIndex={0}
                >
                  Clients
                </span>
                <span>{'>'}</span>
                <span className="breadcrumb-list" style={{ fontWeight: '700' }}>
                  Client Details
                </span>
              </Stack>
            </Box>
            <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
              <Stack alignItems="center" direction="row" spacing={1}>
                <IconButton
                  className="back-button"
                  onClick={() => navigate(-1)}
                  sx={{
                    width: '34px',
                    height: '34px',
                    backgroundColor: '#eff1f6',
                    borderRadius: '6px',
                    padding: '8px 16px',
                    '&:hover': {
                      backgroundColor: '#e0e3eb',
                    },
                  }}
                >
                  <ArrowBack />
                </IconButton>

                <h5 className="heading">Client Details #CT{client?.id} </h5>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button className="discard-btn" onClick={discardHandler} variant="light">
                  Discard
                </Button>
                {!readOnly && (
                  <Button
                    className="d-flex"
                    disabled={readOnly || Object.keys(errors || {}).length > 0}
                    onClick={submitForm}
                  >
                    <Check size={16} style={{ marginTop: '2px' }} />
                    &nbsp;Save
                  </Button>
                )}
              </Stack>
            </Stack>

            <h6 className="sub-heading">Manage the client details</h6>
          </>
        ) : (
          <>
            <Box mb={2}>
              <h5 className="heading">Your Profile</h5>
            </Box>
            <Box>
              <h6 className="sub-heading">See details about your company</h6>
            </Box>
          </>
        )}
      </Stack>
      <Form ref={clientDetailsForm}>
        <Container className="p-0" fluid>
          <div className="client-general-fields">
            <div
              className="hide-show-wrapper"
              onClick={() => setShowGeneralFields(!showGeneralFields)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setShowGeneralFields(!showGeneralFields);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <h4 className="main-client-heading">
                Client Details
                <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
                  {showGeneralFields ? (
                    <>
                      <KeyboardArrowUp
                        className="ms-2"
                        style={{ color: '#2C4474', fontSize: '18px' }}
                      />
                      <span className="show-hide-text">Hide Section</span>
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDown
                        className="ms-2"
                        style={{ color: '#2C4474', fontSize: '18px' }}
                      />{' '}
                      <span className="show-hide-text">Show Section</span>
                    </>
                  )}
                </span>
              </h4>
            </div>
            <div className="general-fields-w">
              {showGeneralFields && (
                <>
                  <Row>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <TextInput
                          className="common-input"
                          controlId="clientDetails.Name"
                          defaultValue={client.name}
                          disabled={readOnly}
                          errors={errors?.name}
                          label="Organisation Name"
                          onChange={(newValue: string) => setField('name', newValue)}
                          placeholder="John Doe"
                          required={!readOnly}
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Organisation Name</div>
                          <div className="field-value">{client.name}</div>
                        </div>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <SelectInput
                          choices={types.map((e) => e.name)}
                          className="common-input"
                          controlId="clientDetails.Type"
                          defaultValue={client.clientType as string}
                          disabled={readOnly}
                          label="Org Type"
                          onChange={(newValue: string | number) => setField('type', newValue)}
                          required={!readOnly}
                          text={
                            form?.type && !readOnly ? '' : 'Will replace currently undefined type.'
                          }
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Org Type</div>
                          <div className="field-value">{client.clientType}</div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <StatePicker
                          className="common-input"
                          controlId="clientDetails.StatePicker"
                          defaultValue={client.state}
                          disabled={readOnly}
                          error={errors?.state}
                          label="State"
                          onChange={(newValue) => setField('state', newValue)}
                          required={!readOnly}
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">State</div>
                          <div className="field-value">{client.state}</div>
                        </div>
                      )}
                    </Col> */}
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <SelectInput
                          choices={['Flex', 'Full']}
                          className="common-input"
                          controlId="clientDetails.BillingType"
                          defaultValue={form.billingType || 'Full'}
                          disabled={readOnly}
                          label="Billing Type"
                          onChange={(newValue: string | number) => {
                            const billingType =
                              typeof newValue === 'number'
                                ? newValue === 0
                                  ? 'Flex'
                                  : 'Full'
                                : newValue;
                            setField('billingType', billingType);
                          }}
                          required={false}
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Billing Type</div>
                          <div className="field-value">{form.billingType || 'Full'}</div>
                        </div>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <TextInput
                          className="common-input"
                          controlId="clientDetails.Population"
                          defaultValue={client.population}
                          disabled={readOnly}
                          errors={errors?.population}
                          label="Population"
                          onChange={(newValue: string) => setField('population', newValue)}
                          placeholder="0"
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Population</div>
                          <div className="field-value">{client.population}</div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <TextInput
                          className="common-input"
                          controlId="clientDetails.TaxID"
                          defaultValue={client.taxId}
                          disabled={readOnly}
                          errors={errors?.taxId}
                          label="Tax ID"
                          onChange={(newValue: string | null) =>
                            setField('taxId', newValue || undefined)
                          }
                          placeholder="9XX7XXXXX"
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Tax ID</div>
                          <div className="field-value">{client.taxId}</div>
                        </div>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <TextInput
                          className="common-input"
                          controlId="clientDetails.DUNS"
                          defaultValue={client.dunsNumber}
                          disabled={readOnly}
                          errors={errors?.duns}
                          label="UEI Number"
                          maxLength={12}
                          minLength={10}
                          onChange={(newValue: string | null) =>
                            setField('duns', newValue || undefined)
                          }
                          placeholder="XXXXXXXXXXXXX"
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">UEI Number</div>
                          <div className="field-value">{client.dunsNumber}</div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <DateInput
                          className="common-input"
                          controlId="clientDetails.ContractStartDate"
                          defaultValue={
                            client.contractStartedAt
                              ? dayjs(
                                  new Date(client.contractStartedAt).toLocaleString?.('en-US', {
                                    timeZone: 'GMT',
                                  })
                                ).startOf('day')
                              : dayjs().startOf('year')
                          }
                          disabled={readOnly}
                          errors={errors?.contractStartDate}
                          isShowYear
                          label="Contract Start Date"
                          onChange={(newValue: Date | string | null) =>
                            setField('contractStartDate', newValue)
                          }
                          placeholder="Select a date"
                          text="Leave blank to base memos on calendar years."
                          textClass="currency-text"
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Contract Start Date</div>
                          <div className="field-value">
                            {client.contractStartedAt
                              ? new Date(client.contractStartedAt).toLocaleDateString('en-US')
                              : ''}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <DateInput
                          className="common-input"
                          controlId="clientDetails.ContractEndDate"
                          defaultValue={
                            client.contractEndsAt
                              ? dayjs(
                                  new Date(client.contractEndsAt).toLocaleString?.('en-US', {
                                    timeZone: 'GMT',
                                  })
                                ).startOf('day')
                              : dayjs().startOf('year').add(1, 'year')
                          }
                          disabled={readOnly}
                          errors={errors?.contractEndDate}
                          isShowYear
                          label="Contract End Date"
                          onChange={(newValue: Date | string | null) =>
                            setField('contractEndDate', newValue)
                          }
                          placeholder="Select a date"
                          text="Leave blank to set to 12 months from the start date in memos."
                          textClass="currency-text"
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Contract End Date</div>
                          <div className="field-value">
                            {client.contractEndsAt
                              ? new Date(client.contractEndsAt).toLocaleDateString('en-US')
                              : ''}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <Form.Group className="common-input" controlId="clientDetails.MemoTO">
                          <TextInputNew
                            controlId="clientDetails.MemoTO"
                            defaultValue={client.memoTo}
                            disabled={readOnly}
                            label="Memo TO"
                            onChange={(newValue: string) => setField('memoTo', newValue)}
                            placeholder="Please enter comma-separated values"
                            text="Please enter comma-seperated values"
                            textClass="currency-text"
                          />
                        </Form.Group>
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Memo TO</div>
                          <div className="field-value">{client.memoTo}</div>
                        </div>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <Form.Group className="common-input" controlId="clientDetails.MemoCC">
                          <TextInputNew
                            controlId="clientDetails.MemoCC"
                            defaultValue={client.memoCc}
                            disabled={readOnly}
                            label="Memo CC"
                            onChange={(newValue: string) => setField('memoCc', newValue)}
                            placeholder="Please enter comma-separated values"
                            text="Please enter comma-seperated values"
                            textClass="currency-text"
                          />
                        </Form.Group>
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Memo CC</div>
                          <div className="field-value">{client.memoCc}</div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <Form.Group className="common-input" controlId="assignedApplicationUsers">
                          <Form.Label>Assigned Application Users</Form.Label>
                          <ClientApplicationUsersPicker
                            client={client}
                            clientDirectorId={form.clientDirectorId}
                            clientLeadId={form.clientLeadId}
                            controlId="assignedApplicationUsers"
                            onChange={(users) => setField('assignedApplicationUsers', users)}
                            readOnly={readOnly}
                          />
                          {!readOnly && (
                            <Form.Text className="currency-text">
                              Start typing the user&apos;s name in the box.
                            </Form.Text>
                          )}
                        </Form.Group>
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Assigned Application Users</div>
                          <div className="field-value">
                            {form.assignedApplicationUsers?.map((user) => user.name).join(', ')}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <Form.Group className="common-input" controlId="clientLead">
                          <Form.Label>Client Lead</Form.Label>
                          <UserSelect
                            defaultValue={client?.applicationUsers?.find(
                              (user) => user.id === client.primaryUserId
                            )}
                            options={form.assignedApplicationUsers}
                            readOnly={readOnly}
                            setSelectedId={(id) => setField('clientLeadId', id)}
                          />
                          {!readOnly && (
                            <Form.Text className="currency-text">
                              Can be selected from Assigned Application Users.
                            </Form.Text>
                          )}
                        </Form.Group>
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Client Lead</div>
                          <div className="field-value">
                            {
                              client?.applicationUsers?.find(
                                (user) => user.id === client.primaryUserId
                              )?.name
                            }
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <Form.Group className="common-input" controlId="clientDirector">
                          <Form.Label>Client Director</Form.Label>
                          <UserSelect
                            defaultValue={client?.applicationUsers?.find(
                              (user) => user.id === client.clientDirectorId
                            )}
                            options={form.assignedApplicationUsers}
                            readOnly={readOnly}
                            setSelectedId={(id) => setField('clientDirectorId', id)}
                          />
                          {!readOnly && (
                            <Form.Text className="currency-text">
                              Can be selected from Assigned Application Users.
                            </Form.Text>
                          )}
                        </Form.Group>
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Client Director</div>
                          <div className="field-value">
                            {
                              client?.applicationUsers?.find(
                                (user) => user.id === client.clientDirectorId
                              )?.name
                            }
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <Form.Group className="common-input" controlId="assignedAwardUsers">
                          <Form.Label>Assigned Award Users</Form.Label>
                          <ClientAwardUsersPicker
                            defaultValues={client.awardUsers || []}
                            onChange={(ids) => setField('assignedAwardsUserIds', ids)}
                            options={form.assignedApplicationUsers}
                            readOnly={readOnly}
                          />
                          {!readOnly && (
                            <Form.Text className="currency-text">
                              Can be selected from Assigned Application Users.
                            </Form.Text>
                          )}
                        </Form.Group>
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Assigned Award Users</div>
                          <div className="field-value">
                            {client.awardUsers?.map((user) => user.name).join(', ')}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <MultiSelectInput
                          choices={states.map((state) => state.name)}
                          className="common-input"
                          controlId="clientDetails.States"
                          defaultValue={client.states || []}
                          disabled={readOnly}
                          isIndexBased={false}
                          label="State"
                          onChange={(selectedIndices) => {
                            const selectedStates = selectedIndices.map(
                              (index) => states[index].name
                            );
                            setField('states', selectedStates);
                            setField('counties', []);
                          }}
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">States</div>
                          <div className="field-value selectorbg">
                            {Array.isArray(client.states)
                              ? client.states.join(', ')
                              : client.states || ''}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      {!readOnly ? (
                        <MultiSelectInput
                          choices={getCountiesForStates(
                            form.states
                              ?.map((stateName) => states.find((s) => s.name === stateName)?.abbr)
                              .filter(Boolean) || []
                          )}
                          className="common-input"
                          controlId="clientDetails.Counties"
                          defaultValue={Array.isArray(client.counties) ? client.counties : []}
                          disabled={readOnly}
                          isIndexBased={false}
                          label="County"
                          onChange={(selectedIndices) => {
                            const availableCounties = getCountiesForStates(
                              form.states
                                ?.map((stateName) => states.find((s) => s.name === stateName)?.abbr)
                                .filter(Boolean) || []
                            );
                            const selectedCounties = selectedIndices.map(
                              (index) => availableCounties[index]
                            );
                            setField('counties', selectedCounties);
                          }}
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Counties</div>
                          <div className="field-value selectorbg">
                            {Array.isArray(client.counties)
                              ? client.counties.join(', ')
                              : client.counties || ''}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {currentUser.isMillenniumUser && (
                    <Row>
                      <Col lg={6} xs={12}>
                        {!readOnly ? (
                          <Form.Group
                            className="common-input"
                            controlId="clientDetails.EnabledAwards"
                          >
                            <SelectInput
                              choices={['Yes', 'No']}
                              controlId="clientDetails.EnabledAwards"
                              defaultValue={client.awardsEnabled ? 'Yes' : 'No'}
                              disabled={readOnly}
                              label="Awards Are Enabled"
                              onChange={(newValue: string | number) => {
                                const isEnabled =
                                  typeof newValue === 'number'
                                    ? newValue === 0
                                    : newValue === 'Yes';
                                setForm((prev) => ({
                                  ...prev,
                                  awardsEnabled: isEnabled,
                                }));
                              }}
                              required={false}
                            />
                          </Form.Group>
                        ) : (
                          <div className="field-heading-wraper">
                            <div className="field-heading">Awards Are Enabled</div>
                            <div className="field-value">{client.awardsEnabled ? 'Yes' : 'No'}</div>
                          </div>
                        )}
                      </Col>
                      {form.awardsEnabled && (
                        <>
                          <Col lg={6} xs={12}>
                            {!readOnly ? (
                              <Form.Group className="common-input" controlId="canCreateAward">
                                <SelectInput
                                  choices={['Yes', 'No']}
                                  controlId="clientDetails.CanCreateAward"
                                  defaultValue={form.canCreateAward ? 'Yes' : 'No'}
                                  disabled={readOnly}
                                  label="Can Create Awards?"
                                  onChange={(newValue: string | number) => {
                                    const isEnabled =
                                      typeof newValue === 'number'
                                        ? newValue === 0
                                        : newValue === 'Yes';
                                    setForm((prev) => ({
                                      ...prev,
                                      canCreateAward: isEnabled,
                                    }));
                                  }}
                                  required={false}
                                />
                              </Form.Group>
                            ) : (
                              <div className="field-heading-wraper">
                                <div className="field-heading">Can Create Awards?</div>
                                <div className="field-value">
                                  {form.canCreateAward ? 'Yes' : 'No'}
                                </div>
                              </div>
                            )}
                          </Col>
                          <Col lg={6} xs={12}>
                            {!readOnly ? (
                              <Form.Group
                                className="common-input"
                                controlId="privateAwardsManagement"
                              >
                                <SelectInput
                                  choices={['Yes', 'No']}
                                  controlId="clientDetails.PrivateAwardsManagement"
                                  defaultValue={form.privateAwardsManagement ? 'Yes' : 'No'}
                                  disabled={readOnly}
                                  label="Privately Manage Awards?"
                                  onChange={(newValue: string | number) => {
                                    const isEnabled =
                                      typeof newValue === 'number'
                                        ? newValue === 0
                                        : newValue === 'Yes';
                                    setForm((prev) => ({
                                      ...prev,
                                      privateAwardsManagement: isEnabled,
                                    }));
                                  }}
                                  required={false}
                                  text="When enabled, only Millennium admins can manage awards and receive notifications"
                                  textClass="currency-text"
                                />
                              </Form.Group>
                            ) : (
                              <div className="field-heading-wraper">
                                <div className="field-heading">Privately Manage Awards?</div>
                                <div className="field-value">
                                  {form.privateAwardsManagement ? 'Yes' : 'No'}
                                </div>
                              </div>
                            )}
                          </Col>
                        </>
                      )}
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>
          {(currentUser.isMillenniumUser || clientCustomFields.length > 0) && !readOnly && (
            <div className="mt-4 custom-fields-wrapper">
              <div
                className="hide-show-wrapper"
                onClick={() => setShowCustomFields(!showCustomFields)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setShowCustomFields(!showCustomFields);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <h5 className="heading-gb mb-4">Custom Fields</h5>
                <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
                  {showCustomFields ? (
                    <>
                      <KeyboardArrowUp
                        className="ms-2"
                        style={{ color: '#2C4474', fontSize: '18px' }}
                      />
                      <span className="show-hide-text">Hide Section</span>
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDown
                        className="ms-2"
                        style={{ color: '#2C4474', fontSize: '18px' }}
                      />{' '}
                      <span className="show-hide-text">Show Section</span>
                    </>
                  )}
                </span>
              </div>
              {showCustomFields && (
                <>
                  <hr />
                  <CustomFields
                    defaultFields={clientCustomFields}
                    disabled={readOnly}
                    onChange={(newFields: CustomField[]) => {
                      setField('customFields', newFields);
                      setClientCustomFields(newFields);
                    }}
                  />
                </>
              )}
            </div>
          )}
        </Container>
      </Form>
    </div>
  ) : (
    <>Loading...</>
  );
}
