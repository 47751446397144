import { AxiosResponse } from 'axios';

import { AwardBudgetEntry } from 'types/awards';
import api, { outputError } from 'services/apiService';

export const getAwardBudget = (awardId: string): Promise<AwardBudgetEntry[] | false> =>
  api
    .get(`/awards/${awardId}/budget-entries`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const bulkCreateBudgetEntries = (
  awardId: string,
  payload: Partial<AwardBudgetEntry>[]
): Promise<boolean> =>
  api
    .post(`/awards/${awardId}/budget-entries`, payload)
    .then((response: AxiosResponse) => {
      return response.status < 303;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const bulkUpdateBudgetEntries = (
  awardId: string,
  payload: Partial<AwardBudgetEntry>[]
): Promise<boolean> =>
  api
    .put(`/awards/${awardId}/budget-entries`, payload)
    .then((response: AxiosResponse) => {
      return response.status < 303;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteBudgetEntries = (
  awardId: string,
  payload: { id: number }[]
): Promise<boolean> => {
  const deletePromises = payload.map((entry) =>
    api
      .delete(`/awards/${awardId}/budget-entries`, { id: entry.id })
      .then((response: AxiosResponse) => response.status === 204)
      .catch((error) => {
        outputError(error);
        return false;
      })
  );

  return Promise.all(deletePromises).then((results) => results.every((result) => result));
};
export const fetchVersions = (awardId: string, page: number, pageSize: number): Promise<boolean> =>
  api
    .get(`/awards/${awardId}/versions`, {
      params: { page, pageSize },
    })
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const createBudgetVersion = (
  awardId: string,
  payload: { versionName: string; entries: Partial<AwardBudgetEntry>[] }
): Promise<boolean> =>
  api
    .post(`/awards/awards/${awardId}/createVersion`, payload)
    .then((response: AxiosResponse) => {
      return response.status < 303;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const getVersionData = (versionId: string): Promise<boolean> =>
  api
    .get(`/awards/versions/${versionId}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });
