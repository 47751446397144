import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Stack, Typography } from '@mui/material';

import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';
import InputField from 'shared/muiComponents/InputField';

import { createFilter } from 'services/filterService';
import { SavedFilter } from 'types/filter';
import { tossError, tossSuccess } from 'utils/toastTosser';

// TODO: can be refactored together with API to use names directly

const moduleIds = {
  summaryView: 3,
  financeView: 4,
  projectView: 5,
  userView: 6,
} as const;

type ViewKey = keyof typeof moduleIds;

interface CreateFiltersSetModalProps {
  view: string;
  onClose: () => void;
  onCreate: (filterSet: SavedFilter) => void;
}

export default function CreateFiltersSetModal({
  view,
  onClose,
  onCreate,
}: CreateFiltersSetModalProps) {
  const [name, setName] = useState<string>('');
  const location = useLocation();

  return (
    <Modal centered className="awards-modal" onHide={onClose} show>
      <Modal.Body>
        <Stack direction="column" px={2} py={2} spacing={4}>
          <Typography m={2} variant="h2">
            Create Filters Set
          </Typography>

          <InputField
            id="description"
            label="Filters Set Name"
            onChange={setName}
            placeholder="Enter name"
            required
            value={name}
          />

          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
            <StandardButton
              disabled={!name}
              onClick={async () => {
                const createdSet = await createFilter({
                  title: name,
                  moduleId: moduleIds[view as ViewKey],
                  filters: location.pathname + location.search,
                });
                if (createdSet) {
                  tossSuccess(`${createdSet.title} created.`);
                  onCreate(createdSet!);
                  onClose();
                } else tossError('Error creating filter set');
              }}
            >
              Create
            </StandardButton>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
