// MultiSelectInput.tsx
import { useEffect, useState, useCallback, useRef } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { X } from 'react-feather';

interface MultiSelectInputProps {
  controlId?: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  choices: string[];
  defaultValue?: number[] | string[];
  onChange: (newValue: number[]) => void;
  errors?: string;
  className?: string;
  isIndexBased?: boolean;
}

export default function MultiSelectInput(props: MultiSelectInputProps) {
  const {
    controlId,
    label,
    disabled = false,
    required = false,
    choices = [],
    defaultValue = [],
    onChange,
    errors,
    className,
    isIndexBased = true,
  } = props;

  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      let indices: number[] = [];

      if (isIndexBased) {
        indices = (defaultValue as number[]).filter(
          (index) => index >= 0 && index < choices.length
        );
      } else {
        indices = (defaultValue as string[])
          .map((value) => choices.indexOf(value))
          .filter((index) => index !== -1);
      }

      setSelectedIndices(indices);
      isInitialMount.current = false;
    }
  }, [defaultValue, choices, isIndexBased]);

  const handleSelection = useCallback(
    (index: number) => {
      setSelectedIndices((prev) => {
        const newIndices = prev.includes(index)
          ? prev.filter((i) => i !== index)
          : [...prev, index];

        onChange(newIndices);
        return newIndices;
      });
    },
    [onChange]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedIndices([]);
    onChange([]);
  }, [onChange]);

  const renderSelectedItems = () => {
    if (selectedIndices.length === 0) {
      return (
        <span style={{ color: '#9FADC9', fontWeight: 600, fontSize: '14px' }}>
          Select options...
        </span>
      );
    }

    return (
      <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
        {selectedIndices.map((index) => (
          <span
            key={index}
            style={{
              backgroundColor: '#2C4474',
              color: '#fff',
              padding: '2px 4px',
              borderRadius: '2px',
              display: 'inline-flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {choices[index]}
            <X
              onClick={(e) => {
                e.stopPropagation();
                handleSelection(index);
              }}
              size={12}
              style={{ cursor: 'pointer' }}
            />
          </span>
        ))}
      </div>
    );
  };

  return (
    <Form.Group className={className} controlId={controlId}>
      <Form.Label>
        {label}
        {required ? ' *' : ''}
      </Form.Label>
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix={disabled ? 'dropdown-disabled' : 'dropdown-toggle'}
          className="w-100 text-left form-control"
          disabled={disabled}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '38px',
          }}
          variant="outline-select"
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              gap: '4px',
              flexWrap: 'wrap',
              overflow: 'hidden',
              alignItems: 'center',
            }}
          >
            {renderSelectedItems()}
          </div>
          {selectedIndices.length > 0 && (
            <X
              onClick={(e) => {
                e.stopPropagation();
                handleClearSelection();
              }}
              size={16}
              style={{
                cursor: 'pointer',
                marginLeft: '8px',
              }}
            />
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100" style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {choices.map((choice, index) => (
            <Dropdown.Item
              key={choice}
              active={selectedIndices.includes(index)}
              onClick={() => handleSelection(index)}
              style={{
                backgroundColor: selectedIndices.includes(index) ? 'transparent' : undefined,
                color: 'inherit',
              }}
            >
              {choice}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {Boolean(errors) && typeof errors === 'string' && (
        <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
