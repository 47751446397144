import { Modal } from 'react-bootstrap';
import { Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { StringsContext } from 'index';
import useUserSession from 'hooks/useUserSession';

import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import InputField from 'shared/muiComponents/InputField';
import StandardButton from 'shared/muiComponents/StandardButton';
import SelectField from 'shared/muiComponents/SelectField';
import CurrencyInputField from 'shared/muiComponents/CurrencyInputField';

import SearchFilter from 'shared/muiComponents/SearchFilter';

import { createProject } from 'services/awards/awardsService';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { searchClients } from 'services/clientService';

import { Client } from 'types/client';

interface NewProjectModalProps {
  toggle: () => void;
  refetchProjects: () => void;
}

export default function NewProjectModal(props: NewProjectModalProps) {
  const { toggle, refetchProjects } = props;

  const [selectedClient, setSelectedClient] = useState<Client | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [category, setCategory] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [budget, setBudget] = useState<number>(0);

  const currentUser = useUserSession();
  const strings = useContext(StringsContext);
  const { categories } = strings.projects;

  const onSubmit = async () => {
    setLoading(true);

    if (currentUser.isMillenniumUser && selectedClient?.id === undefined) return;

    const result = await createProject({ name, budget, category, clientId: selectedClient?.id });

    if (result) {
      tossSuccess('The project was successfully created.');

      refetchProjects();

      toggle();
    } else tossError('Error creating a new project.');

    setLoading(false);
  };

  return (
    <Modal centered className="awards-modal" onHide={toggle} show>
      <Modal.Body>
        <Stack direction="column" px={2} py={2} spacing={4}>
          <Stack direction="column" spacing={4}>
            <Typography m={2} variant="h2">
              New Project
            </Typography>

            <InputField
              id="projectName"
              label="Project Name"
              onChange={setName}
              required
              value={name}
            />

            <CurrencyInputField
              id="projectBudget"
              label="Total Project Budget"
              onSave={(v) => setBudget(parseInt(v.toString().replace(/[^0-9.]/g, ''), 10))}
              value={budget}
            />

            <SelectField
              handleChange={setCategory}
              id="category"
              label="Select the category"
              options={categories}
              required
              value={category}
            />

            {currentUser.isMillenniumUser && (
              <SearchFilter<Client, false>
                getLabel={(f) => f.name}
                label="Client"
                multiple={false}
                onChange={(value) => setSelectedClient(value || null)}
                searchFunction={async (input: string) => searchClients(input, 'name')}
                selected={selectedClient}
                width="100%"
              />
            )}
          </Stack>

          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
            <SecondaryButton onClick={toggle}>Cancel</SecondaryButton>

            <StandardButton
              disabled={
                loading ||
                !name ||
                !category ||
                (currentUser.isMillenniumUser && selectedClient?.id === undefined)
              }
              onClick={onSubmit}
              startIcon
            >
              Create New Project
            </StandardButton>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
