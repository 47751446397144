/* eslint-disable no-else-return */
import { isIndirectTotal } from './BudgetTable';
import { BudgetFormEntry } from './index';

const localeCompareName = (a: BudgetFormEntry, b: BudgetFormEntry): number => {
  if (a.name === null && b.name === null) {
    return 0;
  }
  if (a.name === null) {
    return 1;
  }
  if (b.name === null) {
    return -1;
  }
  return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
};

type BudgetField =
  | 'awardAmount'
  | 'awardExpended'
  | 'awardBalance'
  | 'matchAmount'
  | 'matchExpended'
  | 'matchBalance';

export type Totals = {
  [key in BudgetField]: number;
};

export default (
  items: BudgetFormEntry[],
  isVersionData: boolean
): { sortedItems: BudgetFormEntry[]; totals: Totals } => {
  const groups = new Map<number | null, BudgetFormEntry[]>();

  items = items.map((item) => ({
    ...item,
    matchExpended:
      typeof item.matchExpended === 'string' ? parseFloat(item.matchExpended) : item.matchExpended,
    matchBalance:
      typeof item.matchBalance === 'string' ? parseFloat(item.matchBalance) : item.matchBalance,
  }));

  items = items.filter((item) => item.enabled !== false);

  items.forEach((item) => {
    const parentKey = isVersionData ? item.parentId : item.parentId;
    if (!groups.has(parentKey)) {
      groups.set(parentKey, []);
    }
  });

  // Populate the groups with items
  items.forEach((item) => {
    const parentKey = isVersionData ? item.parentId : item.parentId;
    groups.get(parentKey)!.push(item);
  });

  console.log('populated groups with items', groups);
  // Sort each group by itemOrder or name
  groups.forEach((group) => {
    group.sort(localeCompareName);
  });

  const sortedItems: BudgetFormEntry[] = [];
  const totals: Totals = {
    awardAmount: 0,
    awardExpended: 0,
    awardBalance: 0,
    matchAmount: 0,
    matchExpended: 0,
    matchBalance: 0,
  };

  const appendItems = (parentId: number | null) => {
    if (groups.has(parentId)) {
      const group = groups.get(parentId)?.sort((a, b) => {
        if (isVersionData) {
          return Number(a.entryId) - Number(b.entryId); // eslint-disable-line no-else-return
        } else {
          return Number(a.id) - Number(b.id);
        }
      });

      group?.forEach((item) => {
        const isIndirectTotalItem = isIndirectTotal(item);
        const children = groups.get(isVersionData ? Number(item.entryId) : Number(item.id)) || [];
        const hasChildren = children.length > 0;

        if (hasChildren) {
          const childrenAmounts = children.reduce(
            (sums, child) => ({
              awardAmount: sums.awardAmount + (child.awardAmount || 0),
              awardExpended: sums.awardExpended + (child.awardExpended || 0),
              awardBalance: sums.awardBalance + (child.awardBalance || 0),
              matchAmount: sums.matchAmount + (child.matchAmount || 0),
              matchExpended: sums.matchExpended + (child.matchExpended || 0),
              matchBalance: sums.matchBalance + (child.matchBalance || 0),
            }),
            {
              awardAmount: 0,
              awardExpended: 0,
              awardBalance: 0,
              matchAmount: 0,
              matchExpended: 0,
              matchBalance: 0,
            }
          );

          sortedItems.push({
            ...item,
            hasChildren,
            awardAmount: childrenAmounts.awardAmount,
            awardExpended: childrenAmounts.awardExpended,
            awardBalance: childrenAmounts.awardBalance,
            matchAmount: childrenAmounts.matchAmount,
            matchExpended: childrenAmounts.matchExpended,
            matchBalance: childrenAmounts.matchBalance,
            hasError: childrenAmounts.awardBalance < 0 || childrenAmounts.matchBalance < 0,
          });

          totals.awardAmount += childrenAmounts.awardAmount;
          totals.awardExpended += childrenAmounts.awardExpended;
          totals.awardBalance += childrenAmounts.awardBalance;
          totals.matchAmount += childrenAmounts.matchAmount;
          totals.matchExpended += childrenAmounts.matchExpended;
          totals.matchBalance += childrenAmounts.matchBalance;
        } else {
          sortedItems.push({
            ...item,
            hasChildren,
            ...(isIndirectTotalItem && {
              awardAmount: totals.awardAmount,
              awardExpended: totals.awardExpended,
              awardBalance: totals.awardBalance,
              matchAmount: totals.matchAmount,
              matchExpended: totals.matchExpended,
              matchBalance: totals.matchBalance,
              editable: false,
            }),
            hasError: item.awardBalance < 0 || item.matchBalance < 0,
          });

          if (!isIndirectTotalItem && !hasChildren) {
            totals.awardAmount += item.awardAmount || 0;
            totals.awardExpended += item.awardExpended || 0;
            totals.awardBalance += item.awardBalance || 0;
            totals.matchAmount += item.matchAmount || 0;
            totals.matchExpended += item.matchExpended || 0;
            totals.matchBalance += item.matchBalance || 0;
          }
        }

        appendItems(isVersionData ? Number(item.entryId) : Number(item.id)); // Recursively append child items
      });
    }
  };

  appendItems(null);

  return { sortedItems, totals };
};
