import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { Stack } from '@mui/material';

interface ContactMillenniumModalProps {
  show: boolean;
  onHide: () => void;
}

export default function ContactMillenniumModal({ show, onHide }: ContactMillenniumModalProps) {
  const [formData, setFormData] = useState({
    subject: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    onHide();
    e.preventDefault();
    // Handle form submission (e.g., send data to an API)
    console.log('Form Data:', formData);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Contact Millennium</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Contact Millennium</h5>
        <p className="mb-4">
          Enter the subject and your message for the millennium support and you will get a response
          on your e-mail you use for Millennium login.
        </p>

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group controlId="formSubject" className="mb-3">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Enter the subject"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="formMessage" className="mb-3">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Type in your message for support here..."
              rows={4}
              required
            />
          </Form.Group>
          <div className="d-flex justify-content-end gap-5">
            <Stack direction="row" spacing={2}>
              <Button variant="light" onClick={onHide} className="me-2">
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Send E-mail
              </Button>
            </Stack>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
