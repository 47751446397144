import { useContext } from 'react';
import { UserSessionContext } from 'index';

const useUserSession = () => {
  const context = useContext(UserSessionContext);

  if (!context) {
    throw new Error('Trying to use User Session Context outside of Provider');
  }

  return context;
};

export default useUserSession;
