import useSearchParams from 'hooks/useSearchParams';
import FilterSelect from 'shared/muiComponents/FilterSelect';

const statusFilterValues = {
  initialAgreementRequired: 'Awarded',
  awardLetterRequired: 'Award Letter',
  resolutionRequired: 'Resolution',
  applicationRequired: 'Application',
  appropriated: 'Appropriated',
  encumbered: 'Encumbered',
  approval: 'Approval Required',
  active: 'Active',
  reportsRequired: 'Report Due',
  complianceRequired: 'Compliance',
  closeout: 'Close Out',
  closed: 'Completed',
};

type StatusFilterOption = keyof typeof statusFilterValues;

export default function StatusFilter({ width = '100%' }) {
  const { searchParams, setSearchParams } = useSearchParams();

  return (
    <FilterSelect
      getValueLabel={(value) => (value ? statusFilterValues[value as StatusFilterOption] : 'All')}
      label="Status"
      onChange={(value) => setSearchParams({ page: 1, status: value || null })}
      options={[undefined, ...Object.keys(statusFilterValues)]}
      selected={String(searchParams.status || '')}
      width={width}
    />
  );
}
