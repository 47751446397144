import { Client } from 'types/client';

export const userTypes = [
  'millenniumAdmin',
  'millenniumAnalyst',
  'millenniumResearcher',
  'millenniumManager',
  'userAdmin',
  'userAnalyst',
] as const;

export type UserType = (typeof userTypes)[number];

export interface User {
  id: number;
  primaryClientId: number;
  clientCreatorId?: number;
  enabled?: boolean;
  name: string;
  email: string;
  phone?: string;
  position: string;
  location?: string;
  userType: UserType;
  defaultRole?: string;
  applicationClients?: Client[];
  awardClients?: Client[];
  // primaryClient?: Client;
  clientCreator?: Client;
  client?: Client;
  primary?: boolean;
}

export interface CurrentUser extends User {
  isMillenniumUser: boolean;
  isMillenniumAnalyst: boolean;
  userRole?: {
    role: string | null;
    customUserRole: string | null;
  };
}

export interface UserUpdatePayload {
  id?: number | null;
  clientCreatorId?: number | null;
  name?: string;
  email?: string;
  phone?: string;
  userType?: UserType;
  defaultRole?: string;
  location?: string;
  assignedClients?: number[];
  awardClients?: number[];
  position?: string;
  primaryClient?: number;
  password?: string;
  passwordConfirmation?: string;
}

export const defaultMandatoryRoles = [
  'primaryOrganizationalContact',
  'purchasing',
  // 'departmentDirector', // Only one per client
  'financeContact',
  'resolutionOwner',
] as const;
