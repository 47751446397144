import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

export default function RenderTextWithHighlight({
  text,
  highlight,
}: {
  text: string;
  highlight: string;
}) {
  if (highlight === '') return <div>{text}</div>;

  const matches = match(text, highlight, { insideWords: true });
  const parts = parse(text, matches).map((part, index) => ({ ...part, key: index }));

  return (
    <div
      style={{
        wordBreak: 'break-word',
      }}
    >
      {parts.map((part) => (
        <span
          key={part.key}
          style={{
            fontWeight: part.highlight ? 700 : 400,
          }}
        >
          {part.text}
        </span>
      ))}
    </div>
  );
}
