import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';

import { AwardReport } from 'types/awards';

interface FilterSelectProps {
  label: string;
  onChange: (value?: string) => void;
  selected?: string;
  options: AwardReport[];
  width?: string;
}

export default function ReportSelect({
  label,
  onChange,
  selected,
  options,
  width = '100%',
}: FilterSelectProps) {
  return (
    <FormControl
      sx={{
        width,
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: (theme) =>
            !selected
              ? `1px solid ${theme.palette.secondary.light}`
              : `2px solid ${theme.palette.primary.light}`,
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: (theme) => `2px solid ${theme.palette.secondary.main}`,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: (theme) => `2px solid ${theme.palette.primary.main}`,
        },
        '& .MuiInputLabel-outlined': {
          fontSize: '0.8rem',
          p: '2px',
          fontWeight: 500,
          lineHeight: '0.4rem',
        },
        '& .MuiInputLabel-shrink, .MuiOutlinedInput-notchedOutline': {
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1.1rem',
        },
      }}
    >
      <InputLabel id={`filter-select-${label}`}>{label}</InputLabel>
      <Select
        IconComponent={ExpandMoreRounded}
        id={`filter-select-${label}`}
        label={label}
        labelId={`filter-select-${label}`}
        onChange={(event) => onChange(event.target.value)}
        size="small"
        sx={{
          '& .MuiOutlinedInput-input': {
            padding: '12px 10px 7px 10px',
          },
          // height: '40px',
          fieldset: { borderRadius: 0 },
          '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-root': {
              minHeight: '40px',
            },
          },
        }}
        value={selected}
      >
        <MenuItem divider value={0}>
          Report ID: Start Date, End Date, Report Type
        </MenuItem>

        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {`${option.stringId}, ${option.startsOn}, ${option.endsOn}, ${option.type}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
