import { AxiosResponse } from 'axios';

import {
  Award,
  Project,
  ProjectSources,
  UsersAwards,
  AwardTimelineStep,
  AwardPayments,
  Notifications,
  Preferences,
  PreferenceObject,
} from 'types/awards';

import api, { outputError } from '../apiService';

export interface AwardsList {
  rows: Award[];
  count: number;
}

export interface ProjectsList {
  rows: Project[];
  count: number;
  error?: string;
}

export interface UsersAwardsList {
  rows: UsersAwards[];
  count: number;
  error?: string;
}

export interface CreateProject {
  name?: string;
  category?: string;
  clientId?: number;
  budget?: number;
}

export interface UpdateProject extends CreateProject {
  id: number;
  clientId?: number;
  assignedAwards?: number[];
  sources?: ProjectSources[];
  budget?: number;
}

export interface NotificationsList {
  rows: Notifications[];
  count: number;
  error?: string;
}

export const getAwards = (searchParams: string): Promise<AwardsList | false> =>
  api
    .get(`/awards${searchParams}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const getAwardById = (awardId: string): Promise<Award | false> =>
  api
    .get(`/awards/${awardId}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const getAwardTimeline = (awardId: string): Promise<AwardTimelineStep[] | false> =>
  api
    .get(`/awards/${awardId}/timelines`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const createAward = (payload: Partial<Award>): Promise<Partial<Award> | false> =>
  api
    .post(`/awards`, payload)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const updateAward = (payload: Partial<Award> & { id: number }): Promise<boolean> =>
  api
    .put(`/awards`, payload)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const searchAwards = (field: string, searchQuery: string): Promise<Award[]> =>
  api
    .get(`/awards/search?field=${field}&query=${searchQuery}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });

export const downloadAwardsCSV = (searchParams: string) =>
  api
    .get(`/awards/export/csv${searchParams}`, { responseType: 'arraybuffer' })
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const downloadAwardsXLSX = (searchParams: string) =>
  api
    .get(`/awards/export/xlsx${searchParams}`, { responseType: 'arraybuffer' })
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const getProjects = (searchParams: string): Promise<ProjectsList> =>
  api
    .get(`/projects${searchParams}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {} as ProjectsList;
    })
    .catch((error) => {
      outputError(error);
      return {} as ProjectsList;
    });

export const getProject = (id: number): Promise<Project> =>
  api
    .get(`/projects/${id}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {} as Project;
    })
    .catch((error) => {
      outputError(error);
      return {} as Project;
    });

export const getUsersAwards = (searchParams: string): Promise<UsersAwardsList | false> =>
  api
    .get(`/users/statistics${searchParams}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const createProject = (data: CreateProject): Promise<boolean> =>
  api
    .post('/projects', data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const updateProject = (data: UpdateProject): Promise<boolean> =>
  api
    .put(`/projects`, data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteProject = (id: number): Promise<boolean> =>
  api
    .delete(`/projects/${id}`)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const getNotifications = (id: string): Promise<NotificationsList> =>
  api
    .get(`/awards/${id}/notifications?page=1&perPage=100&sourceId=${id}&sourceType=award`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {} as NotificationsList;
    })
    .catch((error) => {
      outputError(error);
      return {} as NotificationsList;
    });

export const getPreferences = (id: number): Promise<PreferenceObject> =>
  api
    .get(`/clients/${id}/preferences`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {} as PreferenceObject;
    })
    .catch((error) => {
      outputError(error);
      return {} as PreferenceObject;
    });

export const updatePreferences = (id: number, payload: PreferenceObject): Promise<boolean> =>
  api
    .put(`/clients/${id}/preferences`, payload)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
