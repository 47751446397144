import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Trash as DeleteIcon, Check } from 'react-feather';

import { tossError, tossSuccess } from 'utils/toastTosser';

import { deleteProgram } from 'services/programService';
import { useNavigate } from 'react-router';

interface DeleteProgramProps {
  programId: number;
  disabled?: boolean;
  onDelete?: () => void;
}

export default function DeleteProgram({ programId, disabled, onDelete }: DeleteProgramProps) {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prevState) => !prevState);
  const navigate = useNavigate();

  const save = async () => {
    const result = await deleteProgram(programId);

    if (result) {
      tossSuccess('The program had been deleted successfully.');
      toggle();
      navigate(-1);
      if (onDelete) onDelete();
    } else tossError('Error deleting this application.');
  };

  return programId ? (
    <>
      <Button
        className="d-flex justify-content-center align-items-center"
        disabled={disabled}
        onClick={toggle}
        variant="danger"
      >
        <DeleteIcon size={16} />
        &nbsp;Delete
      </Button>

      <Modal centered onHide={toggle} show={open}>
        <Modal.Header closeButton>Delete Program</Modal.Header>

        <Modal.Body>
          <Form>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <Alert variant="light">
                    This action is irreversible! The program will be deleted permanently. Do you
                    want to proceed?
                  </Alert>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="d-flex justify-content-center align-items-center"
            onClick={save}
            variant="danger"
          >
            <Check size={16} />
            &nbsp;Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div className="text-center">
      No program provided for deletion. Please reload the page or clear your cache if you see this
      text.
    </div>
  );
}
