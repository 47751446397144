import { MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { nanoid } from 'nanoid';

import TooltipWithAnchor from 'shared/muiComponents/TooltipWithAnchor';
import { formatCurrencyValue } from 'utils/utilFunctions';

import { Award } from 'types/awards';
import { Totals } from '../prepareBudgetEntries';

type statBubblesProps = {
  award: Award;
  totals: Totals;
  hideMatchTotal?: boolean;
};

type statBubble = {
  title: string;
  amount: string;
  color: string;
  border?: string;
  hasTooltip?: boolean | undefined;
};

export default function StatsBubbles({ award, totals, hideMatchTotal }: statBubblesProps) {
  const [hoveredToolTipIcon, setHoveredToolTipIcon] = useState<HTMLElement | null>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseOver = (event: ReactMouseEvent<HTMLDivElement>, item: statBubble) => {
    if (item?.hasTooltip) {
      // setCursorPosition({ x: event.clientX, y: event.clientY });
      setHoveredToolTipIcon(event.currentTarget.parentElement);
    }

    hoverTimeout.current = setTimeout(() => {
      if (!showTooltip && hoveredToolTipIcon) setShowTooltip(true);
    }, 500);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
    setHoveredToolTipIcon(null);

    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
  };

  const errors = {
    awardTotal: award.awardAmount + Number(award.matchAmount) <= 0,
    awardAmount: award.awardAmount <= 0,
    awardExpended:
      totals.awardExpended > 0 &&
      (totals.awardExpended > award.awardAmount ||
        Number(totals.matchExpended) > Number(award.matchAmount)),
    awardBalance:
      totals.awardBalance < 0 ||
      (award.awardBalance > 0 &&
        totals.awardBalance > 0 &&
        totals.awardBalance > award.awardBalance) ||
      (award.matchBalance > 0 &&
        totals.matchBalance > 0 &&
        totals.awardBalance > 0 &&
        totals.matchBalance > award.matchBalance),
  };

  const awardBalance =
    Number(totals.awardExpended) === 0 && totals.awardAmount === 0
      ? award.awardAmount
      : totals.awardBalance;

  const matchBalance =
    Number(totals.matchExpended) === 0 && totals.matchAmount === 0
      ? award.matchAmount
      : totals.matchBalance;

  const data: statBubble[] = [
    {
      title: 'Total Project Budget',
      amount: formatCurrencyValue(award.awardAmount + Number(award.matchAmount)),
      color: errors.awardTotal ? '#FDEDED' : 'white',
      ...(errors.awardTotal && { border: '1px solid red' }),
    },
    {
      title: 'Total Award Amount vs. Match',
      amount: `${formatCurrencyValue(award.awardAmount)} / ${formatCurrencyValue(
        award.matchAmount
      )}`,
      color: errors.awardAmount ? '#FDEDED' : 'white',
      ...(errors.awardAmount && { border: '1px solid red' }),
    },
    {
      title: `Expenditure: Award vs. Match`,
      amount: `${formatCurrencyValue(totals.awardExpended)} / ${formatCurrencyValue(
        totals.matchExpended
      )}`,
      hasTooltip: true,
      color: errors.awardExpended ? '#FDEDED' : 'white',
      ...(errors.awardExpended && { border: '1px solid red' }),
    },
    {
      title: `Total Balance Award ${hideMatchTotal ? '' : 'vs. Match'}`,
      amount: `${formatCurrencyValue(awardBalance)} ${
        hideMatchTotal ? '' : ` / ${formatCurrencyValue(matchBalance)}`
      }`,
      color: errors.awardBalance ? '#FDEDED' : 'white',
      ...(errors.awardBalance && { border: '1px solid red' }),
    },
    {
      title: 'Payments Received vs. Requested',
      amount: `${formatCurrencyValue(award.paymentsReceived)} / ${formatCurrencyValue(
        award.paymentsRequested
      )}`,
      color: 'white',
    },
  ];

  useEffect(() => {
    // Logic to update totals if needed
    // setTotals(updatedTotals);
  }, [totals]); // , setTotals]);

  return (
    <>
      <Grid container spacing={2}>
        {data.map((item) => (
          <Grid
            key={nanoid()}
            item
            md={2.4}
            onMouseLeave={() => handleMouseOut()}
            onMouseOut={() => handleMouseOut()}
            onMouseOver={(event: ReactMouseEvent<HTMLDivElement>) => handleMouseOver(event, item)}
            sm={6}
            xs={12}
          >
            <Box
              sx={{
                border: item.border,
                backgroundColor: item.color,
                padding: '20px 16px',
                borderRadius: '8px',
                textAlign: 'center',
                boxShadow: '0 0 15px 10px #f7f7f7',
              }}
            >
              <Typography color="textSecondary" gutterBottom variant="h3">
                {item.title}
              </Typography>
              <Typography fontWeight="bold" variant="body2">
                {item.amount}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {showTooltip && hoveredToolTipIcon && (
        <TooltipWithAnchor
          anchorElement={hoveredToolTipIcon}
          isOpen={showTooltip && Boolean(hoveredToolTipIcon)}
          text="When highlighted in red, the award or match funds have been expended"
        />
      )}
    </>
  );
}
