import { useEffect, useState } from 'react';
import { Save, RefreshCw, Search, Trash2 } from 'react-feather';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { AddRounded } from '@mui/icons-material';
import FilterCard from 'shared/filters/FilterCard';
import filtersConfigs, {
  getDefaultApplicationsSortByFilter,
  getDefaultApplicationsStatusFilter,
} from 'constants/filtersConfigs';
import useLocalStorage from 'hooks/useLocalStorage';

import useUserSession from 'hooks/useUserSession';
import DeleteFilterModal from './DeleteFilterModal';
import CreateFilterModal from './CreateFilterModal';
import SaveFilterModal from './SaveFilterModal';
import SearchFilterModal from './SearchFilterModal';

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

interface FilterSetControlsProps {
  filters: Filter;
  defaultSet: Filter;
  onUpdate: (selectedSet: FilterSet) => void;
  onRevert: () => void;
  dataType: string;
  activeTab: number;
}

export default function FilterSetControls({
  filters,
  defaultSet,
  onUpdate,
  onRevert,
  dataType,
  activeTab,
}: FilterSetControlsProps) {
  const currentUser = useUserSession();
  const [currentSet, setCurrentSet] = useState(null);
  const [modified, setModified] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [searchOpen, setSearchOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [saveOpen, setSaveOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const filterSetNameInLS = `setName${dataType ?? ''}`;

  const [, setStoredFilterSet, removeStoredFilterSet] = useLocalStorage(filterSetNameInLS, 'v1.0');

  const toggleSearchModal = () => setSearchOpen((prevState) => !prevState);
  const toggleCreateModal = () => setCreateOpen((prevState) => !prevState);
  const toggleDeleteModal = () => setDeleteOpen((prevState) => !prevState);
  const toggleSaveModal = () => setSaveOpen((prevState) => !prevState);

  const moduleId = activeTab === 0 ? 1 : 2;

  const defaultProgramFilter = {
    programNames: [],
    funders: [],
    category: filtersConfigs.grants.programs.default.category,
    startDate: filtersConfigs.grants.programs.default.startDate,
    endDate: filtersConfigs.grants.programs.default.endDate,
    sortBy: 'startDate',
    sortOrder: 'desc',
  };

  const defaultAppFilter = {
    programNames: [],
    users: [],
    clients: [],
    funders: [],
    status: getDefaultApplicationsStatusFilter(currentUser.isMillenniumUser),
    startDate: filtersConfigs.grants.applications.default.startDate,
    endDate: filtersConfigs.grants.applications.default.endDate,
    endDateType: filtersConfigs.grants.applications.default.dateType,
    sortBy: 'dueDate',
    sortOrder: getDefaultApplicationsSortByFilter(currentUser.isMillenniumUser),
  };

  const compareProps = (obj1: object, obj2: object) => {
    // Sometimes the objects are the same length, but the keys are in a different order.
    return JSON.stringify(obj1 || {}).length === JSON.stringify(obj2 || {}).length;
  };

  useEffect(() => {
    if (!isEqual(defaultSet, currentSet)) {
      setCurrentSet(defaultSet);
    }
  }, [defaultSet]);

  const assignCurrentSet = (selectedSet: FilterSet) => {
    setCurrentSet(selectedSet);
    if (onUpdate) onUpdate(selectedSet);
  };

  useEffect(() => {
    const { filterData } = currentSet ?? {};

    if (filterData) {
      setStoredFilterSet(currentSet.name);

      const isModified = !isEqual(filters, { ...filterData, currentSet });

      setModified(isModified);
    } else {
      removeStoredFilterSet();
    }

    if (filters) {
      const isEmpty =
        Object.values(filters).every(
          (e) =>
            e === null ||
            e === undefined ||
            e.length === 0 ||
            e === getDefaultApplicationsStatusFilter(currentUser.isMillenniumUser) ||
            Object.prototype.hasOwnProperty.call(e, 'filterData')
        ) || compareProps(filters, activeTab === 0 ? defaultProgramFilter : defaultAppFilter);

      setEmpty(isEmpty);
    } else if (filters === undefined) {
      setEmpty(true);
    }

    return () => removeStoredFilterSet();
  }, [filters, currentSet, activeTab]);

  return (
    <>
      {currentSet && modified && empty && (
        <FilterCard
          onClick={toggleDeleteModal}
          SelectedIcon={Trash2}
          variant="filter-delete"
          tooltipText={`Delete ${currentSet.name}`}
        />
      )}
      {currentSet && modified && !empty && (
        <FilterCard
          onClick={toggleSaveModal}
          SelectedIcon={Save}
          variant="filter-save"
          tooltipText={`Save ${currentSet.name}`}
        />
      )}
      {currentSet && !modified && (
        <FilterCard
          onClick={toggleSearchModal}
          SelectedIcon={RefreshCw}
          variant="filter-change"
          tooltipText={`Change ${currentSet.name}`}
        />
      )}
      {!currentSet && empty && (
        <FilterCard
          onClick={toggleSearchModal}
          SelectedIcon={Search}
          tooltipText="Search Filter set"
          title="No Filter"
          variant="filter-search"
        />
      )}
      {!currentSet && !empty && (
        <FilterCard
          onClick={toggleCreateModal}
          SelectedIcon={AddRounded}
          title="Filter"
          tooltipText="Create Filter set"
          variant="filter-save"
        />
      )}

      <SearchFilterModal
        moduleId={moduleId}
        onClose={toggleSearchModal}
        onSelect={assignCurrentSet}
        open={searchOpen}
        setSearchOpen={setSearchOpen}
      />
      <CreateFilterModal
        filters={filters}
        moduleId={moduleId}
        onClose={toggleCreateModal}
        onCreate={assignCurrentSet}
        open={createOpen}
      />
      <DeleteFilterModal
        filterSet={currentSet}
        onClose={toggleDeleteModal}
        onDelete={assignCurrentSet}
        open={deleteOpen}
      />
      <SaveFilterModal
        filters={filters}
        filterSet={currentSet}
        moduleId={moduleId}
        onClose={toggleSaveModal}
        onRevert={onRevert}
        onSave={assignCurrentSet}
        open={saveOpen}
      />
    </>
  );
}
