import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import { User } from 'types/user';
import { Program } from 'types/program';
import api, { outputError } from './apiService';

const { REACT_APP_PROGRAMS_PER_PAGE, REACT_APP_API_URL } = process.env;

export interface ProgramList {
  rows: Program[];
  pages: number;
  error?: string;
}

export interface ReadProgram {
  programId: number;
  users: string;
}

export const createProgram = (data: Partial<Program> & { user?: User }): Promise<Program> =>
  api
    .post(`/programs`, data)
    .then((response: AxiosResponse) => {
      if (response.status < 303) {
        return response?.data;
      }
      throw new Error(`Failed to create program: ${response.statusText}`);
    })
    .catch((error) => {
      outputError(error);
      throw error;
    });

export const getPrograms = (filterQuery: string): Promise<ProgramList> =>
  api
    .get(`/programs?${filterQuery}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303)
        return {
          rows: response.data.rows,
          pages: Math.ceil(response.data.count / Number(REACT_APP_PROGRAMS_PER_PAGE || '20')),
        };
      return { rows: [], pages: 0, error: response.statusText || 'Error' };
    })
    .catch((error) => {
      outputError(error);
      return { rows: [], pages: 0, error: 'Error' };
    });

export const searchPrograms = (
  searchQuery: string,
  searchField: string,
  funder: string | null
): Promise<Program[]> =>
  api
    .get(`/programs/search?query=${searchQuery}&field=${searchField}&funder=${funder}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });

export const updateProgram = (data: Partial<Program> & { user?: User }): Promise<boolean> =>
  api
    .put(`/programs`, data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteProgram = (id: number): Promise<boolean> =>
  api
    .delete('/programs', { data: { id } })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const getProgramsReadList = (userId: string): Promise<ReadProgram[]> =>
  api
    .get(`/programs/read/${userId}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });

export const markProgramsAsRead = (data: unknown): Promise<boolean> =>
  api
    .post('/programs/read', data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const downloadProgramFile = (path: string) =>
  api
    .get(`${REACT_APP_API_URL}/file/local/summaries/${path}`, { responseType: 'blob' })
    .then((response: AxiosResponse) => {
      saveAs(new Blob([response.data]), `${path}`);
    })
    .catch((error) => {
      outputError(error);
    });

export const deleteprogramFile = (id: number): Promise<boolean> =>
  api
    .delete('programs/deletefile', { id })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
