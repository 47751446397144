import { AxiosResponse } from 'axios';
import { FilterData, SavedFilter } from 'types/filter';
import api, { outputError } from './apiService';

interface CreateFilterProps {
  title: string;
  moduleId: number;
  filters: FilterData | string;
}

export const searchFilters = (
  moduleId: number,
  query: string,
  field: string
): Promise<SavedFilter[]> =>
  api
    .get(`/filters/search?section=${moduleId}&query=${query}&field=${field}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });

export const createFilter = (data: CreateFilterProps): Promise<SavedFilter | false> =>
  api
    .post(`/filters`, data)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const updateFilter = (data: unknown): Promise<boolean> =>
  api
    .put(`/filters`, data)
    .then((response: AxiosResponse) => {
      return response.status < 303;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteFilter = (id: number): Promise<boolean> =>
  api
    .delete(`/filters`, { data: { id } })
    .then((response: AxiosResponse) => {
      return response.status < 303;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });
