import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import { Sliders } from 'react-feather';
import { ArrowDropDown, AttachMoney } from '@mui/icons-material';
import CurrencyInput from 'react-currency-input-field';
import Button from 'react-bootstrap/Button';

interface MoneyInputProps {
  controlId: string;
  label: string;
  defaultAmount: number | string;
  defaultStringAmount: string;
  defaultVaries: boolean;
  disabled?: boolean;
  errors: string;
  required?: boolean;
  text: string;
  canVary?: boolean;
  onAmountChange: (amount: number) => void;
  onVaryingAmountChange: (amount: string | null) => void;
  onVaryChange: (varies: boolean) => void;
  className?: string;
  textClass?: string;
  isFromPrograms?: boolean;
}

export default function MoneyInput(props: MoneyInputProps) {
  const {
    controlId,
    label,
    defaultAmount,
    defaultStringAmount,
    defaultVaries,
    disabled = false,
    errors,
    required = false,
    text,
    canVary = true,
    onAmountChange,
    onVaryingAmountChange,
    onVaryChange,
    className,
    textClass,
    isFromPrograms = false,
  } = props;
  const [amount, setAmount] = useState(defaultAmount ?? 0.0);
  const [stringAmount, setStringAmount] = useState(defaultStringAmount ?? null);
  const [varies, setVaries] = useState(defaultVaries ?? false);

  useEffect(() => {
    onVaryChange?.(varies);

    if (onAmountChange && onVaryingAmountChange && varies) {
      onAmountChange(0.0);
      onVaryingAmountChange(null);
    }

    if (onAmountChange && onVaryingAmountChange && !varies) {
      onAmountChange(0.0);
      onVaryingAmountChange(null);
    }
  }, [varies]);

  useEffect(() => {
    if (onAmountChange) onAmountChange(parseFloat(amount));
    if (onVaryingAmountChange && amount) onVaryingAmountChange(null);
  }, [amount]);

  useEffect(() => {
    if (onAmountChange && stringAmount) onAmountChange(0.0);
    if (onVaryingAmountChange) onVaryingAmountChange(stringAmount);
  }, [stringAmount]);

  const placeHolderComparison = defaultAmount === 'Multiple' || defaultStringAmount === 'Multiple';

  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>
        {label}
        {required ? ' *' : ''}
      </Form.Label>
      <InputGroup>
        {varies ? (
          <Form.Control
            className={`form-control${errors ? ' is-invalid' : ''}`}
            defaultValue={stringAmount}
            disabled={disabled}
            onChange={(e) => setStringAmount(e.target.value)}
            placeholder={placeHolderComparison ? 'Multiple' : 'Varies'}
            type="text"
            style={{ paddingRight: '40px' }}
          />
        ) : (
          <CurrencyInput
            allowDecimals
            allowNegativeValue={false}
            className={`form-control${errors ? ' is-invalid' : ''}`}
            decimalSeparator="."
            decimalsLimit={2}
            defaultValue={defaultAmount}
            disabled={disabled}
            groupSeparator=","
            onValueChange={(value) => setAmount(value)}
            placeholder={placeHolderComparison ? 'Multiple' : '$0'}
            step={10}
            style={{ paddingRight: '40px' }}
          />
        )}
        {isFromPrograms && canVary && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => !disabled && setVaries((prevState) => !prevState)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setVaries((prevState) => !prevState);
              }
            }}
            role="button"
            tabIndex={0}
          >
            {!varies && <AttachMoney fontSize="small" sx={{ color: '#2C4474' }} />}{' '}
            <ArrowDropDown fontSize="small" sx={{ color: '#2C4474' }} />
          </div>
        )}
        {!isFromPrograms && canVary && (
          <InputGroup.Append>
            <Button
              color="primary-green"
              disabled={disabled}
              onClick={() => setVaries((prevState) => !prevState)}
              style={{
                margin: '0 0 0 0',
                padding: '5px 10px 0 10px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
              }}
              variant="primary-green"
            >
              <Sliders size={16} />
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>

      {errors ? (
        <div className="validation-errors">{errors}</div>
      ) : (
        <Form.Text className={textClass}>{text}</Form.Text>
      )}
    </Form.Group>
  );
}
