import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Helmet } from 'react-helmet';

import useUserSession from 'hooks/useUserSession';

import { getClient } from 'services/clientService';
import { Client } from 'types/client';

import ClientDetails from 'components/clients/ClientDetails';
import ClientContacts from 'components/clients/ClientContacts';
import ClientFiles from 'components/clients/ClientFiles';
import ClientUsers from 'components/clients/ClientUsers';
import { states } from 'constants/globalConstants';

dayjs.extend(advancedFormat);

export default function ClientEdit() {
  const currentUser = useUserSession();
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState<Client>();

  // TODO: check logic of page changing, why we need that
  window.scrollTo(0, 0); // Scroll to Top after opening Edit page

  useEffect(() => {
    if (clientId)
      getClient(clientId).then(async (clientDetails) => {
        // TODO: why extra steps required here:
        if (!states.map((e) => e.abbr).includes(clientDetails.state)) clientDetails.state = 'AL';
        if (parseInt(clientDetails.population, 10) < 0) clientDetails.population = '0';

        setClient(clientDetails);
      });
  }, [clientId]);

  let readOnly = !['millenniumAdmin', 'millenniumManager'].includes(currentUser.userType);

  if (
    currentUser.isMillenniumUser &&
    client?.applicationUsers?.some((e) => e.id === currentUser.id)
  )
    readOnly = false;

  return (
    <div>
      <Helmet>
        <title>Edit Client - Grantrack</title>
      </Helmet>

      {client && (
        <Container
          className="client-page-form edit-client-page-form client-page-edit"
          fluid
          style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          <ClientDetails client={client} readOnly={readOnly} />
          {currentUser.userType === 'millenniumAdmin' && !readOnly && (
            <ClientUsers users={client?.usersCreatedByClient} />
          )}
          {!readOnly && <ClientContacts client={client} readOnly={readOnly} />}
          {!readOnly && <ClientFiles client={client} readOnly={readOnly} />}
        </Container>
      )}
    </div>
  );
}
