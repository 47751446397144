import { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchFieldProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
}

export default function SearchField({ value, placeholder, onChange }: SearchFieldProps) {
  return (
    <TextField
      fullWidth
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }}
      placeholder={placeholder}
      sx={{
        '& .MuiOutlinedInput-input': {
          padding: '8px 12px',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: (theme) => theme.palette.primary.light,
          },
          '&.Mui-focused fieldset': {
            borderColor: (theme) => theme.palette.primary.light,
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: (theme) => theme.palette.secondary.contrastText,
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          display: 'none',
        },
        '& input[type=number]': {
          MozAppearance: 'textfield',
        },
      }}
      value={value}
    />
  );
}
