import { useFormikContext } from 'formik';

import StandardButton from 'shared/muiComponents/StandardButton';
// import { tossError } from 'utils/toastTosser';
import { BudgetForm } from 'components/awards/AwardsDetails/AwardsDetailsView/Budget/index';

export default function SaveButton({
  setUnsavedChanges,
}: {
  setUnsavedChanges?: (value: boolean) => void;
}) {
  const { submitForm, errors } = useFormikContext<BudgetForm>();

  const handleSave = () => {
    if (setUnsavedChanges) setUnsavedChanges(false);

    submitForm();
  };

  return <StandardButton onClick={() => handleSave()}>Save Changes</StandardButton>;
}
