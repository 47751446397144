import dayjs from 'dayjs';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FileMinus, FileText } from 'react-feather';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { BootstrapTableRef } from 'react-bootstrap-table-next';

import ApplicationFilters from 'components/applications/ApplicationFilters';

import splitAndReplaceDoubleCommas from 'utils/helpers/splitAndReplaceDoubleCommas';
import checkFilterValues from 'utils/helpers/checkFilterValues';
import filtersConfigs, {
  getDefaultApplicationsSortByFilter,
  getDefaultApplicationsStatusFilter,
} from 'constants/filtersConfigs';

import useWindowSize from 'hooks/useWindowSize';
import useQueryString from 'hooks/useQueryString';
import useLocalStorage from 'hooks/useLocalStorage';
import ProgramsList from 'components/programs/ProgramsList';
import ProgramFilters from 'components/programs/ProgramFilters';
import ApplicationsList from 'components/applications/ApplicationsList';

import { Application } from 'types/application';
import useUserSession from 'hooks/useUserSession';
import { ApplicationFilter, ProgramFilter } from './Dashboard';

type PageName = 'applications' | 'programs';

interface StoredFilters {
  applicationFilters: ApplicationFilter;
  programFilters: ProgramFilter;
}

interface ProgramFilters {
  programNamesFiltered: { name: string }[];
  categoryFiltered: string;
  fundersFiltered: { name: string }[];
  startDateFiltered: string;
  endDateFiltered: string;
  sortByFiltered: string;
  sortOrderFiltered: string;
}

interface Filters {
  activeTab: number;
  appFilterFound: ApplicationFilter | null;
  progFilterFound: ProgramFilters | null;
  applicationFilters: ApplicationFilter;
  programFilters: ProgramFilter;
  setApplicationFilters: Dispatch<SetStateAction<ApplicationFilter>>;
  setProgramFilters: Dispatch<SetStateAction<ProgramFilter>>;
  applicationsPage: number;
  setApplicationsPage: Dispatch<SetStateAction<number>>;
  programsPage: number;
  setProgramsPage: Dispatch<SetStateAction<number>>;
}

export const FilterContext = createContext<Filters>({} as Filters);

function Applications() {
  const currentUser = useUserSession();
  const location = useLocation();
  const pageName = location?.pathname?.split('/').at(-1) as PageName;
  const [storedFilters] = useLocalStorage<Record<PageName, StoredFilters>>('filters', 'v1.0');
  const queryFilters = useQueryString();

  const windowSize = useWindowSize();
  const isMobile = !!(windowSize?.width && windowSize.width < 768);

  // const APPLICATION_FILTERS_DEFAULT = { programNames: [], users: [], clients: [], funders: [], status: 'all', startDate: null, endDate: null },
  // 			PROGRAM_FILTERS_DEFAULT			= { programNames: [], category: '', funders: [], startDate: null, endDate: null };

  const defaultTab = queryFilters.get('t');
  const [activeTab, setActiveTab] = useState<0 | 1>(defaultTab === '0' ? 0 : 1);
  const [applicationFilters, setApplicationFilters] = useState(
    (pageName && storedFilters?.[pageName]?.applicationFilters) || {
      programNames: [],
      users: [],
      clients: [],
      funders: [],
      status: getDefaultApplicationsStatusFilter(currentUser.isMillenniumUser),
      startDate: filtersConfigs.grants.applications.default.startDate,
      endDate: filtersConfigs.grants.applications.default.endDate,
      sortBy: filtersConfigs.grants.applications.default.sortBy,
      sortOrder: getDefaultApplicationsSortByFilter(currentUser.isMillenniumUser),
    }
  );
  const [programFilters, setProgramFilters] = useState<ProgramFilter>(
    (pageName && storedFilters?.[pageName]?.programFilters) || {
      programNames: [],
      funders: [],
      category: filtersConfigs.grants.programs.default.category,
      startDate: filtersConfigs.grants.programs.default.startDate,
      endDate: filtersConfigs.grants.programs.default.endDate,
      sortBy: 'startDate',
      sortOrder: 'desc',
    }
  );
  const [applicationsPage, setApplicationsPage] = useState(1);
  const [programsPage, setProgramsPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [selected, setSelected] = useState<Application[]>([]);

  const [, setSearchParams] = useSearchParams();

  const appTableRef = useRef<BootstrapTableRef>(null);
  const programTableRef = useRef(null);

  const switchTab = () => setActiveTab(activeTab === 0 ? 1 : 0);

  const syncProgramFilters = useCallback(() => {
    const filter: ProgramFilter | undefined = checkFilterValues(programFilters)
      ? (programFilters as ProgramFilter)
      : (pageName && storedFilters?.[pageName]?.programFilters) || {};

    const { programNames, category, funders, startDate, endDate, sortBy, sortOrder } = filter || {};

    const historyObject: { [key: string]: string } = { t: '0', p: programsPage.toString() };

    /*
			MIL-283:
			As the list of program names is comma-separated in the URL, if any of the names happens to
			contain commas, these commas will be treated as separators too, causing unintended behavior.
			To prevent that, I'm going to add escape mechanics for commas in names (and funders too, just
			in case): single commas will be replaced with double commas when passed to the URL.
		*/
    if (programNames && programNames.length > 0)
      historyObject.gp = programNames.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    if (funders && funders.length > 0)
      historyObject.f = funders.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    if ((category === '0' || category) && category !== 'all') historyObject.ct = category;
    if (startDate && dayjs(startDate).isValid())
      historyObject.sd = dayjs(startDate).format('YYYY-MM-DD');
    if (endDate && dayjs(endDate).isValid()) historyObject.ed = dayjs(endDate).format('YYYY-MM-DD');
    if (sortBy) historyObject.sb = sortBy;
    if (sortOrder) historyObject.so = sortOrder;

    setSearchParams(historyObject);
  }, [programFilters, programsPage]);

  const syncApplicationFilters = useCallback(() => {
    const filter: ApplicationFilter | undefined = checkFilterValues(applicationFilters)
      ? (applicationFilters as ApplicationFilter)
      : pageName && storedFilters?.[pageName]?.applicationFilters;

    const {
      programNames,
      users,
      clients,
      funders,
      status,
      startDate,
      endDate,
      endDateType,
      sortBy,
      sortOrder,
    } = filter || {};

    const historyObject: { [key: string]: string } = { t: '1', p: applicationsPage.toString() };

    /*
			MIL-283:
			As the list of program names is comma-separated in the URL, if any of the names happens to
			contain commas, these commas will be treated as separators too, causing unintended behavior.
			To prevent that, I'm going to add escape mechanics for commas in names (and funders too, just
			in case): single commas will be replaced with double commas when passed to the URL.
		*/

    if (programNames && programNames.length > 0)
      historyObject.gp = programNames.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    if (funders && funders.length > 0)
      historyObject.f = funders.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    if (users && users.length > 0)
      historyObject.a = users.map((e) => `${e.id}|${e.name}`).join(',');
    if (clients && clients.length > 0)
      historyObject.c = clients.map((e) => `${e.id}|${e.name}`).join(',');
    if (status) historyObject.s = status;
    if (startDate && dayjs(startDate).isValid())
      historyObject.sd = dayjs(startDate).format('YYYY-MM-DD');
    if (endDate && dayjs(endDate).isValid()) historyObject.ed = dayjs(endDate).format('YYYY-MM-DD');
    if (sortBy) historyObject.sb = sortBy;
    if (sortOrder) historyObject.so = sortOrder;
    if (endDateType) historyObject.edt = endDateType;

    setSearchParams(historyObject);
  }, [applicationFilters, applicationsPage]);

  useEffect(() => {
    // To prevent URL flickering.
    if (!programFilters) return;

    syncProgramFilters();
  }, [programFilters, syncProgramFilters]);

  useEffect(() => {
    // To prevent URL flickering.
    if (!applicationFilters) return;

    syncApplicationFilters();
  }, [applicationFilters, syncApplicationFilters]);

  useEffect(() => {
    if (activeTab === 0) {
      syncProgramFilters();
    } else if (activeTab === 1) {
      syncApplicationFilters();
    }
  }, [activeTab, syncApplicationFilters, syncProgramFilters]);

  const readProgramFiltersFromURL = (query: URLSearchParams) => {
    const page = query.get('p');
    const grantPrograms = query.get('gp');
    const category = query.get('ct');
    const funders = query.get('f');
    const startDate = query.get('sd');
    const endDate = query.get('ed');
    const sortBy = query.get('sb');
    const sortOrder = query.get('so');

    setProgramsPage(Number.isNaN(page) || !page ? 1 : parseInt(page, 10));

    // Safe to not address exceptions for startDate and endDate because if they
    // don't exist among params, they will be equal to null according to js docs.

    return {
      programNames: grantPrograms ? splitAndReplaceDoubleCommas(grantPrograms) : [],
      funders: funders ? splitAndReplaceDoubleCommas(funders) : [],
      category: category ?? 'all',
      startDate: startDate ?? null,
      endDate: endDate ?? null,
      sortBy: sortBy ?? 'startDate',
      sortOrder: sortOrder ?? 'desc',
    };
  };

  const resetApplicationsPage = () => {
    setApplicationsPage(1);
  };

  const resetProgramsPage = () => {
    setProgramsPage(1);
  };

  const readApplicationFiltersFromURL = (query: URLSearchParams) => {
    const page = query.get('p');
    const grantPrograms = query.get('gp');
    const users = query.get('a');
    const clients = query.get('c');
    const status = query.get('s');
    const funders = query.get('f');
    const startDate = query.get('sd');
    const endDate = query.get('ed');
    const sortBy = query.get('sb');
    const sortOrder = query.get('so');
    const endDateType = query.get('edt');

    setApplicationsPage(Number.isNaN(page) || !page ? 1 : parseInt(page, 10));

    // Safe to not address exceptions for startDate and endDate because if they
    // don't exist among params, they will be equal to null according to js docs.

    return {
      programNames: grantPrograms ? splitAndReplaceDoubleCommas(grantPrograms) : [],
      users: users
        ? users.split(',').map((e) => {
            const arrUser = e.split('|');
            return { id: parseInt(arrUser[0], 10), name: arrUser[1] };
          })
        : [],
      clients: clients
        ? clients.split(',').map((e) => {
            const arrClient = e.split('|');
            return { id: parseInt(arrClient[0], 10), name: arrClient[1] };
          })
        : [],
      funders: funders ? splitAndReplaceDoubleCommas(funders) : [],
      status: status ?? getDefaultApplicationsStatusFilter(currentUser.isMillenniumUser),
      startDate,
      endDate,
      sortBy: sortBy ?? 'dueDate',
      sortOrder: sortOrder ?? (currentUser.isMillenniumUser ? 'asc' : 'desc'),
      endDateType: endDateType ?? 'Due Date',
    };
  };

  useEffect(() => {
    // Parse the query string once per page load. Don't do that on each tab switch.
    // This solution is stable, even though eslint doesn't quite like it.
    const tab = parseInt(queryFilters.get('t') || '', 10);

    if (tab === 0) {
      const programUrlFilters = readProgramFiltersFromURL(queryFilters);
      const filter = checkFilterValues(programUrlFilters)
        ? programUrlFilters
        : pageName && storedFilters?.[pageName]?.programFilters;

      setActiveTab(0);
      setProgramFilters(filter as ProgramFilter);
    } else if (tab === 1) {
      const appUrlFilters = readApplicationFiltersFromURL(queryFilters);
      const filter = checkFilterValues(appUrlFilters)
        ? appUrlFilters
        : pageName && storedFilters?.[pageName]?.applicationFilters;

      setActiveTab(1);
      setApplicationFilters(filter as ApplicationFilter);
    }
  }, []);

  const startMultipleEdit = () => {
    setIsEditing((prevState) => !prevState);
  };

  const cancelMultipleEdit = () => {
    setIsEditing(false);
    if (appTableRef?.current?.selectionContext?.selected?.length) {
      appTableRef.current.selectionContext.selected = [];
    }
    setSelected([]);
  };

  return (
    <>
      <Helmet>
        <title>{activeTab === 1 ? 'Grant Applications' : 'Grant Programs'} - Grantrack</title>
      </Helmet>
      <FilterContext.Provider
        value={{
          activeTab,
          appFilterFound: null,
          progFilterFound: null,
          applicationFilters,
          programFilters,
          setApplicationFilters,
          setProgramFilters,
          applicationsPage,
          setApplicationsPage,
          programsPage,
          setProgramsPage,
        }}
      >
        <Container className="filter-header p-0" fluid>
          <Row className="d-flex justify-content-start align-items-end">
            <Col className="d-none d-md-flex justify-content-start align-items-start " xs={12}>
              <Col className="d-none d-md-flex justify-content-end align-items-end" xs={12} />
            </Col>
            <Col>
              {!isMobile && (
                <div className="filter-body">
                  {activeTab === 0 && (
                    <ProgramFilters onManualChange={resetProgramsPage} switchTab={switchTab} />
                  )}
                  {activeTab === 1 && (
                    <ApplicationFilters
                      cancelMultipleEdit={cancelMultipleEdit}
                      onManualChange={resetApplicationsPage}
                      selected={selected}
                      startMultipleEdit={startMultipleEdit}
                      switchTab={switchTab}
                    />
                  )}
                </div>
              )}
            </Col>

            <Col className="pt-3 d-md-none" xl={3}>
              {isMobile && (
                <div className="application-sections mx-md-3">
                  <input
                    defaultChecked={activeTab === 0}
                    id="section-progs"
                    name="application-section"
                    onChange={switchTab}
                    type="radio"
                    value="0"
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    className="d-flex justify-content-center align-items-center"
                    htmlFor="section-progs"
                  >
                    <FileMinus
                      size={16}
                      style={{
                        minWidth: '16px',
                        maxWidth: '16px',
                        minHeight: '16px',
                        maxHeight: '16px',
                      }}
                    />
                    &nbsp;&nbsp;Programs
                  </label>
                  <input
                    defaultChecked={activeTab === 1}
                    id="section-apps"
                    name="application-section"
                    onChange={switchTab}
                    type="radio"
                    value="1"
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    className="d-flex justify-content-center align-items-center"
                    htmlFor="section-apps"
                  >
                    <FileText
                      size={16}
                      style={{
                        minWidth: '16px',
                        maxWidth: '16px',
                        minHeight: '16px',
                        maxHeight: '16px',
                      }}
                    />
                    &nbsp;&nbsp;Applications
                  </label>
                </div>
              )}
              {activeTab === 0 && (
                <ProgramFilters
                  isMobile={isMobile}
                  onManualChange={resetProgramsPage}
                  switchTab={switchTab}
                />
              )}
              {activeTab === 1 && (
                <ApplicationFilters
                  cancelMultipleEdit={cancelMultipleEdit}
                  isMobile={isMobile}
                  onManualChange={resetApplicationsPage}
                  selected={selected}
                  startMultipleEdit={startMultipleEdit}
                  switchTab={switchTab}
                />
              )}
            </Col>
          </Row>
        </Container>

        {/* <div className="mt-4" /> */}

        {activeTab === 1 ? (
          <ApplicationsList
            appTableRef={appTableRef}
            cancelMultipleEdit={cancelMultipleEdit}
            checkFilterValues={checkFilterValues}
            isEditing={isEditing}
            selected={selected}
            setSelected={setSelected}
          />
        ) : (
          <ProgramsList checkFilterValues={checkFilterValues} programTableRef={programTableRef} />
        )}
      </FilterContext.Provider>
    </>
  );
}

export default Applications;
