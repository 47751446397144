import { ElementType, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import {
  AwardsIcon,
  ClientsIcon,
  DashboardIcon,
  GrantsIcon,
  ReportsIcon,
  UsersIcon,
} from 'shared/muiComponents/customIcons';

import { Stack, Button, Typography } from '@mui/material';
import useUserSession from 'hooks/useUserSession';

interface NavTabProps {
  to: string;
  icon: ReactNode;
  title: string;
}
function NavTab({ to, icon, title }: NavTabProps) {
  return (
    <Button
      component={NavLink as ElementType}
      disableRipple
      sx={{
        height: '70px',
        width: '110px',
        color: 'white',
        borderRadius: 0,
        backgroundColor: (theme) => theme.palette.primary.main,
        '#wrapper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: '12px 20px',
        },
        '&.active, &:hover': {
          color: 'white',
          backgroundColor: (theme) => theme.palette.primary.dark,
          '#wrapper': {
            borderBottom: `2px solid white`,
          },
        },
        svg: {
          marginBottom: '5px',
        },
      }}
      to={`/dashboard${to}`}
    >
      <div id="wrapper">
        {icon}
        <Typography sx={{ color: 'white', fontWeight: 600, fontSize: '0.7rem', lineHeight: 1 }}>
          {title}
        </Typography>
      </div>
    </Button>
  );
}

export default function HeaderNavBar() {
  const currentUser = useUserSession();

  return (
    <Stack direction="row">
      {currentUser.isMillenniumUser && currentUser.userType !== 'millenniumAnalyst' && (
        <NavTab icon={<DashboardIcon />} title="Dashboard" to="/user-dashboard" />
      )}

      <NavTab icon={<GrantsIcon />} title="Programs &nbsp;&nbsp; & Apps" to="/applications" />

      <NavTab icon={<AwardsIcon />} title="Awards" to="/awards" />

      <NavTab
        icon={<ClientsIcon size={14} />}
        title={currentUser.isMillenniumUser ? 'Clients' : 'Profile'}
        to={
          currentUser.isMillenniumUser
            ? '/clients'
            : `/clients/edit/${currentUser.clientCreatorId || ''}`
        }
      />

      {(currentUser.userType === 'millenniumAdmin' || currentUser.userType === 'userAdmin') && (
        <NavTab icon={<UsersIcon />} title="Users" to="/users" />
      )}

      <NavTab icon={<ReportsIcon />} title="Data" to="/reports" />
    </Stack>
  );
}
