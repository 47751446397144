import { useState, useEffect, useCallback, useContext } from 'react';
import { saveAs } from 'file-saver';
import { Container, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import sumBy from 'lodash.sumby';

import { OverviewData, Submissions, AwardAmounts, StatsCharts } from 'components/reports/sections';

import useQueryString from 'hooks/useQueryString';

import { tossSuccess } from 'utils/toastTosser';
import Breadcrumbs from 'components/layout/Breadcrumbs';
import ReportsFiltersUI from 'components/reports/filters/ReportsFiltersUI';
import { downloadReportsDOCX, getReports, ReportEntry, ReportsData } from 'services/reportService';
import OverviewKnobs from 'shared/OverviewKnobs';

// import PizZip from 'pizzip';
// import Docxtemplater from 'docxtemplater';
// import { angularParser, DocxMimeType, loadFile } from 'services/fileService';
import { DocxMimeType } from 'services/fileService';
import { StringsContext } from 'index';
import useUserSession from 'hooks/useUserSession';
import { applicationsConfigs, applicationDates } from 'constants/globalConstants';

export interface Entity {
  id: number;
  name: string;
}

export interface ReportFilters {
  users: Entity[];
  clients: Entity[];
  funders: { name: string }[];
  state: string | null;
  startDate: string | null;
  endDate: string | null;
  endDateType: string | null;
}

interface ReportSearchParams extends Omit<ReportFilters, 'users' | 'clients' | 'funders'> {
  users: string[];
  clients: string[];
  funders: string[];
}

export interface IOverviewData {
  source: string;
  appsSubmitted: number;
  appsRejected: number;
  totalAwards: number;
  totalAwardsValue: number;
  averageAward: number;
  successRate: number | null;
}

export interface ISubmissionData {
  category: number;
  total: number;
  submissionShare: number | null;
  successRate: number | null;
  [key: string]: number | null;
}

export interface IAwardAmountsData {
  category: number;
  [key: string]: number | null;
}

export default function Reports() {
  const queryFilters = useQueryString();
  const [, setSearchParams] = useSearchParams();
  const currentUser = useUserSession();

  const todayDate = dayjs().format('YYYY-MM-DD');
  const startOfYearDate = dayjs().startOf('y').format('YYYY-MM-DD');

  const [filters, setFilters] = useState<Partial<ReportFilters>>({
    startDate: currentUser.isMillenniumUser ? startOfYearDate : null,
    endDate: currentUser.isMillenniumUser ? todayDate : null,
    endDateType: 'Due Date',
  });

  const [reportsData, setReportsData] = useState<ReportsData>({
    bySource: [],
    byCategory: [],
    byCategoryAndSource: [],
  });

  const [overviewData, setOverviewData] = useState<IOverviewData[]>([]);
  const [submissionsData, setSubmissionsData] = useState<ISubmissionData[]>([]);
  const [awardAmountsData, setAwardAmountsData] = useState<IAwardAmountsData[]>([]);

  const [reportsTotals, setReportsTotals] = useState({
    awardsValue: 0,
    submissionsValue: 0,
    awards: 0,
    submissions: 0,
    averageAward: 0,
    averageAmount: 0,
  });
  const strings = useContext(StringsContext);

  const updateKnobs = () => {
    const totalAwardsValue = reportsData.bySource
      .map((e: ReportEntry) => e.awardsValue)
      .reduce((a: number, v: number) => a + v, 0);
    const totalSubmissionsValue = reportsData.bySource
      .map((e: ReportEntry) => e.submissionsValue)
      .reduce((a: number, v: number) => a + v, 0);
    const totalAwards = reportsData.bySource
      .map((e: ReportEntry) => e.awarded)
      .reduce((a: number, v: number) => a + v, 0);
    const totalSubmissions = reportsData.bySource
      .map((e: ReportEntry) => e.submitted)
      .reduce((a: number, v: number) => a + v, 0);
    const totalAverageAward = totalAwardsValue / totalAwards || 0;
    const totalAverageAmount = totalSubmissionsValue / totalSubmissions || 0;

    setReportsTotals({
      awardsValue: totalAwardsValue,
      submissionsValue: totalSubmissionsValue,
      awards: totalAwards,
      submissions: totalSubmissions,
      averageAward: totalAverageAward,
      averageAmount: totalAverageAmount,
    });
  };

  const refetchData = async () => {
    const currentFilters: Partial<ReportSearchParams> = {};

    const { users, clients, funders, state, startDate, endDate, endDateType } = filters || {};

    if (users && users.length > 0) currentFilters.users = users.map((e) => e.id.toString());
    if (clients && clients.length > 0) currentFilters.clients = clients.map((e) => e.id.toString());
    if (funders && funders.length > 0) currentFilters.funders = funders.map((e) => e.name);
    if (state) currentFilters.state = state;
    if (startDate) currentFilters.startDate = dayjs(startDate).format('YYYY-MM-DD');
    if (endDate) currentFilters.endDate = dayjs(endDate).format('YYYY-MM-DD');
    if (endDateType)
      currentFilters.endDateType =
        applicationDates[
          applicationDates.findIndex(({ name }) => name === endDateType)
        ].serverValue;

    const params = new URLSearchParams();

    Object.entries(currentFilters).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value) && value.length > 0) {
          params.append(key, value.join(','));
        } else {
          params.append(key, value.toString());
        }
      }
    });

    return getReports(params.toString());
  };

  const updateData = () => {
    const prepareOverviewData = (data: ReportEntry[]) => {
      const finalData = data.map(
        ({ submitted, awarded, rejected, awardsValue, averageAward }, index) => ({
          source: strings.applications.sourceNames[index],
          appsSubmitted: submitted,
          appsRejected: rejected,
          totalAwards: awarded,
          totalAwardsValue: awardsValue,
          averageAward,
          successRate: rejected + awarded ? (awarded / (rejected + awarded)) * 100 : null,
        })
      );

      setOverviewData(finalData);
    };

    const prepareSubmissionsData = (data: ReportEntry[][]) => {
      const totalSubmissions = data.reduce(
        (partial: number, current) => partial + sumBy<ReportEntry>(current, 'submitted'),
        0
      );

      const finalData = data.map((categoryData, index) => {
        const submittedInCategory = sumBy<ReportEntry>(categoryData, 'submitted');
        const awardedInCategory = sumBy<ReportEntry>(categoryData, 'awarded');
        const rejectedInCategory = sumBy<ReportEntry>(categoryData, 'rejected');

        const decidedInCategory = awardedInCategory + rejectedInCategory;

        const result: ISubmissionData = {
          category: index,
          total: submittedInCategory,
          submissionShare: totalSubmissions ? (submittedInCategory / totalSubmissions) * 100 : null,
          successRate: decidedInCategory ? (awardedInCategory / decidedInCategory) * 100 : null,
        };

        for (const [source, sourceData] of categoryData.entries()) {
          const { submitted } = sourceData;
          result[`source${source}`] = submitted;
        }

        return result;
      });

      setSubmissionsData(finalData);
    };

    const prepareAwardAmountsData = (data: ReportEntry[][]) => {
      const finalData = data.map((categoryData, index) => {
        const result: IAwardAmountsData = {
          category: index,
        };

        for (const [source, sourceData] of categoryData.entries()) {
          const { awardsValue } = sourceData;
          result[`source${source}`] = awardsValue;
        }

        return result;
      });

      setAwardAmountsData(finalData);
    };

    const dataUpdater = async () => {
      const { bySource, byCategory, byCategoryAndSource }: ReportsData = await refetchData();
      if (bySource && byCategory && byCategoryAndSource) {
        setReportsData({ bySource, byCategory, byCategoryAndSource });
        prepareOverviewData(bySource);
        prepareSubmissionsData(byCategoryAndSource);
        prepareAwardAmountsData(byCategoryAndSource);
      }
    };

    dataUpdater();
  };

  useEffect(updateKnobs, [reportsData]);

  useEffect(updateData, [filters]);

  const prepareReportsData = () => {
    const overview = [
      [
        'Source',
        'Apps Submitted',
        'Total Awards',
        'Total Awards Value',
        'Average Award Amount',
        'Success Rate',
      ],
    ];

    const totalByCategory = [['Category', 'Total']];

    for (const fundingSource of applicationsConfigs.sources) totalByCategory[0].push(fundingSource);

    totalByCategory[0].push('Submission Share');
    totalByCategory[0].push('Success Rate');

    const totalByCategoryAndSource = [['Category']];

    for (const fundingSource of applicationsConfigs.sources)
      totalByCategoryAndSource[0].push(fundingSource);

    return { overview, totalByCategory, totalByCategoryAndSource };
  };

  const downloadDOCX = async () => {
    const preparedReportsData = prepareReportsData();

    // loadFile(`${process.env.REACT_APP_API_URL}/templates/reports.docx`, (fileError, content) => {
    //   if (fileError) throw fileError;

    //   const zip = new PizZip(content);
    //   const doc = new Docxtemplater(zip, { parser: angularParser });

    //   doc.render(); // Check for file issues in the console

    //   const out = doc.getZip().generate({
    //     type: 'blob',
    //     mimeType: DocxMimeType,
    //     compression: 'DEFLATE',
    //   }); // Output the document using Data-URI

    //   saveAs(out, 'Test.docx');
    // });

    const data = await downloadReportsDOCX({
      currentUser: currentUser.name,
      overviewData: preparedReportsData.overview,
      totalByCategory: preparedReportsData.totalByCategory,
      totalByCategoryAndSource: preparedReportsData.totalByCategoryAndSource,
      submissionsValue: reportsTotals.submissionsValue,
      appsApplied: reportsTotals.submissions,
      averageAmount: reportsTotals.averageAmount,
      fundingAwarded: parseFloat(reportsTotals.awardsValue.toFixed(2)),
      appsAwarded: reportsTotals.awards,
      averageAward: reportsTotals.averageAward,
      bySourceData: reportsData.bySource,
      byCategoryData: reportsData.byCategory,
      sources: applicationsConfigs.sources,
      categories: applicationsConfigs.categories,
      overviewTable: overviewData,
      categoryTable: submissionsData,
      awardTable: awardAmountsData,
    });

    if (data) saveAs(new Blob([data, { type: DocxMimeType }]), 'Millennium Report.docx');
  };

  const readFiltersFromURL = (query: URLSearchParams) => {
    const users = query.get('a');
    const clients = query.get('c');
    const funders = query.get('f');
    const state = query.get('s');
    const startDate = query.get('sd');
    const endDate = query.get('ed');
    const endDateType = query.get('edt');

    return {
      users: users
        ? users.split(',').map((e) => {
            const arrUser = e.split('|');
            return { id: parseInt(arrUser[0], 10), name: arrUser[1] };
          })
        : [],
      clients: clients
        ? clients.split(',').map((e) => {
            const arrClient = e.split('|');
            return { id: parseInt(arrClient[0], 10), name: arrClient[1] };
          })
        : [],
      funders: funders
        ? funders.split(',').map((e) => {
            return { name: e };
          })
        : [],
      state,
      startDate: startDate || (currentUser.isMillenniumUser ? startOfYearDate : null),
      endDate: endDate || (currentUser.isMillenniumUser ? todayDate : null),
      endDateType: endDateType || 'Due Date',
    };
  };

  const syncFilters = useCallback(() => {
    const { users, clients, funders, state, startDate, endDate, endDateType } = filters || {};

    const historyObject: { [key: string]: string } = {};

    if (users && users.length > 0)
      historyObject.a = users.map((e) => `${e.id}|${e.name}`).join(',');
    if (clients && clients.length > 0)
      historyObject.c = clients.map((e) => `${e.id}|${e.name}`).join(',');
    if (funders && funders.length > 0) historyObject.f = funders.map((e) => `${e.name}`).join(',');
    if (state && state !== 'all') historyObject.s = state;
    if (startDate && dayjs(startDate).isValid())
      historyObject.sd = dayjs(startDate).format('YYYY-MM-DD');
    if (endDate && dayjs(endDate).isValid()) historyObject.ed = dayjs(endDate).format('YYYY-MM-DD');
    if (endDateType) historyObject.edt = endDateType;

    setSearchParams(historyObject);
  }, [filters]);

  const resetFilters = () => {
    setFilters({
      startDate: currentUser.isMillenniumUser ? startOfYearDate : null,
      endDate: currentUser.isMillenniumUser ? todayDate : null,
      endDateType: 'Due Date',
    });
  };

  const onFiltersCopy = () => {
    tossSuccess('Link copied to clipboard!');
  };

  useEffect(() => {
    // To prevent URL flickering.
    if (!filters) return;

    syncFilters();
  }, [filters, syncFilters]);

  useEffect(() => {
    setFilters(readFiltersFromURL(queryFilters));
  }, []);

  const { bySource: knobsData } = reportsData || {};

  const { bySource: chartsDataSource, byCategory: chartsDataCategory } = reportsData || {};

  return (
    <>
      <Helmet>
        <title>Reports - Grantrack</title>
      </Helmet>

      <div className="filter-section-buttons">
        <Breadcrumbs title="Reports" />
        {/* <div className="filter-action-row filter-action-row--reports mb-3">
          <FilterActionsNew
            onCopy={onFiltersCopy}
            onDownloadDOCX={downloadDOCX}
            onExport
            onReset={resetFilters}
          />
        </div> */}
      </div>

      <Container fluid>
        <Row>
          <ReportsFiltersUI
            filters={filters}
            setFilters={setFilters}
            onCopy={onFiltersCopy}
            onDownloadDOCX={downloadDOCX}
            onExport
            onReset={resetFilters}
          />
        </Row>
        <Row className="mt-4">
          <Col className="mt-4 d-flex align-items-stretch" xl={7}>
            <OverviewKnobs data={knobsData} />
          </Col>

          <Col className="mt-4 d-flex align-items-stretch" xl={5}>
            <StatsCharts categoryData={chartsDataCategory} sourceData={chartsDataSource} />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="mt-4" xl={12}>
            <OverviewData data={overviewData} />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="mt-4" xl={12}>
            <Submissions data={submissionsData} />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="mt-4" xl={12}>
            <AwardAmounts data={awardAmountsData} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
