import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import 'assets/scss/authwrapper.scss';
import headingImageSrc from 'assets/images/milliniumheading.png';

interface AuthWrapperProps {
  children: React.ReactNode;
  imageSrc: string;
}

export default function AuthWrapper({ children, imageSrc }: AuthWrapperProps) {
  return (
    <div className="auth-wrapper">
      <Container fluid className="p-0 m-0">
        <Row className="align-items-center justify-content-center auth-container p-0 g-0 m-0">
          <Col md={6} className="auth-left p-0 m-0 d-flex flex-column">
            <img src={headingImageSrc} alt="Millennium Strategies" className="heading-image" />
            <div className="flex-grow-1 d-flex align-items-center justify-content-center w-70">
              {children}
            </div>
            <a href="/login" className="contact-link" target="_blank" rel="noopener noreferrer">
              Contact Millennium
            </a>
            <p className="copyright">@2024 Millennium Strategies</p>
          </Col>
          <Col md={6} className="auth-right p-0 m-0">
            <div className="auth-image w-100 h-100">
              <img src={imageSrc} alt="Auth" className="w-100 h-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
