import { Modal } from 'react-bootstrap';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';

import InputField from 'shared/muiComponents/InputField';
import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { updateProject } from 'services/awards/awardsService';
import { Project } from 'types/awards';
import SelectField from 'shared/muiComponents/SelectField';
// import OutlinedInputField from 'shared/muiComponents/OutlinedInputField';
import CurrencyInputField from 'shared/muiComponents/CurrencyInputField';

interface AddFundingModalProps {
  toggle: () => void;
  fetchProject: (id: number) => void;
  data: Project;
}

const sources: string[] = ['Bond', 'Budget', 'Grant', 'Loan', 'Other'];

export default function AddFundingModal(props: AddFundingModalProps) {
  const { data, toggle, fetchProject } = props;

  const [description, setDescription] = useState<string>('');
  const [amount, setAmount] = useState<string | number>('');
  const [source, setSource] = useState<string>('');

  const onSubmit = async () => {
    const newSource = { amount: Number(amount), name: description, type: source };

    const result = await updateProject({
      id: data.id,
      name: data.name,
      category: data.category,
      sources: [...data.sources, newSource],
    });

    data.sources.push(newSource);

    if (result) {
      tossSuccess('The Source was successfully added.');
      fetchProject(data.id);
      toggle();
    } else tossError('Error adding a new Source.');
  };

  return (
    <Modal centered className="awards-modal" onHide={toggle} show>
      <Modal.Body>
        <Stack direction="column" px={2} py={2} spacing={4}>
          <Stack direction="column" spacing={4}>
            <Typography m={2} variant="h2">
              Add Funding Source
            </Typography>

            <InputField
              id="description"
              label="Description"
              onChange={setDescription}
              placeholder="Enter the Description"
              required
              value={description}
            />

            <CurrencyInputField
              id="totalAwardAmount"
              label="Amount"
              onSave={(v) => setAmount(v)}
              // placeholder="Enter Amount"
              value={Number(amount)}
            />

            <SelectField
              handleChange={setSource}
              id="source"
              label="Select a Source"
              options={sources}
              required
              value={source}
            />
          </Stack>
          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
            <SecondaryButton onClick={toggle}>Cancel</SecondaryButton>

            <StandardButton disabled={!description || !amount || !source} onClick={onSubmit}>
              Add Funding Source
            </StandardButton>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
