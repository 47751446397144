import { useState, useEffect } from 'react';

function Loader({ infinite }: { infinite?: boolean }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!infinite) setShow(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [show, infinite]);

  return (
    <div className={`loader-wrapper ${show ? '' : 'loaderhide'}`}>
      <div className="loader loader-7">
        <div className="line line1" />
        <div className="line line2" />
        <div className="line line3" />
      </div>
    </div>
  );
}

export default Loader;
