import { Ref, forwardRef, useEffect, useState } from 'react';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import DateHeader from 'shared/DateHeader';
import { Calendar } from 'react-feather';

interface DateFieldProps {
  controlId: string;
  text?: string;
  label: string;
  value?: string | null;
  disabled?: boolean;
  required?: boolean;
  errors?: string;
  onChange?: (newValue: string | null) => void;
  onBlur?: () => void;
  popperClassName?: string;
  tooltipData?: unknown[];
  labelClass?: string;
  inputClass?: string;
  className?: string;
}

const DateField = forwardRef<HTMLDivElement, DateFieldProps>(
  (
    {
      controlId,
      text,
      label,
      value,
      disabled = false,
      required = false,
      errors,
      onChange,
      onBlur,
      popperClassName,
      tooltipData,
      labelClass = '',
      inputClass = '',
      className,
    }: DateFieldProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [defaultValue, setDefaultValue] = useState('None');
    const [isDateSelected, setIsDateSelected] = useState(false);
    const toClear = value ? value.substring(0, 10) : null;
    const clearValue = toClear ? moment(toClear).toDate() : null;

    const defaultPlaceholder = () => {
      if (tooltipData && tooltipData?.length > 1) {
        setDefaultValue('Multiple');
      } else if (tooltipData && tooltipData?.length === 1) {
        setDefaultValue(tooltipData[0] as string);
      } else {
        setDefaultValue('None');
      }
    };

    useEffect(() => {
      if (tooltipData?.length) {
        defaultPlaceholder();
      }
    }, [tooltipData]);

    useEffect(() => {
      setIsDateSelected(!!clearValue);
    }, [clearValue]);

    return (
      <Form.Group controlId={controlId} className={className}>
        <Form.Label className={labelClass}>
          {label}
          {required ? ' *' : ''}
        </Form.Label>

        <div style={{ position: 'relative', width: '100%' }}>
          <DatePicker
            ref={ref as unknown as React.RefObject<DatePicker<never, undefined>>}
            autoComplete="off"
            className={`w-100 form-control digits${errors ? ' is-invalid' : ''}`}
            dateFormat="eee, MMM do - yyyy"
            disabled={disabled}
            isClearable={!disabled && !required}
            onBlur={onBlur}
            onChange={(date) => {
              const changedDate = date
                ? `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                : null;
              onChange?.(changedDate);
              setIsDateSelected(!!date);
            }}
            placeholderText={value || defaultValue}
            popperClassName={popperClassName}
            renderCustomHeader={DateHeader}
            selected={clearValue}
            wrapperClassName={inputClass}
          />
          {!isDateSelected && (
            <div
              style={{
                position: 'absolute',
                top: '55%',
                right: '10px',
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
              }}
            >
              <Calendar size={22} />
            </div>
          )}
        </div>

        {Boolean(errors) && <div className="validation-errors">{errors}</div>}

        {!errors && <Form.Text muted>{text}</Form.Text>}
      </Form.Group>
    );
  }
);

export default DateField;
