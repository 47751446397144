import { AxiosResponse } from 'axios';

import api, { outputError } from './apiService';

export const getToken = (data: { email: string; password: string }): Promise<string> =>
  api
    .post(`/sessions`, data)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data.token;
      return '';
    })
    .catch((error) => {
      outputError(error);
      return '';
    });

export const changePassword = (data: {
  tokenString: string;
  newPassword: string;
}): Promise<boolean> =>
  api
    .patch('/users/password', data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const requestPasswordReset = (data: { email: string }): Promise<boolean> =>
  api
    .post('/users/password', data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
