import { useEffect, useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { X } from 'react-feather';

interface SelectInputProps {
  controlId?: string;
  text?: string;
  defaultValue?: number | string;
  label: string;
  disabled?: boolean;
  required: boolean;
  choices: string[];
  onChange: (newValue: string | number) => void;
  tooltipData?: unknown[];
  errors?: string;
  className?: string;
}

export default function SelectInput(props: SelectInputProps) {
  const {
    controlId,
    text,
    defaultValue,
    label,
    disabled = false,
    required = false,
    choices = [],
    onChange = null,
    tooltipData,
    errors,
    className,
  } = props;
  const [selected, setSelected] = useState(defaultValue || 0);

  useEffect(() => {
    if (onChange) onChange(selected);
  }, [selected]);

  const defaultPlaceholder = () => {
    if (tooltipData && tooltipData?.length >= 1) {
      setSelected('Multiple');
    } else if (tooltipData?.length === 1) {
      setSelected(tooltipData[0] as string);
    } else {
      setSelected('None');
    }
  };

  useEffect(() => {
    if (tooltipData?.length) defaultPlaceholder();
  }, [tooltipData]);

  const handleClearSelection = () => {
    setSelected(0);
  };

  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>
        {label}
        {required ? ' *' : ''}
      </Form.Label>
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix={disabled ? 'dropdown-disabled' : 'dropdown-toggle'}
          className="w-100 text-left form-control"
          disabled={disabled}
          variant="outline-select"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{
              backgroundColor: selected !== 0 ? '#2C4474' : '#2C4474',
              color: selected !== 0 ? '#fff' : '#fff',
              padding: '2px 4px',
              borderRadius: '2px',
              display: 'inline-flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {choices[selected] || selected}
            {/* { ( */}
            <X
              onClick={(e) => {
                e.stopPropagation();
                handleClearSelection();
              }}
              style={{ cursor: 'pointer' }}
              size={16}
            />
            {/* )} */}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
          {choices.map((currentChoice, choiceIndex) => (
            <Dropdown.Item key={currentChoice} onClick={() => setSelected(choiceIndex)}>
              {currentChoice}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {Boolean(errors) && typeof errors === 'string' && (
        <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
      )}
      {text && <Form.Text muted>{text}</Form.Text>}
    </Form.Group>
  );
}
