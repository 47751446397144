import { Grid, InputLabel, Stack, Typography } from '@mui/material';

import dayjs from 'dayjs';

import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';
import DateField from 'components/applications/EditForm/DateField';
import SelectField from 'shared/muiComponents/SelectField';

import { createReport } from 'services/awards/awardReportsService';

import { tossError } from 'utils/toastTosser';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AwardReport } from 'types/awards';
import useCurrentAward from 'hooks/useCurrentAward';
import { camelCaseToWords } from 'utils/utilFunctions';
import { useEffect } from 'react';
import MountedModal from 'shared/muiComponents/MountedModal';
import InputField from 'shared/muiComponents/InputField';
import PaymentSelect from './PaymentSelect';

interface NewReportModalProps {
  initialData?: AwardReport | null;
  toggle: () => void;
  refetchReports: () => void;
}

export default function NewReportModal(props: NewReportModalProps) {
  const { initialData, toggle, refetchReports } = props;

  const { currentAwardId, refetchAwardAndTimeline } = useCurrentAward();

  const initialValues: Partial<AwardReport> = {
    name: initialData?.name || dayjs().utc().format('YYYY-MM-DD'),
    startsOn: initialData?.startsOn ? dayjs(initialData.startsOn).utc().format('YYYY-MM-DD') : '',
    endsOn: initialData?.endsOn ? dayjs(initialData.endsOn).utc().format('YYYY-MM-DD') : '',
    dueDate: initialData?.dueDate ? dayjs(initialData.dueDate).utc().format('YYYY-MM-DD') : '',
    type: initialData?.type || 'financial',
    paymentId: 0,
  };

  const formik = useFormik<Partial<AwardReport>>({
    initialValues,

    validationSchema: Yup.object({
      type: Yup.string().required('Please enter a type'),
      startsOn: Yup.date()
        // .nullable()
        .max(Yup.ref('endsOn'), `Start Date must end before the End Date`)
        // .max(Yup.ref('dueDate'), `Start Date must come before the Due Date`)
        .optional(),
      endsOn: Yup.date()
        // .nullable()
        .min(Yup.ref('startsOn'), `End Date must begin after the Start Date`)
        // .min(Yup.ref('dueDate'), `End Date must come after the Due Date`)
        .optional(),
      dueDate: Yup.date()
        // .nullable()
        // // .min(Yup.ref('startsOn'), `Due Date must come after Start Date`)
        // .max(Yup.ref('endsOn'), `Due Date must come before the End Date`)
        .required('Due Date is a required field'),
    }),

    onSubmit: async (values) => {
      const result = await createReport(currentAwardId, {
        ...values,
        startsOn: dayjs(values.startsOn).utc().format('YYYY-MM-DD'),
        endsOn: dayjs(values.endsOn).utc().format('YYYY-MM-DD'),
        dueDate: dayjs(values.dueDate).utc().format('YYYY-MM-DD'),
        paymentId: values.paymentId || undefined,
      });

      if (result) {
        refetchReports();
        refetchAwardAndTimeline();
        toggle();
      } else tossError('Error creating report');
    },
  });

  const { values, errors, isValid, validateField, touched, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    if (values.type === 'programmatic') setFieldValue('paymentId', 0);
  }, [values]);

  // Uncomment the following lines to see the values, errors, and touched objects to debug
  // console.log('isValid', isValid);
  // console.log('errors', errors);
  // console.log('errors', touched);

  const onBlur = () => {
    if (touched.startsOn) validateField('startsOn');
    if (touched.endsOn) validateField('endsOn');
    if (touched.dueDate) validateField('dueDate');
  };

  return (
    <MountedModal maxWidth="640px" onClose={toggle}>
      <Typography my={2} variant="h2">
        New Reporting Period
      </Typography>

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <InputField
            id="name"
            label="Name"
            onChange={(value) => setFieldValue('name', value)}
            value={values.name}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <DateField
            controlId="startDate"
            errors={errors?.startsOn || ''}
            inputClass="mui-datepicker"
            label="Start Date"
            labelClass="mui-datepicker-label"
            onBlur={onBlur}
            onChange={(newValue) =>
              setFieldValue('startsOn', dayjs(newValue).add(1, 'day').format('YYYY-MM-DD'))
            }
            required
            value={values.startsOn}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <DateField
            controlId="endDate"
            errors={errors?.endsOn || ''}
            inputClass="mui-datepicker"
            label="End Date"
            labelClass="mui-datepicker-label"
            onBlur={onBlur}
            onChange={(newValue) =>
              setFieldValue('endsOn', dayjs(newValue).add(1, 'day').format('YYYY-MM-DD'))
            }
            required
            value={values.endsOn}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <DateField
            controlId="dueDate"
            errors={errors.dueDate}
            inputClass="mui-datepicker"
            label="Report Due Date"
            labelClass="mui-datepicker-label"
            onBlur={onBlur}
            onChange={(newValue) =>
              setFieldValue('dueDate', dayjs(newValue).add(1, 'day').format('YYYY-MM-DD'))
            }
            required
            value={values.dueDate}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <SelectField
            errorMessage={errors.type}
            handleChange={(value) => setFieldValue('type', value.toLowerCase())}
            id="type"
            label="Report Type"
            options={['Financial', 'Programmatic', 'Combined']}
            required
            value={camelCaseToWords(values.type || '')}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Link Report to Payment</InputLabel>

          {values.type !== 'programmatic' && (
            <PaymentSelect
              // disabled={values.type === 'programmatic'}
              onChange={(newValue) => setFieldValue('paymentId', Number(newValue))}
              selected={values.paymentId || 0}
            />
          )}

          <Typography component="div" my={2} variant="caption">
            {values.type === 'programmatic'
              ? 'No payment can be added to programmatic reports'
              : 'Note: Only existing award payments can be linked to this report.'}
          </Typography>
        </Grid>
      </Grid>

      <Stack alignItems="center" direction="row" justifyContent="end" m={2} spacing={2}>
        <SecondaryButton onClick={toggle}>Cancel</SecondaryButton>

        <StandardButton disabled={!values.type || !values.dueDate} onClick={handleSubmit} startIcon>
          Create
        </StandardButton>
      </Stack>
    </MountedModal>
  );
}
