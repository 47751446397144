import { createContext, useContext, useState } from 'react';
import { Program } from 'types/program';

interface ProgramContextType {
  createProgramProps: {
    disabled: boolean;
    onCreation: () => void;
    readOnly: boolean;
  };
  setCreateProgramProps: (props: {
    disabled: boolean;
    onCreation: () => void;
    readOnly: boolean;
  }) => void;
  programDetailsProps: {
    program: Program;
    onSave: () => void;
    readList: (userId: number) => Promise<void>;
    readOnly: boolean;
    disabled: boolean;
  };
  setProgramDetailsProps: (props: {
    program: Program;
    onSave: () => void;
    readList: (userId: number) => Promise<void>;
    readOnly: boolean;
    disabled: boolean;
  }) => void;
  clientCreationProps: {
    onCreation: () => void;
  };
  setClientCreationProps: (props: { onCreation: () => void }) => void;
}

const ProgramContext = createContext<ProgramContextType | undefined>(undefined);

export const useProgramContext = () => {
  const context = useContext(ProgramContext);
  if (!context) {
    throw new Error('useProgramContext must be used within a ProgramProvider');
  }
  return context;
};

export function ProgramProvider({ children }: { children: React.ReactNode }) {
  const [createProgramProps, setCreateProgramProps] = useState({
    disabled: false,
    onCreation: () => {},
    readOnly: false,
  });

  const [programDetailsProps, setProgramDetailsProps] = useState({
    program: null,
    onSave: () => {},
    readList: async () => {},
    readOnly: false,
    disabled: false,
  });

  const [clientCreationProps, setClientCreationProps] = useState({
    onCreation: () => {},
  });

  return (
    <ProgramContext.Provider
      value={{
        createProgramProps,
        setCreateProgramProps,
        programDetailsProps,
        setProgramDetailsProps,
        clientCreationProps,
        setClientCreationProps,
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
}
