import { Box, Stack, Typography } from '@mui/material';
import { toArray } from 'utils/utilFunctions';
import useSearchParams from 'hooks/useSearchParams';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';
import useUserSession from 'hooks/useUserSession';
import { states } from 'constants/globalConstants';

const allowedReportFilters = {
  c: 'Client',
  f: 'Funder',
  a: 'Assigned To',
  s: 'State',
  sd: 'Start Date',
  ed: 'End Date',
  edt: 'Date Type',
} as const;

const getDefaultFilters = (isMillenniumUser: boolean) => {
  const todayDate = dayjs().format('YYYY-MM-DD');
  const startOfYearDate = dayjs().startOf('y').format('YYYY-MM-DD');

  return {
    startDate: isMillenniumUser ? startOfYearDate : null,
    endDate: isMillenniumUser ? todayDate : null,
    endDateType: 'Due Date',
  };
};

export default function UsedReportsFiltersDescriptionBlock(): JSX.Element {
  const { searchParams } = useSearchParams();
  const location = useLocation();
  const currentUser = useUserSession();
  const defaultFilters = getDefaultFilters(currentUser.isMillenniumUser);

  const usedFilters: { key: keyof typeof allowedReportFilters; values: string }[] = [];

  Object.entries(allowedReportFilters).forEach(([key, label]) => {
    const filterValues = toArray(searchParams[key]);

    if (filterValues.length > 0) {
      const isDefaultValue =
        (key === 'c' && filterValues.join(', ') === defaultFilters.clients?.join(', ')) ||
        (key === 'f' && filterValues.join(', ') === defaultFilters.funders?.join(', ')) ||
        (key === 'a' && filterValues.join(', ') === defaultFilters.users?.join(', ')) ||
        (key === 's' && filterValues.join(', ') === defaultFilters.state) ||
        (key === 'sd' && filterValues.join(', ') === defaultFilters.startDate) ||
        (key === 'ed' && filterValues.join(', ') === defaultFilters.endDate) ||
        (key === 'edt' && filterValues.join(', ') === defaultFilters.endDateType);

      if (!isDefaultValue) {
        let displayValue = filterValues.join(', ');

        if (key === 'c') {
          const cleanedValues = filterValues.map((value) => {
            return value
              .replace(/undefined\|/g, '')
              .replace(/NaN\|/g, '')
              .replace(/%2C/g, ', ');
          });
          displayValue = cleanedValues.join(', ');
        }

        if (key === 'a') {
          const splitValues = filterValues.flatMap((value) => value.split('|'));
          const uniqueValues = [...new Set(splitValues)];
          displayValue = uniqueValues.join(', ');
        }

        if (key === 's') {
          const stateValue = filterValues[0];
          if (stateValue === 'all') {
            displayValue = 'All States';
          } else {
            const state = states.find((s) => s.abbr === stateValue);
            displayValue = state ? state.name : stateValue;
          }
        }

        usedFilters.push({ key: key as keyof typeof allowedReportFilters, values: displayValue });
      }
    }
  });

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" gap={3} mb={2} mt={2}>
      {usedFilters.map((filter) => (
        <Stack key={filter.key} alignItems="center" direction="row" gap={2}>
          <Typography m={0} variant="body1">{`${allowedReportFilters[filter.key]}:`}</Typography>
          <Typography sx={{ color: 'grey' }} variant="body1">
            {filter.values}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
}
