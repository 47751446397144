import { forwardRef, useState } from 'react';

import { tossError, tossSuccess } from 'utils/toastTosser';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { createFilter, searchFilters } from 'services/filterService';

import { Filter, FilterData } from 'types/filter';

interface CreateModalProps {
  open: boolean;
  moduleId: number;
  filters: FilterData;
  onClose: () => void;
  onCreate: (filter: Filter) => void;
}

export default forwardRef(
  ({ open = false, moduleId, onClose, onCreate, filters }: CreateModalProps, ref) => {
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [name, setName] = useState<string | null>(null);

    const close = () => {
      setValid(false);
      setInvalid(false);
      setName(null);
      onClose();
    };

    const create = async () => {
      const filterFields = {
        title: name || '',
        moduleId,
        filters,
      };

      const newFilter = await createFilter(filterFields);

      if (newFilter) {
        onCreate({
          ...newFilter,
          name: newFilter.title,
        });
        tossSuccess('A new Filter Set created successfully!');
        close();
      } else tossError('Error creating a new Filter Set.');
    };

    const onInputChange = (newValue: string) => {
      if (newValue.length === 0) {
        setValid(false);
        setInvalid(false);
        setName(newValue);
      }
    };

    const onChange = async (query: string) => {
      // onInputChange handles empty query string.
      setLoading(true);

      const response = await searchFilters(moduleId, query, 'title');
      const exacts = response.filter(({ title }) => title === query);

      setValid(query.length > 0 && exacts.length === 0);
      setInvalid(exacts.length > 0);
      setName(query);
      setLoading(false);
    };

    return (
      <Modal ref={ref} centered className="bg-filter-set-create-modal" onHide={close} show={open}>
        <Modal.Header closeButton>
          <Modal.Title>Create Filter Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Choose a <strong>unique</strong> title for new Filter Set:
          </span>
          <br />
          <AsyncTypeahead
            id="filterSetCreator"
            isInvalid={invalid}
            isLoading={loading}
            isValid={valid}
            minLength={1}
            onInputChange={onInputChange}
            onSearch={onChange}
            options={[]}
            placeholder="Choose title for the new Filter Set"
            renderMenu={() => null}
            useCache={false}
          />
          <br />
          <Button
            disabled={loading || invalid || (!valid && !invalid)}
            onClick={create}
            variant="save"
          >
            Create
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
);
