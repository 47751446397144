import { useContext, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import sum from 'lodash.sum';
import isNil from 'lodash.isnil';
import sumBy from 'lodash.sumby';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { StringsContext } from 'index';
import { formatCurrencyValue } from 'utils/utilFunctions';

import { IOverviewData } from 'pages/Reports';
import 'assets/scss/Reports.scss';

export default function OverviewData({ data }: { data: IOverviewData[] }) {
  const {
    applications: { sourceNames },
  } = useContext(StringsContext);

  const [showSection, setShowSection] = useState(true);

  const findTotalAverageAmount = () => {
    const totalAwarded = sumBy(data, 'totalAwards');
    const totalAwardedValue = sumBy(data, 'totalAwardsValue');
    const totalMean = totalAwardedValue / totalAwarded;

    return Number.isNaN(totalMean) ? null : totalMean;
  };

  const findTotalSuccessRate = () => {
    const totalAwarded = sumBy<IOverviewData>(data, 'totalAwards');
    const totalRejected = sumBy<IOverviewData>(data, 'appsRejected');

    const totalDecided = totalAwarded + totalRejected;

    return totalDecided ? (totalAwarded / totalDecided) * 100 : null;
  };

  function sourceFormatter(cell: number) {
    return sourceNames[cell] ?? cell;
  }

  function awardsValueFormatter(cell: number) {
    return formatCurrencyValue(cell);
  }

  function averageAwardFormatter(cell: number | null) {
    return isNil(cell) ? '—' : formatCurrencyValue(cell);
  }

  function successRateFormatter(cell: number | null) {
    return isNil(cell) ? '—' : `${cell.toFixed(1)}%`;
  }

  const columns = [
    {
      dataField: 'source',
      text: 'Source',
      formatter: sourceFormatter,
      classes: 'custom-first-column',
      footer: 'Totals',
    },
    {
      dataField: 'appsSubmitted',
      text: 'Apps Submitted',
      footer: (columnData: number[]) => String(sum(columnData)),
    },
    {
      dataField: 'totalAwards',
      text: 'Total Awards',
      footer: (columnData: number[]) => String(sum(columnData)),
    },
    {
      dataField: 'totalAwardsValue',
      text: 'Total Awards Value',
      formatter: awardsValueFormatter,
      footer: (columnData: number[]) => awardsValueFormatter(sum(columnData)),
    },
    {
      dataField: 'averageAward',
      text: 'Average Award Amount',
      formatter: averageAwardFormatter,
      footer: () => averageAwardFormatter(findTotalAverageAmount()),
    },
    {
      dataField: 'successRate',
      text: 'Success Rate',
      formatter: successRateFormatter,
      footer: () => successRateFormatter(findTotalSuccessRate()),
    },
  ];

  return (
    <div className="w-100 section-wrapper">
      <div
        className="hide-show-wrapper"
        onClick={() => setShowSection(!showSection)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setShowSection(!showSection);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <h5 className="section-heading">
          Overview Data
          <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
            {showSection ? (
              <>
                <KeyboardArrowUp className="ms-2" style={{ color: '#2C4474', fontSize: '18px' }} />
                <span className="show-hide-text">Hide Section</span>
              </>
            ) : (
              <>
                <KeyboardArrowDown
                  className="ms-2"
                  style={{ color: '#2C4474', fontSize: '18px' }}
                />{' '}
                <span className="show-hide-text">Show Section</span>
              </>
            )}
          </span>
        </h5>
      </div>

      {showSection && (
        <div style={{ marginTop: '32px' }}>
          <BootstrapTable
            bootstrap4
            columns={columns}
            data={data}
            keyField="id"
            remote={{
              filter: true,
            }}
            striped
            wrapperClasses="table-responsive table-borderless"
          />
        </div>
      )}
    </div>
  );
}
