import Form from 'react-bootstrap/Form';
import FileUploader from '../FileUploader';

interface FileInputProps {
  controlId: unknown;
  disabled: unknown;
  group: unknown;
  filesUploaded: unknown;
  label: unknown;
  text: unknown;
  required: unknown;
  onUploadStart: unknown;
  onUploadEnd: unknown;
  onFileAppend: unknown;
  onDownload: unknown;
}

export default function FileInput({
  controlId,
  disabled = false,
  group = true,
  filesUploaded = null,
  label,
  text,
  required = false,
  onUploadStart = null,
  onUploadEnd = null,
  onFileAppend = null,
  onDownload = null,
}: FileInputProps) {
  const deleteFile = () => {
    if (onFileAppend) onFileAppend(null);
  };

  const unregisterFile = () => {
    if (onUploadEnd) onUploadEnd();
  };

  const registerFile = () => {
    if (onUploadStart) onUploadStart();
  };

  const appendFile = (fileMeta) => {
    unregisterFile();
    if (onFileAppend) onFileAppend(fileMeta.name);
  };

  return group ? (
    <Form.Group controlId={controlId}>
      <Form.Label>
        {label}
        {required ? ' *' : ''}
      </Form.Label>
      <FileUploader
        disabled={disabled}
        filesUploaded={filesUploaded}
        maxFiles="1"
        onDownload={onDownload}
        uploadURL={`${process.env.REACT_APP_API_URL}/file`}
        whenDone={appendFile}
        whenError={unregisterFile}
        whenRemoved={deleteFile}
        whenStarted={registerFile}
      />

      <Form.Text>{text}</Form.Text>
    </Form.Group>
  ) : (
    <>
      <Form.Label>
        {label}
        {required ? ' *' : ''}
      </Form.Label>
      <FileUploader
        disabled={disabled}
        filesUploaded={filesUploaded}
        maxFiles="1"
        uploadURL={`${process.env.REACT_APP_API_URL}/upload`}
        whenDone={appendFile}
        whenError={unregisterFile}
        whenStarted={registerFile}
      />

      <Form.Text>{text}</Form.Text>
    </>
  );
}
