import { useCallback, useEffect, useState } from 'react';

import { Select, MenuItem, FormControl } from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';

import { AwardPayments } from 'types/awards';

import useCurrentAward from 'hooks/useCurrentAward';
import { getAwardPayments } from 'services/awards/awardPaymentsService';
import { camelCaseToWords, formatCurrencyValue } from 'utils/utilFunctions';

interface FilterSelectProps {
  onChange: (value?: number) => void;
  selected: number;
  disabled?: boolean;
}

export default function PaymentSelect({ onChange, selected, disabled }: FilterSelectProps) {
  const { currentAwardId } = useCurrentAward();

  const [payments, setPayments] = useState<AwardPayments[]>([]);

  const fetchPayments = useCallback(async () => {
    if (currentAwardId) {
      const result = await getAwardPayments(currentAwardId, '');

      if (result && result?.rows?.length)
        setPayments(result.rows.filter((payment) => !payment.reportId || payment.id === selected));
    }
  }, []);

  useEffect(() => {
    fetchPayments();
  }, []);

  return (
    <FormControl
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: (theme) =>
            !selected
              ? `1px solid ${theme.palette.secondary.light}`
              : `2px solid ${theme.palette.primary.light}`,
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: (theme) => `2px solid ${theme.palette.secondary.main}`,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: (theme) => `2px solid ${theme.palette.primary.main}`,
        },
        '& .MuiInputLabel-outlined': {
          fontSize: '0.8rem',
          p: '2px',
          fontWeight: 500,
          lineHeight: '0.4rem',
        },
        '& .MuiInputLabel-shrink, .MuiOutlinedInput-notchedOutline': {
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1.1rem',
        },
      }}
    >
      <Select
        disabled={disabled}
        IconComponent={ExpandMoreRounded}
        onChange={(event) => onChange(Number(event.target.value))}
        size="small"
        sx={{
          '& .MuiOutlinedInput-input': {
            padding: '12px 10px 7px 10px',
          },
          // height: '40px',
          fieldset: { borderRadius: 0 },
          '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-root': {
              minHeight: '40px',
            },
          },
        }}
        value={selected}
      >
        <MenuItem divider selected value={0}>
          Payment ID: amount, type, status
        </MenuItem>

        {payments?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {`${option.id}: ${formatCurrencyValue(option.amount)}, ${camelCaseToWords(
              option.type
            )}, ${option.status ? camelCaseToWords(option.status) : 'Not paid'}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
