import { useEffect, useState } from 'react';
import { Box, Grid, InputLabel, Link, Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';

import SearchFilter from 'shared/muiComponents/SearchFilter';
import { User } from 'types/user';
import { searchUsers } from 'services/userService';
import DateField from 'components/applications/EditForm/DateField';
import { Award } from 'types/awards';
import { searchFunders } from 'services/funderService';
import { searchClients } from 'services/clientService';
import { searchPrograms } from 'services/programService';
import { searchApplications, getApplication } from 'services/applicationService';
import { Client } from 'types/client';
import { Application, NewApplication } from 'types/application';
import { applicationsConfigs } from 'constants/globalConstants';
import SelectField from 'shared/muiComponents/SelectField';
import InputField from 'shared/muiComponents/InputField';

import AwardStatusChip from 'components/awards/AwardStatusChip';
import useUserSession from 'hooks/useUserSession';
import { Program } from 'types/program';

function Summary({
  awardEditForm,
  isNewAwardCreation,
  setUnsavedChanges,
}: {
  awardEditForm: FormikProps<Partial<Award>>;
  isNewAwardCreation: boolean;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}) {
  const { userType } = useUserSession();
  const { values, setFieldValue, errors } = awardEditForm;
  const [selectedAssignee, setSelectedAssignee] = useState<User | null>(values.assignee ?? null);
  const [selectedClient, setSelectedClient] = useState<Client | null>(values.client ?? null);
  const [selectedFunder, setSelectedFunder] = useState<string | null>(values.funder ?? null);
  const [selectedGrantProgramName, setSelectedGrantProgramName] = useState<string | null>(
    values.grantProgramName ?? null
  );
  const [selectedDepartment, setSelectedDepartment] = useState<Application | null>(null);

  // useEffect(() => {
  //   // This effect will run whenever the `values` prop changes
  //   // You can add any additional logic here if needed
  // }, [values, awardEditForm.values, awardEditForm]);

  // TODO: Only activate when a field changes
  // setUnsavedChanges(true);
  useEffect(() => {
    if (awardEditForm.values.department) {
      setSelectedDepartment({ departmentNotified: awardEditForm.values.department });
    } else {
      setSelectedDepartment(null);
    }
  }, [awardEditForm.values.department]);

  useEffect(() => {
    if (awardEditForm.values.assigneeId === awardEditForm.values.assignee?.id)
      setSelectedAssignee(awardEditForm.values.assignee ?? null);
    if (awardEditForm.values.clientId === awardEditForm.values.client?.id)
      setSelectedClient(awardEditForm.values.client ?? null);

    if (awardEditForm.values.funder) setSelectedFunder(awardEditForm.values.funder ?? null);

    if (awardEditForm.values.grantProgramName) {
      setSelectedGrantProgramName(awardEditForm.values.grantProgramName ?? null);
    }
  }, [
    awardEditForm.values.assigneeId,
    awardEditForm.values.clientId,
    awardEditForm.values.funder,
    awardEditForm.values.grantProgramName,
  ]);

  useEffect(() => {
    if (selectedAssignee?.id) setFieldValue('assigneeId', selectedAssignee.id);
    if (selectedClient?.id) setFieldValue('clientId', selectedClient.id);
    if (selectedFunder) setFieldValue('funder', selectedFunder);
    if (selectedGrantProgramName) setFieldValue('grantProgramName', selectedGrantProgramName);
    if (selectedDepartment?.departmentNotified)
      setFieldValue('department', selectedDepartment.departmentNotified);

    setFieldValue('category', awardEditForm.values?.category || 0);
    setFieldValue('source', awardEditForm.values?.source || 0);
  }, [
    selectedAssignee,
    selectedClient,
    selectedFunder,
    selectedGrantProgramName,
    selectedDepartment,
  ]);

  const canEditAward = userType !== 'millenniumAnalyst' && userType !== 'millenniumResearcher';
  const isClientUser = userType === 'userAdmin' || userType === 'userAnalyst';
  const searchDepartments = async (input: string) => {
    const applications = await searchApplications(input, 'departmentNotified');
    const applicationsWithDepartments = await Promise.all(
      applications.map(async (app: Application) => {
        const fullAppDetails = await getApplication(app?.id);
        return {
          id: app.id,
          departmentNotified: fullAppDetails?.departmentNotified || 'Unknown Department',
        };
      })
    );
    return applicationsWithDepartments;
  };

  return (
    <Box my={4}>
      <Typography color="primary.main" mb={2} variant="h2">
        Award Summary
      </Typography>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="assignee">Assignee</InputLabel>
            <SearchFilter<User, false>
              getLabel={(f) => f.name}
              label=""
              multiple={false}
              onChange={setSelectedAssignee}
              readOnly={!canEditAward}
              searchFunction={async (input: string) => searchUsers(input, 'name')}
              selected={selectedAssignee}
            />
          </Stack>
        </Grid>

        <Grid item md={6} xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="department">Department</InputLabel>
            <SearchFilter<Application, false>
              getLabel={(d) => d.departmentNotified}
              label=""
              multiple={false}
              onChange={setSelectedDepartment}
              readOnly={!canEditAward}
              searchFunction={searchDepartments}
              selected={selectedDepartment}
            />
          </Stack>
        </Grid>

        <Grid item md={6} xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="funder" required>
              Funder
            </InputLabel>
            <SearchFilter<string, false>
              getLabel={(f) => f}
              label=""
              multiple={false}
              onChange={setSelectedFunder}
              readOnly={!canEditAward}
              searchFunction={async (input: string) =>
                (await searchFunders(`field=name&source=awards&query=${input}`)).map(
                  (f) => f.funder
                )
              }
              selected={selectedFunder}
            />
          </Stack>
        </Grid>

        <Grid item md={6} xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="grantProgramName" required>
              Grant Program Name
            </InputLabel>
            <SearchFilter<Partial<Program>, false>
              getLabel={(f) => f.name || ''}
              label=""
              multiple={false}
              onChange={(f) => {
                setSelectedGrantProgramName(f?.name || null);
                setFieldValue('grantProgramName', f?.name || null);
                setFieldValue('category', f?.category || null);
                setFieldValue('source', f?.source || null);
              }}
              readOnly={!canEditAward}
              searchFunction={async (input: string) =>
                (await searchPrograms(input, 'name', selectedFunder || values.funder || null)).map(
                  (f) => f
                )
              }
              selected={{ name: selectedGrantProgramName || values.grantProgramName || '' }}
            />
          </Stack>
        </Grid>

        <Grid item md={6} xs={12}>
          <DateField
            controlId="startDate"
            disabled={!canEditAward}
            errors={errors.startsOn}
            inputClass="mui-datepicker"
            label="Grant Period Start Date"
            labelClass="mui-datepicker-label"
            onChange={(newValue) => newValue && setFieldValue('startsOn', newValue)}
            required
            value={values.startsOn || ''}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <DateField
            controlId="endDate"
            disabled={!canEditAward}
            errors={errors.endsOn}
            inputClass="mui-datepicker"
            label="Grant Period End Date"
            labelClass="mui-datepicker-label"
            onChange={(newValue) => newValue && setFieldValue('endsOn', newValue)}
            required
            value={values.endsOn}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <SelectField
            disabled={!canEditAward}
            handleChange={(value) =>
              setFieldValue('source', applicationsConfigs.sources.indexOf(value))
            }
            id="source"
            label="Source"
            options={applicationsConfigs.sources}
            required
            value={applicationsConfigs.sources[values.source || 0]}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <SelectField
            disabled={!canEditAward}
            handleChange={(value) =>
              setFieldValue('category', applicationsConfigs.categories.indexOf(value))
            }
            id="category"
            label="Category"
            options={applicationsConfigs.categories}
            required
            value={applicationsConfigs.categories[values.category || 0]}
          />
        </Grid>

        {!isClientUser && (
          <Grid item md={6} xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="client" required>
                Client
              </InputLabel>
              <SearchFilter<Client, false>
                getLabel={(f) => f.name}
                label=""
                multiple={false}
                onChange={setSelectedClient}
                readOnly={!canEditAward}
                searchFunction={async (input: string) => searchClients(input, 'name')}
                selected={selectedClient}
              />
            </Stack>
          </Grid>
        )}

        {!isNewAwardCreation && (
          <Grid item md={isClientUser ? 6 : 3} xs={isClientUser ? 12 : 4}>
            <Stack spacing={1}>
              <Typography variant="body1">Status</Typography>
              <AwardStatusChip height="40px" status={values.status || ''} />
            </Stack>
          </Grid>
        )}

        <Grid item md={6} xs={12}>
          <InputField
            id="grantPurpose"
            label="Grant Purpose"
            onChange={(value) => setFieldValue('grantPurpose', value)}
            readOnly={!canEditAward}
            value={values.grantPurpose}
          />
        </Grid>

        {!isNewAwardCreation && awardEditForm.values?.application?.id && (
          <Grid item md={6} xs={12}>
            <Stack direction="column" height={1} justifyContent="space-around">
              <Typography mb={1} variant="body1">
                Application Link
              </Typography>

              <Link
                color="primary.main"
                fontWeight={600}
                href={`/dashboard/applications/edit/${awardEditForm.values?.application?.id}`}
                pb="10px"
                variant="body2"
              >
                {awardEditForm.values?.application?.name}
              </Link>
            </Stack>
          </Grid>
        )}

        <Grid item md={6} xs={12}>
          <InputField
            id="funderAwardId"
            label="Funder Award ID"
            onChange={(value) => setFieldValue('funderAwardId', value)}
            readOnly={!canEditAward}
            value={values.funderAwardId}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <InputField
            id="awardPortalLink"
            label="Award Portal Link"
            onChange={(value: string | null) => setFieldValue('awardPortalLink', value)}
            placeholder="http://..."
            readOnly={!canEditAward}
            value={values.awardPortalLink}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Summary;
