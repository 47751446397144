import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { Plus, Check } from 'react-feather';
import dayjs from 'dayjs';
import Dropzone, { IFileWithMeta, IUploadParams, StatusValue } from 'react-dropzone-uploader';
import { tossError, tossSuccess } from 'utils/toastTosser';
import useKeyPress from 'hooks/useKeyPress';
import {
  SelectInput,
  MoneyInput,
  TextInput,
  FunderInput,
  AssigneeInput,
  ClientInput,
  NotesStatusInput,
} from 'shared/inputs';
import useUserSession from 'hooks/useUserSession';
import DateField from 'components/applications/EditForm/DateField';
import CustomFields from 'shared/CustomFields';
import { createApplication } from 'services/applicationService';
import api from 'services/apiService';
import { nanoid } from 'nanoid';
import { applicationsConfigs } from 'constants/globalConstants';
import FileCard from 'shared/FileCard';

export default function CreateApplication({ prefillOptions, disabled = false, onCreation }) {
  const currentUser = useUserSession();
  const dropzoneRef = useRef<HTMLDivElement>(null); // Correct type: HTMLInputElement
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const enterPress = useKeyPress('Enter');
  const escPress = useKeyPress('Esc');
  const tempApplication = parseInt(nanoid(6).replace(/\D/g, ''), 10);
  const [tempApplicationId, setTempApplicationId] = useState(tempApplication || 0);

  const setField = (field, value) => setForm((prevState) => ({ ...prevState, [field]: value }));

  const toggle = () => setOpen((prevState) => !prevState);

  const save = async () => {
    setIsLoading(true);
    const { id: programId, summaryData } = prefillOptions || {};

    const {
      assignee,
      client,
      name,
      funder,
      fundingAmount,
      varyingFundingAmount,
      amountVaries,
      source,
      category,
      startDate,
      dueDate,
      matchRequirements,
      performancePeriod,
      estimatedResponse,
      customFields,
      departmentNotified,
      notesStatus,
    } = form;

    const submittedFields = {
      programId: programId ?? null,
      assigneeId: currentUser.userType === 'millenniumAdmin' ? assignee : currentUser.id,
      clientId: client || null,
      name,
      funder,
      fundingAmount: fundingAmount ?? 0.0,
      varyingFundingAmount,
      amountVaries: amountVaries ?? false,
      source,
      startsAt: startDate,
      endsAt: dueDate,
      matchRequirements,
      performancePeriod,
      status: '1',
      summaryFile: summaryData ?? null,
      estimatedResponse,
      customFields: customFields ?? [],
      category: applicationsConfigs.categories.indexOf(
        applicationsConfigs.alphabetSortedCategories[category]
      ),
      departmentNotified,
      submissionStatus: notesStatus,
    };

    try {
      const result = await createApplication({
        ...submittedFields,
        user: currentUser,
      });

      if (result) {
        if (files.length > 0) {
          await api.put('/applications/updatefile', {
            tempApplicationId,
            applicationId: result.id,
          });
        }

        tossSuccess('The application was successfully created.');
        setForm({});
        setFiles([]);
        toggle();
        if (onCreation) onCreation();
      } else {
        tossError('Error creating the application.');
      }
    } catch (error) {
      tossError('Error creating the application.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickUpload = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (dropzoneRef.current) {
      const inputElement = dropzoneRef.current.querySelector('input[type="file"]');
      if (inputElement) {
        inputElement.click();
      } else {
        console.error('Could not find file input element.');
      }
    }
  };
  const getUploadParams = (fileWithMeta: IFileWithMeta) => {
    const body = new FormData();
    body.append('applicationId', tempApplicationId.toString());
    body.append('file', fileWithMeta.file);
    body.append('size', fileWithMeta.meta.size.toString());
    body.append('name', fileWithMeta.meta.name);
    body.append('fileUrl', fileWithMeta.meta.previewUrl || '');
    body.append('type', fileWithMeta.meta.type);

    return {
      url: `${process.env.REACT_APP_API_URL}/applications/uploadfile`,
      body,
      headers: {
        Authorization: api.client?.defaults.headers.common.Authorization,
      },
    } as IUploadParams;
  };

  const handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      fileWithMeta.remove();
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          id: fileWithMeta.meta.id,
          name: fileWithMeta.meta.name,
          size: fileWithMeta.meta.size,
          type: fileWithMeta.meta.type,
        },
      ]);
    } else if (status === 'error_upload_params') {
      tossError('Error uploading file. Please try again.');
    }
  };

  useEffect(() => {
    if (prefillOptions) {
      setField('startDate', prefillOptions.startsAt);
      setField('dueDate', prefillOptions.endsAt);
    }
  }, [prefillOptions]);

  useEffect(() => {
    const {
      name,
      funder,
      fundingAmount,
      amountVaries,
      source,
      category,
      startDate,
      dueDate,
      assignee,
      client,
    } = form;

    const errorList = {};

    if (typeof name !== 'undefined') {
      if (!name || name === '') errorList.name = 'This field is required.';
      else if (name.length < 3)
        errorList.name = 'Grant program names must have 3 or more characters.';
    }

    if (typeof startDate !== 'undefined' && typeof dueDate !== 'undefined') {
      if (startDate && dueDate) {
        if (dayjs(dueDate).isBefore(startDate)) {
          errorList.startDate = 'Start Date must be earlier than Due Date.';
          errorList.dueDate = 'Due Date must be later than Start Date.';
        }
      }
    }

    if (Number.isNaN(fundingAmount) && !amountVaries)
      errorList.fundingAmount = 'Enter a valid funding amount.';

    if (!assignee) errorList.assignee = 'Please pick an assignee.';

    if (!client) errorList.client = 'Please pick a client.';

    if (!funder) errorList.funder = 'Please pick a funder.';

    if (
      [
        typeof name,
        typeof funder,
        typeof fundingAmount,
        typeof source,
        typeof category,
        typeof assignee,
        typeof client,
      ].includes('undefined')
    )
      errorList.undefineds = 'One or more required fields are undefined.';

    setErrors(errorList);
  }, [form]);

  useEffect(() => {
    if (!enterPress && open) {
      if (Object.keys(errors).length === 0) save();
    }
  }, [enterPress]);

  useEffect(() => {
    if (!escPress && open) {
      toggle();
    }
  }, [escPress]);

  useEffect(() => {
    if (!open) {
      setField('startDate', undefined);
      setField('dueDate', undefined);
      setFiles([]);
    }
  }, [open]);

  return (
    <>
      <Button
        className="d-flex justify-content-center align-items-center"
        disabled={disabled || isLoading}
        onClick={toggle}
        variant="primary"
      >
        <Plus size={16} style={{ marginBottom: '2px' }} />
        &nbsp;Create Application
      </Button>

      <Modal centered onHide={toggle} show={open} size="xl">
        <Modal.Header closeButton>Add New Application</Modal.Header>

        <Modal.Body>
          <Form className="add-app-form">
            <Container fluid>
              <h5>
                <strong>General Fields</strong>
              </h5>
              <hr />

              <Row>
                <Col lg={3} xs={12}>
                  <AssigneeInput
                    controlId="createApplication.Assignee"
                    onChange={(newValue) => setField('assignee', newValue)}
                  />
                </Col>

                <Col lg={9} xs={12} />

                <Col lg={3} xs={12}>
                  <TextInput
                    controlId="createApplication.Name"
                    defaultValue={prefillOptions ? prefillOptions.name : null}
                    errors={errors.name}
                    label="Grant Program Name"
                    onChange={(newValue) => setField('name', newValue)}
                    placeholder="My Grant Program"
                    required
                  />
                </Col>

                <Col lg={3} xs={6}>
                  <FunderInput
                    controlId="createApplication.Funder"
                    defaultValue={prefillOptions ? prefillOptions.funder : null}
                    onChange={(newValue) => setField('funder', newValue)}
                    placeholder="Start typing a name..."
                  />
                </Col>

                <Col lg={3} xs={12}>
                  <ClientInput
                    controlId="createApplication.Client"
                    onChange={(newValue) => setField('client', newValue)}
                  />
                </Col>

                <Col lg={3} xs={6}>
                  <MoneyInput
                    canVary
                    controlId="createApplication.FundingAmount"
                    defaultAmount={prefillOptions ? prefillOptions.fundingAmount : null}
                    defaultStringAmount={
                      prefillOptions ? prefillOptions.varyingFundingAmount : null
                    }
                    defaultVaries={prefillOptions ? prefillOptions.amountVaries : null}
                    errors={errors.fundingAmount}
                    label="Funding Amount / Award Info"
                    onAmountChange={(newAmount) => setField('fundingAmount', newAmount)}
                    onVaryChange={(newVaries) => setField('amountVaries', newVaries)}
                    onVaryingAmountChange={(newAmount) =>
                      setField('varyingFundingAmount', newAmount)
                    }
                    required
                    text="May be either fixed or varying."
                  />
                </Col>

                <Col lg={3} xs={6}>
                  <SelectInput
                    choices={applicationsConfigs.sources}
                    controlId="createApplication.Source"
                    defaultValue={prefillOptions ? prefillOptions.source : 0}
                    label="Source"
                    onChange={(newValue) => setField('source', newValue)}
                    required
                  />
                </Col>

                <Col lg={3} xs={6}>
                  <SelectInput
                    choices={applicationsConfigs.alphabetSortedCategories}
                    controlId="createApplication.Category"
                    defaultValue={
                      prefillOptions?.category
                        ? applicationsConfigs.alphabetSortedCategories.indexOf(
                            applicationsConfigs.categories[prefillOptions.category]
                          )
                        : 0
                    }
                    label="Category"
                    onChange={(newValue) => setField('category', newValue)}
                    required
                  />
                </Col>

                <Col lg={3} xs={6}>
                  <DateField
                    controlId="createApplication.StartDate"
                    errors={errors.startDate}
                    label="Start Date"
                    onChange={(newValue) => setField('startDate', newValue)}
                    value={form.startDate ? form.startDate : null}
                  />
                </Col>

                <Col lg={3} xs={6}>
                  <DateField
                    controlId="createApplication.DueDate"
                    errors={errors.dueDate}
                    label="Due Date"
                    onChange={(newValue) => setField('dueDate', newValue)}
                    value={form.dueDate ? form.dueDate : null}
                  />
                </Col>

                <Col lg={3} xs={6}>
                  <NotesStatusInput
                    controlId="createApplication.NotesStatus"
                    onChange={(newValue) => setField('notesStatus', newValue)}
                    placeholder="Enter status details"
                  />
                </Col>

                <Col lg={3} xs={12}>
                  <TextInput
                    controlId="createApplication.MatchRequirements"
                    defaultValue={prefillOptions ? prefillOptions.matchRequirements : null}
                    label="Match Requirements"
                    onChange={(newValue) => setField('matchRequirements', newValue)}
                  />
                </Col>

                <Col lg={3} xs={12}>
                  <TextInput
                    controlId="createApplication.DepartmentNotified"
                    label="Department Notified"
                    onChange={(newValue) => setField('departmentNotified', newValue)}
                  />
                </Col>

                <Col lg={3} xs={12}>
                  <TextInput
                    controlId="createApplication.PeriodOfPerformance"
                    defaultValue={prefillOptions ? prefillOptions.performancePeriod : null}
                    label="Period of Performance"
                    onChange={(newValue) => setField('performancePeriod', newValue)}
                  />
                </Col>

                <Col lg={6} xs={12}>
                  <TextInput
                    controlId="createApplication.EstimatedResponse"
                    defaultValue={prefillOptions ? prefillOptions.estimatedResponse : null}
                    label="Estimated Response"
                    onChange={(newValue) => setField('estimatedResponse', newValue)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12}>
                  <div
                    ref={dropzoneRef}
                    className="dz-message tall needsclick"
                    onClick={handleClickUpload}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') handleClickUpload(e);
                    }}
                    role="button"
                    style={{ cursor: 'pointer' }}
                    tabIndex={0}
                  >
                    <Dropzone
                      getUploadParams={getUploadParams}
                      inputContent="Drag Files Here or Click to Upload"
                      onChangeStatus={handleChangeStatus}
                    />
                  </div>
                </Col>
              </Row>
              {/* Display uploaded files */}
              <Row>
                {files.length === 0 ? (
                  <Col>
                    <Form.Text muted>
                      No files uploaded yet. Drag and drop files above to upload.
                    </Form.Text>
                  </Col>
                ) : (
                  files?.map((file) => (
                    <Col key={file.id} lg={4} md={6} sm={12} xl={4}>
                      <FileCard file={file} removeappfile />
                    </Col>
                  ))
                )}
              </Row>
              <div className="mt-4" />

              <h5>
                <strong>Custom Fields</strong>
              </h5>
              <hr />
              <CustomFields
                defaultFields={
                  // eslint-disable-next-line no-nested-ternary
                  prefillOptions
                    ? typeof prefillOptions.customFields === 'string'
                      ? JSON.parse(prefillOptions.customFields)
                      : prefillOptions.customFields
                    : []
                }
                onChange={(newFields) => setField('customFields', newFields)}
              />
            </Container>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="d-flex justify-content-center align-items-center"
            disabled={Object.keys(errors).length > 0}
            onClick={save}
            variant="save"
          >
            <Check size={16} />
            &nbsp;Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
