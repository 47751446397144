import { AxiosResponse } from 'axios';

import { AwardDocument } from 'types/awards';

import api, { outputError } from '../apiService';

export const getDocumentsByAwardId = (awardId: string): Promise<AwardDocument[] | false> =>
  api
    .get(`/awards/${awardId}/documents`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const uploadAwardFile = (awardId: string, formData: FormData): Promise<boolean> =>
  api
    .post(`/awards/${awardId}/documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteAwardFile = (awardId: string, fileId: string): Promise<boolean> =>
  api
    .delete(`/awards/${awardId}/documents`, { id: fileId })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const downloadAwardFilesArchive = (awardId: string, params: string) =>
  api
    .get(`/awards/${awardId}/documents/zip${params}`, { responseType: 'arraybuffer' })
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });
