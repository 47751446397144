import { Box, IconButton, Tooltip } from '@mui/material';
import { Icon } from 'react-feather';

interface FilterCardProps {
  variant?: string;
  title?: string;
  SelectedIcon: Icon;
  children?: (string | JSX.Element)[];
  onClick: () => void;
  tooltipText?: string;
  showBadge?: boolean;
}

export default function FilterCard({
  variant = 'primary',
  title,
  SelectedIcon,
  children,
  onClick,
  tooltipText = '',
  showBadge = false,
}: FilterCardProps) {
  return (
    <Box height="38px" width="38px" ml={1}>
      <Tooltip
        title={tooltipText}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: '#2C4474',
              color: 'white',
              fontSize: '12px',
            },
          },
        }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            backgroundColor: '#EFF1F6',
            borderRadius: '10%',
            width: '38px',
            height: '38px',
            padding: '8px',
            '&:hover': {
              backgroundColor: '#5F77A5',
            },
          }}
        >
          <SelectedIcon size={20} color="black" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
