/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import { useEffect } from 'react';

/**
 * Custom hook to prompt the user before leaving the page if there are unsaved changes.
 * @param unsavedChanges - Boolean indicating if there are unsaved changes.
 */
export default function useUnsavedChangesWarning(unsavedChanges: boolean) {
  const message = 'You have unsaved changes. Are you sure you want to leave?';

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        // This is the correct way to trigger the browser's default confirmation dialog.
        // The exact message cannot be customized in most modern browsers.
        event.preventDefault();
        event.stopPropagation();
        event.returnValue = message;
        // Note: Setting event.returnValue is necessary for the dialog to appear,
        // but the message may not be shown to the user in modern browsers.
      }
    };

    // Not working as expected
    // const handlePopState = (event: BeforeUnloadEvent) => {
    //   const decision = confirm(message);

    //   if (unsavedChanges && !decision) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   }
    // };

    window.addEventListener('beforeunload', handleBeforeUnload);
    // window.addEventListener('popstate', handlePopState);

    // Return a cleanup function that removes the event listeners
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // window.removeEventListener('popstate', handlePopState);
    };
  }, [unsavedChanges]);
}
