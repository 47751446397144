import { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';

import { getClientContacts } from 'services/clientContactsService';
import useUserSession from 'hooks/useUserSession';

interface TokenizedInputProps {
  controlId: unknown;
  required: unknown;
  label: unknown;
  placeholder: unknown;
  errors: unknown;
  onChange: unknown;
  text: unknown;
  page: unknown;
}

export default function TokenizedInput({
  controlId,
  errors,
  text,
  label,
  required = false,
  placeholder,
  onChange = null,
  page,
}: TokenizedInputProps) {
  const currentUser = useUserSession();

  const [selected, setSelected] = useState([]);
  const [clients, setClients] = useState([]);

  const typeaheadRef = useRef(null);

  const getContacts = async () => {
    const result = await Promise.all(
      currentUser.applicationClients?.map(async (client) => {
        if (client?.id) {
          const clientContacts = await getClientContacts(client?.id);
          return clientContacts;
        }
        return null;
      })
    );

    const mails = result?.map((c) => c?.map((client) => ({ name: client?.email }))).flat();
    setClients(mails);
  };

  const onInputChange = (newValue) => {
    newValue.forEach((el) => {
      el.name = el?.name?.trim().toLowerCase();
    });
    setSelected(newValue);
  };

  const updateSelected = (email) => {
    if (selected.some((item) => item.name === email)) {
      setSelected([...selected]);
    } else {
      setSelected([...selected, { name: email }]);
    }
    typeaheadRef.current.clear();
  };

  useEffect(() => {
    // If we have a selected item, their name is passed, else nothing is passed.
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (page === 'createApp') {
      getContacts();
    }
  }, []);

  return (
    <Form.Group controlId={controlId}>
      <Form.Label>
        {label}
        {required ? ' *' : ''}
      </Form.Label>
      <Typeahead
        ref={typeaheadRef}
        allowNew
        id={controlId}
        isInvalid={!!errors}
        labelKey="name"
        multiple
        onBlur={(e) => {
          const email = e.target.value.toLowerCase().trim();
          if (email.length > 0) {
            updateSelected(email);
          }
        }}
        onChange={onInputChange}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.keyCode === 188) {
            e.preventDefault();
            updateSelected(e.target.value);
          }
        }}
        options={clients || []}
        placeholder={placeholder}
        selected={selected}
      />
      {errors ? <div className="validation-errors">{errors}</div> : <Form.Text>{text}</Form.Text>}
    </Form.Group>
  );
}
