/* eslint-disable no-else-return */
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';

import { Totals } from 'components/awards/AwardsDetails/AwardsDetailsView/Budget/prepareBudgetEntries';
import { BudgetFormEntry } from './index';

import MatchField from './MatchField';
import CurrencyField from './CurrencyField';
import CategoryNameField from './CategoryNameField';

function formatCurrency(value: number | string | null) {
  if (value === null) return '0.00';

  value = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;

  if (value > 1000000000) return '1,000,000,000'; // 1 billion

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

const budgetColumns = (
  showWithMatch: boolean,
  readOnly: boolean,
  addChild: (parent: BudgetFormEntry) => void,
  removeChild: (item: BudgetFormEntry) => void,
  updateItem: (item: BudgetFormEntry) => void,
  totals: Totals,
  awardMatchAmount: number
): ColumnDescription<BudgetFormEntry>[] => [
  {
    dataField: 'name',
    text: 'Category',
    formatter: (name: string | null, entry) => {
      if (readOnly) return name || '-';
      return (
        <CategoryNameField
          disabled={entry.editable !== undefined && !entry.editable}
          ml={entry.parentId ? 2 : 0}
          onSave={(newValue) => updateItem({ ...entry, name: newValue })}
          value={name || ''}
        />
      );
    },
    footer: ' Total:',
  },
  {
    dataField: 'awardAmount',
    text: 'Total Project Budget',
    formatter: (value: number | null, entry) => {
      if (readOnly) return `$${formatCurrency(value)}`;

      return <CurrencyField disabled onSave={() => {}} value={`$${formatCurrency(value)}`} />;
    },
    footer: () => `$${formatCurrency(totals.awardAmount)} `,
    footerStyle: { letterSpacing: '0.8px' },
    headerAlign: 'right',
    footerAlign: 'right',
  },
  {
    dataField: 'awardExpended',
    text: 'Award Expended',
    formatter: (value: number | null, entry) => {
      if (readOnly) return `$${formatCurrency(value)}`;

      return (
        <CurrencyField
          disabled={!!entry.hasChildren || (entry.editable !== undefined && !entry.editable)}
          onSave={(newValue) => updateItem({ ...entry, awardExpended: newValue })}
          value={formatCurrency(value)}
        />
      );
    },
    footer: () => `$${formatCurrency(totals.awardExpended)} `,
    footerStyle: { letterSpacing: '0.8px' },
    headerAlign: 'right',
    footerAlign: 'right',
  },
  {
    dataField: 'awardBalance',
    text: 'Award Balance',
    formatter: (value: number | null, entry) => {
      if (readOnly) return `$${formatCurrency(value)}`;
      return (
        <CurrencyField
          disabled={!!entry.hasChildren || (entry.editable !== undefined && !entry.editable)}
          onSave={(newValue) => updateItem({ ...entry, awardBalance: newValue })}
          value={formatCurrency(value)}
        />
      );
    },
    footer: () => `$${formatCurrency(totals.awardBalance)}`,
    footerStyle: { letterSpacing: '0.8px' },
    headerAlign: 'right',
    footerAlign: 'right',
  },
  ...(showWithMatch
    ? [
        {
          dataField: 'matchExpended',
          text: 'Match Expended',
          hidden: !showWithMatch,
          formatter: (value: number | null, entry) => {
            if (!showWithMatch) return null;
            if (readOnly) return `$${formatCurrency(value)}`;

            return (
              <MatchField
                awardMatchAmount={awardMatchAmount}
                data={entry}
                disabled={!!entry.hasChildren || (entry.editable !== undefined && !entry.editable)}
                onSave={(newValues) => updateItem(newValues)}
                totalsMatchAmount={totals.matchExpended || 0}
                value={formatCurrency(value)}
              />
            );
          },
          footer: () => `$${formatCurrency(totals.matchExpended)} `,
          footerStyle: { letterSpacing: '0.8px' },
          headerAlign: 'right',
          footerAlign: 'right',
        },
      ]
    : []),
  ...(showWithMatch
    ? [
        {
          dataField: 'matchBalance',
          hidden: !showWithMatch,
          text: 'Match Balance',
          formatter: (value: number | null, entry) => {
            if (!showWithMatch) return null;
            if (readOnly) return `$${formatCurrency(value)}`;

            return (
              <MatchField
                awardMatchAmount={awardMatchAmount}
                data={entry}
                disabled={!!entry.hasChildren || (entry.editable !== undefined && !entry.editable)}
                onSave={(newValues) => updateItem(newValues)}
                totalsMatchAmount={totals.matchExpended || 0}
                value={formatCurrency(value)}
              />
            );
          },
          footer: () => `$${formatCurrency(totals.matchBalance)} `,
          footerStyle: { letterSpacing: '0.8px' },
          ...(item) => item.hasError && { style: { color: 'red' } },
          headerAlign: 'right',
          footerAlign: 'right',
        },
      ]
    : []),
  {
    dataField: 'actions',
    text: '',
    headerStyle: { width: '80px' },
    formatter: (_cell: unknown, item: BudgetFormEntry) => {
      if (item.editable === false) return null;
      if (!readOnly) {
        if (!item.parentId)
          return (
            <Stack direction="row" justifyContent="flex-start">
              <IconButton
                aria-label="add"
                disabled={readOnly}
                onClick={() => addChild(item)}
                size="small"
                sx={{
                  width: '70px',
                  fontSize: '1rem',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  borderRadius: 2,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <AddCircle />
                Add
              </IconButton>
            </Stack>
          );
        else
          return (
            <Stack direction="row" justifyContent="flex-start">
              <IconButton
                aria-label="remove"
                disabled={readOnly}
                onClick={() => removeChild(item)}
                size="small"
                sx={{
                  width: '70px',
                  fontSize: '1rem',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  borderRadius: 2,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <RemoveCircle />
              </IconButton>
            </Stack>
          );
      }
      return null;
    },
    footer: '',
  },
];

export default budgetColumns;
