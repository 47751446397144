import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';

interface InputFieldProps {
  value: string | undefined;
  options: string[];
  handleChange: (value: string) => void;
  label?: string;
  required?: boolean;
  id?: string;
  errorMessage?: string;
  disabled?: boolean;
}

const formatOption = (option?: string) => {
  if (!option) return 'Please Select an Option';

  // Convert camelcased words to separate words
  let formattedOption = option.replace(/([a-z])([A-Z])/g, '$1 $2');
  // Replace underscores with spaces
  formattedOption = formattedOption.replace(/_/g, ' ');
  // Capitalize the first letter of each word
  formattedOption = formattedOption
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedOption;
};

export default function SelectField({
  value,
  options,
  handleChange,
  label = '',
  required = false,
  id = '',
  disabled = false,
  errorMessage,
}: InputFieldProps) {
  return (
    <Stack spacing={1}>
      {label && (
        <InputLabel htmlFor={id} required={required}>
          {label}
        </InputLabel>
      )}
      <Select
        disabled={disabled}
        IconComponent={ExpandMoreRounded}
        labelId={id}
        onChange={(event: SelectChangeEvent) => {
          handleChange(event.target.value);
        }}
        sx={{
          '& .MuiOutlinedInput-input': {
            padding: '12px 10px 7px 10px',
            // height: '40px',
          },
          '& .MuiSelect-select': {
            '&:hover fieldset': {
              borderColor: (theme) => theme.palette.primary.light,
            },
            '&.Mui-focused fieldset': {
              borderColor: (theme) => theme.palette.primary.light,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.secondary.contrastText,
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            color: '#2c4474',
          },
          ...(disabled && {
            '& .MuiInputBase-root': {
              minHeight: '40px',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              color: '#2c4474',
            },
          }),
          ...(!disabled && {
            '& .MuiInputBase-root': {
              minHeight: '40px',
            },
          }),
        }}
        value={value}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {formatOption(option)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </Stack>
  );
}
