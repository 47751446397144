import { useState, useRef, Component, ChangeEvent } from 'react';
import { CustomInput } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import { Edit as EditIcon } from 'react-feather';
import BootstrapTable from 'react-bootstrap-table-next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// @ts-expect-error no types support planned for react-bootstrap-table2
import overlayFactory from 'react-bootstrap-table2-overlay';
import { useUserDetails } from 'hooks/UserDetailsContext';
import { tossError, tossSuccess } from 'utils/toastTosser';
import reduceTZ from 'utils/dateUtils';
import { User } from 'types/user';

import { updateUser } from 'services/userService';
import EditUserModal from 'components/users/EditUserModal';
import { camelCaseToWords } from 'utils/utilFunctions';
import 'assets/scss/ClientUsers.scss';
import { useNavigate } from 'react-router';

export default function ClientUsers({ users }: { users?: User[] }) {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isclientUsersVisible, setIsclientUsersVisible] = useState(true);
  const { setUser, setOnSave, setIsFromClientUsersList } = useUserDetails();

  const tableRef = useRef<BootstrapTable>(null);
  const navigate = useNavigate();
  const toggleUserActive = async (e: ChangeEvent<HTMLInputElement>, item: User) => {
    const targetBox = e.target;
    const userName = item.name;
    const newCheckedState = targetBox.checked;

    targetBox.disabled = true;

    const submittedFields = {
      id: item.id,
      enabled: newCheckedState,
    };

    const result = await updateUser(submittedFields);
    if (result) {
      tossSuccess(`The user ${userName} is now ${newCheckedState ? 'activated' : 'deactivated'}.`);
      targetBox.disabled = false;
      targetBox.checked = newCheckedState;
    } else {
      tossError(`Error ${newCheckedState ? 'enabling' : 'disabling'} the user ${userName}.`);
      targetBox.disabled = false;
    }
  };

  const handleEditUserDetails = (userData: User | null, isFromClientList = false) => {
    setUser(userData);
    setOnSave(() => {});
    setIsFromClientUsersList(isFromClientList);
    // Navigate to edit route
    navigate('/dashboard/users/user-details');
  };
  function userTypeFormatter(cell: string) {
    return camelCaseToWords(cell);
  }

  function lastLoggedInFormatter(cell: Date | string) {
    return reduceTZ(cell, 'Never');
  }

  function enabledFormatter(cell: string | Component, row: User) {
    return (
      <div className="align-middle">
        <CustomInput
          checked={!!cell}
          id={`enableUser${row.id}`}
          name={`enableUserSwitch${row.id}`}
          onChange={(e) => toggleUserActive(e, row)}
          type="switch"
        />
      </div>
    );
  }

  function actionsFormatter(cell: string | Component, row: User) {
    return (
      <div className="align-middle">
        <Button
          className="d-flex justify-content-center align-items-center"
          onClick={() => handleEditUserDetails(row, true)}
          variant="primary"
        >
          <EditIcon size={16} style={{ marginBottom: '2px' }} />
          &nbsp;Edit
        </Button>
      </div>
    );
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'userType',
      text: 'Type',
      formatter: userTypeFormatter,
    },
    {
      dataField: 'lastLoggedIn',
      text: 'Last Logged In',
      formatter: lastLoggedInFormatter,
    },
    {
      dataField: 'enabled',
      text: 'Enabled',
      formatter: enabledFormatter,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      formatter: actionsFormatter,
    },
  ];

  return (
    <div className="client-users-wrapper">
      <div
        className="hide-show-wrapper"
        onClick={() => setIsclientUsersVisible(!isclientUsersVisible)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsclientUsersVisible(!isclientUsersVisible);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <h4 className="client-user-heading">
          Client Users
          <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
            {isclientUsersVisible ? (
              <>
                <KeyboardArrowUp className="ms-2" style={{ color: '#2C4474', fontSize: '18px' }} />
                <span className="show-hide-text">Hide Section</span>
              </>
            ) : (
              <>
                <KeyboardArrowDown
                  className="ms-2"
                  style={{ color: '#2C4474', fontSize: '18px' }}
                />{' '}
                <span className="show-hide-text">Show Section</span>
              </>
            )}
          </span>
        </h4>
      </div>

      {isclientUsersVisible && (
        <BootstrapTable
          ref={tableRef}
          bootstrap4
          columns={columns}
          data={users || []}
          keyField="id"
          noDataIndication={() => 'No Users Found.'}
          overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
          striped
          wrapperClasses="table-responsive table-borderless"
        />
      )}
    </div>
  );
}
