import { AxiosResponse } from 'axios';

import { AwardReport } from 'types/awards';
import api, { outputError } from 'services/apiService';

export interface ReportsList {
  rows: AwardReport[];
  count: number;
  error?: string;
}

export const getAwardReports = (id: string, searchParams: string): Promise<ReportsList> =>
  api
    .get(`/awards/${id}/reports${searchParams}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) {
        return response.data;
      }
      return {} as ReportsList;
    })
    .catch((error) => {
      outputError(error);
      return {} as ReportsList;
    });

export const createReport = (awardId: string, payload: Partial<AwardReport>): Promise<boolean> =>
  api
    .post(`/awards/${awardId}/reports`, payload)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const updateReport = (awardId: string, payload: Partial<AwardReport>): Promise<boolean> =>
  api
    .put(`/awards/${awardId}/reports`, payload)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteReport = (id: string, reportId: number): Promise<boolean> =>
  api
    .delete(`/awards/${id}/reports/${reportId}`)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
