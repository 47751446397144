// contexts/UserDetailsContext.tsx
import { createContext, useContext, useState } from 'react';
import { User } from 'types/user';

interface UserDetailsContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  onSave?: () => void; // Make it optional as it will be provided by UserList
  open: boolean;
  toggle: () => void;
  isFromClientUsersList: boolean;
  setIsFromClientUsersList: (value: boolean) => void;
  setOnSave: (saveHandler: () => void) => void;
}

export const UserDetailsContext = createContext<UserDetailsContextType>({
  user: null,
  setUser: () => {},
  onSave: undefined,
  open: false,
  toggle: () => {},
  isFromClientUsersList: false,
  setIsFromClientUsersList: () => {},
  setOnSave: () => {},
});

export function UserDetailsProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [onSave, setOnSave] = useState<() => void>(() => {});
  const [isFromClientUsersList, setIsFromClientUsersList] = useState(false);
  const open = Boolean(user);

  const toggle = () => setUser(null);

  return (
    <UserDetailsContext.Provider
      value={{
        user,
        setUser,
        onSave,
        open,
        toggle,
        isFromClientUsersList,
        setIsFromClientUsersList,
        setOnSave,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
}

export const useUserDetails = () => useContext(UserDetailsContext);
