import { memo, useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';

import StatsCharts from 'components/reports/sections/StatsCharts';

import { getReports, ReportsData } from 'services/reportService';
import OverviewKnobs from 'shared/OverviewKnobs';

function DashboardStats({ userId }: { userId: number }) {
  const [reportsData, setReportsData] = useState<ReportsData>({} as ReportsData);
  const today = dayjs();
  const year1 = today.startOf('y');
  const startDate = dayjs(year1.toDate()).format('YYYY-MM-DD');
  const endDate = dayjs(today.toDate()).format('YYYY-MM-DD');

  const fetchReports = async () => {
    const result = await getReports(
      `users=${userId}${startDate ? `&startDate=${startDate}` : ''}${
        endDate ? `&endDate=${endDate}` : ''
      }`
    );
    setReportsData(result);
  };

  useEffect(() => {
    fetchReports();
  }, []);

  const { bySource: knobsData } = reportsData || {};
  const { bySource: chartsDataSource, byCategory: chartsDataCategory } = reportsData || {};

  return (
    <Container fluid>
      <Row>
        <Col className="mt-4 d-flex align-items-stretch" xl={7}>
          <OverviewKnobs data={knobsData} />
        </Col>

        <Col className="mt-4 d-flex align-items-stretch" xl={5}>
          <StatsCharts categoryData={chartsDataCategory} sourceData={chartsDataSource} />
        </Col>
      </Row>
    </Container>
  );
}

export default memo(DashboardStats);
