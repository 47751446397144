import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import useSearchParams from 'hooks/useSearchParams';
import useLocalStorage from 'hooks/useLocalStorage';

import {
  FunderFilter,
  DepartmentFilter,
  AssigneeFilter,
  UnAssigneeFilter,
  ClientFilter,
  ProgramFilter,
  FiltersButtonsBlock,
  StatusFilter,
  UsedFiltersDescriptionBlock,
} from 'components/awards/filters';

import useUserSession from 'hooks/useUserSession';

const defaultFiltersSet = { sortBy: 'endsOn', sortOrder: 'desc', page: '1', perPage: '20' };

export default function FinanceFilters() {
  const currentUser = useUserSession();
  const { setSearchParams } = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [storedFiltersSet, setStoredFiltersSet] = useLocalStorage<string>(
    'financeAwardsFilters',
    'v1.2'
  );

  useEffect(() => {
    if (location.search === '' && storedFiltersSet) {
      navigate(storedFiltersSet, { replace: true });
    } else {
      setSearchParams(defaultFiltersSet);
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      setStoredFiltersSet(location.pathname + location.search);
    }
  }, [location]);

  return (
    <>
      {/* <Typography m={2} variant="h2">
        Filters
      </Typography> */}
      <Stack direction="row" justifyContent="space-between" m={2}>
        <Stack direction="row" flexGrow={1} justifyContent="space-between">
          <FunderFilter width="22%" />
          <ProgramFilter width="22%" />
          <AssigneeFilter width="22%" />
          <UnAssigneeFilter width="22%" />
          {currentUser.isMillenniumUser ? (
            <ClientFilter width="22%" />
          ) : (
            <DepartmentFilter width="22%" />
          )}
          <StatusFilter width="12%" />
        </Stack>

        <FiltersButtonsBlock view="financeView" />
      </Stack>

      <UsedFiltersDescriptionBlock />
    </>
  );
}
