import Chip from '@mui/material/Chip';

export default function StatusChip({
  label,
  color,
  background,
  height = '100%',
}: {
  label: string;
  color?: string;
  background?: string;
  height: string;
}) {
  return (
    <Chip
      label={label}
      size="small"
      sx={{
        height,
        color: color || 'black',
        border: `1px solid ${color || 'black'}`,
        fontSize: '0.75rem',
        fontWeight: '750',
        borderRadius: '2px',
        backgroundColor: background || 'white',
      }}
      variant="outlined"
    />
  );
}
