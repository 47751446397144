const recursiveParseJsonArray = (array) => {
  if (typeof array === 'string') {
    const result = JSON.parse(array);
    return recursiveParseJsonArray(result);
  }

  return array;
};

export default recursiveParseJsonArray;
