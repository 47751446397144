import { createContext, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import { ThemeProvider } from '@mui/material/styles';

import './index.scss';
import logger from 'services/logger';

import Loader from 'components/layout/Loader';
import useLocalStorage from 'hooks/useLocalStorage';

import { CurrentUser } from 'types/user';
import { Strings } from 'types/strings';

import { sessionUser } from 'services/userService';
import getStrings from 'services/configService';

import getRoutes from 'routes/index';
import muiTheme from './assets/muiTheme';
import api from './services/apiService';

registerLocale('en-GB', enGB);
setDefaultLocale('en-GB');

export const UserSessionContext = createContext<CurrentUser | null>(null);
export const StringsContext = createContext<Strings>({} as Strings);

function Root() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [stringsData, setStringsData] = useState<Strings>({} as Strings);
  const [storedToken, , removeStoredToken] = useLocalStorage<string>('user', 'v1.0');

  const clearLS = () => {
    removeStoredToken();
    setCurrentUser(null);
    setIsLoading(false);
  };

  const initializeStrings = async () => setStringsData(await getStrings());

  const getSession = async () => {
    if (storedToken) {
      api.token = storedToken;

      const user = await sessionUser();

      if (user && user.id) {
        logger.info('User Logged in', { user: { ...user } });

        setCurrentUser({
          ...user,
          isMillenniumUser: user?.userType.includes('millennium'),
          isMillenniumAnalyst: user?.userType.includes('millenniumAnalyst'),
        });

        setIsLoading(false);
      } else {
        clearLS();
      }
    } else {
      clearLS();
    }
  };

  useEffect(() => {
    if (isLoading) {
      initializeStrings();
      getSession();
    }
  }, [isLoading]);

  if (isLoading) return <Loader infinite />;

  return (
    <ThemeProvider theme={muiTheme}>
      <StringsContext.Provider value={stringsData}>
        <UserSessionContext.Provider value={currentUser}>
          <BrowserRouter basename="/">{getRoutes(currentUser)}</BrowserRouter>
          <a className="help" href="mailto:tech-support@m-strat.com">
            ?
          </a>
        </UserSessionContext.Provider>
      </StringsContext.Provider>
    </ThemeProvider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);
