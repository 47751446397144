import { forwardRef, useEffect, useRef, useState } from 'react';
// @ts-expect-error no types support planned for knob TODO: refactor with own component
import Knob from 'knob';
import { useLocation } from 'react-router';
import { Container, Row, Col, Card } from 'react-bootstrap';
import sumBy from 'lodash.sumby';

import { formatCurrencyValue } from 'utils/utilFunctions';
import { ReportEntry } from 'services/reportService';
import 'assets/scss/ApplicationList.scss';

interface OverviewKnobProps {
  id: string;
  caption: string;
  value: number;
  totalValue: number;
  subtitle: string;
  formatter?: (value: number) => string;
}

const OverviewKnob = forwardRef(
  ({ id, caption, value, totalValue, subtitle, formatter }: OverviewKnobProps, ref) => {
    const options = {
      value: 0,
      left: 1,
      angleOffset: 0,
      className: 'review',
      thickness: 0.15,
      fgColor: '#2C4474',
      readOnly: true,
      dynamicDraw: true,
      tickColorizeValues: true,
      bgColor: '#E0E5F0',
      lineCap: 'round',
      displayPrevious: false,
    };

    const update = () => {
      const newKnob = Knob({
        ...options,
        value: Math.trunc((100 * value) / totalValue),
      });

      if (ref && ref.current) {
        ref.current.innerHTML = '';
        ref.current.appendChild(newKnob);
        ref.current.querySelector(`input:first-of-type`).value = formatter
          ? formatter(value)
          : value;
        ref.current.querySelector(`input:first-of-type`).style.fontSize = `${
          40 - Math.max(Math.max(0, `${value}`.length - 5) * 1, 20)
        }px`;
        ref.current.querySelector(`input:first-of-type`).style.fontFamily = 'Nunito';
        ref.current.querySelector(`input:first-of-type`).style.fontWeight = 600;
        ref.current.querySelector(`span:first-of-type`).innerHTML = caption;
      }
    };

    useEffect(update, [id, caption, value, totalValue, subtitle, formatter]);

    return (
      <div className="knob-block text-center">
        <div ref={ref} className="knob" id={id} />
        <div className="mt-4" />
        <span className="stats-overview-subtitle">{subtitle}</span>
        <div className="mt-4" />
      </div>
    );
  }
);

export default function OverviewKnobs({ data }: { data: ReportEntry[] }) {
  const [fundingAwarded, setFundingAwarded] = useState(0);
  const [fundingRequested, setFundingRequested] = useState(0);
  const [applicationsAwarded, setApplicationsAwarded] = useState(0);
  const [applicationsSent, setApplicationsSent] = useState(0);
  const [averageAward, setAverageAward] = useState(0);
  const [averageRequest, setAverageRequest] = useState(0);

  const knob1Ref = useRef();
  const knob2Ref = useRef();
  const knob3Ref = useRef();

  const location = useLocation();

  const updateKnobs = () => {
    if (!data) return;

    const totalAwardsValue = sumBy(data, 'awardsValue');
    const totalSubmissionsValue = sumBy(data, 'submissionsValue');
    const totalAwards = sumBy(data, 'awarded');
    const totalSubmissions = sumBy(data, 'submitted');
    const totalAverageAward = totalAwardsValue / totalAwards || 0;
    const totalAverageAmount = totalSubmissionsValue / totalSubmissions || 0;

    setFundingAwarded(totalAwardsValue);
    setFundingRequested(totalSubmissionsValue);

    setApplicationsAwarded(totalAwards);
    setApplicationsSent(totalSubmissions);

    setAverageAward(totalAverageAward);
    setAverageRequest(totalAverageAmount);
  };

  useEffect(updateKnobs, [data]);

  const formatAwardAmount = (value: number) => formatCurrencyValue(value);

  return (
    <Card className="w-100 stats-overview-container">
      <h5 className="stats-overview-heading">
        {location?.pathname.includes('user-dashboard') ? 'Application Overview' : 'Overview'}
      </h5>
      <Card.Body>
        <Container className="text-center report-knob-data stats-overview-data" fluid>
          <Row>
            <Col md="4">
              <OverviewKnob
                ref={knob1Ref}
                caption="Funding Awarded"
                formatter={formatAwardAmount}
                id="fundingAwarded"
                subtitle={`${formatCurrencyValue(fundingRequested)} Applied For`}
                totalValue={fundingRequested}
                value={fundingAwarded}
              />
            </Col>

            <Col md="4">
              <OverviewKnob
                ref={knob2Ref}
                caption="Applications Awarded"
                id="applicationsAwarded"
                subtitle={`${applicationsSent} Applied For`}
                totalValue={applicationsSent}
                value={applicationsAwarded}
              />
            </Col>

            <Col md="4">
              <OverviewKnob
                ref={knob3Ref}
                caption="Average Award"
                formatter={formatAwardAmount}
                id="averageAward"
                subtitle={`${formatCurrencyValue(averageRequest)} Average Request`}
                totalValue={averageRequest}
                value={averageAward}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
