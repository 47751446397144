import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Stack, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Col, Container, Row } from 'react-bootstrap';

import IconButton from 'shared/muiComponents/IconButton';

import useCurrentAward from 'hooks/useCurrentAward';
import AllReportsView from 'components/awards/AwardsDetails/AwardsDetailsView/Reporting/AllReportsView';
import ReportingNavBar from './ReportingNavBar';

export default function ReportingPage() {
  const navigate = useNavigate();
  const { currentAwardId, awardData } = useCurrentAward();
  const currentAward = awardData.data;

  const goToAwardDetails = () => {
    navigate(`/dashboard/award/${currentAwardId}/details`, { state: { from: 'Reports' } });
  };

  return (
    <>
      <Helmet>
        <title>Grantrack - Award Reports {currentAwardId}</title>
      </Helmet>

      <Container fluid>
        <Row>
          <Col>
            <Stack direction="row" justifyContent="space-between" mb={2}>
              <Stack alignItems="center" direction="row" spacing={2}>
                <IconButton onClick={() => goToAwardDetails()}>
                  <ArrowBack htmlColor="black" />
                </IconButton>

                <Typography variant="h2">
                  Reporting for {currentAward?.stringId || 'Award'}
                </Typography>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </Container>

      <Stack alignItems="center" direction="row" justifyContent="space-between" m={2}>
        <ReportingNavBar />
      </Stack>

      <AllReportsView />
    </>
  );
}
