import { CurrentUser } from 'types/user';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from 'components/layout/Layout';

import {
  ApplicationEdit,
  Applications,
  awardsDetailsRoutes,
  awardsRoutes,
  ClientEdit,
  ClientList,
  Dashboard,
  ForgotPassword,
  Preferences,
  Register,
  Reports,
  ResetPassword,
  SignIn,
  UserList,
} from 'pages/index';

import { AwardContextWrapper } from 'hooks/AwardContext';
import CreateProgram from 'components/programs/CreateProgram';
import ProgramDetails from 'components/programs/ProgramDetails';
import { ProgramProvider } from 'hooks/ProgramContext';
import CreateClient from 'components/clients/CreateClient';
import { UserDetailsProvider } from 'hooks/UserDetailsContext';
import EditUserModal from 'components/users/EditUserModal';

const getRoutes = (currentUser: CurrentUser | null) => {
  let defaultRouteNav: string;
  switch (currentUser?.userType) {
    case 'millenniumAnalyst':
      defaultRouteNav =
        '/dashboard/applications?t=1&p=1&s=openStatusesActive&sb=dueDate&so=asc&edt=Due+Date';
      break;
    case 'userAnalyst':
      defaultRouteNav = '/dashboard/applications';
      break;
    case 'userAdmin':
      defaultRouteNav = '/dashboard/applications';
      break;
    default:
      defaultRouteNav = '/dashboard/applications';
      break;
  }

  return currentUser ? (
    <UserDetailsProvider>
      <ProgramProvider>
        <Routes>
          <Route element={<Navigate to={defaultRouteNav} />} index />
          <Route element={<Layout />} path="/">
            <Route path="dashboard">
              <Route
                element={
                  ['millenniumAnalyst', 'userAnalyst', 'userAdmin'].includes(
                    currentUser.userType
                  ) ? (
                    <Navigate to={defaultRouteNav} />
                  ) : (
                    <Dashboard />
                  )
                }
                path="user-dashboard"
              />

              <Route path="applications">
                <Route element={<Applications />} index />
                <Route element={<ApplicationEdit />} path="edit/:applicationId" />
                <Route element={<ApplicationEdit />} path="edit/multiple" />
              </Route>
              <Route path="users">
                <Route
                  element={
                    ['millenniumAdmin', 'userAdmin'].includes(currentUser.userType) ? (
                      <UserList />
                    ) : (
                      <Navigate to={defaultRouteNav} />
                    )
                  }
                  index
                />

                <Route
                  element={
                    ['millenniumAdmin', 'userAdmin'].includes(currentUser.userType) ? (
                      <EditUserModal />
                    ) : (
                      <Navigate to={defaultRouteNav} />
                    )
                  }
                  path="user-details"
                />
              </Route>
              <Route path="clients">
                <Route element={<ClientList />} index />
                <Route element={<CreateClient />} path="create-client" />
                <Route element={<ClientEdit />} path="edit/:clientId" />
              </Route>

              <Route element={<Reports />} path="reports" />

              <Route element={<AwardContextWrapper />}>
                {awardsRoutes()}
                {awardsDetailsRoutes()}
                <Route element={<Preferences />} path="awards-preferences" />
              </Route>
              <Route element={<CreateProgram />} path="create-program" />
              <Route element={<ProgramDetails />} path="program-details/:programId" />
              <Route element={<Reports />} path="newprogram" />
              {/* <Route element={<Navigate to={defaultRouteNav} />} path="*" /> */}
            </Route>

            <Route element={<Navigate to={defaultRouteNav} />} path="*" />
          </Route>

          <Route element={<Navigate to={defaultRouteNav} />} path="*" />
        </Routes>
      </ProgramProvider>
    </UserDetailsProvider>
  ) : (
    <Routes>
      <Route element={<Navigate to="/login" />} index />
      <Route path="/">
        <Route element={<SignIn />} path="login" />
        <Route element={<Register />} path="register/:tokenData" />
        <Route element={<ForgotPassword />} path="forgotpassword" />
        <Route element={<ResetPassword />} path="resetpassword/:tokenData" />
      </Route>

      <Route element={<Navigate to="/login" />} path="*" />
    </Routes>
  );
};

export default getRoutes;
