import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import DateHeader from '../DateHeader';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDatePicker.css';

dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

interface DateRangeFilterProps {
  startDate?: string | null;
  endDate?: string | null;
  onChange: (start: string | null, end: string | null) => void;
}

export default function DateRangeFilter({
  startDate: startDateValue,
  endDate: endDateValue,
  onChange,
}: DateRangeFilterProps) {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const toggle = () => setOpen((prevState) => !prevState);

  useEffect(() => {
    const newStartDate = dayjs(startDateValue);

    if (dayjs(startDate).isSame(newStartDate)) return;

    setStartDate(newStartDate && newStartDate.isValid() ? newStartDate.toDate() : null);
  }, [startDateValue]);

  useEffect(() => {
    const newEndDate = dayjs(endDateValue);

    if (dayjs(endDate).isSame(newEndDate)) return;

    setEndDate(newEndDate && newEndDate.isValid() ? newEndDate.toDate() : null);
  }, [endDateValue]);

  const update = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const newStartDate = dayjs(start);
    const newEndDate = dayjs(end);

    setStartDate(start);
    setEndDate(end);

    if (onChange)
      onChange(
        newStartDate.isValid() ? newStartDate.format('YYYY-MM-DD') : null,
        newEndDate.isValid() ? newEndDate.format('YYYY-MM-DD') : null
      );
  };

  const selectWeekToDate = () => {
    const today = dayjs();
    const monday = today.subtract(1, 'd').startOf('w').add(1, 'd');
    const start = monday.toDate();
    const end = today.toDate();

    setStartDate(start);
    setEndDate(end);

    if (onChange) onChange(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
  };

  const select14Days = () => {
    const today = dayjs();
    const fourteenDays = today.startOf('D').subtract(14, 'd');
    const start = fourteenDays.toDate();
    const end = today.add(14, 'd').toDate();

    setStartDate(start);
    setEndDate(end);

    if (onChange) onChange(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
  };

  const selectMonthToDate = () => {
    const today = dayjs();
    const month = today.startOf('M');
    const start = month.toDate();
    const end = today.toDate();

    setStartDate(start);
    setEndDate(end);

    if (onChange) onChange(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
  };

  const selectQuarterToDate = () => {
    const today = dayjs();
    const quarter = today.startOf('Q');
    const start = quarter.toDate();
    const end = today.toDate();

    setStartDate(start);
    setEndDate(end);

    if (onChange) onChange(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
  };

  const selectYearToDate = () => {
    const today = dayjs();
    const year = today.startOf('y');
    const start = year.toDate();
    const end = today.toDate();

    setStartDate(start);
    setEndDate(end);

    if (onChange) onChange(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
  };

  const selectAllTime = () => {
    setStartDate(null);
    setEndDate(null);

    if (onChange) onChange(null, null);
  };
  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <>
      <Button className="date-picker-button" onClick={toggle}>
        {!startDate && !endDate && 'All Time'}
        {startDate &&
          endDate &&
          dayjs(endDate).isSame(startDate) &&
          truncateText(`On ${dayjs(startDate).format('dddd, MMMM Do YYYY')}`, 10)}
        {startDate &&
          endDate &&
          !dayjs(endDate).isSame(startDate) &&
          truncateText(
            `${dayjs(startDate).format('dddd, MMMM Do YYYY')} to ${dayjs(endDate).format(
              'dddd, MMMM Do YYYY'
            )}`,
            10
          )}
      </Button>
      <Modal centered className="bg-filter-date-modal" onHide={toggle} show={open}>
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col md={4} xs={12}>
                <Container className="p-0" fluid>
                  <Row>
                    <Col xs={12}>
                      <Button
                        className="w-100 text-left mb-2 periodSelectorButton"
                        onClick={select14Days}
                        size="sm"
                        type="button"
                      >
                        14 Days
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <Button
                        className="w-100 text-left mb-2 periodSelectorButton"
                        onClick={selectWeekToDate}
                        size="sm"
                        type="button"
                      >
                        Week to Date
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <Button
                        className="w-100 text-left mb-2 periodSelectorButton"
                        onClick={selectMonthToDate}
                        size="sm"
                        type="button"
                      >
                        Month to Date
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <Button
                        className="w-100 text-left mb-2 periodSelectorButton"
                        onClick={selectQuarterToDate}
                        size="sm"
                        type="button"
                      >
                        Quarter to Date
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <Button
                        className="w-100 text-left mb-2 periodSelectorButton"
                        onClick={selectYearToDate}
                        size="sm"
                        type="button"
                      >
                        Year to Date
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <Button
                        className="w-100 text-left mb-2 periodSelectorButton"
                        onClick={selectAllTime}
                        size="sm"
                        type="button"
                      >
                        All Time
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>

              <Col md={8} xs={12}>
                <DatePicker
                  className="form-control digits custom-datepicker"
                  dateFormat="MM/dd/yyyy"
                  endDate={endDate}
                  inline
                  name="filterDate"
                  onChange={update}
                  openToDate={startDate!}
                  renderCustomHeader={DateHeader}
                  selectsRange
                  startDate={startDate}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
