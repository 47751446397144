import { useState, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Plus } from 'react-feather';

import TooltipWrapper from 'shared/muiComponents/TooltipWrapper';
import { CustomFieldInput, TextInput } from './inputs';

export interface CustomField {
  id: string;
  name: string;
  value?: string | number | null;
}

interface CustomFieldsProps {
  defaultFields?: CustomField[];
  disabled?: boolean;
  onChange?: (val: CustomField[]) => void;
}
export default function CustomFields({
  defaultFields,
  disabled = false,
  onChange,
}: CustomFieldsProps) {
  const [fields, setFields] = useState<CustomField[]>(defaultFields ?? []);

  const [name, setName] = useState<string>();
  const prevDefaultFieldsRef = useRef<string>();

  useEffect(() => {
    onChange?.(fields);
  }, [fields]);

  useEffect(() => {
    const prevDefaultFields = prevDefaultFieldsRef.current;
    const currentDefaultFields = JSON.stringify(defaultFields);

    if (prevDefaultFields !== currentDefaultFields) {
      setFields(defaultFields ?? []);
      prevDefaultFieldsRef.current = currentDefaultFields;
    }
  }, [defaultFields]);

  const add = () => {
    if (!name || name === '') {
      return;
    }
    setFields((prevState) => [...prevState, { id: nanoid(), name, value: null }]);
  };

  const remove = (fieldId: string) => {
    setFields((prevState) => prevState.filter((e) => e.id !== fieldId));
  };

  const update = (fieldId: string, fieldValue: string | number | null) => {
    const currentFields = [...fields];
    const updateIndex = currentFields.map((e) => e.id).indexOf(fieldId);

    currentFields[updateIndex].value = fieldValue;

    setFields(currentFields);
  };

  return (
    <Row>
      {
        /*
          NOTE
          Here if no fields, defaultFields type - string.
          That is why it is throws error & blank page.
          Quick solution to have ability to see or edit application without error
        */
        Array.isArray(fields) && fields.length > 0 ? (
          fields.map((field) => (
            <Col key={field.id} lg={3} xs={12}>
              <TooltipWrapper text={String(field.value || '')}>
                <div>
                  <CustomFieldInput
                    controlId={`createProgram.CustomField${field.id}`}
                    defaultValue={field.value}
                    disabled={disabled}
                    label={field.name}
                    onChange={(fieldData) => update(field.id, fieldData)}
                    onDelete={() => remove(field.id)}
                  />
                </div>
              </TooltipWrapper>
            </Col>
          ))
        ) : (
          <Col xs={12}>
            <Form.Text muted>{/* No custom fields added yet. */}</Form.Text>
          </Col>
        )
      }

      {!disabled && (
        <>
          <Col className="pt-4 pb-2" xs={12}>
            <h6>
              <strong>Add Custom Field</strong>
            </h6>
          </Col>

          <Col lg={3} xs={6}>
            <div className="custom-field-input">
              <TextInput
                controlId="customFields.AddNew"
                onChange={(newValue: string) => setName(newValue)}
                placeholder="Enter Custom Field's Name"
              />
            </div>
          </Col>
          <Col className="d-flex align-items-end pb-3" lg={3} xs={6}>
            <Button color="secondary-blue" onClick={add} variant="secondary-blue">
              <Plus className="pt-1" size={16} /> Add Custom Field
            </Button>
          </Col>
          <Col lg={6} xs={12} />
        </>
      )}
    </Row>
  );
}
