import {
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  Component,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react';
import { CustomInput, PaginationItemProps } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import Button from 'react-bootstrap/Button';
import { Edit as EditIcon, Plus, UserPlus } from 'react-feather';
import BootstrapTable, { TableChangeType, ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
// @ts-expect-error no types support planned for react-bootstrap-table2
import overlayFactory from 'react-bootstrap-table2-overlay';

import useUserSession from 'hooks/useUserSession';
import { useUserDetails } from 'hooks/UserDetailsContext';

import { tossError, tossSuccess } from 'utils/toastTosser';
import reduceTZ from 'utils/dateUtils';
import { camelCaseToWords, scrollToBottom } from 'utils/utilFunctions';
import { User } from 'types/user';
import { Client } from 'types/client';
import UserFilters from 'components/users/UserFilters';
import InviteUser from 'components/users/InviteUser';
import { getUsers, updateUser } from 'services/userService';
import Breadcrumbs from 'components/layout/Breadcrumbs';
import EditUserModal from 'components/users/EditUserModal';
import { Row } from 'react-bootstrap';
import 'assets/scss/userList.scss';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';

interface IFilter {
  names: {
    name: string;
  }[];
  emails: {
    name: string;
  }[];
  phones: {
    name: string;
  }[];
  page: number;
  target: string;
}

interface FiltersContext {
  filters: IFilter;
  setFilters: Dispatch<SetStateAction<IFilter>>;
}

export const FilterContext = createContext<FiltersContext>({} as FiltersContext);

function UserList() {
  const { REACT_APP_USERS_PER_PAGE: USERS_PER_PAGE } = process.env;

  const [filters, setFilters] = useState<IFilter>({} as IFilter);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const currentUser = useUserSession();

  const [data, setData] = useState<User[]>([]);
  const [dataSize, setDataSize] = useState(0);
  const [fetching, setFetching] = useState(true);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const tableRef = useRef<BootstrapTable>(null);
  const { setUser, setOnSave, setIsFromClientUsersList } = useUserDetails();

  const navigate = useNavigate();

  const refetchList = useCallback(async () => {
    const queryFilters: {
      names?: string[];
      emails?: string[];
      phones?: string[];
      target?: string;
    } = {};
    const {
      names: filterNames,
      emails: filterEmails,
      phones: filterPhones,
      page: filterPage,
      target: filterTarget,
    } = filters || {};
    if (!isEmpty(filters)) {
      if (filterNames && filterNames.length > 0)
        queryFilters.names = filterNames.map((e) => e.name);
      if (filterEmails && filterEmails.length > 0)
        queryFilters.emails = filterEmails.map((e) => e.name);
      if (filterPhones && filterPhones.length > 0)
        queryFilters.phones = filterPhones.map((e) => e.name);
    }
    if (filterTarget) {
      queryFilters.target = filterTarget;
    }
    const url = new URL(`${process.env.REACT_APP_API_URL}/users`);
    url.search = new URLSearchParams({
      page: `${filterPage ?? 1}`,
      perPage: `${USERS_PER_PAGE}`,
      ...(queryFilters.names && { names: queryFilters.names.join(',') }),
      ...(queryFilters.emails && { emails: queryFilters.emails.join(',') }),
      ...(queryFilters.phones && { phones: queryFilters.phones.join(',') }),
      ...(queryFilters.target && { target: queryFilters.target }),
    }).toString();

    return getUsers(url.search);
  }, [filters]);

  const updateUserList = () => {
    setFetching(true);
    const now = new Date();
    const stop = now.getTime() + 300;

    const performListUpdate = (retrievedList: { pages: number; rows: User[]; entries: number }) => {
      const { pages, rows, entries } = retrievedList;
      const calculatedTotalRecords = pages * Number(USERS_PER_PAGE);
      setData(rows);
      setDataSize(entries);
      setFetching(false);
      setPageCount(pages);
      setTotalRecords(calculatedTotalRecords);
      if (!isInitialLoad) {
        scrollToBottom();
      }
    };

    const performFetch = async () => {
      const retrievedList = await refetchList();
      const endDate = new Date();
      const timeRemaining = stop - endDate.getTime();

      if (endDate.getTime() > stop) {
        performListUpdate(retrievedList);
      } else {
        setTimeout(() => {
          performListUpdate(retrievedList);
        }, timeRemaining);
      }
    };

    performFetch();
  };

  useEffect(updateUserList, [currentUser.id, refetchList, filters]);

  useEffect(
    () => setFilters((prevState) => (page ? { ...(prevState as IFilter), page } : prevState)),
    [page]
  );

  const toggleUserActive = async (e: ChangeEvent<HTMLInputElement>, item: User) => {
    const targetBox = e.target;
    const userName = item.name;
    const newCheckedState = targetBox.checked;

    targetBox.disabled = true;

    const submittedFields = {
      id: item.id,
      enabled: newCheckedState,
    };

    const result = await updateUser(submittedFields);
    if (result) {
      tossSuccess(`The user ${userName} is now ${newCheckedState ? 'activated' : 'deactivated'}.`);
      targetBox.disabled = false;
      targetBox.checked = newCheckedState;
    } else {
      tossError(`Error ${newCheckedState ? 'enabling' : 'disabling'} the user ${userName}.`);
      targetBox.disabled = false;
    }
  };

  const handleUserDetails = (userData: User | null, isFromClientList = false) => {
    setUser(userData);
    setOnSave(() => updateUserList);
    setIsFromClientUsersList(isFromClientList);
    // Navigate to edit route
    navigate('/dashboard/users/user-details');
  };

  const onTableChange = (type: TableChangeType) => {
    if (type === 'sort') {
      // @ts-expect-error this is an unofficial hack to update table page.
      if (tableRef.current) tableRef.current.paginationContext.currPage = 1;
      setPage(1);
    }
  };

  function userTypeFormatter(cell: string) {
    return camelCaseToWords(cell);
  }

  function lastLoggedInFormatter(cell: Date | string) {
    return reduceTZ(cell, 'Never');
  }

  function assignedClientsFormatter(cell: Partial<Client>[]) {
    return (
      <div className="align-middle">
        {cell.length === 0
          ? 'None'
          : cell[0].name + (cell.length > 1 ? ` (+${cell.length - 1})` : '')}
      </div>
    );
  }

  function enabledFormatter(cell: string | Component, row: User) {
    return (
      <div className="align-middle">
        <CustomInput
          checked={!!cell}
          className="custom-user-toggle"
          id={`enableUser${row.id}`}
          name={`enableUserSwitch${row.id}`}
          onChange={(e) => toggleUserActive(e, row)}
          type="switch"
        />
      </div>
    );
  }

  function actionsFormatter(cell: unknown, row: User) {
    return (
      <div className="align-middle">
        <Button
          className="d-flex justify-content-center align-items-center"
          onClick={() => handleUserDetails(row, false)}
          variant="primary"
        >
          <EditIcon size={16} style={{ marginBottom: '2px' }} />
          &nbsp;Edit
        </Button>
      </div>
    );
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'userType',
      text: 'Type',
      formatter: userTypeFormatter,
    },
    {
      dataField: 'lastLoggedIn',
      text: 'Last Logged In',
      formatter: lastLoggedInFormatter,
    },
  ];

  if (currentUser.isMillenniumUser)
    columns.push({
      dataField: 'applicationClients',
      text: 'Assigned Clients',
      formatter: assignedClientsFormatter,
    });

  columns.push(
    {
      dataField: 'enabled',
      text: 'Enabled',
      formatter: enabledFormatter,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      formatter: actionsFormatter,
    }
  );

  const startItem = (page - 1) * Number(USERS_PER_PAGE) + 1;
  const handleGoToPage = (e) => {
    e.preventDefault();
    const pageNum = parseInt(goToPage, 10);
    if (pageNum >= 1 && pageNum <= pageCount) {
      setPage(pageNum);
      window.scrollTo({ top: 100 });
    }
    setGoToPage('');
  };
  const displayValue = goToPage || (goToPage === '' ? '#' : page.toString());
  const contentTable = ({ paginationProps, paginationTableProps }: PaginationItemProps) => (
    <div>
      <div>
        <div>
          <BootstrapTable
            ref={tableRef}
            bootstrap4
            columns={columns}
            data={data}
            keyField="id"
            loading={fetching}
            noDataIndication={() => 'No Users Found.'}
            onTableChange={onTableChange}
            overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
            remote={{
              filter: true,
              pagination: true,
              sort: true,
            }}
            striped
            wrapperClasses="table-responsive table-borderless"
            {...paginationTableProps}
          />
        </div>
      </div>
      {pageCount > 1 && (
        <div className="my-3 pagination-wrapper">
          <div className="records-showing-text">
            Showing {startItem} of {totalRecords}
          </div>
          <div className="pagination-controls-wrapper">
            <form className="pagination-form-field" onSubmit={handleGoToPage}>
              <span className="me-2 label-text">Go to page</span>
              <input
                aria-label="Page number"
                className="form-control form-control-sm goToPage-input"
                max={pageCount}
                min="1"
                onBlur={() => {
                  if (goToPage === '') {
                    setGoToPage('#');
                  }
                }}
                onChange={(e) => setGoToPage(e.target.value)}
                onFocus={() => {
                  if (goToPage === '' && page) {
                    setGoToPage(page.toString());
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleGoToPage(e);
                  }
                }}
                type="number"
                value={displayValue || '#'}
              />
            </form>

            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Users - Grantrack</title>
      </Helmet>
      <FilterContext.Provider value={{ filters, setFilters }}>
        <Breadcrumbs styles={{ padding: '0px' }} title="Users" />
        <UserFilters />
        <Stack className="users-listing-wrappere" direction="column" spacing={4}>
          <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2}>
            <h3 className="user-listing-heading">Users</h3>
            <Row>
              <InviteUser />

              {currentUser.userType === 'millenniumAdmin' && (
                <Button
                  className="d-flex justify-content-center align-items-center"
                  // onClick={() => setSelectedUser({} as User)}
                  onClick={() => handleUserDetails({} as User, false)}
                  style={{ marginLeft: '15px' }}
                >
                  <Plus size={16} style={{ marginBottom: '2px' }} />
                  &nbsp;Add New Users
                </Button>
              )}
            </Row>
          </Stack>
          <div id="content-table">
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                hideSizePerPage: true,
                withFirstAndLast: pageCount > 5,
                alwaysShowAllBtns: true,
                page,
                totalSize: dataSize,
                sizePerPage: Number(USERS_PER_PAGE),
                firstPageText: '«',
                nextPageText: '›',
                prePageText: '‹',
                lastPageText: '»',
                onPageChange: (currentPage) => {
                  setIsInitialLoad(false);
                  setPage(currentPage);
                },
              })}
            >
              {contentTable}
            </PaginationProvider>
          </div>
        </Stack>
      </FilterContext.Provider>
    </>
  );
}
export default UserList;
