import { AxiosResponse } from 'axios';

import api, { outputError } from 'services/apiService';
import { AwardPayments } from 'types/awards';

export interface AwardPaymentsList {
  rows: AwardPayments[];
  count: number;
  error?: string;
}

export const getAwardPayments = (
  id: string,
  searchParams: string
): Promise<AwardPaymentsList | false> =>
  api
    .get(`/awards/${id}/payments${searchParams}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const createPayment = (id: string, payload: Partial<AwardPayments>): Promise<boolean> =>
  api
    .post(`/awards/${id}/payments`, payload)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deletePayment = (id: string, paymentId: number): Promise<boolean> =>
  api
    .delete(`/awards/${id}/payments/${paymentId}`)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const updatePayment = (id: string, payload: Partial<AwardPayments>): Promise<boolean> =>
  api
    .put(`/awards/${id}/payments`, payload)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const getPayment = (id: number, paymentId: number): Promise<AwardPayments> =>
  api
    .get(`/awards/${id}/payments/${paymentId}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {} as AwardPayments;
    })
    .catch((error) => {
      outputError(error);
      return {} as AwardPayments;
    });
