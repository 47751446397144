import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import useSearchParams from 'hooks/useSearchParams';
import useLocalStorage from 'hooks/useLocalStorage';
import useUserSession from 'hooks/useUserSession';

import {
  AssigneeFilter,
  ClientFilter,
  UsedFiltersDescriptionBlock,
} from 'components/awards/filters';
import FiltersButtonsBlock from './FiltersButtonsBlock';

const defaultFiltersSet = {
  sortBy: 'name',
  sortOrder: 'desc',
  page: '1',
  perPage: '20',
};

export default function FinanceFilters({ canFilter }: { canFilter: boolean }): JSX.Element {
  const { setSearchParams } = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [storedFiltersSet, setStoredFiltersSet] = useLocalStorage<string>(
    'usersAwardsFilters',
    'v1.2'
  );
  const currentUser = useUserSession();

  useEffect(() => {
    if (storedFiltersSet) {
      navigate(storedFiltersSet, { replace: true });
    } else {
      setSearchParams(defaultFiltersSet);
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      setStoredFiltersSet(location.pathname + location.search);
    }
  }, [location]);

  if (!canFilter) return <div />;

  return (
    <>
      <Typography m={2} variant="h2">
        Filters
      </Typography>

      <Stack direction="row" justifyContent="space-between" m={2}>
        <Stack direction="row" flexGrow={1} justifyContent="space-between">
          <AssigneeFilter width={currentUser.isMillenniumUser ? '78%' : '100%'} />
          {currentUser.isMillenniumUser && <ClientFilter width="22%" />}
        </Stack>

        <FiltersButtonsBlock view="userView" />
      </Stack>

      <UsedFiltersDescriptionBlock />
    </>
  );
}
