import { AxiosResponse } from 'axios';
import {
  Client,
  ClientsList,
  GetClientMemoPayload,
  CreateClientPayload,
  UpdateClientPayload,
} from 'types/client';

import api, { outputError } from './apiService';

const { REACT_APP_CLIENTS_PER_PAGE } = process.env;

export const createClient = (data: CreateClientPayload): Promise<Client> =>
  api
    .post(`/clients`, { ...data, target: 'client' })
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {} as Client;
    })
    .catch((error) => {
      outputError(error);
      return {} as Client;
    });

export const getClient = (id: string): Promise<Client> =>
  api
    .get(`/clients/${id}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {} as Client;
    })
    .catch((error) => {
      outputError(error);
      return {} as Client;
    });

export const getClientsList = (filters: string): Promise<ClientsList> =>
  api
    .get(`/clients?${filters}&target=client`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) {
        const { rows, count } = response.data;
        return {
          rows,
          pages: Math.ceil(count / Number(REACT_APP_CLIENTS_PER_PAGE || '20')),
          entries: count,
        };
      }
      return {} as ClientsList;
    })
    .catch((error) => {
      outputError(error);
      return {} as ClientsList;
    });

export const updateClient = (data: UpdateClientPayload): Promise<boolean> =>
  api
    .put(`/clients`, { ...data, target: 'client' })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const searchClients = (searchQuery: string, field: string): Promise<Client[]> =>
  api
    .get(`/clients/search?query=${searchQuery}&field=${field}&target=client`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });

export const getClientMemoData = (data: GetClientMemoPayload): Promise<unknown> =>
  api
    .post(`/clients/memo`, data)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {};
    })
    .catch((error) => {
      outputError(error);
      return {};
    });
