import { ReactNode } from 'react';
import Card from 'react-bootstrap/Card';

function EmptyScreenCard({ title, children }: { title: string; children?: ReactNode }) {
  return (
    <Card className="w-100 py-3 card-empty">
      <Card.Body>
        <h5>{title}</h5>
        {children}
      </Card.Body>
    </Card>
  );
}

export default EmptyScreenCard;
