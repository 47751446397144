import {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
} from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { Eye, Edit } from 'react-feather';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
// @ts-expect-error no types support planned for react-bootstrap-table2
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Box, Stack } from '@mui/material';
import { getApplications } from 'services/applicationService';
import { FilterContext } from 'pages/Applications';
import reduceTZ from 'utils/dateUtils';
import { StringsContext } from 'index';
import filtersConfigs from 'constants/filtersConfigs';
import useLocalStorage from 'hooks/useLocalStorage';
import { Application } from 'types/application';
import { ApplicationFilter } from 'pages/Dashboard';
import { applicationDates, applicationsConfigs } from 'constants/globalConstants';
import CustomTooltip from 'shared/CustomTooltip';
import useUserSession from 'hooks/useUserSession';
import NewApplication from './NewApplication';
import 'assets/scss/ApplicationList.scss';

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);

interface ApplicationsListProps {
  isEditing?: boolean;
  setSelected?: Dispatch<SetStateAction<Application[]>>;
  selected?: Application[];
  appTableRef: unknown;
  cancelMultipleEdit?: () => void;
  checkFilterValues: (filter: ApplicationFilter) => boolean;
}

export default function ApplicationsList(props: ApplicationsListProps) {
  const {
    isEditing,
    setSelected,
    selected: selectedApps,
    appTableRef,
    cancelMultipleEdit,
    checkFilterValues,
  } = props;

  const currentUser = useUserSession();
  const strings = useContext(StringsContext);

  const [storedFilters, setStoredFilters, removeStoredFilters] = useLocalStorage('filters', 'v1.0');
  const [storedFilterSet] = useLocalStorage('setNameApp', 'v1.0');

  const {
    applicationClients: authUserClients,
    userType: authUserType,
    isMillenniumUser,
  } = currentUser;

  const {
    applicationFilters,
    setApplicationFilters,
    applicationsPage: page,
    setApplicationsPage: setPage,
  } = useContext(FilterContext);

  const { REACT_APP_APPLICATIONS_PER_PAGE: APPLICATIONS_PER_PAGE } = process.env;

  const location = useLocation();
  const navigate = useNavigate();

  const [hoveredRow, setHoveredRow] = useState<Application | null>(null);
  const [hoveredCell, setHoveredCell] = useState<HTMLElement | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<ColumnDescription<Application> | null>(null);

  let tooltipText = '';

  if (hoveredRow && hoveredColumn) {
    switch (hoveredColumn.dataField) {
      case 'assignee':
        tooltipText = hoveredRow.assignee ? `Assigned to: ${hoveredRow.assignee}` : 'No assignee';
        break;
      case 'client':
        tooltipText = hoveredRow.client ? `Client: ${hoveredRow.client}` : 'No client';
        break;
      case 'funder':
        tooltipText = hoveredRow.funder ? `Funder: ${hoveredRow.funder}` : 'No funder';
        break;
      case 'programName':
        tooltipText = hoveredRow.programName
          ? `Program: ${hoveredRow.programName}`
          : 'No program name';
        break;
      case 'startsAt':
        tooltipText = hoveredRow.startsAt
          ? `Start Date: ${reduceTZ(hoveredRow.startsAt)}`
          : 'Start date is not set';
        if (hoveredRow.startsAt && new Date(hoveredRow.startsAt) < new Date()) {
          tooltipText += ' (Start date is in the past)';
        }
        break;
      case 'endsAt':
        tooltipText = hoveredRow.endsAt
          ? `End Date: ${reduceTZ(hoveredRow.endsAt)}`
          : 'End date is not set';
        if (hoveredRow.endsAt && new Date(hoveredRow.endsAt) < new Date()) {
          tooltipText += ' (End date is in the past)';
        }
        break;
      case 'status':
        tooltipText = 'Click Edit button to view Application details';
        break;
      default:
        tooltipText = 'Click Edit button to view Application details';
    }
  }

  function dateFormatter(cell: string) {
    return reduceTZ(cell);
  }

  function assigneeFormatter(cell: string) {
    return cell ?? 'None Yet';
  }

  function clientFormatter(cell: string) {
    return cell ?? 'None Yet';
  }

  function statusFormatter(cell: string) {
    return (
      <div className="align-middle d-flex align-items-center">
        <Badge color={`status${cell}`}>
          {applicationsConfigs.statuses.concat(applicationsConfigs.additionalCases)[+cell]}
        </Badge>
      </div>
    );
  }

  const pageName = location?.pathname?.split('/').at(-1);

  const [totalPages, setTotalPages] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<Application[]>([]);
  const [fetching, setFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  const controllerRef = useRef(null);

  const goToEditPage = (applicationId: number) =>
    navigate(`${process.env.PUBLIC_URL}/dashboard/applications/edit/${applicationId}`);

  const refetchList = useCallback(async () => {
    const filter = checkFilterValues(applicationFilters)
      ? applicationFilters
      : storedFilters?.[pageName]?.applicationFilters;
    const queryFilters = {};

    const {
      programNames: filterProgramNames,
      users: filterUsers,
      clients: filterClients,
      funders: filterFunders,
      status: filterStatus,
      startDate: filterStartDate,
      endDate: filterEndDate,
      sortBy: filterSortBy,
      sortOrder: filterSortOrder,
      endDateType: filterEndDateType,
      onlyMe, // Use onlyMe from filters
    } = filter || {};

    if (filterProgramNames && filterProgramNames.length > 0)
      queryFilters.names = filterProgramNames
        .map(({ name }) => name.replace(/,/gm, ',,'))
        .join(',');
    if (filterClients && filterClients.length > 0)
      queryFilters.clients = filterClients.map(({ id }) => id);
    if (filterUsers && filterUsers.length > 0)
      queryFilters.assignees = filterUsers.map(({ id }) => id);
    if (filterFunders && filterFunders.length > 0)
      queryFilters.funders = filterFunders.map(({ name }) => name.replace(/,/gm, ',,')).join(',');
    if (filterStatus) queryFilters.status = filterStatus;
    if (filterStartDate) queryFilters.dateFrom = filterStartDate;
    if (filterEndDate) queryFilters.dateTo = filterEndDate;

    if (filterSortBy) queryFilters.sortBy = filterSortBy;
    if (filterSortOrder) queryFilters.sortOrder = filterSortOrder;

    if (filterEndDateType)
      queryFilters.endDateType =
        applicationDates[
          applicationDates.findIndex(({ name }) => name === filterEndDateType)
        ].serverValue;

    if (onlyMe) {
      queryFilters.target = 'om'; // Add onlyMe to query filters
    }

    const url = new URL(`${process.env.REACT_APP_API_URL}/applications`);
    url.search = new URLSearchParams({
      page: page ?? 1,
      perPage: APPLICATIONS_PER_PAGE,
      ...queryFilters,
    }).toString();

    return getApplications(url.search, controllerRef);
  }, [applicationFilters, page]);

  useEffect(() => {
    if (location.search) {
      refetchList();
    }
  }, [location.search, refetchList]);

  const updateList = () => {
    const startDate = new Date();
    const stop = startDate.getTime() + 300;

    const performListUpdate = (retrievedList: {
      pages: number;
      rows: Application[];
      error: string;
    }) => {
      const { pages, rows, error } = retrievedList;
      const calculatedTotalRecords = pages * Number(APPLICATIONS_PER_PAGE);

      const preparedRows = [];

      for (const currentRow of rows) {
        const {
          id,
          assigneeName,
          clientName,
          funder,
          name,
          startsAt,
          endsAt,
          awardDate,
          notifyDate,
          submissionDate,
          status,
          client,
        } = currentRow;

        preparedRows.push({
          id,
          assignee: assigneeName,
          client: clientName,
          clientId: client?.id ?? 0,
          funder,
          programName: name,
          startsAt,
          endsAt,
          awardDate,
          notifyDate,
          submissionDate,
          status,
          actions: 'edit',
        });
      }

      setTotalPages(pages);
      setTotalRecords(calculatedTotalRecords);
      setData(preparedRows);
      setFetching(false);
      setIsError(error);
    };

    const performFetch = async () => {
      const retrievedList = await refetchList();
      const endDate = new Date();
      const timeRemaining = stop - endDate.getTime();

      if (retrievedList) {
        if (endDate.getTime() > stop) {
          performListUpdate(retrievedList);
        } else {
          setTimeout(() => {
            performListUpdate(retrievedList);
          }, timeRemaining);
        }
      }
    };

    performFetch();
  };

  const canEditApplication = (clientId: number) =>
    isMillenniumUser &&
    authUserType !== 'millenniumAnalyst' &&
    (authUserType === 'millenniumAdmin' ||
      authUserType === 'millenniumManager' ||
      authUserClients?.map(({ id }) => id).includes(clientId));

  function actionsFormatter(cell: string, row: Application) {
    return (
      <div className="align-middle">
        <Button
          className="d-flex justify-content-center align-items-center"
          color="primary"
          onClick={() => goToEditPage(row.id!)}
        >
          {canEditApplication(row.clientId!) ? (
            <>
              <Edit size={16} style={{ marginBottom: '2px' }} />
              &nbsp;Edit
            </>
          ) : (
            <>
              <Eye size={16} style={{ marginBottom: '1px' }} />
              &nbsp;View
            </>
          )}
        </Button>
      </div>
    );
  }

  const eventsHandlers = {
    onMouseEnter: (event: SyntheticEvent, column: ColumnDescription<Application>) => {
      event.stopPropagation();
      const cell = event.currentTarget as HTMLElement;
      setHoveredCell(cell);
      setHoveredColumn(column);
    },
    onMouseLeave: (event: SyntheticEvent) => {
      event.stopPropagation();
      setHoveredCell(null);
      setHoveredRow(null);
    },
  };

  const getColumns = () => {
    const endDateText = (applicationFilters && applicationFilters.endDateType) || 'Due Date';
    const endDateTextDataField =
      applicationDates[applicationDates.findIndex(({ name }) => name === endDateText)].serverValue;

    return [
      {
        dataField: 'assignee',
        text: 'Assigned To',
        formatter: assigneeFormatter,
        sort: true,
        events: eventsHandlers,
      },
      {
        dataField: 'client',
        text: 'Client',
        formatter: clientFormatter,
        sort: true,
        events: eventsHandlers,
      },
      {
        dataField: 'funder',
        text: 'Funder',
        sort: true,
        events: eventsHandlers,
      },
      {
        dataField: 'programName',
        text: 'Grant Program',
        sort: true,
        events: eventsHandlers,
      },
      {
        dataField: 'startsAt',
        text: 'Start Date',
        formatter: dateFormatter,
        classes: 'start-date-col',
        sort: true,
        events: eventsHandlers,
      },
      {
        dataField: endDateTextDataField,
        text: endDateText,
        formatter: dateFormatter,
        classes: 'due-date-col',
        sort: true,
        events: eventsHandlers,
      },
      {
        dataField: 'status',
        text: 'Status',
        classes: 'status-col',
        formatter: statusFormatter,
        events: eventsHandlers,
      },
      {
        dataField: 'actions',
        text: 'Actions',
        classes: 'actions-col',
        formatter: actionsFormatter,
      },
    ];
  };

  const [columns, setColumns] = useState(getColumns());

  useEffect(() => {
    const controller = new AbortController();
    controllerRef.current = controller;
    updateList();

    return () => {
      if (controllerRef.current) controllerRef?.current?.abort();
    };
  }, [applicationFilters, page, refetchList]);

  const onTableChange = (type, newState) => {
    if (type === 'sort') {
      const { sortField, sortOrder } = newState;
      const { sortByFiltered, sortOrderFiltered } = applicationFilters;

      if (sortOrderFiltered !== sortOrder || sortByFiltered !== sortField) {
        if (appTableRef?.current?.paginationContext)
          appTableRef.current.paginationContext.currPage = 1;

        setPage(1);

        setApplicationFilters({
          ...applicationFilters,
          sortBy: sortField,
          sortOrder,
        });
      }
    }
  };

  const selectRow = {
    text: 'Select',
    mode: 'checkbox',
    clickToSelect: !!isEditing,
    classes: 'table-row-selected',
    style: { backgroundColor: '#D7E3DC' },
    onSelect: (row, isSelected) => {
      if (isSelected) {
        setSelected(selectedApps.length ? [...selectedApps, row] : [row]);
      } else {
        setSelected(selectedApps?.filter((item) => item.id !== row.id));
      }
    },
    onSelectAll: (isSelected, rows) => {
      if (isSelected) {
        setSelected(selectedApps?.length ? [...selectedApps, ...rows] : rows);
      } else {
        const currentSelection = new Set(rows?.map((row) => row.id));
        const list = selectedApps?.filter((app) => !currentSelection.has(app.id));
        setSelected(list);
      }
    },
  };

  useEffect(() => {
    if (location?.pathname?.match(/dashboard|applications/) && isEditing) {
      cancelMultipleEdit?.();
    }
  }, [applicationFilters]);

  useEffect(() => {
    if (storedFilters?.id && storedFilters?.id !== currentUser.id) removeStoredFilters();
    else if (checkFilterValues(applicationFilters) && !storedFilterSet) {
      const filter = storedFilters
        ? {
            ...storedFilters,
            [pageName]: { ...storedFilters[pageName], applicationFilters },
            id: currentUser.id,
          }
        : { [pageName]: { applicationFilters, id: currentUser.id } };

      setStoredFilters(filter);
    }

    setColumns(getColumns());
  }, [applicationFilters, storedFilterSet]);

  const rowClasses = (row: Application) => {
    let classes = null;

    if (!strings?.applications?.statuses) return classes;

    const statusKeys = Object.keys(strings.applications.statuses);
    const status =
      statusKeys.filter((s) => strings.applications.statuses[s] === row.status)[0] || statusKeys[0];

    if (location?.pathname?.includes('user-dashboard')) {
      if (
        dayjs(row.endsAt).isBefore(dayjs(), 'day') &&
        ['notStarted', 'clientNotified', 'inProgress'].includes(status)
      )
        classes = 'expired-row';

      if (
        dayjs(row.endsAt).isBetween(dayjs().subtract(1, 'days'), dayjs().add(2, 'days')) &&
        ['notStarted', 'clientNotified', 'inProgress'].includes(status)
      )
        classes = 'urgent-row';

      if (
        dayjs(row.startsAt).isBetween(dayjs().subtract(1, 'days'), dayjs().add(2, 'days')) &&
        ['notStarted', 'clientNotified', 'inProgress'].includes(status)
      )
        classes = 'urgent-row-start';
    } else {
      if (
        dayjs(row.endsAt).isBefore(dayjs(), 'day') &&
        ['notStarted', 'clientNotified', 'inProgress'].includes(status)
      )
        classes = 'expired-row';

      if (
        dayjs(row.endsAt).isBetween(dayjs().subtract(1, 'days'), dayjs().add(1, 'week')) &&
        ['notStarted', 'clientNotified', 'inProgress'].includes(status)
      )
        classes = 'urgent-row';
    }

    return classes;
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    const pageConfig = (
      pageName === 'user-dashboard' ? filtersConfigs.dashboard : filtersConfigs.grants
    ).applications.default;

    const sortObj = {
      dataField: applicationFilters?.sortBy || pageConfig.sortBy,
      order: applicationFilters?.sortOrder || pageConfig.sortOrder,
    };

    // Calculate total items
    const startItem = (page - 1) * Number(APPLICATIONS_PER_PAGE) + 1;
    const handleGoToPage = (e) => {
      e.preventDefault();
      const pageNum = parseInt(goToPage, 10);
      if (pageNum >= 1 && pageNum <= totalPages) {
        setPage(pageNum);
        window.scrollTo({ top: 100 });
      }
      setGoToPage('');
    };
    const displayValue = goToPage || (goToPage === '' ? '#' : page.toString());
    return (
      <div>
        <CustomTooltip hoveredCell={hoveredCell} tooltipText={tooltipText} />
        <div>
          <div
            className={`application-content__list ${
              isEditing
                ? 'application-content__list--active'
                : 'application-content__list--dissabled'
            }`}
          >
            <BootstrapTable
              ref={appTableRef}
              bootstrap4
              columns={columns}
              data={data}
              defaultSorted={[sortObj]}
              keyField="id"
              loading={fetching}
              noDataIndication={() =>
                isError
                  ? 'Error loading Applications. Reset filters or try again'
                  : 'No Applications Found.'
              }
              onTableChange={onTableChange}
              overlay={overlayFactory({
                spinner: true,
                background: 'rgba(192,192,192,0.3)',
              })}
              remote={{
                filter: true,
                pagination: true,
                sort: true,
              }}
              rowClasses={rowClasses}
              sort={sortObj}
              striped
              wrapperClasses="table-responsive table-borderless"
              {...paginationTableProps}
              rowEvents={{
                onMouseEnter: (_e: SyntheticEvent, row: Application) => {
                  setHoveredRow(row);
                },
                onMouseLeave: () => {
                  setHoveredRow(null);
                  setHoveredCell(null);
                },
              }}
              selectRow={selectRow}
            />
          </div>
        </div>
        {totalPages > 1 && (
          <div className="my-3 pagination-wrapper">
            <div className="records-showing-text">
              Showing {startItem} of {totalRecords}
            </div>

            <div className="pagination-controls-wrapper">
              <form className="pagination-form-field" onSubmit={handleGoToPage}>
                <span className="me-2 label-text">Go to page</span>
                <input
                  aria-label="Page number"
                  className="form-control form-control-sm goToPage-input"
                  max={totalPages}
                  min="1"
                  onBlur={() => {
                    if (goToPage === '') {
                      setGoToPage('#');
                    }
                  }}
                  onChange={(e) => setGoToPage(e.target.value)}
                  onFocus={() => {
                    if (goToPage === '' && page) {
                      setGoToPage(page.toString());
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleGoToPage(e);
                    }
                  }}
                  type="number"
                  value={displayValue || '#'}
                />
              </form>

              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Stack className="application-listing-wrapper" direction="column" spacing={2}>
      <h3 className="application-heading">Applications</h3>
      {!location?.pathname.includes('user-dashboard') && (
        <h5 className="application-tagline">
          See list of applications with possibility to edit and/or add new.{' '}
        </h5>
      )}
      <div id="content-table">
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            hideSizePerPage: true,
            withFirstAndLast: totalPages > 5,
            alwaysShowAllBtns: true,
            page,
            totalSize: totalPages * 20,
            sizePerPage: Number(APPLICATIONS_PER_PAGE),
            firstPageText: '«',
            nextPageText: '›',
            prePageText: '‹',
            lastPageText: '»',
            onPageChange: (currentPage) => {
              setPage(currentPage);
              window.scrollTo({ top: 100 });
            },
          })}
        >
          {contentTable}
        </PaginationProvider>
        {!location?.pathname.includes('user-dashboard') &&
          (currentUser.userType === 'millenniumAdmin' ||
            currentUser.userType === 'millenniumManager') && (
            <div>
              <NewApplication onCreation={updateList} />
            </div>
          )}
      </div>
    </Stack>
  );
}
