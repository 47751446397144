import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import ForgotPasswordForm from 'components/auth/forgotPasswordForm';
import authImage from 'assets/images/authwrapperImg.png';
import AuthWrapper from 'components/auth/authWrapper';

function ForgotPassword() {
  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Forgot Password - Grantrack</title>
      </Helmet>
      <AuthWrapper imageSrc={authImage}>
        <ForgotPasswordForm />
      </AuthWrapper>
    </>
  );
}

export default ForgotPassword;
