import { useEffect, useState } from 'react';
import { AsyncTypeahead, Highlighter, Token } from 'react-bootstrap-typeahead';
import Form from 'react-bootstrap/Form';

import { User } from 'types/user';
import { searchClients } from 'services/clientService';
import { ClientOption } from './EditUserModal';
import 'assets/scss/editUser.scss';

export default function AssignedToPicker({
  user,
  readOnly = false,
  onChange,
}: {
  user: User;
  readOnly: boolean;
  onChange: (selectedUsers: ClientOption[]) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<ClientOption[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<ClientOption[]>([]);

  useEffect(() => {
    if (user?.applicationClients) {
      setSelectedUsers(
        user?.applicationClients.map((client) => ({
          id: client.id,
          name: client.name,
          primary: client.id === user?.primaryClientId,
        }))
      );
    }
  }, [user]);

  useEffect(() => {
    onChange(selectedUsers);
  }, [selectedUsers]);

  const onClientSearch = async (query: string) => {
    setLoading(true);

    const foundClients = await searchClients(query, 'name');

    const mappedClients = foundClients.map((e) => ({
      id: e.id,
      name: e.name,
      primary: e.id === user?.primaryClientId,
    }));

    setOptions(mappedClients);
    setLoading(false);
  };

  const changePrimaryClient = (selectedClient: ClientOption) => {
    if (selectedClient.primary) {
      selectedClient.primary = false;
    } else {
      const currentPrimaryClient = selectedUsers.findIndex((e) => e.primary);
      if (currentPrimaryClient !== -1) selectedUsers[currentPrimaryClient].primary = false;

      selectedClient.primary = true;
    }

    setSelectedUsers([...selectedUsers]);
  };

  const onClientRemoved = (selectedClient: ClientOption) => {
    if (selectedClient.primary) {
      selectedClient.primary = false;
    }
  };

  const renderTokens = (
    {
      inputRef,
      referenceElementRef,
      ...props
    }: {
      inputRef: (input: HTMLInputElement) => void;
      referenceElementRef: (input: HTMLInputElement) => void;
    },
    {
      onRemove,
      selected = [],
    }: { onRemove: (client: ClientOption) => void; selected: ClientOption[] }
  ) => (
    <>
      <input
        {...props}
        ref={(input) => {
          if (input) {
            referenceElementRef(input);
            inputRef(input);
          }
        }}
        className="form-control"
        type="text"
      />
      <div style={{ marginTop: '10px' }}>
        {selected.map((client: ClientOption) => (
          <Token
            key={client.id}
            className={client.primary ? 'rbt-token-primary' : ''}
            onClick={() => changePrimaryClient(client)}
            onRemove={() => {
              onClientRemoved(client);
              onRemove(client);
            }}
            option={client}
            readOnly={readOnly}
          >
            {client.name}
          </Token>
        ))}
      </div>
    </>
  );

  return (
    <>
      <AsyncTypeahead
        disabled={readOnly}
        id="editUser.assignedToPicker"
        isLoading={loading}
        labelKey="name"
        minLength={1}
        multiple
        onChange={setSelectedUsers}
        onSearch={onClientSearch}
        options={options}
        placeholder={readOnly ? 'No permissions for editing this list.' : 'Search for a client...'}
        // @ts-expect-error TODO: check InputContainerPropsSingle<ClientUser> props, this works however
        renderInput={renderTokens}
        renderMenuItemChildren={(option, props) => (
          <Highlighter search={props.text!}>{option.name}</Highlighter>
        )}
        selected={selectedUsers}
      />
      {!readOnly && (
        <Form.Text className="currency-text">
          Start typing the client&apos;s name in the box.
        </Form.Text>
      )}
    </>
  );
}
