import { useEffect, useState } from 'react';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import { updateIfChanged } from 'utils/utilFunctions';
import { searchFunders } from 'services/funderService';
import { Filter } from 'types/filter';

interface FunderFilterProps {
  filters: Filter[];
  onChange: (filters: { name: string }[]) => void;
  sourceOfSearch?: string;
  cFilters?: Filter[];
  width?: string;
}

export default function FunderFilter({
  filters,
  onChange,
  sourceOfSearch = 'applications',
  cFilters = [],
  width,
}: FunderFilterProps) {
  const [currentFilters, setCurrentFilters] = useState<{ name: string }[]>(
    Array.isArray(filters) ? filters : []
  );
  const handleChange = (values: string[]) => {
    const newFilters = values.map((name) => ({ name }));
    setCurrentFilters(newFilters);
    onChange(newFilters);
  };
  const onSearch = async (query: string) => {
    const params = new URLSearchParams({
      query,
      field: 'name',
      source: sourceOfSearch,
    });
    if (cFilters?.length > 0) {
      params.append('clients', cFilters.map((el) => el.id).join(','));
    }
    const fundersFound = await searchFunders(params.toString());
    return fundersFound.map(({ funder }) => funder);
  };
  useEffect(() => {
    updateIfChanged(setCurrentFilters, Array.isArray(filters) ? filters : []);
  }, [filters]);
  return (
    <SearchFilter<string>
      getLabel={(f) => f}
      label="Funder"
      onChange={handleChange}
      onSearch={onSearch}
      selected={currentFilters.map((f) => f.name)}
      variant="filter"
      width={width}
    />
  );
}
