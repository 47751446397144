import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Plus } from 'react-feather';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Client, ClientContact, CreateClientContactPayload } from 'types/client';

import { createClientContact, getClientContacts } from 'services/clientContactsService';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { TextInput } from 'shared/inputs';
import EditClientContacts from './EditClientContacts';
import 'assets/scss/Clientcontacts.scss';

interface ClientContactsProps {
  client: Client;
  readOnly?: boolean;
}

export default function ClientContacts({ client, readOnly = false }: ClientContactsProps) {
  const [contacts, setContacts] = useState<ClientContact[]>([]);
  const [iscontactsVisible, setIsContactsVisible] = useState(true);

  const formRef = useRef<HTMLFormElement>(null);

  const refetch = useCallback(() => {
    getClientContacts(client.id).then((response) => setContacts(response));
  }, []);

  const formik = useFormik<CreateClientContactPayload>({
    initialValues: {
      clientId: client.id,
      name: '',
      email: '',
      phone: '',
      title: '',
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required('Please enter name')
        .min(2, 'Name should be at least 2 characters'),
      email: Yup.string().trim().required('Please enter email').email('Please enter valid email'),
      phone: Yup.string()
        .required('Please enter phone number')
        .matches(
          /^\+?(\d{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})$/,
          'Please enter valid phone number'
        ),
      title: Yup.string().trim().required('Please enter title'),
    }),

    onSubmit: async () => {
      const response = await createClientContact(formik.values);

      if (response) {
        tossSuccess(`New contact to client ${client?.name} added successfully.`);
        refetch();
        formik.resetForm();
        formRef.current?.reset();
      } else
        tossError(
          'There was an error adding new contact, please make sure all fields are entered and try again.'
        );
    },
  });

  useEffect(refetch, []);

  return (
    <div className="client-contact-wrapper">
      <div
        className="hide-show-wrapper"
        onClick={() => setIsContactsVisible(!iscontactsVisible)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsContactsVisible(!iscontactsVisible);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <h4 className="client-contact-heading">
          Contacts
          <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
            {iscontactsVisible ? (
              <>
                <KeyboardArrowUp className="ms-2" style={{ color: '#2C4474', fontSize: '18px' }} />
                <span className="show-hide-text">Hide Section</span>
              </>
            ) : (
              <>
                <KeyboardArrowDown
                  className="ms-2"
                  style={{ color: '#2C4474', fontSize: '18px' }}
                />{' '}
                <span className="show-hide-text">Show Section</span>
              </>
            )}
          </span>
        </h4>
      </div>

      {iscontactsVisible && (
        <Form ref={formRef}>
          <Container className="client-contacts-form" fluid>
            <Row>
              {contacts.length > 0 ? (
                <div className="table-responsive table-hover borderless">
                  <Table>
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Title</th>
                        {!readOnly && <th scope="col">Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((item) => (
                        <tr key={item.name + item.email}>
                          <td className="align-middle">{item.name}</td>
                          <td className="align-middle">
                            {item.email ?? <Form.Text muted>Not Set</Form.Text>}
                          </td>
                          <td className="align-middle">
                            {item.phoneNumber ?? <Form.Text muted>Not Set</Form.Text>}
                          </td>
                          <td className="align-middle">
                            {item.title ?? <Form.Text muted>Not Set</Form.Text>}
                          </td>
                          {!readOnly && (
                            <td className="align-middle d-flex">
                              <EditClientContacts contact={item} onSave={refetch} />
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                'No contacts added yet.'
              )}
              <div className="mt-4">&nbsp;</div>

              {!readOnly && (
                <Container fluid>
                  <Row className="d-flex align-items-start justify-content-between">
                    <Col className="px-2 py-0">
                      <TextInput
                        className="common-input"
                        controlId="addContact.Name"
                        disabled={readOnly}
                        errors={formik.touched.name ? formik.errors.name : ''}
                        label="Name"
                        onBlur={() => formik.setFieldTouched('name', true)}
                        onChange={formik.handleChange('name')}
                        placeholder="Enter Contact Name"
                      />
                    </Col>
                    <Col className="px-2 py-0">
                      <TextInput
                        className="common-input"
                        controlId="addContact.Email"
                        disabled={readOnly}
                        errors={formik.touched.email ? formik.errors.email : ''}
                        label="E-mail address"
                        onBlur={() => formik.setFieldTouched('email', true)}
                        onChange={(newValue: string) => formik.setFieldValue('email', newValue)}
                        placeholder="example@email.com"
                      />
                    </Col>
                    <Col className="px-2 py-0">
                      <TextInput
                        className="common-input"
                        controlId="addContact.Phone"
                        disabled={readOnly}
                        errors={formik.touched.phone ? formik.errors.phone : ''}
                        label="Phone Number"
                        onBlur={() => formik.setFieldTouched('phone', true)}
                        onChange={(newValue: string) => formik.setFieldValue('phone', newValue)}
                        placeholder="+1 (XXX) XXX-XXXX"
                      />
                    </Col>
                    <Col className="px-2 py-0">
                      <TextInput
                        className="common-input"
                        controlId="addContact.Title"
                        disabled={readOnly}
                        errors={formik.touched.title ? formik.errors.title : ''}
                        label="Title"
                        onBlur={() => formik.setFieldTouched('title', true)}
                        onChange={(newValue: string) => formik.setFieldValue('title', newValue)}
                        placeholder="Enter Title"
                      />
                    </Col>
                    <Col className="d-flex align-items-center px-2 py-0">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        color="secondary-blue"
                        disabled={readOnly || !formik.isValid}
                        onClick={formik.submitForm}
                        style={{ marginTop: '28px' }}
                        variant="secondary-blue"
                      >
                        <Plus size={16} style={{ marginBottom: '1px' }} />
                        &nbsp;Add Contact
                      </Button>
                    </Col>
                  </Row>
                </Container>
              )}
            </Row>
          </Container>
        </Form>
      )}
    </div>
  );
}
