/* eslint-disable no-nested-ternary */
import { useState, useEffect, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  ArrowBack,
  Share,
  ExpandMore,
  Close,
  OpenInFull,
  ZoomOutMap,
} from '@mui/icons-material';
import { Box, Stack, IconButton, Modal, Typography } from '@mui/material';
import { Check, X as CloseIcon } from 'react-feather';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router';
import Dropzone, { IFileWithMeta, IUploadParams, StatusValue } from 'react-dropzone-uploader';
import logger from 'services/logger';
import './programs.css';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { TextInput, FunderInput, MoneyInputNew, MultiSelectInput } from 'shared/inputs';
import DateField from 'components/applications/EditForm/DateField';
import reduceTZ from 'utils/dateUtils';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CustomInput } from 'reactstrap';
import { useProgramContext } from 'hooks/ProgramContext';
import useUserSession from 'hooks/useUserSession';
import CustomFields from 'shared/CustomFields';
import { deleteprogramFile, markProgramsAsRead, updateProgram } from 'services/programService';
import { Program } from 'types/program';
import api from 'services/apiService';
import useLocalStorage from 'hooks/useLocalStorage';
import ContactMillenniumModal from 'shared/ContactMillenniumModal';
import {
  applicationsConfigs,
  getCountiesForStates,
  OrgTypes,
  states,
} from 'constants/globalConstants';
import DeleteProgram from './DeleteProgram';
import 'assets/scss/programDetailsnew.scss';

// Set up the worker from pdfjs-dist
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.mjs',
  import.meta.url
).toString();

export default function ProgramDetails() {
  const [form, setForm] = useState<Partial<Program>>({});
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const [errors, setErrors] = useState<Partial<Record<keyof Program, string>>>({});
  const [files, setFiles] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { programDetailsProps, setProgramDetailsProps } = useProgramContext();
  const { program, readOnly, disabled, onSave, readList } = programDetailsProps || {};
  const [storedProgramDetails, storeProgramDetails] = useLocalStorage('programDetailsProps', 'v1');

  useEffect(() => {
    if (programDetailsProps.program === null && storedProgramDetails) {
      setProgramDetailsProps(
        storedProgramDetails as {
          program: Program;
          onSave: () => void;
          readList: (userId: number) => Promise<void>;
          readOnly: boolean;
          disabled: boolean;
        }
      );
    }
  }, [programDetailsProps, storedProgramDetails, setProgramDetailsProps]);

  useEffect(() => {
    if (programDetailsProps?.program) {
      const getCategoryValues = (categoryData) => {
        if (!categoryData) return [];
        const categories = Array.isArray(categoryData) ? categoryData : [categoryData];
        return categories.map((cat) => applicationsConfigs.categories[cat]);
      };
      setForm({
        name: program?.name,
        funder: program?.funder,
        fundingAmount: program?.fundingAmount,
        varyingFundingAmount: program?.varyingFundingAmount,
        amountVaries: program?.amountVaries,
        source: Array.isArray(program?.source)
          ? program?.source
          : program?.source
            ? [program.source]
            : [],
        category: getCategoryValues(program?.category),
        startsAt: program?.startsAt,
        endsAt: program?.endsAt,
        matchRequirements: program?.matchRequirements,
        performancePeriod: program?.performancePeriod,
        estimatedResponse: program?.estimatedResponse,
        customFields: program?.customFields,
        states: Array.isArray(program?.states)
          ? program.states
          : program?.states
            ? [program.states]
            : [],
        counties: Array.isArray(program?.counties)
          ? program.counties
          : program?.counties
            ? [program.counties]
            : [],
        orgTypes: Array.isArray(program?.orgTypes)
          ? program.orgTypes
          : program?.orgTypes
            ? [program.orgTypes]
            : [],
        showForFlexClient: program?.showForFlexClient ?? true,
      });
    }
  }, [programDetailsProps]);

  const currentUser = useUserSession();
  const IsUserAdmin = currentUser.userType === 'millenniumAdmin';
  const IsClient = currentUser.applicationClients;
  const location = useLocation();
  const navigate = useNavigate();
  const isFromPrograms = true;
  const setField = (
    field: string,
    value: string | number | boolean | null | undefined | number[] | string[]
  ) => setForm((prevState) => ({ ...prevState, [field]: value }));

  const setAsRead = async () => {
    await markProgramsAsRead({ selected: [program], currentUser });

    if (readList) {
      await readList(currentUser.id);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const toggleSectionVisibility = () => {
    setIsSectionVisible((prev) => !prev);
  };

  const save = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to edit this program?')) {
      const {
        name,
        funder,
        fundingAmount,
        varyingFundingAmount,
        amountVaries,
        source,
        category,
        startsAt,
        endsAt,
        matchRequirements,
        performancePeriod,
        estimatedResponse,
        customFields,
        states: selectedStates,
        counties: selectedCounties,
        orgTypes,
        showForFlexClient,
      } = form;

      const submittedFields = {
        id: program?.id,
        name,
        funder,
        fundingAmount: fundingAmount ?? 0.0,
        varyingFundingAmount,
        amountVaries: amountVaries ?? false,
        source: Array.isArray(source) ? source : source ? [source] : [],
        startsAt,
        endsAt,
        matchRequirements,
        performancePeriod,
        estimatedResponse,
        customFields: JSON.stringify(customFields ?? []),
        category: Array.isArray(category)
          ? category.map((cat) => applicationsConfigs.categories.indexOf(cat))
          : category
            ? [applicationsConfigs.categories.indexOf(category)]
            : [],
        states: Array.isArray(selectedStates)
          ? selectedStates
          : selectedStates
            ? [selectedStates]
            : [],
        counties: Array.isArray(selectedCounties)
          ? selectedCounties
          : selectedCounties
            ? [selectedCounties]
            : [],
        orgTypes: Array.isArray(orgTypes) ? orgTypes : orgTypes ? [orgTypes] : [],
        showForFlexClient: showForFlexClient ?? true,
      };
      console.log({ submittedFields });

      const result = await updateProgram(submittedFields);

      if (result) {
        logger.info('Updated a program.', { program: submittedFields });
        tossSuccess('The program was successfully edited.');
        if (onSave) onSave();
        const updatedProgramDetailsProps = {
          ...programDetailsProps,
          program: { ...program, ...submittedFields },
        };
        storeProgramDetails(updatedProgramDetailsProps);
        navigate(-1);
      } else {
        logger.error('Error updating a program.', { program: submittedFields });
        tossError('Error editing the program.');
      }
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await api.get(`/programs/files/${program?.id}`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
      tossError('Error fetching files.');
    }
  };
  useEffect(() => {
    if (program?.id) {
      fetchFiles();
    }
  }, [program?.id]);
  const getUploadParams = (fileWithMeta: IFileWithMeta) => {
    if (!fileWithMeta.meta.previewUrl) {
      fileWithMeta.meta.previewUrl = URL.createObjectURL(fileWithMeta.file);
    }
    const body = new FormData();
    body.append('programId', program?.id.toString());
    body.append('file', fileWithMeta.file);
    body.append('size', fileWithMeta.meta.size.toString());
    body.append('name', fileWithMeta.meta.name);
    body.append('fileUrl', fileWithMeta.meta.previewUrl || '');
    body.append('type', fileWithMeta.meta.type);

    return {
      url: `${process.env.REACT_APP_API_URL}/programs/programfile`,
      body,
      headers: {
        Authorization: api.client?.defaults.headers.common.Authorization,
      },
    } as IUploadParams;
  };

  const handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      fileWithMeta.remove();
      fetchFiles();
    }
  };

  useEffect(() => {
    if (program) {
      setField('startsAt', program?.startsAt);
      setField('endsAt', program?.endsAt?.toLocaleString());
    }
  }, [program]);

  useEffect(() => {
    const { name, funder, fundingAmount, amountVaries, source, category, startsAt, endsAt } = form;

    const errorList: Partial<Record<keyof Program, string>> & {
      undefineds?: string;
    } = {};

    if (typeof name !== 'undefined') {
      if (!name || name === '') errorList.name = 'This field is required.';
      else if (name.length < 3)
        errorList.name = 'Grant program names must have 3 or more characters.';
    }

    if (typeof startsAt !== 'undefined' && typeof endsAt !== 'undefined') {
      if (startsAt && endsAt) {
        if (dayjs(endsAt).isBefore(dayjs(startsAt))) {
          errorList.startsAt = 'Start Date must be earlier than Due Date.';
          errorList.endsAt = 'Due Date must be later than Start Date.';
        }
      }
    }

    if (Number.isNaN(fundingAmount) && !amountVaries)
      errorList.fundingAmount = 'Enter a valid funding amount.';

    if (
      [typeof name, typeof funder, typeof fundingAmount, typeof source, typeof category].includes(
        'undefined'
      )
    )
      errorList.undefineds = 'One or more required fields are undefined.';

    setErrors(errorList);
  }, [form]);

  const handleDeleteFile = async (fileId: number) => {
    try {
      const result = await deleteprogramFile(fileId);
      if (result) {
        tossSuccess('The file has been deleted successfully.');
        fetchFiles();
      } else {
        tossError('Failed to delete this file.');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlediscard = () => {
    if (program) {
      setForm({
        name: program?.name,
        funder: program?.funder,
        fundingAmount: program?.fundingAmount,
        varyingFundingAmount: program?.varyingFundingAmount,
        amountVaries: program?.amountVaries,
        source: Array.isArray(program?.source)
          ? program?.source
          : program?.source
            ? [program.source]
            : [],
        category: Array.isArray(program?.category)
          ? program?.category
          : program?.category
            ? [program.category]
            : [],
        startsAt: program?.startsAt,
        endsAt: program?.endsAt,
        matchRequirements: program?.matchRequirements,
        performancePeriod: program?.performancePeriod,
        estimatedResponse: program?.estimatedResponse,
        customFields: program?.customFields,
      });
    }
  };
  const handleCopy = useCallback(() => {
    tossSuccess('Link copied to clipboard');
  }, []);

  const onDocumentLoadSuccess = ({ numPages: totalNumPages }) => {
    setNumPages(totalNumPages);
  };

  const handleExpandPdf = () => {
    setShowPdfModal(true);
  };

  const getCategoryDisplayValues = (
    categoryValues: string[] | string | number[] | number | undefined
  ) => {
    if (!categoryValues) return [];
    const values = Array.isArray(categoryValues) ? categoryValues : [categoryValues];
    return values
      .map((value) => {
        if (typeof value === 'number') {
          return applicationsConfigs.categories[value];
        }
        if (typeof value === 'string' && applicationsConfigs.categories.includes(value)) {
          return value;
        }
        return '';
      })
      .filter(Boolean);
  };

  const getSourceDisplayValues = (sourceIndices: number[] | number | undefined) => {
    if (!sourceIndices) return [];
    const indices = Array.isArray(sourceIndices) ? sourceIndices : [sourceIndices];
    return indices.map((index) => applicationsConfigs.sources[index]).filter(Boolean);
  };

  return (
    <Container className="program-detail-form" fluid>
      <Stack alignItems="space-between" direction="column" mb={4} spacing={1}>
        <Box mb={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <span
              className="breadcrumb-list"
              onClick={() => navigate(-1)}
              onKeyDown={(e) => e.key === 'Enter' && navigate(-1)}
              role="button"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              tabIndex={0}
            >
              Programs
            </span>
            <span>{'>'}</span>
            <span className="breadcrumb-list" style={{ fontWeight: '700' }}>
              Program Details
            </span>
          </Stack>
        </Box>
        <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <IconButton
              className="back-button"
              onClick={() => navigate(-1)}
              sx={{
                width: '34px',
                height: '34px',
                backgroundColor: '#eff1f6',
                borderRadius: '6px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: '#e0e3eb',
                },
              }}
            >
              <ArrowBack />
            </IconButton>

            <h5 className="heading">Program Details #NH{program?.id} </h5>
          </Stack>
          <Stack direction="row" spacing={1}>
            {!readOnly ? (
              <>
                <Button className="discard-btn" onClick={handlediscard} variant="light">
                  Discard
                </Button>
                <DeleteProgram
                  disabled={disabled}
                  onDelete={() => onSave?.()}
                  programId={program?.id}
                />
                <Button
                  className="d-flex justify-content-center align-items-center"
                  disabled={Object.keys(errors).length > 0}
                  onClick={save}
                >
                  <Check size={16} />
                  &nbsp;Save
                </Button>
              </>
            ) : (
              <>
                <Button className="discard-btn d-flex align-items-center" variant="light">
                  <CopyToClipboard onCopy={handleCopy} text={window.location.href}>
                    <span className="d-flex align-items-center">
                      <Share />
                      &nbsp; Share
                    </span>
                  </CopyToClipboard>
                </Button>
                <Button
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => setShowContactModal(true)}
                >
                  Contact Millennium
                </Button>
              </>
            )}
          </Stack>
        </Stack>

        <h6 className="sub-heading">Edit Program details</h6>
      </Stack>
      <Form>
        <div className="general-fields-wrapper">
          <Row>
            <Col lg={6} xs={12}>
              <h5 className="heading-gb mb-4">Grant Summary File</h5>
              <div
                className="file-uploader"
                style={{ height: files.length === 0 ? '90%' : 'auto' }}
              >
                {files?.length === 0 ? (
                  <div className="dz-message tall needsclick">
                    <Dropzone
                      disabled={readOnly}
                      getUploadParams={getUploadParams}
                      inputContent="Drag Files Here"
                      onChangeStatus={handleChangeStatus}
                    />
                  </div>
                ) : (
                  <div className="pdf-file-display-container">
                    <div className="file-display-link">
                      <Document file={files[0]?.pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page key={index} pageNumber={index + 1} />
                        ))}{' '}
                      </Document>
                    </div>
                    <Button className="expand-icon" onClick={handleExpandPdf} variant="light">
                      <ZoomOutMap />
                    </Button>
                  </div>
                )}
              </div>
              {!(files?.length === 0) && (
                <Stack
                  alignItems="center"
                  direction="row"
                  sx={{
                    justifyContent: 'flex-start',
                    textAlign: 'center',
                    color: '#2C4474',
                    gap: '5px',
                    marginTop: '20px',
                  }}
                >
                  <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />
                  <a
                    download={files[0].pdfName}
                    href={files[0].pdfUrl}
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    target="_blank"
                  >
                    <Typography sx={{ cursor: 'pointer' }} variant="body2">
                      {files[0]?.pdfName}
                    </Typography>
                  </a>
                  <IconButton
                    onClick={() => handleDeleteFile(program?.id)}
                    size="small"
                    sx={{ color: '#2C4474' }}
                  >
                    <Close />
                  </IconButton>
                </Stack>
              )}
            </Col>
            <Col lg={6} xs={12}>
              <h5 className="heading-gb mb-4">General Fields</h5>
              {!readOnly ? (
                <FunderInput
                  className="common-input"
                  controlId="createProgram.Funder"
                  defaultValue={program?.funder}
                  disabled={readOnly}
                  onChange={(newValue) => setField('funder', newValue)}
                  placeholder="Start typing a name..."
                />
              ) : (
                <div className="field-heading-wraper">
                  <div className="field-heading">Funder</div>
                  <div className="field-value">{program?.funder}</div>
                </div>
              )}

              <Row>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <TextInput
                      className="common-input"
                      controlId="createProgram.Name"
                      defaultValue={program?.name}
                      disabled={readOnly}
                      errors={errors.name}
                      label="Grant Program Name"
                      onChange={(newValue) => setField('name', newValue)}
                      placeholder="My Grant Program"
                      required
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Grant Program Name</div>
                      <div className="field-value">{program?.name}</div>
                    </div>
                  )}
                </Col>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <MultiSelectInput
                      choices={OrgTypes}
                      className="common-input"
                      controlId="createProgram.OrgTypes"
                      defaultValue={program?.orgTypes || []}
                      disabled={readOnly}
                      isIndexBased={false}
                      label="Org Type"
                      onChange={(selectedIndices) => {
                        const selectedOrgTypes = selectedIndices.map((index) => OrgTypes[index]);
                        setField('orgTypes', selectedOrgTypes);
                      }}
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Organization Types</div>
                      <div className="field-value selectorbg">
                        {Array.isArray(program?.orgTypes)
                          ? program.orgTypes.join(', ')
                          : program?.orgTypes || ''}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <DateField
                      className="common-input"
                      controlId="createProgram.StartDate"
                      disabled={readOnly}
                      errors={errors.startsAt}
                      label="Start Date"
                      onChange={(newValue) => setField('startsAt', newValue)}
                      value={form.startsAt}
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Start Date</div>
                      <div className="field-value">
                        {program?.startsAt
                          ? new Date(program?.startsAt).toLocaleDateString('en-US')
                          : ''}
                      </div>
                    </div>
                  )}
                </Col>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <DateField
                      className="common-input"
                      controlId="createProgram.DueDate"
                      disabled={readOnly}
                      errors={errors.endsAt}
                      label="Due Date"
                      onChange={(newValue) => setField('endsAt', newValue)}
                      popperClassName="date-field-popper-container"
                      value={reduceTZ(form.endsAt?.toLocaleString(), '', 'YYYY-MM-DD')}
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">End Date</div>
                      <div className="field-value">
                        {program?.endsAt
                          ? new Date(program?.endsAt).toLocaleDateString('en-US')
                          : ''}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <MultiSelectInput
                      choices={applicationsConfigs.alphabetSortedCategories}
                      className="common-input"
                      controlId="createProgram.Category"
                      defaultValue={program?.category || []}
                      disabled={readOnly}
                      isIndexBased={false}
                      label="Category"
                      onChange={useCallback((selectedIndices: number[]) => {
                        const selectedCategories = selectedIndices.map(
                          (index) => applicationsConfigs.alphabetSortedCategories[index]
                        );
                        setField('category', selectedCategories);
                      }, [])}
                      required
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Category </div>
                      <div className="field-value selectorbg">
                        {getCategoryDisplayValues(program?.category).join(', ')}
                      </div>
                    </div>
                  )}
                </Col>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <MultiSelectInput
                      choices={applicationsConfigs.sources}
                      className="common-input"
                      controlId="createProgram.Source"
                      defaultValue={
                        Array.isArray(program?.source)
                          ? program.source
                          : program?.source !== undefined && program?.source !== null
                            ? [program.source]
                            : []
                      }
                      disabled={readOnly}
                      label="Source"
                      onChange={useCallback((selectedIndices: number[]) => {
                        setField('source', selectedIndices);
                      }, [])}
                      required
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Source</div>
                      <div className="field-value selectorbg">
                        {getSourceDisplayValues(program?.source).join(', ')}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <MultiSelectInput
                      choices={states.map((state) => state.name)}
                      className="common-input"
                      controlId="createProgram.States"
                      defaultValue={program?.states || []}
                      disabled={readOnly}
                      isIndexBased={false}
                      label="State"
                      onChange={(selectedIndices) => {
                        const selectedStates = selectedIndices.map((index) => states[index].name);
                        setField('states', selectedStates);
                        setField('counties', []);
                      }}
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">States</div>
                      <div className="field-value selectorbg">
                        {Array.isArray(program?.states)
                          ? program.states.join(', ')
                          : program?.states || ''}
                      </div>
                    </div>
                  )}
                </Col>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <MultiSelectInput
                      choices={getCountiesForStates(
                        form.states
                          ?.map((stateName) => states.find((s) => s.name === stateName)?.abbr)
                          .filter(Boolean) || []
                      )}
                      className="common-input"
                      controlId="createProgram.Counties"
                      defaultValue={program?.counties || []}
                      disabled={readOnly || !form.states?.length}
                      isIndexBased={false}
                      label="County"
                      onChange={(selectedIndices) => {
                        const availableCounties = getCountiesForStates(
                          form.states
                            ?.map((stateName) => states.find((s) => s.name === stateName)?.abbr)
                            .filter(Boolean) || []
                        );
                        const selectedCounties = selectedIndices.map(
                          (index) => availableCounties[index]
                        );
                        setField('counties', selectedCounties);
                      }}
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Counties</div>
                      <div className="field-value selectorbg">
                        {Array.isArray(program?.counties)
                          ? program.counties.join(', ')
                          : program?.counties || ''}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <MoneyInputNew
                      canVary
                      className="common-input"
                      controlId="createProgram.FundingAmount"
                      defaultAmount={program?.fundingAmount}
                      defaultStringAmount={program?.varyingFundingAmount}
                      defaultVaries={program?.amountVaries}
                      disabled={readOnly}
                      errors={errors.fundingAmount}
                      isFromPrograms={isFromPrograms}
                      label="Funding Amount"
                      onAmountChange={(newAmount) => setField('fundingAmount', newAmount)}
                      onVaryChange={(newVaries) => setField('amountVaries', newVaries)}
                      onVaryingAmountChange={(newAmount) =>
                        setField('varyingFundingAmount', newAmount)
                      }
                      required
                      text="May be either fixed or varying."
                      textClass="currency-text"
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Funding Amount</div>
                      <div className="field-value">{program?.fundingAmount}</div>
                    </div>
                  )}
                </Col>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <TextInput
                      className="common-input"
                      controlId="createProgram.MatchRequirements"
                      defaultValue={program?.matchRequirements}
                      disabled={readOnly}
                      label="Match Requirements"
                      onChange={(newValue) => setField('matchRequirements', newValue)}
                      placeholder="25% Match"
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Match Requirements</div>
                      <div className="field-value">{program?.matchRequirements}</div>
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <TextInput
                      className="common-input"
                      controlId="createProgram.PeriodOfPerformance"
                      defaultValue={program?.performancePeriod}
                      disabled={readOnly}
                      label="Period of Performance"
                      onChange={(newValue) => setField('performancePeriod', newValue)}
                      placeholder="Enter Period of Preformance"
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Period of Preformance</div>
                      <div className="field-value">{program?.performancePeriod}</div>
                    </div>
                  )}
                </Col>
                <Col lg={6} xs={12}>
                  {!readOnly ? (
                    <TextInput
                      className="common-input"
                      controlId="createProgram.EstimatedResponse"
                      defaultValue={program?.estimatedResponse}
                      disabled={readOnly}
                      label="Estimated Response"
                      onChange={(newValue) => setField('estimatedResponse', newValue)}
                      placeholder="Enter Estimated Response"
                    />
                  ) : (
                    <div className="field-heading-wraper">
                      <div className="field-heading">Estimated Response</div>
                      <div className="field-value">{program?.estimatedResponse}</div>
                    </div>
                  )}
                </Col>
              </Row>
              {currentUser.isMillenniumUser && (
                <Row>
                  <Col lg={6} xs={12}>
                    {!readOnly ? (
                      <div className="program-common-input">
                        <div className="d-flex flex-column mb-2">
                          <span className="show-for-clients-text small">Show for flex Clients</span>
                          <CustomInput
                            checked={form.showForFlexClient ?? true}
                            className="show-flexclient-toggle"
                            id="showForFlexClient-toggle"
                            label=""
                            name="showForFlexClient"
                            onChange={(e) => setField('showForFlexClient', e.target.checked)}
                            type="switch"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="field-heading-wraper">
                        <div className="field-heading">Show for Flex Clients</div>
                        <div className="field-value selectorbg">
                          {program?.showForFlexClient ? 'Yes' : 'No'}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
        {!readOnly && (
          <div className="mt-4 custom-fields-wrapper">
            <div
              className="hide-show-wrapper"
              onClick={toggleSectionVisibility}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleSectionVisibility();
                }
              }}
              role="button"
              tabIndex={0}
            >
              <h5 className="heading-gb mb-4">Custom Fields</h5>
              <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
                {isSectionVisible ? (
                  <>
                    <KeyboardArrowUp
                      className="ms-2"
                      style={{ color: '#2C4474', fontSize: '18px' }}
                    />
                    <span className="show-hide-text">Hide Section</span>
                  </>
                ) : (
                  <>
                    <KeyboardArrowDown
                      className="ms-2"
                      style={{ color: '#2C4474', fontSize: '18px' }}
                    />{' '}
                    <span className="show-hide-text">Show Section</span>
                  </>
                )}
              </span>
            </div>
            {isSectionVisible && (
              <>
                <hr />
                <CustomFields
                  defaultFields={
                    typeof program?.customFields === 'string'
                      ? JSON.parse(program?.customFields)
                      : program?.customFields
                  }
                  disabled={readOnly}
                  onChange={(newFields) => setField('customFields', newFields)}
                />
              </>
            )}
          </div>
        )}
      </Form>
      <ContactMillenniumModal onHide={() => setShowContactModal(false)} show={showContactModal} />
      <Modal onClose={() => setShowPdfModal(false)} open={showPdfModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '1200px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
            <Typography component="h2" variant="h6">
              Grant Summary File (PDF) Full View
            </Typography>

            <Stack alignItems="center" direction="row" spacing={2}>
              <Button
                className="discard-btn"
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  handleCopy();
                }}
                variant="light"
              >
                <span className="d-flex align-items-center">
                  <Share />
                  &nbsp; Share
                </span>
              </Button>

              <Button
                onClick={() => {
                  setShowPdfModal(false);
                  setTimeout(() => {
                    setShowContactModal(true);
                  }, 300);
                }}
              >
                Contact Millennium
              </Button>

              <IconButton onClick={() => setShowPdfModal(false)} sx={{ ml: 2 }}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
          <Box
            sx={{
              maxHeight: '70vh',
              overflowY: 'auto',
              border: '1px solid #ddd',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Document file={files[0]?.pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={index} pageNumber={index + 1} />
              ))}
            </Document>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
}
