import { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, CardBody, Label, Input, Button } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

import { tossError, tossSuccess } from 'utils/toastTosser';
import { createInvitedUser } from 'services/userService';

interface UserFormData {
  name: string;
  email: string;
  phone: string;
}

function Register() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [userData, setUserData] = useState<UserFormData>();
  const [newPass, setNewPass] = useState('');
  const [verPass, setVerPass] = useState('');
  const [phone, setPhone] = useState('');

  const { tokenData } = useParams();

  const getInviteData = (inviteString: string) => {
    return fetch(`${process.env.REACT_APP_API_URL}/users/invite?token=${inviteString}`).then(
      (data) => data.json()
    );
  };

  useEffect(() => {
    if (loading) {
      setLoading(false);
      if (tokenData) {
        getInviteData(tokenData).then((inviteData) => {
          if (Object.prototype.hasOwnProperty.call(inviteData, 'error')) {
            navigate(`${process.env.PUBLIC_URL}/login`);
          } else {
            setUserData({
              name: inviteData.results.name,
              email: inviteData.results.email,
              phone: inviteData.results.phone,
            });

            setPhone(inviteData.results.phone);
          }
        });
      }
    }
  }, [loading, tokenData, navigate]);

  const registerRequest = async () => {
    if (newPass === verPass) {
      if (newPass.length < 7) {
        tossError(`Password must be at least 7 characters long.`);
      } else {
        const result = await createInvitedUser({
          tokenString: tokenData!,
          phone,
          password: newPass,
        });
        if (result) {
          tossSuccess(`Successfully registered!`);
          navigate(`${process.env.PUBLIC_URL}/login`);
          window.location.reload();
        } else tossError(`Error during registration.`);
      }
    } else {
      tossError(`Two passwords does not match.`);
    }
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Register - Grantrack</title>
      </Helmet>
      <ToastContainer />
      <Container className="p-0" fluid>
        <div className="authentication-main m-0">
          <Row>
            <Col md="12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <CardBody className="h-100-d-center">
                    <div className="cont b-light">
                      <div>
                        <Form className="theme-form">
                          <div className="mb-4">
                            <a href={`${process.env.PUBLIC_URL}/login`} rel="noreferrer nofollow">
                              &laquo; Back to Login
                            </a>
                          </div>
                          <div className="text-center">
                            <h4>REGISTER NEW USER</h4>
                            <div>Your invitation contains the following data:</div>
                          </div>
                          <FormGroup>
                            <Label className="col-form-label pt-0">Name</Label>
                            <Input
                              className="form-control"
                              defaultValue={userData?.name}
                              disabled
                              type="text"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label pt-0">Email</Label>
                            <Input
                              className="form-control"
                              defaultValue={userData?.email}
                              disabled
                              type="text"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label pt-0">Phone Number</Label>
                            <Input
                              className="form-control"
                              defaultValue={userData?.phone}
                              onChange={(e) => setPhone(e.target.value)}
                              type="text"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label pt-0">Password</Label>
                            <Input
                              className="form-control"
                              onChange={(e) => setNewPass(e.target.value)}
                              required
                              type="password"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label pt-0">Repeat Password</Label>
                            <Input
                              className="form-control"
                              onChange={(e) => setVerPass(e.target.value)}
                              required
                              type="password"
                            />
                          </FormGroup>

                          <FormGroup className="form-row mt-3 mb-0">
                            <Button color="primary btn-block" onClick={registerRequest}>
                              Register
                            </Button>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Register;
