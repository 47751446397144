import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';

import { AWARD_STATUSES, Notifications } from 'types/awards';

import { getNotifications } from 'services/awards/awardsService';
import useCurrentAward from 'hooks/useCurrentAward';
import NotesModal from './NotesModal';

export default function Notes() {
  const { currentAwardId, notesData, awardData } = useCurrentAward();
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [notes, setNotes] = useState<Notifications[]>([]);
  const isMounted = useRef(true);

  const fetchNotification = useCallback(async () => {
    if (currentAwardId && isMounted.current) {
      const result = await getNotifications(currentAwardId);

      if (result) setNotes(result?.rows || []);
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchNotification();
  }, []);

  // Status keys
  // const requiredActions: { [K in PresentationAwardStatuses]?: string | null } = {
  //   awarded: 'Upload application',

  //   applicationRequired: 'Upload application',
  //   awardLetterRequired: 'Upload award letter',
  //   initialAgreementRequired: 'Upload agreement',
  //   resolutionRequired: 'Upload resolution',

  //   appropriated: 'Add budget line items',

  //   budgetRequired: 'Add budget line items',
  //   reportScheduleRequired: 'Input report schedule',
  //   contractMaterialRequired: 'Add required contract materials',

  //   active: null,

  //   encumbered: 'Submit for approval',
  //   approval: 'Approve award',

  //   complianceRequired: 'Mark as in-compliance',
  //   reportsRequired: 'Upload report document',

  //   closeout: 'Finish reports & payments',
  //   closed: 'The award is closed',
  // };

  useEffect(() => {
    if (notesData?.data) {
      const status = awardData.data?.status;

      // filter notes based on timeline
      const filteredNotes = (notesData.data as unknown as Notifications[]).filter(
        (note: Notifications) => {
          if (!status) return true;

          const hideNote = !note.subject.match(/was approved|was denied|was rejected/i);

          const statusIndex = AWARD_STATUSES.indexOf(status);

          const hideApplicationNotice =
            statusIndex > 0 && note.subject.match(/application required/i);
          const hideAwardLetterNotice =
            statusIndex > 1 && note.subject.match(/award letter required/i);
          const hideResolutionNotice =
            statusIndex > 2 && note.subject.match(/resolution required/i);
          const hideAgreementNotice = statusIndex > 3 && note.subject.match(/agreement required/i);
          const hideBudgetNotice = statusIndex >= 4 && note.subject.match(/budget required/i);
          const hideReportScheduleNotice =
            statusIndex > 5 && note.subject.match(/report schedule required/i);
          const hideContractMaterialNotice =
            statusIndex > 6 && note.subject.match(/contract material required/i);
          const hideComplianceNotice =
            statusIndex > 7 && note.subject.match(/compliance required/i);
          const hideApprovalNotice = statusIndex > 7 && note.subject.match(/approval/i);

          // console.log(
          //   'statusIndex',
          //   statusIndex,
          //   hideApplicationNotice,
          //   hideAwardLetterNotice,
          //   hideResolutionNotice,
          //   hideAgreementNotice,
          //   hideBudgetNotice,
          //   hideReportScheduleNotice,
          //   hideContractMaterialNotice,
          //   hideComplianceNotice
          // );

          if (
            hideApplicationNotice ||
            hideAwardLetterNotice ||
            hideResolutionNotice ||
            hideAgreementNotice ||
            hideBudgetNotice ||
            hideReportScheduleNotice ||
            hideContractMaterialNotice ||
            hideComplianceNotice ||
            hideApprovalNotice
          ) {
            return false;
          }

          return hideNote;
        }
      );

      setNotes(filteredNotes);
    }
  }, [notesData]);

  const toggleOpen = () => setIsNotesOpen((prevState) => !prevState);

  if (!notes || notes?.length === 0) return null;

  return (
    <>
      <Box
        sx={{
          borderRadius: '12px',
          border: '1px solid #817031',
          backgroundColor: '#FFFDD1',
          p: 2,
          mb: 2,
          '& p': {
            color: '#817031',
          },
          '& h3': {
            color: '#817031',
          },
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between" mb={1}>
          <Typography fontWeight={600} variant="h3">
            Latest notes
          </Typography>

          <Link
            component={Button}
            onClick={toggleOpen}
            sx={{
              color: '#817031',
              textTransform: 'none',
              '&:hover': {
                background: '#FFFDD1',
              },
            }}
            variant="h3"
          >
            Preview all
          </Link>
        </Stack>

        <Typography fontStyle="italic" variant="body1">
          {notes[notes.length - 1].subject}
        </Typography>
      </Box>

      {isNotesOpen && <NotesModal data={notes} toggle={toggleOpen} />}
    </>
  );
}
