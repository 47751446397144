import { AxiosResponse } from 'axios';

import api, { outputError } from '../apiService';

export const requestApproval = (awardId: string): Promise<boolean> =>
  api
    .post(`/awards/${awardId}/request/approval`, {})
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const approveAward = (awardId: string): Promise<boolean> =>
  api
    .post(`/awards/${awardId}/approve`, {})
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const setCompliance = (awardId: string, status?: string): Promise<boolean> =>
  api
    .post(`/awards/${awardId}/compliance`, status ? { status } : {})
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const rejectAward = (awardId: string): Promise<boolean> =>
  api
    .post(`/awards/${awardId}/reject`, {})
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
