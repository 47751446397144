import { RefObject } from 'react';
import axios, { AxiosResponse } from 'axios';

import { Application, NewApplication } from 'types/application';
import { User } from 'types/user';
import api, { outputError } from './apiService';

const { REACT_APP_APPLICATIONS_PER_PAGE } = process.env;

export interface ApplicationsList {
  rows: Application[];
  pages: number;
  error?: string;
}

export const getApplication = (id: string): Promise<Application> =>
  api
    .get(`/applications/${id}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {} as Application;
    })
    .catch((error) => {
      outputError(error);
      return {} as Application;
    });

export const createApplication = (
  data: Partial<NewApplication> & { user?: User }
): Promise<NewApplication> =>
  api
    .post(`/applications`, data)
    .then((response: AxiosResponse) => {
      if (response.status < 303) {
        return response?.data;
      }
      throw new Error(`Failed to create application: ${response.statusText}`);
    })
    .catch((error) => {
      outputError(error);
      throw error;
    });

export const getApplications = (
  filterQuery: string,
  controllerRef: RefObject<AbortController>
): Promise<ApplicationsList> =>
  api
    .get(`/applications${filterQuery}`, { signal: controllerRef.current?.signal })
    .then((response: AxiosResponse) => {
      if (response.status < 303)
        return {
          rows: response.data.rows,
          pages: Math.ceil(response.data.count / Number(REACT_APP_APPLICATIONS_PER_PAGE || '20')),
        };
      return { rows: [], pages: 0, error: response.statusText || 'Error' };
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        outputError(error);
        return { rows: [], pages: 0, error: 'Error' };
      }
      return { rows: [], pages: 0 };
    });

export const searchApplications = (
  searchQuery: string,
  searchField: string
): Promise<Application[]> =>
  api
    .get(`/applications/search?query=${searchQuery}&field=${searchField}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });

export const updateApplication = (
  data: Partial<NewApplication> & { user?: User }
): Promise<boolean> =>
  api
    .put(`/applications`, data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteApplication = (id: string): Promise<boolean> =>
  api
    .delete('/applications', { data: { id } })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const updateMultipleApplications = (
  data: Partial<NewApplication> & { appIds?: number[] }
): Promise<boolean> =>
  api
    .put('/applications/multiple', data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const duplicateMultipleApplications = (data: unknown): Promise<boolean> =>
  api
    .post('/applications/multiple', data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteMultipleApplications = (data: unknown): Promise<boolean> =>
  api
    .delete('/applications/multiple', { data })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const downloadApplicationsCSV = (data: string) =>
  api
    .get(`/applications/export/csv${data}`, { responseType: 'arraybuffer' })
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {};
    })
    .catch((error) => {
      outputError(error);
      return {};
    });

export const downloadApplicationsXLSX = (data: string) =>
  api
    .get(`/applications/export/xlsx${data}`, { responseType: 'arraybuffer' })
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {};
    })
    .catch((error) => {
      outputError(error);
      return {};
    });

export const deleteApplicationFile = (id: number): Promise<boolean> =>
  api
    .delete(`/deletefile/${id}`)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
