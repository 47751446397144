import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

// Example of the format: Monday, July 25th 2022

export default function reduceTZ(
  date?: Date | string,
  placeholder = 'TBD',
  format = 'dddd, MMMM Do YYYY'
) {
  if (!date) return placeholder;

  const dateObject = dayjs(date.toString().replace(/T.+/, ''));

  return dateObject.isValid() ? dateObject.format(format) : placeholder;
}
