import { forwardRef } from 'react';

import { tossError, tossSuccess } from 'utils/toastTosser';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { deleteFilter } from 'services/filterService';

import { Filter } from 'types/filter';

interface DeleteModalProps {
  open: boolean;
  filterSet: Filter;
  onClose?: () => void;
  onDelete?: (selectedSet?: unknown) => void;
}

export default forwardRef(
  ({ open = false, filterSet, onClose, onDelete }: DeleteModalProps, ref) => {
    const doDelete = async () => {
      const result = await deleteFilter(filterSet.id);

      if (result) {
        tossSuccess('Filter deleted successfully!');
        if (onClose) onClose();
        if (onDelete) onDelete();
      } else tossError('Error deleting this filter.');
    };

    return (
      <Modal ref={ref} centered className="bg-filter-set-delete-modal" onHide={onClose} show={open}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Filter Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You&apos;re about to delete a Filter Set <strong>{filterSet?.name}</strong>. This action
            is irreversible and cannot be undone. All data inside this Filter Set will be lost.
          </p>
          <p>Do you really want to proceed?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={doDelete} variant="danger">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);
