import { ChangeEvent, FocusEventHandler } from 'react';
import { InputAdornment, InputLabel, TextField, Stack } from '@mui/material';

interface OutlinedInputProps {
  value?: number;
  onChange: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  label?: string;
  required?: boolean;
  id?: string;
  errorText?: string;
}

export default function OutlinedInputField({
  value,
  onChange,
  onBlur,
  label = '',
  required = false,
  placeholder = '',
  id = '',
  errorText,
}: OutlinedInputProps) {
  return (
    <Stack spacing={1}>
      {label && (
        <InputLabel htmlFor={id} required={required}>
          {label}
        </InputLabel>
      )}
      <TextField
        autoComplete="off"
        error={!!errorText}
        helperText={errorText}
        id={id}
        InputProps={{
          endAdornment: <InputAdornment position="end">$</InputAdornment>,
        }}
        name={id}
        onBlur={onBlur}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
        placeholder={placeholder}
        sx={{
          '& .MuiOutlinedInput-input': {
            padding: '8px 12px',
          },

          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: (theme) => theme.palette.primary.light,
            },
            '&.Mui-focused fieldset': {
              borderColor: (theme) => theme.palette.primary.light,
            },
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.secondary.contrastText,
          },

          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },

          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        }}
        type="number"
        value={value ?? null}
      />
    </Stack>
  );
}
