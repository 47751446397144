import { useState, createRef } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem, FormGroup } from 'reactstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Plus } from 'react-feather';

import { searchPrograms } from 'services/programService';

import { Program } from 'types/program';
import CreateApplication from './CreateApplication';

export default function NewApplication({ onCreation }: { onCreation: () => void }) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [programsSelected, setProgramsSelected] = useState<Partial<Program>[]>([]);
  const [programsLoading, setProgramsLoading] = useState(false);
  const [programsOptions, setProgramsOptions] = useState<Partial<Program>[]>([]);

  const textFieldRef = createRef<AsyncTypeahead<Partial<Program>>>();

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
    if (!popoverOpen) {
      setProgramsSelected([]);
      if (textFieldRef.current) {
        textFieldRef.current.clear();
      }
    }
  };

  const handleProgramsSearch = async (query: string) => {
    setProgramsLoading(true);

    const programs = await searchPrograms(query, 'name');

    const options = programs.map((e: Program) => ({
      id: e.id,
      name: e.name,
      funder: e.funder,
      fundingAmount: e.fundingAmount,
      amountVaries: e.amountVaries,
      source: e.source,
      category: e.category,
      departmentNotified: e.departmentNotified,
      matchRequirements: e.matchRequirements,
      performancePeriod: e.performancePeriod,
      customFields: e.customFields,
      startsAt: e.startsAt,
      endsAt: e.endsAt,
      summaryData: e.summaryData?.[0],
    }));

    setProgramsOptions(options);

    setProgramsLoading(false);
  };

  return (
    <Dropdown direction="down" isOpen={popoverOpen} toggle={togglePopover}>
      <DropdownToggle className="d-flex justify-content-center align-items-center" color="primary">
        <Plus size={16} style={{ marginBottom: '2px' }} />
        &nbsp;New Application
      </DropdownToggle>

      <DropdownMenu flip={false} persist>
        <DropdownItem header>Choose Program</DropdownItem>
        <DropdownItem text>
          <FormGroup>
            <AsyncTypeahead
              ref={textFieldRef}
              id="filterTasks"
              isLoading={programsLoading}
              labelKey={(option) => option.name!}
              minLength={1}
              onChange={setProgramsSelected}
              onSearch={handleProgramsSearch}
              options={programsOptions}
              placeholder="Search for programs..."
              renderMenuItemChildren={(option, { text }) => (
                <Highlighter search={text!}>{option.name}</Highlighter>
              )}
              selected={programsSelected}
              useCache={false}
            />
          </FormGroup>
        </DropdownItem>

        <DropdownItem className="text-center" text>
          <CreateApplication onCreation={onCreation} prefillOptions={programsSelected[0]} />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
