import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Copy as DuplicateIcon, Check } from 'react-feather';

import { tossError, tossSuccess } from 'utils/toastTosser';
import useKeyPress from 'hooks/useKeyPress';

import { ClientInput } from 'shared/inputs';
import useUserSession from 'hooks/useUserSession';

import { createApplication, duplicateMultipleApplications } from 'services/applicationService';

import { Application } from 'types/application';

interface ModalProps {
  status: boolean;
  pending: boolean;
  title: string;
  message: string;
}

interface DuplicateApplicationProps {
  application: Application;
  disabled: boolean;
  eligibleApps: {
    apps: Application[];
    status: string;
  };
  setIsModalOpen: Dispatch<SetStateAction<ModalProps>>;
}

interface Form {
  clients: number[];
  disabled: boolean;
  eligibleApps: {
    apps: Application[];
    status: string;
  };
  setIsModalOpen: Dispatch<SetStateAction<ModalProps>>;
}

interface Errors {
  clients: string;
  required: string;
}

export default function DuplicateApplication({
  application,
  disabled = false,
  eligibleApps,
  setIsModalOpen,
}: DuplicateApplicationProps) {
  const currentUser = useUserSession();

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<Form>({} as Form);
  const [errors, setErrors] = useState<Errors>({} as Errors);

  const escPress = useKeyPress('Esc');

  const toggle = () => setOpen((prevState) => !prevState);

  const setField = (field: string, value: string) =>
    setForm((prevState) => ({ ...prevState, [field]: value }));

  const getFieldData = () => {
    const { clients } = form;
    const {
      programId,
      name,
      summaryFile,
      funder,
      fundingAmount,
      amountVaries,
      varyingFundingAmount,
      source,
      category,
      startsAt,
      matchRequirements,
      performancePeriod,
      estimatedResponse,
      departmentNotified,
      submissionStatus,
      endsAt,
      customFields,
      notifyDate,
      awardDate,
      submissionDate,
      assigneeId,
      assigneeName,
      status,
    } = application;

    const submittedFields = {
      programId: programId ?? 0,
      name,
      funder,
      fundingAmount: fundingAmount ?? 0.0,
      amountVaries: amountVaries ?? false,
      varyingFundingAmount,
      source,
      startsAt,
      endsAt,
      matchRequirements,
      performancePeriod,
      summaryFile,
      estimatedResponse,
      customFields,
      category,
      departmentNotified,
      submissionStatus,
      dateNotified: notifyDate,
      dateAwarded: awardDate,
      submissionDate,
      status,
      assigneeId,
      assigneeName,
    };

    return { clients, application, submittedFields };
  };

  const save = async () => {
    const { clients, submittedFields } = getFieldData();

    let anyErrors = false;

    for (const client of clients) {
      // eslint-disable-next-line no-await-in-loop
      const result = await createApplication({
        ...submittedFields,
        clientId: client,
      });

      if (!result) {
        anyErrors = true;
        break;
      }
    }

    if (anyErrors) {
      tossError(
        'Errors during duplication. Please check server status or your Internet connection.'
      );
    } else {
      tossSuccess(`The application was successfully duplicated for ${clients.length} clients.`);
      toggle();
    }
  };

  const saveMultiple = async () => {
    setOpen(false);
    setIsModalOpen({
      status: true,
      pending: true,
      title: 'Bulk Duplicating',
      message: 'Processing Changes...',
    });

    const { clients, submittedFields } = getFieldData();
    const data = { clients, multipleApps: eligibleApps?.apps };

    const response = await duplicateMultipleApplications({
      ...submittedFields,
      assigneeId: currentUser.id,
      data,
    });

    if (response) {
      setIsModalOpen((prevState) => ({
        ...prevState,
        pending: false,
        title: 'Bulk Editing Completed',
        message: 'Please proceed to the applications screen to view your changes',
      }));
    } else tossError('Problem duplicating applications');
  };

  useEffect(() => {
    const { clients } = form;

    const errorList: Errors = {} as Errors;

    if (clients) {
      if (typeof clients !== 'undefined' && clients.length === 0)
        errorList.clients = 'Select at least one client.';
    } else {
      errorList.required = 'Not all required fields are filled.';
    }

    setErrors(errorList);
  }, [form]);

  useEffect(() => {
    if (!escPress && open) {
      toggle();
    }
  }, [escPress]);

  return application ? (
    <>
      <Button
        className={
          eligibleApps?.apps?.length
            ? 'd-flex justify-content-center align-items-center'
            : `w-100 ${'d-flex justify-content-center align-items-center'}`
        }
        disabled={disabled}
        onClick={toggle}
        style={{ height: '46px' }}
        variant="primary"
      >
        <DuplicateIcon size={16} />
        &nbsp;Duplicate
      </Button>

      <Modal centered onHide={toggle} show={open}>
        <Modal.Header closeButton>Duplicate Application</Modal.Header>

        <Modal.Body>
          <Form>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <Alert variant="light">
                    <p>
                      Duplicate this application for some of{' '}
                      {currentUser.userType === 'millenniumAdmin' ? 'the' : 'your'} other clients by
                      using this interface.
                    </p>

                    <p>
                      You can do this as easy as selecting one or more clients and pressing the{' '}
                      <strong>Duplicate</strong> button below.
                    </p>

                    <p>
                      Each application created by this method will have the same status, the same
                      Assignee user as the current application, and the selected client as Client.
                    </p>
                    {eligibleApps?.status && (
                      <span className="eligibility-status">{eligibleApps.status}</span>
                    )}
                  </Alert>
                </Col>
                <Col xs={12}>
                  <ClientInput
                    controlId="duplicateApplication.Clients"
                    errors={errors.clients}
                    onChange={(newValue: string) => setField('clients', newValue)}
                    single={false}
                    text="Select all clients you want this application to be duplicated for."
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="d-flex justify-content-center align-items-center"
            disabled={Object.keys(errors).length > 0}
            onClick={eligibleApps?.apps?.length ? saveMultiple : save}
            variant="save"
          >
            <Check size={16} />
            &nbsp;Duplicate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div className="text-center">
      No application provided to duplicate. Please reload the page or clear your cache if you see
      this text.
    </div>
  );
}
