import { createTheme } from '@mui/material/styles';

export const bluePalette = {
  B100: '#EFF1F6',
  B200: '#CFD6E4',
  B300: '#9FADC9',
  B500: '#5F77A5',
  B700: '#2C4474',
  B800: '#1F3665',
};

export const greenPalette = {
  G600: '#31816C',
};

const body2 = {
  color: '#2C4474',
  fontSize: '0.9rem',
  fontWeight: '600',
  lineHeight: '0.9rem',
};

export default createTheme({
  typography: {
    fontFamily: 'raleway',
    allVariants: {
      color: bluePalette.B700,
    },
    h1: {
      color: 'black',
      fontSize: '20px',
      fontWeight: 600,
      letterSpacing: '0.8px',
    },
    h2: {
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.7px',
    },
    h3: {
      color: 'black',
      fontSize: '0.9rem',
      fontWeight: 500,
    },
    caption: {
      color: '#50535B',
      fontSize: '0.8rem',
      fontWeight: 500,
    },
    body1: {
      color: 'black',
      fontSize: '0.8rem',
      fontWeight: 600,
    },
    body2: {
      ...body2,
    },
  },

  palette: {
    primary: {
      light: bluePalette.B500,
      main: bluePalette.B700,
      dark: bluePalette.B800,
    },
    secondary: {
      light: bluePalette.B100,
      main: bluePalette.B200,
      dark: bluePalette.B300,
      contrastText: bluePalette.B300,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1000,
      xl: 1200,
    },
  },

  components: {
    MuiOutlinedInput: { styleOverrides: { root: { ...body2 } } },
    MuiFilledInput: { styleOverrides: { root: { ...body2 } } },
    MuiInput: { styleOverrides: { root: { ...body2 } } },
    MuiInputBase: { styleOverrides: { root: { ...body2 } } },
    MuiSelect: { styleOverrides: { root: { ...body2 } } },
  },
});
