/* eslint-disable no-alert */
import { useState, useEffect, Fragment, useCallback, useContext, useMemo } from 'react';
import { Container, Row, Col, Form, Dropdown, Button } from 'react-bootstrap';
import { Check, CheckCircle } from 'react-feather';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import dayjs from 'dayjs';
import moment from 'moment';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import Dropzone, { IFileWithMeta, IUploadParams, StatusValue } from 'react-dropzone-uploader';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import {
  ArrowBack,
  ContentCopy,
  DeleteOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Share,
} from '@mui/icons-material';

import logger from 'services/logger';

import {
  TextInput,
  FunderInput,
  AssigneeInput,
  ClientInput,
  NotesStatusInput,
  MoneyInputNew,
  SelectInputNew,
} from 'shared/inputs';

import CustomFields, { CustomField } from 'shared/CustomFields';

import useUserSession from 'hooks/useUserSession';
import { tossError, tossSuccess } from 'utils/toastTosser';
import DateField from 'components/applications/EditForm/DateField';
import recursiveParseJsonArray from 'utils/helpers/recursiveParseJsonArray';
import { Application } from 'types/application';
import {
  getApplication,
  updateApplication,
  updateMultipleApplications,
} from 'services/applicationService';
import DeleteApplication from 'components/applications/DeleteApplication';
import DuplicateApplication from 'components/applications/DuplicateApplication';
import HistoryBar from 'components/applications/HistoryBar';
import RenderTooltip from 'shared/RenderTooltip';
import FileCard from 'shared/FileCard';
import api from 'services/apiService';
import { applicationsConfigs } from '../../constants/globalConstants';
import 'assets/scss/ApplicationEdit.scss';

interface StatusSelectorProps {
  defaultValue?: number;
  disabled?: boolean;
  onChange: (val: number) => void;
  tooltipData?: number[];
}

function StatusSelector({
  defaultValue,
  disabled = false,
  onChange,
  tooltipData,
}: StatusSelectorProps) {
  const [status, setStatus] = useState(defaultValue ?? 0);
  const defaultPlaceholder = useCallback(() => {
    if (tooltipData && tooltipData?.length > 1) {
      return 'Multiple';
    }
    if (tooltipData?.length) {
      return applicationsConfigs.statuses.concat(applicationsConfigs.additionalCases)[
        tooltipData[0]
      ];
    }
    return 'None';
  }, [tooltipData]);

  useEffect(() => {
    onChange(status);
  }, [status]);

  return (
    <Dropdown className="custom-status-dropdown common-input w-70">
      <Dropdown.Toggle className="form-control w-100" disabled={disabled}>
        <span className={`btn-status${status} status-text`}>
          {applicationsConfigs.statuses.concat(applicationsConfigs.additionalCases)[status] ||
            defaultPlaceholder()}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ padding: '0' }}>
        <Dropdown.Header className="text-center">Main Cases</Dropdown.Header>
        {applicationsConfigs.statuses
          .concat(applicationsConfigs.additionalCases)
          .map((item, index) => (
            <Fragment key={item}>
              {item === applicationsConfigs.additionalCases[0] && (
                <Dropdown.Header className="text-center">Other Cases</Dropdown.Header>
              )}
              <Dropdown.Item onClick={() => setStatus(index)}>{item}</Dropdown.Item>
            </Fragment>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

interface ApplicationFormFields {
  name?: string;
  funder?: string;
  fundingAmount?: number;
  varyingFundingAmount?: string;
  amountVaries?: boolean;
  source?: number;
  category?: number;
  startDate?: Date | string | null;
  dueDate?: Date | string | null;
  assignee?: number;
  client?: number;
  status?: number;
  dateNotified?: Date | string | null;
  awardDate?: Date | string | null;
  submissionDate?: Date | string | null;
  departmentNotified?: string;
  matchRequirements?: string;
  notesStatus?: string;
  estimatedResponse?: string;
  grantPurpose?: string;
  performancePeriod?: string;
  customFields?: CustomField[];
}

interface UpsertApplicationPayload {
  id?: string;
  assigneeId?: number;
  clientId?: number;
  status?: number;
  name?: string;
  funder?: string;
  fundingAmount?: number;
  varyingFundingAmount?: string;
  amountVaries?: boolean;
  source?: number;
  category?: number;
  departmentNotified?: string;
  matchRequirements?: string;
  performancePeriod?: string;
  submissionStatus?: string;
  customFields?: CustomField[];
  startsAt?: Date | string | null;
  endsAt?: Date | string | null;
  dateNotified?: Date | string | null;
  dateAwarded?: Date | string | null;
  submissionDate?: Date | string | null;
  estimatedResponse?: string;
  grantPurpose?: string;
  appIds?: number[];
}

export default function ApplicationEdit() {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const { state } = useLocation();
  const currentUser = useUserSession();
  const IsUserAdmin = currentUser.userType === 'millenniumAdmin';
  const IsClient = currentUser.applicationClients;
  const [form, setForm] = useState<ApplicationFormFields>({});
  const [errors, setErrors] = useState<Partial<Record<keyof ApplicationFormFields, string>>>();
  const [readAccess, setReadAccess] = useState(true);
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const [application, setApplication] = useState<Application>();
  const [isModalOpen, setIsModalOpen] = useState({
    status: false,
    pending: true,
    title: 'Bulk Editing',
    message: 'Processing Changes...',
  });
  const [multipleApps, setMultipleApps] = useState<Application[]>([]);
  const [eligibleApps, setEligibleApps] = useState<{ status: string; apps: Application[] }>({
    status: '',
    apps: [],
  });
  const [generateAward, setGenerateAward] = useState<boolean | null>(null);
  const [prevStatus, setPrevStatus] = useState<number | undefined>(form.status);
  const [files, setFiles] = useState([]);
  const isfromapplication = true;

  // `toolTipData` is not being updated. If tooltips are no longer needed then all its implementations should be wiped out.
  const [toolTipData, setToolTipData] = useState<Record<string, string | number | unknown[]>>({}); // eslint-disable-line @typescript-eslint/no-unused-vars

  const setField = <K extends keyof ApplicationFormFields>(
    field: K,
    value: ApplicationFormFields[K]
  ) => setForm((prevState) => ({ ...prevState, [field]: value }));

  const getUploadParams = (fileWithMeta: IFileWithMeta) => {
    const body = new FormData();
    body.append('applicationId', applicationId);
    body.append('file', fileWithMeta.file);
    body.append('size', fileWithMeta.meta.size.toString());
    body.append('name', fileWithMeta.meta.name);
    body.append('fileUrl', fileWithMeta.meta.previewUrl || '');
    body.append('type', fileWithMeta.meta.type);

    return {
      url: `${process.env.REACT_APP_API_URL}/applications/uploadfile`,
      body,
      headers: {
        Authorization: api.client?.defaults.headers.common.Authorization,
      },
    } as IUploadParams;
  };

  const handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      fileWithMeta.remove();
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          id: fileWithMeta.meta.id,
          name: fileWithMeta.meta.name,
          size: fileWithMeta.meta.size,
          type: fileWithMeta.meta.type,
        },
      ]);
    }
  };

  const toggleSectionVisibility = () => {
    setIsSectionVisible((prev) => !prev);
  };
  const handleDeleteFile = async (fileId: string) => {
    try {
      await api.delete(`/applications/deletefile`);
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
      tossSuccess('File deleted successfully.');
    } catch (error) {
      console.error('Error deleting file:', error);
      tossError('Error deleting file.');
    }
  };

  useEffect(() => {
    if (applicationId) {
      const fetchFiles = async () => {
        try {
          const response = await api.get(`/applications/get/${applicationId}`);
          setFiles(response?.data);
        } catch (error) {
          console.error('Error fetching files:', error);
          tossError('Error fetching files.');
        }
      };

      fetchFiles();
    }
  }, [applicationId]);
  useEffect(() => {
    setPrevStatus(form.status);
    if (multipleApps?.length) return;

    const dateNow = moment().format('YYYY-MM-DD');

    if (form.status === 4 && prevStatus && prevStatus !== 4) {
      if (
        application?.client?.awardsEnabled === true &&
        form.fundingAmount &&
        !form.varyingFundingAmount
      ) {
        if (application?.awardDoesNotExist === false) {
          if (
            window.confirm(
              'An award record already exists for this application. Would you like to update it?'
            )
          ) {
            setGenerateAward(true);
            logger.info('User has chosen to update the existing award record.', {
              applicationId,
              user: currentUser,
            });
          }
        } else if (!application?.client?.privateAwardsManagement) {
          alert(
            'Please note that changing the status to awarded will generate an award record here in GranTrack.'
          );
        }
      }
    }

    if (form.status === 1 && !form.dateNotified) setField('dateNotified', dateNow);
    if (form.status === 3 && !form.submissionDate) setField('submissionDate', dateNow);
    if (form.status === 4 && !form.awardDate) setField('awardDate', dateNow);
    if (form.status === 5 && !form.awardDate) setField('awardDate', dateNow);
  }, [form.status, prevStatus]);

  const dataFromFields = (): UpsertApplicationPayload => {
    const {
      name,
      funder,
      fundingAmount,
      varyingFundingAmount,
      amountVaries,
      source,
      category,
      startDate,
      dueDate,
      assignee,
      client,
      status,
      dateNotified,
      awardDate,
      submissionDate,
      departmentNotified,
      matchRequirements,
      notesStatus,
      estimatedResponse,
      grantPurpose,
      performancePeriod,
      customFields,
    } = form;

    return {
      id: applicationId,
      assigneeId: assignee,
      clientId: client,
      status,
      name,
      funder,
      fundingAmount,
      varyingFundingAmount,
      amountVaries,
      source,
      ...((category === 0 || category) && {
        category: applicationsConfigs.categories.indexOf(
          applicationsConfigs.alphabetSortedCategories[category]
        ),
      }),
      departmentNotified,
      matchRequirements,
      performancePeriod,
      submissionStatus: notesStatus,
      customFields,
      startsAt: startDate || null,
      endsAt: dueDate || null,
      dateNotified: dateNotified
        ? `${dayjs(dateNotified).format('YYYY-MM-DD')}T00:00:00.000Z`
        : null,
      dateAwarded: awardDate ? `${dayjs(awardDate).format('YYYY-MM-DD')}T00:00:00.000Z` : null,
      submissionDate: submissionDate
        ? `${dayjs(submissionDate).format('YYYY-MM-DD')}T00:00:00.000Z`
        : null,
      estimatedResponse,
      grantPurpose,
    };
  };

  const update = () => {
    const updateApplicationData = async () => {
      if (applicationId) {
        try {
          const app = await getApplication(applicationId);

          let parsedField: string | string[] | Record<string, string | number> = '';

          if (app?.customFields?.length)
            parsedField = app?.customFields?.includes('id') ? app?.customFields : [];

          if (Array.isArray(app?.customFields)) parsedField = JSON.stringify(app?.customFields);

          setApplication({ ...app, customFields: parsedField });

          const userIsAdmin = currentUser.userType === 'millenniumAdmin';
          const userIsAnalyst = currentUser.userType === 'millenniumAnalyst';
          const userIsManager = currentUser.userType === 'millenniumManager';
          const clientIsAssigned = currentUser.applicationClients
            ?.map((e) => e.id)
            .includes(app.clientId as number);

          setReadAccess(
            !currentUser.isMillenniumUser ||
              userIsAnalyst ||
              !(userIsAdmin || userIsManager || clientIsAssigned)
          );
        } catch (err) {
          navigate(`${process.env.PUBLIC_URL}/dashboard/applications?t=1`);
        }
      }
    };

    updateApplicationData();
  };

  const save = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to edit this application?')) {
      const submittedFields = dataFromFields();

      const result = await updateApplication({
        ...submittedFields,
        user: currentUser,
        generateAward: generateAward || false,
      });
      if (result) {
        update();
        tossSuccess('The application was successfully updated.');
        navigate(-1);
      } else tossError('There was an error updating application(s).');
    }
  };

  const saveMultiple = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to bulk edit these applications?')) {
      setIsModalOpen({
        status: true,
        pending: true,
        title: 'Bulk Editing',
        message: 'Processing Changes...',
      });

      const submittedFields: UpsertApplicationPayload & { appIds?: number[] } = dataFromFields();
      submittedFields.appIds = (eligibleApps.apps || [])
        .map((app) => app.id)
        .filter(Boolean) as number[];

      const result = await updateMultipleApplications(submittedFields);

      if (result)
        setIsModalOpen((prevState) => ({
          ...prevState,
          pending: false,
          title: 'Bulk Editing Completed',
          message: 'Please proceed to the applications screen to view your changes',
        }));
      else {
        setIsModalOpen({ ...isModalOpen, status: false });
        tossError('There was an error updating applications.');
      }
    }
  };

  const getMultipleApps = async (appObj: Application[]) => {
    const result = await Promise.all(appObj.map(async (app) => getApplication(`${app.id}`)));
    setMultipleApps(result);
  };

  const navigateBack = () => navigate(`${process.env.PUBLIC_URL}/dashboard/applications`);

  /**
   * Compares the properties of an application object with other application objects.
   * @param obj1 - The application object to compare.
   * @param apps - An array of application objects to compare with.
   */
  // The provided code is a function named compareData that compares the properties of an application object (obj1) with other application objects (apps). Here's a breakdown of what the function does:
  //
  // It creates an empty object common that will store the comparison results.
  // It gets the keys of obj1 excluding 'customFields' and stores them in propertiesToCompare.
  // It iterates over each property in propertiesToCompare and for each property, it iterates over each application in apps.
  // For each application (item), it checks if the current property (key) is equal in item and obj1. If they are equal and common[key] is not already set to 'Multiple', it sets common[key] to the value of item[key]. If they are not equal, it sets common[key] to 'Multiple'.
  // If key is 'fundingAmount' or 'varyingFundingAmount' and the values in item and obj1 are not equal, it sets common.varyingFundingAmount or common.fundingAmount to 'Multiple', respectively.
  // If obj1[key] is truthy, it updates toolTipData state. If common[key] is 'Multiple', it adds obj1[key] to the array of options for the current property. Otherwise, it sets the options to an array containing only obj1[key].
  // After comparing all properties, it checks if common.customFields exists and is a string that includes 'id'. If it does, it removes backslashes and trims leading and trailing characters to clean up the string.
  //
  // The purpose of this function seems to be to compare multiple applications and track which properties have multiple different values. The results of the comparison are stored in the common object and used to update the toolTipData state. The 'Multiple' string appears to be used to indicate that a property has multiple different values across the compared applications.
  const compareData = (obj1: Application, apps: Application[]) => {
    const common: Record<string, string | 'Multiple'> = {};

    const propertiesToCompare = Object.keys(obj1).filter((prop) => prop !== 'customFields');

    for (const prop of propertiesToCompare) {
      apps.forEach((item) => {
        if (item.id === obj1.id) return;

        const key = prop as keyof Application;
        // eslint-disable-next-line no-extra-boolean-cast
        const keyComparison = common[key] !== 'Multiple' && item[key] === obj1[key];

        if (keyComparison) common[key] = item[key] as keyof typeof common;
        else common[key] = 'Multiple';

        // TODO: For now keep this for debugging, but once bulk edit issues are complete - remove it.
        // console.log(key, common, common[key], item[key], obj1[key], item[key] === obj1[key]);

        // If either of these are multiple, the other variable must be multiiple also, so it's not affected.
        if (key === 'fundingAmount' && item[key] !== obj1[key]) {
          common.varyingFundingAmount = 'Multiple';
        }
        if (key === 'varyingFundingAmount' && item[key] !== obj1[key]) {
          common.fundingAmount = 'Multiple';
        }

        setToolTipData((prev) => {
          const multipleOptions = [...new Set((prev[prop] as string[]) || []), obj1[key] || 'None'];

          return {
            ...prev,
            [prop]: common[key] === 'Multiple' ? multipleOptions : [obj1[key] || 'None'],
          };
        });
      });
    }

    let parsedField = '';
    if (common?.customFields?.length) {
      parsedField = (common?.customFields as string)?.includes('id')
        ? (common?.customFields as string)
        : '';
      if (parsedField?.length) {
        if (parsedField?.includes('\\')) {
          parsedField = parsedField?.replace(/\\/g, '');
        }
        if (parsedField?.startsWith('["')) {
          parsedField = parsedField?.slice(2);
        }
        if (parsedField?.includes('}]"')) {
          parsedField = parsedField?.replace('}]"', '}');
        }
        if (parsedField?.includes('[,')) {
          parsedField = parsedField.replace('[,', '');
        }
      }
    }

    setApplication({ ...common, customFields: (parsedField as string) || ([] as string[]) });
  };

  const statusNote = useMemo(() => {
    return (toolTipData?.status as number[])
      ?.filter((value: number) => applicationsConfigs?.statuses[value] !== undefined)
      .map((value) => applicationsConfigs?.statuses[value]);
  }, [toolTipData]);

  const determineEligibilityStatus = (eligible: Application[], total: Application[]) => {
    let bar = '';

    if (!eligible?.length)
      bar = 'Selected applications will not be affected because you do not have access to them';
    if (eligible?.length && eligible?.length < total?.length)
      bar = `${total.length - eligible.length} out of ${
        total.length
      } selected applications will not be affected because you do not have access to them`;

    return bar?.length ? bar : '';
  };

  useEffect(() => {
    if (state?.data) {
      getMultipleApps(state?.data);
      if (currentUser.userType === 'millenniumAnalyst') setReadAccess(true);
      else setReadAccess(false);
    }
  }, [state, currentUser.userType]);

  useEffect(() => {
    if (multipleApps) {
      const eligible: Application[] = [];

      multipleApps?.forEach((app) => {
        if (
          currentUser.userType !== 'millenniumAnalyst' &&
          (currentUser.userType === 'millenniumAdmin' ||
            // TODO: needs refactoring probably supported with API response
            (app.clientId &&
              currentUser.applicationClients?.map(({ id }) => id).includes(app?.clientId)))
        )
          eligible.push(app);

        compareData(app, multipleApps);
      });

      const status = determineEligibilityStatus(eligible, multipleApps);

      if (eligible?.length) setEligibleApps({ status, apps: eligible });
      else setEligibleApps({ status, apps: [] });
    }
  }, [multipleApps, currentUser.userType]);

  useEffect(() => {
    if (applicationId) {
      update();
    }
  }, [applicationId]);

  useEffect(() => {
    const {
      name,
      funder,
      fundingAmount,
      amountVaries,
      source,
      category,
      startDate,
      dueDate,
      assignee,
      client,
    } = form;

    const errorList: Partial<Record<keyof ApplicationFormFields, string> & { undefineds: string }> =
      {};

    if (typeof name !== 'undefined') {
      if (!name || name === '') errorList.name = 'This field is required.';
      else if (name.length < 3)
        errorList.name = 'Grant program names must have 3 or more characters.';
    }

    if (typeof startDate !== 'undefined' && typeof dueDate !== 'undefined') {
      if (startDate && dueDate) {
        if (moment(dueDate).isBefore(startDate)) {
          errorList.startDate = 'Start Date must be earlier than Due Date.';
          errorList.dueDate = 'Due Date must be later than Start Date.';
        }
      }
    }

    if (Number.isNaN(fundingAmount) && !amountVaries)
      errorList.fundingAmount = 'Enter a valid funding amount.';

    if (!assignee) errorList.assignee = 'Please pick an assignee.';

    if (!client) errorList.client = 'Please pick a client.';

    if (
      [
        typeof name,
        typeof funder,
        typeof fundingAmount,
        typeof source,
        typeof category,
        typeof assignee,
        typeof client,
      ].includes('undefined')
    )
      errorList.undefineds = 'One or more required fields are undefined.';

    setErrors(errorList);
  }, [form]);

  useEffect(() => {
    if (application && !multipleApps.length) {
      if (
        application.startsAt &&
        application.startsAt !== 'Multiple' &&
        application.startsAt.toString().match(/invalid date/i) === null
      )
        setField('startDate', application.startsAt);
      if (
        application.endsAt &&
        application.endsAt !== 'Multiple' &&
        application.endsAt.toString().match(/invalid date/i) === null
      )
        setField('dueDate', application.endsAt);
      if (
        application.notifyDate &&
        application.notifyDate !== 'Multiple' &&
        application.notifyDate.toString().match(/invalid date/i) === null
      )
        setField('dateNotified', application.notifyDate);
      if (
        application.submissionDate &&
        application.submissionDate !== 'Multiple' &&
        application.submissionDate.toString().match(/invalid date/i) === null
      )
        setField('submissionDate', application.submissionDate);
      if (
        application.awardDate &&
        application.awardDate !== 'Multiple' &&
        application.awardDate.toString().match(/invalid date/i) === null
      )
        setField('awardDate', application.awardDate);
    }
  }, [application]);

  const defaultCustomFields = recursiveParseJsonArray(application?.customFields);

  return application ? (
    <>
      {multipleApps && (
        <Modal centered isOpen={isModalOpen?.status}>
          <ModalBody className="applications-loading">
            {isModalOpen?.pending ? (
              <Spinner animation="border" role="status" />
            ) : (
              <CheckCircle size={150} />
            )}
            <span>{isModalOpen?.title}</span>
            <span>{isModalOpen?.message}</span>
            {!isModalOpen?.pending && (
              <Button color="primary-green" onClick={navigateBack} variant="primary-green">
                Done
              </Button>
            )}
          </ModalBody>
        </Modal>
      )}
      <Container className="application-detail-form" fluid>
        <Stack alignItems="space-between" direction="column" mb={4} spacing={1}>
          <Box mb={2}>
            <Stack alignItems="center" direction="row" spacing={1}>
              <span
                className="breadcrumb-list"
                onClick={() => navigate(-1)}
                onKeyDown={(e) => e.key === 'Enter' && navigate(-1)}
                role="button"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                tabIndex={0}
              >
                Application
              </span>
              <span>{'>'}</span>
              <span className="breadcrumb-list" style={{ fontWeight: '700' }}>
                {multipleApps?.length
                  ? `Editing ${multipleApps?.length} Applications`
                  : 'Application Details'}
              </span>
            </Stack>
          </Box>
          <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
            <Stack alignItems="center" direction="row" spacing={1}>
              <IconButton
                className="back-button"
                onClick={() => navigate(-1)}
                sx={{
                  width: '34px',
                  height: '34px',
                  backgroundColor: '#eff1f6',
                  borderRadius: '6px',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#e0e3eb',
                  },
                }}
              >
                <ArrowBack />
              </IconButton>

              <h5 className="heading">Application Details #CT{application?.id} </h5>
            </Stack>
            <Stack direction="row" spacing={1}>
              {!readAccess && (
                <>
                  <div className={multipleApps?.length ? 'd-flex' : 'w-100 d-flex'}>
                    <DuplicateApplication
                      application={application}
                      disabled={multipleApps?.length ? !eligibleApps.apps?.length : readAccess}
                      eligibleApps={eligibleApps}
                      setIsModalOpen={setIsModalOpen}
                    />
                    &nbsp;&nbsp;
                    <DeleteApplication
                      applicationId={applicationId!}
                      disabled={multipleApps?.length ? !eligibleApps.apps?.length : readAccess}
                      eligibleApps={eligibleApps}
                      setIsModalOpen={setIsModalOpen}
                    />
                  </div>

                  <Button
                    className="discard-btn d-flex align-items-center"
                    onClick={navigateBack}
                    style={{ height: '46px' }}
                    variant="light"
                  >
                    Discard
                  </Button>
                  {multipleApps?.length > 0 && (
                    <div className="application-header-controls">
                      {eligibleApps?.status && (
                        <span className="eligibility-status">{eligibleApps.status}</span>
                      )}
                      <Button
                        className="cancel-editing-btn"
                        color="primary-blue"
                        onClick={navigateBack}
                        style={{ height: '46px', width: 'max-content' }}
                        variant="primary-blue"
                      >
                        Cancel Editing
                      </Button>
                    </div>
                  )}
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    disabled={
                      multipleApps?.length
                        ? !eligibleApps.apps?.length
                        : Object.keys(errors || {}).length > 0
                    }
                    onClick={multipleApps?.length ? saveMultiple : save}
                    style={{ height: '46px', width: '-webkit-fill-available' }}
                  >
                    <Check size={16} />
                    &nbsp;Save Changes
                  </Button>
                </>
              )}
            </Stack>
          </Stack>

          <h6 className="sub-heading">
            Edit, duplicate or delete Application Details for this award
          </h6>
        </Stack>
        <Form>
          <Stack className="details-main-layout" direction="row" spacing={2}>
            <Stack className="left-column" direction="column" spacing={2} sx={{ width: '70%' }}>
              <Box className="general-detail-section">
                <h5 className="application-edit-heading">General</h5>
                <Row>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.funder,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {!readAccess ? (
                        <FunderInput
                          controlId="editApplication.Funder"
                          placeholder="Start typing a name..."
                          {...(application?.funder && {
                            defaultValue: application?.funder || 'None',
                          })}
                          className="common-input"
                          disabled={readAccess}
                          onChange={(newValue: string) => setField('funder', newValue)}
                          tooltipData={toolTipData?.funder}
                        />
                      ) : (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Funder</div>
                          <div className="field-value">{toolTipData?.funder}</div>
                        </div>
                      )}
                    </RenderTooltip>
                  </Col>
                  <Col
                    className={`${multipleApps?.length ? 'application-edit-progname' : ''}`}
                    lg={6}
                    xs={12}
                  >
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.name,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Grant Program Name</div>
                          <div className="field-value">{application?.name}</div>
                        </div>
                      ) : (
                        <TextInput
                          controlId="editApplication.Name"
                          defaultValue={application?.name}
                          label="Grant Program Name"
                          placeholder="My Grant Program"
                          required={!readAccess}
                          {...(multipleApps?.length && { multipleEdit: application?.name })}
                          className="common-input"
                          disabled={readAccess}
                          errors={errors?.name}
                          onChange={(newValue: string) => setField('name', newValue)}
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.assigneeName,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Assignee</div>
                          <div className="field-value selectorbg">
                            {application?.assigneeName || 'None'}
                          </div>
                        </div>
                      ) : (
                        <AssigneeInput
                          controlId="editApplication.Assignee"
                          disabled={readAccess}
                          {...(application?.assignee && {
                            defaultValue: application?.assignee || 'None',
                          })}
                          onChange={(newValue: number) => setField('assignee', newValue)}
                          {...(multipleApps?.length && {
                            multipleEdit: {
                              id: application?.assigneeId || 'None',
                              name: application?.assigneeName || 'None',
                            },
                          })}
                          className="common-input"
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.category,
                        choices: applicationsConfigs?.categories,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Category</div>
                          <div className="field-value selectorbg">
                            {applicationsConfigs?.categories[application?.category] || 'None'}
                          </div>
                        </div>
                      ) : (
                        <SelectInputNew
                          choices={applicationsConfigs?.alphabetSortedCategories}
                          className="common-input"
                          controlId=""
                          defaultValue={
                            (application.category as unknown) === 'Multiple'
                              ? application.category
                              : applicationsConfigs?.alphabetSortedCategories.indexOf(
                                  applicationsConfigs.categories[application.category || 0]
                                )
                          }
                          disabled={readAccess}
                          label="Category"
                          onChange={(newValue: string | number) =>
                            setField('category', newValue as number)
                          }
                          required={!readAccess}
                          tooltipData={
                            (application.category as unknown) === 'Multiple'
                              ? [toolTipData?.category]
                              : applicationsConfigs?.alphabetSortedCategories.indexOf(
                                  applicationsConfigs.categories[
                                    typeof toolTipData?.category === 'number'
                                      ? toolTipData?.category
                                      : 0
                                  ]
                                )
                          }
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.source,
                        choices: applicationsConfigs?.sources,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Source</div>
                          <div className="field-value selectorbg">
                            {application?.source || 'None'}
                          </div>
                        </div>
                      ) : (
                        <SelectInputNew
                          choices={applicationsConfigs?.sources}
                          className="common-input"
                          controlId="editApplication.Source"
                          defaultValue={application?.source}
                          disabled={readAccess}
                          label="Source"
                          onChange={(newValue) => setField('source', newValue)}
                          required={!readAccess}
                          tooltipData={toolTipData?.source}
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.clientName,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Client</div>
                          <div className="field-value selectorbg">
                            {application?.clientName || 'None'}
                          </div>
                        </div>
                      ) : (
                        <ClientInput
                          controlId="editApplication.Client"
                          disabled={readAccess}
                          {...(application?.client && {
                            defaultValue: application?.client || 'None',
                          })}
                          {...(multipleApps?.length && {
                            multipleEdit: {
                              id: application?.clientId || 'None',
                              name: application?.clientName || 'None',
                            },
                          })}
                          className="common-input"
                          onChange={(newValue: number) => setField('client', newValue)}
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.estimatedResponse,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Grant Purpose</div>
                          <div className="field-value">{application?.grantPurpose || 'None'}</div>
                        </div>
                      ) : (
                        <TextInput
                          controlId="editApplication.GrantPurpose"
                          defaultValue={application?.grantPurpose}
                          disabled={readAccess}
                          label="Grant Purpose"
                          onChange={(newValue: string) => setField('grantPurpose', newValue)}
                          {...(multipleApps?.length && {
                            placeholder: application?.grantPurpose || 'None',
                          })}
                          className="common-input"
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.departmentNotified,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Department Notified</div>
                          <div className="field-value">
                            {application?.departmentNotified || 'None'}
                          </div>
                        </div>
                      ) : (
                        <TextInput
                          controlId="editApplication.DepartmentNotified"
                          defaultValue={application?.departmentNotified}
                          disabled={readAccess}
                          label="Department Notified"
                          onChange={(newValue: string) => setField('departmentNotified', newValue)}
                          {...(multipleApps?.length && {
                            placeholder: application?.departmentNotified || 'None',
                          })}
                          className="common-input"
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                </Row>
              </Box>

              <Box className="financial-detail-section">
                <h5 className="application-edit-heading">Grant Application Financial Details</h5>
                <Row>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.fundingAmount,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Funding Amount / Award Info</div>
                          <div className="field-value">
                            {application?.varyingFundingAmount ||
                              application?.fundingAmount ||
                              'None'}
                          </div>
                        </div>
                      ) : (
                        <MoneyInputNew
                          canVary
                          controlId="editApplication.FundingAmount"
                          defaultStringAmount={application?.varyingFundingAmount}
                          defaultVaries={application?.amountVaries}
                          disabled={readAccess}
                          errors={errors?.fundingAmount}
                          label="Funding Amount / Award Info"
                          onAmountChange={(newAmount: number) =>
                            setField('fundingAmount', newAmount)
                          }
                          onVaryChange={(newVaries: boolean) => setField('amountVaries', newVaries)}
                          onVaryingAmountChange={(newAmount: string) =>
                            setField('varyingFundingAmount', newAmount)
                          }
                          required={!readAccess}
                          text={readAccess ? '' : 'May be either fixed or varying.'}
                          {...(typeof application?.fundingAmount === 'number' && {
                            defaultAmount: application?.fundingAmount,
                          })}
                          {...(multipleApps?.length && {
                            placeholder: application?.fundingAmount || 'None',
                          })}
                          className="common-input"
                          isFromPrograms={isfromapplication}
                          textClass="currency-text"
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.matchRequirements,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Match Requirements</div>
                          <div className="field-value">
                            {application?.matchRequirements || 'None'}
                          </div>
                        </div>
                      ) : (
                        <TextInput
                          controlId="editApplication.MatchRequirements"
                          defaultValue={application?.matchRequirements}
                          disabled={readAccess}
                          label="Match Requirements"
                          onChange={(newValue: string) => setField('matchRequirements', newValue)}
                          {...(multipleApps?.length && {
                            placeholder: application?.matchRequirements || 'None',
                          })}
                          className="common-input"
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.performancePeriod,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Period of Performance</div>
                          <div className="field-value">
                            {application?.performancePeriod || 'None'}
                          </div>
                        </div>
                      ) : (
                        <TextInput
                          controlId="editApplication.PeriodOfPerformance"
                          defaultValue={application?.performancePeriod}
                          disabled={readAccess}
                          label="Period of Performance"
                          onChange={(newValue: string) => setField('performancePeriod', newValue)}
                          {...(multipleApps?.length && {
                            placeholder: application?.performancePeriod || 'None',
                          })}
                          className="common-input"
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                  <Col lg={6} xs={12}>
                    <RenderTooltip
                      {...(multipleApps?.length && {
                        tooltipData: toolTipData?.estimatedResponse,
                        delay: { show: 250, hide: 400 },
                      })}
                    >
                      {readAccess ? (
                        <div className="field-heading-wraper">
                          <div className="field-heading">Estimated Response</div>
                          <div className="field-value">
                            {application?.estimatedResponse || 'None'}
                          </div>
                        </div>
                      ) : (
                        <TextInput
                          controlId="editApplication.EstimatedResponse"
                          defaultValue={application?.estimatedResponse}
                          disabled={readAccess}
                          label="Estimated Response"
                          onChange={(newValue: string) => setField('estimatedResponse', newValue)}
                          {...(multipleApps?.length && {
                            placeholder: application?.estimatedResponse || 'None',
                          })}
                          className="common-input"
                        />
                      )}
                    </RenderTooltip>
                  </Col>
                </Row>
                {/* <Row>
                  <Col className="pb-3" lg={12} xs={12}>
                    <Form.Label>Grant Summary File</Form.Label>
                    <Form.Control
                      defaultValue={
                        application?.summaryFile
                          ? application.summaryFile.split('_').slice(1).join('')
                          : 'None'
                      }
                      disabled
                      type="text"
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="mt-4">
                      <h5 className="grant-summary-file">Grant Summary File</h5>
                      <hr />
                      {!IsUserAdmin ||
                        (!IsClient && (
                          <Row style={{ marginBottom: '12px' }}>
                            <Col lg={6} xs={12}>
                              <div className="dz-message tall needsclick">
                                <Dropzone
                                  disabled={readAccess}
                                  getUploadParams={getUploadParams}
                                  inputContent="Drag Files Here"
                                  onChangeStatus={handleChangeStatus}
                                />
                              </div>
                            </Col>
                          </Row>
                        ))}
                      <Row>
                        {files?.length === 0 ? (
                          <Col>
                            <Form.Text muted>
                              No files uploaded yet. Drag and drop files above to upload.
                            </Form.Text>
                          </Col>
                        ) : (
                          Array.isArray(files) &&
                          files?.map((file) => (
                            <Col key={file.id} lg={4} md={6} sm={12} xl={4}>
                              <FileCard file={file} readOnly={readAccess} removeappfile />
                            </Col>
                          ))
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Box>
            </Stack>
            <Box className="right-column" sx={{ width: '30%' }}>
              <h5 className="application-edit-heading">Status and Dates</h5>
              <Row style={{ marginBottom: '10px' }}>
                <Col lg={12} xs={12}>
                  <RenderTooltip
                    {...(multipleApps?.length && {
                      tooltipData: toolTipData?.status,
                      choices: [
                        ...applicationsConfigs.statuses,
                        ...applicationsConfigs.additionalCases,
                      ],
                      delay: { show: 250, hide: 400 },
                    })}
                  >
                    {readAccess ? (
                      <div className="field-heading-wraper">
                        <div className="field-heading">Status</div>
                        <div className="field-value">
                          {applicationsConfigs.statuses[application?.status] || 'None'}
                        </div>
                      </div>
                    ) : (
                      <StatusSelector
                        defaultValue={Number(application?.status)}
                        disabled={readAccess}
                        onChange={(newValue: number) => setField('status', newValue)}
                        tooltipData={toolTipData?.status as number[]}
                      />
                    )}
                  </RenderTooltip>
                </Col>
              </Row>

              <Row style={{ marginBottom: '10px' }}>
                <Col lg={12} xs={12}>
                  <RenderTooltip
                    {...(multipleApps?.length && {
                      tooltipData: toolTipData?.notifyDate,
                      delay: { show: 250, hide: 400 },
                    })}
                  >
                    {readAccess ? (
                      <div className="field-heading-wraper">
                        <div className="field-heading">Client Notified At</div>
                        <div className="field-value">{form?.dateNotified || 'None'}</div>
                      </div>
                    ) : (
                      <DateField
                        className="common-input"
                        controlId="editApplication.NotifiedDate"
                        disabled={readAccess}
                        errors={errors?.dateNotified}
                        label="Client Notified At"
                        onChange={(newValue) => setField('dateNotified', newValue)}
                        tooltipData={toolTipData?.notifyDate as unknown[]}
                        value={form?.dateNotified as string}
                      />
                    )}
                  </RenderTooltip>
                </Col>
              </Row>

              <Row style={{ marginBottom: '10px' }}>
                <Col lg={12} xs={12}>
                  <RenderTooltip
                    {...(multipleApps?.length && {
                      tooltipData: toolTipData?.startsAt,
                      delay: { show: 250, hide: 400 },
                    })}
                  >
                    {readAccess ? (
                      <div className="field-heading-wraper">
                        <div className="field-heading">Start Date</div>
                        <div className="field-value">{form?.startDate || 'None'}</div>
                      </div>
                    ) : (
                      <DateField
                        className="common-input"
                        controlId="editApplication.StartDate"
                        disabled={readAccess}
                        errors={errors?.startDate}
                        label="Start Date"
                        onChange={(newValue: string | null) => setField('startDate', newValue)}
                        tooltipData={toolTipData?.startsAt as unknown[]}
                        value={form?.startDate as string}
                      />
                    )}
                  </RenderTooltip>
                </Col>
              </Row>

              <Row style={{ marginBottom: '10px' }}>
                <Col lg={12} xs={12}>
                  <RenderTooltip
                    {...(multipleApps?.length && {
                      tooltipData: toolTipData?.endsAt,
                      delay: { show: 250, hide: 400 },
                    })}
                  >
                    {readAccess ? (
                      <div className="field-heading-wraper">
                        <div className="field-heading">Due Date</div>
                        <div className="field-value">{form?.dueDate || 'None'}</div>
                      </div>
                    ) : (
                      <DateField
                        className="common-input"
                        controlId="editApplication.DueDate"
                        disabled={readAccess}
                        errors={errors?.dueDate}
                        label="Due Date"
                        onChange={(newValue) => setField('dueDate', newValue)}
                        tooltipData={toolTipData?.endsAt as unknown[]}
                        value={form?.dueDate as string}
                      />
                    )}
                  </RenderTooltip>
                </Col>
              </Row>

              <Row style={{ marginBottom: '10px' }}>
                <Col lg={12} xs={12}>
                  <RenderTooltip
                    {...(multipleApps?.length && {
                      tooltipData: toolTipData?.submissionDate,
                      delay: { show: 250, hide: 400 },
                    })}
                  >
                    {readAccess ? (
                      <div className="field-heading-wraper">
                        <div className="field-heading">Grant Submitted At</div>
                        <div className="field-value">{form?.submissionDate || 'None'}</div>
                      </div>
                    ) : (
                      <DateField
                        className="common-input"
                        controlId="editApplication.SubmissionDate"
                        disabled={readAccess}
                        errors={errors?.submissionDate}
                        label="Grant Submitted At"
                        onChange={(newValue) => setField('submissionDate', newValue)}
                        tooltipData={toolTipData?.submissionDate as unknown[]}
                        value={form?.submissionDate as string}
                      />
                    )}
                  </RenderTooltip>
                </Col>
              </Row>

              <Row style={{ marginBottom: '10px' }}>
                <Col lg={12} xs={12}>
                  <RenderTooltip
                    {...(multipleApps?.length && {
                      tooltipData: toolTipData?.awardDate,
                      delay: { show: 250, hide: 400 },
                    })}
                  >
                    {readAccess ? (
                      <div className="field-heading-wraper">
                        <div className="field-heading">Grant Date Decided</div>
                        <div className="field-value">{form?.awardDate || 'None'}</div>
                      </div>
                    ) : (
                      <DateField
                        controlId="editApplication.AwardedDate"
                        disabled={readAccess}
                        errors={errors?.awardDate}
                        label="Grant Date Decided"
                        onChange={(newValue) => setField('awardDate', newValue)}
                        value={!toolTipData?.awardDate ? (form?.awardDate as string) : ''}
                        {...(multipleApps?.length && {
                          placeholder: application?.awardDate || 'None',
                        })}
                        className="common-input"
                        tooltipData={toolTipData?.awardDate as unknown[]}
                      />
                    )}
                  </RenderTooltip>
                </Col>
              </Row>

              <Row style={{ marginBottom: '10px' }}>
                <Col lg={12} xs={12}>
                  <RenderTooltip
                    {...(multipleApps?.length && {
                      tooltipData: statusNote?.length > 1 && statusNote,
                      delay: { show: 250, hide: 400 },
                    })}
                  >
                    {readAccess ? (
                      <div className="field-heading-wraper">
                        <div className="field-heading">Status Notes</div>
                        <div className="field-value">
                          {statusNote?.length > 1 ? 'Multiple' : statusNote?.[0] || 'None'}
                        </div>
                      </div>
                    ) : (
                      <NotesStatusInput
                        controlId="editApplication.NotesStatus"
                        defaultValue={application?.submissionStatus}
                        disabled={readAccess}
                        onChange={(newValue: string) => setField('notesStatus', newValue)}
                        placeholder="Enter status details"
                        {...(multipleApps?.length && {
                          placeholder:
                            statusNote?.length > 1 ? 'Multiple' : statusNote?.[0] || 'None',
                        })}
                        className="common-input"
                      />
                    )}
                  </RenderTooltip>
                </Col>
              </Row>
            </Box>
          </Stack>
          {!readAccess && (
            <Stack direction="row" spacing={2}>
              {(currentUser.isMillenniumUser ||
                (defaultCustomFields.length > 0 &&
                  !readAccess &&
                  defaultCustomFields !== '[]')) && (
                <div className="mt-4 custom-fields-wrapper">
                  <div
                    className="hide-show-wrapper"
                    onClick={toggleSectionVisibility}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        toggleSectionVisibility();
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <h5 className="heading-gb mb-4">Custom Fields</h5>
                    <span className="ms-2" style={{ display: 'flex', alignItems: 'center' }}>
                      {isSectionVisible ? (
                        <>
                          <KeyboardArrowUp
                            className="ms-2"
                            style={{ color: '#2C4474', fontSize: '18px' }}
                          />
                          <span className="show-hide-text">Hide Section</span>
                        </>
                      ) : (
                        <>
                          <KeyboardArrowDown
                            className="ms-2"
                            style={{ color: '#2C4474', fontSize: '18px' }}
                          />{' '}
                          <span className="show-hide-text">Show Section</span>
                        </>
                      )}
                    </span>
                  </div>
                  {isSectionVisible && (
                    <>
                      <hr style={{ margin: '0px' }} />
                      <CustomFields
                        disabled={readAccess}
                        {...((application?.customFields?.length === 0 ||
                          (application?.customFields as string[])?.includes('id')) && {
                          defaultFields: defaultCustomFields,
                        })}
                        onChange={(newFields: CustomField[]) => setField('customFields', newFields)}
                        {...(multipleApps?.length && {
                          placeholder: application?.customFields || 'None',
                        })}
                      />
                    </>
                  )}
                </div>
              )}
            </Stack>
          )}
          {!multipleApps?.length && !readAccess && (
            <Stack className="mt-2" direction="column" spacing={2}>
              {!multipleApps?.length && <HistoryBar state={application.actionLogs?.state} />}
            </Stack>
          )}
        </Form>
      </Container>
    </>
  ) : (
    <>Loading the application, please wait.</>
  );
}
