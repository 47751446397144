import { useContext, useEffect, useState } from 'react';
import { CustomInput } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FilterContext } from 'pages/ClientList';
import FilterSelect from 'shared/muiComponents/FilterSelect';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import { searchClients } from 'services/clientService';
import { searchClientContacts } from 'services/clientContactsService';
import useQueryString from 'hooks/useQueryString';
import { states } from 'constants/globalConstants';
import { Stack } from '@mui/material';
import UsedClientFiltersDescriptionBlock from './UsedClientsFilterBlock';

function useQueryFilters() {
  const queryFilters = useQueryString();
  const page = queryFilters.get('p');
  const names = queryFilters.get('n');
  const state = queryFilters.get('s');
  const taxIds = queryFilters.get('t');
  const duns = queryFilters.get('d');
  const contactNames = queryFilters.get('cn');
  const contactPhones = queryFilters.get('cp');
  const contactEmails = queryFilters.get('ce');
  const contactTitles = queryFilters.get('ct');
  const myClients = queryFilters.get('mc');

  return {
    names: names ? names.split(',').map((e) => ({ name: e })) : [],
    taxIds: taxIds ? taxIds.split(',').map((e) => ({ name: e })) : [],
    duns: duns ? duns.split(',').map((e) => ({ name: e })) : [],
    state: state || undefined,
    contactNames: contactNames ? contactNames.split(',').map((e) => ({ name: e })) : [],
    contactPhones: contactPhones ? contactPhones.split(',').map((e) => ({ name: e })) : [],
    contactEmails: contactEmails ? contactEmails.split(',').map((e) => ({ name: e })) : [],
    contactTitles: contactTitles ? contactTitles.split(',').map((e) => ({ name: e })) : [],
    page: page ? parseInt(page, 10) : 1,
    myClients: myClients ? Boolean(myClients) : false,
  };
}

export default function ClientFilters() {
  const { filters, setFilters } = useContext(FilterContext);

  const urlFilters = useQueryFilters();

  const [, setSearchParams] = useSearchParams();

  const [clientsSelected, setClientsSelected] = useState(urlFilters.names);
  const [taxIdsSelected, setTaxIdsSelected] = useState(urlFilters.taxIds);
  const [dunsSelected, setDunsSelected] = useState(urlFilters.duns);
  const [contactNamesSelected, setContactNamesSelected] = useState(urlFilters.contactNames);
  const [contactEmailsSelected, setContactEmailsSelected] = useState(urlFilters.contactEmails);
  const [contactPhonesSelected, setContactPhonesSelected] = useState(urlFilters.contactPhones);
  const [contactTitlesSelected, setContactTitlesSelected] = useState(urlFilters.contactTitles);
  const [myClientsSelected, setMyClientsSelected] = useState(urlFilters.myClients);

  useEffect(
    () => setFilters((prevState) => ({ ...prevState, names: clientsSelected })),
    [clientsSelected]
  );
  useEffect(
    () => setFilters((prevState) => ({ ...prevState, taxIds: taxIdsSelected })),
    [taxIdsSelected]
  );
  useEffect(
    () => setFilters((prevState) => ({ ...prevState, duns: dunsSelected })),
    [dunsSelected]
  );
  useEffect(
    () => setFilters((prevState) => ({ ...prevState, contactNames: contactNamesSelected })),
    [contactNamesSelected]
  );
  useEffect(
    () => setFilters((prevState) => ({ ...prevState, contactEmails: contactEmailsSelected })),
    [contactEmailsSelected]
  );
  useEffect(
    () => setFilters((prevState) => ({ ...prevState, contactPhones: contactPhonesSelected })),
    [contactPhonesSelected]
  );
  useEffect(
    () => setFilters((prevState) => ({ ...prevState, contactTitles: contactTitlesSelected })),
    [contactTitlesSelected]
  );
  useEffect(
    () => setFilters((prevState) => ({ ...prevState, myClients: myClientsSelected })),
    [myClientsSelected]
  );

  useEffect(() => {
    const { page } = urlFilters;
    setFilters((prevState) => ({ ...prevState, page }));
  }, []);

  const updateFiltersInURL = () => {
    if (isEmpty(filters)) return;

    const historyObject = {};

    const {
      names: filterNames,
      state: filterState,
      taxIds: filterTaxIds,
      duns: filterDuns,
      contactNames: filterContactNames,
      contactEmails: filterContactEmails,
      contactPhones: filterContactPhones,
      contactTitles: filterContactTitles,
      page: filterPage,
      myClients: filterMyClients,
    } = filters || {};

    if (!isEmpty(filters)) {
      if (filterNames && filterNames.length > 0)
        historyObject.n = filterNames.map((e) => e.name).join(',');
      if (filterTaxIds && filterTaxIds.length > 0)
        historyObject.t = filterTaxIds.map((e) => e.name).join(',');
      if (filterDuns && filterDuns.length > 0)
        historyObject.d = filterDuns.map((e) => e.name).join(',');

      if (filterState) historyObject.s = filterState;

      if (filterContactNames && filterContactNames.length > 0)
        historyObject.cn = filterContactNames.map((e) => e.name).join(',');
      if (filterContactEmails && filterContactEmails.length > 0)
        historyObject.ce = filterContactEmails.map((e) => e.name).join(',');
      if (filterContactPhones && filterContactPhones.length > 0)
        historyObject.cp = filterContactPhones.map((e) => e.name).join(',');
      if (filterContactTitles && filterContactTitles.length > 0)
        historyObject.ct = filterContactTitles.map((e) => e.name).join(',');

      if (filterPage) historyObject.p = filterPage;
      if (filterMyClients) historyObject.mc = filterMyClients;
    }

    setSearchParams(historyObject);
  };

  useEffect(updateFiltersInURL, [filters]);

  const handleClientSearch = async (query: string) => {
    const foundClients = await searchClients(query, 'name');
    return foundClients.map((e) => e.name);
  };

  const handleTaxIdSearch = async (query: string) => {
    const foundTaxIds = await searchClients(query, 'taxId');
    return foundTaxIds.map((e) => e.taxId);
  };

  const handleDunsSearch = async (query: string) => {
    const foundDuns = await searchClients(query, 'dunsNumber');
    return foundDuns.map((e) => e.dunsNumber);
  };

  const handleContactNameSearch = async (query: string) => {
    const foundNames = await searchClientContacts(query, 'name');
    return foundNames.map((e) => e.name);
  };

  const handleContactEmailsSearch = async (query: string) => {
    const foundEmails = await searchClientContacts(query, 'email');
    return foundEmails.map((e) => e.email);
  };

  const handleContactPhonesSearch = async (query: string) => {
    const foundPhones = await searchClientContacts(query, 'phoneNumber');
    return foundPhones.map((e) => e.phoneNumber);
  };

  const handleContactTitlesSearch = async (query: string) => {
    const foundTitles = await searchClientContacts(query, 'title');
    return foundTitles.map((e) => e.title);
  };

  return (
    <Stack direction="column" mb={2} mt={2} sx={{ width: '100%' }}>
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ width: '100%' }}
      >
        <SearchFilter
          getLabel={(f) => f}
          label="Client"
          onChange={(values) => setClientsSelected(values.map((name) => ({ name })))}
          onSearch={handleClientSearch}
          selected={clientsSelected.map((f) => f.name)}
          variant="filter"
        />
        <FilterSelect
          getValueLabel={(value) => {
            if (!value || value === 'all') return 'All States';
            return states.find((s) => s.abbr === value)?.name || '';
          }}
          label="State"
          onChange={(value) =>
            setFilters((prevState) => ({
              ...prevState,
              state: value === 'all' ? null : value,
            }))
          }
          options={['all', ...states.map((s) => s.abbr)]}
          selected={filters.state || 'all'}
        />
        <SearchFilter
          getLabel={(f) => f}
          label="Tax ID"
          onChange={(values) => setTaxIdsSelected(values.map((name) => ({ name })))}
          onSearch={handleTaxIdSearch}
          selected={taxIdsSelected.map((f) => f.name)}
          variant="filter"
        />
        <SearchFilter
          getLabel={(f) => f}
          label="UEI Number"
          onChange={(values) => setDunsSelected(values.map((name) => ({ name })))}
          onSearch={handleDunsSearch}
          selected={dunsSelected.map((f) => f.name)}
          variant="filter"
        />
      </Stack>
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ width: '100%', marginTop: 2 }}
      >
        <SearchFilter
          getLabel={(f) => f}
          label="Contact Name"
          onChange={(values) => setContactNamesSelected(values.map((name) => ({ name })))}
          onSearch={handleContactNameSearch}
          selected={contactNamesSelected.map((f) => f.name)}
          variant="filter"
        />
        <SearchFilter
          getLabel={(f) => f}
          label="Contact Email"
          onChange={(values) => setContactEmailsSelected(values.map((name) => ({ name })))}
          onSearch={handleContactEmailsSearch}
          selected={contactEmailsSelected.map((f) => f.name)}
          variant="filter"
        />
        <SearchFilter
          getLabel={(f) => f}
          label="Contact Phone"
          onChange={(values) => setContactPhonesSelected(values.map((name) => ({ name })))}
          onSearch={handleContactPhonesSearch}
          selected={contactPhonesSelected.map((f) => f.name)}
          variant="filter"
        />
        <SearchFilter
          getLabel={(f) => f}
          label="Contact Title"
          onChange={(values) => setContactTitlesSelected(values.map((name) => ({ name })))}
          onSearch={handleContactTitlesSearch}
          selected={contactTitlesSelected.map((f) => f.name)}
          variant="filter"
        />
      </Stack>
      <UsedClientFiltersDescriptionBlock />
      <Stack
        direction="row"
        justifyContent="flex-start"
        spacing={1}
        sx={{ width: '100%', marginTop: 2 }}
      >
        <CustomInput
          checked={myClientsSelected}
          id="enableClient"
          label="Only My Clients"
          name="enableClientSwitch"
          onChange={(e) => setMyClientsSelected(e.target.checked)}
          type="switch"
        />
      </Stack>
    </Stack>
  );
}
