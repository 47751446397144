import { saveAs } from 'file-saver';
import moment from 'moment';
import { IconButton, Stack, Typography } from '@mui/material';
import { DeleteForeverOutlined, Download, InsertDriveFileOutlined } from '@mui/icons-material';
import { tossError, tossSuccess } from 'utils/toastTosser';

import { downloadClientFile, deleteClientFile } from 'services/clientFilesService';
import { deleteApplicationFile } from 'services/applicationService';

import { ClientFile } from 'types/client';

interface FileCardProps {
  file: ClientFile;
  afterRemove: () => void;
  readOnly: boolean;
  removeappfile: boolean;
}

export default function FileCard({ file, afterRemove, readOnly, removeappfile }: FileCardProps) {
  const downloadFile = () => {
    downloadClientFile(file.fileUrl).then((buffer) => {
      saveAs(new Blob([buffer]), file.name);
    });
  };

  const removeFile = async () => {
    let result;
    if (!removeappfile) {
      result = await deleteClientFile(file.id);
    } else {
      result = await deleteApplicationFile(file.id);
    }
    if (result) {
      tossSuccess('The file had been deleted successfully.');
      afterRemove();
    } else tossError('Failed to delete this file.');
  };

  return (
    <Stack alignItems="center" direction="row" gap={5} justifyContent="space-between" width="100%">
      <Stack alignItems="center" direction="row" gap="10px">
        <InsertDriveFileOutlined color="primary" />
        <Stack>
          <Typography color="primary.main" component="div" variant="body1">
            {`${file.name}  ${file.size}`}
          </Typography>
          <Typography color="primary.main" variant="caption">
            {moment(new Date(file.createdAt)).format('MMM DD YYYY')}
          </Typography>
        </Stack>
      </Stack>

      <Stack alignItems="center" direction="row" gap="8px">
        <IconButton
          aria-label="download"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            downloadFile();
          }}
          size="small"
          sx={{
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            borderRadius: 2,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <Download />
        </IconButton>

        {!readOnly && (
          <IconButton
            aria-label="delete"
            onClick={async (event) => {
              event.stopPropagation();
              event.preventDefault();
              await removeFile();
            }}
            size="small"
            sx={{
              fontSize: '1rem',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              borderRadius: 2,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <DeleteForeverOutlined />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}
