import { AxiosResponse } from 'axios';

import { User, UserUpdatePayload } from 'types/user';
import { Invitee } from 'components/users/InviteUser';
import api, { outputError } from './apiService';

const { REACT_APP_USERS_PER_PAGE } = process.env;

interface UsersList {
  rows: User[];
  pages: number;
  entries: number;
}

export const sessionUser = (): Promise<User> =>
  api
    .get(`/users`)
    .then((response: AxiosResponse<User>) => {
      if (response.status < 303 && response.data) {
        const receivedUser = response.data;

        if (!receivedUser.applicationClients) receivedUser.applicationClients = [];
        if (!receivedUser.awardClients) receivedUser.awardClients = [];
        return receivedUser;
      }

      return {} as User;
    })
    .catch((error) => {
      outputError(error);
      return {} as User;
    });

export const createInvitedUser = (data: {
  tokenString: string;
  phone: string;
  password: string;
}): Promise<boolean> =>
  api
    .post(`/users/create-invited-user`, data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const createUser = (data: UserUpdatePayload): Promise<boolean> =>
  api
    .post(`/users`, data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const getUsers = (filters: string): Promise<UsersList> =>
  api
    .get(`/users/read${filters}`)
    .then((response: AxiosResponse<{ count: number; rows: User[] }>) => {
      if (response.status < 303) {
        const { count, rows } = response.data;

        for (const user of rows) {
          // TODO: handle it properly
          if (user.applicationClients === null) user.applicationClients = [];
          if (user.awardClients === null) user.awardClients = [];
        }

        return {
          rows,
          pages: Math.ceil(count / Number(REACT_APP_USERS_PER_PAGE || '20')),
          entries: count,
        };
      }
      return { rows: [], pages: 1, entries: 0 };
    })
    .catch((error) => {
      outputError(error);
      return { rows: [], pages: 1, entries: 0 };
    });

export const searchUsers = (
  query: string,
  field: string,
  enabled = false,
  isAward = true,
  clients = []
): Promise<User[]> => {
  const searchParams = new URLSearchParams(window.location.search);

  const selectedClients = (
    typeof searchParams.get('clients') === 'string'
      ? [searchParams.get('clients') || '']
      : searchParams.getAll('clients') || ''
  ).join(',');

  return api
    .get(
      `/users/search?query=${encodeURIComponent(
        query
      )}&field=${field}&enabled=${enabled}&isAward=${isAward}&clients=${selectedClients}`
    )
    .then((response: AxiosResponse<User[]>) => {
      if (response.status < 303) return response.data;
      // .filter((user) => {
      //     return !selectedClients || !user.userType.match(/user/)
      //       ? true
      //       : user?.clientCreator?.name && selectedClients.join(',').match(user.clientCreator.name);
      //   });
      return [];
    })
    .catch((error) => {
      outputError(error);
      return [];
    });
};

export const updateUser = (data: UserUpdatePayload): Promise<boolean> =>
  api
    .put(`/users`, data)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const inviteUsers = (data: Invitee[]): Promise<boolean> =>
  api
    .post(`/users/invite`, { invitees: data })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
