import { Stack, Typography, Checkbox } from '@mui/material';

import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';

import { tossError } from 'utils/toastTosser';
import { useEffect, useState } from 'react';
import { AwardDocumentCategory } from 'types/awards';
import useCurrentAward from 'hooks/useCurrentAward';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import RenderTextWithHighlight from 'utils/helpers/RenderTextWithHighlight';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import { downloadAwardFilesArchive } from 'services/awards/awardDocumentsService';
import MountedModal from 'shared/muiComponents/MountedModal';

interface DownloadDocumentsModalProps {
  onClose: () => void;
}

// TODO move to utils
function formatFileSize(bytes: number) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / k ** i).toFixed(2));
  return `${size} ${sizes[i]}`;
}

export default function DownloadDocumentsModal({ onClose }: DownloadDocumentsModalProps) {
  const { currentAwardId, documentsData, documentsFilters } = useCurrentAward();

  const [selectedDocumentIds, setSelectedDocumentIds] = useState<number[]>([]);

  useEffect(() => {
    if (documentsData?.data)
      setSelectedDocumentIds(documentsData.data.map((document) => document.id));
  }, [documentsData?.data]);

  const handleCheck = (id: number) => {
    setSelectedDocumentIds((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
    );
  };

  const documentsByCategory = (category: AwardDocumentCategory, label: string) => {
    const currentDocuments = documentsData.data?.filter((d) => d.category === category) || [];

    return currentDocuments.length > 0 ? (
      <Stack>
        <Stack alignItems="center" direction="row" sx={{ w: '100%', wordBreak: 'break-word' }}>
          {label && (
            <Typography my={1} variant="h3">
              {label}
            </Typography>
          )}
        </Stack>

        <Stack gap="10px" sx={{ wordBreak: 'break-word' }}>
          {currentDocuments.map((document) => (
            <Stack key={document.id} alignItems="center" direction="row" gap={1}>
              <Checkbox
                checked={selectedDocumentIds.includes(document.id)}
                onClick={() => handleCheck(document.id)}
                sx={{ p: 0 }}
              />

              <InsertDriveFileOutlined color="primary" />

              <Typography color="primary.main" component="div" variant="body1">
                <RenderTextWithHighlight
                  highlight={documentsFilters.search || ''}
                  text={`${document.name}  ${formatFileSize(document.size)}`}
                />
              </Typography>

              <Typography color="primary.main" variant="caption">
                {dayjs(document.createdAt).format('MMM DD YYYY')}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    ) : null;
  };

  const handleDownload = async () => {
    const data = await downloadAwardFilesArchive(
      currentAwardId,
      `?fileIds=${selectedDocumentIds.join(',')}`
    );

    if (data) {
      saveAs(
        new Blob([data, { type: 'application/zip' }]),
        `Award_${currentAwardId}_Documents_${dayjs().format('YYYYMMDD_HHmmss')}.zip`
      );
    } else tossError('Error downloading selected Documents');
  };

  return (
    <MountedModal onClose={onClose}>
      <Typography my={2} variant="h1">
        Download Documents
      </Typography>

      <Stack direction="column" spacing={2}>
        <Typography color="primary" variant="h2">
          Initial Documentation
        </Typography>
        <Typography variant="body1">
          Award letters & resolutions are typically required for all grants. When a grant is first
          awarded, populate all of the required documents here.
        </Typography>

        <Stack direction="column" gap="8px">
          {documentsByCategory('initialApplication', 'Application')}
          {documentsByCategory('initialAwardLetter', 'Award Letter')}
          {documentsByCategory('initialResolution', 'Resolution')}
          {documentsByCategory('initialAgreement', 'Agreement')}
        </Stack>

        <Typography color="primary" variant="h2">
          Contract materials
        </Typography>
        <Typography variant="body1">
          Contact materials are typically required for all grants. When a grant is appropriated,
          populate all of the required documents here.
        </Typography>

        <Stack direction="column" gap="8px">
          {documentsByCategory('requiredContractMaterial', 'Required contract material')}
          {documentsByCategory('optionalContractMaterial', 'Other contract materials (optional)')}
        </Stack>

        <Typography color="primary" variant="h2">
          Reports & Related Documents
        </Typography>
        <Typography variant="body1">
          Reports & Related Documents depend on the grant. All reports uploaded for the grant as
          well as any other documents you upload will show here
        </Typography>

        <Stack direction="column" gap="8px">
          {documentsByCategory('report', 'Reports')}
          {documentsByCategory('other', 'Additional uploaded documents')}
        </Stack>
      </Stack>

      <Stack alignItems="center" direction="row" justifyContent="end" mt={3} spacing={2}>
        <SecondaryButton onClick={onClose}>Close</SecondaryButton>
        <StandardButton disabled={selectedDocumentIds.length === 0} onClick={handleDownload}>
          Download .zip archive
        </StandardButton>
      </Stack>
    </MountedModal>
  );
}
