import { useState, useEffect } from 'react';

interface IWindowSize {
  height?: number;
  width?: number;
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<IWindowSize>({});

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
