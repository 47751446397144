import { SyntheticEvent, useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import BootstrapTable, {
  BootstrapTableProps,
  ColumnDescription,
  PaginationOptions,
} from 'react-bootstrap-table-next';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton, Stack, Typography } from '@mui/material';
import { Card } from 'react-bootstrap';
import { format } from 'date-fns';

import useLocalStorage from 'hooks/useLocalStorage';
import useSearchParams from 'hooks/useSearchParams';
import { ProjectsList, deleteProject } from 'services/awards/awardsService';

import { UserSessionContext } from 'index';

import { Project } from 'types/awards';
import StandardButton from 'shared/muiComponents/StandardButton';
import TooltipWithAnchor from 'shared/muiComponents/TooltipWithAnchor';
import { tossError, tossSuccess } from 'utils/toastTosser';
import NoProjectsEmptyScreen from './NoProjectsEmptyScreen';
import EditProjectModal from '../modals/EditProjectModal';
import NewProjectModal from '../modals/NewProjectModal';

interface ProjectsTableProps {
  loading: boolean;
  data?: ProjectsList;
  refetchProjects: () => void;
}

const defaultTooltipMessage = 'Click to view "Project" page';

export default function ProjectsTable({ data, loading, refetchProjects }: ProjectsTableProps) {
  const { searchParams, setSearchParams } = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [storedFilter, setStoredFilter] = useLocalStorage<string>('awardsProjectsFilter', 'v1.2');
  const [selectedProject, setSelectedProject] = useState<Project>({} as Project);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [hoveredRow, setHoveredRow] = useState<Project | null>(null);
  const [hoveredCell, setHoveredCell] = useState<HTMLElement | null>(null);
  const [tooltipMessage, setTooltipMessage] = useState(defaultTooltipMessage);
  const [goToPage, setGoToPage] = useState('');

  const currentUser = useContext(UserSessionContext);

  const canEdit =
    !currentUser?.isMillenniumAnalyst && currentUser?.userType !== 'millenniumResearcher';

  const isClientUser =
    currentUser?.userType === 'userAdmin' || currentUser?.userType === 'userAnalyst';

  const currentPage = Number(searchParams.page);
  const perPage = Number(searchParams.perPage || 20);
  const totalRecords = data?.count || 0;
  const pageCount = Math.ceil(totalRecords / perPage);
  const startItem = (currentPage - 1) * perPage + 1;

  const goToSelectedProject = (projectId: number) =>
    navigate(`${process.env.PUBLIC_URL}/dashboard/awards/projects/details/${projectId}`);

  const toggleCreate = () => setIsCreateOpen((prevState) => !prevState);
  const toggleEdit = () => setIsEditOpen((prevState) => !prevState);

  useEffect(() => {
    if (storedFilter) {
      navigate(storedFilter, { replace: true });
    } else {
      setSearchParams({ sortBy: 'id', sortOrder: 'desc', page: 1, perPage: 20 });
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      setStoredFilter(location.pathname + location.search);
    }
  }, [location]);

  const handleSortChange = (sortField: string, sortOrder: string) => {
    setSearchParams({ page: 1, sortBy: sortField, sortOrder });
  };

  const handleGoToPage = (e: React.FormEvent) => {
    e.preventDefault();
    const pageNum = parseInt(goToPage, 10);
    if (pageNum >= 1 && pageNum <= pageCount) {
      setSearchParams({ page: pageNum });
      window.scrollTo({ top: 250 });
    }
    setGoToPage('');
  };

  const displayValue = goToPage || (goToPage === '' ? '#' : currentPage.toString());

  const remove = async (projectId: number) => {
    const result = await deleteProject(projectId);

    if (result) {
      tossSuccess('The project was successfully deleted.');
      refetchProjects();
    } else tossError('Error deleting the project.');
  };

  function awardCategoryFormatter(cell: string) {
    return (
      <Chip
        label={cell}
        size="small"
        sx={{
          color: '#FFFFFF',
          border: `1px solid #5F77A5`,
          fontSize: '0.75rem',
          fontWeight: '750',
          borderRadius: '2px',
          backgroundColor: '#5F77A5',
        }}
        variant="outlined"
      />
    );
  }

  function actionsFormatter(_cell: string, row: Project) {
    return (
      <Stack direction="row" justifyContent="left" spacing={4}>
        {canEdit && (
          <>
            <IconButton
              aria-label="edit"
              className="table-hover-btn"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedProject(row);
                toggleEdit();
              }}
              onMouseOut={() => setTooltipMessage(defaultTooltipMessage)}
              onMouseOver={() => setTooltipMessage('Click to edit')}
              sx={{ borderRadius: 'initial', backgroundColor: '#EFF1F6' }}
            >
              <BorderColorIcon />
            </IconButton>

            <IconButton
              aria-label="delete"
              className="table-hover-btn"
              onClick={(e) => {
                e.stopPropagation();
                remove(row.id);
              }}
              onMouseOut={() => setTooltipMessage(defaultTooltipMessage)}
              onMouseOver={() => setTooltipMessage('Click to delete')}
              sx={{ borderRadius: 'initial', backgroundColor: '#EFF1F6' }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Stack>
    );
  }

  const ProjectsAwardsColumns = (): ColumnDescription<Project>[] => {
    const eventsHandlers = {
      onMouseEnter: (event: SyntheticEvent) => {
        setHoveredCell(event.target instanceof HTMLElement ? event.target : null);
      },
    };

    return [
      {
        dataField: 'name',
        text: 'Project Name',
        sort: true,
      },
      {
        dataField: 'category',
        text: 'Project Category',
        sort: true,
        formatter: awardCategoryFormatter,
      },
      {
        dataField: 'createdBy.name',
        text: 'Created By',
        sort: false,
      },
      ...(isClientUser
        ? []
        : [
            {
              dataField: 'client.name',
              text: 'Client',
              sort: false,
            },
          ]),
      {
        dataField: 'createdAt',
        text: 'Date Created',
        sort: true,
        formatter: (cell: string) => format(new Date(cell), 'eeee, MMMM do yyyy'),
      },
      {
        dataField: 'actions',
        text: 'Options',
        isDummyField: true,
        formatter: actionsFormatter,
      },
    ].map((column) => ({ ...column, events: eventsHandlers }));
  };

  const contentTable = ({
    paginationProps,
    paginationTableProps,
  }: {
    paginationProps: PaginationOptions;
    paginationTableProps: BootstrapTableProps;
  }) => (
    <>
      <BootstrapTable<Project>
        {...paginationTableProps}
        bodyClasses={loading ? 'loading' : ''}
        bootstrap4
        bordered={false}
        columns={ProjectsAwardsColumns()}
        data={[...new Set(data?.rows || [])]}
        keyField="id"
        onTableChange={(type, newState) => {
          if (type === 'sort') {
            handleSortChange(newState.sortField, newState.sortOrder);
          }
        }}
        remote={{
          sort: true,
          pagination: true,
        }}
        rowEvents={{
          onClick: (_e: SyntheticEvent, row: Project) => goToSelectedProject(row.id),
          onMouseEnter: (_e: SyntheticEvent, row: Project) => setHoveredRow(row),
          onMouseLeave: () => setHoveredRow(null),
        }}
        sort={{ dataField: searchParams.sortBy, order: searchParams.sortOrder as 'asc' | 'desc' }}
        wrapperClasses="table-responsive table-borderless hover"
      />

      {data?.count >= 1 && (
        <div className="my-3 pagination-wrapper">
          <div className="records-showing-text">
            Showing {startItem} of {totalRecords}
          </div>
          <div className="pagination-controls-wrapper">
            <form className="pagination-form-field" onSubmit={handleGoToPage}>
              <span className="me-2 label-text">Go to page</span>
              <input
                aria-label="Page number"
                className="form-control form-control-sm goToPage-input"
                max={pageCount}
                min="1"
                onBlur={() => {
                  if (goToPage === '') {
                    setGoToPage('#');
                  }
                }}
                onChange={(e) => setGoToPage(e.target.value)}
                onFocus={() => {
                  if (goToPage === '' && currentPage) {
                    setGoToPage(currentPage.toString());
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleGoToPage(e);
                  }
                }}
                type="number"
                value={displayValue || '#'}
              />
            </form>

            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}

      <TooltipWithAnchor
        anchorElement={hoveredCell}
        isOpen={Boolean(hoveredRow)}
        text={tooltipMessage}
      />
    </>
  );

  if (data?.rows?.length === 0 || !data?.rows)
    return (
      <>
        <NoProjectsEmptyScreen canEdit={canEdit} onCreate={toggleCreate} />

        {isCreateOpen && (
          <NewProjectModal refetchProjects={refetchProjects} toggle={toggleCreate} />
        )}
      </>
    );

  return (
    <>
      <Card className="w-100">
        <Card.Header>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography variant="h2">Projects</Typography>
            {canEdit && (
              <StandardButton onClick={toggleCreate} startIcon>
                Create New Project
              </StandardButton>
            )}
          </Stack>
        </Card.Header>

        <Card.Body>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              hideSizePerPage: true,
              withFirstAndLast: pageCount > 5,
              alwaysShowAllBtns: true,
              page: currentPage,
              totalSize: totalRecords,
              sizePerPage: perPage,
              firstPageText: '«',
              nextPageText: '›',
              prePageText: '‹',
              lastPageText: '»',
              onPageChange: (newPage) => {
                setSearchParams({ page: newPage });
                window.scrollTo({ top: 250 });
              },
            })}
          >
            {contentTable}
          </PaginationProvider>
        </Card.Body>
      </Card>

      {isCreateOpen && !currentUser?.isMillenniumAnalyst && (
        <NewProjectModal refetchProjects={refetchProjects} toggle={toggleCreate} />
      )}

      {isEditOpen && !currentUser?.isMillenniumAnalyst && (
        <EditProjectModal
          data={selectedProject}
          refetchProjects={refetchProjects}
          toggle={toggleEdit}
        />
      )}
    </>
  );
}
