import { Tooltip, Grow } from '@mui/material';
import { ReactElement } from 'react';

export default function TooltipWrapper({
  text,
  children,
}: {
  text: string;
  children: ReactElement;
}) {
  return (
    <Tooltip
      enterDelay={600}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: (theme) => theme.palette.primary.main,
            fontSize: '0.7rem',
            fontWeight: '600',
          },
        },
      }}
      title={text}
      TransitionComponent={Grow}
    >
      {children}
    </Tooltip>
  );
}
