import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import useUserSession from 'hooks/useUserSession';
import { searchClients } from 'services/clientService';
import { Client } from 'types/client';

interface ClientInputProps {
  controlId: string;
  disabled: boolean;
  defaultValue?: Partial<Client>;
  single?: boolean;
  errors?: string;
  text?: string;
  associatedOnly?: boolean;
  placeholder: string;
  onChange: (id: number | number[] | null) => void;
  multipleEdit: { id: number; name: string };
  className?: string;
}

export default function ClientInput(props: ClientInputProps) {
  const {
    controlId,
    disabled = false,
    defaultValue,
    single = true,
    errors,
    text,
    associatedOnly = false,
    placeholder,
    onChange,
    multipleEdit,
    className,
  } = props;

  const currentUser = useUserSession();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<{ id: number; name: string }[]>(
    defaultValue
      ? [
          {
            id: defaultValue.id!,
            name: `${defaultValue.name} (${defaultValue.state})`,
          },
        ]
      : []
  );
  const [options, setOptions] = useState<{ id: number; name: string }[]>([]);

  const search = async (query: string) => {
    setLoading(true);

    const foundClients = await searchClients(query, 'name');

    const mappedClients = foundClients
      .filter((e) => (associatedOnly ? e.users.find((user) => user.id === currentUser.id) : true))
      .map((e) => ({
        id: e.id,
        name: e.name,
      }));

    setOptions(mappedClients);
    setLoading(false);
  };

  const onInputChange = (newValue: { id: number; name: string }[]) => {
    if (single) setSelected(newValue.length > 0 ? [newValue.pop()!] : []);
    else setSelected(newValue);
  };

  useEffect(() => {
    if (single) {
      // If we have a selected client, their id is passed, else nothing is passed.
      if (onChange) onChange(selected.length > 0 ? selected[0].id! : null);
    } else if (onChange) onChange(selected.length > 0 ? selected.map((e) => e.id!) : null);
  }, [selected]);

  useEffect(() => {
    if (multipleEdit)
      setSelected([{ ...selected, id: multipleEdit?.id, name: multipleEdit?.name }]);
  }, []);

  return (
    <Form.Group className={className} controlId={controlId}>
      <Form.Label>
        Client{single ? '' : 's'} {!disabled && '*'}
      </Form.Label>
      <AsyncTypeahead
        disabled={disabled}
        id={controlId}
        isInvalid={!!errors}
        isLoading={loading}
        labelKey="name"
        minLength={1}
        multiple
        onChange={onInputChange}
        onSearch={search}
        options={options}
        placeholder={placeholder}
        renderMenuItemChildren={(option) => <span>{option.name}</span>}
        selected={selected}
      />
      {errors ? <div className="validation-errors">{errors}</div> : <Form.Text>{text}</Form.Text>}
    </Form.Group>
  );
}
