import { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Box,
  IconButton,
  keyframes,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { ExpandMoreRounded } from '@mui/icons-material';
import useCurrentAward from 'hooks/useCurrentAward';

import { AwardStateChange } from 'types/actionLogs';
import { camelCaseToWords } from 'utils/utilFunctions';
import { Award } from 'types/awards';
import { applicationsConfigs } from 'constants/globalConstants';

dayjs.extend(advancedFormat);

export function formatCurrency(value: number | null) {
  if (value === null) return '0.00';

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

const LogLimit = 5;
const LogDetailLimit = 3;

const validateAndFormatStateChange = (
  awardState: Partial<Award> & Partial<{ [key: string]: string | boolean }>,
  changedValue: keyof Award | string
): string => {
  // as there is no info about the types of changed values
  // here we try to handle various cases and provide fallbacks for unexpected values:

  if (changedValue.match(/id|Id/) !== null) return 'none';
  if (!awardState[changedValue] && !['source'].includes(changedValue)) return 'none';

  if (['size', 'enabled', 'updatedAt', 'createdAt', 'type', 'user'].includes(changedValue))
    return 'none';

  // format dates
  if (['startsOn', 'dueDate', 'endsOn'].includes(changedValue))
    return dayjs(String(awardState[changedValue])).format('ddd, MMM Do - YYYY');

  // format $
  if (
    [
      'awardAmount',
      'awardExpended',
      'awardBalance',
      'matchAmount',
      'matchExpended',
      'matchBalance',
    ].includes(changedValue)
  )
    return `$${formatCurrency(Number(awardState[changedValue]))}`;

  // format required/optional documents
  if (awardState[changedValue] === true) return 'Yes';
  if (awardState[changedValue] === false) return 'No';

  // handle legacy number values
  if (changedValue === 'source') return applicationsConfigs.sources[awardState[changedValue] || 0];
  if (changedValue === 'category')
    return applicationsConfigs.categories[awardState[changedValue] || 0];

  // stringify all not covered cases
  return String(awardState[changedValue]);
};

function ChangeLog({ change }: { change: AwardStateChange }) {
  // show expanded if changelog is not too long
  const [expanded, setExpanded] = useState(false);
  const [showInitialFields, setShowInitialFields] = useState(false);
  const [lastLogsShown, setLastLogsShown] = useState(expanded ? 100 : LogDetailLimit);

  const hasValues = Object.keys(change.award?.newState).length > 0;
  const keys: string[] = [];
  const values: string[] = [];
  const oldStateValues: string[] = [];

  const oldState = Object.keys(change.award?.oldState).filter(
    (key) => key.match(/Entity/) === null
  );

  if (hasValues) {
    [...oldState, ...Object.keys(change.award?.newState)]
      // Unique values only
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((column) => {
        if (column.match(/_/) !== null) return column;

        const value = validateAndFormatStateChange(change.award?.newState, column as keyof Award);
        const oldValue = validateAndFormatStateChange(
          change.award?.oldState,
          column as keyof Award
        );

        // Uncomment to debug
        // if (change.status !== 'Award Created')
        //   console.log(
        //     'value',
        //     value,
        //     'oldValue',
        //     oldValue,
        //     column,
        //     change.award?.oldState,
        //     change.award?.newState
        //   );

        if (change.status === 'Award Created') {
          if (value === 'none') return column;
        } else if (value?.trim() === oldValue?.trim()) return column;

        if (!value && !oldValue) return column;
        if (!value && oldValue === 'none') return column;
        if (!oldValue && value === 'none') return column;

        keys.push(camelCaseToWords(column));
        values.push(camelCaseToWords(value));

        // Do not show old values for new entities
        if (oldValue !== 'none' || (oldValue === 'none' && column.match(/Entity/) === null))
          oldStateValues.push(camelCaseToWords(oldValue));

        return column;
      });
  }

  if (change.status === 'Award Created')
    return (
      <Stack>
        <Stack alignItems="center" direction="row" mb={1}>
          <Typography color="primary.main">{change.status}</Typography>
          <IconButton
            disableRipple
            onClick={() => setShowInitialFields(!showInitialFields)}
            size="small"
            sx={{ borderRadius: 2, color: (theme) => theme.palette.primary.main }}
          >
            <ExpandMoreRounded transform={showInitialFields ? 'rotate(180)' : ''} />
            <Typography sx={{ fontWeight: 600 }} variant="caption">
              {showInitialFields ? 'Hide' : 'Show details'}
            </Typography>
          </IconButton>
        </Stack>

        {showInitialFields && (
          <Stack gap="5px">
            {hasValues &&
              keys.map((key, index) => (
                <Stack key={key} alignItems="center" direction="row" gap={1}>
                  <Typography variant="caption">{key}:</Typography>
                  <Stack alignItems="center" direction="row" gap={2}>
                    <Typography>{values[index]}</Typography>
                  </Stack>
                </Stack>
              ))}
          </Stack>
        )}
      </Stack>
    );

  // if (keys.length !== values.length || keys.length !== oldStateValues.length) return <div />;

  return (
    <Stack>
      <Stack alignItems="center" direction="row" mb={1}>
        <Typography color="primary.main">{change.status}</Typography>

        {keys.length > LogLimit && (
          <IconButton
            disableRipple
            onClick={() => {
              setExpanded(!expanded);
              setLastLogsShown(expanded ? 100 : LogDetailLimit);
            }}
            size="small"
            sx={{ borderRadius: 2, color: (theme) => theme.palette.primary.main }}
          >
            <ExpandMoreRounded transform={expanded ? 'rotate(180)' : ''} />
            <Typography sx={{ fontWeight: 600 }} variant="caption">
              {expanded ? 'Hide' : 'Show all changes'}
            </Typography>
          </IconButton>
        )}
      </Stack>

      <Stack gap="5px">
        {hasValues &&
          keys.slice(0, lastLogsShown).map((key, index) => (
            <Stack key={key} alignItems="center" direction="row" gap={1}>
              <Typography variant="caption">{key}:</Typography>
              <Stack alignItems="center" direction="row" gap={1}>
                {oldStateValues[index] && <Typography>{oldStateValues[index]}</Typography>}
                {oldStateValues[index] && values[index] && (
                  <KeyboardArrowRightIcon fontSize="small" />
                )}
                {values[index] && <Typography>{values[index]}</Typography>}
              </Stack>
            </Stack>
          ))}
        {!expanded && keys.length > LogDetailLimit && '...'}
      </Stack>
    </Stack>
  );
}

const columns = [
  {
    dataField: 'user.userName',
    text: 'Change made by',
    headerStyle: () => ({ width: '200px' }),
  },
  {
    dataField: 'changedColumns',
    text: 'Change',
    formatter: (_cell: string, row: AwardStateChange) => <ChangeLog change={row} />,
  },
  {
    dataField: 'date',
    text: 'Time',
    headerStyle: () => ({ width: '200px' }),
    formatter: (cell: string) => {
      const options: Intl.DateTimeFormatOptions = {
        month: 'short', // Abbreviated month name
        day: '2-digit', // Day of the month
        year: 'numeric', // Four-digit year
        hour: '2-digit', // Hour (12-hour format by default)
        minute: '2-digit', // Minutes
        hour12: true, // Use 12-hour format
      };

      return new Date(cell).toLocaleString('en-US', options);
    },
  },
];

export default function ActionHistory() {
  const { awardData } = useCurrentAward();
  const { data, loading, error } = awardData;
  const [expanded, setExpanded] = useState(false);

  if (!data?.actionLogs) return <div />;

  const lastLogsShown = expanded ? 100 : LogLimit;

  return (
    <Paper sx={{ width: '100%', p: 2, borderRadius: 3, my: 2, boxShadow: '0 0 15px 10px #f7f7f7' }}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography mt={2} mb={2} variant="h2">
          Action History
        </Typography>

        {data?.actionLogs?.state.length > 3 && (
          <IconButton
            aria-label="expand"
            disabled={error}
            disableRipple
            onClick={() => setExpanded(!expanded)}
            size="small"
            sx={{ borderRadius: 2, color: (theme) => theme.palette.primary.main }}
          >
            <ExpandMoreRounded transform={expanded ? 'rotate(180)' : ''} />
            <Typography sx={{ fontWeight: 600 }} variant="caption">
              {expanded ? 'Collapse' : 'Show All'}
            </Typography>
          </IconButton>
        )}
      </Stack>

      <>
        {loading && <LinearProgress />}
        <BootstrapTable
          bodyClasses={loading ? 'loading' : ''}
          bootstrap4
          bordered={false}
          columns={columns}
          data={data?.actionLogs?.state.slice(0, lastLogsShown) || []}
          keyField="date"
          noDataIndication="No actions are taken yet"
          wrapperClasses="table-responsive table-borderless"
        />
        {!expanded && data?.actionLogs?.state.length > LogLimit && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: 100,
              background: 'linear-gradient(transparent, white)',
              pointerEvents: 'none',
            }}
          />
        )}
      </>
    </Paper>
  );
}
