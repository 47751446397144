import { useState, useEffect } from 'react';
import { Token } from 'react-bootstrap-typeahead';
import Dropdown from 'react-bootstrap/Dropdown';

interface ClientUser {
  id: number;
  name: string;
}

interface ClientAwardUsersPickerProps {
  readOnly?: boolean;
  options?: ClientUser[];
  defaultValues: ClientUser[];
  onChange: (userIds: number[]) => void;
}

export default function ClientAwardUsersPicker({
  readOnly,
  options,
  defaultValues,
  onChange,
}: ClientAwardUsersPickerProps) {
  const [selected, setSelected] = useState<ClientUser[]>(defaultValues);
  const availableOptions = options
    ? options.filter((user) => !selected.find((u) => u.id === user.id))
    : [];

  useEffect(() => {
    if (options)
      setSelected(selected.filter((user) => Boolean(options.find((u) => u.id === user.id))));
  }, [options]);

  useEffect(() => {
    onChange(selected.map((user) => user.id));
  }, [selected]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="w-100 text-left form-control"
        disabled={readOnly || availableOptions.length === 0}
        variant="outline-select"
      >
        {readOnly ? 'No permissions for editing this list' : 'Add Users'}
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100">
        {availableOptions.map((user) => (
          <Dropdown.Item key={user.id} onClick={() => setSelected([...selected, user])}>
            {user.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>

      <div style={{ marginTop: '10px' }}>
        {selected.map((user) => (
          <Token
            key={user.id}
            className="disable-select"
            disabled={readOnly}
            onRemove={() => setSelected(selected.filter((c) => c.id !== user.id))}
            option={user}
            readOnly={readOnly}
          >
            {user.name}
          </Token>
        ))}
      </div>
    </Dropdown>
  );
}
