import { ToastContent, toast } from 'react-toastify';

const tossError = (error: ToastContent) => {
  toast.error(error, {
    className: 'error-toast',
    delay: 750,
  });
};

const tossSuccess = (message: ToastContent) => {
  toast.success(message, {
    className: 'success-toast',
    delay: 250,
  });
};

const tossWarning = (message: ToastContent) => {
  toast.warning(message, {
    className: 'warning-toast',
    delay: 250,
  });
};

export { tossSuccess, tossError, tossWarning };
