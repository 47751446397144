import { ColumnDescription } from 'react-bootstrap-table-next';
import { format } from 'date-fns';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { AwardPayments } from 'types/awards';
import { formatCurrencyValue } from 'utils/utilFunctions';
import ActionField from './ActionField';

const paymentTypeValues = {
  advance: 'Advance Payment',
  reimbursement: 'Reimbursement',
};

type TypesOption = keyof typeof paymentTypeValues;

const getPaymentsColumns = (
  callback: () => void,
  remove: (id: number) => void,
  setSelectedPayment: (data: AwardPayments) => void,
  toggleEdit: () => void,
  currentAwardId: string,
  readOnly: boolean
): ColumnDescription<AwardPayments>[] => {
  const columns: ColumnDescription<AwardPayments>[] = [
    {
      dataField: 'id',
      text: 'Payment ID',
      sort: true,
    },
    {
      dataField: 'requestedDate',
      text: 'Day Requested',
      formatter: (cell: string) => format(new Date(cell), 'eeee, MMMM do yyyy'),
      sort: true,
    },
    {
      dataField: 'user.name',
      text: 'Name',
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (amount: number | null) => (amount ? formatCurrencyValue(amount) : '-'),
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Payment Type',
      sort: true,
      formatter: (type: string) => (type ? paymentTypeValues[type as TypesOption] : '-'),
    },
    {
      dataField: 'report.string_id',
      text: 'Report Link',
      formatter: (report: string) => {
        const reportLinkId = (report?.match(/[a-z0-9]+$/) || [])[0] || '';

        return (
          <Link
            color="primary.main"
            component={RouterLink}
            fontWeight={600}
            to={`/dashboard/award/${currentAwardId}/reporting/${reportLinkId}`}
            variant="body1"
          >
            {report}
          </Link>
        );
      },
    },
    ...(readOnly
      ? []
      : [
          {
            dataField: 'status',
            text: 'Actions',
            formatter: (_cell: string, row: AwardPayments) => {
              return (
                <ActionField
                  callback={callback}
                  id={row.id}
                  itemStatus={row.status}
                  itemType={row.type}
                />
              );
            },
          },
          {
            dataField: 'actions',
            text: '',
            isDummyField: true,
            formatter: (_cell: string, row: AwardPayments) => {
              return (
                <Stack direction="row" justifyContent="center" spacing={4}>
                  <IconButton
                    aria-label="edit"
                    className="table-hover-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedPayment(row);
                      toggleEdit();
                    }}
                    sx={{
                      visibility: 'hidden',
                      borderRadius: 'initial',
                      backgroundColor: '#EFF1F6',
                    }}
                  >
                    <BorderColorIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    className="table-hover-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      remove(row.id);
                    }}
                    sx={{
                      visibility: 'hidden',
                      borderRadius: 'initial',
                      backgroundColor: '#EFF1F6',
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              );
            },
          },
        ]),
  ];
  return columns;
};

export default getPaymentsColumns;
