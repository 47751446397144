import EmptyScreenCard from 'shared/EmptyScreenCard';
import StandardButton from 'shared/muiComponents/StandardButton';

export default function NoReportsEmptyScreen({
  onCreate,
  canEdit = true,
}: {
  onCreate: () => void;
  canEdit?: boolean;
}) {
  return (
    <EmptyScreenCard title="Reports">
      <p>There are no reports yet.</p>
      {canEdit && (
        <StandardButton onClick={onCreate} startIcon>
          Add New Report
        </StandardButton>
      )}
    </EmptyScreenCard>
  );
}
