import { Container, Col, Row } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { UserSessionContext } from 'index';
import IconButton from 'shared/muiComponents/IconButton';
import { getProject } from 'services/awards/awardsService';
import { Project } from 'types/awards';
import Awards from './sections/Awards';
import FundingSources from './sections/FundingSources';
import FundingSummary from './sections/FundingSummary';
import EditProjectModal from './modals/EditProjectModal';

function ProjectDetails() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<Project>({} as Project);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const currentUser = useContext(UserSessionContext);

  const canEdit =
    !currentUser?.isMillenniumAnalyst && currentUser?.userType !== 'millenniumResearcher';

  const toggleEdit = () => setIsEditOpen((prevState) => !prevState);

  const fetchProject = async (id: number) => {
    const result = await getProject(id);
    setData(result);
  };

  useEffect(() => {
    if (projectId) {
      fetchProject(Number(projectId));
    }
  }, [projectId]);

  return (
    <Container fluid>
      <Row className="mt-4">
        <Col>
          <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2}>
            <Stack alignItems="center" direction="row" spacing={2}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack htmlColor="black" />
              </IconButton>
              <Typography variant="h2">{data.name}</Typography>
            </Stack>
            {canEdit && (
              <IconButton
                aria-label="edit"
                onClick={() => toggleEdit()}
                sx={{ visibility: 'hidden', borderRadius: 'initial', backgroundColor: '#EFF1F6' }}
              >
                <Typography>Edit</Typography>
              </IconButton>
            )}
          </Stack>
        </Col>
      </Row>

      <Row>
        <Col className="mt-4 d-flex align-items-stretch" lg={6} sm={12} xl={7} xs={12}>
          <Container className="p-0" fluid>
            <Row>
              <Col xs={12}>
                <Awards data={data} fetchProject={fetchProject} />
              </Col>
              <Col xs={12}>
                <FundingSources data={data} fetchProject={fetchProject} />
              </Col>
            </Row>
          </Container>
        </Col>

        <Col className="mt-4 d-flex align-items-stretch" lg={6} sm={12} xl={5} xs={12}>
          <FundingSummary data={data} />
        </Col>
      </Row>

      {isEditOpen && !currentUser?.isMillenniumAnalyst && (
        <EditProjectModal
          data={data}
          refetchProjects={() => fetchProject(Number(projectId))}
          toggle={toggleEdit}
        />
      )}
    </Container>
  );
}

export default ProjectDetails;
