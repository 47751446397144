import { Box, Stack, Typography } from '@mui/material';
import { toArray } from 'utils/utilFunctions';
import useSearchParams from 'hooks/useSearchParams';

const allowedUserFilters = {
  u: 'Name',
  e: 'Email',
  n: 'Phone',
  t: 'Toggle',
} as const;

export default function UsedUserFiltersDescriptionBlock(): JSX.Element {
  const { searchParams } = useSearchParams();

  const usedFilters: { key: keyof typeof allowedUserFilters; values: string }[] = [];

  Object.entries(allowedUserFilters).forEach(([key, label]) => {
    const filterValues = toArray(searchParams[key]);

    if (filterValues.length > 0) {
      let displayValue = filterValues.join(', ');
      if (key === 't') {
        const targetValue = filterValues[0];
        if (targetValue === 'all') {
          displayValue = 'All Users';
        } else if (targetValue === 'clients') {
          displayValue = 'Only for [my client]';
        } else if (targetValue === 'millennium') {
          displayValue = 'Only Millennium Users';
        }
      }

      usedFilters.push({ key: key as keyof typeof allowedUserFilters, values: displayValue });
    }
  });

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" gap={3} mb={2} mt={2}>
      {usedFilters.map((filter) => (
        <Stack key={filter.key} alignItems="center" direction="row" gap={2}>
          <Typography m={0} variant="body1">{`${allowedUserFilters[filter.key]}:`}</Typography>
          <Typography sx={{ color: 'grey' }} variant="body1">
            {filter.values}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
}
