import { useCallback, useEffect, useMemo, useState } from 'react';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import { searchApplications } from 'services/applicationService';
import { searchPrograms } from 'services/programService';

interface Filter {
  name: string;
}

interface ProgramNamesFilterProps {
  moduleId: number;
  filters: Filter[];
  onChange: (newValue: Filter[]) => void;
  width?: string;
}

// Debounce function to limit API calls
const debounce = <R, F extends (...args: string[]) => Promise<R>>(func: F, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: Parameters<F>): Promise<R> => {
    return new Promise((resolve) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => resolve(func(...args)), delay);
    });
  };
};

export default function ProgramNamesFilter({
  moduleId = 2,
  filters,
  onChange,
  width,
}: ProgramNamesFilterProps) {
  const [currentFilters, setCurrentFilters] = useState<string[]>([]);

  useEffect(() => {
    const newFilters = Array.isArray(filters) ? filters.map((f) => f.name) : [];
    if (JSON.stringify(newFilters) !== JSON.stringify(currentFilters)) {
      setCurrentFilters(newFilters);
    }
  }, [filters]);

  const handleChange = useCallback(
    (values: string[]) => {
      if (JSON.stringify(values) !== JSON.stringify(currentFilters)) {
        onChange(values?.map((name) => ({ name })));
      }
    },
    [onChange, currentFilters]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(async (query: string) => {
      if (!query) {
        return [];
      }

      try {
        const rows =
          moduleId === 2
            ? await searchApplications(query, 'name')
            : await searchPrograms(query, 'name');
        const names = rows?.map((row) => row.name) || [];
        return names;
      } catch (error) {
        console.error('Error during search:', error);
        return [];
      }
    }, 300);
  }, [moduleId]);

  const handleSearch = useCallback(
    async (query: string) => {
      const results = await debouncedSearch(query);
      return results;
    },
    [debouncedSearch]
  );

  return (
    <SearchFilter<string>
      getLabel={(f) => f}
      label="Program"
      onChange={handleChange}
      onSearch={handleSearch}
      selected={currentFilters}
      variant="filter"
      width={width}
    />
  );
}
