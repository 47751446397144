import SearchFilter from 'shared/muiComponents/SearchFilter';
import { toArray } from 'utils/utilFunctions';
import useSearchParams from 'hooks/useSearchParams';
import { searchClients } from 'services/clientService';

export default function ClientFilter({ width = '100%' }) {
  const { searchParams, setSearchParams } = useSearchParams();

  return (
    <SearchFilter<string>
      getLabel={(f) => f}
      label="Client"
      onChange={(values) => setSearchParams({ page: 1, clients: values })}
      searchFunction={async (input: string) =>
        (await searchClients(input, 'name')).map((p) => p.name)
      }
      selected={toArray(searchParams.clients)}
      variant="filter"
      width={width}
    />
  );
}
