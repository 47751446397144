import SearchFilter from 'shared/muiComponents/SearchFilter';
import { searchFunders } from 'services/funderService';
import { toArray } from 'utils/utilFunctions';
import useSearchParams from 'hooks/useSearchParams';

export default function FunderFilter({ width = '100%' }) {
  const { searchParams, setSearchParams } = useSearchParams();

  return (
    <SearchFilter<string>
      getLabel={(f) => f}
      label="Funder"
      onChange={(values) => setSearchParams({ page: 1, funders: values })}
      searchFunction={async (input: string) =>
        (await searchFunders(`field=name&source=awards&query=${input}`)).map((f) => f.funder)
      }
      selected={toArray(searchParams.funders)}
      variant="filter"
      width={width}
    />
  );
}
