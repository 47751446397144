import { AxiosResponse } from 'axios';

import { AwardUserRole } from 'types/awards';

import api, { outputError } from '../apiService';

export interface AwardRolesList {
  rows: AwardUserRole[];
  count: number;
}

interface CreatedRole {
  id: number;
  userId: number;
}

export const getAwardRoles = (
  awardId: string,
  searchParams: string
): Promise<AwardRolesList | false> =>
  api
    .get(`/awards/${awardId}/user-roles?${searchParams}`)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const createAwardRole = (
  awardId: string,
  payload: Partial<AwardUserRole>
): Promise<CreatedRole | false> =>
  api
    .post(`/awards/${awardId}/user-roles`, payload)
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return false;
    })
    .catch((error) => {
      outputError(error);
      return false;
    });

export const updateAwardRole = (
  awardId: string,
  roleId: number,
  payload: Partial<AwardUserRole>
): Promise<boolean> =>
  api
    .put(`/awards/${awardId}/user-roles/${roleId}`, payload)
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });

export const deleteAwardRole = (awardId: string, roleId: number): Promise<boolean> =>
  api
    .delete(`/awards/${awardId}/user-roles/${roleId}`, { id: roleId })
    .then((response: AxiosResponse) => response.status < 303)
    .catch((error) => {
      outputError(error);
      return false;
    });
