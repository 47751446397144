import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { X } from 'react-feather';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TokenizedInput, ClientInput, AssigneeInput } from 'shared/inputs';

export default function ApplicationCreationForm({ form, index = -1, onChange, onDelete }) {
  const { id, errors } = form;

  return (
    <Card>
      <Card.Header as="h5" className="d-flex justify-content-between align-items-center">
        <div>Application #{index}</div>
        <Button
          onClick={() => onDelete(id)}
          style={{ margin: '0 0 0 0', padding: '5px 10px 0 10px' }}
          variant="danger"
        >
          <X size={16} />
        </Button>
      </Card.Header>
      <Card.Body>
        <Container className="create-app-inputs" fluid>
          <Row>
            <Col lg={6} xs={12}>
              <AssigneeInput
                controlId={`createApplications${id}.Assignee`}
                errors={errors.assignee}
                onChange={(newValue) => onChange({ ...form, assignee: newValue })}
              />
            </Col>

            <Col lg={6} xs={12}>
              <ClientInput
                controlId={`createApplications${id}.Client`}
                errors={errors.client}
                onChange={(newValue) => onChange({ ...form, client: newValue })}
              />
            </Col>

            <Col xs={12}>
              <TokenizedInput
                controlId={`createApplications${id}.Emails`}
                errors={errors.emails}
                label="Emails"
                onChange={(newValue) => onChange({ ...form, emails: newValue })}
                page="createApp"
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
