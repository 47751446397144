import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Stack, Typography } from '@mui/material';

import { StringsContext } from 'index';
import { updateProject } from 'services/awards/awardsService';

import { Project } from 'types/awards';
import { tossError, tossSuccess } from 'utils/toastTosser';

import SelectField from 'shared/muiComponents/SelectField';
import InputField from 'shared/muiComponents/InputField';
import CurrencyInputField from 'shared/muiComponents/CurrencyInputField';
import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import StandardButton from 'shared/muiComponents/StandardButton';

interface EditProjectModalProps {
  data: Project;
  toggle: () => void;
  refetchProjects: () => void;
}

export default function EditProjectModal({ data, toggle, refetchProjects }: EditProjectModalProps) {
  const [category, setCategory] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [budget, setBudget] = useState<number>(data.budget || 0);
  const strings = useContext(StringsContext);
  const { categories } = strings.projects;

  useEffect(() => {
    if (data) {
      setCategory(data.category);
      setName(data.name);
      setBudget(data.budget);
    }
  }, [data, data.budget]);

  const onSubmit = async () => {
    const result = await updateProject({
      id: data.id,
      name,
      budget,
      category,
      clientId: data.clientId,
    });
    if (result) {
      tossSuccess('The project was successfully updated.');
      refetchProjects();
      toggle();
    } else tossError('Error updating the project.');
  };

  return (
    <Modal centered className="awards-modal" onHide={toggle} show>
      <Modal.Body>
        <Stack direction="column" px={2} py={2} spacing={4}>
          <Stack direction="column" spacing={4}>
            <Typography m={2} variant="h2">
              Edit
            </Typography>

            <InputField
              id="project-name"
              label="Project Name"
              onChange={setName}
              required
              value={name}
            />

            <CurrencyInputField
              id="projectBudget"
              label="Total Project Budget"
              onSave={(v) => setBudget(parseFloat(v.toString().replace(/[^0-9.]/g, '')))}
              value={budget}
            />

            <SelectField
              handleChange={setCategory}
              id="category"
              label="Select the category"
              options={categories}
              required
              value={category}
            />
          </Stack>

          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2}>
            <SecondaryButton onClick={toggle}>Cancel</SecondaryButton>
            <StandardButton disabled={!name || !category} onClick={onSubmit}>
              Confirm
            </StandardButton>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
