import { ReactNode } from 'react';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

export default function SecondaryButton({
  onClick,
  children,
  disabled = false,
  startIcon = false,
}: {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
  startIcon?: boolean;
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      startIcon={startIcon ? <Add /> : ''}
      sx={{
        background: (theme) => theme.palette.secondary.light,
        color: (theme) => theme.palette.primary.light,
        boxShadow: 'none',
        borderRadius: 2,
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '0.8rem',
        lineHeight: 1,
        minHeight: 32,
        '&:hover': {
          background: (theme) => theme.palette.secondary.main,
        },
      }}
      variant="contained"
    >
      {children}
    </Button>
  );
}
