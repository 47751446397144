import Form from 'react-bootstrap/Form';

interface TextareaInputProps {
  id?: string;
  controlId?: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  defaultValue?: string | number;
  errors?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  text?: string;
  value?: string;
}

export default function TextareaInput({
  id,
  controlId,
  required = false,
  label,
  placeholder,
  defaultValue,
  errors,
  disabled = false,
  onChange,
  text,
  value,
}: TextareaInputProps) {
  return (
    <Form.Group controlId={id || controlId}>
      <Form.Label>
        {label}
        {required ? ' *' : ''}
      </Form.Label>

      <Form.Control
        as="textarea"
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        isInvalid={!!errors}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
        placeholder={placeholder}
        required={required}
        rows={5}
        value={value}
      />

      {errors ? (
        <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
      ) : (
        <Form.Text>{text}</Form.Text>
      )}
    </Form.Group>
  );
}
