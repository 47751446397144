import { Row, Col, Container } from 'reactstrap';

function Breadcrumbs({
  className,
  title,
  description,
  styles,
}: {
  className?: string;
  title: string;
  description?: string;
  styles?: React.CSSProperties;
}) {
  return (
    <Container className={className} fluid style={styles}>
      <div className="page-header">
        <Row>
          <Col>
            <h3>{title}</h3>
          </Col>
        </Row>
        {description && <div className="mt-3">{description}</div>}
      </div>
    </Container>
  );
}

export default Breadcrumbs;
