import { AxiosResponse } from 'axios';

import { IAwardAmountsData, IOverviewData, ISubmissionData } from 'pages/Reports';
import api, { outputError } from './apiService';

export interface ReportEntry {
  submitted: number;
  awarded: number;
  rejected: number;
  submissionsValue: number;
  awardsValue: number;
  averageAward: number;
  successRate: number;
}

export interface ReportsData {
  bySource: ReportEntry[];
  byCategory: ReportEntry[];
  byCategoryAndSource: ReportEntry[][];
}

interface downloadReportsProps {
  currentUser?: string;
  overviewData: string[][];
  totalByCategory: string[][];
  totalByCategoryAndSource: string[][];
  submissionsValue: number;
  appsApplied: number;
  averageAmount: number;
  fundingAwarded: number;
  appsAwarded: number;
  averageAward: number;
  bySourceData: ReportEntry[];
  byCategoryData: ReportEntry[];
  sources: string[];
  categories: string[];
  overviewTable: IOverviewData[];
  categoryTable: ISubmissionData[];
  awardTable: IAwardAmountsData[];
}

export const getReports = (filterQuery: string): Promise<ReportsData> =>
  api
    .get(`/reports?${filterQuery}`)
    .then((response: AxiosResponse<ReportsData>) => {
      if (response.status < 303) return response.data;
      return {} as ReportsData;
    })
    .catch((error) => {
      outputError(error);
      return {} as ReportsData;
    });

export const downloadReportsDOCX = (data: downloadReportsProps) =>
  api
    .post(`/reports/docx`, data, { responseType: 'arraybuffer' })
    .then((response: AxiosResponse) => {
      if (response.status < 303) return response.data;
      return {};
    })
    .catch((error) => {
      outputError(error);
      return {};
    });
