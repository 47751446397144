import Card from 'react-bootstrap/Card';
import BootstrapTable from 'react-bootstrap-table-next';
import { IconButton, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useContext } from 'react';

import { formatCurrencyValue } from 'utils/utilFunctions';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { UserSessionContext } from 'index';

import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import TooltipWithAnchor from 'shared/muiComponents/TooltipWithAnchor';

import { Project, ProjectAwards } from 'types/awards';
import { updateProject } from 'services/awards/awardsService';
import AddAwardsModal from '../modals/AddAwardsModal';

interface AwardsTableProps {
  data: Project;
  fetchProject: (id: number) => void;
}

function Awards({ data, fetchProject }: AwardsTableProps) {
  const [isAddAwardsOpen, setIsAddAwardsOpen] = useState(false);
  const [hoveredToolTipIcon, setHoveredToolTipIcon] = useState<HTMLElement | null>(null);

  const toggleAward = () => setIsAddAwardsOpen((prevState) => !prevState);

  const remove = async (projectId: number) => {
    const filteredAwards = data.awards.filter((award) => award.id !== projectId).map((e) => e.id);

    const result = await updateProject({
      id: data.id,
      name: data.name,
      category: data.category,
      sources: data.sources,
      assignedAwards: filteredAwards,
    });

    if (result) {
      tossSuccess('The Award was successfully deleted.');
      fetchProject(data.id);
    } else tossError('Error deleting the Award.');
  };

  function awardsValueFormatter(cell: number) {
    return formatCurrencyValue(cell);
  }

  const currentUser = useContext(UserSessionContext);

  const canEdit =
    !currentUser?.isMillenniumAnalyst && currentUser?.userType !== 'millenniumResearcher';

  // Delete btn
  function actionsFormatter(_cell: string, row: ProjectAwards) {
    return (
      <Stack direction="row">
        <IconButton
          aria-label="delete"
          className="table-hover-btn"
          onClick={(e) => {
            e.stopPropagation();
            remove(row.id);
          }}
          onMouseOut={() => setHoveredToolTipIcon(null)}
          onMouseOver={(event) => setHoveredToolTipIcon(event.currentTarget)}
          sx={{ visibility: 'hidden', borderRadius: 'initial', backgroundColor: '#EFF1F6' }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  }

  const columns = [
    {
      dataField: 'stringId',
      text: 'Award ID',
    },
    {
      dataField: 'funder',
      text: 'Funder',
    },
    {
      dataField: 'grantProgramName',
      text: 'Grant Program',
    },
    {
      dataField: 'department',
      text: 'Department',
    },
    {
      dataField: 'awardAmount',
      text: 'Amount',
      formatter: awardsValueFormatter,
    },
    {
      dataField: 'actions',
      text: '',
      isDummyField: true,
      formatter: actionsFormatter,
    },
  ];

  return (
    <>
      <Card className="w-100 py-3">
        <Card.Header className="details-card-header">
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography color="primary.main" variant="h2">
              Millennium Awards
            </Typography>
            {canEdit && (
              <SecondaryButton onClick={toggleAward} startIcon>
                Add Award
              </SecondaryButton>
            )}
          </Stack>
        </Card.Header>

        <Card.Body>
          <BootstrapTable
            bootstrap4
            bordered={false}
            columns={columns}
            data={data.awards || []}
            keyField="stringId"
            remote={{
              filter: true,
            }}
            wrapperClasses="table-responsive table-borderless"
          />
        </Card.Body>
      </Card>

      {isAddAwardsOpen && (
        <AddAwardsModal data={data} fetchProject={fetchProject} toggle={toggleAward} />
      )}

      <TooltipWithAnchor
        anchorElement={hoveredToolTipIcon}
        isOpen={Boolean(hoveredToolTipIcon)}
        text="Remove this award from the project"
      />
    </>
  );
}

export default Awards;
