import { ChangeEvent, useEffect, useState } from 'react';
import { InputLabel, Stack, TextField } from '@mui/material';

interface InputFieldProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  label?: string;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

export default function InputField({
  value,
  onChange,
  label = '',
  required = false,
  placeholder = '',
  id = '',
  disabled = false,
  readOnly = false,
}: InputFieldProps) {
  const [inputValue, setInputValue] = useState(value || '');

  // useEffect(() => {
  //   // This effect will run whenever the `values` prop changes
  //   // You can add any additional logic here if needed
  // }, [value]);

  console.log('InputField rendered', value);

  useEffect(() => {
    return setInputValue(value || '');
  }, [value]);

  return (
    <Stack spacing={1}>
      {label && (
        <InputLabel htmlFor={id} required={required}>
          {label}
        </InputLabel>
      )}
      <TextField
        autoComplete="off"
        // defaultValue={inputValue}
        disabled={disabled || readOnly}
        id={id}
        InputProps={{
          readOnly,
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
        placeholder={placeholder}
        sx={{
          '& .MuiOutlinedInput-input': {
            padding: '8px 12px',
            // height: '40px',
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: (theme) => theme.palette.primary.light,
            },
            '&.Mui-focused fieldset': {
              borderColor: (theme) => theme.palette.primary.light,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.secondary.contrastText,
          },
          ...(readOnly && {
            '& .MuiInputBase-root': {
              minHeight: '40px',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              color: '#2c4474',
            },
          }),
          ...(!readOnly && {
            '& .MuiInputBase-root': {
              minHeight: '40px',
            },
          }),
        }}
        value={inputValue}
      />
    </Stack>
  );
}
