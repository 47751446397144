import { useEffect, useState } from 'react';
import FilterSelect from 'shared/muiComponents/FilterSelect';
import { applicationDates } from '../../constants/globalConstants';

interface DateTypeFilterProps {
  dateType?: string | null;
  onChange: (value: string) => void;
  width?: string;
}

export default function DateTypeFilter({ dateType, onChange, width }: DateTypeFilterProps) {
  const [currentDateType, setCurrentDateType] = useState(dateType || '');

  useEffect(() => {
    setCurrentDateType(dateType || '');
  }, [dateType]);

  const handleChange = (value?: string) => {
    const newValue = value || '';
    setCurrentDateType(newValue);
    onChange(newValue);
  };

  const getValueLabel = (value?: string) => {
    if (!value) return 'Select Date Type';
    return applicationDates.find(({ name }) => name === value)?.name || '';
  };

  const options = applicationDates.map(({ name }) => name);

  return (
    <FilterSelect
      getValueLabel={getValueLabel}
      label="Date Type"
      onChange={handleChange}
      options={options}
      selected={currentDateType}
      width={width}
    />
  );
}
