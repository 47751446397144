import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import { OverlayDelay } from 'react-bootstrap/OverlayTrigger';

interface RenderTooltipProps {
  tooltipData?: string[];
  choices?: string[];
  placement?: Placement;
  delay: OverlayDelay;
  children?: JSX.Element;
}

export default function RenderTooltip(props: RenderTooltipProps) {
  const { tooltipData, choices, placement, delay, children } = props;

  const renderTip = (rest) => (
    <Tooltip stylename="tooltip" {...rest}>
      <span>Entries:</span>
      <ul>
        {tooltipData?.map((tip, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`tip-${index}`} className="render-custom-tooltip__list-item">
            {choices ? choices[tip] : tip}
          </li>
        ))}
      </ul>
    </Tooltip>
  );

  return tooltipData && tooltipData.length > 1 ? (
    <OverlayTrigger delay={delay} overlay={renderTip} placement={placement}>
      <div className="render-custom-tooltip">{children}</div>
    </OverlayTrigger>
  ) : (
    children
  );
}
