/* eslint-disable react/no-unescaped-entities, no-nested-ternary */
import { useState } from 'react';
import { FormGroup, Input, Label, Button } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { tossError, tossSuccess } from 'utils/toastTosser';
import { changePassword } from 'services/sessionService';
import { Eye, EyeOff } from 'react-feather';
import 'assets/scss/resetPassword.scss';

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const { tokenData } = useParams();
  const [newPass, setNewPass] = useState('');
  const [verPass, setVerPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [showPass, setShowPass] = useState(false);
  const [showVerPass, setShowVerPass] = useState(false);

  const togglePasswordVisibility = () => setShowPass(!showPass);
  const toggleVerPasswordVisibility = () => setShowVerPass(!showVerPass);

  const passChangeRequest = async () => {
    if (newPass !== verPass) {
      tossError('Passwords do not match.');
      return;
    }
    if (newPass.length < 7) {
      tossError('Password must be at least 7 characters long.');
      return;
    }
    setLoading(true);
    try {
      const result = await changePassword({ tokenString: tokenData!, newPassword: newPass });
      if (result) {
        tossSuccess('Password successfully changed!');
        setStep(2);
      } else {
        tossError('Error changing your password.');
      }
    } catch (error) {
      tossError('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="custom-auth-form-reset">
      <div className="custom-header-reset">
        {step === 1 ? (
          <>
            <h4 className="custom-title">Set Your New Password</h4>
            <h6 className="custom-subtitle">Enter your new password for logging in</h6>
          </>
        ) : (
          <>
            <h4 className="custom-title">Great! Password reset!</h4>
            <h6 className="custom-subtitle">
              You can now go back to the login screen and <br /> enter your new password to log into{' '}
              <br />
              the dashboard.
            </h6>
          </>
        )}
      </div>

      {step === 1 && (
        <>
          <FormGroup className="custom-form-group-reset password-group-reset">
            <Label className="custom-label">Your New Password</Label>
            <div className="password-input-container">
              <Input
                className="form-control custom-input"
                type={showPass ? 'text' : 'password'}
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
                required
                placeholder="Enter your new password"
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    togglePasswordVisibility();
                  }
                }}
              >
                {showPass ? <EyeOff /> : <Eye />}
              </span>
            </div>
          </FormGroup>

          <FormGroup className="custom-form-group-reset password-group-reset">
            <Label className="custom-label">Type Again Your New Password</Label>
            <div className="password-input-container">
              <Input
                className="form-control custom-input"
                type={showVerPass ? 'text' : 'password'}
                value={verPass}
                onChange={(e) => setVerPass(e.target.value)}
                required
                placeholder="Re-enter your new password"
              />
              <span
                className="password-toggle-icon"
                onClick={toggleVerPasswordVisibility}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    toggleVerPasswordVisibility();
                  }
                }}
              >
                {showVerPass ? <EyeOff /> : <Eye />}
              </span>
            </div>
          </FormGroup>
        </>
      )}

      <FormGroup className="custom-button-group-reset">
        <Button
          className="custom-login-button"
          color="primary"
          disabled={loading || (step === 1 && (!newPass || !verPass))}
          onClick={step === 1 ? passChangeRequest : () => navigate('/login')}
        >
          {loading ? 'Processing...' : step === 1 ? 'Set New Password' : 'Login'}
        </Button>
      </FormGroup>
    </div>
  );
}
