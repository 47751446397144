import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Check, Edit as EditIcon, X } from 'react-feather';
import { ClientContact } from '../../types/client';
import useKeyPress from '../../hooks/useKeyPress';
import { deleteClientContact, updateClientContact } from '../../services/clientContactsService';
import { tossError, tossSuccess } from '../../utils/toastTosser';
import { TextInput } from '../../shared/inputs';

interface EditClientContactsProps {
  contact: ClientContact;
  onSave?: () => void;
}

export interface ContactFields {
  name?: string;
  email?: string;
  phone?: string;
  title?: string;
}

export default function EditClientContacts({ contact, onSave }: EditClientContactsProps) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<ContactFields>({});
  const [errors, setErrors] = useState<Partial<Record<keyof ContactFields, string>>>();
  const enterPress = useKeyPress('Enter');
  const escPress = useKeyPress('Esc');

  const setField = <K extends keyof ContactFields>(field: K, value: ContactFields[K]) =>
    setForm((prevState) => ({ ...prevState, [field]: value }));

  const toggle = () => setOpen((prevState) => !prevState);

  const remove = async () => {
    const result = await deleteClientContact(contact.id);
    if (result) {
      tossSuccess('The contact had been deleted successfully.');
      onSave?.();
    } else tossError("Error deleting a client's contact.");
  };

  const save = async () => {
    const { name, email, phone, title } = form;

    const submittedFields = {
      id: contact.id,
      name,
      email,
      phone,
      title,
    };

    const result = await updateClientContact(submittedFields);
    if (result) {
      tossSuccess('The contact had been updated successfully.');
      toggle();
      onSave?.();
    } else tossError("Error updating a client's contact.");
  };

  useEffect(() => {
    const { name, email, phone } = form;

    const errorList: Partial<Record<keyof ContactFields, string>> = {};

    if (typeof name !== 'undefined') {
      if (name === '') errorList.name = 'Name is required.';
      else if (name.length < 2)
        errorList.name = 'Names with less than 2 characters are not allowed.';
    }

    if (email) {
      if (
        email.match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
        )?.[0] !== email
      )
        errorList.email = 'Invalid email format.';
    }

    if (phone) {
      if (
        /^\+?(\d{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})$/.test(
          phone
        ) === false
      )
        errorList.phone = 'Invalid phone format.';
    }

    setErrors(errorList);
  }, [form]);

  useEffect(() => {
    if (!enterPress && open) {
      if (errors && Object.keys(errors).length === 0) save();
    }
  }, [enterPress]);

  useEffect(() => {
    if (!escPress && open) {
      toggle();
    }
  }, [escPress]);

  return (
    <>
      <Button
        className="d-flex justify-content-center align-items-center"
        onClick={toggle}
        variant="secondary-blue"
      >
        <EditIcon size={16} style={{ marginBottom: '2px' }} />
        &nbsp;Edit
      </Button>
      &nbsp;&nbsp;
      <Button
        className="d-flex justify-content-center align-items-center"
        color="primary-red"
        onClick={remove}
        variant="primary-red"
      >
        <X size={16} style={{ marginBottom: '1px' }} />
        &nbsp;Delete
      </Button>
      <Modal centered onHide={toggle} show={open}>
        <Modal.Header closeButton>Edit Client Contact</Modal.Header>
        <Modal.Body>
          <Form>
            <Container fluid>
              <Row>
                <Col md={6} xs={12}>
                  <TextInput
                    controlId="editContact.Name"
                    defaultValue={contact.name}
                    errors={errors?.name}
                    label="Name"
                    onChange={(newValue: string) => setField('name', newValue)}
                    placeholder="John Doe"
                    required
                  />
                </Col>
                <Col md={6} xs={12}>
                  <TextInput
                    controlId="editContact.Email"
                    defaultValue={contact.email}
                    errors={errors?.email}
                    label="Email"
                    onChange={(newValue: string) => setField('email', newValue)}
                    placeholder="example@email.com"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <TextInput
                    controlId="editContact.Phone"
                    defaultValue={contact.phoneNumber}
                    errors={errors?.phone}
                    label="Phone Number"
                    onChange={(newValue: string) => setField('phone', newValue)}
                    placeholder="+1 (XXX) XXX-XXXX"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <TextInput
                    controlId="editContact.Title"
                    defaultValue={contact.title}
                    errors={errors?.title}
                    label="Title"
                    onChange={(newValue: string) => setField('title', newValue)}
                    placeholder="Manager"
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="d-flex justify-content-center align-items-center"
            disabled={errors && Object.keys(errors).length > 0}
            onClick={save}
            variant="save"
          >
            <Check size={16} />
            &nbsp;Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
