import StatusChip from 'shared/muiComponents/StatusChip';

const statusBadges = {
  applicationRequired: { label: 'Application', color: '#3C5D9C', background: '#EDFAFD' },
  awardLetterRequired: { label: 'Award Letter', color: '#3C5D9C', background: '#EDFAFD' },
  resolutionRequired: { label: 'Resolution', color: '#3C5D9C', background: '#EDFAFD' },
  initialAgreementRequired: { label: 'Agreement', color: '#3C5D9C', background: '#EDFAFD' },
  reportScheduleRequired: { label: 'Appropriated', color: '#3C5D9C', background: '#EDFAFD' },
  budgetRequired: { label: 'Appropriated', color: '#3C5D9C', background: '#EDFAFD' },
  contractMaterialRequired: { label: 'Appropriated', color: '#3C5D9C', background: '#EDFAFD' },
  encumbered: { label: 'Encumbered', color: '#3C5D9C', background: '#EDFAFD' },
  approval: { label: 'Approval Required', color: '#3C5D9C', background: '#EDFAFD' },
  active: { label: 'Active', color: '#855D83', background: '#F7F3F6' },
  reportsRequired: { label: 'Report Due', color: '#807719', background: '#FCFAEE' },
  complianceRequired: { label: 'Compliance', color: '#ED6402', background: '#FFF3EB' },
  closeout: { label: 'Close Out', color: '#31816C', background: '#F2F6F3' },
  closed: { label: 'Completed', color: '#31816C', background: '#F2F6F3' },
};

type Status = keyof typeof statusBadges;

export default function AwardStatusChip({
  status,
  height = '100%',
}: {
  status: string;
  height?: string;
}) {
  if (status in statusBadges)
    return (
      <StatusChip
        background={statusBadges[status as Status].background}
        color={statusBadges[status as Status].color}
        height={height}
        label={statusBadges[status as Status].label}
      />
    );

  return <StatusChip height={height} label={status} />;
}
