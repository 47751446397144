import { Modal } from 'react-bootstrap';
import { Box, Stack, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import { format } from 'date-fns';

import SecondaryButton from 'shared/muiComponents/SecondaryButton';
import { Notifications } from 'types/awards';

interface NotesModalProps {
  toggle: () => void;
  data: Notifications[];
}

export default function NotesModal(props: NotesModalProps) {
  const { toggle, data } = props;

  const uniqueAndSortedData = data.reverse().filter((note, index, self) => {
    return index === self.findIndex((t) => t.subject === note.subject);
  });

  return (
    <Modal centered className="award-notification-modal" onHide={toggle} show>
      <Modal.Body>
        <Typography m={2} variant="h2">
          Latest Notes
        </Typography>

        <Box
          py={2}
          sx={{
            height: '400px',
            overflowY: 'auto',
          }}
        >
          {uniqueAndSortedData.map((note) => (
            <Stack
              key={nanoid()}
              direction="row"
              justifyContent="space-between"
              spacing={2}
              style={{ padding: '0 16px', marginBottom: '16px' }}
            >
              <Typography fontStyle="italic" variant="body1">
                {note.subject}
              </Typography>

              <Typography
                color="primary.main"
                fontWeight="bold"
                textAlign="right"
                variant="caption"
                width="150px"
              >
                {(note.createdAt && format(new Date(note.createdAt), 'MMMM do yyyy hh:mm')) || ''}
              </Typography>
            </Stack>
          ))}
        </Box>

        <Stack alignItems="center" direction="row" justifyContent="end" mt={3} spacing={2}>
          <SecondaryButton onClick={toggle}>Close</SecondaryButton>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
