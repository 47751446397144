import { useEffect, useState } from 'react';
import FilterSelect from 'shared/muiComponents/FilterSelect';
import { states } from '../../constants/globalConstants';

interface StateFilterProps {
  state?: string | null;
  onChange: (value: string | null) => void;
  width?: string;
}

export default function StateFilter({ state, onChange, width }: StateFilterProps) {
  const [currentState, setCurrentState] = useState(state || 'all');

  useEffect(() => {
    setCurrentState(state || 'all');
  }, [state]);

  const handleChange = (value?: string) => {
    const newValue = value || 'all';
    setCurrentState(newValue);
    onChange(newValue);
  };

  const getValueLabel = (value?: string) => {
    if (!value || value === 'all') return 'All States';
    return states.find((s) => s.abbr === value)?.name || '';
  };

  const options = ['all', ...states.map((s) => s.abbr)];

  return (
    <FilterSelect
      getValueLabel={getValueLabel}
      label="State"
      onChange={handleChange}
      options={options}
      selected={currentState}
      width={width}
    />
  );
}
