import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { searchUsers } from 'services/userService';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

interface AssigneeInputProps {
  controlId: string;
  disabled?: boolean;
  errors?: string;
  text: unknown;
  defaultValue: unknown;
  placeholder: unknown;
  onChange: unknown;
  multipleEdit: unknown;
  className?: string;
}

export default function AssigneeInput(props: AssigneeInputProps) {
  const {
    controlId,
    disabled = false,
    errors,
    text,
    defaultValue,
    placeholder,
    onChange,
    multipleEdit,
    className,
  } = props;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(
    defaultValue && !multipleEdit ? [{ id: defaultValue.id, name: defaultValue.name }] : []
  );
  const [options, setOptions] = useState([]);

  const search = async (query) => {
    setLoading(true);

    try {
      const foundUsers = await searchUsers(query, 'name', false, false);

      const mappedUsers = foundUsers.map((e) => ({
        id: e.id,
        name: e.name,
      }));

      setOptions(mappedUsers);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const onInputChange = (newValue) => {
    setSelected(newValue.length > 0 ? [newValue.pop()] : []);
  };

  useEffect(() => {
    // If we have a selected assignee, their id is passed, else nothing is passed.
    onChange?.(selected.length > 0 ? selected[0].id : null);
  }, [selected]);

  useEffect(() => {
    if (multipleEdit)
      setSelected([{ ...selected, id: multipleEdit?.id, name: multipleEdit?.name }]);
  }, []);

  return (
    <Form.Group className={className} controlId={controlId}>
      <Form.Label>Assignee {!disabled && '*'}</Form.Label>
      <AsyncTypeahead
        disabled={disabled}
        id={controlId}
        isInvalid={!!errors}
        isLoading={loading}
        labelKey="name"
        minLength={1}
        multiple
        onChange={onInputChange}
        onSearch={search}
        options={options}
        placeholder={placeholder}
        renderMenuItemChildren={(option) => <span>{option.name}</span>}
        selected={selected}
      />
      {errors ? (
        <div className="validation-errors">{errors}</div>
      ) : (
        <Form.Text muted>{text}</Form.Text>
      )}
    </Form.Group>
  );
}
