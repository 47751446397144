import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';

import { applicationsConfigs } from '../../constants/globalConstants';

interface NotesStatusInputProps {
  defaultValue: unknown;
  disabled: unknown;
  controlId: unknown;
  placeholder: unknown;
  onChange: unknown;
  className?: string;
}

export default function NotesStatusInput({
  defaultValue,
  disabled = false,
  controlId,
  placeholder,
  onChange,
  className,
}: NotesStatusInputProps) {
  const [selected, setSelected] = useState<string[]>(defaultValue ? [defaultValue] : []);

  const onInputChange = (newValue: string[]) => {
    setSelected(newValue.length > 0 ? [newValue.pop()] : []);
  };

  useEffect(() => {
    // If we have a selected funder, their name is passed, else nothing is passed.
    onChange?.(selected.length > 0 ? selected[0].name : null);
  }, [selected]);

  return (
    <Form.Group className={className} controlId={controlId}>
      <Form.Label>Notes / Status</Form.Label>
      <Typeahead
        allowNew
        disabled={disabled}
        id={controlId}
        labelKey="name"
        multiple
        onChange={onInputChange}
        options={applicationsConfigs.notesStatuses.map((e) => ({ name: e }))}
        placeholder={disabled ? null : placeholder}
        renderMenuItemChildren={(option) => <span>{option.name}</span>}
        selected={selected || placeholder}
      />
    </Form.Group>
  );
}
