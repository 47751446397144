import { forwardRef, useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import dayjs, { Dayjs } from 'dayjs';
import { Calendar } from 'react-feather';
import DateHeader from '../DateHeader';

interface DateInputProps {
  controlId: string;
  text: string;
  placeholder: string;
  label: string;
  defaultValue?: string | Dayjs | null;
  disabled?: boolean;
  required?: boolean;
  errors?: string;
  onChange?: (newValue: string | Date | null) => void;
  isShowYear?: boolean;
  className?: string;
  textClass?: string;
}

export default forwardRef<DatePicker<never, undefined>, DateInputProps>(
  (
    {
      controlId,
      text,
      placeholder,
      label,
      defaultValue,
      disabled = false,
      required = false,
      errors,
      onChange,
      isShowYear = false,
      className,
      textClass,
    }: DateInputProps,
    ref
  ) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(
      defaultValue ? dayjs(defaultValue).toDate() : null
    );
    const [isDateSelected, setIsDateSelected] = useState(!!selectedDate);

    const withTimeZoneOffset = useMemo(() => {
      if (selectedDate) return dayjs(selectedDate).toDate();
      return selectedDate;
    }, [selectedDate]);

    useEffect(() => {
      onChange?.(selectedDate);
    }, [selectedDate]);

    useEffect(() => {
      setIsDateSelected(!!selectedDate);
    }, [selectedDate]);

    const format = (date: Date | null): string => {
      if (!date) return 'eee, MMM do - yyyy';

      const checkedDate = dayjs(date);

      if (checkedDate.year() === dayjs().year()) return 'eee, MMM do';

      return 'eee, MMM do - yyyy';
    };

    return (
      <Form.Group className={className} controlId={controlId}>
        <Form.Label>
          {label}
          {required ? ' *' : ''}
        </Form.Label>
        <div style={{ position: 'relative', width: '100%' }}>
          <DatePicker
            ref={ref}
            autoComplete="off"
            className={`form-control digits${errors ? ' is-invalid' : ''}`}
            dateFormat={isShowYear ? 'eee, MMM do - yyyy' : format(selectedDate)}
            disabled={disabled}
            isClearable={!disabled}
            onChange={(date) => {
              setSelectedDate(date);
              setIsDateSelected(!!date);
            }}
            placeholderText={placeholder}
            renderCustomHeader={DateHeader}
            selected={withTimeZoneOffset}
          />
          {!isDateSelected && (
            <div
              style={{
                position: 'absolute',
                top: '55%',
                right: '10px',
                transform: 'translateY(-50%)',
              }}
            >
              <Calendar size={22} />
            </div>
          )}
        </div>
        {Boolean(errors) && <div className="validation-errors">{errors}</div>}
        {!errors && <Form.Text className={textClass}>{text}</Form.Text>}
      </Form.Group>
    );
  }
);
