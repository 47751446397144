import { ElementType, ReactNode } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import useUserSession from 'hooks/useUserSession';

function Tab({ to, children }: { to: string; children: ReactNode }) {
  const location = useLocation();
  return (
    <Button
      component={NavLink as ElementType}
      sx={{
        p: '10px 24px',
        border: (theme) => `2px solid ${theme.palette.secondary.main}`,
        fontWeight: 600,
        fontSize: '0.8rem',
        lineHeight: 1,
        textTransform: 'none',
        '&.active': {
          color: 'white',
          backgroundColor: (theme) => theme.palette.primary.main,
          border: (theme) => `2px solid ${theme.palette.primary.main} !important`,
        },
        '&:hover:not(.active)': {
          border: (theme) => `2px solid ${theme.palette.secondary.main} !important`,
        },
      }}
      to={`/dashboard/awards${to}${location.search}`}
    >
      {children}
    </Button>
  );
}

export default function AwardViewsNavBar() {
  const { userType } = useUserSession();

  return (
    <ButtonGroup component="nav">
      <Tab to="/summary">Summary View</Tab>
      <Tab to="/finance">Finance View</Tab>
      <Tab to="/projects">Project View</Tab>
      {(userType === 'millenniumAdmin' || userType === 'userAdmin') && (
        <Tab to="/userView">User View</Tab>
      )}
    </ButtonGroup>
  );
}
