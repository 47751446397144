import { forwardRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { tossError, tossSuccess } from 'utils/toastTosser';
import { updateFilter } from 'services/filterService';
import { FilterData } from 'types/filter';
import CreateFilterModal from './CreateFilterModal';

interface SaveModalProps {
  open: boolean;
  moduleId: number;
  filters: FilterData;
  onClose: () => void;
  onSave: (filter: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterSet: unknown | any;
  onRevert: () => void;
}

export default forwardRef(
  (
    { open = false, moduleId, onClose, onSave, filterSet, filters, onRevert }: SaveModalProps,
    ref
  ) => {
    const [createOpen, setCreateOpen] = useState(false);

    const createToggle = () => setCreateOpen((prevState) => !prevState);

    const close = () => {
      setCreateOpen(false);
      onClose();
    };

    const onCreate = (newSet: unknown) => {
      onSave(newSet);
      close();
    };

    const save = async () => {
      const filterFields = {
        id: filterSet.id,
        title: filterSet.title,
        moduleId,
        filters: typeof filters === 'string' ? JSON.parse(filters) : filters,
      };

      const result = await updateFilter(filterFields);

      if (result) {
        tossSuccess('A Filter Set updated successfully!');
        onSave({ ...filterSet, filterData: filters });
        close();
      } else tossError('Error updating a Filter Set.');
    };

    const revert = async () => {
      onRevert();
      close();
    };

    return (
      <Modal ref={ref} centered className="bg-filter-set-save-modal" onHide={close} show={open}>
        <Modal.Header closeButton>
          <Modal.Title>Save Filter Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You can either update the currently selected Filter Set or create a new Filter Set with
            your current choices of filters.
          </p>
          <p>What would you like to do?</p>
          <Button className="mr-3" onClick={revert} variant="danger">
            Revert
          </Button>
          <Button className="mr-3" onClick={save} variant="save">
            Update Current
          </Button>
          <Button onClick={createToggle} variant="primary">
            Create New
          </Button>

          <CreateFilterModal
            filters={filters}
            moduleId={moduleId}
            onClose={createToggle}
            onCreate={onCreate}
            open={createOpen}
          />
        </Modal.Body>
      </Modal>
    );
  }
);
