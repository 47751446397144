import { Box, Stack, Typography } from '@mui/material';
import { toArray } from 'utils/utilFunctions';
import useSearchParams from 'hooks/useSearchParams';
import { states } from 'constants/globalConstants';

const allowedClientFilters = {
  n: 'Client',
  s: 'State',
  t: 'Tax ID',
  d: 'UEI Number',
  cn: 'Contact Name',
  ce: 'Contact Email',
  cp: 'Contact Phone',
  ct: 'Contact Title',
  mc: 'Only My Clients',
} as const;

export default function UsedClientFiltersDescriptionBlock(): JSX.Element {
  const { searchParams } = useSearchParams();

  const usedFilters: { key: keyof typeof allowedClientFilters; values: string }[] = [];

  Object.entries(allowedClientFilters).forEach(([key, label]) => {
    const filterValues = toArray(searchParams[key]);

    if (filterValues.length > 0) {
      let displayValue = filterValues.join(', ');
      if (key === 's') {
        const stateValue = filterValues[0];
        if (stateValue === 'all') {
          displayValue = 'All States';
        } else {
          const state = states.find((s) => s.abbr === stateValue);
          displayValue = state ? state.name : stateValue;
        }
      } else if (key === 'mc') {
        displayValue = filterValues[0] === 'true' ? 'Yes' : 'No';
      }

      usedFilters.push({ key: key as keyof typeof allowedClientFilters, values: displayValue });
    }
  });

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" gap={3} mb={2} mt={2}>
      {usedFilters.map((filter) => (
        <Stack key={filter.key} alignItems="center" direction="row" gap={2}>
          <Typography m={0} variant="body1">{`${allowedClientFilters[filter.key]}:`}</Typography>
          <Typography sx={{ color: 'grey' }} variant="body1">
            {filter.values}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
}
