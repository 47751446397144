import { Box } from '@mui/material';
import { StepWithCoordinates } from 'components/awards/AwardsDetails/AwardsDetailsView/Timeline/index';

export default function RightLine({ step }: { step: StepWithCoordinates }) {
  const { lastInRow, rowDirection, stepColor, lastInTotal, firstInRow, nextStepColor } = step;

  const lineColor = rowDirection === 'left-to-right' ? nextStepColor : stepColor;

  const line = {
    position: 'absolute',
    height: '4px',
    width: '50%',
  };

  if (lastInTotal && rowDirection === 'left-to-right')
    return (
      <Box
        sx={{
          ...line,
          borderTop: '4px solid transparent',
          right: 0,
        }}
      />
    );

  if (firstInRow && rowDirection === 'right-to-left')
    return (
      <Box
        sx={{
          ...line,
          width: 'calc(50% - 20px)',
          height: 'calc(50% - 4px)',
          borderBottom: `4px solid ${lineColor}`,
          borderBottomRightRadius: '40px',
          borderRight: `4px solid ${lineColor}`,
          right: '20px',
          bottom: 'calc(50% - 2px)',
        }}
      />
    );

  if (lastInRow && rowDirection === 'left-to-right')
    return (
      <Box
        sx={{
          ...line,
          width: 'calc(50% - 20px)',
          height: 'calc(50% - 4px)',
          borderTop: `4px solid ${lineColor}`,
          borderTopRightRadius: '40px',
          borderRight: `4px solid ${lineColor}`,
          right: '20px',
          top: 'calc(50% - 2px)',
        }}
      />
    );

  return (
    <Box
      sx={{
        ...line,
        borderTop: `4px solid ${lineColor}`,
        right: 0,
      }}
    />
  );
}
