import { useState, useEffect } from 'react';
import { UserPlus, X, Check } from 'react-feather';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { nanoid } from 'nanoid';

import { tossError, tossSuccess } from 'utils/toastTosser';
import useKeyPress from 'hooks/useKeyPress';
import { TextInput } from 'shared/inputs';

import { inviteUsers } from 'services/userService';
import 'assets/scss/userList.scss';

interface Errors {
  name?: string;
  email?: string;
  phone?: string;
  fields?: string;
}

export interface Invitee {
  id: string;
  name?: string;
  email?: string;
  phone?: string;
  errors: Errors;
}

interface InviteeFormProps {
  invitee: Invitee;
  disabled?: boolean;
  index: number;
  onChange: (updatedInvitee: Invitee) => void;
  onDelete: (id: string) => void;
}

function InviteeForm({
  invitee,
  disabled = false,
  index = -1,
  onChange,
  onDelete,
}: InviteeFormProps) {
  const { id, errors } = invitee;

  return (
    <Card className="inivte-user-card">
      {/* <Stack direction="column" spacing={2} className="inivte-user-card"> */}
      <div className="invite-user-heading-wrapper">
        <div className="invite-heading">User Invite #{index}</div>
        <Button
          disabled={disabled}
          onClick={() => onDelete(id)}
          style={{ margin: '0 0 0 0', padding: '5px 10px 0 10px' }}
          variant="danger"
        >
          <X size={16} />
        </Button>
      </div>
      <Container fluid>
        <Row>
          <Col lg={4} xs={12}>
            <TextInput
              className="common-input"
              controlId={`inviteUser${id}.Name`}
              errors={errors.name}
              label="Name"
              onChange={(value: string) => onChange({ ...invitee, name: value })}
              placeholder="New User"
              required
            />
          </Col>

          <Col lg={4} xs={12}>
            <TextInput
              className="common-input"
              controlId={`inviteUser${id}.Email`}
              errors={errors.email}
              label="Email"
              onChange={(value: string) => onChange({ ...invitee, email: value })}
              placeholder="johndoe@somemail.com"
              required
            />
          </Col>

          <Col lg={4} xs={12}>
            <TextInput
              className="common-input"
              controlId={`inviteUser${id}.Phone`}
              errors={errors.phone}
              label="Phone"
              onChange={(value: string) => onChange({ ...invitee, phone: value })}
              placeholder="+1 (XXX) XXX-XXXX"
            />
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default function InviteUser() {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<Invitee[]>([{ id: nanoid(), errors: {} }]);
  const escPress = useKeyPress('Esc');

  const toggle = () => setOpen((prevState) => !prevState);

  const add = () => {
    setForm([...form, { id: nanoid(), errors: {} }]);
  };

  const updateInvitee = (value: Invitee) => {
    const { id, name, email, phone } = value;
    const updatedInvitees = [...form];

    const updateIndex = updatedInvitees.map((e) => e.id).indexOf(id);

    // Check for errors during the update.
    const errorList = {} as Errors;

    if (typeof name !== 'undefined') {
      if (!name || name === '') errorList.name = 'This field is required.';
      else if (name.length < 3) errorList.name = 'User names must have 3 or more characters.';
    }

    if (phone) {
      if (
        phone.match(
          /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g
        )?.[0] !== phone
      )
        errorList.phone = 'Invalid phone format.';
    }

    if ([typeof name, typeof email].includes('undefined'))
      errorList.fields = 'Required fields are not filled out.';

    if (email) {
      if (
        email.match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
        )?.[0] !== email
      )
        errorList.email = 'Invalid email format.';
    }

    updatedInvitees[updateIndex] = { ...value, errors: errorList };

    setForm(updatedInvitees);
  };

  const removeInvitee = (id: string) => {
    const newInviteesList = form.filter((invitee) => invitee.id !== id);
    setForm(newInviteesList);
  };

  const send = async () => {
    const result = await inviteUsers(form);
    if (result) {
      tossSuccess(`All invitees emailed successfully.`);
      toggle();
    } else tossError(`Error during invitation.`);
  };

  useEffect(() => {
    if (!escPress && open) {
      toggle();
    }
  }, [escPress]);

  return (
    <>
      <Button className="d-flex justify-content-center align-items-center" onClick={toggle}>
        <UserPlus size={16} style={{ marginBottom: '2px' }} />
        &nbsp;Invite New Users
      </Button>

      <Modal centered onHide={toggle} show={open} size="xl">
        <Modal.Header className="invite-modal-heading" closeButton>
          Invite New Users
        </Modal.Header>

        <Modal.Body>
          <div className="overflow-auto form-cards-container invite-modal-body">
            <Form>
              {form.length > 0 ? (
                form.map((invitee, currentIndex) => (
                  <InviteeForm
                    key={invitee.id}
                    index={currentIndex + 1}
                    invitee={invitee}
                    onChange={updateInvitee}
                    onDelete={removeInvitee}
                  />
                ))
              ) : (
                <Form.Text muted>
                  No invitees specified. Use the button to the right to declare one.
                </Form.Text>
              )}
              <div className="text-right">
                <Button className="add-invitee-btn" onClick={add}>
                  <i className="fa fa-plus" /> Add Another Invite
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="d-flex justify-content-center align-items-center"
            onClick={toggle}
            variant="light"
          >
            Cancel
          </Button>
          <Button
            className="d-flex justify-content-center align-items-center"
            disabled={
              form.length === 0 ||
              form
                .filter(({ errors }) => Object.keys(errors).length > 0)
                .map(({ errors }) => errors).length > 0
            }
            onClick={send}
            variant="primary"
          >
            <Check size={16} />
            &nbsp;Send {form.length} Invite{form.length !== 1 ? 's' : ''}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
