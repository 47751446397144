import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

function convertToNumber(sanitizedValue: string) {
  const numberValue = parseFloat(sanitizedValue.replace(/,/g, ''));
  return Number.isNaN(numberValue) ? 0 : numberValue;
}

export default function CurrencyField({
  value,
  onSave,
  disabled = false,
}: {
  value: string;
  onSave: (newValue: number) => void;
  disabled?: boolean;
}) {
  const [currentValue, setCurrentValue] = useState<string>(value);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (value !== currentValue) {
      timeout = setTimeout(() => {
        onSave(convertToNumber(currentValue));
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [currentValue, onSave]);

  return (
    <TextField
      disabled={disabled}
      fullWidth
      InputProps={{
        endAdornment: <InputAdornment position="end">$</InputAdornment>,
      }}
      onBlur={() => onSave(convertToNumber(currentValue))}
      onChange={(event) => setCurrentValue(event.target.value.replace(/[^\d.,-]/g, ''))}
      onKeyDown={(event) => {
        if (event.key === 'Enter') onSave(convertToNumber(currentValue));
      }}
      size="small"
      sx={{
        '& .MuiInputBase-input': {
          textAlign: 'right',
          letterSpacing: '0.8px',
        },
        '& .Mui-disabled': {
          '& input': {
            WebkitTextFillColor: 'black',
          },
          '& fieldset': {
            borderColor: 'transparent',
          },
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-disabled': {
            '& fieldset': {
              border: 'transparent',
            },
          },
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: (theme) => theme.palette.primary.light,
          },
          '&.Mui-focused fieldset': {
            borderColor: (theme) => theme.palette.primary.main,
          },
        },
      }}
      value={currentValue}
    />
  );
}
