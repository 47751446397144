import { Helmet } from 'react-helmet';
import { Outlet, Route } from 'react-router-dom';

import AwardsDetailsView from 'components/awards/AwardsDetails/AwardsDetailsView';
import ReportingPage from 'components/awards/AwardsDetails/AwardsDetailsView/Reporting';

import AssignRolesPage from 'components/awards/AwardsDetails/AwardsDetailsView/AssignRoles';
import Budget from 'components/awards/AwardsDetails/AwardsDetailsView/Budget';

function AwardsDetailsPage() {
  return (
    <>
      <Helmet>
        <title>Grantrack - Awards Details</title>
      </Helmet>
      <Outlet />
    </>
  );
}

export default function awardsDetailsRoutes() {
  return (
    <Route element={<AwardsDetailsPage />} path="award">
      <Route element={<AwardsDetailsView />} path=":awardId/details" />
      <Route element={<AssignRolesPage />} path=":awardId/roles" />
      <Route element={<Budget />} path=":awardId/budget" />
      <Route element={<ReportingPage />} path=":awardId/reporting" />
      <Route element={<ReportingPage />} path=":awardId/reporting/:reportId" />
    </Route>
  );
}
