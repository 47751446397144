import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { tossSuccess } from 'utils/toastTosser';
import IconButton from 'shared/muiComponents/IconButton';
import { Box, Menu, MenuItem, Stack } from '@mui/material';
import {
  FileDownloadOutlined,
  SettingsBackupRestoreRounded,
  ShareOutlined,
  UndoRounded,
} from '@mui/icons-material';
import { Filter } from 'types/filter';
import { isEqual } from 'lodash';

interface FilterActionsProps {
  onCopy?: () => void;
  onExport?: boolean;
  onRevert?: () => void;
  onReset?: () => void;
  onDownloadXLSX?: () => void;
  onDownloadCSV?: () => void;
  onDownloadDOCX?: () => void;
  defaultResetFilters?: Filter;
  filters?: Filter;
}

export default function FilterActions(props: FilterActionsProps) {
  const {
    onCopy,
    onExport,
    onRevert,
    onReset,
    onDownloadXLSX,
    onDownloadCSV,
    onDownloadDOCX,
    defaultResetFilters,
    filters,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="d-flex justify-content-center">
      {onCopy && (
        <Box height="38px" ml={1} width="38px">
          <CopyToClipboard
            onCopy={() => tossSuccess('Link copied to clipboard')}
            text={window.location.href}
          >
            <IconButton onClick={() => {}} tooltipText="Copy link with filters set">
              <ShareOutlined htmlColor="black" />
            </IconButton>
          </CopyToClipboard>
        </Box>
      )}
      {onExport && (
        <Box height="38px" ml={1} width="38px">
          <IconButton onClick={handleClick} tooltipText="Export List">
            <FileDownloadOutlined htmlColor="black" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            id="download-menu"
            keepMounted
            sx={{
              '& .MuiMenu-paper': {
                mt: 1,
                borderRadius: 3,
                border: (theme) => `2px solid ${theme.palette.secondary.light}`,
                '.MuiMenu-list': {
                  p: 0,
                  '.MuiMenuItem-root': {
                    fontSize: '0.8rem',
                    '&:first-of-type': {
                      borderBottom: (theme) => `1px solid ${theme.palette.secondary.light}`,
                    },
                  },
                },
              },
            }}
          >
            {onDownloadCSV && (
              <MenuItem
                onClick={() => {
                  onDownloadCSV();
                  handleClose();
                }}
              >
                Download as CSV
              </MenuItem>
            )}
            {onDownloadXLSX && <MenuItem onClick={onDownloadXLSX}>Download as .xlsx</MenuItem>}
            {onDownloadDOCX && <MenuItem onClick={onDownloadDOCX}>Download as .docx</MenuItem>}
          </Menu>
        </Box>
      )}
      {onReset && (
        <Box height="38px" ml={1} width="38px">
          <IconButton
            onClick={onReset}
            showBadge={!isEqual(defaultResetFilters, filters)}
            tooltipText="Reset All Filters"
          >
            <UndoRounded htmlColor="black" />
          </IconButton>
        </Box>
      )}
      {onRevert && (
        <Box height="38px" ml={1} width="38px">
          <IconButton
            onClick={onRevert}
            showBadge={false}
            tooltipText="Revert to last loaded Filters set"
          >
            <SettingsBackupRestoreRounded htmlColor="black" />
          </IconButton>
        </Box>
      )}
    </div>
  );
}
