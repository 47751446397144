import dayjs from 'dayjs';
import { applicationsConfigs } from './globalConstants';

const today = dayjs();

export const getDefaultApplicationsStatusFilter = (isMillenniumUser?: boolean) =>
  isMillenniumUser
    ? applicationsConfigs.filterStatusesGrants['Open Status - Active']
    : applicationsConfigs.filterStatusesGrants['All Statuses'];

export const getDefaultApplicationsSortByFilter = (isMillenniumUser?: boolean) =>
  isMillenniumUser ? 'asc' : 'desc';

const filtersConfigs = {
  dashboard: {
    applications: {
      default: {
        startDate: null,
        endDate: null,
        status: applicationsConfigs.filterStatuses['Open Statuses'],
        sortBy: 'dueDate',
        dateType: 'Due Date',
        sortOrder: 'asc',
      },
    },
    programs: {
      default: {
        startDate: today.startOf('D').subtract(14, 'd').toDate(),
        endDate: today.add(14, 'd').toDate(),
      },
    },
  },
  grants: {
    applications: {
      default: {
        startDate: null,
        endDate: null,
        status: applicationsConfigs.filterStatusesGrants['Open Status - Active'],
        dateType: 'Due Date',
        sortBy: 'dueDate',
        sortOrder: 'asc',
      },
    },
    programs: {
      default: {
        startDate: null,
        endDate: null,
        category: 'all',
      },
    },
  },
};

export default filtersConfigs;
