import { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { CustomInput } from 'reactstrap';
import { FilterContext } from 'pages/UserList';
import useQueryString from 'hooks/useQueryString';
import { searchUsers } from 'services/userService';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import UsedUserFiltersDescriptionBlock from './UsedUserFiltersblock';

function useQueryFilters() {
  const queryFilters = useQueryString();
  const names = queryFilters.get('u');
  const emails = queryFilters.get('e');
  const phones = queryFilters.get('n');
  const page = queryFilters.get('p');
  const target = queryFilters.get('t');

  return {
    names: names ? names.split(',').map((e) => ({ name: e })) : [],
    emails: emails ? emails.split(',').map((e) => ({ name: e })) : [],
    phones: phones ? phones.split(',').map((e) => ({ name: e })) : [],
    page: page ? parseInt(page, 10) : 1,
    target: target || '',
  };
}

export default function UserFilters() {
  const { filters, setFilters } = useContext(FilterContext);

  const urlFilters = useQueryFilters();

  const [namesSelected, setNamesSelected] = useState(urlFilters.names);
  const [emailsSelected, setEmailsSelected] = useState(urlFilters.emails);
  const [phonesSelected, setPhonesSelected] = useState(urlFilters.phones);
  const [targetSelected, setTargetSelected] = useState(urlFilters.target);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, names: namesSelected }));
  }, [namesSelected]);

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, emails: emailsSelected }));
  }, [emailsSelected]);

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, phones: phonesSelected }));
  }, [phonesSelected]);

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, target: targetSelected }));
  }, [targetSelected]);

  useEffect(() => {
    const { page } = urlFilters;
    setFilters((prevState) => ({ ...prevState, page }));
  }, []);

  const updateFiltersInURL = () => {
    if (isEmpty(filters)) return;

    const historyObject = {};

    const {
      names: filterNames,
      emails: filterEmails,
      phones: filterPhones,
      page: filterPage,
      target: filterTarget,
    } = filters || {};

    if (filterNames && filterNames.length > 0)
      historyObject.u = filterNames.map((e) => e.name).join(',');
    if (filterEmails && filterEmails.length > 0)
      historyObject.e = filterEmails.map((e) => e.name).join(',');
    if (filterPhones && filterPhones.length > 0)
      historyObject.n = filterPhones.map((e) => e.name).join(',');
    if (filterPage) historyObject.p = filterPage;
    if (filterTarget) historyObject.t = filterTarget;

    setSearchParams(historyObject);
  };

  useEffect(updateFiltersInURL, [filters]);

  const handleNamesSearch = async (query: string) => {
    const foundUsers = await searchUsers(query, 'name');
    return foundUsers.map((user) => user.name);
  };

  const handleEmailsSearch = async (query: string) => {
    const foundEmails = await searchUsers(query, 'email');
    return foundEmails.map((user) => user.email);
  };

  const handlePhonesSearch = async (query: string) => {
    const foundPhones = await searchUsers(query, 'phone');
    return foundPhones.map((user) => user.phone);
  };

  const handleTargetChange = (newTarget: string) => {
    setTargetSelected(newTarget);
  };

  return (
    <Stack direction="column" mb={2} mt={2} sx={{ width: '100%' }}>
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ width: '100%' }}
      >
        <SearchFilter
          getLabel={(f) => f}
          label="Name(s)"
          onChange={(values) => setNamesSelected(values?.map((name) => ({ name })))}
          onSearch={handleNamesSearch}
          selected={namesSelected.map((f) => f.name)}
          variant="filter"
        />
        <SearchFilter
          getLabel={(f) => f}
          label="Email(s)"
          onChange={(values) => setEmailsSelected(values?.map((name) => ({ name })))}
          onSearch={handleEmailsSearch}
          selected={emailsSelected.map((f) => f.name)}
          variant="filter"
        />
        <SearchFilter
          getLabel={(f) => f}
          label="Phone(s)"
          onChange={(values) => setPhonesSelected(values?.map((name) => ({ name })))}
          onSearch={handlePhonesSearch}
          selected={phonesSelected.map((f) => f.name)}
          variant="filter"
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-start"
        spacing={1}
        sx={{ width: '100%', marginTop: 2 }}
      >
        <CustomInput
          checked={targetSelected === 'all'}
          id="target-all"
          label="All Users"
          name="target"
          onChange={() => handleTargetChange('all')}
          type="switch"
        />
        <CustomInput
          checked={targetSelected === 'clients'}
          id="target-myclients"
          label="Only for my client"
          name="target"
          onChange={() => handleTargetChange('clients')}
          type="switch"
        />
        <CustomInput
          checked={targetSelected === 'millennium'}
          id="target-melinium"
          label="Only Millennium Users"
          name="target"
          onChange={() => handleTargetChange('millennium')}
          type="switch"
        />
      </Stack>
      <UsedUserFiltersDescriptionBlock />
    </Stack>
  );
}
