import { useState, MouseEvent } from 'react';
import { FormGroup, Input, Label, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { tossError } from 'utils/toastTosser';
import { getToken } from 'services/sessionService';
import useLocalStorage from 'hooks/useLocalStorage';
import 'assets/scss/signIn-form.scss';

export default function SignInForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ emailerr: '', passworderr: '' });
  const [showPassword, setShowPassword] = useState(false);

  const [, setStoredToken] = useLocalStorage('user', 'v1.0');

  const validateEmail = (emailvalue: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailvalue);
  };

  const validatePassword = (pass: string) => {
    return pass.length >= 6;
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
    setError((prev) => ({
      ...prev,
      emailerr: validateEmail(value) ? '' : 'Invalid email format',
    }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
    setError((prev) => ({
      ...prev,
      passworderr: validatePassword(value) ? '' : 'Password must be at least 6 characters',
    }));
  };

  const loginAuth = async (event?: MouseEvent<HTMLButtonElement>) => {
    if (event) event.preventDefault();

    if (error.emailerr || error.passworderr || !email || !password) return;

    setLoading(true);

    const loginToken = await getToken({ email, password });

    if (loginToken) {
      setStoredToken(loginToken);
      navigate(`${process.env.PUBLIC_URL}/dashboard/user-dashboard`);
      window.location.reload();
    } else {
      tossError('Authentication failed. Please check your credentials.');
      setLoading(false);
    }
  };

  const gotoForgotPassForm = () => navigate(`${process.env.PUBLIC_URL}/forgotpassword`);

  return (
    <div className="custom-auth-form">
      <div className="custom-header">
        <h4 className="custom-title">Welcome to Millennium!</h4>
        <h6 className="custom-subtitle">Enter your login details to access Millennium</h6>
      </div>

      <FormGroup className="custom-form-group">
        <Label className="custom-label">Your E-mail Address</Label>
        <Input
          className="form-control custom-input"
          onChange={handleEmailChange}
          placeholder="Enter your E-mail Address"
          required
          type="email"
          value={email}
        />
        {error.emailerr && <span className="custom-error">{error.emailerr}</span>}
      </FormGroup>

      <FormGroup className="custom-form-group password-group">
        <div className="password-label-container">
          <Label className="custom-label">Password</Label>
          <span
            className="custom-forgot-password"
            onClick={gotoForgotPassForm}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                gotoForgotPassForm();
              }
            }}
            role="button"
            tabIndex={0}
          >
            Forgot Password?
          </span>
        </div>
        <div className="password-input-container">
          <Input
            className="form-control custom-input"
            onChange={handlePasswordChange}
            placeholder="Enter your password"
            required
            type={showPassword ? 'text' : 'password'}
            value={password}
          />
          <span
            className="password-toggle-icon"
            onClick={() => setShowPassword(!showPassword)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setShowPassword(!showPassword);
              }
            }}
            role="button"
            tabIndex={0}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </span>
        </div>
        {error.passworderr && <span className="custom-error">{error.passworderr}</span>}
      </FormGroup>

      <FormGroup className="custom-button-group">
        <Button
          className="custom-login-button"
          color="primary"
          disabled={loading || !!error.emailerr || !!error.passworderr || !email || !password}
          onClick={(e) => loginAuth(e)}
        >
          {loading ? 'Logging in...' : 'Login'}
        </Button>
      </FormGroup>
    </div>
  );
}
