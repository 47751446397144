/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import { useState } from 'react';
import { Box, Checkbox, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import {
  DeleteForeverOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  Download,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import dayjs from 'dayjs';

import { uploadAwardFile, deleteAwardFile } from 'services/awards/awardDocumentsService';
import useCurrentAward from 'hooks/useCurrentAward';
import RenderTextWithHighlight from 'utils/helpers/RenderTextWithHighlight';
import { AwardDocument, AwardDocumentCategory } from 'types/awards';
import { DocumentsFilters } from 'hooks/AwardContext';

const DocumentDropzone = styled('div')(({ theme }) => ({
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#F9FAFB',
  border: `1px dashed ${theme.palette.secondary.contrastText}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

const getComparator = (
  sortBy: DocumentsFilters['sortBy'],
  sortOrder: DocumentsFilters['sortOrder']
) => {
  if (sortBy === 'default') {
    return () => 0;
  }

  return (a: AwardDocument, b: AwardDocument) => {
    let comparison = 0;

    const dateA = dayjs(a.createdAt);
    const dateB = dayjs(b.createdAt);

    switch (sortBy) {
      case 'name':
        comparison = a.name.localeCompare(b.name);
        break;
      case 'dateAdded':
        // eslint-disable-next-line no-nested-ternary
        comparison = dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
        break;
      case 'fileType':
        comparison = a.type.localeCompare(b.type);
        break;
      default:
        throw new Error(`Unsupported sortBy parameter: ${sortBy}`);
    }

    return sortOrder === 'asc' ? comparison : -comparison;
  };
};

function formatFileSize(bytes: number) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / k ** i).toFixed(2));
  return `${size} ${sizes[i]}`;
}

export default function AwardDocumentDropzone({
  label,
  category,
  preSaveCheck,
  reportId,
  checked,
  onCheck,
  allowMultiple = false,
  disabled = false,
  callback = () => {},
}: {
  label?: string;
  category: AwardDocumentCategory;
  preSaveCheck?: () => Promise<boolean>;
  reportId?: number;
  checked?: boolean;
  onCheck?: () => void;
  allowMultiple?: boolean;
  disabled?: boolean;
  callback?: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const {
    currentAwardId,
    documentsData,
    documentsFilters,
    refetchAwardAndTimeline,
    fetchDocuments,
  } = useCurrentAward();

  const onCheckGuard = async () => {
    if (preSaveCheck) await preSaveCheck();

    if (onCheck) onCheck();
  };

  const currentDocuments =
    (documentsData?.data?.filter &&
      documentsData.data.filter(
        (d) => d.category === category && (reportId ? d.reportId === reportId : true)
      )) ||
    [];

  const filteredDocuments =
    currentDocuments
      .filter((d) => {
        return d.name.toLowerCase().includes(documentsFilters.search);
      })
      .sort(getComparator(documentsFilters.sortBy, documentsFilters.sortOrder)) || [];

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop: async (acceptedFiles: File[]) => {
      if (preSaveCheck) preSaveCheck();

      setLoading(true);

      const formData = new FormData();

      formData.append('file', acceptedFiles[0]);
      formData.append('category', category);

      if (reportId) formData.append('reportId', String(reportId));

      const result = await uploadAwardFile(currentAwardId, formData);

      if (result) {
        fetchDocuments();
        setTimeout(() => {
          refetchAwardAndTimeline();
        }, 500);
      }

      if (callback) callback();

      setLoading(false);
    },
    disabled: loading,
  });

  return (
    <Box width="100%">
      <Stack alignItems="center" direction="row" sx={{ w: '100%' }}>
        {checked !== undefined && (
          <Checkbox checked={checked} onClick={onCheckGuard} sx={{ p: 0 }} />
        )}
        {label && (
          <Typography m={1} variant="h3">
            {label}
          </Typography>
        )}
      </Stack>

      {(currentDocuments.length === 0 || allowMultiple) && !disabled && (
        <DocumentDropzone
          {...getRootProps()}
          sx={{
            padding: '6px 12px',
            ...(isDragActive && { opacity: 0.7 }),
            ...(isDragReject && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.dark',
            }),
          }}
        >
          <Box
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <input {...getInputProps()} />
          </Box>

          <Stack
            alignItems="center"
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="center"
            spacing={2}
            sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
          >
            {loading ? (
              <LinearProgress sx={{ width: '100%' }} />
            ) : (
              <>
                <FileUploadOutlined htmlColor="black" />
                <Stack>
                  <Typography color="primary.main" variant="h3">
                    Browse or drop a file here
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        </DocumentDropzone>
      )}
      <Stack gap="10px" mb={reportId ? 0 : 1} p={reportId ? 0 : 1}>
        {currentDocuments.length > 0 &&
          filteredDocuments.length === 0 &&
          !reportId &&
          'No documents match your search and filter criteria. Broaden your search or modify filters to find relevant results.'}
        {filteredDocuments.length > 0 &&
          filteredDocuments.map((document) => (
            <Stack
              key={document.id}
              alignItems="center"
              direction="row"
              gap={5}
              justifyContent="space-between"
              width="100%"
            >
              <Stack alignItems="center" direction="row" gap="10px">
                <InsertDriveFileOutlined color="primary" />
                <Stack>
                  <Typography color="primary.main" component="div" variant="body1">
                    <RenderTextWithHighlight
                      highlight={documentsFilters.search || ''}
                      text={`${document.name}  ${formatFileSize(document.size)}`}
                    />
                  </Typography>

                  <Typography color="primary.main" variant="caption">
                    {dayjs(document.createdAt).format('MMM DD YYYY')}
                  </Typography>
                </Stack>
              </Stack>

              <Stack alignItems="center" direction="row" gap="8px">
                <IconButton
                  aria-label="download"
                  onClick={async (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    window.location.href = document.url;
                  }}
                  size="small"
                  sx={{
                    fontSize: '1rem',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    borderRadius: 2,
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  <Download />
                </IconButton>

                <IconButton
                  aria-label="delete"
                  onClick={async (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    if (!confirm('Are you sure you want to delete this document?')) return;

                    const result = await deleteAwardFile(currentAwardId, String(document.id));

                    if (result) fetchDocuments();

                    if (callback) callback();
                  }}
                  size="small"
                  sx={{
                    fontSize: '1rem',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    borderRadius: 2,
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  <DeleteForeverOutlined />
                </IconButton>
              </Stack>
            </Stack>
          ))}
      </Stack>
    </Box>
  );
}
