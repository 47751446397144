/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LinearProgress, Link, Paper, Stack, Typography } from '@mui/material';
import { ArrowBack, SaveRounded } from '@mui/icons-material';
import BootstrapTable from 'react-bootstrap-table-next';

import IconButton from 'shared/muiComponents/IconButton';
import { AwardUserRole, mandatoryRoles } from 'types/awards';
import {
  getAwardRoles,
  createAwardRole,
  deleteAwardRole,
  updateAwardRole,
} from 'services/awards/awardRolesService';

import useUserSession from 'hooks/useUserSession';
import useUnsavedChangesWarning from 'hooks/useUnsavedChangesGuard';

import { tossError, tossSuccess } from 'utils/toastTosser';
import {
  CustomRoleRow,
  getCustomRolesColumns,
  getMandatoryRolesColumns,
  MandatoryRoleRow,
} from './getRolesColumns';

import AddCustomRoleBlock from './AddCustomRoleBlock';

export default function AssignRolesPage() {
  const [roles, setRoles] = useState<AwardUserRole[]>([]);
  const [mandatoryList, setMandatoryList] = useState<MandatoryRoleRow[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  const { userType } = useUserSession();
  const { awardId } = useParams();
  const navigate = useNavigate();

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useUnsavedChangesWarning(unsavedChanges);

  const goToUserPage = () => {
    navigate('/dashboard/users');
  };

  const fetchRoles = useCallback(async () => {
    setLoading(true);

    const result = await getAwardRoles(awardId!, 'sortBy=role&sortOrder=asc');

    if (isMounted.current) {
      if (result) {
        setRoles(result.rows);

        const mandatoryRolesList = mandatoryRoles.map((roleName) => {
          const roleFoundInList = result.rows.find((role) => role.role === roleName);

          return {
            id: roleFoundInList?.id,
            role: roleName,
            roleId: roleFoundInList?.id,
            userId: roleFoundInList?.user?.id,
            updatedUserId: roleFoundInList?.user?.id,
            userName: roleFoundInList?.user?.name,
            email: roleFoundInList?.user?.email,
            position: roleFoundInList?.user?.position,
          };
        });

        setMandatoryList(mandatoryRolesList);

        setError(false);
      } else setError(true);

      setLoading(false);
    }
  }, [awardId]);

  const updateRole = useCallback(
    (updatedRole: MandatoryRoleRow) => {
      setMandatoryList((currentList) =>
        currentList.map((role) => {
          if (role.role === updatedRole.role) return updatedRole;
          return role;
        })
      );
    },
    [mandatoryList, setMandatoryList]
  );

  // Similar to the above, I want to save all roles at once
  const saveRoles = async () => {
    if (!awardId) return;

    setUnsavedChanges(false);
    tossSuccess('Roles saved successfully');

    mandatoryList.forEach(async (userRole: MandatoryRoleRow) => {
      const isCreated = !userRole.id;
      const { updatedUserId } = userRole;
      const { role } = userRole;
      const deletableUserRoleId = mandatoryList.find(
        (r: MandatoryRoleRow) => r.userId === userRole.userId && r.role === userRole.role
      )?.id;

      if (isCreated) {
        const result = await createAwardRole(awardId, {
          awardId: Number(awardId),
          userId: updatedUserId,
          role,
        });

        if (!result) tossError(`Error saving role: ${role}`);
        else {
          updateRole({ ...userRole, roleId: result.id, userId: result.userId });
          if (deletableUserRoleId) await deleteAwardRole(awardId, deletableUserRoleId);
        }
      } else if (updatedUserId && userRole.id) {
        const result = await updateAwardRole(awardId, userRole.id, {
          userId: updatedUserId,
          role,
        });

        if (!result) tossError(`Error saving role: ${role}`);
        else {
          updateRole({ ...userRole, userId: updatedUserId });
        }
      } else {
        if (!userRole.id) return;

        const result = await deleteAwardRole(awardId, userRole.id);

        if (!result) tossError(`Error saving role: ${role}`);
        else {
          updateRole({ ...userRole, userId: undefined, roleId: undefined });
        }
      }
    });
  };

  const goToAwardDetails = () => {
    const proceedToNavigate = unsavedChanges
      ? confirm('Please save or discard your changes before leaving')
      : true;

    if (proceedToNavigate) {
      navigate(`/dashboard/award/${awardId}/details`, { state: { from: 'Roles' } });
    }
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (awardId) fetchRoles();
  }, [awardId]);

  const customRolesList: CustomRoleRow[] = roles
    .filter((role) => role.customUserRole)
    .map((role) => {
      return {
        id: role.id,
        role: role.customUserRole || '-',
        userId: role.user.id,
        userName: role.user.name,
        email: role.user.email,
        position: role.user.position,
      };
    });

  const canEditCustomRoles =
    userType !== 'millenniumAnalyst' && userType !== 'millenniumResearcher';

  return (
    <>
      <Helmet>
        <title>Grantrack - Award {awardId} - Assign Roles</title>
      </Helmet>

      <Stack direction="row" justifyContent="space-between" m={2}>
        <Stack alignItems="center" direction="row" spacing={2}>
          <IconButton onClick={() => goToAwardDetails()}>
            <ArrowBack htmlColor="black" />
          </IconButton>

          <Typography variant="h2">Assign Roles</Typography>
        </Stack>

        <IconButton onClick={() => saveRoles()}>
          <SaveRounded sx={{ color: (theme) => theme.palette.primary.main }} />{' '}
          <Typography sx={{ fontWeight: 600 }} variant="caption">
            Save
          </Typography>
        </IconButton>
      </Stack>

      <Paper elevation={2} sx={{ m: 2, p: 2, borderRadius: 4, boxShadow: '0 0 15px 10px #f7f7f7' }}>
        <Stack direction="column" mb={2} mt={2}>
          {/* <Typography variant="h2">Mandatory Roles</Typography> */}
          <Typography variant="caption">
            Default roles are configured in the user&apos;s profile. Navigate to the{' '}
            <Link onClick={goToUserPage}>users</Link> page to edit the default user roles.
          </Typography>
        </Stack>

        {loading && <LinearProgress />}

        <BootstrapTable<MandatoryRoleRow>
          bodyClasses={loading ? 'loading' : ''}
          bootstrap4
          bordered={false}
          columns={getMandatoryRolesColumns(awardId!, updateRole, setUnsavedChanges)}
          data={mandatoryList}
          keyField="role"
          wrapperClasses="table-responsive table-borderless rows-centered"
        />
      </Paper>

      <Paper elevation={2} sx={{ m: 2, p: 2, borderRadius: 4, boxShadow: '0 0 15px 10px #f7f7f7' }}>
        <Stack direction="column" mb={2}>
          <Typography variant="h2">Custom Roles</Typography>
        </Stack>

        {loading && <LinearProgress />}

        <BootstrapTable<CustomRoleRow>
          bodyClasses={loading ? 'loading' : ''}
          bootstrap4
          bordered={false}
          columns={getCustomRolesColumns(awardId!, fetchRoles, !canEditCustomRoles)}
          data={customRolesList}
          keyField="id"
          noDataIndication="No custom roles yet"
          wrapperClasses="table-responsive table-borderless rows-centered"
        />

        {canEditCustomRoles && (
          <Stack direction="column" mb={1} mt={5}>
            <Typography variant="caption">Add new role:</Typography>
          </Stack>
        )}

        {canEditCustomRoles && (
          <AddCustomRoleBlock
            awardId={awardId!}
            fetchRoles={fetchRoles}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      </Paper>
    </>
  );
}
