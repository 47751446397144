import useSearchParams from 'hooks/useSearchParams';
import FilterSelect from 'shared/muiComponents/FilterSelect';

const assignedFilterValues = {
  assignee: 'Assigned',
  unassignee: 'Unassigned',
};

export default function AssignedFilter({ width = '100%' }) {
  const { searchParams, setSearchParams } = useSearchParams();

  return (
    <FilterSelect
      getValueLabel={(value) =>
        value ? assignedFilterValues[value as keyof typeof assignedFilterValues] : 'All'
      }
      label="Assigned"
      onChange={(value) => setSearchParams({ page: 1, assigned: value || null })}
      options={[undefined, ...Object.keys(assignedFilterValues)]}
      selected={String(searchParams.assigned || '')}
      width={width}
    />
  );
}
