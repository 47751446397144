import { Box, Grid, Stack, Typography } from '@mui/material';
import { nanoid } from 'nanoid';

import { StepWithCoordinates } from 'components/awards/AwardsDetails/AwardsDetailsView/Timeline/index';
import LeftLine from './LeftLine';
import RightLine from './RightLine';

export default function TimelineCollapsed({ firstRow }: { firstRow: StepWithCoordinates[] }) {
  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      {firstRow.map((step) => (
        <Grid key={nanoid()} item sx={{ p: '0!important', mt: '-5px' }} xs={12 / firstRow.length}>
          <Stack
            direction="row"
            sx={{
              height: '100px',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
            textAlign="center"
            zIndex={1}
          >
            {step.firstInRow && !step.firstInTotal ? (
              <Box
                sx={{
                  position: 'absolute',
                  height: '4px',
                  width: '50%',
                  borderTop: `4px dashed ${step.stepColor}`,
                  left: 0,
                }}
              />
            ) : (
              <LeftLine step={step} />
            )}
            <Box
              sx={{
                width: '20px',
                height: '20px',
                backgroundColor: step.complete ? step.stepColor : 'white',
                borderRadius: '50%',
                zIndex: '2',
                border: `3px solid ${step.stepColor}`,
                position: 'relative',
              }}
            >
              <Stack direction="column" left={-42} position="absolute" top={24} width="100px">
                <Typography
                  sx={{
                    color: '#5F77A5',
                    textTransform: 'uppercase',
                    fontSize: '0.7rem',
                    fontWeight: '600',
                    lineHeight: '1',
                  }}
                >
                  {step.timeline}
                </Typography>
              </Stack>
            </Box>

            {step.lastInRow && !step.lastInTotal ? (
              <Box
                sx={{
                  position: 'absolute',
                  height: '4px',
                  width: '50%',
                  borderTop: `4px dashed ${step.nextStepColor}`,
                  right: 0,
                }}
              />
            ) : (
              <RightLine step={step} />
            )}
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
