import EmptyScreenCard from 'shared/EmptyScreenCard';
import StandardButton from 'shared/muiComponents/StandardButton';

export default function NoProjectsEmptyScreen({
  onCreate,
  canEdit = false,
}: {
  onCreate: () => void;
  canEdit?: boolean;
}) {
  return (
    <EmptyScreenCard title="Your Projects">
      <p>You have no projects.</p>
      {canEdit && (
        <StandardButton onClick={onCreate} startIcon>
          Create New Project
        </StandardButton>
      )}
    </EmptyScreenCard>
  );
}
