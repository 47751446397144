import { useEffect, useState } from 'react';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import { updateIfChanged } from 'utils/utilFunctions';
import { searchClients } from 'services/clientService';
import { Filter } from 'types/filter';

interface ClientFilterProps {
  filters: Filter[];
  onChange: (filters: { name: string }[]) => void;
  width?: string;
}

export default function ClientFilter({ filters, onChange, width }: ClientFilterProps) {
  const [currentFilters, setCurrentFilters] = useState<{ name: string }[]>(
    Array.isArray(filters) ? filters : []
  );

  const handleChange = (values: string[]) => {
    const newFilters = values.map((name) => ({ name }));
    setCurrentFilters(newFilters);
    onChange(newFilters);
  };

  const onSearch = async (query: string) => {
    const clientsFound = await searchClients(query, 'name');
    return clientsFound.map(({ name }) => name);
  };

  useEffect(() => {
    updateIfChanged(setCurrentFilters, Array.isArray(filters) ? filters : []);
  }, [filters]);

  return (
    <SearchFilter<string>
      getLabel={(f) => f}
      label="Client"
      onChange={handleChange}
      onSearch={onSearch}
      selected={currentFilters.map((f) => f.name)}
      variant="filter"
      width={width}
    />
  );
}
