import { useEffect, useState } from 'react';
import SearchFilter from 'shared/muiComponents/SearchFilter';
import { updateIfChanged } from 'utils/utilFunctions';
import { searchUsers } from 'services/userService';
import { User } from 'types/user';

interface UserFilterProps {
  filters?: { id: string; name: string }[];
  onChange: (filters: { id: string; name: string }[]) => void;
  width?: string;
}

export default function UserFilter({ filters, onChange, width }: UserFilterProps) {
  const [currentFilters, setCurrentFilters] = useState<{ id: string; name: string }[]>(
    Array.isArray(filters) ? filters : []
  );

  const handleChange = (values: string[]) => {
    const newFilters = values.map((name) => ({ id: name, name }));
    setCurrentFilters(newFilters);
    onChange(newFilters);
  };

  const onSearch = async (query: string) => {
    const usersFound = await searchUsers(query, 'name', true, false);
    return usersFound.map(({ name }: User) => name);
  };

  useEffect(() => {
    updateIfChanged(setCurrentFilters, Array.isArray(filters) ? filters : []);
  }, [filters]);

  return (
    <SearchFilter<string>
      getLabel={(f) => f}
      label="Assigned To"
      onChange={handleChange}
      onSearch={onSearch}
      selected={currentFilters.map((f) => f.name)}
      variant="filter"
      width={width}
    />
  );
}
