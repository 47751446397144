import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import useSearchParams from 'hooks/useSearchParams';

import { getAwardReports, ReportsList } from 'services/awards/awardReportsService';
import ReportsTable from '../ReportsTable';

const defaultFiltersSet = { sortBy: 'id', sortOrder: 'desc', page: '1', perPage: '20' };

export default function AllReportsView() {
  const { awardId, reportId } = useParams();
  const { setSearchParams } = useSearchParams();
  const location = useLocation();

  const [data, setData] = useState<ReportsList>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    setSearchParams(defaultFiltersSet);

    return () => {
      isMounted.current = false;
    };
  }, []);

  const refetchReports = useCallback(async () => {
    if (awardId && isMounted.current) {
      setLoading(true);

      const result = await getAwardReports(awardId, location.search);

      if (result) {
        setData(result);
        setError(false);
      } else setError(true);

      setLoading(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (location.search) {
      refetchReports();
    }
  }, [location.search]);

  return (
    <Container fluid>
      <ReportsTable
        data={data}
        error={error}
        loading={loading}
        refetchReports={refetchReports}
        reportId={reportId}
      />
    </Container>
  );
}
