import { useState, useEffect } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { Outlet, useLocation } from 'react-router';

import 'react-toastify/dist/ReactToastify.css';
import Header from 'components/layout/Header';
import Loader from './Loader';

export default function Layout() {
  const [uncaughtErrorInChildren, setUncaughtErrorInChildren] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleError = (error: ErrorEvent) => {
      if (error.message.includes('Uncaught')) setUncaughtErrorInChildren(true);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  useEffect(() => {
    if (uncaughtErrorInChildren) {
      setUncaughtErrorInChildren(false); // Reset error state on route change
    }
  }, [location.pathname]);

  return (
    <div>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          <div className="page-body">
            {!uncaughtErrorInChildren ? <Outlet /> : <div>Something went wrong</div>}
          </div>
          <div className="mt-4" />
          <ToastContainer autoClose={3500} transition={Slide} />
        </div>
      </div>
    </div>
  );
}
