import { useState, useEffect } from 'react';
import { Check, Layers } from 'react-feather';

import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { nanoid } from 'nanoid';

import { tossError, tossSuccess } from 'utils/toastTosser';
import useKeyPress from 'hooks/useKeyPress';
import { TextareaInput } from 'shared/inputs';
import useUserSession from 'hooks/useUserSession';

import logger from 'services/logger';

import { createApplication } from 'services/applicationService';
import { Program } from 'types/program';
import { Application } from 'types/application';

import ApplicationCreationForm from './ApplicationCreationForm';
import 'assets/scss/programList.scss';

interface CreateApplicationsProps {
  program: Program;
  disabled?: boolean;
}

interface Form {
  applications: { id: string; errors: Record<string, string> }[];
  message?: string;
}

export default function CreateApplications({ program, disabled = false }: CreateApplicationsProps) {
  const currentUser = useUserSession();

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<Form>({ applications: [{ id: nanoid(), errors: {} }] });
  const escPress = useKeyPress('Esc');

  const setField = (field: string, value: string) =>
    setForm((prevState) => ({ ...prevState, [field]: value }));

  const toggle = () => setOpen((prevState) => !prevState);

  const add = () => {
    setField('applications', [...form.applications, { id: nanoid(), errors: {} }]);
  };

  const update = (value: Application) => {
    const { id, assignee, client, emails } = value;
    const { applications } = form;
    const currentApplications = [...applications];
    const updateIndex = currentApplications.map((e) => e.id).indexOf(id);

    // Check for errors during the update.
    const errorList = {};

    if (assignee === null) errorList.assignee = 'This field is required.';

    if (client === null) errorList.client = 'This field is required.';

    if ([typeof assignee, typeof client].includes('undefined') || [assignee, client].includes(null))
      errorList.assigneeClient = 'You need to specify both assignee and client.';

    if (emails) {
      if (
        emails.filter(
          (email) =>
            email.name.match(
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
            )?.[0] === email.name
        ).length !== emails.length
      )
        errorList.emails = 'Some emails are misformatted.';
    }

    currentApplications[updateIndex] = { ...value, errors: errorList };

    setField('applications', currentApplications);
  };

  const remove = (id) => {
    const newApplicationsList = form.applications.filter((e) => e.id !== id);

    setField('applications', newApplicationsList);
  };

  const send = async () => {
    const { applications, message } = form;

    let errorsFound = false;

    for (const currentApplication of applications) {
      const {
        id,
        name,
        summaryFile,
        funder,
        fundingAmount,
        amountVaries,
        source,
        category,
        startsAt,
        endsAt,
        matchRequirements,
        performancePeriod,
        estimatedResponse,
        customFields,
      } = program;

      const { assignee, client, emails } = currentApplication;

      const submittedFields = {
        programId: id,
        assigneeId: currentUser.userType === 'millenniumAdmin' ? assignee : currentUser.id,
        clientId: client,
        name,
        funder,
        fundingAmount: fundingAmount ?? 0.0,
        amountVaries,
        source,
        startsAt,
        endsAt,
        matchRequirements,
        performancePeriod,
        summaryFile,
        estimatedResponse,
        customFields: JSON.stringify(customFields ?? []),
        category,
        status: '1',
        emails: emails.map((e: { name: string }) => e.name),
        message,
      };

      // eslint-disable-next-line no-await-in-loop
      const result = await createApplication({ ...submittedFields, user: currentUser });

      if (!result) {
        logger.error('Error sending an application.', { application: submittedFields });
        errorsFound = true;
        break;
      } else {
        logger.info('Sent an application.', { application: submittedFields });
      }
    }

    if (errorsFound) {
      tossError('Emails were not sent, but applications were successfully created.');
    } else {
      tossSuccess('Applications successfully created and the provided emails were notified.');
      toggle();
    }
  };

  useEffect(() => {
    if (!escPress && open) {
      toggle();
    }
  }, [escPress]);

  return (
    <>
      <Button
        className="d-flex justify-content-center align-items-center application-creation-btn"
        disabled={currentUser.userType === 'millenniumAnalyst' || disabled}
        onClick={toggle}
        title={
          disabled
            ? 'This program has no grant summary attached.'
            : 'Click to create applications and send grant summaries.'
        }
        variant="light"
      >
        <Layers size={16} style={{ marginBottom: '2px' }} />
        &nbsp;Create Applications
      </Button>

      <Modal centered onHide={toggle} show={open} size="lg">
        <Modal.Header className="app-modal-header" closeButton>
          Create Applications
        </Modal.Header>

        <Modal.Body>
          <div className="overflow-auto form-cards-container create-app-form">
            <Form>
              {form.applications.length > 0 ? (
                form.applications.map((currentApplication, currentIndex) => (
                  <ApplicationCreationForm
                    key={currentApplication.id}
                    form={currentApplication}
                    index={currentIndex + 1}
                    onChange={update}
                    onDelete={remove}
                  />
                ))
              ) : (
                <Form.Text muted>
                  No applications declared. Use the button to the right to declare one.
                </Form.Text>
              )}
              <div className="text-right">
                <Button onClick={add} variant="primary-blue">
                  + Add Another Application
                </Button>
              </div>
            </Form>
          </div>

          <div className="create-app-textarea">
            <TextareaInput
              controlId="createApplications.Message"
              label="Message"
              onChange={(newValue: string) => setField('message', newValue)}
            />
            <Form.Text muted>
              This message will be included in an email letter sent to every email from the lists
              above.
              <ul>
                <li>
                  - <strong>If this message is blank, a default message is shown</strong>
                </li>
                <li>
                  - If this program has an associated grant summary file, this file will be included
                  as an attachment.
                </li>
                <li>
                  - If an application has at least one email provided, its initial status will be
                  set to <strong>Client Notified</strong>.
                </li>
                <li>
                  - If any email was provided to more than one application, it will be receiving
                  multiple letters.
                </li>
              </ul>
            </Form.Text>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="d-flex justify-content-center align-items-center"
            disabled={
              form.applications.length === 0 ||
              form.applications
                .filter(({ errors }) => Object.keys(errors).length > 0)
                .map(({ errors }) => errors).length > 0
            }
            onClick={send}
            variant="save"
          >
            <Check size={16} />
            &nbsp;Send &amp; Create {form.applications.length} Application
            {form.applications.length !== 1 ? 's' : ''}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
